
import { COLLECTION } from "@api/queries/collections";
import { useLazyQuery } from "@hooks/useApi";
import { useDataProvider, usePageContext, useRepeatProvider } from "@mosanic/core/Provider";
import { isClone } from "@mosanic/utils/value";
import { useEffect } from "react";

import { getField, getFieldValue } from "@mosanic/fields/Source/helpers";
import { DataProvider as ProviderContext } from "@mosanic/core/Provider";
import { Expander } from "@mosanic/items";
import { DropdownMenuIcon } from "@radix-ui/react-icons";


 
const getEntry = (entries, entry) => (entry?.entry) ?
    entries?.find(e => e?._id === entry?.entry) :
    entries 
 
const useCollection = ({entry}) => {
    let [handler, [collection, loading, error]]  = useLazyQuery(
        'collectionById', 
        COLLECTION 
    );
    useEffect(() => { 
        if(
            entry?.collection &&
            !collection?.entries && !error
        ) handler({_id: entry.collection})
        
    },[entry?.collection, collection?.entries, error])

    return [collection, loading]
}

const DataProvider = ({fields, ref, editor, active = false, clone}, children) => {
    const {source} = editor || {source: null};
    const {entry, filters} = source || {entry: null, filters: true};
    
    const page = usePageContext()
    const repeater = useRepeatProvider()

    const providedData = useDataProvider()

    let [collection, loading] = useCollection({entry})


    const entries = collection?.entries;

    const current = (source?.dynamic && page?.entry) ? 
        page?.entry :
        (repeater?.entries && isClone(clone)) ? 
            entries?.[clone] :
            getEntry(entries, entry);

            
    // const field = hasAttr(current, entry?.field) ? current[entry.field] : null;

    const field = getField(collection, entry)
    let data = getFieldValue(entry?.fieldPath, current)
    data =  (isClone(clone) && data?.[clone]) ? data[clone] : data;

    const lastName = entry?.fieldPath?.[entry?.fieldPath?.length - 1]?.name
    // if(toLowCase(entry?.field) === 'gallery' ) console.log({content, contentRender})
    // if((Array.isArray(content) && content?.[0]?.url?.url) ) console.log("NOT RENDINGER?")
    // const content = 
    //         field ? field : 
    //         current ? current : 
    //         entries ? entries :
    //         null
 
            // console.log({content, wrapperContent, content, entry: entry?.field} )
            // return JSON.stringify({wrapperContent, contentRender, content})
    return (
        <ProviderContext data={{[lastName]: {data, entry}, ...providedData}}>
            {filters && (
                <Expander trigger={<h3>Filters  <DropdownMenuIcon /></h3>}>
                    Filter options
                </Expander>
            )}
            
            {children}
            {/* {lastName} */}
            {/* {JSON.stringify({data, entry})} */}
        </ProviderContext>
    ); 
            // return content ? (
    //     <>
    //     {contentRender}
    //     </>
    // ) : (
    //     <DataContext.Provider value={wrapperContent}>
    //         {children}
    //     </DataContext.Provider>
    // );
}
export default DataProvider;