import { InputGroup } from "@mosanic/items";
import { colors } from "@mosanic/styles";
import { containsSubstr, isString, matchSubstrList, replaceParts, toLowCase } from "@util/Text";
import { useCallback, useEffect, useState } from "react";
import Button from "../Button/Buttons";
import { CodeSandboxLogoIcon, FileIcon } from "@radix-ui/react-icons";

const demo = {
    text: 'info',
    arr: [20220, 1, 22, 25, {test: 'value', arr: ['a', 'b', 'c']}],
    nested: {
        extra: 'text',
        sub: 'text',
        num: 89
    },
    dateTest: new Date()
}


// todo add : https://www.npmjs.com/package/sandbox on server


const restricted = [
    'eval', 'innerHTML', 'version', 'process.env', 'process', 'env', 'password', 'secret', 'restricted', 'restrict', 'require', 'function', 'console', 'yaml', 'execute'
]

// const (?:^|\W)single_word(?:$|\W)

export const parseCode = (code, ctx, returnCtx = true) => {
    const mosanic_context = ctx;
    code = code ? code.replace(/[\u{0080}-\u{FFFF}]/gu,"") : 'wrong chars';
    if(matchSubstrList(toLowCase(code), restricted)) return 'restricted ';
    try {
        return eval(replaceParts(code, '$ctx', 'mosanic_context'))
    } catch (e) {
        return returnCtx ? mosanic_context : null
    }
}



export const useParseCode = ({value, ctx}) => {
    const [parsed, setParsed] = useState(null);

    useEffect(() => {
        let timer;
        timer = setTimeout(() => {
            const code = parseCode(value, ctx) || null
            setParsed(code)
        }, 1000);
        
        return () => clearTimeout(timer)
    },[ctx, value])

    return parsed;
}

export const PreviewCode = ({value, ctx}) => {
    const result = useParseCode({value, ctx})
    const [view, setView] = useState('parsed')
    const toggle = () => setView(view === 'parsed' ? 'ctx' : 'parsed');

    const data = view === 'parsed' ? {result} : ctx;
    return (
        <div style={{
            width: '50%',
            fontSize: '12px', 
            fontFamily: 'Menlo, Monaco, "Courier New", monospace',
            overflow: 'auto',
            borderLeft: `2px solid ${colors.util.gray.light}`,
            paddingLeft: '10px'
        }}>
            <Button onClick={() => toggle()} icon={<FileIcon />} right ghost float style={{right: 2}} />
            <pre>
            {JSON.stringify(data, undefined, 2)}
            </pre>
        </div>
    )
}