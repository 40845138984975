import { hex2hsl, hsl2Hex } from "./Convert";

export const  getShades = (baseColor, advanced = false, numberOfValues = 12, initialAlpha = 4, saturation = null) => {
    let output = [];

    //Basic


    //todo --> store each advanced layout
    // ! Advanced select of 12 'preferred'
    //      + change secondary
    //      + change opacity
    //      + change palette
    //      + change display type


    // ! ON Super user --> 'unlimited' preferred'
    //  §  --> future --> drag drop order
    //      + change util colors
    //      + change alert colors
    //      + gradients
    //      + patterns


    // ... on call --> use '6 selected preferred'
    if(advanced){
        var delta = 1 / numberOfValues; // if number of values was 5 d would represent 1/5 of the base. 
        var outputArray = [];
        var i = 0;
        let current;
        let base = hex2hsl(baseColor);

        while (i < numberOfValues) {
            i++ 
            if (i == 1) {
                //...math on base color & incorporate initial alpha
                // alert(pSBC ( d * initialAlpha,  baseColor))
                // alert(getLighter(baseColor, d * initialAlpha))
                current = getValidLightest(baseColor, delta, initialAlpha, saturation);

                base = hex2hsl(current);
                output.push( {type: '10', value: current} )
                
            } else {
                let max = 94;
                let start = 23;
                let interval = (max - start) / (numberOfValues - 1)


                current = getLight(base, 100 - (interval * i), saturation );
                // console.log((interval * i) +' ---delta---'+(delta* i))
                output.push({
                    type: i+'0', 
                    value: current
                })
                // pSBC ( d * initialAlpha, );
                //...math on base color by incrementing d or 1/n
                // outputArray.push("result of math on d base")
            }  
            
        }
        // if(output.find(color => color.value === '#BDBDBD')){
        //     alert('not good')
        // }

    }
   

    return !advanced ? [
        {type: '10', value: getLighter(baseColor, 45, saturation)},
        {type: '20', value: getLighter(baseColor, 25, saturation)},
        {type: '30', value: getLighter(baseColor, 10, saturation)}, 
        {type: '40', value: getDarker(baseColor, 0, saturation)},
        {type: '50', value: getDarker(baseColor, 10, saturation)},
        {type: '60', value: getDarker(baseColor, 25, saturation)},
    ] : output;

}

const isValid = (hex) => {return /^#[0-9A-F]{6}$/i.test(hex)};


//          --green-1: hsl(131, 80%, 90%);
//          --green-2: hsl(131, 80%, 86%);
//          --green-3: hsl(131, 80%, 77%);
//          --green-4: hsl(131, 80%, 68%);
//          --green-5: hsl(131, 80%, 59%);
//          --green-6: hsl(131, 80%, 50%);
//          --green-7: hsl(131, 80%, 41%);
//          --green-8: hsl(131, 80%, 32%);
//          --green-9: hsl(131, 80%, 23%);
const getLight = (base, light, saturation = null) => {
    // let base = hex2hsl(hex);
    // alert(base.l +' light>'+light*10)
    let newHex = hsl2Hex(base.h, saturation ? saturation : base.s, light)
    return isValid(newHex) ? newHex : '#FFFFFF';
}
const getLighter = (hex, change = 10, saturation = null) => {
    let base = hex2hsl(hex);
    let newL = (base.l + change);
    let newHex = hsl2Hex(base.h, saturation ? saturation : base.s, newL)
    return isValid(newHex) ? newHex : '#FFFFFF';
}
// const getDark = (hex, light) => {
//     let base = hex2hsl(hex);
//     // let newL = (base.l - change)
//     let newHex = hsl2Hex(base.h, base.s, light)
//     return isValid(newHex) ? newHex : '#000000';
// }
const getDarker = (hex, change = 10, saturation = null) => {
    let base = hex2hsl(hex);
    let newL = (base.l - change)
    let newHex = hsl2Hex(base.h, saturation ? saturation : base.s, newL)
    return isValid(newHex) ? newHex : '#000000';
}




const getValidLightest = (hex, change = 10, initialAlpha = 0, saturation = null) => {
    let max = 95;
    let start = 3;


    let amount;

    let current = getLighter(hex, start, saturation );
   
    let darknessIndex = 1;
    for (let index = start; index < max; index++) {
        // const element = array[index];
        // darknessIndex++;
         amount = start + darknessIndex;
        // alert('i'+darknessIndex+' >>'+amount)
        current = getLight(hex2hsl(hex), 100 - amount, saturation );

        if(isValid(current) && current != '#FFFFFF') index = 99;
    }
    // while ((!isValid(current) || current === '#FFFFFF') && darknessIndex <= max) {
       
    //     darknessIndex++;
    //      amount = start + darknessIndex;
    //     // alert('i'+darknessIndex+' >>'+amount)
    //      current = getLight(hex, amount );
    // }
    return current;
}
