import { CenterCenter } from "@mosanic/styles/flex.styles";
import { lightText } from "@util";
import styled, { css } from "styled-components";
import { color, layout, space } from 'styled-system';
import colors from "./colors";
import { Transitions } from "./transition.styles";
import { darken, lighten } from "polished";

const getTextColor = (color) => {
    return lightText(color) ? colors.util.light : colors.util.dark;
}

const getStyle = theme => css` 
    background-color: ${colors.util[theme]?.base};

    svg path {
        fill: ${colors.util[theme]?.lighter};
    }

    &, * {
        color: ${getTextColor(colors.util[theme]?.dark)}!important;
    }
    &:hover {
        background-color: ${darken(0.15, colors.util[theme]?.base)};

        &, * {
            color: ${getTextColor(darken(0.15, colors.util[theme]?.base))}!important;
        }
        svg path {
            fill: ${colors.util[theme]?.light};
        }
    }
    &:active{
        background-color: ${darken(0.2, colors.util[theme]?.base)};

        &, * {
            color: ${getTextColor(darken(0.2, colors.util[theme]?.base))}!important;
        }
        svg path {
            fill: ${colors.util[theme]?.lighter};
        }
    }
    

    color: ${getTextColor(colors.util[theme].dark)};
`

const getTheme = (theme) => {
    if(['red', 'green', 'orange'].includes(theme)){
        return css`
            svg path {
                fill: ${colors.util[theme]?.lighter};
            }
            background: transparent;
            color: ${getTextColor(colors.util[theme].dark)};
        `; 
    }
    switch (theme) {
        case 'ghost': return css`
            ${getStyle('gray')}
        `;
        case 'primary': return css`
            ${getStyle('primary')}
        `;

        case 'secondary': return css`
            background-color: ${colors.util.secondary.dark};
            border: 1px solid ${colors.util.secondary.base};
            color: ${getTextColor(colors.util.secondary.dark)};
        `;
        case 'dark': return css`
            background-color: ${colors.util.gray.base};
            border: 1px solid ${colors.util.gray.base};
            color: ${getTextColor(colors.util.gray.dark)};
        `;
        case 'error': return css`
            background-color: ${colors.util.red.base};
            border: 1px solid ${colors.util.gray.base};
            color: ${getTextColor(colors.util.gray.dark)};
        `;

        case 'link': return css`
            svg path {
                fill: ${colors.util.primary.base};
            }
            background: transparent;
            color: ${colors.util.primary.base};


            &:hover {
                border: 1px solid  ${colors.util.gray.light};
                background-color: ${colors.util.gray.lighter};

                svg path {
                    fill: ${colors.util.primary.dark};
                }
            }
            &:active{
                background-color: ${colors.util.gray.light};

                &, * {
                    color: ${colors.util.primary.dark}!important;
                }
                svg path {
                    fill: ${colors.util.primary.base};
                }
            }
        `;

        case 'disabled': return css`
            
        `
    
        //Primary
        default: return css`
            background-color: transparent;
            color: ${colors.util.dark};
            border: 1px solid ${colors.util.dark};

            &:hover,
            &:active{
                background-color: ${colors.util.gray.lighter};
            }
        `;
    }
}

const getSize = (size) => {
    switch (size) {
        case 'large': return css`
            padding: 8px 16px;
            font-size: 14px;
        `;
        // case 'large': return css`
        //     padding: 8px 16px;
        //     font-size: 14px;
        // `;

        case 'medium': return css`
            padding: 4px 8px;
            font-size: 12px;
        `;
    
        //Small
        default: return css`
            padding: 4px 8px;
            font-size: 12px;
        `;
    }
}
const getOffset = (offset) => {
    switch (offset) {
        case 'large': return css`
            margin: 7.5px 10px;
        `;
    
        //Small
        default: return css`
            margin: 0 5px 5px;
        `;
    }
}
export const Button = styled.button` 
    border: 1px solid  transparent;
    ${props => getTheme(props?.theme)}
    ${props => getOffset(props?.offset)}
    ${props => getSize(props?.sz)}


    ${props => props.scale && css`

        svg {
            ${Transitions(0.5).easeInOut}
            transform: scale(0.7);
        }
        &:hover {
            svg {
                transform: scale(0.8);
            }
        }
    `}

    ${props => props.uiButton && css` 
        ${getSize('large')}
        ${props => (props.theme != 'ghost' && props.theme != 'link' && !props.ghost) ? css`
            box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.25);
        ` : css` 
        
        `}
    `}
    

    &:focus {
        color: ${colors.util.primary.base};
        /* font-weight: bold; */
    }
    
    img, svg{
        ${Transitions().easeOut}
        transform: scale(0.8);
    }
    svg {
        margin-right: 2px;
    }
    img ,svg {
        transform-origin: center center;
    }
    &:hover,
    &:active{
        cursor: pointer;

        img, svg {
            transform: scale(1);
        }
    }

    ${props => props.active && css`
        background-color: ${colors.util.gray.lighter};
        color: ${colors.util.primary.base};
        &:focus {
            ${colors.util.primary.darker};
        }
        svg path {
            fill: ${colors.util.primary.base};
        }   
        &:hover img ,
        img {
            transform: scale(0.95);
        }
    `}

    ${Transitions().easeOut}
    border-radius: 2px;
    width: fit-content;

    ${props => props.bold && css`
        font-weight: bold;
        color: #212121;
    `}

    ${props => props.disabled && css`
        opacity: 0.6;
        pointer-events: none;
    `}
   
    ${props => props.noVal && css`
        opacity: 0.2;
        &:hover {
            opacity: 0.6;
        }
    `}

    ${props => props.float && css`
        position: absolute!important;
        right: -10px;
        transform: scale(0.8);
        transform-origin: center center;
    `}
    ${props => props.round && css`
        height: 25px !important;
        max-height: 25px !important;
        min-height: 25px !important;
        width: 25px !important;
        max-width: 25px !important;
        min-width: 25px !important;

        border-radius: 50%;
    `}
    ${props => props.clear && css`
        
        border: 1px solid ${colors.util.red.lighter}!important;
        background: #fff;

        &:hover {
            transform: scale(0.9);
            border: 1px solid ${colors.util.bright.red}!important;

            svg path {
                fill: ${colors.util.bright.red}!important;
            }
        }
    `}
    ${props => props.ghost && css`
        border: none;
    `}
    ${props => props.cell && css`
        margin-top: 5px !important;
        color: red;
    `}
    ${props => props.double && css`
        text-align: center;
        flex-wrap: wrap;
        height: 45px;
        max-height: 50px !important;
        font-size: 10px;
        *:not(svg) {
            display: block;
            width: 100%;
        }
    `}
    ${props => props?.lines >= 2 && css` 
        text-align: center;
        flex-wrap: wrap;
        max-height: none!important;
        *:not(svg) {
            display: block;
            width: 100%;
        }
    `}


    ${CenterCenter}
    ${layout}
    ${space}
    ${color}
`;
export const Buttons = styled.div` 
    ${Button} {
        display: inline-block;
    }

    ${props => props.right && css` 
        float: right;
        margin-left: auto;
        width: fit-content;
    `}
    
    ${layout}
    ${space}
    ${color}
`;

const ButtonStyles = {
    Button,
    Buttons,
}
export default ButtonStyles;