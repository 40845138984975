import { isNumber } from "@util/Num"

export const addonsMap = [{
    title: 'Custom Domain Slot',
    id: 'prod_MOVaBXHbflxhis',
    category: 'addon',
    currency: 'EUR',
    price: 350,
    priceNotice: 'per additional slot/month',
    value: 'domain',
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 3,
        enterprise: 99,
    },
    label: "Connect additional custom domains to your Mosanic account.",
    points: [
      "Pro Plan and above only",
    ],
  },{
    title: 'Page views',
    id: 'prod_MOVeliWIc6kXTv',
    category: 'addon',
    currency: 'EUR',
    price: 600,
    priceNotice: 'per 10K visitors/month',
    metric: {
      amount: 10,
      metric: 'K',
      category: 'visitors',
    },

    value: 'views',
    limit: {
        starter: 0,
        basic: 1,
        advanced: 2,
        pro: 99,
        enterprise: 99,
    },
    label: "Mosanic handles your hosting and delivers your sites pages fast. To ensure fair use we do this with a generous packages and offer extension.",
    points: [
      "Basic Plan and above",
    ],
  },{
    title: 'Bulk page views',
    id: 'prod_MOVflYtUNFR30O',
    category: 'addon',
    currency: 'EUR',
    price: 1250,
    priceNotice: 'per 25K visitors/month',
    metric: {
      amount: 25,
      metric: 'K',
      category: 'visitors',
    },
    value: 'bulk-views', //Todo --> add new field for 'general' type/ ref to membership
    limit: {
        starter: 0,
        basic: 0,
        advanced: 1,
        pro: 99,
        enterprise: 99,
    },
    label: "Discounted page views for users who really need them. ",
    points: [
      "Advanced Plan and above only",
    ],
  },{
    title: 'Basic CMS Entries',
    id: 'prod_MOVfzHXK4yhNx1',
    currency: 'EUR',
    category: 'addon',
    price: 450,
    "price_data": {
    recurring: {
      interval: 'month',
      "interval_count": 1
    },
    // "tax_code": "txcd_10102000",
    "tax_behavior": "exclusive",
  },
    priceNotice: '1 * 250 Entries/month',
    value: 'basic-entries',
    metric: {
      amount: 250,
      metric: '',
      category: 'entries',
    },
    limit: {
        starter: 0,
        basic: 1,
        advanced: 0,
        pro: 0,
        enterprise: 0,
    },
    color: 'green',
    label: "To get started with collections we offer a basic package.",
    points: [
      "Basic Plan only. Max 1 CMS Entry set.",
    ],
  },{
    title: 'Advanced CMS Entries',
    id: 'prod_MOVgqswetw6Knm',
    category: 'addon',
    currency: 'EUR',
    price: 550,
    priceNotice: 'per 500 Entries/month',
    value: 'advanced-entries',
    metric: {
      amount: 500,
      metric: '',
      category: 'entries',
    },
    limit: {
        starter: 0,
        basic: 0,
        advanced: 10,
        pro: 0,
        enterprise: 0,
    },
    color: 'orange',
    label: "Combined with the 1500 entries included in the advanced membership you won't be running out of them any time soon.",
    points: [
      "Advanced Plan only. Max 10 CMS Entry sets.",
    ],
  }, {
    title: 'Pro CMS Entries',
    id: 'prod_MOVh3np7DCeZLc',
    category: 'addon',
    currency: 'EUR',
    price: 750,
    priceNotice: 'per 1K Entries/month',
    value: 'pro-entries',
    metric: {
      amount: 1,
      metric: 'K',
      category: 'entries',
    },
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 25,
        enterprise: 99,
    },
    color: 'primary',
    label: "Expand and transform all of your data to collections. Starting from the 2500 included entries of the Pro membership.",
    points: [
      "Pro Plan only. Unlimited CMS Entry sets.",
    ],
  }, {
    title: 'Storage',
    id: 'prod_MOVhuTXgyHg3eu',
    category: 'addon',
    currency: 'EUR',
    price: 1000,
    priceNotice: 'per 25GB/month',
    value: 'storage',
    metric: {
      amount: 25,
      metric: 'GB',
      category: 'storage',
    },
    limit: {
        starter: 0,
    },
    label: "All memberships have a generous amount of storage included in them. If this ain't enough you can upgrade without a limit.",
    points: [
      "All plans",
    ],
  },{
    title: 'Bandwidth',
    id: 'prod_MOVi8ludoo4tUY',
    category: 'addon',
    currency: 'EUR',
    price: 2000,
    priceNotice: 'per 50GB bandwidth/month',
    value: 'bandwidth',
    metric: {
      amount: 25,
      metric: 'GB',
      category: 'bandwidth',
    },
    limit: {
        starter: 0,
    },
    label: "Data is always delivered by super fast CDN providers and scales as your needs might exceed your membership starting data. ",
    points: [
      "All plans",
    ],
  },{
    title: 'Additional User',
    id: 'SKU-MSN22-CRO-ADD015',
    currency: 'EUR',
    category: 'user',
    priceNotice: 'per 1 extra user/month',
    value: 'users',
    limit: {
        starter: 0,
        basic: 7,
        advanced: 7,
        pro: 7,
        enterprise: 99,
    },
    label: "Add users to your membership, all access to your team's data with limits defined and manged from your team panel.",
    points: [
      "Price based on membership plan",
    ],
  },{
    title: 'Custom roles',
    id: 'SKU-MSN22-CRO-ADD014',
    currency: 'EUR',
    category: 'addon',
    price: 750,
    priceNotice: 'per 2 extra roles/month',
    value: 'roles',
    limit: {
        starter: 0,
        basic: 7,
        advanced: 7,
        pro: 10,
        enterprise: 99,
    },
    label: "Extend the possibilities of user roles by adding your own. You can even add restrictions inside our CMS, your Builder Canvas and Artboards, Settings and Variables.",
    cta: 'Request addon',
    points: [
      "Pro and Enterprise Plan only",
    ],
  },{
    title: 'Localization',
    id: 'SKU-MSN22-LOC-ADD015',
    category: 'addon',
    currency: 'EUR',
    priceNotice: 'on request',
    value: 'localization',
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 0,
        enterprise: 99,
    },
    color: 'gray',
    cta: 'Request addon',
    label: "White label mosanic completely, you only have to deliver a custom domain, we take care of the hosting & setup.",
    points: [
      "Enterprise Plan only",
    ],
  }, {
    title: 'Private connections (API)',
    id: 'SKU-MSN22-API-ADD016',
    category: 'addon',
    currency: 'EUR',
    priceNotice: 'on request',
    value: 'white-label',
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 0,
        enterprise: 1,
    },
    color: 'gray',
    cta: 'Request addon',
    label: "White label mosanic completely, you only have to deliver a custom domain, we take care of the hosting & setup.",
    points: [
      "Enterprise Plan only",
    ],
  }, {
    title: 'White label Mosanic',
    id: 'SKU-MSN22-WLB-ADD017',
    category: 'addon',
    currency: 'EUR',
    priceNotice: 'on request',
    value: 'white-label',
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 0,
        enterprise: 1,
    },
    color: 'gray',
    cta: 'Request addon',
    label: "White label mosanic completely, you only have to deliver a custom domain, we take care of the hosting & setup.",
    points: [
      "Enterprise Plan only",
    ],
  }, {
    title: 'Spaces',
    id: 'SKU-MSN22-SPA-ADD018',
    category: 'addon',
    currency: 'EUR',
    priceNotice: 'on request',
    value: 'spaces',
    limit: {
        starter: 0,
        basic: 0,
        advanced: 0,
        pro: 0,
        enterprise: 99,
    },
    color: 'gray',
    cta: 'Request addon',
    label: "White label mosanic completely, you only have to deliver a custom domain, we take care of the hosting & setup.",
    points: [
      "Enterprise Plan only",
    ],
  }
]

 export const getAddon = (key) => isNumber(key) ? addonsMap[key - 1] : addonsMap.filter(addon => addon.value === key)[0]
