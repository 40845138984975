import { dataActions, itemSelector } from "@redux/map/data";
import { copyItem } from '@mosanic/utils/data/transform';import { splitPath } from "./paths";
import { ObjectID } from "../ObjectID";
;

export const loopTroughMap = (map, components, state) => {
    
    if(map?.children) map?.children.map(child => {
        if(child?._id) {
            components = {
                ...components,
                [child._id]: itemSelector(state, child)
            } 
        }

        if(child?.children) components = loopTroughMap(child, components, state);
    })
    return components;
}

export const copyAndTransformItemMap = (itemMap, dispatch) => {
    if(!itemMap || !Array.isArray(itemMap)) return itemMap;

    //Recursive copy data method for children
    const convertAndCopyItemMap = (child) => {
        const {itemMap, component} = copyItem({item: child})
        dispatch(dataActions.copyDataAction({component}, child?._id, true));
        return itemMap;
    }

    //Return first child of the converted itemMap
    return convertItemMap(itemMap, convertAndCopyItemMap)[0];
}

  
export const convertItemMap = (children = null, transform = (child) => child) => {

    if (Array.isArray(children) && (children.length >= 1)) {

        return children.map(child => {
            if(!child?._id) return child;

            const itemMap = transform(child)

            return {
                ...itemMap,
                children: itemMap?.children ? 
                    convertItemMap(itemMap.children, transform) : 
                    undefined
            }
        })

    } 
    return children;
}


export const getPreSaveLayoutMap = (map, path) => {
    const splittedPath = splitPath(path)
    const itemMap = map?.[splittedPath?.[0]];
    return itemMap;
}
export const convertPreSaveLayout = (storedData, itemMap) => {
    //Local data array
    let local = {}
    let count = 0

    //Get child from global storage
    const getChild = (child) => storedData?.[child?.group]?.[child?._id]
    const addChild = (child, newId) => {
        if(!child?.group) return child;
        local = {
            ...local, 
            [child.group]: {
                ...local?.[child.group],
                [newId]: {
                    ...getChild(child),
                    _id: newId
                }
            }
        }
        return local;
    }

    const convert = child => {
        count ++;
        const newId = ObjectID();
        //Regenerate a new local data map 
        addChild(child, newId);
        //Return transformed child map
        return {...child, _id: newId}
    }
    const looped = convertItemMap(itemMap?.children, convert)

    const newId = ObjectID();

    //Get source component by using item map _id and group
    const component = {
        ...getChild(itemMap),
        _id: newId
    };

    //Combine new item map with source item map and the new parent _id
    const map = {
        ...itemMap,
        _id: newId,
        children: looped
    };

    //Finally add the parent component to the local data tree
    const data = addChild(itemMap, newId);

    return [
        map,
        data,
        component,
        count
    ]
}