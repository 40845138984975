
import { useEffect, useState } from 'react';

import { Button } from '@mosanic/fields';
import { SelectLocales } from '@mosanic/i18n';
import { Expander, InputGroup, Label, LoadingDots } from '@mosanic/items';
import { useRouter } from 'next/router';
import { useForm } from "react-hook-form";

import { Input, LocalizedInput } from '@mosanic/fields/Input/Input';
import { InlineGroup } from '@mosanic/items/Inputs';
import { extendClone } from '@mosanic/utils/clone';
import { isString, revertDevCases } from "@util/Text";

import { 
    getFieldOptions, getFieldRelation, shouldPassData, shouldPassSchema 
} from '../../fields/Source/helpers';
import { PreviewDynamicEntry } from './PreviewEntry';
import { userIdSelector, userSelector } from '@redux/auth/reducer';
import { useSelector } from 'react-redux';
import { getDevModePrefSelector } from '@redux/options/reducer';
import { useGetUser } from '@mosanic/cms/Query/byId';

 

export const EntryFields = ({
    collection,
    schema,
    initial,
    onSubmit,
    type,
    dataType,
    loading,

    disabled = false,
    formDisabled = false,
    hideDisabled = false,
    inputGroup = {
        border: false,
        label: null,
        cols: 1
    },
    preview = false,

    hideMeta = true,
    submitTrigger = null,
    smallSubmit = false,
    schemaButtons = null,
    replaceRouter = false,
}) => {

    const primaryField = schema?.[0]?.name;
	const hasLocalizedFields = schema?.find(s => s?.localized)

    //Language support
	const router = useRouter()
	const {locales, locale, asPath: path} = router;
	const [activeLang, setActiveLan] = useState(locale);

    const setLang = (lang, replaceRouter) => {
		setActiveLan(lang);
		if(replaceRouter)
			router.push(path, path, { locale: lang, shallow: true });
	}

    const { handleSubmit, control, watch, reset, formState: { errors, isValid } } = useForm({mode: 'all'});
    const inputProps = {control, errors, fullWidth: true, preview}

	//Reset handling
	useEffect(() => {
		if( initial && (
			watch()?.[primaryField] != initial?.[primaryField] || 
			initial?._id != watch()?._id
        )) {
            reset({...initial});
        }
	}, [initial, primaryField, watch])
 

      const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const devMode = useSelector(getDevModePrefSelector)

    return (
        <PreviewDynamicEntry
			collection={collection}
			preview={preview}
			entry={watch()}
		>
        {/* {(user?.isAdmin && devMode) && (
            <Expander trigger="Entry Data">
                <pre>
                    {JSON.stringify(watch(), null, 2)}
                </pre>
            </Expander>
        ) } */}
        <form disabled={formDisabled} onSubmit={handleSubmit(onSubmit)}>
            <SelectLocales locales={hasLocalizedFields ? locales : null} active={activeLang} setActive={setLang} />
            <InputGroup fullWidth  style={{width: '100%'}} {...inputGroup}>
                {collection?.schema?.map((field, index) => (!field?.name) ? null : (
                    <LocalizedInput
                        disabled={disabled}
                        {...{
                            locales, 
                            locale,
                            activeLang
                        }}

                        hideDisabled={hideDisabled}
                        name={field?.name} 
                        label={revertDevCases(isString(field?.label) ? field.label : field?.name)}
                        type={field?.type} 
                        // value={field?.default} 
                        field={field}
                        help={field?.help}
                        options={getFieldOptions(field)}
                        multiple={field?.multiple}
                        relation={getFieldRelation(field)}
                        schema={shouldPassSchema(field) ? collection.schema : null}
                        data={shouldPassData(field) ? watch() : null}
                        preview={preview}
                        {...inputProps}
                        fullWidth={field?.fullWidth ? field.fullWidth : false}
                    />
                ))}
            </InputGroup>

            {Boolean(
                (schemaButtons?.filter(b => b?.action === type)?.length >= 1 ||
                type === 'preview' 	) && !submitTrigger
            ) ? (
                null 
            ) : submitTrigger ? ( 
                extendClone({
                    type: 'submit'
                }, submitTrigger) 
            ) : (
                <Button 
                    width={smallSubmit ? null : [1, '100%']}
                    style={{margin: '20px 0 0 0'}}
                    label={loading ? <LoadingDots /> : 
                        dataType === 'global' ? 'Update' : type === 'create' ? "Add entry" : "Update entry"
                    }
                    theme="primary"
                    size={smallSubmit ? 'small' : "large"}
                    type="submit"
                    disabled={loading || !isValid || type === 'preview'}
                />
            )}

            {user?.isAdmin && (
                <Expander trigger={<Button label="Admin controls" ghost style={{minWidth: '100%'}} m={0} />}>
                <InputGroup border label="Admin controls" mb={4}>
				    <Input type="user" name="userId" {...inputProps} />
                    {(!hideMeta && type != 'create') && (
                        <InputGroup border label="Admin controls" mb={4}>
                    <Expander 
                        trigger={(
                        <Button label="Entry meta" ghost fullWidth 
                            width={smallSubmit ? null : [1, '100%']}
                            style={{margin: '0 0 10px 0'}}
                        />
                    )}>
                        <InlineGroup style={{flexWrap: 'wrap'}}>
                            <Label label="Entry id" value={initial?._id} />
                            <Label label="Entry author" value={initial?.userId} />
                            {initial?.collectionId && <Label label="Collection ID" value={initial?.collectionId} />}
                        </InlineGroup>
                    </Expander>
                    </InputGroup>
                    )}

                </InputGroup>
                </Expander>
			)}

        </form>
        </PreviewDynamicEntry>
    )
}