import { Fragment, useState } from 'react';

import { Button } from '@mosanic/fields';
import { useFieldArray, useFormContext } from "react-hook-form";

import { PAGES } from '@api/queries';
import { circlePlus } from '@mosanic/Icons/EditIcons';
import { useGet } from '@mosanic/cms';
import { AdvancedLabel } from '@mosanic/items';
import { ObjectID } from '@mosanic/utils';
import { hasAttr } from '@util/Obj';
import { DataProvider, useDataProvider } from '../Provider';
import { CollectionField } from './Field/CollectionField';
import { FieldControls } from './FieldControls';
import { FieldDragItem, FieldsDragContext } from './FieldsDragWrapper';
import { useFieldFilters } from './FieldsFilters';
import { useCollectionSelector } from './useCollections';





import { Condition } from '@JSX';
import { useGetUser } from '@mosanic/cms/Query/byId';
import { userIdSelector } from '@redux/auth/reducer';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { FieldHeaderLabels } from './Field/Header';

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })
export const FieldsCollection = ({ctx, id = 'schema', level = 0, type = 'field', skipFirstField = false}) => {
	// const PRO = useDataProvider()
	const collections = useCollectionSelector()
	const {users} = useDataProvider() || {users: null}
	const [getP, [zones]] = useGet({
		query: PAGES,
		type: 'page',
		method: 'Many',
		silent: true,
		params: {filter: {
			type: 'zone'
		}}
	})
	const { control, setValue, watch, formState: { isValid } } = useFormContext();
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		name: id, 
		// control
	});

	const [expanded, setExpanded] = useState({});
	const toggle = (i) => setExpanded({
		// ...expanded,
		[i]: hasAttr(expanded, i) ? !expanded?.[i] : true
	})

	const addItem = () => append({ id: ObjectID(), type: "text", maxDepth: 0})


	const [filterGroups, filters, inFilters, setFilter] = useFieldFilters({watch})

	  const userId = useSelector(userIdSelector)
    const [user_data] = useGetUser({_id: userId})
	const user = {
		...user_data,
		pref: undefined,
		sites: undefined,
		teams: undefined,
		notifications: undefined,
		security: undefined
	}
	const parent = useDataProvider()
	return (
		<Condition when={level === 0} wrapper={children => (
			<DataProvider data={{collections, zones, filters, users, ctx, user}}>
				{children}
			</DataProvider>
		)}>


		<FieldHeaderLabels level={level != 0 ? 'Sub' : null}/>

		<FieldsDragContext id={id} move={move}>
			{fields.map((field, index) => inFilters(field) && (
				<FieldDragItem index={index} id={field?.id ? field.id : index}
					render={dragHandle => (
						<CollectionField 
							skipFirstField={skipFirstField}
							dragHandle={dragHandle}
							id={id}
							fieldId={`${id}.${index}`}
							expanded={expanded}
							toggle={toggle}
							index={index}
							move={move}
							remove={remove}
							fieldCount={fields?.length}
							level={level}
						/>
					)}
				/>
			))} 

			
		</FieldsDragContext>	
			<Button
				mt={4}
				type="button"
				onClick={addItem}
			>
				{circlePlus} &nbsp; {fields?.length >= 1 ? `Append ${type}` : `Add first ${type}`}
			</Button>
		</Condition>
	);
};

export const StepsCollection = ({id = 'schema', level = 0, type = 'field'}) => {
	
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		name: id, 
	});
	const steps = fields

	const [expanded, setExpanded] = useState({});
	const toggle = (i) => setExpanded({
		// ...expanded,
		[i]: hasAttr(expanded, i) ? !expanded?.[i] : true
	})

	const { control } = useFormContext();
	


	return (
		<Fragment>
	
			{steps.map((field, index) => (
				<div key={index}>
					<div style={{position: 'relative', height: '50px'}}>
						<AdvancedLabel label={`step ${index + 1}`}/>
						<FieldControls
							remove={remove}
							move={move}
							index={index}
							count={null}
						/>
					</div>
					<FieldsCollection 
						id={`${id}.${index}`}
						level={level + 1}
					/>
				</div>
			))}
			<Button
				mt={4}
				type="button"
				onClick={() => append({ type: "text", name: "identifier", help: "", value: ObjectID()})}
			>
				{circlePlus} &nbsp; {fields?.length >= 1 ? `Append ${type}` : `Add first ${type}`}
			</Button>
			
		</Fragment>
	);
};
