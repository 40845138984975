export const FieldTypes = [
    'metricInput',
    'incrementInput',
    'transform',
    'bezier',
    'font',

    'imageUrls',
    // 'gallery',
    'imageUrl',
    // 'image',
    // 'markedimage',

    'videoUrl',
    // 'video',

    'focal',
    'icon',
    
    'choice',
    // 'checkbox',
    // 'radio',

    'select',
    'toggle',
    // 'switch',


    'btn',
    // 'image',
    'number',
    'string',
    // 'text',
    // 'textarea',
    'content',

    'code',
    'slider',
    'link',

    'colorPlus',
    'editor',
    'color',
    'gradient',

    //Data links
    'entry',
    'collection',
    // 'relation',
    
    'form',
    'inputfield',

    'dialogSelector',
    // 'date',
    // 'date-time',
    // 'time',

    //Inline
    'slot',
    'render',

    'label'
];

export const isRenderField = (type) => FieldTypes.includes(type);