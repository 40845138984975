import { get, set } from "@util/Obj";
import produce from "immer";


const getIndexDetails = ({title = null, label = null, help = null, slug, data}) => ({
    title: data?.title ? data.title : title,
    label: data?.description ? data.description : data?.label ? data.label : label,
    help: data?.help ? data.help : help,
    slug: data?.slug ? data.slug : slug
})
const getEmptyDetails = ({title = null, label = null, help = null, slug, data}) => ({
    title: data?.title ? data.title : title,
    label: data?.description ? data.description : data?.label ? data.label : label,
    help: data?.help ? data.help : help,
    slug: data?.slug ? data.slug : slug
})


const getSubDataTableSchema = base => ({
    ...base,
    props: {
        ...base.props,
        info: null,
        level: base.props.level + 1
    }
})

export const combineIntoDataTableSchema = ({dataTableSchema, config, data}) => produce(dataTableSchema, base => {
    base.singular = data?.singular ? data.singular : get(base, 'props.singular');
    base.multiple = data?.multiple ? data.multiple : get(base, 'props.multiple');
    base.info = getIndexDetails({data, ...get(base, 'props.info')});

    base.props.level = base.props?.level ? base.props?.level : 1;
    base.props.empty = getEmptyDetails({data, ...get(base, 'props.info')})

    base.dataTable = getSubDataTableSchema(base);
})

export const updateSchemaProp = ({schema, path, data}) => produce(schema, base => {
    set(base, path, data)
})


export const updateSlug = (schema, slug = null) => updateSchemaProp({
    schema, 
    path: 'props.info.slug', 
    data: slug
})

const removePaths = ({schema, paths}) => {
    paths.map(path => {
        schema = updateSchemaProp({schema, path, data: null})
    })
    return schema
}

export const removeSchemaInfo = schema => updateSchemaProp({
    schema: (
        removePaths({
            schema,
            paths: ['props.info', 'props.empty'],
        })
    ),
    path: 'props.level',
    data: 4
})