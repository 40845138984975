

import { colors } from "@mosanic/styles";
import { darken } from "polished";
import styled, {css} from "styled-components";

const inspectedStyle = css` 
  color: ${colors.util.primary.dark};
  border: 1px solid ${colors.util.primary.dark};
  background: ${colors.util.primary.lightest};
`
const hoverStyle = css` 
  color: #000;
  border: 1px dashed ${colors.util.primary.base};
  border-left-style: solid;
`
const Label = styled.div` 

    /* color: ${props => props.isOpen ? '#111' :'#999'}; */
    opacity: ${props => props.isOpen ? '1' :'0.6'};
    transition: all ease-in-out 0.15s;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    padding: 2.5px 0 5px 5px;
    border: 1px solid rgba(0,0,0,0);
    /* border: 1px solid red; */
    margin-bottom: 2.5px;

    svg {
      margin: 0 5px 2.5px 0;
      opacity: 0.2;
    }
    :hover {
      ${hoverStyle}
    }
    ${props => props.hovered && css`
       ${hoverStyle}
       svg {
        opacity: 0.7;
        path {
          color: #000;
        }
      }
    `}

    ${props => props.active && css`
      ${inspectedStyle}
      svg {
        opacity: 1;
      }
    `}
`;

const DropZone = styled.div` 
    height: 5px;
    max-height: 5px;
    left: 0!important;
    right: 0;
    position: absolute!important;
`
const Layer = styled.div` 
  position: relative;
  margin-left: ${props => ((props.level - 1) * 10)+'px'};
  /* border-left: 1px solid rgba(0,0,0,0.2); */
  ${props => props.hasChildren && css` 
    border-left: 1px solid rgba(0,0,0,0.4);
    /* border-top: 1px solid rgba(0,0,0,0.4); */
  `}
`;
const Render = styled.div` 
    /* border: 1px solid; */
    /* border-top: none; */
    /* border-right: none; */
    /* border-color: ${props => darken(props.level / 20, '#eee')}; */
    padding-right: ${props => ((props.level) * 5)+'px'};
    margin-left: ${props => ((props.level) * 5)+'px'};
    /* margin-bottom: 10px; */
    
`;

export const LayerLabel = ({children, ...other}) => (
  <Layer {...other}>
    <Label>
    {children}
    </Label>
  </Layer>
)

const LayerStyles ={
    Label,
    Layer,
    Render,
    DropZone,
}
export default LayerStyles;