

import { useGetSite, useGetUser } from "@mosanic/cms/Query/byId";
import { UserCard } from "@mosanic/components/Users";
import { AdvancedLabel, ControlPopper, Link } from "@mosanic/items";
import { RenderList } from "@mosanic/items/List";
import { removeAuthAction } from "@redux/auth/action";
import { isAuthSelector, siteIdSelector, userIdSelector } from "@redux/auth/reducer";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

const groupStyle = {margin: '30px 0', background: '#fff', padding: '20px', borderRadius: '8px', border: '1px solid #eee'}


export const UserComponent = ({fields, ref, editor, active = false, clone}, children) => {
    const router = useRouter()
    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})

    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})

    const isAuth = useSelector(isAuthSelector)

    const placement = undefined;

    const dispatch = useDispatch()
    const removeAuth = () => dispatch(removeAuthAction({redirect: '/login'}, 'Component logout'))


    // const paths = 'register|login|reset'.split('|').includes(router?.query?.slug?.[0])



    const loggedIn = [(
    <Link key="logout"
      href={'/login'}
      onClick={removeAuth}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M22.829 9.172 18.95 5.293a1 1 0 0 0-1.414 1.414l3.879 3.879a2.057 2.057 0 0 1 .3.39c-.015 0-.027-.008-.042-.008L5.989 11a1 1 0 0 0 0 2l15.678-.032c.028 0 .051-.014.078-.016a2 2 0 0 1-.334.462l-3.879 3.879a1 1 0 1 0 1.414 1.414l3.879-3.879a4 4 0 0 0 0-5.656Z"/><path d="M7 22H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h2a1 1 0 0 0 0-2H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h2a1 1 0 0 0 0-2Z"/></svg>
      Logout
    </Link>
  ), {
      href: '/account',
      icon: <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="22" height="22"><path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z"></path><path d="M20,0H17a4,4,0,0,0-4,4V7a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z"></path><path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z"></path><path d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z"></path></svg>,
      label: 'Dashboard'
  }];

  const loggedOut = [{
    href: '/login',
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M17 0H7a5.006 5.006 0 0 0-5 5v15a4 4 0 0 0 4 4h11a5.006 5.006 0 0 0 5-5V5a5.006 5.006 0 0 0-5-5Zm3 5v11H8V2h9a3 3 0 0 1 3 3ZM6 2.172V16a3.98 3.98 0 0 0-2 .537V5a3 3 0 0 1 2-2.828ZM17 22H6a2 2 0 0 1 0-4h14v1a3 3 0 0 1-3 3Z"/></svg>,
    label: 'Login'
  }]


    const siteConfig = site?.options?.users;
    // const {
    //     platformName,
    //     register,
    //     login
    // } = siteConfig?.content || {
    //     platformName: 'Mosanic', 
    //     login: null, 
    //     register: null
    // };

    const accountItems = [
    ...(isAuth ? loggedIn : loggedOut),
    ]

    const {firstName, lastName} = user || {firstName: '', lastName: ''};

    return (
        <>
        <ControlPopper width="175px" p={2} placement={placement} trigger={(
            <UserCard width="100%" height="100%" mr={0} user={!placement ? {firstName, lastName, profilePicture: user?.author?.profilePicture} : {firstName, lastName: ''}}/>
        )}>
        <AdvancedLabel as="h3" pl={2} span helpText="We're working on amazing new features right now! Missing something? Submit a feature request.">
            Account actions  
        </AdvancedLabel>
        <RenderList items={accountItems} size="small" />      
        </ControlPopper>
        </>
    )


}