
export const FONT_SCALES = [
        {value: 1.067, label: '1.067 - Minor Second'},
        {value: 1.125, label: '1.125 - Major Second'},
        {value: 1.200, label: '1.200 - Minor Third'},
        {value: 1.250, label: '1.250 - Major Third'},
        {value: 1.333, label: '1.333 - Perfect Fourth'},
        {value: 1.414, label: '1.414 - Super Fourth'},
        {value: 1.500, label: '1.500 - Perfect Fifth'},
        {value: 1.618, label: '1.618 - Golden Ratio'},
    ]


export const DEFAULT_FONTS = [
        {
        //     type: 'title',
        //     heading: true,
        //     text: false,
        //     label: 'Title',
        //     font: 'Open Sans',
        //     scale: 6.5,
        //     size: '16',
        //     metric: 'px',
        //     color: 'shades',
        //     shade: '40',
        //     bold: false,
        //     weight: null,
        //     italic: false,
        //     underline: false,
        //     class:'title',
        //     lorem: 'For super clients.'
        // },{
            type: 'h1',
            heading: true,
            label: 'Heading 1',
            font: 'Open Sans',
            scale: 5,
            size: '16',
            metric: 'px',
            lorem: 'For power clients.'
        },{
            type: 'h2',
            heading: true,
            label: 'Heading 2',
            font: 'Open Sans',
            scale: 4,
            size: '16',
            metric: 'px',
            lorem: 'For more power clients.'
        },{
            type: 'h3',
            heading: true,
            label: 'Heading 3',
            font: 'Open Sans',
            scale: 3,
            size: '16',
            metric: 'px',
            lorem: 'For more power clients.'
        },{
            type: 'h4',
            heading: true,
            label: 'Heading 4',
            font: 'Open Sans',
            scale: 2,
            size: '16',
            metric: 'px',
            lorem: 'For more power clients.'
        },{
            type: 'h5',
            heading: true,
            label: 'Heading 5',
            font: 'Open Sans',
            scale: 1,
            size: '16',
            metric: 'px',
            lorem: 'For more power clients.'
        },{
            type: 'h6',
            heading: true,
            label: 'Heading 6',
            font: 'Open Sans',
            scale: 0,
            size: '16',
            metric: 'px',
            lorem: 'For more power clients.'
        },{
            type: 'p',
            text: true,
            label: 'Paragraph',
            font: 'Open Sans',
            size: '16',
            metric: 'px',
            text: 'Paragraph',            
            lorem: 'Lorem ipsum dolar samit dola.'
        },{
            type: 'p',
            text: true,
            label: 'Small paragraph',
            font: 'Open Sans',
            scale: -1,
            size: '16',
            metric: 'px',
            class: 'small',
            lorem: 'Lorem ipsum dolar samit dola.'
        },{
        //     type: 'span',
        //     text: true,
        //     heading: false,
        //     label: 'Span tag',
        //     font: 'Open Sans',
        //     scale: 0,
        //     size: '16',
        //     metric: 'px',
        //     color: 'shades',
        //     shade: '40',
        //     bold: false,
        //     weight: null,
        //     italic: false,
        //     underline: false,
        //     lorem: 'Span',
        // },{
            type: 'b',
            text: true,
            label: 'Bold tag',
            font: 'Open Sans',
            scale: 0,
            size: '16',
            metric: 'px',
            bold: true,
            lorem: 'Bold',
        },{
            type: 'i',
            text: true,
            label: 'Italic tag',
            font: 'Open Sans',
            scale: 0,
            size: '16',
            metric: 'px',
            italic: true,
            lorem: 'Italic',
        },{
            type: 'u',
            text: true,
            label: 'Underline tag',
            font: 'Open Sans',
            scale: 0,
            size: '16',
            metric: 'px',
            underline: true,
            lorem: 'Underline',
        },{
            type: 'button',
            text: true,
            label: 'Button',
            font: 'Open Sans',
            scale: -1,
            size: '16',
            metric: 'px',
            lorem: 'Button text',
        },{
            type: 'a',
            text: true,
            label: 'Link tag',
            font: 'Open Sans',
            scale: 0,
            size: '16',
            metric: 'px',
            lorem: 'Link',
        }
    ];
