import { hasAttr } from "@util/Obj";
import { error } from "./ErrorStrings"

const core = error.register.core;
const SUPPORTED_REGISTER_TYPES = ['component', 'fieldset', 'field'];

export const validateRegister = ({schema, type}) => {
  if(!SUPPORTED_REGISTER_TYPES.includes(type)) console.error(core.type);

  if(hasAttr(schema, 'name')) console.error(core.name);
  if(hasAttr(schema, 'props')) console.error(core.props);
}  