import { MAIN_SHADE } from "@mosanic/constants/colors";
import { toVarCase } from "@mosanic/utils";


export const COLOR_ID                 = `[colors]`;

export const RESET_TO_BASE      = `${COLOR_ID} Reset base`;
export const PALETTE_SHADE      = `${COLOR_ID} Toggle palette s`
export const CUSTOM_SHADE      = `${COLOR_ID} Custom shade`

export const REGENERATE_HUE      = `${COLOR_ID} Regenerate hue`;
export const SET_SATURATION      = `${COLOR_ID} Set saturation`;
export const SHADE_COUNT         = `${COLOR_ID} Shade count`;

export const UPDATE_COLOR      = `${COLOR_ID} Update color`;
export const UPDATE_HUE         = `${COLOR_ID} Update Hue`;


export const CREATE_DESIGN_COLOR       = `${COLOR_ID} Create`;
export const SET_DESIGN_COLOR          = `${COLOR_ID} Set`;
export const UPDATE_DESIGN_COLOR       = `${COLOR_ID} Update`;
export const DELETE_DESIGN_COLOR       = `${COLOR_ID} Delete`;

const SET_COLORS = `${COLOR_ID} Set colors`;

export const COLOR_ACTION_IDS = {
    RESET_TO_BASE,

    PALETTE_SHADE, //toggle
    CUSTOM_SHADE,

    SHADE_COUNT,
    REGENERATE_HUE,
    UPDATE_HUE,

    SET_SATURATION,

    SET_COLORS
}
export const setColorsAction = (data) => ({
    type: SET_COLORS,
    payload: data,
    meta: {feature: COLOR_ID,}
})

export const setSaturationAction = (saturation, hue = null, shadeIndex = MAIN_SHADE) => ({
    type: SET_SATURATION,
    payload: {
        index: shadeIndex,
        saturation, 
        hue
    },
    meta: {feature: COLOR_ID,}
})
export const updateShadeCountAction = (hue, operator = '+') => ({
    type: SHADE_COUNT,
    payload: {hue, operator},
    meta: {feature: COLOR_ID,}
})
export const updateHueAction = (hue) => ({
    type: UPDATE_HUE,
    payload: hue,
    meta: {feature: COLOR_ID,}
})

export const togglePaletteShadeAction = (hue, shade) => ({
    type: PALETTE_SHADE,
    payload: {
        hue, shade
    },
    meta: {feature: COLOR_ID,}
})

export const updateColorAction = (hue, type, color) => ({
    type: CUSTOM_SHADE,
    payload: {
        hue, type, color
    },
    meta: {feature: COLOR_ID,}
})

export const regenerateHueShadesAction = (hue, mainShade, saturation = null) => ({
    type: REGENERATE_HUE,
    payload: {hue, mainShade, saturation},
    meta: {feature: COLOR_ID,}
})

export const resetColorsAction = () => ({
    type: RESET_TO_BASE,
    meta: {feature: COLOR_ID,}
})





export const createDesignColor = ({label, value, endValue, type = "metric"}) => ({
    type: CREATE_DESIGN_COLOR,
    payload: {
        label, value, endValue, type
    },
    meta: {feature: COLOR_ID,}
});

export const setDesignColor = ({color, _id, meta = null}) => ({
    type: SET_DESIGN_COLOR,
    payload: {...color, _id},
    meta: {feature: COLOR_ID, ...meta}
});

export const deleteDesignColor = ({_id}) => ({
    type: DELETE_DESIGN_COLOR,
    payload: _id,
    meta: {feature: COLOR_ID}
});

export const updateDesignColor = (color) => ({
    type: UPDATE_DESIGN_COLOR,
    payload: color,
    meta: {feature: COLOR_ID}
});
export const updateDesignMetricColor = (color, label, metric) => ({
    type: UPDATE_DESIGN_COLOR,
    payload: {
        ...color, 
        label, 
        value: metric.value, 
        endValue: metric.metric, 
        selector: toVarCase(label)
    },
    meta: {
        feature: COLOR_ID, 
        dispatched: 'updateDesignMetricColor'
    }
});

