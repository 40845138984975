import { CenterCenter, colors } from "@mosanic/styles";
import styled, { css } from "styled-components";


const MediaContent = styled.div` 
    z-index: 1;
`
const SideBarImage = styled.div` 

    video {
        width: 100%;
        height: 100%;
    }
`
const MediaSideBar = styled.div` 
    padding: 0 10px;
    top:0;
    /* position:absolute; */
    right: 0;
    height: 100%;
    min-height: 70vh;
    border-left: 1px solid ${colors.util.gray.light};
    width: 30%;
    float: left;
    max-height: none;

    ${props => props.dialog && css` 
        min-height: 0;
        border-left: 0;
        padding: 0;
        width: 100%;
        padding: 0;

        ${SideBarImage} {
            ${CenterCenter}
            width: 50%;
            height: 100%;
            float: left;
        }
        ${MediaContent} {
            padding-left: 25px;
            width: 50%;
            float: left;
        }
    `}
`

const MediaItem = styled.div` 
    transition: all ease-in-out 0.4s;
    background-color: ${colors.util.gray.lightest};
    /* display: inline-block; */
    vertical-align: top;
    max-width: 48%;
    height: auto;
    
    height: 200px;
    width: 200px;

    @media (min-width: 1000px) {
        height: 200px;
        width: 23%;
        height: 170px;
    }
    @media (min-width: 1400px) {
        height: 200px;
        width: 18%;
        height: 190px;
    }

    ${props => props.size === 'small' && css` 
        height: 90px;
        width: 90px;
    `}
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 1% 10px;
    cursor: pointer;
    border: 1.5px solid ${colors.util.gray.light};
    position: relative;

    &:hover {
      border: 1.5px solid ${colors.util.primary.base};
    }
    ${props => props.active && css` 
        border: 1.5px solid ${colors.util.primary.base};
        transform: scale(1.02);
    `}

    ${props => props.isLoading && css` 
        &, &:hover {
            border: 1.5px solid transparent;
            cursor: default;
        }
    `}
    video {
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

`
const MediaItems = styled.div` 
    width: 70%;
    /* padding: 25px 10px; */
    float: left;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
    /* background: ${props => props.theme.colors.light}; */
    
const Media = styled.div` 
    min-height: 70vh;
    height: auto;
    display: block;
    ${props => props.dialog && css` 
        min-height: 100px;

        ${MediaItems} {
            width: 100%;
            padding: 0;

            ${MediaItem} {
                min-height: 100px;
            }
        }
    `}
`
const Clear = styled.div` 
    clear: both;
    display: block;
    content: '';
    position: relative;
    min-height: 2vh;
`

const MediaStyles = {
    Clear,
    Media,
    MediaItems,
    MediaSideBar,
    SideBarImage,
    MediaContent,

    MediaItem
}
export default MediaStyles;