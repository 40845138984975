import { Condition, If } from "@JSX";
import { getDropzoneData, hasDropZone } from "@mosanic/utils/map/dropZone";
import { isDevMode } from "@mosanic/utils/map/mode";
import { getCurrentPath } from "@mosanic/utils/map/path";
import { canMap } from "@util/Arr";
import { isServer } from "@util/Device";
import { Fragment } from "react";
import { DropZone } from "../DropZone";

import Child from "./Child";
import Render from "./Render";



const Children = ({
    render,
    dropZoneState,
    path,
    handleDrop,
    breakpoint,
    data,
    mode,
    ...other
}) => canMap(render) && render.map((child, index) => (

  <Fragment key={child?._id}>
    {( isDevMode(mode) && !isServer() ) && (
      <DropZone
        data={getDropzoneData(path, index, render)}
        onDrop={handleDrop}
        {...dropZoneState}
      />
    )}
       
    <Child 
      data={data}
      child={child}
      path={getCurrentPath(path, index)}
      level={dropZoneState.level + 1}
      breakpoint={breakpoint}
      mode={mode}
      {...other}
    >

      {/* <If condition={child?.children}> */}
      {child?.children && (
        <Render
          data={data}
          child={child}
          handleDrop={handleDrop}
          path={getCurrentPath(path, index)}
          breakpoint={breakpoint}
          level={dropZoneState.level + 1}
          mode={mode}
          {...other}
        />
      )}
    </Child>
  </Fragment>

));

export default Children;