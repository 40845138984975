import { composeFunctions } from "@util/Compose";
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import withAutoReplace from "./AutoReplace";
import withInlineElements from "./InlineElements";
import withImages from "./withImages";

const withPlugins = composeFunctions(
    withReact, 
    withHistory,
    withAutoReplace, 
    withImages,
    withInlineElements,
);


export {
    withInlineElements,
    withAutoReplace,
    withHistory,
    withReact,
};

export default withPlugins;

