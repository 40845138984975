import { ObjectID } from "@mosanic/utils";
import { setNotification } from "../notification/action";
import { hideSpinner, hideSpinnerById, showSpinner, SHOW_SPINNER, SPINNER_ID, TIMING_STOP_SPINNER, UI_ID } from "./uiAction";
import { isActive } from "./uiReducer";


/**
 * Spinner enricher
 * @returns next action
 */
export const spinnerMiddleware = ({getState}) => (next) => (action) => {
    if(action?.type?.includes(SHOW_SPINNER)) {
        const {payload, meta} = action;
        const _id             = ObjectID();
        const spinner = {[SPINNER_ID]: _id, location: payload}

        next(showSpinner({location: spinner, feature: meta.feature}))

        setTimeout(() => {
            if(isActive(getState(), payload)){
                next(setNotification({message: 'Spinner is auto terminated.', feature: UI_ID, meta: {_id, previous: meta}}))
                next(hideSpinnerById({spinnerId: _id, feature: meta.feature}))
            } else {
                // next(setNotification({message: 'Spinner is already terminated.', feature: UI_ID, meta: {_id, previous: meta}}))
            }
        }, TIMING_STOP_SPINNER);
    } else {
        next(action);
    }
}