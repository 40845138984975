
import { Fragment, useEffect } from 'react';

import { usePages } from '@mosanic/components/Pages/Resolvers/usePages';
import Input from '@mosanic/fields/Input';
import { InputGroup } from '@mosanic/items';
import { siteIdSelector } from '@redux/auth/reducer';
import { capitalizeFirst } from '@util/Text';
import { useFormContext } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useGetCollections } from '../Query';
import { useGetEntryCount } from '../Query/count';
import { isNumber } from '@util/Num';
import { Button } from '@mosanic/fields';


export const EditorBase = ({
    type = 'collection', 
    types,
    singular = 'collection', 
    hasField,
    getField,
    fields
}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } } = useFormContext();
	const inputProps = {control, fullWidth: true, errors, wrap: true}
    const siteId = useSelector(siteIdSelector)

    // todo --> pass these fields inside a 'data provider' 
    const pages = usePages({siteId, pagesOnly: true})?.map(p => ({label: p.title, value: p._id}))
    const collections = useGetCollections({siteId})?.[0]?.map(p => ({label: p?.title, value: p._id}))

    const example = type === 'collection' ? 
        "User cards are shown in the contact page" :
        "Main footer contact form";

    type = watch()?.type || type
    types = types?.filter(t => t != 'all');

    useEffect(() => {
        if(!watch()?.type && types?.length >= 1) reset({...watch(), type: types[0]})
    },[watch, types, reset])

    const [count] = useGetEntryCount({_id: watch()?._id})
    const setCount = () => reset({...watch(), entries: count})
	return (
		<Fragment>
            
            <InputGroup cols={2} border label={`${capitalizeFirst(singular)} info`} mt={0}>
                {hasField('title') && (
                    <Input 
                        name="title" label={`${singular} name`} type="text" {...inputProps} required
                        rules={{required: `Shorthand name that defines this ${singular}`}}
                    />
                )}
                {hasField('description') && (
                    <Input 
                        fullWidth
                        name="description" label="Description" type="textarea" {...inputProps} required
                        rules={{required: `Explain the purpose of this ${singular} and give some context. For example, ${example}`}}
                    />
                )}

                {fields?.filter(f => ((!f?.when || f?.when(watch())) && f?.base)).map(f => (
                    <Input {...inputProps} 
                        {...f}
                        name={f?.path ? f.path : f.name} 
                        label={f?.label} 
                        type={['page', 'collections'].includes(f?.type) ? 'select' : f.type} 
                        fullWidth={f?.fullWidth}
                        options={f?.type === 'page' ? pages : f?.type === 'collections' ? collections : f?.options}
                    />
                ))}

                {hasField('singular') && (
                    <Input 
                        name="singular" label="Singular entry name" type="text" {...inputProps} required
                        rules={{required: 'How should we call a single entry. For exmaple, articles collection => "Article"'}}
                    />
                )}

                {hasField('status') && (
                    <Input name="status" label='Status' type="select" options={getField('status')?.options} {...inputProps} />
                )}
            </InputGroup>


            {fields?.filter(f => f?.schema)?.map(g => (!g?.when || g?.when(watch())) && (
                <InputGroup key={g.name} label={g?.label} cols={g?.cols ? g.cols : 2} border>
                    {g.schema.map(f => (!f?.when || f?.when(watch())) && (
                        <Input {...inputProps} 
                            {...f}
                            name={f?.path ? f.path : `${g.name}.${f.name}`} 
                            label={f?.label} 
                            type={f?.type === 'page' ? 'select' : f.type} 
                            fullWidth={f?.fullWidth}
                            options={f?.type === 'page' ? pages : f?.options}
                        />
                    ))}
                </InputGroup>
            ))}

            {types?.length >= 2 && (
                <Input name="type" label={`${singular} type`} type="buttonGroup" options={types} {...inputProps} />
            )}
            {(isNumber(count) && count >= 1 && watch()?.entries != count )&& (
			    <Button label={`Set entry count: ${count}`} ghost style={{width: '100%'}} onClick={() => setCount()} />
            )}
            {hasField('limit') && (
			    <Input name="limit" label={type === 'collection' ? "Limit entries" : 'Limit is defined by membership package'} type="number" disabled={type != 'collection'} {...inputProps} />
            )}
            
        
            

		</Fragment>
	);
};
