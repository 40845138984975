import { collectionColumns, mailColumns, subscriptionColumns, userColumns } from "@api/schemas";

import { 
    useGetUsers, 
    useGetCollection, 
    useGetCollections
} from "../Query";

import { useDirectDataGrid, useLinkedDataGrid } from "./useMosanicData";
import { useGetEntries, useGetMails } from "../Query/many";
import { convertEntry } from "../Query/useDataQuery";


import { userHooks, collectionHooks, entryHooks, mailHooks } from "../Mutate";
import { isString } from "@util/Text";
import { useGetEntriesPage } from "../Query/pagination";
import { useGetEntryCount } from "../Query/count";

export const useUsersDataGrid = ({defaultType = 'all', all = false, props, columns, formatter}) => useDirectDataGrid({
    hookResponse: useGetUsers({current: true}),
    defaultType,
    dataHookMethods: userHooks,
    entryTypePath: 'role',
    
    headerSchema: columns,
    entryFormatter: formatter,
    props,

    title: 'users',
    singular: "site's user",
    config: {
        dialog: 'xs'
    }
})
 
export const useCollectionDataGrid = ({_id}) => useLinkedDataGrid({
    hookResponse: useGetCollection({_id}),
    dataHookMethods: collectionHooks,
    
    filtering: false,

    entriesPointer: 'entries',
    entriesReceiverParams: {filter: {collectionId: _id}, sort: '_ID_DESC'},
    useEntriesReceiver: useGetEntriesPage,
    entryHookMethods: entryHooks,
    entryCount: useGetEntryCount({_id}),
    // useGetEntriesPage,
    // useEntryUpdater: params => use

    entryFormatter: e => convertEntry(e)
})
 
export const useCollectionUserDataGrid = ({_id, userId, ...other}) => useLinkedDataGrid({
    hookResponse: useGetCollection({_id, silent: true, ...other}),
    dataHookMethods: collectionHooks,
    
    filtering: false,

    entriesPointer: 'entries',
    entriesReceiverParams: {filter: {
        OR: [
            {collectionId: _id, userId},
            {collectionId: _id, queryId: userId},
        ],
        
    }, sort: '_ID_DESC'},
    useEntriesReceiver: useGetEntriesPage,
    entryHookMethods: { 
        ...entryHooks,
        silent: true,
        update: undefined
    },
    entryCount: useGetEntryCount({collectionId: _id, filter: {userId}}),
    // useGetEntriesPage,
    // useEntryUpdater: params => use

    entryFormatter: e => convertEntry(e)
})


export const useCollectionsDataGrid = ({defaultType = 'all', props, columns, formatter}) => useDirectDataGrid({
    hookResponse: useGetCollections({current: true}),
    dataHookMethods: collectionHooks,
    defaultType,
    entryHookMethods: entryHooks,
    
    headerSchema: columns,
    entryFormatter: formatter

})

export const useMailsDataGrid = ({defaultType = 'all'}) => useDirectDataGrid({
    hookResponse: useGetMails({current: true}),
    dataHookMethods: mailHooks,
    defaultType,
    headerSchema: mailColumns,
    entryFormatter: c => ({
        ...c, 
        Fields: c?.schema?.length?.toString(),
        Entries: c?.entries?.length ? c?.entries?.length?.toString() : '0'
    }),

})
