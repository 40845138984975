import { getLatestFieldFromFieldPath } from "@mosanic/fields/Source/helpers";
import { useFormProvider } from "@mosanic/core/Provider";
import Input from "@mosanic/fields/Input";
import { revertSnakeCase } from "@util/Text";
import { combineString } from "@util/Text";
import { useFormContext } from "react-hook-form";

 
export const FormField = ({entry, schema}) => {
    const { handleSubmit, control, watch, formState: { errors, isValid } } = useFormContext();
    const inputProps = {control, fullWidth: true, errors}

    const form = useFormProvider()






    // TODO use same parser that is used in the input method
    



 
    


    // Render it over here
    const field = getLatestFieldFromFieldPath(entry?.fieldPath)
    return control ? (
        <>
        <Input
            label={revertSnakeCase(field?.name)} type={field?.type}
            {...field}
            name={combineString(entry?.fieldPath.map(e => (e.name)), '.')} 
            {...inputProps}
            props={field}
            // name={entry?.fieldPath?.map}
            // rules={{required: `Shorthand name that defines this ${dataType}`}}
        />
        {/* <pre>
        {JSON.stringify(field, null, 4)}
        </pre> */}
        {/* {field?.type === 'visual' && JSON.stringify(form)} */}

        {/* {entry?.fieldPath?.map(e => e?.name)} */}
        {}
    {/* {JSON.stringify(watch())} */}
    {/* {JSON.stringify(entry?.fieldPath)} */}
</>
    ) : '..'
}