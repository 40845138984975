import { combineReducers } from 'redux';
import { artboardReducer } from './artboard';
import authReducer from './auth/reducer';
import boundsReducer from './bounds/reducer';
import breakpointsReducer from './breakpoints/reducer';
import canvasReducer from './canvas/reducer';
import cartReducer from './cart/reducer';
import { colorsReducer } from './colors';
import counter from './counter/reducer';
import { dataReducer } from './map/data';
import { dropReducer } from './map/drop';

import { inspectReducer } from './inspect';
import { mapReducer } from './map';
import modalReducer from './modal/reducer';
import navReducer from './navigation/reducer';
import { notificationsReducer } from './notification/reducer';
import optionReducer from './options/reducer';
import orderReducer from './order/orderReducer';
import { pageReducer } from './pages/reducer';
import screensReducer from './screens/reducer';
import { themeReducer } from './theme';
import tokensReducer from './tokens/reducer';
import {typesReducer} from './typography';
import uiReducer from './ui/uiReducer';
import { updateReducer } from './update';


 
function allowedDropZoneAction(action, currentState, previousHistory) {
    return action.type === SOME_ACTION; 
}

function hasNoFutureState(action, currentState, previousHistory) {
    let { past, present, future } = previousHistory;
    return future.length === 0;
}

export const createReducer = (asyncReducers) => {
  return combineReducers({
    ...asyncReducers
  })
}

export const coreReducer = combineReducers({
    ui              : uiReducer,
    nav             : navReducer,
    modal           : modalReducer,
    notifications   : notificationsReducer,
    options         : optionReducer,
    breakpoints     : breakpointsReducer,
    screens         : screensReducer,
    artboard        : artboardReducer,
    canvas          : canvasReducer,

    update          : updateReducer,

    drop            : dropReducer,
    data            : dataReducer,
    map             : mapReducer,
    inspect         : inspectReducer, 

    tokens          : tokensReducer, 
    bounds          : boundsReducer,
    theme           : themeReducer,
    colors          : colorsReducer,
    types           : typesReducer,
    

    pages           : pageReducer,
    order           : orderReducer,
    auth            : authReducer,
    cart            : cartReducer,
    counter
    // counter : counterReducer,
});
