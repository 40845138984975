import { ControlPopper } from '@mosanic/items'
import { default as panel } from '@mosanic/styles/panels'
import { FontBoldIcon, FontItalicIcon, PlusCircledIcon, UnderlineIcon } from '@radix-ui/react-icons'
import { useEffect, useRef } from 'react'
import { Editor, Range } from 'slate'
import { useFocused, useSlate } from 'slate-react'
import styled from 'styled-components'
import { FormatButton } from '../Components'
import { Menu, Portal } from '../Wrappers'

const Mnu = styled(Menu)` 
  padding: 0px 10px 2px;
  position: absolute;
  z-index: 9;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: ${panel.dark};
  border-radius: ${panel.borderRadius};
  transition: opacity 0.25s;

`
export const HoveringToolbar = ({extra = null}) => {
  const ref = useRef()
  const editor = useSlate()
  const inFocus = useFocused()

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) return

    if (!selection || !inFocus || 
        Range.isCollapsed(selection) || 
        Editor.string(editor, selection) === ''
    ){
      el.removeAttribute('style')
      return
    }

    const domSelection = window?.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = '1'
    el.style.top = `${rect.top + window?.pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left + window?.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;

  })

  return (
    <Portal>
      <Mnu
        ref={ref}
        onMouseDown={e => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault()
        }}
      >
        <FormatButton format="bold" icon={<FontBoldIcon />} />
        <FormatButton format="italic" icon={<FontItalicIcon />} />
        <FormatButton format="underline" icon={<UnderlineIcon />} />
        {extra ? (
          <ControlPopper reversed placement="top" trigger={<FormatButton icon={<PlusCircledIcon />}/>}>
            {extra}
          </ControlPopper>
        ) : null}
      </Mnu>
    </Portal>
  )
}