 import { ADD_ENTRY, REMOVE_ENTRIES, REMOVE_ENTRY, UPDATE_ENTRY } from "@api/mutations/entry";
import { useMutate } from "@hooks/useApi";
import { useNotify } from "@mosanic/hooks";
import { isString } from "@util/Text";

import { isAuthenticated } from "@api/token";
import { siteIdSelector, userIdSelector } from "@redux/auth/reducer";
import { isFunc } from "@util/Funct";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";


const createEntry = data => ({
    ...data,
    date: data?.date ? data?.date : new Date(),
    updatedAt: data?.updatedAt ? data?.updatedAt : new Date(),
})

const useDataMutate = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
    silent = false,

    action = 'create',
    method = 'CreateOne',
	mutation = ADD_ENTRY,
    formatter = data => createEntry(data),

	loggedInCheck = true,
	siteCheck = true,
}) => {
	const router = useRouter()
	const shouldRedirect = Boolean(redirect && isString(redirect))
    const isAuth = isAuthenticated()
    const userId = useSelector(userIdSelector)
    const siteId = useSelector(siteIdSelector)
    const [ dispatch, [data, loading, error]] = useMutate(`${type}${method}`, mutation)

    const notify = useNotify();

    const handler = ({data}) => {
		
		// if(!isAuth && !loggedInCheck) {notify(`You are not authenticated to ${action} a ${type}.`);return;}
        // if(!siteId && siteCheck) {notify('No active site selected.');return;}
        
        data = isFunc(formatter) ? formatter(data) : data;
        
        let record = data
        // console.log(data)
        if(method === 'CreateOne' && (!data?.userId || !data?.siteId)) {
            record = {
                ...data, 
                siteId,
                // userId
            }
        }
    

        

        //For update by id, the _id is also passed
        const variables = record?.record ? record : {record};


		dispatch(variables).then((res) => {
			let {data, error} = res;

            if(data?.[`${type}${method}`]) data = data[`${type}${method}`]

			if(!error && data?.recordId){
                if(!silent) notify(`Success: ${action} ${type}.`, 'app', 'success')
                
                if(callback) callback(data?.recordId);
                if(shouldRedirect) router.push(data?.recordId ? `${redirect}/${data.recordId}` : redirect);
			} else if (error) {
				notify(`An error occurred during the ${type} ${action}.`)
				// console.log({error, record});
			} else {
                notify(`Unknown: ${action} ${type}.`, 'app', 'info')
                console.log(res)
            }
		})
    }
    
    return [handler, [data, loading, error]];
}
 
export const useCreate = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
	mutation = ADD_ENTRY,

	loggedInCheck = true,
	siteCheck = true,
    formatter = null,
}) => {
    const [handler, [data, loading, error]] = useDataMutate({
        callback,
        redirect,
        type,
        mutation,
        loggedInCheck,
        siteCheck,
        formatter,
        action: 'create',
    });
    return [handler, [data, loading, error]] 
}

export const useUpdate = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
	mutation = UPDATE_ENTRY,
    formatter = false,
    silent = false,

	loggedInCheck = true,
	siteCheck = true,
    method = 'UpdateById',
}) => {
    const [handler, [data, loading, error]] = useDataMutate({
        callback,
        redirect,
        type,
        silent,
        mutation,
        loggedInCheck,
        siteCheck,
        formatter,
        action: 'update',
        method
    });
    return [handler, [data, loading, error]] 
}

export const useRemove = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
    method = 'RemoveById',
	mutation = REMOVE_ENTRY,

	loggedInCheck = true,
	siteCheck = true,
}) => {
    const [handler, [data, loading, error]] = useDataMutate({
        callback,
        redirect,
        method,
        type,
        mutation,
        loggedInCheck,
        siteCheck,
        action: 'delete',
    });
    return [handler, [data, loading, error]] 
}


export const useRemoveMany = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
    method = 'RemoveMany',
	mutation = REMOVE_ENTRIES,

	loggedInCheck = true,
	siteCheck = true,
}) => {
    const [handler, [data, loading, error]] = useDataMutate({
        callback,
        redirect,
        method,
        type,
        mutation,
        loggedInCheck,
        siteCheck,
        action: 'delete',
    });
    return [handler, [data, loading, error]] 
}