import { PAGINATION_ENTRIES } from "@api/queries/entries";
import { useGet } from "./useDataQuery";

import { PAGINATION_MEDIA } from "@api/queries/media";
import { canMap } from "@util/Arr";



const useGetPage = ({params, query, type}) => {
    const [get, [data, loading, error]] = useGet({
        method: 'Pagination',
        params: !params?.filter ? null : params,
        query,
        type,
        silent: true
    });


    return [data?.items, loading, error, get]
}



export const useGetMediaPage = ({perPage, page, siteId}) => useGetPage({
    query: PAGINATION_MEDIA,
    params: siteId ? {filter: {siteId}, page, perPage} : null,

    type: 'media',
})

export const useGetEntriesPage = ({filter, _ids, collectionId, userId = undefined, sort = '_ID_DESC', perPage, page, ...other}) => useGetPage({
    params: (
        filter ? {filter, perPage, page, sort} : 
        (canMap(_ids) && collectionId) ? {filter: {collectionId, AND: {OR: _ids}, userId, perPage, page}} : 
        collectionId ? {filter: {collectionId, userId}, perPage, page} : null
    ),
    query: PAGINATION_ENTRIES,
    type: 'entry',
    ...other
});
