import { WrapInput } from "@mosanic/items";
import { getSensitizedString, removeSpaces, toLowCase } from "@util/Text";
import { useState } from "react";

import { default as s } from "./Input.styles";

const sanitizeValue = (sanitize, e) => !e.target?.value ? e.target?.value :
    sanitize === 'extreme' ? 
    getSensitizedString(
        removeSpaces(toLowCase(e.target.value))
    ) :
    sanitize ? 
        getSensitizedString(e.target.value) :
        event.target.value;


const useBaseField = ({...other}) => {
    const [focussed, setFocused] = useState(false)

    const onFocus = (e) => {
        if(other?.noFocus) return;
        if(other?.onFocus) other.onFocus(e)
        setFocused(true);
    }
    const onBlur = (e) => {
        if(other?.noFocus) return;
        if(other?.onBlur) other.onBlur(e)
        setFocused(false);
    }
    return [focussed, onFocus, onBlur]
}

const Label = ({innerRef, value, label, name, ...other}) => (
    <s.Label shrink={Boolean(innerRef?.current || value || other?.focussed || other?.placeholder)}>
        {
            other?.ninja ? "" :
            label ? label :
            name ? name : ""
        }
        {other?.required && <s.Required />}
    </s.Label>
)
const EndSource = ({...other}) => (other?.endAdornment || other?.endSource) ? (
    <s.End>
        {other?.endAdornment}
        {other?.endSource}
    </s.End>
) : null

const BaseField = ({
    name = '',
    label = null,

    value = null,
    onChange = null,

    type = 'text',
    innerRef = null,
    field = null,
    children,
    ...other
}) => (
    <WrapInput 
        wrap={other?.wrap ? other.wrap : false} 
        fullWidth={other?.fullWidth ? other?.fullWidth : false}
    > 
        <s.Container 
            onClick={other?.onClick}
            focussed={other?.focussed}
            disabled={other?.disabled}
            valid={other?.valid} 
            empty={other?.empty}
            ninja={other?.ninja} 
            size={other?.size}
            className={other?.className+' input_field'}
        >
            <Label {...{innerRef, value, label, name, ...other}} />

            {children}

            <EndSource {...other}/>
        </s.Container>
    </WrapInput>
)

export default BaseField;
export {
    useBaseField,
    sanitizeValue
}