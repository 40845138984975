

import { Col, Wrap } from "@mosanic/styles";
import { Card } from "@mosanic/styles/card.styles";
import { isServer } from "@util/Device";
import Image from "next/image";

const SmallLogo = ({}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 66 66" style={{margin: '-2.5px 2.5px 0 -10px'}}>
      <g id="Group_1" data-name="Group 1" transform="translate(-88 -100)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="28" height="28" rx="5" transform="translate(88 100)" fill="#1082e9"/>
          <rect id="Rectangle_2" data-name="Rectangle 2" width="28" height="28" rx="5" transform="translate(126 100)" fill="#1082e9"/>
          <rect id="Rectangle_3" data-name="Rectangle 3" width="28" height="28" rx="5" transform="translate(88 138)" fill="#1082e9"/>
          <rect id="Rectangle_4" data-name="Rectangle 4" width="28" height="28" rx="5" transform="translate(126 138)" fill="#1082e9"/>
      </g>
  </svg>
)
// style={{marginTop: '-8%', opacity: 0.1}}
const LargeLogo = ({}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="20%" viewBox="0 0 66 66">
      <g id="Group_1" data-name="Group 1" transform="translate(-88 -100)">
        <rect id="Rectangle_1" data-name="Rectangle 1" width="28" height="28" rx="5" transform="translate(88 100)" fill="#fff"/>
        <rect id="Rectangle_2" data-name="Rectangle 2" width="28" height="28" rx="5" transform="translate(126 100)" fill="#fff"/>
        <rect id="Rectangle_3" data-name="Rectangle 3" width="28" height="28" rx="5" transform="translate(88 138)" fill="#fff"/>
        <rect id="Rectangle_4" data-name="Rectangle 4" width="28" height="28" rx="5" transform="translate(126 138)" fill="#fff"/>
      </g>
    </svg>
)
const AuthWrapper = ({children, colorSchema = 'light', onlyForm = false, ...other}) => onlyForm ? children : (
    <Wrap height={onlyForm ? 'auto' : "100vh"} {...other}>
      {/* {!onlyForm && ( */}
      <Col
        bg={colorSchema === 'light' ? 'primary' : '#212121'}
        center
        width={[1, '40%', '40%',1/2]}
        height={[10,'100%']}
      >

        <Image 
          src="https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/mosanic_mark_large.svg" 
          height="150px" width="150px" alt="Mosanic mark" priority 
          style={{opacity: 0.1}}
        />

      </Col>
      {/* )} */}
      <Col
        center
        bg="white"
        width={onlyForm ? 1 : [ 1, '60%', '60%', 1/2 ]}
        height="100%"
      >
        <Card  
          trans
          textAlign="center"
          width={[ '85vw', '80%', '70%', '60%' ]}
          m="auto"
          pb="10vh"
        >
          <div>	
            <SmallLogo />
            Mosanic <br/>
          </div>

          {children}

        </Card>
      </Col>
    </Wrap>
);

export default AuthWrapper;
