import { gql } from '@apollo/client';;



export const ENTRIES_SLUG = gql`
query entryMany($filter: FilterFindManyEntryInput, $limit: Int, $skip: Int) {
	entryMany(filter: $filter, limit: $limit, skip: $skip){
		title
		type
		description
        slug

		catchRoute
		baseRoute

		
        collectionId
        siteId
       	queryId
		_id
	}
}
`;

export const ENTRY_COUNT = gql` 
query entryCount($filter:FilterCountEntryInput) {
  entryCount(filter:$filter) 
}
`

export const ENTRIES_MAP = gql`
query entryMany($filter: FilterFindManyEntryInput, $limit: Int, $skip: Int) {
	entryMany(filter: $filter, limit: $limit, skip: $skip){
		title
		type
        slug
		catchRoute
		baseRoute

		excerpt
        collectionId
        siteId
        map
		queryId
		_id
	}
}
`;

export const PAGINATION_ENTRIES = gql`
query entryPagination($filter: FilterFindManyEntryInput,  $page: Int, $perPage: Int, $sort: SortFindManyEntryInput) {
	entryPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort){
		items{
			title
			type
			description
			slug

			catchRoute
			baseRoute

			dynamicPage
			schema

			data 

			excerpt

			date
			updatedAt

			status
			userId
			collectionId
			siteId
			trigger

			updates
			queryId
			_id
		}
	}
}
`;
export const FILTER_ENTRIES = gql`
query entryMany($filter: FilterFindManyEntryInput, $limit: Int, $skip: Int,  $sort: SortFindManyEntryInput) {
	entryMany(filter: $filter, limit: $limit, skip: $skip, sort: $sort){
		title
		type
		description
        slug

		catchRoute
		baseRoute

		dynamicPage
		schema

		props
        data 
		filters
		map

		excerpt

        date
        updatedAt

        status
        userId
        collectionId
        siteId
        trigger

        updates
		queryId
		_id
	}
}
`;


export const MAP_ENTRIES = gql`
query entryMany($filter: FilterFindManyEntryInput, $limit: Int, $skip: Int) {
	entryMany(filter: $filter, limit: $limit, skip: $skip){
		title
		type
		description
        slug

		catchRoute
		baseRoute

		dynamicPage
		schema

		props
		filters
		map

		excerpt

        date
        updatedAt

        status
        userId
        collectionId
        siteId
        trigger

        updates
		queryId
		_id
	}
}
`;


export const ENTRY = gql`
query entryById($_id: MongoID!) {
	entryById(_id: $_id){
		title
		type
		description
        slug

		catchRoute
		baseRoute

		dynamicPage
		schema

        data 

		excerpt

        date
        updatedAt

        status
        userId
        collectionId
        siteId
        trigger
        map
        updates
		queryId
		_id
	}
}
`;