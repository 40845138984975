
import { useState } from 'react';

import { Button } from '@mosanic/fields';
import { AdvancedLabel, ControlPopper, Expander, InputGroup, LoadingDots } from '@mosanic/items';
import { List, ListItem } from '@mosanic/items/List';
import { parseImageSrc } from '@mosanic/utils/Image';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useCreateEntry } from '../Mutate';
import { createEntryFormatter } from '../Mutate/create';
import { TwoColumns } from '@mosanic/items/Page/Columns';
import { ArrowDownIcon, ArrowLeftIcon } from '@radix-ui/react-icons';
import { removeEmpty } from '@util/Obj';

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })
export const EditorTransform = ({schema, callback, formData, handler}) => {
    const [action, [data, loading, error]] = handler ? handler({callback, collection: formData}) : [() => null, [null, false, null]]
    const entries = formData?.entries;

    const [state, setState] = useState({
        progress: '',
        loading: false,
        ids: [],
        log: []
    })


    const [addEntry, [addData, loadingAdd, errorAdd]] = useCreateEntry({
		callback: id => setState(state => ({
            ...state, 
            progress: `Converting: ${id}`,
            ids: [...state.ids, id],
            log: [...state.log, `Converting: ${id}`]
        })),
        collection: formData
		
	})
   
    const transformData = () => {
        setState({...state, preview: null, loading: true, ids: [], progress: 'Starting transformation.', log: ['Starting transformation.']});
        
        formData?.entries?.map((entry, i) => setTimeout(() => {
            addEntry({data: entry})
        }, i * 150));

        setTimeout(() => {
            setState(state => ({
                ...state, progress: 'Updating collection.', 
                log: [...state.log, 'Updating collection.'] 
            }));

            action({data: {
                ...formData,
                entries: [state.ids],
                linked: undefined
            }})
            
            setTimeout(() => setState(state=>({
                ...state, preview: null, loading: false, progress: 'Finished.', 
                log: [...state.log, 'Finished.'] 
            })), 750);
        }, 750);
    }

    const convertedEntries = entries?.map(entry => createEntryFormatter(entry, formData))
    const isLoading = (loading || state.loading || loadingAdd)

 
//     Converting: 640b562df6eb9a91e2ca8ba7
// Converting: 640b562df6eb9a91e2ca8ba9
// Converting: 640b562df6eb9a91e2ca8bab
// Converting: 640b562df6eb9a91e2ca8bad
    return (
        <InputGroup cols={1}>
            <TwoColumns 
                first={
                    <List my={4}>
                        {convertedEntries?.map(entry => (
                            
                            <ListItem 
                                key={entry?.title} style={{display: 'flex'}}  
                                onClick={() => setState({...state, preview: entry})}
                            >
                                {entry?.image && (
                                    <Image src={parseImageSrc(entry?.image, true)?.full} width="35px" height="20px" objectFit="contain" alt="preview" />
                                )}
                                <div style={{paddingLeft: '10px'}}>
                                    <b>{entry.title}	
                                    </b>
                                    {entry?.slug && (
                                        <a style={{opacity: 0.4}}>&nbsp;/{entry.slug}</a>	
                                    )} <br/>
                                    {entry?.description != '...' && entry?.description}
                                </div>
                            </ListItem>
                         
                        ))}
                    </List>
                }
                second={[
                    state?.preview && <Button key="clear" ghost onClick={() => setState({...state, preview: null})} icon={<ArrowLeftIcon />} label="View all conversions"/>,
                    <ReactJson collapsed={!state?.preview} name={state?.preview?.title || 'Conversion'} key="json" src={state?.preview || convertedEntries} />
                ]}
            />
           
 
            <Button 
                label={isLoading ? <LoadingDots /> : 'Yes, transform'}
                theme="primary"
                size="large"
                formType="submit"
                disabled={isLoading}
                onClick={transformData}
            />
            
           
            <Expander trigger={<AdvancedLabel label={state.progress} icon={<ArrowDownIcon />} />}>
                <List p={4}>
                    {state.log.map((log, index) => (
                        <ListItem key={index}>{log}</ListItem>
                    ))}
                    
                </List>
            </Expander>

            {error || errorAdd}
        </InputGroup>

    )
}