import Image from "next/image";
import { useState } from "react";
import {default as s} from "./Checkbox.style";
import Checkboxes from "./Checkboxes";

const Checkbox = ({
    className,
    label, 
    checked, 
    value = null,
    onChange = null,
    size ="small",
    options = null,
    img = null,
    disabled = false,
    unfocus=false,
    count = null,
    ...other
}) => {
    const [localState, setLocal] = useState(false);
    const toggleLocal = () => setLocal(prev => !prev)
    if(Array.isArray(value)) {
        let temp = {};
        value.map(v => {
            temp = {...temp, [v?.label]: true}
        });
        onChange(temp);
    }
    if(Array.isArray(options)) return (
    <>
    {JSON.stringify(size)}
    <Checkboxes options={options} value={value} onChange={onChange} {...other} />
    </>
    )
    return (
        <s.CheckBoxWrapper size={size} disabled={disabled} unfocus={unfocus} checked={checked} hasBorder={img}>
             <s.CheckboxContainer className={className}>
                <s.HiddenCheckbox 
                    checked={checked ? checked : value ? value : localState} 
                    onChange={Boolean(disabled) ? null : ( 
                        onChange ? onChange : toggleLocal
                    )} 
                />
                {count && <s.CheckBoxCount>{count}</s.CheckBoxCount>}
                {img ? (
                    <s.CheckBoxImage>
                        <Image src={img} alt={label} width="20px" height="20px"/>
                    </s.CheckBoxImage>
                ) : (
                    <s.StyledCheckbox 
                        id={label} 
                        {...other}
                        type="checkbox" 
                        checked={checked ? checked : value ? value : localState} 
                        disabled={disabled}
                        onChange={() => Boolean(disabled) ? null : ( 
                            onChange ? 
                                onChange : toggleLocal
                        )}
                    >
                        <s.Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                        </s.Icon>
                    </s.StyledCheckbox>
                )}
            </s.CheckboxContainer>

            <s.CheckBoxLabel>
                {label}
            </s.CheckBoxLabel>
        </s.CheckBoxWrapper>
    );
};
export default Checkbox;