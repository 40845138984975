import { usePrefColorSchema } from "@hooks";
import { Button, RenderText } from "@mosanic/fields";
import { Link } from "@mosanic/items";
import { activateAuthAction } from "@redux/auth/action";
import { isAuthSelector } from "@redux/auth/reducer";
import { useDispatch, useSelector } from "react-redux";
import { RegisterForm } from "./RegisterForm";
import AuthWrapper from "./Wrapper";


//Welcome to mosanic.io
// Let's get started! Tell us a bit about yourself.

// How would you describe your role?
// We’ll use this information to customize your Builder experience. Select all that apply.
// Executive
// Designer
// Developer
// Product
// Marketer
// Other

// Your imagination has no limits. Your creations shouldn't either.
// Build the digital experiences of your dreams with our visual tools, drag-and-drop optimization, and one-click publish.
// how are you hoping to use mosanic 
// - landing - marketing - blog - shop - platform 

// Why did you sign up for Builder?
//im evaluating differten tools, just exploring, ready to start building

//How did you header about mosnaic


const Register = ({onlyForm = false, content ={header: null}}) => {
    const dispatch = useDispatch()
    const activateAuth = (registerData) => dispatch(activateAuthAction(registerData));
    const isAuth = useSelector(isAuthSelector)

    return ( 
        <AuthWrapper colorSchema={ 'light'} mt="-40px" onlyForm={onlyForm}>
            {/* usePrefColorSchema() || */}
        {Array.isArray(content?.header) ? <RenderText content={content.header} /> : (
            <>
            <h1>
                {!isAuth ?
                    "Welcome on Mosanic" :
                    "Account registered"
                }
            </h1>
            <p> 
                {!isAuth ? 
                    "Let's get started! Tell us a bit about yourself." : 
                    "Start exploring Mosanic!"    
                }
            </p>
            </>
        )}

            {!isAuth ? (
                <RegisterForm activateAuth={activateAuth} accountActions/>
            ) : (   
                <Link href={`/`}>
                    <Button size="large" m="0 auto">
                        Dashboard
                    </Button>
                </Link> 
            )}
        </AuthWrapper>
    )
}
export default Register;