import { Items } from "@mosanic/constants/";

const {COLUMN, COMPONENT, REPEATER, ROW, STACK, CONTAINER} = Items;

export const isStackAvailable = (type) => [COLUMN, STACK, REPEATER].includes(type);
export const isRow = (type) => type === ROW;
export const isCol = (type) => type === COLUMN;
export const isComp = (type) => type === COMPONENT;
export const isComponent = (type) => ![COLUMN, STACK, REPEATER, STACK, ROW].includes(type);
export const isRepeater = (type) => type === REPEATER;

export const isNotInspectedComp = (isInspected, type) => !isInspected && !isComp(type)

export const getClickCheckListValidation = (type) => {
    switch (type) {
        case COMPONENT: return false;
        case STACK: return {accept: ['stack__dropZone', 'stack__label', 'stack__flex', '-STACK']}; 
        case COLUMN: return {accept: ['column__dropZone','column', 'column__label', 'column__flex', 'inspectors']}; 
        case ROW: return {targetZone: ['row__dropZone' ],preventZone: ['column__dropZone', 'page__dropZone', ],accept: ['row__dropZone', 'row__add', 'columns', 'row_col_wrapper', 'row_col_container', 'row'] }; 
        case REPEATER: return {accept: ['repeater__dropZone', 'repeater__label', 'repeater__flex', '-REPEATER']}; 
        case CONTAINER: return {accept: ['container']};
        default: return false;
    }
}