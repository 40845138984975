import { getFieldValue } from "@mosanic/fields/Source/helpers";
import isBoolean from "@util/Boolean";
import { getDate, getDif, isValidDate } from "@util/Date";
import isEmpty from "@util/Empty";
import { roundPrecise } from "@util/Round";
import { combineString, containsSubstr, hasNumber, removeSpaces, replaceParts } from "@util/Text";
import evaluate from 'evaluator.js';

export const getFuncOptions = obj => {
    let base = {
        '.length': 'The length data property of an Array instance',
        '.reverse()': 'Switch arround',
        'length': '',

        '.slice()': 'Skii'
    }
    return base;
}
    // Array.isArray(obj) ? [
    // ] : [

    // ]

export const getValue = (obj, path, canFunc = true) => {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, '');           // strip a leading dot
    var parts = path.split('.');

    for (var i = 0, n = parts.length; i < n; ++i) {
        var part = parts[i];

        //handle func
        if(canFunc && Object.keys(getFuncOptions())?.includes(part)) {
            
            try {
                const func = new Function("return "+obj+"."+part);
                obj = func()
            } catch (e) {
                return parts
            }
        }

        //Getter
        if (part in obj) {
            obj = obj[part];
        } else {
            return parts
        }
    }
    return obj;
}




const splitTokens = value => value ? value?.split(/[{}]+/).filter(e => e) : value;


/**
 * Replace token values
 * @param {*} value 
 * @param {*} source 
 * @param {*} evaluate 
 * @returns 
 */
export const replaceWithSource = (value, source, evaluate = false) => {
    const splitted = splitTokens(value);
    const hasDate = false;

    //No tokens
    if(splitted?.length === 1 && splitted[0]?.length === value?.length) return value;
    
    
    const parts = Array.isArray(splitted) ? splitted?.map(part => {
        if(containsSubstr(part, ' ') || part?.length <= 2) return part;
        // console.log(part.split('.'), getFieldValue(part.split('.').map(name => ({name})), source, data?.coll))

        part = getFieldValue(
            part.split('.').map(name => ({name})), 
            source, 
            // data?.coll
        )

        // console.log(part)
        if(isValidDate(part)) {
            hasDate = true;
            return evaluate ? new Date(part) : getDate(part)
        }
        return part;
    }) : splitted;
    
    const combined = combineString(parts, '');

    return evaluate ? 
        getEvaluatedValue(hasDate ? parts : combined) : 
        combined
}



/**
 * Just value
 */
export const replaceValue = (text, value) => replaceParts(text, '{value}', value)


/**
 * @param {*} value 
 * @returns evaluated value
 */
export const getEvaluatedValue = (value) => {
    if(Array.isArray(value)) {
        if(value?.length === 3) {
            return removeSpaces(value[1]) === '-' ?
                Math.floor((value?.[0] - value?.[2]) / 86400000) :
                Math.floor((value?.[2] - value?.[0]) / 86400000)
        }
        return value
    }
    try {
        return roundPrecise(evaluate(value), 2)
    } catch (error) {
        return value
    }
}

const splitValue = string => string.split('|');


export const evalReplaceValue = (text = '{value}', value, evaluate = true) => {
    if(Array.isArray(value)) return '';
    if(isBoolean(value)){
        const splitted = splitValue(text);
        if(splitted?.length === 2) return value ? splitted[0] : splitted[1];
        return value ? text : 'no'
    }
    value = value ? replaceValue(text, value) : '';
    let str = '';
    splitValue(value).map(part => 
        str += (evaluate && hasNumber(part)) ? 
            getEvaluatedValue(part) :
            part 
    );
    return str;
}


export const isClone = clone => !isEmpty(clone) || clone === 0