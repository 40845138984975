import { default as s } from '@mosanic/builder/Image/Image.style';
import { Button, ButtonGroup, TextArea, TextField } from '@mosanic/fields';
import { editPencil, trash } from '@mosanic/Icons/EditIcons';
import { imageIcon } from '@mosanic/Icons/SidebarIcons';
import { AdvancedLabel, ControlPopper, Spinner } from '@mosanic/items';
import { parseImageSrc } from '@mosanic/utils/Image';
import { matchSubstrList } from '@util/Text';
import dynamic from "next/dynamic";
import Image from 'next/image';
import { useState } from 'react';
const ImgsViewer = dynamic(() => import("react-images-viewer"), {
    ssr: false
});

export const MediaListImage = ({actions, image, meta, index, showEditor, type = 'image'}) => {
    const source = parseImageSrc(image.url, true);

    const [preview, setPreview] = useState(false);
    const togglePreview = () => setPreview(prev => !prev)

    const updateName = e => actions.updateMeta(index, {...meta, title: e.target.value})
    const updateAlt = e => actions.updateMeta(index, {...meta, alt: e})
    const updateFold = e => actions.updateMeta(index, {...meta, folder: e.target.value})
                
    return (
    <s.UploadedListItem>
    
        <s.UploadedImage onClick={togglePreview}>
            {(type === 'video' && matchSubstrList(image['url'], ['mp4', 'mov']) ) ? (
                  <video 
                    src={source?.thumb} 
                    alt={'Video'} 
                    controls={true}
                    />
            ) : (
                <Image 
                    src={source?.thumb} 
                    alt={image?.title} 
                    width="75px"
                    height="75px"
                />
            )}
        </s.UploadedImage>
        {showEditor && (
            <s.Meta>
                <TextField fullWidth label="Asset Name" value={meta?.title} onChange={updateName} />
                {/* <TextArea wrap fullWidth minRows={1} label="Alt" value={meta?.alt} /> */}
            </s.Meta>        
        )}
         {(showEditor) && (
            <s.Controllers 
                editor={showEditor} 
                className="controls"
            >
                <ButtonGroup size="small" mt={2}>
                    <ControlPopper trigger={(
                        <Button icon={editPencil}/>
                    )}>
                        <AdvancedLabel as="h4" label="Asset seo" />
                        <TextField fullWidth wrap label="Asset Name" value={meta?.title} onChange={updateName}/>
                        <TextArea minRows={1} wrap fullWidth label="Alt description" value={meta?.alt} onChange={updateAlt}/>
                        <TextField fullWidth wrap label="Folder" value={meta?.folder} onChange={updateFold} />
                        <Button 
                            mt={2} ml={0} ghost
                            handler={() => actions.onImageUpdate(index)} size="small" 
                        >
                            {imageIcon} &nbsp; Replace asset
                        </Button>
                    </ControlPopper>
                    <Button 
                        size="small"
                        handler={() => actions.handleRemove(index)}
                        icon={trash}
                    /> 
                    <Button 
                        disabled={!meta?.title || !meta?.alt}
                        handler={() => actions.uploadOne(index)} 
                        size="small"
                    >
                        <svg version="1.1" width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M7.81825,1.18188c-0.17574,-0.17573 -0.46066,-0.17573 -0.6364,0l-3,3c-0.17574,0.17574 -0.17574,0.46066 0,0.6364c0.17574,0.17573 0.46066,0.17573 0.6364,0l2.2318,-2.2318v6.91348c0,0.24853 0.20147,0.45 0.45,0.45c0.24853,0 0.45,-0.20147 0.45,-0.45v-6.91348l2.23185,2.2318c0.1757,0.17573 0.4606,0.17573 0.6363,0c0.1758,-0.17574 0.1758,-0.46066 0,-0.6364l-2.99995,-3Zm-5.31825,8.81809c0.27614,0 0.5,0.22383 0.5,0.50003v1.5c0,0.5538 0.44565,1 0.99635,1h7.00485c0.5517,0 0.9988,-0.4472 0.9988,-1v-1.5c0,-0.2762 0.2239,-0.50003 0.5,-0.50003c0.2761,0 0.5,0.22383 0.5,0.50003v1.5c0,1.104 -0.8938,2 -1.9988,2h-7.00485c-1.10616,0 -1.99635,-0.897 -1.99635,-2v-1.5c0,-0.2762 0.22386,-0.50003 0.5,-0.50003Z" fill="#000" fillRule="evenodd"></path></svg>
                    </Button>
                </ButtonGroup>
            </s.Controllers>
        )}
        {source?.full >= 1 && (
        <ImgsViewer
			imgs={[{ src: source?.full }]}
			spinner={() => <Spinner/>}
			isOpen={preview && source?.full}
			onClose={togglePreview}
		/>)}
           {/* {meta?.title ? meta.title : ''} */}

    </s.UploadedListItem>
    )
}