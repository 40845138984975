import { selectInspectContext } from "@redux/inspect/reducer"
import { useSelector } from "react-redux"

import { useGetCollectionIndexes, useGetEntries } from "@mosanic/cms/Query"
import { useEffect, useState } from "react"
import { useGetEntryCount } from "@mosanic/cms/Query/count"
import { isPageSource } from "./helpers"
import { isNumber } from "@util/Num"
import isEmpty from "@util/Empty"
import { isString } from "@util/Text"
import { isObject } from "@util/Obj"
import isBoolean from "@util/Boolean"
import { convertEntry } from "@mosanic/cms/Query/useDataQuery"




const getContext = ({type, context}) => {
    switch (type) {
        case 'page': return context?.pageProvider;
        case 'provider': return context?.dataProvider;
        case 'repeater': return context?.repeatProvider;
        default: return null;
    }
}


const getProviderCollections = ({type, context, collections}) => {
    switch (type) {
        case 'page':return context;
        case 'provider': return [{title: 'Page context', label: 'entry', _id: 'page', ...context}]
        case 'repeater': return context;
        default: return collections?.map(c => ({
            ...c, 
            label: isNumber(c?.entries) ? `${c?.entries} entries` : ``,
            count: isNumber(c?.entries) ? c.entries : ''
        }));
    }
}

const getProviderEntries = ({type, provider, entries}) => {
    switch (type) {
        // case 'page': return Object.entries(providers).map(([title, value]) => ({
        //     title, label: getProviderCollectionLabel({value}), value, _id: title
        // }));
        // case 'provider': return [{title: 'Page context', label: 'entry', _id: 'page', ...context}]
        // case 'repeater': return [{title: 'Repeater context', label: 'entries', _id: 'repeater', ...context}];
        default: return Array.isArray(provider?.entries) ? provider?.entries : entries
    }
}


const getFieldSchema = ({type, provider, entry, value}) => {
    let schema ;
    switch (type) {
        // case 'page': return Object.entries(providers).map(([title, value]) => ({
        //     title, label: getProviderCollectionLabel({value}), value, _id: title
        // }));
        // case 'provider': return [{title: 'Page context', label: 'entry', _id: 'page', ...context}]
        case 'repeater': schema = provider?.schema; break;
        default: schema = provider?.schema; break;
    }
    if(!schema && entry) schema = createSchema(entry);
    if(value?.entry === 'provider' && provider) schema = createSchema(provider);

    return schema;
}

const BASE_CONTROLS = {limit: 8, skip: 0}
export const useSources = ({value}) => {
    const [controller, setController] = useState(BASE_CONTROLS)
    const type = value?.source;

    //Sources
    const [collections, loading] = useGetCollectionIndexes({current: true})
    const collectionId = type === 'source' ? value?.collection : undefined;
    useEffect(() => {if(collectionId){setController(BASE_CONTROLS)}},[collectionId])
    const inspectedContext = useSelector(selectInspectContext)

    //Entries
    const [data_entries, loading_entries] = useGetEntries({filter: {collectionId}, ...controller})
    const [count] = useGetEntryCount({_id: collectionId})

    const context = getContext({type, context: inspectedContext})
    const providers = getProviderCollections({type, context, collections})

    const provider = (value?.collection && Array.isArray(providers) )? providers?.find(p => p?._id === value?.collection) : context;
    const entries = getProviderEntries({type, provider, entries: data_entries})

    let entry = entries?.find(e => e?._id === value?.entry);
    if(entry) entry = convertEntry(entry)

    const schema = getFieldSchema({type, provider, entry, value})


    console.log({context, type, provider, entries})

    const controls = {
        next: () => {
            const newSkip = controller.skip + controller.limit;
            if(!count || newSkip > count) return null;
            return () => setController({...controller, skip: newSkip})
        },
        prev: () => {
            const newSkip = controller.skip - controller.limit;
            if(!count || newSkip < 0) return null;
            return () => setController({...controller, skip: newSkip})
        },
        skip: controller?.skip,
        limit: controller?.limit,
        count,
    }

    return [
        providers,
        {...provider,  entries},
        entry,
        schema,
        [loading, loading_entries],
        controls
    ]

}



//Creation 

const createProviderCollectionLabel = ({value}) => {
    if(Array.isArray(value)) return `List of ${value.length} entries`;
    return `${Object.keys(value)?.length} sub groups`
}

const getField = (key, value) => {
    let field = {
        label: key,
        type: (
            isString(value) ? 'text' : 
            Array.isArray(value) ? 'repeater'  : 
            isObject(value) ? 'group' :  
            isBoolean(value) ? 'switch' : 
            new Date(value)?.getMonth() ? 'date' :
            typeof value
        ),
        name: key,
        schema: null,
        value
    }
    if(['updated', 'created'].includes(field?.name) && field?.type === 'number') field = {
        ...field,
        type: 'date',
        value: new Date(value * 1000)
    }
    return 'group' === field?.type ? {...field, schema: createSchema(value)} :
        'repeater' === field?.type ? {...field, schema: !isString(value?.[0]) ? createSchema(value[0]) : null} :
        field
}
export const createSchema = obj => !obj ? [] : Object.entries(obj).filter(([_, value]) => !isEmpty(value) ).map(([key, value]) => {
    return getField(key, value)
}) 

