
export const NAV_ID = '[nav]';


export const NAV_SET      = `${NAV_ID} [Sidebar] set`;


export const UPDATE_NAVIGATION      = `${NAV_ID} Update`;
export const NAV_SET_PANEL          = `${NAV_ID} Set Panel`;
export const NAV_TOGGLE_MOBILE          = `${NAV_ID} Toggle Mobile`;
export const TOGGLE_SMALL          = `${NAV_ID} Sidebar small`;

const NAV_IDS = {
    NAV_SET_PANEL,
    NAV_TOGGLE_MOBILE,

    TOGGLE_SMALL,


    NAV_SET,
}

export const setMainSideBarAction = ({open = undefined, width = undefined, value = undefined, ...other}) => ({
    type: NAV_SET,
    payload: {open, width, value, ...other, panel: 'main'},
    meta: {feature: NAV_ID}
})
export const setSubSideBarAction = ({open = undefined, width = undefined, value = undefined, ...other}) => ({
    type: NAV_SET,
    payload: {open, width, value, ...other, panel: 'sub'},
    meta: {feature: NAV_ID}
})






export const toggleMobileAction = () => ({
    type: NAV_TOGGLE_MOBILE,
    meta: {feature: NAV_ID}
});




export const setSidebarPanelAction = ({value = null, panel = 'left'}) => ({
    type: NAV_SET_PANEL,
    payload: {value, panel},
    meta: {feature: NAV_ID}
})





//Short cuts
export const setLeftSidebarPanelActive = (panel, value) => setSidebarPanelAction({
    value: { ...panel, ...value }, 
    panel: 'left'
})
export const setSubSidebarPanelActive = (subPanel, value) => setSidebarPanelAction({
    value: { ...subPanel, ...value }, 
    panel: 'subLeft'
});






export default NAV_IDS;