
export const uniqueIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.146 1.49a.5.5 0 0 1 .708 0l5.657 5.656a.5.5 0 0 1 0 .708L7.854 13.51a.5.5 0 0 1-.708 0L1.49 7.854a.5.5 0 0 1 0-.708L7.146 1.49ZM7.5 2.55 2.55 7.5l4.95 4.95 4.95-4.95L7.5 2.55Z" fill="#000"/></svg>;
export const uncloneIcon = uniqueIcon;
export const mainRefIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M4.671 3.146a.5.5 0 0 0 0 .707l2.475 2.475a.5.5 0 0 0 .707 0l2.475-2.475a.5.5 0 0 0 0-.707L7.853.671a.5.5 0 0 0-.707 0L4.671 3.146ZM7.5 5.268 5.732 3.5 7.5 1.732 9.267 3.5 7.5 5.268ZM8.67 7.146a.5.5 0 0 0 0 .707l2.475 2.475a.5.5 0 0 0 .707 0l2.475-2.475a.5.5 0 0 0 0-.707l-2.475-2.475a.5.5 0 0 0-.707 0L8.671 7.146ZM11.5 9.268 9.732 7.5 11.5 5.732 13.268 7.5 11.5 9.268ZM4.67 11.853a.5.5 0 0 1 0-.707l2.475-2.475a.5.5 0 0 1 .707 0l2.475 2.475a.5.5 0 0 1 0 .707l-2.475 2.475a.5.5 0 0 1-.707 0l-2.475-2.474Zm1.061-.353L7.5 13.268 9.267 11.5 7.5 9.732 5.732 11.5ZM.672 7.146a.5.5 0 0 0 0 .708l2.474 2.474a.5.5 0 0 0 .707 0l2.475-2.474a.5.5 0 0 0 0-.708L3.853 4.672a.5.5 0 0 0-.707 0L.671 7.146ZM3.5 9.268 1.732 7.5 3.5 5.732 5.267 7.5 3.5 9.268Z" fill="#000"/></svg>;
export const clonedIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.854 1.49a.5.5 0 0 0-.707 0L1.49 7.146a.5.5 0 0 0 0 .708l5.657 5.656a.5.5 0 0 0 .707 0l5.657-5.656a.5.5 0 0 0 0-.708L7.854 1.49ZM7.5 2.55 2.55 7.5l4.95 4.95v-9.9Z" fill="#000"/></svg>;
export const syncItemIcon = clonedIcon;
export const cloneIcon = clonedIcon;
export const componentIcon = mainRefIcon;

export const mixerIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM3 5c.017 0 .033 0 .05-.002a2.5 2.5 0 0 0 4.9 0A.507.507 0 0 0 8 5h5.5a.5.5 0 0 0 0-1H8c-.017 0-.033 0-.05.002a2.5 2.5 0 0 0-4.9 0A.507.507 0 0 0 3 4H1.5a.5.5 0 0 0 0 1H3Zm8.95 5.998a2.5 2.5 0 0 1-4.9 0A.507.507 0 0 1 7 11H1.5a.5.5 0 0 1 0-1H7c.017 0 .033 0 .05.002a2.5 2.5 0 0 1 4.9 0A.506.506 0 0 1 12 10h1.5a.5.5 0 0 1 0 1H12c-.017 0-.033 0-.05-.002ZM8 10.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" fill="#000"/></svg>

export const elementFieldsIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M6.5 1a.5.5 0 0 0 0 1c.627 0 .957.2 1.156.478C7.878 2.79 8 3.288 8 4v7c0 .712-.122 1.21-.344 1.522-.199.278-.53.478-1.156.478a.5.5 0 0 0 0 1c.873 0 1.543-.3 1.97-.897l.03-.044.03.044c.427.597 1.097.897 1.97.897a.5.5 0 0 0 0-1c-.627 0-.957-.2-1.156-.478C9.122 12.21 9 11.712 9 11V4c0-.712.122-1.21.344-1.522C9.543 2.2 9.874 2 10.5 2a.5.5 0 0 0 0-1c-.873 0-1.543.3-1.97.897l-.03.044-.03-.044C8.042 1.3 7.372 1 6.5 1ZM14 5h-3V4h3a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-3v-1h3V5ZM6 4v1H1v5h5v1H1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5Z" fill="#000"/></svg>

export const mainLinkDataIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M3 2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V8.5a.5.5 0 0 0-1 0V12H3V3h3.5a.5.5 0 0 0 0-1H3Zm9.854.146a.5.5 0 0 1 .146.351V5.5a.5.5 0 0 1-1 0V3.707L6.854 8.854a.5.5 0 1 1-.708-.708L11.293 3H9.5a.5.5 0 0 1 0-1h3a.499.499 0 0 1 .354.146Z" fill="#000"/></svg>;
export const clonedLinkDataIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M8.512 3.005c.676-.46 1.531-.468 2.167-.05.144.094.298.244.71.656.412.412.562.566.657.71.417.636.408 1.49-.051 2.167-.105.155-.267.32-.694.747l-.62.619a.5.5 0 0 0 .708.707l.619-.619.043-.043c.37-.37.606-.606.771-.849.675-.994.71-2.287.06-3.278-.159-.241-.39-.472-.741-.823l-.045-.045-.044-.045c-.352-.351-.583-.582-.824-.74-.99-.65-2.284-.616-3.278.06-.243.164-.48.4-.85.77l-.042.043-.619.62a.5.5 0 1 0 .707.706l.62-.618c.426-.427.592-.59.746-.695ZM4.318 7.147a.5.5 0 0 0-.707-.707l-.619.618-.043.043c-.37.37-.606.606-.771.85-.675.993-.71 2.287-.06 3.277.159.242.39.473.741.824l.045.045.044.044c.352.351.583.583.824.741.99.65 2.284.616 3.278-.06.243-.165.48-.401.849-.771l.043-.043.619-.619a.5.5 0 1 0-.708-.707l-.618.619c-.427.427-.593.59-.747.694-.676.46-1.532.469-2.167.051-.144-.094-.298-.245-.71-.657-.412-.412-.562-.566-.657-.71-.417-.635-.408-1.49.051-2.167.105-.154.267-.32.694-.747l.619-.618Zm5.304-1.061a.5.5 0 0 0-.707-.708L5.379 8.914a.5.5 0 1 0 .707.707l3.536-3.535Z" fill="#000"/></svg>;
export const linkDataIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M10.679 2.954c-.636-.417-1.49-.408-2.167.051-.154.105-.32.268-.747.695l-.619.618a.5.5 0 0 1-.707-.707l.619-.619.043-.042c.37-.37.606-.607.849-.772.994-.675 2.288-.71 3.278-.06.241.159.472.39.824.741l.044.045.045.045c.351.351.582.582.74.823.651.99.616 2.284-.059 3.278-.165.243-.402.48-.771.85l-.043.042-.619.619a.5.5 0 1 1-.707-.707l.619-.619c.427-.427.589-.592.694-.747.46-.676.468-1.531.051-2.167-.095-.144-.245-.298-.657-.71-.412-.412-.566-.562-.71-.657ZM4.318 6.44a.5.5 0 0 1 0 .707l-.619.618c-.427.427-.59.593-.694.747-.46.677-.468 1.532-.051 2.167.095.144.245.298.657.71.412.412.566.563.71.657.635.418 1.49.409 2.167-.05.154-.106.32-.268.747-.695l.618-.619a.5.5 0 1 1 .708.707l-.62.62-.042.042c-.37.37-.606.606-.85.771-.993.676-2.287.71-3.277.06-.241-.158-.472-.39-.824-.74-.014-.016-.03-.03-.044-.045a91.54 91.54 0 0 1-.045-.045c-.351-.351-.582-.582-.741-.824-.65-.99-.615-2.284.06-3.278.165-.243.402-.48.771-.849l.043-.043.619-.618a.5.5 0 0 1 .707 0Z" fill="#000"/></svg>;

export const dataSourceIcon = mainLinkDataIcon; 
export const elementsMapIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.754 1.82a.5.5 0 0 0-.508 0l-5.5 3.25a.5.5 0 0 0 0 .86l5.5 3.25a.5.5 0 0 0 .508 0l5.5-3.25a.5.5 0 0 0 0-.86l-5.5-3.25ZM7.5 8.17 2.983 5.5 7.5 2.83l4.517 2.67L7.5 8.17Zm-5.246.15a.5.5 0 0 0-.508.86l5.5 3.25a.5.5 0 0 0 .508 0l5.5-3.25a.5.5 0 1 0-.508-.86L7.5 11.42l-5.246-3.1Z" fill="#000"/></svg>;
export const layersIcon = elementsMapIcon;
export const syncChildrenIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M1.903 7.297c0 3.044 2.207 5.118 4.686 5.547a.521.521 0 1 1-.178 1.027C3.5 13.367.861 10.913.861 7.297c0-1.537.699-2.745 1.515-3.663.585-.658 1.254-1.193 1.792-1.602H2.532a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V2.686l-.001.002c-.572.43-1.27.957-1.875 1.638-.715.804-1.253 1.776-1.253 2.97Zm11.108.406c0-3.012-2.16-5.073-4.607-5.533a.521.521 0 1 1 .192-1.024c2.874.54 5.457 2.98 5.457 6.557 0 1.537-.699 2.744-1.515 3.663-.585.658-1.254 1.193-1.792 1.602h1.636a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 1 1 1 0v1.845h.002c.571-.432 1.27-.958 1.874-1.64.715-.803 1.253-1.775 1.253-2.97Z" fill="#000"/></svg>;

export const unlinkDataIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M12.146 1.146a.5.5 0 0 1 .707 0l2 2a.5.5 0 0 1 0 .708l-3.942 3.942a1 1 0 0 1-.26.188L6.724 9.947a.5.5 0 0 1-.671-.67l1.963-3.928a1 1 0 0 1 .188-.26l3.942-3.943Zm.354 1.061-3.59 3.59-1.037 2.076.254.254 2.077-1.038L13.793 3.5 12.5 2.207ZM10 2 9 3H4.9c-.428 0-.72 0-.944.019-.22.018-.332.05-.41.09a1 1 0 0 0-.437.437c-.04.078-.072.19-.09.41C3 4.18 3 4.472 3 4.9v6.2c0 .428 0 .72.019.944.018.22.05.332.09.41a1 1 0 0 0 .437.437c.078.04.19.072.41.09.225.019.516.019.944.019h6.2c.428 0 .72 0 .944-.019.22-.018.332-.05.41-.09a1 1 0 0 0 .437-.437c.04-.078.072-.19.09-.41.019-.225.019-.516.019-.944V7l1-1V11.12c0 .403 0 .735-.022 1.006-.023.281-.072.54-.196.782a2 2 0 0 1-.874.874c-.243.124-.501.173-.782.196-.27.022-.603.022-1.005.022H4.88c-.403 0-.735 0-1.006-.022-.281-.023-.54-.072-.782-.196a2 2 0 0 1-.874-.874c-.124-.243-.173-.501-.196-.782C2 11.856 2 11.523 2 11.12V4.88c0-.403 0-.735.022-1.006.023-.281.072-.54.196-.782a2 2 0 0 1 .874-.874c.243-.124.501-.173.782-.196C4.144 2 4.477 2 4.88 2H10Z" fill="#000"/></svg>;
export const editPencilSquareIcon = unlinkDataIcon;

export const mixinIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm.713 1.164a2.5 2.5 0 1 1 0-2.328l3.391-2.12A2.5 2.5 0 1 1 14 3.5a2.5 2.5 0 0 1-4.484 1.52l-3.53 2.207a2.526 2.526 0 0 1 0 .546l3.53 2.206a2.5 2.5 0 1 1-.411.804l-3.392-2.12ZM11.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" fill="#000"/></svg>;
export const shareIcon = mixinIcon;

export const circleCloseIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M.877 7.5a6.623 6.623 0 1 1 13.246 0 6.623 6.623 0 0 1-13.246 0ZM7.5 1.827a5.673 5.673 0 1 0 0 11.346 5.673 5.673 0 0 0 0-11.346Zm2.354 3.32a.5.5 0 0 1 0 .707L8.207 7.5l1.647 1.646a.5.5 0 0 1-.708.708L7.5 8.207 5.854 9.854a.5.5 0 0 1-.708-.708L6.793 7.5 5.146 5.854a.5.5 0 0 1 .708-.708L7.5 6.793l1.646-1.647a.5.5 0 0 1 .708 0Z" fill="#000"/></svg>;
export const crossCloseIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z" fill="#000"/></svg>;


export const stylingIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.5 1.5C4.5 4.25 3 6.5 3 9a4.5 4.5 0 1 0 9 0c0-2.5-1.5-4.75-4.5-7.5ZM11 9c0-1.888-1.027-3.728-3.5-6.126C5.027 5.272 4 7.112 4 9a3.5 3.5 0 1 0 7 0Z" fill="#000"/></svg>;
export const layoutIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.5 1.8a5.7 5.7 0 1 0 0 11.4 5.7 5.7 0 0 0 0-11.4ZM.9 7.5a6.6 6.6 0 1 1 13.2 0 6.6 6.6 0 0 1-13.2 0Z" fill="#000"/><path d="M13.5 7.9h-12v-.8h12v.8Z" fill="#000"/><path d="M7.1 13.5v-12h.8v12h-.8ZM10.375 7.5c0-2.173-.781-4.322-2.313-5.743l.476-.514c1.702 1.58 2.537 3.93 2.537 6.257 0 2.327-.835 4.678-2.537 6.257l-.476-.514c1.532-1.42 2.313-3.57 2.313-5.743ZM4 7.5c0-2.324.808-4.673 2.458-6.253l.484.506C5.458 3.173 4.7 5.323 4.7 7.5c0 2.176.758 4.327 2.242 5.747l-.484.506C4.808 12.173 4 9.823 4 7.5Z" fill="#000"/><path d="M7.5 3.958c2.17 0 4.375.401 5.87 1.236a.35.35 0 1 1-.34.612c-1.35-.754-3.422-1.148-5.53-1.148s-4.18.394-5.53 1.148a.35.35 0 1 1-.34-.612c1.495-.835 3.7-1.236 5.87-1.236ZM7.5 10.85c2.17 0 4.375-.401 5.87-1.236a.35.35 0 1 0-.34-.612c-1.35.754-3.422 1.148-5.53 1.148s-4.18-.394-5.53-1.148a.35.35 0 1 0-.34.611c1.495.836 3.7 1.237 5.87 1.237Z" fill="#000"/></svg>;
export const globeIcon = layoutIcon;

export const inputIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M6.5 1a.5.5 0 0 0 0 1c.627 0 .957.2 1.156.478C7.878 2.79 8 3.288 8 4v7c0 .712-.122 1.21-.344 1.522-.199.278-.53.478-1.156.478a.5.5 0 0 0 0 1c.873 0 1.543-.3 1.97-.897l.03-.044.03.044c.427.597 1.097.897 1.97.897a.5.5 0 0 0 0-1c-.627 0-.957-.2-1.156-.478C9.122 12.21 9 11.712 9 11V4c0-.712.122-1.21.344-1.522C9.543 2.2 9.874 2 10.5 2a.5.5 0 0 0 0-1c-.873 0-1.543.3-1.97.897l-.03.044-.03-.044C8.042 1.3 7.372 1 6.5 1ZM14 5h-3V4h3a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-3v-1h3V5ZM6 4v1H1v5h5v1H1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5Z" fill="#000"/></svg>;


const StateIcons = {
    uniqueIcon,
    mainRefIcon,
    clonedIcon,
    componentIcon,

    elementFieldsIcon,
    mainLinkDataIcon,

    clonedLinkDataIcon,
    linkDataIcon,

    dataSourceIcon,
    elementsMapIcon,
    syncChildrenIcon,

    mixinIcon
}
export default StateIcons;