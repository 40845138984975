import { ErrorMessage as ErrMessage } from '@hookform/error-message';
import { colors } from "@mosanic/styles";
import styled from 'styled-components';

const Message = styled.p` 
    color: ${colors.util.gray.base};
    width: 100%;
    font-size: 12px;
    margin-bottom: 16px;
`

const ErrorMessage = ({errors, name}) => (
    <ErrMessage
        errors={errors}
        name={name}
        render={({ message }) => <Message>{message}</Message>}
    />
)

export default ErrorMessage;