import { IndexingCard, useIndexingSetup } from "./IndexingCard";
import { Navigation } from "./Navigation";
import { PageMeta, UpdatePageDialog } from "./PageMeta";
import { RenderPage } from "./Resolvers";
import { usePage } from "./Resolvers/usePage";
import { usePageInit } from "./usePageInit";
import { useCreatePage } from "./useUpdatePage";

export {
  IndexingCard,
  usePage,
  usePageInit,
  useCreatePage,
  RenderPage,
  useIndexingSetup,
  Navigation,
  PageMeta,
  UpdatePageDialog
};
export default RenderPage;