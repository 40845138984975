import { toLowCase } from "./Text";

export const TAG_LIST = [
    'button', 'a', 
    'div', 'span', 
    'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 
    'b', 'mark', 
    'input', 'textarea',
    'section', 'article', 
    'main', 'aside', 'footer', 'header',
    'figure', 'caption', 'img',
    'ul', 'ol', 'li'
];

export const isHtmlTag = (stringType) => stringType ? TAG_LIST.includes(toLowCase(stringType)) : false;
export const getTag = (stringType) => 
    TAG_LIST.includes(toLowCase(stringType)) ?
        toLowCase(stringType) : null;