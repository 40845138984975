export const CREATE_ORDER    = '[order] Create';
export const UPDATE_ORDER    = '[order] Update';
export const SUBMIT_ORDER = '[order] Complete order';

export const createOrder = (data) => ({
  type: CREATE_ORDER,
  payload: data
});

export const updateOrder = (data) => ({
  type: UPDATE_ORDER,
  payload: data
});

export const submitOrder = (data) => ({
  type: SUBMIT_ORDER,
  payload: data
});