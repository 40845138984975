
import styled from 'styled-components';

const Span = styled.span` 
  margin: 0 0.1em;
  background-color: #efefef;
  padding: 2px 6px;
  border: 1px solid #767676;
  border-radius: 2px;
  font-size: 0.9em;
  ${props => props.render}
`

// Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
export const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={`hiddenFont`}
    style={{fontSize: '0 !important;'}}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);



const EditableButtonComponent = ({ style, attributes, children }) => (
    /*
      Note that this is not a true button, but a span with button-like CSS.
    */
    <Span
      {...attributes}
      onClick={ev => ev.preventDefault()}
      // Margin is necessary to clearly show the cursor adjacent to the button
      render={style}
    >
      <InlineChromiumBugfix />
        {children}
      <InlineChromiumBugfix />
    </Span>
);

export {
    EditableButtonComponent,
}