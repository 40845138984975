import { FilterContext } from "./DataGrid";

import {
    useContext,
    useEffect,
    useLayoutEffect as useOriginalLayoutEffect,
    useRef
} from 'react';

export const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

export function useFocusRef(isSelected) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!isSelected) return;
    ref.current?.focus({ preventScroll: true });
  }, [isSelected]);

  return {
    ref,
    tabIndex: isSelected ? 0 : -1
  };
}

export function FilterRenderer({
  isCellSelected,
  column,
  children
}) {
  const filters = useContext(FilterContext);
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  return filters?.enabled ? children({ ref, tabIndex, filters })  : column.name
}

