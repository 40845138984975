import { passControlState } from "@mosanic/utils/schema";
import { createContext, useContext } from "react";

export const TabContext = createContext(undefined);

const Tabs = ({fields, editor, ref, active}, children) => (
    <TabContext.Provider value={editor}>
        {children}
    </TabContext.Provider>
)




const TabHeader = ({fields, editor, ref, active}, children) => {
    const {source, handler} = editor;
    const ctx = useContext(TabContext)
    
    const controlProps = {
        control: {
            active: source?.identifier === ctx?.source?.active,
            notActive: source?.identifier != ctx?.source?.active,
        },
        handler: () => ctx?.handler({value: source?.identifier, extend: 'active'})
    }

    return (
        <div
            onClick={() => ctx?.handler({value: source?.identifier, extend: 'active'})}
        >
            {source?.content}
            {passControlState({children, controlProps})}
        </div>
    )
}

const Tab = ({fields, editor, ref, active}, children) => {
    const {source, handler} = editor || {source: null, handler: null}
     const ctx = useContext(TabContext)
     if(ctx && ctx?.source?.active) {
        if(source?.identifier != ctx.source.active) return null;
     }
    return (
        <>
        {children}
        </>
    )
}

export default Tabs;
export {
    TabHeader,
    Tab
}