
import { arrowDownIcon } from '@mosanic/Icons/HelpIcons';
import { useDataProvider } from '@mosanic/core/Provider';

import Input from '@mosanic/fields/Input';
import { Expander, InputGroup } from '@mosanic/items';
import { capitalizeFirst, containsSubstr, removeSpaces, toLowCase } from '@util/Text';
import { useRouter } from 'next/router';
import { useFormContext } from "react-hook-form";
import InView from '../../Items/InView';
import { default as s } from '../Collection.styles';
import { FieldControls } from '../FieldControls';

import {
	ChoiceType,
	FieldButton,
	FieldGroup,
	FieldMarkedImage,
	FieldMeta,
	FieldRelation,
	FieldRepeat,
	FieldSteps,
	FieldUser,
	FieldValidation,
	FieldVisibility
} from './FieldGroups';

import { FieldType } from './FieldType';
import { FieldHeader } from './Header';
import { get } from '@util/Obj';
import { getValue } from '@mosanic/utils/value';


export const CollectionField = ({
	id,
	fieldId, 
	expanded, 
	toggle, 
	index, 
	dragHandle,
	move, 
	remove,
	fieldCount,
	level = 0,
	skipFirstField = false
}) => {
	const {collections, zones, filters} = useDataProvider()

	const {locales, locale} = useRouter()
	const { control, setValue, watch, formState: { isValid } } = useFormContext();

	const source = getValue(watch(), id, false);
	
	const inputProps = {control, fullWidth: true, size: 'small'}

	//Reduce does separated fieldId path to object and get certain key.
	const getField = (key) => getValue(watch(), fieldId, false)?.[key] 
	const getType = i => getField('type')
	const fieldType =  getField('type')

	const isFirstField = Boolean(index === 0) && !skipFirstField;

	const isInList = list => list.split('|').includes(fieldType);
	const isField = field => field === fieldType

	const restrict = {
		meta: isInList('label|label|group') || isFirstField, 
		default: isInList('repeater|relation|label|group') || isFirstField,
		localize: !getField('localized') || locales?.length < 2,
		validation: !isInList("text|textarea|email|number|link|visual|code|repeater|button|relation|select"),
		visibility: isInList('label|label|group'),
	}
	const groupProps = {
		control, fieldId, fieldType,
		getField: f => getField(f)
	}

	let options = getField('values')?.split('\n')
	if(containsSubstr(options?.[0], ':')) options = options.map(o => ({
		value: removeSpaces(o.split(':')[0]), 
		label: o.split(':')?.[1] ? o.split(':')[1] : o.split(':')[0]
	}))

	return (
		<s.FieldEntry key={fieldId}>
			{/* {JSON.stringify(getValue(watch(), fieldId, false))} */}
			<FieldHeader 
				isFirstField={isFirstField}
				displayName={filters?.displayName} 
				duplicate={[...source?.map((f, i) => i != index && f?.name)]}
				restricted={'_id'.split('|')}

				{...{inputProps, expanded, level, fieldId, index, getField, getType, toggle}}
				controls={!isFirstField ?
					<FieldControls 
						{...{dragHandle, remove, move}}
						{...{index, count: fieldCount}}
						extra={!isFirstField && (
							<s.HeaderToggle 
								tabindex="1"
								expanded={expanded?.[index]}
								disabled={!getField('name')}
								onClick={() => getField('name') ? toggle(index) : null}
							>
								{arrowDownIcon}
							</s.HeaderToggle>
						)}
					/> : <div style={{width: '100px'}}></div>
				}
			/>
			<Expander expanded={expanded?.[index]} fullWidth>
				<InView>
			<s.FieldEntryFields>
			
			<FieldType {...{
				isFirstField,
				inputProps,
				fieldId,
				setValue,
				fieldType
			}}/>

			{'code' === fieldType && (
				<InputGroup>
					<Input name={`${fieldId}.codeLang`} label="Code language" type="select" 
					options={"js|html|css|json".split("|")}
					control={control} />
					
				</InputGroup>
			)}
			<Input 
				name={`${fieldId}.label`} label="Label" {...inputProps} 
			/>

			<Input 
				name={`${fieldId}.help`} label="Help text" type="textarea" {...inputProps} 
			/>

			{!restrict.default && (
				<Input 
					field={watch()?.[id]?.[index]}
					name={`${fieldId}.default`} 
					setup
					direction={getField('direction')}
					label="Default value" 
					type={fieldType} 
					options={
						getField('options') ? getField('options')?.split('\n') :
						fieldType === 'select' ? options : null
					}
					noCase={getField('localized') && locales?.length >= 2}
					language={fieldType === 'code' ? toLowCase(getField('codeLang')) : undefined} 
					{...inputProps} 
				/>
			)}	
			{!restrict.localize && (
				locales.filter(l => l != locale).map(lang => (
					<Input 
						key={fieldId+''+lang}
						name={`${fieldId}.default_${lang}`} 
						label={`Default value ${lang}`}
						type={fieldType} 
						noCase
						language={fieldType === 'code' ? toLowCase(getField('codeLang')) : undefined} 
						{...inputProps} 
					/>
				))
			)} 
			

			<FieldMeta {...groupProps}fieldId={fieldId}  disabled={restrict.meta} />
			<FieldVisibility {...groupProps} fieldId={fieldId} disabled={restrict.visibility} />
			<FieldValidation {...groupProps} fieldId={fieldId} disabled={restrict.validation} />
			
			<FieldMarkedImage 
				{...groupProps} 
				collections={collections}
				show={isField("markedimage")}
			/>

			<FieldRelation 
				{...groupProps} 
				collections={collections} 
				zones={zones}
				setValue={setValue} 
				show={isField("relation")}
			/>

			{("select" === fieldType) && (
				<InputGroup border cols={2} label="Select settings">
					<Input name={`${fieldId}.multiple`} label="Multiple values" type="switch" control={control} />
					<Input name={`${fieldId}.searchable`} label="Searchable" type="switch" control={control} />
					<Input name={`${fieldId}.values`} fullWidth label="Selectable Values" placeHolder={`Example: \nmain \nsub \nthird`} type="textarea" control={control} />
				</InputGroup>
			)}

			<ChoiceType fieldId={fieldId} fieldType={fieldType} control={control} 
				show={isInList("radio|checkbox|buttonGroup")} 
			/>

			<FieldUser {...groupProps} show={isField("user")} />
			<FieldGroup fieldId={fieldId} level={level} show={isInList('group|column')} /> 
			<FieldRepeat fieldId={fieldId} level={level} show={isField('repeater')}
				label={`${capitalizeFirst(fieldType)} fields`}
			/> 
			<FieldSteps fieldId={fieldId} level={level} show={isField('steps')}
				label={`${capitalizeFirst(fieldType)} fields`}
			/> 

			<FieldButton {...groupProps} show={isField("button")} getField={getField} schema={watch()?.schema} collectionId={watch()?._id} trigger={watch()?.trigger} />





			</s.FieldEntryFields>
			</InView>
			</Expander>
		</s.FieldEntry>

	);
};
