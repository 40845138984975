
import { isAction } from "@redux/util";

import { addFontSourceAction, setFontsAction, TYPE_ACTION_IDS as actions } from './action';
import { typesSelector } from "./reducer";


export const handleRegenFontSizes = ({getState, dispatch}) => next => action => {
  
  if(isAction(action, actions.REGEN_SIZES)){
    const state = getState();
    const {fonts, fontScale, baseSize} = typesSelector(state)
    const newFontMap = fonts.map(font => ({
      ...font,
      shade: '60',
      size: (baseSize && font.size && font.scale) ? (
          font.scale != 0 ? 
            getScaled(font, baseSize, fontScale) : 
            baseSize.toString()
        ) : font.size
    }))
    dispatch(setFontsAction({fonts: newFontMap}))

  } else {
    next(action);
  }
};

export const handleFontChange = ({getState, dispatch}) => next => action => {
  
  if(isAction(action, actions.SET_CHANGE)){
    const state = getState();
    const {
      font, 
      currentItem, 
      addSource, 
      fontType
    } = action.payload;

    const {fonts, fontScale, baseSize} = typesSelector(state)
    
    
    if(addSource && font?.files) {
        Object.entries(font.files).forEach((key, value) => {
          dispatch(addFontSourceAction({family: font.family, type: key, src: value}))
        })
    } 

    if(currentItem) {
      const newFontMap = fonts.forEach(e => {

          if(e.type != currentItem.type) return e;
          return {
            ...e,
            weight: fontType ? fontType : 'regular',
            font: font?.family ? font.family : font.activeFontFamily
          } 
      });
      dispatch(setFontsAction({fonts: newFontMap}))

      // regenerateFontSizes();
  }

  } else {
    next(action);
  }
};

const getScaled = (font, baseSize, fontScale) => {
  let index = 0;
  let copy = baseSize;
  while (index < font.scale) {
      copy = (copy * fontScale).toFixed(1);
      index++;
  }
  return copy.toString();
}


export const typesMdl = [
  handleRegenFontSizes,
  handleFontChange
];
