 import { EDIT_PASSWORD, UPDATE_USER } from "@api/mutations/users";
import { isAuthenticated } from "@api/token";
import { useMutate } from "@hooks/useApi";
import { useGetUser } from "@mosanic/cms/Query/byId";
import { useNotify } from "@mosanic/hooks";
import { userIdSelector } from "@redux/auth/reducer";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUpdateUser = () => {   
    const userId = useSelector(userIdSelector)
    const isAuth = isAuthenticated()
    const [user] = useGetUser({_id: userId})
    const [update, [data, loading, errorUpdate]] = useMutate('userUpdateById', UPDATE_USER)
    const notify = useNotify()


    const handler = useCallback(({_id, user}) => {
        const diffUser = userId != _id;

        if(!_id) { notify('No user _id received.'); return;}
        if(!isAuth) { notify('Current user not authenticated.');return; }

        if(diffUser && !user?.isAdmin) { notify('You can not update another user.');return; }
        
        const record = {
            ...user,
            firstName: undefined,
            lastName: undefined,
            _id: undefined
        }
        update({_id, record}, diffUser).then(res => {
            let {error, data} = res;
            if(data?.userUpdateById) data = data.userUpdateById

            const {recordId} = data || {recordId: null}

            if(!error && !errorUpdate && recordId) {
                notify('Successfully updated user.', 'app', 'success');
            }
            if(error){
                console.log(error)
                notify('Could not update user.');
            }
        
        })
 
    },[userId, user?.isAdmin, isAuth, update, notify, errorUpdate]);

    return [
        handler, 
        data, 
        loading, 
        errorUpdate, 
    ]
}

export const useUpdatePassword = () => {   
    const isAuth = isAuthenticated()
    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const [update, [data, loading, errorUpdate]] = useMutate('editUserPassword', EDIT_PASSWORD)
    const notify = useNotify()
    const dispatch = useDispatch()

    const handler = useCallback(({_id, password, oldPassword}) => {
        const diffUser = userId != _id;

        if(!_id) { notify('No user _id received.'); return;}
        if(!isAuth) { notify('Current user not authenticated.');return; }

        if(diffUser && !user?.isAdmin) { notify('You can not update another user.');return; }
        if(!password || !oldPassword) { notify('Did not receive credentials.');return; }
        const variables = {
            _id ,
            password ,
            oldPassword
        }
        update(variables).then(res => {
            const {error, data} = res;

            if(!error && !errorUpdate && data) {
                notify('Successfully updated password.', 'app', 'success');
            }
            if(error){
                console.log(error)
                notify('Could not update password.');
            }
        
        })

    },[userId, user?.isAdmin, isAuth, update, notify, errorUpdate]);

    return [
        handler, 
        data, 
        loading, 
        errorUpdate, 
    ]
}
