const ID = '[data]'
export const DATA_ID = ID;

const DATA_INIT             = `${ID} Init`;
const DATA_COPY             = `${ID} Copy`;
const DATA_ADD              = `${ID} Add`;
const DATA_UPDATE           = `${ID} Update`;
const DATA_EXTEND           = `${ID} Extend`;
const DATA_REMOVE           = `${ID} Remove`;

const DATA_CONVERT          = `${ID} Convert`
const DATA_SET          = `${ID} Set`
const DATA_REPLACE_STORE = `${ID} Replace store`

export const convertDataAction = ({_id, group}, path, target = 'items') => ({
    type: `${DATA_CONVERT} to ${target}`,
    payload: {_id, group, path, target}
})

export const setDataAction = ({data}) => ({
    type: DATA_SET,
    payload: data,
    meta: {feature: ID},
})

export const initDataAction = ({component}, options = null) => ({
    type: `${DATA_INIT} ${component.type}`,
    payload: component,
    meta: {feature: ID},
    options
});
export const copyDataAction = ({component}, sourceId, options = null) => ({
    type: `${DATA_COPY} ${component.type}`,
    payload: component,
    meta: {feature: ID, sourceId},
    options
});

export const addDataAction = ({component}, handled = false) => ({
    type: !handled ? `${DATA_ADD} ${component.type}` : DATA_ADD,
    payload: component,
    meta: {feature: ID}
});
export const replaceFullDataStoreAction = ({data}) => ({
    type: DATA_REPLACE_STORE,
    payload: data,
    meta: {feature: ID}
});
export const updateDataAction = ({component}, handled = false) => ({
    type: !handled ? `${DATA_UPDATE} ${component.type}` : DATA_UPDATE,
    payload: component,
    meta: {feature: ID}
});
export const extendItemDataAction = ({itemMap, dropPath}) => ({
    type: DATA_EXTEND,
    payload: {itemMap, dropPath},
    meta: {feature: ID}
});
export const removeDataAction = ({component}, sourceId, handled = false) => ({
    type: !handled ? `${DATA_REMOVE} ${component.type}` : DATA_REMOVE,
    payload: component,
    meta: {feature: ID, sourceId}
});



const DATA_ACTION_IDS = {
    DATA_INIT,
    DATA_COPY,
    DATA_ADD,
    DATA_UPDATE,
    DATA_REMOVE,
    DATA_EXTEND,

    DATA_SET,
    DATA_CONVERT,
    DATA_REPLACE_STORE
};

export const dataActions = {
    initDataAction,
    copyDataAction,

    removeDataAction,
    updateDataAction,
    addDataAction,
    extendItemDataAction,

    convertDataAction
};

export default DATA_ACTION_IDS;