import { Condition } from "@JSX";
import renderComponent from "@mosanic/builder/registery";
import { Items } from "@mosanic/constants";
import { useEditorMenu } from "@mosanic/hooks";
import { hasSlots, manageSlots } from "@mosanic/utils/map/slots";
import { useSchemaFieldsRef } from "@mosanic/utils/schema";
import { selectInspectItemScreen } from "@redux/inspect/reducer";
import { isFunc } from "@util/Funct";
import { hasAttr } from "@util/Obj";
import { getTag } from "@util/Tag";
import { isString } from "@util/Text";
import Link from "next/link";
import { createContext, memo } from "react";
import { useSelector } from "react-redux";
import { useValidate } from "../../hooks/useValidate";
import ItemControls from "./ItemControls";
import Row from "./ItemRow";
import { Component } from "./Styles/Item.styles";


const Wrapper = ({props, children}) => {
    if(props?.child?.itemType === Items.ROW)
        return <Row props={{...props, children}} menu={{render: 'Column'}} />

    return children;
}

const controls = {
    column: {render: 'Column'},
    stack: {render: 'Stack'},
    repeater: {render: 'Repeater'},
}

export const FieldsContext = createContext(undefined);
 
const ChildRender = ({child, reference, children}) => {
    const Render = renderComponent(child.type) 
    const slotComponent = hasSlots(child?.children)
    const manageComponent = manageSlots(child?.children)

    return slotComponent ? (
        <FieldsContext.Provider value={reference}>
            {(isFunc(Render) && manageComponent) ?  
                Render(reference, children) :
                children    
            }
            {/* {Render && <Render {...{reference, children}} />} */}
        </FieldsContext.Provider>
    ) : (
        <>
        {isFunc(Render) &&  Render(reference) }
        {children}
        </>
    )
}
const Item = ({children, props, ...other}) => {
    const {item, child, path, level, breakpoint, hover, inspect, ref} = props;
    const {onClick, isInspected, contextMenu, setContextMenu} = inspect;
    const [showEditor, toggleEditor] = useEditorMenu(screen);
    

    const validate = useValidate(item?.state, breakpoint, false)

    const reference = useSchemaFieldsRef({
        active: Boolean(showEditor && isInspected),
        item,
        clone: other?.clone,
        breakpoint ,
    });
    

    let linkHref = hasAttr(reference?.fields, 'href') ? {
        href: reference?.fields?.href,
        target: reference?.fields?.target
    } : null;

    
    const className = other?.clone ? `${item?.className}${other?.clone}` : item?.className;
    const actionsStyle = item?.isCut ? {opacity: 0.2} : null;

    return (
        <Condition when={linkHref?.href && false} wrapper={children => (
            <Link
                href={linkHref.href} 
                target={linkHref.target}>
                    {children}
            </Link>
        )}>
            <Component
                style={actionsStyle} 
                as={isString(item?.data?.tags?.tag) ? item.data.tags.tag : getTag(item?.type)}
                type={item?.type != 'textarea' ? item?.type : 'div'}
                onContextMenu={onClick}
                onClick={onClick} 
                ref={ref}
                className={className}
                childs={child?.children?.length}

                validate={validate}
                data={item?.data}
                {...hover}
            >
                {/* {isInspected && ref?.current?.getBoundingClientRect()?.top}
                {inspectScreen}{breakpoint} */}
                {/* {JSON.stringify(item?.data?.tags)} test */}
                {/* {hover?.hoverState ? 't' : 'f'} */}
                <Wrapper props={props}>
                    <ItemControls menu={{
                        setMenu: setContextMenu,
                        data: item,
                        ...controls[item.type],
                        ...contextMenu
                    }} />


                    <ChildRender child={child} reference={reference}>
                        {children}
                        
                    </ChildRender>
                    
                </Wrapper>
            </Component>
        </Condition> 
    )
}
const memoizedChildItem = memo(Item)
memoizedChildItem.displayName = 'memoizedChildItem';
export default memoizedChildItem