export const HOVER_ID                 = `[hover]`;
const ID = HOVER_ID;

export const HANDLE_HOVER              = `${ID} Handle`;
export const SET_HOVER_ELEMENT         = `${ID} Set`;

export const TOGGLE_LAST_HOVER         = `${ID} Toggle last`;
export const RESET_HOVER_BOUNDS        = `${ID} reset bounds`;
export const CLEAR_HOVER_BOUNDS        = `${ID} clear bounds`;
export const RESET_BOUNDS_STATE        = `${ID} reset state`;

export const ADD_BOUNDS_SCREEN         = `${ID} add screen`;

export const SET_INSPECTED_BOUNDS    = `${ID} Set i bounds`
export const SET_INSPECT_SCREEN_BOUNDS    = `${ID} Set inspect [screen] bounds`
export const UPDATE_INSPECT_BOUNDS    = `${ID} Update inspect bounds`

export const setHoverAction = ({item, state, map, bounds, screen}) => (dispatch) => {
    const minified = {
        title: item?.title,
        type: item?.type,
        icon: item?.icon,
        _id: item?._id,
        clone: item?.clone
    };
    dispatch({
        type: HANDLE_HOVER,
        payload: minified,
        meta: {
            state, 
            map, 
            bounds, 
            screen
        }
    })
}

export const addBoundsScreenAction = ({screen}) => ({
    type: ADD_BOUNDS_SCREEN,
    payload: screen, 
    meta: {feature: ID}
})

export const updateInspectBounds = (path, bounds, screen = 'base') => ({
    type: UPDATE_INSPECT_BOUNDS,
    payload: bounds,
    meta: {feature: ID, path, screen}
});


export const setInspectBoundsAction = ({component, screen, bounds}) => ({
    type: SET_INSPECTED_BOUNDS,
    payload: {component, screen, bounds},
    meta: {feature: ID}
})
export const setInspectScreenBounds = (action) => ({
    type: SET_INSPECT_SCREEN_BOUNDS,
    payload: action.payload,
    meta: action.meta
})

export const toggleLastHover = (action) => ({
    type: TOGGLE_LAST_HOVER,
    payload: action.payload,
    meta: action.meta
}); 
export const setHoverElement = (action) => ({
    type: SET_HOVER_ELEMENT,
    payload: action.payload,
    meta: action.meta
})

export const resetHoverBounds = (source) => ({
    type: RESET_HOVER_BOUNDS,
    payload: source,
    meta: {feature: "LAYOUT_ID"}
})
export const resetBounds = (source) => ({
    type: RESET_BOUNDS_STATE,
    payload: source,
    meta: {feature: "LAYOUT_ID"}
})
