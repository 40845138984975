import styled from 'styled-components';
import { default as s } from '../Input/Input.styles';
import { layout, space } from 'styled-system';
const Container = s.Container;

export const EditorContainer = styled(Container)`
    display: block;

    [role="textbox"] {
        ${s.BaseField}
    }
    ${layout}
    ${space}
`;
export const EditorLabel = s.Label
