import {
  ErrorBoundary,
  ErrorFallback,
  MosanicCoreProviders,
  RoutingProvider
} from '@mosanic/routing';

import { ApolloProvider } from '@apollo/client';
import { reduxWrapper } from '@redux/store';
import NextNProgress from "nextjs-progressbar";

import { GoogleAnalytics, event } from "nextjs-google-analytics";


import '@animated-burgers/burger-squeeze/dist/styles.css';

import 'normalize.css/normalize.css';

import 'rc-dialog/assets/index.css';
import 'react-ig-feed/dist/index.css';
import 'react-slidedown/lib/slidedown.css';
import "../scss/main.scss";
import { client } from '@api/client';
import ThemeWrapper from '@mosanic/core/Layout/ThemeWrapper';

export function reportWebVitals(metric) {
  const { id, name, label, value } = metric;

  event(name, {
    category: label === "web-vital" ? `Mosanic Web Vitals` : `Mosanic ${label} metric`,
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

const MosanicApp = ({ 
  Component, 
  pageProps, 
  router 
}) => {
  return (
  <ErrorBoundary FallbackComponent={ErrorFallback} suppressHydrationWarning>
    <ApolloProvider client={client}>
      <NextNProgress 
        color="#e1e1e1"
        // startPosition={0.3}
        // stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
        <MosanicCoreProviders>
          <RoutingProvider router={router}>
            <GoogleAnalytics gaMeasurementId={pageProps?.analytics?.gaMeasurementId || "G-ZQS1M1QEYL"}/>
            <Component {...pageProps} suppressHydrationWarning/>
          </RoutingProvider>
        </MosanicCoreProviders> 
      </ApolloProvider>
      {pageProps?.analytics?.analyticsId && (
        <script async defer data-website-id={pageProps?.analytics?.analyticsId} src="https://analytics.mosanic.io/umami.js"></script>
      )}
  </ErrorBoundary>
);
  }
 
export default reduxWrapper.withRedux(MosanicApp);