import { Fragment } from 'react';

import Input from '@mosanic/fields/Input';
import { useFormContext } from "react-hook-form";
import { siteIdSelector } from '@redux/auth/reducer';
import { useSelector } from 'react-redux';
import { usePages } from '@mosanic/components/Pages/Resolvers/usePages';
import { InputGroup } from '@mosanic/items';
import { capitalizeFirst } from '@util/Text';


export const BaseCollection = ({dataType = 'collection'}) => {
	const { handleSubmit, control, watch, formState: { errors, isValid } } = useFormContext();
	const inputProps = {control, fullWidth: true, errors, wrap: true}
    const siteId = useSelector(siteIdSelector)

    const pages = usePages({siteId, pagesOnly: true}) 
    const example = dataType === 'collection' ? 
        "User cards are shown in the contact page" :
        "Main footer contact form";

    dataType = watch()?.type || dataType
	return (
		<Fragment>

            <InputGroup cols={2} border label={`${capitalizeFirst(dataType)} info`} mt={0}>
                <Input 
                    name="title" label={`${dataType} name`} type="text" {...inputProps} required
                    rules={{required: `Shorthand name that defines this ${dataType}`}}
                />

                <Input 
                    name="description" label="Description" type="textarea" {...inputProps} required
                    rules={{required: `Explain the purpose of this ${dataType} and give some context. For example, ${example}`}}
                />

                {/* // TODO add field to colleciton api */}

                <Input 
                    name="singular" label="Singular entry name" type="text" {...inputProps} required
                    rules={{required: 'How should we call a single entry. For example, articles collection => "Article"'}}
                />
            </InputGroup>
        
            <Input name="type" label="Collection type" type="buttonGroup" options={'collection|form'.split('|')} {...inputProps} />

            <InputGroup cols={2} border label={dataType === 'form' ? "Form options" : "Routing"} style={{paddingTop: 5, marginTop: 25}}>
                {dataType === 'collection' && (
                    <>
                    <Input 
                        key="catchRoutes" name="catchRoutes" label="Catch routes" type="switch" {...inputProps} fullWidth={false}
                    />
                    <Input 
                        key="frontEnd" name="frontEnd" label="Front end" type="switch" {...inputProps} disabled={!watch()?.catchRoutes} fullWidth={false}
                    />
                   </>
                )}
                here
                {(watch()?.catchRoutes && dataType === 'collection') && (
                    <>sss
                    <Input 
                        name="baseRoute" key="baseRoute" label="Base route" {...inputProps} type="text"
                        fullWidth={false}
                    />ss
                    <Input 
                        name="dynamicPage" key="dynamicPage" label="Dynamic page" type="select" {...inputProps}
                        options={pages?.length >= 1 ? pages.map(p => ({label: p.title, value: p._id})) : ['No pages setup']}
                        fullWidth={false}
                    />
                    {(watch()?.frontEnd && dataType === 'collection') && (<>
                        <Input 
                            key="editRoute" name="editRoute" label="Edit route" type="text" {...inputProps}
                            fullWidth={false}
                        />
                        <Input 
                            key="editPage" name="editPage" label="Edit page" type="select" {...inputProps}
                            options={pages?.length >= 1 ? pages.map(p => ({label: p.title, value: p._id})) : ['No pages setup']}
                            fullWidth={false}
                        />
                        </>
                    )}
                    <Input 
                        name="groupPage" label="Index page" type="select" {...inputProps}
                        options={pages?.length >= 1 ? pages.map(p => ({label: p.title, value: p._id})) : ['No pages setup']}
                        fullWidth={false}
                    />
                    <Input 
                        name="notFoundPage" label="404 page" type="select" {...inputProps}
                        options={pages?.length >= 1 ? pages.map(p => ({label: p.title, value: p._id})) : ['No pages setup']}
                        fullWidth={false}
                    />
                    </>
                )}
                {(dataType === 'form') && (
                    <>
                        <Input name="stepType" label="Stepper flow" helpText="Each field is displayed in a separate step. To combine fields in a step, use a group field." type="switch" {...inputProps} fullWidth={false} />

                        <Input name="form.data" label="Auto delete" type="select" options={'7 days|14 days|1 month|3 months|6 months|fill-up'.split('|')} {...inputProps} fullWidth={false} />
                    </>
                )}
            </InputGroup>
        
			
			<Input name="limit" label={dataType === 'collection' ? "Limit entries" : 'Limit is defined by membership package'} type="number" disabled={dataType != 'collection'} {...inputProps} />

             
                {/* {watch()?._id} */}
		</Fragment>
	);
};
