import { FormItems } from '@mosanic/fields/Form';
import { InputGroup } from '@mosanic/items';
import { containsSubstr, getNumberPartOfString } from '@util/Text';
import { useFormContext } from 'react-hook-form';

const formatInterval = v => {
    const interval = parseFloat(getNumberPartOfString(v));
    if(containsSubstr(v, 'd')) return `${interval} day${interval != 1 ? 's' : ''}`
    if(containsSubstr(v, 'm')) return `${interval} month${interval != 1 ? 's' : ''}`
    if(containsSubstr(v, 'y')) return `${interval} year${interval != 1 ? 's' : ''}`
    return v;
}
export const TwoFactorAuth = ({handler, updating = false, user}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } } = useFormContext({mode: 'all', defaultValues: user});
	const inputProps = {control, errors}

	return (
        <InputGroup >
            <FormItems controls={inputProps} fields={[{    
                name: 'security.two-factor',
                label: 'Two factor enabled',
                desc: 'Verify your login with your email.',
                type: 'switch',
                value: true,
                disabled: true
                // fullWidth: true
            },{    
                name: 'security.interval',
                label: 'Verify interval',
                desc: 'Change the interval of your two-factor authentication verification.',
                type: 'select',
                options: 'instant|1d|2d|7d|14d|1m|3m|6m|1y'.split('|').map(value => ({
                    value,
                    label: formatInterval(value)
                })),
                // fullWidth: true
            }]}
            />
        </InputGroup>
	);
};