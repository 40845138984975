import { UPDATE_COLLECTION } from "@api/mutations/collections";
import { useUpdate } from "./useDataMutate";
import { UPDATE_ENTRY } from "@api/mutations/entry";
import { getEntryLabel } from "@mosanic/fields/Source/helpers";
import { convertEntryBack } from "../Query/useDataQuery";
import { UPDATE_USER } from "@api/mutations/users";
import { capitalizeFirst } from "@util/Text";
import { useEditTeam } from "@mosanic/components/Team/Handlers/useEditTeam";
import { UPDATE_MAIL } from "@api/mutations/mail";
import { UPDATE_CHAT } from "@api/mutations/chats";

const useUpdateItem = ({formatter, mutation, type, siteCheck = true, silent = false, ...other}) => {
    const [update, [data, loading, error]] = useUpdate({
        formatter,
        mutation,
        type,
        silent,
        siteCheck,
        ...other
    });

    return [update, [data, loading, error]]
}


export const useUpdateCollection = () => useUpdateItem({
    mutation: UPDATE_COLLECTION,
    type: 'collection',
    formatter: data => ({
        _id: data?._id,
        record: {
            ...data,
            updatedAt: new Date(),
            _id: undefined
        }
    })
});


export const useUpdateEntry = ({collection, ...other}) => {
    const {_id, schema} = collection || {_id: null, schema: []}
    const formatter = data => ({
        record: {
            ...convertEntryBack(data, schema),
            collectionId: _id,
            _id: undefined
        },
        _id: data?._id,
    })

    return useUpdateItem({
        mutation: UPDATE_ENTRY,
        type: 'entry',
        formatter,
        ...other
    });
}

export const useUpdateUser = (silent = false) => {
    const formatter = data => ({
        record: {
            ...data,
            updatedAt: new Date(),
            firstName: undefined,
            lastName: undefined,
            status: capitalizeFirst(data?.status),
            status: undefined,
            slug: undefined,
            date: undefined,
            type: undefined,
            updatedAt: undefined,
            _id: undefined
        },
        _id: data?._id
    })

    return useUpdateItem({
        mutation: UPDATE_USER,
        type: 'user',
        siteCheck: false,
        loggedInCheck: false,
        formatter,
        silent
    });
}


export const useUpdateTeam = ({callback}) => useEditTeam({callback})


export const useUpdateMail = () => useUpdateItem({
    mutation: UPDATE_MAIL,
    type: 'mail',
    formatter: data => ({
        _id: data?._id,
        record: {
            ...data,
            updatedAt: new Date(),
            Fields: undefined,
            Entries: undefined,
            _id: undefined
        }
    })
});

export const useUpdateChat = () => useUpdateItem({
    mutation: UPDATE_CHAT,
    type: 'chat',
    siteCheck: false,
    silent: false,
    formatter: data => ({
        _id: data?._id,
        record: {
            ...data,
            updatedAt: new Date(),
            _id: undefined
        }
    })
});