import { Fragment } from 'react';

import { LOGIN } from '@api/mutations/auth';
import { useMutate } from '@hooks/useApi';
import { Button } from '@mosanic/fields';
import Input from '@mosanic/fields/Input';
import { useNotify } from '@mosanic/hooks';
import { InputGroup, InputGroupItem, Link, LoadingDots } from '@mosanic/items';
import { saveCookie } from '@util/server/cookie';
import { useForm } from "react-hook-form";
import { SubmitButtonHelper } from '../../components/Core/Utils';
import { validateLoginForm } from '../../utils/server/validations';

import { AUTH_EMAIL_REF } from '@api/constants';
import { activateAuthAction } from '@redux/auth/action';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

export const LoginForm = ({ mode, setMode , verify, redirect, accountActions = true, setStep = null}) => {
	const { handleSubmit, control, watch, formState: { errors, isValid } } = useForm({mode: 'all'});
	const inputProps = {control, fullWidth: true, errors, size: 'large'}
	const router = useRouter()

	const dispatch = useDispatch();

	const [loginUser, [login, loading, error]] = useMutate('loginUser', LOGIN)
	const notify = useNotify()

	const onSubmit = (data) => {
		const remember = data.remember
		const variables = {...data, verify, templateId: 'd-44b2808280d04801b306a70f217c6bcd'}
		loginUser(variables).then((response) => {
			const res = response;
			let {data, error} = res;

			if(data?.loginUser) data = data?.loginUser;
			if(data && !error){
				if(data?.status === 200 && data?.response){
					if(verify) {
						setMode({step: 'verification', email: variables?.email, remember})
						notify("Verification code send to your email.", '[login]', 'info')
						saveCookie(AUTH_EMAIL_REF, variables?.email);
					} else if(data?.status === 200) {
						if(redirect) router.push(redirect)
						dispatch(activateAuthAction({token: data.token, remember: true}))
					} else {
						notify("Could not login, refresh and try again.", 'login', 'error')
					}
					
				} else {
					notify("Unknown response.", '[login]', 'info')
				}
			}
			if(error) {
				notify("Could not login, refresh and try again.", 'login', 'error')
			}
		});
		
	};
	// useEffect(() => {
	// 	if(login?.token && login?.status === 200) {
	// 		activateAuth({token: login.token, remember:true});
	// 	}
	// },[login, activateAuth])

	// email: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
// /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!*^?+-_@#$%&]{8,}$/
	//form-row mb-3
	return (
		<Fragment>

			<form  onSubmit={handleSubmit(onSubmit)}>
					<InputGroup center style={{justifyContent: 'center'}}>
						<Input 
							style={{maxWidth: '300px'}}
							name="email" type="email" {...inputProps}
							rules={{required: "You should enter in you're email"}}
						/>
						<Input 
							style={{maxWidth: '300px'}}
							name="password" placeholder="****" type="password" {...inputProps}
							rules={{required: "You should enter in you're password"}}
						/>
						<InputGroupItem mb={4} fullWidth>
							<Input label=" Remember me" name="remember" {...inputProps} type="checkbox" />
						</InputGroupItem>
						<Button 
							
							width={[1, '200px', '250px']}
							label={loading ? <LoadingDots /> : "Log in"}
							theme="primary"
							size="large"
							type="submit"
							disabled={Boolean(!isValid)}
						/>
					</InputGroup>
					
					<SubmitButtonHelper 
						action="Login"
						mustShowHelper={!validateLoginForm(watch().email , watch().password)}
					/>
				
			</form>

			{error}
			{accountActions && (
				<div className="form-bottom text-center">
					<br/>
					<p className="sign-up-text">
						Don&apos;t have an account?{" "}
						{setStep ? (
							<a onClick={() => setStep('register')}>
								Register
							</a>
						) : (
							<Link href="/register" color="primary">

								Register
							</Link>
						)}
					</p>
					<p className="sign-up-text" style={{opacity: 0.7, fontSize: '70%'}}>
						Want to reset your password?{" "}
						<Link href="/reset" color="primary">
							Reset
						</Link>
					</p>
				</div>
			)}
		</Fragment>
	);
};
