import { clearPreStoreAction, DROP_ID } from "@redux/map/drop/action";
import { showSpinner } from "@redux/ui/uiAction";
import { dataActions } from "@redux/map/data";
import { mapActions } from "@redux/map";

import { convertItemMap, copyAndTransformItemMap } from "./transform";
import { transformInitialItemFull } from "../data";
import { splitPath } from "./paths";
import { ObjectID } from "../ObjectID";



const SPINNER = {location: 'canvas', feature: DROP_ID };

export const setStartStateDropAction = dispatch => {
    dispatch(clearPreStoreAction())
    dispatch(showSpinner(SPINNER)); 
}

export const transformDropActionPayload = action => {
    const {dropZone, item} = action.payload;
    const dropPath = splitPath(dropZone?.path);
    return [dropZone, item, dropPath]
} 



export const handleNewItemDropAction = ({dispatch, item, dropZone, dropPath}) => {
    const [itemMap, component] = transformInitialItemFull({item}, dispatch);

    dispatch(dataActions.initDataAction({component}));

    if(dropZone?.slot){
        dispatch(dataActions.extendItemDataAction({itemMap, dropPath}));
    } else {
        dispatch(mapActions.addMapAction({itemMap, dropPath}));
    }
}

export const handleNewLayoutDropAction = ({dispatch, item, dropZone, dropPath}) => {
    const getChild = (child) => item.data[child.group][child._id]

    //Copy the child from the layout data and change the _id of it
    const copyTransform = child => {
        // console.log(getChild(child))
        const newId = ObjectID();
        const component = {
            ...getChild(child),
            _id: newId
        }
        console.log({newId, oldId: child._id})
        dispatch(dataActions.addDataAction({component}, true));
        return {...child, _id: newId}
    }
    //Recursive loop and create a new map
    const itemMap = convertItemMap([item.map], copyTransform)?.[0];

    dispatch(mapActions.addMapAction({itemMap, dropPath}));
    console.log(itemMap)
}

export const handleItemMoveDropAction = ({dispatch, item, dropPath}) => {
    const itemPath = splitPath(item.path);
    dispatch(mapActions.moveMapAction({item: {...item, path: undefined}, itemPath, dropPath}));
}
export const handleAddCopiedItemAction = ({dispatch, item, dropPath}) => {
    //DropZone is clicked, but since the item is from the pre_store:
    //Copy and transform its children to a new item map.
    const copySource = {...item, isCopied: undefined, sourceItemPath: undefined}
    dispatch(mapActions.addMapAction({
        itemMap: copyAndTransformItemMap([copySource], dispatch), 
        dropPath
    }));
}
export const handleAddCutItemAction = ({dispatch, item, dropPath}) => {
    //Item exists already, since it has cut state.
    //Just move it to the clicked dropZone
    dispatch(mapActions.moveMapAction({
        item: {...item, isCut: undefined, sourceItemPath: undefined}, 
        itemPath: item.sourceItemPath,
        dropPath
    }));
}
