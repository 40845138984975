import { setNotification } from "@redux/notification/action";
import { resetPagesAction } from "@redux/pages/action";
import { includesAction, isAction } from "@redux/util";

import { 
    default as actions, 
    removeAuthAction, 
    setSiteId, 
    setUserId, 
    storeAuthAction 
} from "./action";

import { 
    storeUserDataOnSessionStorage 
} from "@util/server/session";

import { 
    clearSession, 
    decodeToken, 
    isAuthenticated, 
    isTokenExpired, 
    storeToken 
} from "@api/token";


const expireValidationFlow = (exp, dispatch) => {
    const expired = isTokenExpired(exp);
    if(expired) dispatch(setNotification({
        message: `Token expired ${expired} ago. Login again.`, 
        feature: 'auth', 
        meta: {variant: 'error', filter: 'Token expired'}
    }))
    return expired;
}


export const validateAuthFlow = ({getState, dispatch}) => next => action => { 
    next(action);
    if(isAction(action, actions.ACTIVATE)) {
        const {token, remember} = action.payload;
        const userData = decodeToken(token);
        const expired = expireValidationFlow(userData?.exp, dispatch);

        if(!userData || expired) {
            clearSession(token);
            const reason = !userData ? 'No user data' : 'Expired token';
            console.log({type: "Validation failed", payload: {userData, expired, token, remember}})

            dispatch(removeAuthAction({redirect: '/login'}, reason))
            dispatch(setNotification({message: reason, feature: '[auth]', variant: 'error'}))
            return;
        } else {
            storeUserDataOnSessionStorage(userData);

            storeToken(token, remember)
            
            dispatch(storeAuthAction({...action.payload, userData}, true))

            dispatch(setUserId({userId: userData._id}))
        }
    } 
};

export const removeAuthFlow = ({getState, dispatch}) => next => action => { 
    next(action)
    if(includesAction(action, actions.REMOVE)) {

        clearSession();
        dispatch(removeAuthAction({redirect: '/login'}, 'remove auth flow', true))
           
    }
}

export const revalidateAuthFlow = ({getState, dispatch}) => next => action => { 
    if(isAction(action, actions.REVALIDATE)) {

        const isAuth = isAuthenticated()
        if(!isAuth) dispatch(
            removeAuthAction({
                redirect: '/login'
            }, 'Revalidate flow')
        )
  
    } else {
        next(action);
    }
}


export const selectSiteFlow = ({getState, dispatch}) => next => action => { 
    next(action)
    if(includesAction(action, actions.SET_SITE_ID)) {
        const siteId = action.payload;
        dispatch(resetPagesAction())

        dispatch(setSiteId({ siteId }, true))

    }
};


  
export const authMdl = [
    removeAuthFlow,
    validateAuthFlow,
    revalidateAuthFlow,
    selectSiteFlow,
]