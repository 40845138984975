import { useEffect, useState } from "react";
import { loopFunc, remove, updateItem as update } from '@util/Arr';
import { replacePart } from "@util/Text";

const convertFileName = name => !name ? name : replacePart(replacePart(
        name.substr(0, name.lastIndexOf("."))
    , '-', ' '), '_', ' ')

export const useMetaFields = ({images}) => useArrayFields({
    array: images, 
    dataFunc: (img, index) => ({
        title: convertFileName(img.file?.name), 
        alt: convertFileName(img.file?.name), 
        folder: '' 
    })
})


export const useArrayFields = ({array, count = null, dataFunc}) => {
    const [arr, setArr] = useState([]);

    useEffect(() => {
        if(count){
            if(count > arr?.length) setArr([...arr, dataFunc(arr?.length)])
            else if(count < arr?.length) setArr(arr.slice(0, -1)) 
        } else {
            if(array?.length > arr?.length) setArr(array?.map((item, index) => dataFunc(item, index)))
            else if(array?.length < arr?.length) setArr(arr.slice(0, -1)) 
        }
    }, [array, count, arr, dataFunc])

    const updateItem = (index, newData) => setArr(update(arr, index, newData));
    const removeItem = (index) => setArr([...remove(arr, index)])
    const addItem = (item) => setArr([...arr, item])
    return [
        arr,
        updateItem,
        removeItem,
        addItem
    ]
}