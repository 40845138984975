import { colors } from "@mosanic/styles"
import { capitalizeFirst } from "@util/Text"


export const roles = 'admin|designer|builder|editor|viewer|all'.split('|')
export const platform_roles = {
    member_active: 'active',
    member_pr: 'pending registration',
    member_r: 'registered',
    member_d: 'disabled',
    member_o: 'overdue',
    administrator: 'admin [wp]'
}

export const list_roles = [
    ...roles.map(value => ({
        label: capitalizeFirst(value),
        value
    })),
    ...Object.entries(platform_roles).map(([value, label]) => ({
        label: capitalizeFirst(label),
        value
    })), 
    {
        value: 'all',
        label: 'Undefined'
    }
]

const status_colors = {
    member_active: colors.util.green.base,
    member_pr: colors.util.green.light,
    member_r: colors.util.gray.light,
    member_d: colors.util.red.dark,
    member_o: colors.util.red.base
}
export const getRole = role => {
    role = list_roles?.find(r => r.value === role);
    return {
        ...role,
        color: status_colors?.[role?.value] ? status_colors[role.value] : colors.util.primary.base
    }
}