import { setNotification } from "@redux/notification/action";
import { useDispatch } from "react-redux";

const ERROR_DURATION = 10000;
const STATUS_DURATION = 4000;

const DURATION = 2500;

const notifyError = () => ({
    duration: ERROR_DURATION, variant: 'error'
})
const notifySuccess = () => ({
    duration: STATUS_DURATION, variant: 'success'
})
const notify = variant => ({
    duration: DURATION, variant
})
const getNotify = type => {
    if(type === 'error') return notifyError();
    if(type === 'success') return notifySuccess()
    return notify(type)
}

const getNotification = (notification, feature, type) => ({
    feature,
    ...(notification?.message ? notification : {message: notification}),
    ...(getNotify(type))
})

const useNotify = () => {
    const dispatch = useDispatch();

    return (notification, feature = 'app', type = 'error') => (
        dispatch(setNotification(getNotification(notification, feature, type)))
    )
}
export default useNotify;