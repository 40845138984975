import { getItemByPath } from "@mosanic/utils/map/get";
import { inspectSelector } from "@redux/inspect";
import { inspectAttrSelector } from "@redux/inspect/selector";
import { itemMapByPathSelector, mapSelector } from "@redux/map/reducer";
import isEqual from "@util/isEqual";
import { useSelector } from "react-redux";


//Validate from inspect store
export const useInspectState = (_id, screen, check = false, clone = null) => {
    const state = {
        id: useSelector(state => 
            inspectAttrSelector(state, '_id'), isEqual
        ),
        screen: useSelector(state => 
            inspectAttrSelector(state, 'screen'), isEqual
        ),
        path: useSelector(state => 
            inspectAttrSelector(state, 'path'), isEqual
        ),
        clone: useSelector(state => 
            inspectAttrSelector(state, 'clone'), isEqual
        ),
        clone: useSelector(state => 
            inspectAttrSelector(state, 'clone'), isEqual
        )
    }; 
    if(!check) return state; //No check just state

    if(_id != state.id) return false; //Diff id
    if(screen === 'any') return true; //Skip screen check
    if(!clone && screen === state.screen) return true; //No clone and current screen
    return clone && clone === state.clone //Validate clone index
};

export const useInspectItemMap = () => {
    const {path} = useInspectState();
    const map = useSelector(mapSelector);
    const itemMap = getItemByPath({map, path});
    
    // console.log('ALERT:', path, itemMap)

    return {...itemMap, path}
}