import { Fragment, useState } from 'react';

import { RESET_PASSWORD } from '@api/mutations/auth';

import { useMutate } from '@hooks/useApi';
import { Button } from '@mosanic/fields';
import Input from '@mosanic/fields/Input';
import { useNotify } from '@mosanic/hooks';
import { InputGroup, Link, LoadingDots } from '@mosanic/items';
import { useForm } from 'react-hook-form';

import { LIST_ALL_USERS } from '@api/queries';
import { useGet } from '@mosanic/cms';
import { useGetMail } from '@mosanic/cms/Query/byId';
import { convertTemplate } from '@mosanic/utils/mail';


export const ResetForm = ({ setMode , accountActions = false, mail = null}) => { 
	const [mailId, templateId] = mail ? mail.split('|') : [null, null]
	const [mail_data, loadingMail] = useGetMail({_id: mailId, silent: true})

	const template = convertTemplate({
		template: mail_data?.templates?.find(t => templateId === templateId), 
		entry: null
	})


	const { handleSubmit, control, watch, formState: { errors, isValid } } = useForm({mode: 'all'});
	const inputProps = {control, fullWidth: true, errors}

	const [disabled, setDisabled] = useState(false);

	const [resetPassword, [rst, loading, resetError]] = useMutate('resetPassword', RESET_PASSWORD)


	const notify = useNotify()


	    const [getUsersMosanic, [mos_users, loadingUsers]] = useGet({
        params: {filter: {siteId: "6303a9537f25bc1630afd84b"}},
        type: 'user',
        method: 'Many',
        query: LIST_ALL_USERS,
        silent: true,
        siteCheck: false
    })

	// const users = mos_users?.filter(u => [
	// 	'63e4af66349309012494ba04',
	// 	'63e4af66349309012494ba0d',
	// 	'63e4af66349309012494b9eb',
	// 	'63e4af66349309012494b9ed',
	// 	'63e4af66349309012494ba07'

	// ].includes(u?._id))

	const onSubmit = (data) => {
		setDisabled(true);

		const variables = {
			...data,
			templateId: mail_data?.data?.templateId,
			mailData: mail_data?.data ? {
				...template,
				mainText: template?.mainText ? template.mainText : template?.content,
				template_name: undefined,
				content: undefined,

				linkHref: 'https://www.swopyourstay.com/reset?resetCode=',
				linkText: ' ',

				// sectionFooterHeader: undefined,
				// sectionFooterText: undefined,

				template_id: undefined,
			} : undefined,
			from: mail_data?.data?.defaultSender 
		};

		resetPassword(variables).then(res => {
			let {data, error} = res;
			// if(data?.resetPassword) data = data.resetPassword

			if(!error){
				setMode({step: 'verification', email: variables?.email})
				notify("Verification code send, check your email.", 'login', 'info')

				
			} else {
				notify('Reset send failed, please check your email.')
			}
			setDisabled(false)
		});
	};


	// const sendMails = () => {
	// 	users?.map(u => {
	// 		onSubmit({email: u?.email})
	// 	})
	// }
	return (
		<Fragment>
			{/* <pre style={{overflow: 'scroll', maxWidth: '100%'}}>
{JSON.stringify({...template}, null, 4)}
</pre> */}
{/* <button onClick={() => sendMails()}>send</button> */}
{/* {JSON.stringify(users?.length)} */}
			<form  disabled={disabled} onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>



					<Input 
						name="email" type="email" label="Account Email" {...inputProps} required
						rules={{required: "You registered email."}}
					/>


				</InputGroup>
		
				
				<div className="mt-2 ml-1">

					<Button 
						width={[1, '100%']}
						size="large"
						theme="primary"
						type="submit"
						disabled={disabled || !watch()?.email}
						label={rst?.response ? rst?.response : loading ? <LoadingDots/> : (watch()?.email) ? 'Send reset code' : 'Disabled, enter in email'}
					/>

				</div>
				{loading}
				{resetError}
			</form>



			<div className="form-bottom text-center">
				<br/>
				<p className="sign-up-text">
					Remembered your password?{" "}
					<Link href="/login" color="primary" style={{fontWeight: 'bold'}}>

						Login

					</Link>
				</p>
				{accountActions && (
				<p className="sign-up-text" style={{opacity: 0.7, fontSize: '70%'}}>
					Don&apos;t have a account?{" "}
					<Link href="/register" color="primary">
						Register
					</Link>
				</p>
				)}
			</div>
		</Fragment>
	);
};
