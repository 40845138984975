import { useState } from "react"
import { usePagination } from "../Pagination/usePagination"
import { ControlDialog } from "@mosanic/items"

const defaultInfo = {
    singular: 'collection',
    plural: 'collection',
}

const useDataGridState = ({info}) => {
    const [data, setState] = useState({
        deleteItem: null,
        item: null,
        modal: false,
        sidebar: false,
        filters: {
            enabled: false
        },
        order: 'desc',
        info
    });

    //Append dialog state
    const state = { ...data, action: data?.item ? 'update' : 'create' };

    return {state, setState}
}

const useDataGridActions = ({state, setState, refresh}) => {
    const setFilters = filters => setState({...state, filters: {...state.filters, ...filters}})

    const actions = {
        close: (type = 'modal') => setState({...state, [type]: false, item: null}),
        open: (type = 'modal') => setState({...state, [type]: true, item: null}),
        edit: (item, type = 'modal') => setState({...state, item, [type]: true}),
        delete: (deleteItem) => setState({...state, deleteItem}),
        toggleFilters: () => setFilters({enabled: !state?.filters?.enabled}),
        setFilters,
        setState,
        refresh
    }
 
    return actions;
}

const DataDialog = ({state, actions, children, level = 2, ...other}) => children ? (
    <ControlDialog  
        {...other} 
        titleSize={level}
        state={state?.modal || state?.item} 
        close={() => actions.close()}
    >
        {children}
    </ControlDialog>
) : null;

export const useDataGrid = ({items, info = defaultInfo, refresh = () => null, count = null, level = 2}) => {
    const {state, setState} = useDataGridState({info});
    const actions = useDataGridActions({state, setState, refresh})

    const [limit, setLimit] = useState((items?.length > 10 || !items) ? '10' : items?.length?.toString())
    
    const pagination = usePagination({
        data: items,
        limit: parseFloat(limit),
        count
    })

    const Dialog = ({props, children}) => (
        <DataDialog {...{state, actions, level}} {...props}>{children}</DataDialog>
    )

    const data = {
        items: pagination.items,
        limiter: { limit, setLimit, count: count ? count : items?.length },
        pagination,
        actions,
        state,
        Dialog
    }

    return data;
}