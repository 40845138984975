 import { DELETE_ACCOUNT } from "@api/mutations/auth";
import { useLazyQuery, useMutate } from "@hooks/useApi";
import { useLogout } from "@mosanic/auth";
import { useNotify } from "@mosanic/hooks";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSendOne } from "@mosanic/components/Mail";
import { isAuthenticated } from "@api/token";

export const useDeleteUser = () => {   
    const router = useRouter();
    const logout = useLogout(false)

    const [dispatchDelete, [data, loading, errorDelete]] = useMutate('deleteMyUserAccount', DELETE_ACCOUNT)
    const [sendMail] = useSendOne();
    const notify = useNotify()

    const isAuth = isAuthenticated();

    const handler = useCallback(({_id, user}) => {
        const {email, firstName, lastName} = user;


        if(!_id) {
            notify('No user _id received.');
            return;
        }
        if(!email || !firstName) {
            notify('No user data found.');
            return;
        }
        if(!isAuth) {
            notify('Current user not authenticated.');
            // router.push('/login');
            return;
        }
 
        dispatchDelete().then(res => {
            const {data, error} = res;

            if(!error && !errorDelete) {
                sendMail({
                    subject: `${firstName}, we're sad to see you leave.`, 
                    to: email, 
                    preHeader: "You're account on Mosanic will be terminated within 31 days...", 
                    subHeader: "😢 Oh no..", 
                    mainHeader: `${firstName} ${lastName}, we're sad to see you leave.`, 
                    mainText: "We value your opinion very much and would massively appreciate it if you would like to tell us why. \n So we can keep improving and maybe get you back in the future. \n If you wouldn't mind, send us a short reply on this email. \n \n Kind Regards, \n The Mosanic Team"
                })
                notify('Successfully deleted account.', 'app', 'success');
                console.log(data)
            
                setTimeout(() => {
                    logout();
                    router.push({
                        pathname: '/ticket',
                        query: { type: 'terminate-account' }
                    });
                }, 1000);
            }
            if(error){
                console.log(error)
                notify('Could not delete account.');
            }
        
        })

    },[dispatchDelete, notify, errorDelete, sendMail, logout, router]);

    return [
        handler, 
        data, 
        loading, 
        errorDelete, 
    ]
}