import { gql } from '@apollo/client';

export const ADD_MAIL = gql`
mutation mailCreateOne($record: CreateOneMailInput!) {
	mailCreateOne (record: $record) {
		recordId
		record {
			title
			description
			schema
			userId 
			siteId 
			_id
		}
		error {
			message
		}
	}
}
`;


export const UPDATE_MAIL = gql`
mutation mailUpdateById($_id: MongoID!,$record: UpdateByIdMailInput!) {
	mailUpdateById (_id: $_id, record: $record) {
		recordId
		record {
			title
			description
			schema
			userId 
			siteId 
			_id
		}
		error {
			message
		}
	}
}
`;