
import { EditIcons } from "@mosanic/Icons";
import PartStyles from "./Parts.styles";
const Part = PartStyles;


const DEFAULT_TRIGGER_ICON = 'editPencil'
const DEFAULT = 'default';

const PanelIcon = ({icon, source = EditIcons}) => {
    const iconKey = (icon === DEFAULT) ? 
        DEFAULT_TRIGGER_ICON : 
        (!icon ? null : icon)

    for (const [key, value] of Object.entries(source)) {
        if(iconKey === key) return value;
    }
}

    
export const ControlPanel = ({children, extraClass= '', bigShadow = false, reversed=false, overflow=false, ...other}) => (
    <Part.Panel className={`control__panel ${extraClass} ${bigShadow ? '-bigShadow' :''}`} reversed={reversed} overflow={overflow} {...other}>
        {children}
    </Part.Panel>
);
export const ControlHeader = ({children, title='', extraClass= ''}) => <PanelHeader title={title} extraClass={extraClass}>{children}</PanelHeader>

export const PanelHeader = ({children, title='', extraClass= ''}) => (
    <div className={`panel__header ${extraClass}`}>
        {title}{children}
    </div>
);


export const PanelTrigger = ({children, icon = DEFAULT, extraClass= '', onClick = null}) => (
    <span className={`panel__trigger ${extraClass}`} onClick={onClick}>
        <PanelIcon icon={icon} />{children}
    </span>
);
