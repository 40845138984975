import { useRouter } from "next/router";

import { setDataAction } from "@redux/map/data/action";
import { selectMapAction, updateMapAction } from "@redux/map/action";
import { handlePageAdd, setCurrentPage, updateSeoAction } from "@redux/screens/action";
import { useDispatch } from "react-redux";
import { setPageAction } from "@redux/pages/action";


export const usePageInit = ({page = null}) => {
    const router = useRouter()
    const dispatch = useDispatch()

    const update = (data) => { 
        const meta = {
            ...data,
            map: undefined,
            data: undefined,
            author: undefined,
            updates: undefined,
        }
        dispatch(setPageAction(meta))
        dispatch(handlePageAdd({name: data?.title, _id: data?._id}))
        dispatch(setCurrentPage({_id: data?._id}))

        dispatch(setDataAction({data: data?.data ? data.data : {}}))

        dispatch(selectMapAction({name: data?._id}))
        dispatch(updateMapAction({map: data?.map ? data?.map : []}))
    }
    const initiate = (data = null) => {
        update(page?.title ? page : data)
        router.push('/builder')
    }
    return initiate;
}