import { Condition, HeadingTag } from "@JSX";
import { combineIntoDataTableSchema } from "@api/schemas";
import { collectionsSchema } from "@api/schemas/collections";
import { Entry } from "@mosanic/core/Collections";
import { GridOrderButton } from "@mosanic/core/Collections/DataGrid";
import { substrSearch, useSearchItems } from "@mosanic/core/Items/useItems";
import { DataProvider } from "@mosanic/core/Provider";
import { Button, ButtonGroup } from "@mosanic/fields";
import { ToggleButton } from "@mosanic/fields/Button/Buttons";
import { AdvancedLabel, Block, Expander, Page } from "@mosanic/items";
import { InlineGroup } from "@mosanic/items/Inputs";
import MetaHead from "@mosanic/items/Page/MetaHead";
import { ArrowLeftIcon, MixerHorizontalIcon, MixerVerticalIcon, PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { siteIdSelector, userIdSelector } from "@redux/auth/reducer";
import { isFunc } from "@util/Funct";
import { capitalizeFirst, excerptString } from "@util/Text";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchField } from "../DataGrid";
import { DataGrid } from "../DataGrid/DataGrid";
import EditorProvider from "../Editor/EditorProvider";
import { PaginationLimit } from "../Pagination/Pagination";
import { useGetSite, useGetUser } from "../Query/byId";
import { useListUsers } from "../Query/list";
import { AuthForm } from "@mosanic/auth/AuthForm";

const emptyResponse = null


    
export const DataTable = ({
    schema = {
        ...collectionsSchema,
        props: {
            ...collectionsSchema.props,
            info: {
                ...collectionsSchema.props.info,
                slug: null
            }
        }
    },
    config = {
        onlyEditorSchema: false,
        isIndexPage: false,

        defaultType: 'user',
        defaultTypes: null,

        controlRouter: '/site',
    },
    controls = {
        add: true,
        ordering: true,
        limiter: true,
        filters: true,
        edit: true,
        delete: true,
        search: true,
        admin: true
    },
    useDataGrid,
    useEntryDataGrid = null,
    hookProps = {},

    settings = null,
    render = true,
    wrap = true,
    domain = null,

    ...other
}) => {

    

    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})
    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const router = useRouter()

    const site_schema = schema?.site?.schema && site ? schema.site.schema(site) : null;


    const [users] = useListUsers({})
    const controlCount = Object.values(controls).filter(c => c)?.length 

    const [allSites, setAllSites] = useState(false)
    const {  
        data, limiter, pagination, actions, state, type, loading, error, options, status,
        Dialog, Selector, dataHookMethods, entryHookMethods, collection
    } = useDataGrid({
        //Potential call hooks
        ...hookProps, 

        //Schema props for rendering the entry columns
        props: schema?.props,
        columns: Array.isArray(site_schema) ? [...schema?.columns, ...site_schema] : schema?.columns,
        //Formatting of entries for datagrid columns
        formatter: schema?.formatting?.columns,
    })

    const baseSchema = combineIntoDataTableSchema({dataTableSchema: schema, config, data})
    const {
        dataTable,
        props, 
        info,
        singular
    } = baseSchema



    useEffect(() => {
        if(data && (data?.siteId && config.controlRouter) && siteId != data?.siteId) {
            router.push(config.controlRouter)
        }
    },[siteId, data?.siteId, data, router, config.controlRouter])

    const [tab, setTab] = useState('index');

    // const currentType = type === 'all' ? config?.defaultType : type ? type : data?.singular
    
    const Controls = () => config?.viewOnly ? (
        <InlineGroup mb={2}></InlineGroup>
    ):(tab === 'index' && (controlCount >= 1 || controls?.search)) ? (
        <InlineGroup mb={4}>
            {controlCount >= 1 && (
                <ButtonGroup my={0} width={`${controlCount * 120}px`} maxWidth="100%" minWidth="300px" mr={2}>
                    {props?.routing?.indexPath && (
                        <Button href={props?.routing?.indexPath} label="Return" icon={<ArrowLeftIcon />} />
                    )}
                    {Selector && <Selector cb={() => setTab('index')}/>}

                    {controls.edit  && !status?.loading && !config.isIndexPage && ( <Button active={tab === 'update'} label={`Edit schema`} onClick={() => setTab(tab === 'update' ? 'index' : 'update')}/> )}
                    {settings && ( <Button icon={<MixerVerticalIcon />} active={tab === 'settings'} label={`Settings`} onClick={() => setTab(tab === 'settings' ? 'index' : 'settings')}/> )}
                    {controls.add && <Button icon={<PlusCircledIcon />} label={` Add ${excerptString(singular, 4)}`} handler={() => actions.open('modal')}/>}

                    {controls.filters && (
                        <ToggleButton key="filter"
                            label=" Filters" altLabel=" Filtering"
                            icon={<MixerHorizontalIcon/>} altIcon={<MixerVerticalIcon/>} 
                            checked={state?.filters?.enabled} onClick={actions?.toggleFilters} 
                        />
                    )}
                    {controls.ordering && ( <GridOrderButton state={state} setState={actions?.setState}/> )}
                    {controls.delete && ( <Button icon={<TrashIcon />} ghost label=" Delete" /> )}
                    {user?.isAdmin && controls.admin && <ToggleButton onClick={() => setAllSites(!allSites)} checked={allSites} altLabel="Current site" label="[admin]"/> }
                    {controls.limiter && <PaginationLimit {...limiter} />}
                </ButtonGroup>
            )}
            {controls.search && false && (
                <SearchField input={input} setInput={setInput}/>
            )}
        </InlineGroup>
    ) : null
  
    const title = capitalizeFirst(info.title);
    const label = <AdvancedLabel label={info.label} helpText={info.help} as="p" />


    const [input, setInput, filtered] = useSearchItems({
        components:  data?.entries,
        validate: (c, input) => (
            substrSearch(c?.title, input) ||
            data?.schema?.filter(f => 
                f?.type === 'text' ?  substrSearch(c?.[f?.name], input) : false
            )?.length >= 1 ||
            substrSearch(c?.title, input) ||
            substrSearch(c?.accommodation_desc, input) ||
            substrSearch(c?.sys_location?.location_desc, input)
        )
    })


    const Entries = () => (
        <DataGrid 
            loading={status.loading}
            props={props}
            collection={data ? {...data, entries: filtered} : data}
            pagination={pagination}
            handles={{
                ...actions,
                delete: !config?.disabled ? actions.delete : null,
                viewOnly: config?.viewOnly
            }}
            state={state}
            domain={domain}
         />
    )
    const Editor = () =>  tab === 'update' ? (
        <>
        <EditorProvider 
            initial={collection}
            dataHookMethods={dataHookMethods}
            entryHookMethods={entryHookMethods}
            formType="update"
            stopEditing={() => setTab('index')}
            baseSchema={baseSchema} 
            type={type || config.defaultType}
            types={config.defaultTypes || options}
            singular={singular}
        />
        </>
    ) : tab === 'settings' ? (
        settings({
            stopEditing: () => setTab('index') 
        })
    ) : null
 
            // console.log(site_schema)
    const isUpdate = state.action != 'create';

    const EditorRender = ({}) => {
        const isUpdate = state.action != 'create';
        // console.log(dataHookMethods, entryHookMethods
        let handler = isUpdate ? dataHookMethods?.update : dataHookMethods?.create;
        handler = isFunc(handler) ? handler : () => [() => null, [null, false, null]]
        
        //Return editor for updating schema's 
        // * Update Collection from /collections

        if(props.editor.entry === 'data_table' && isUpdate && useEntryDataGrid) {
            console.log(useEntryDataGrid)
            return (
            <>

             {/* {JSON.stringify(data.entries)} */}
            <DataTable 
                // handler={dataHookMethods}
                // entryHandler={entryHookMethods}
                schema={dataTable}
                useDataGrid={useEntryDataGrid}
                hookProps={{_id: state?.item?._id}}
                config={{
                    dialog: 'lg',
                    onlyEditorSchema: false,
                }}
                wrap={false}
            />
            </>
        )
            }


        //Return provided editor for index pages
        // * Create Collection from /collections
        // if(config.isIndexPage) return 'EditorProvider'
        if(config.isIndexPage) return (
            <>
            <EditorProvider 
                dataHookMethods={dataHookMethods}
                handler={handler}
                formType={state?.action}
                initial={state?.item}
                stopEditing={() => setTab('index')}
                baseSchema={!site_schema ? baseSchema : {
                    ...baseSchema,
                    base: [
                        ...baseSchema?.base,
                        ...(site_schema ? site_schema : [])
                    ]
                }} 
                type={type != 'all' ? type : null || config.defaultType}
                types={config.defaultTypes || options}
                singular={singular}
            />
            </>
        )
        handler = entryHookMethods || (data?.config?.notLinked && entryHookMethods) ? (
            isUpdate ? entryHookMethods?.update : entryHookMethods?.create 
        ) : (
            handler
        )
        handler = isFunc(handler) ? handler : () => [() => null, [null, false, null]]
        
        // if(props.editor.entry != 'entry') return JSON.stringify(props?.editor)
        return (
            <Entry 
                handler={handler}
                cols={data?._id ? 1 : 2}
                dataType={type} 
                initial={state?.item} 
                type={state?.action} 
                disabled={config?.disabled}
                collection={
                    config.onlyEditorSchema ? {
                        ...data,
                        schema: (
                            baseSchema?.base ? 
                                baseSchema?.base : 
                                data?.schema
                        ),
                    } : data
                }
            /> 
        )
    }

    const editorDialogTitle = config.onlyEditorSchema && isUpdate ? null : capitalizeFirst(`${state.action} ${singular ? singular : ''}`);
    const editorDialogSize =  (state.action === 'create' || config.isIndexPage) ? (
        props.level === 1 ? 'xl' :
        props.level === 2 ? 'lg' :
        props.level === 3 ? 'md' :
        'sm'
    ) : config?.dialog

    const EditorDialog = ({}) => (
        <Dialog props={{
            size: editorDialogSize, 
            title: editorDialogTitle,
            level: props.level + 1
        }}>
            {/* {state?.action} */}
            <EditorRender />
        </Dialog>
    )
    

    if(!data) return render ? null : emptyResponse
    return render ? (
        <Condition when={info.slug?.length >= 1} wrapper={children => (
            <Page slug={[...info.slug, {label: title}]}>
                <MetaHead title={title} description={label}/>
                {children}
            </Page>
        )}>
        
            <Condition when={wrap} wrapper={children => <Block p={[4, 8, 10]} {...other}>{children}</Block>}>
                <DataProvider data={{users}}>
                    <HeadingTag level={props.level}>{title}</HeadingTag>
                    {label}

                    {!userId ? (
                        <AuthForm verify={false}/>
                    ) : (
                        <>
                        <Controls />
                        {error}

                        <Expander expanded={tab != 'index'}>
                            <Editor />
                        </Expander>

                        <Expander expanded={tab === 'index'}>
                            <Entries />
                        </Expander>

                {/* <pre>
                        {JSON.stringify(data?.entries, null, 2)}
                        </pre> */}
                        <EditorDialog />
                        </>
                    )}
                </DataProvider>
            </Condition>
        </Condition>
    ) : {
        title,
        label,
        Error,
        Controls,
        Entries,
        Dialog,
        Editor,
        loading,
        EditorDialog,

        schema: data?.schema,

        state
    }
}