
// import Coming from "@mosanic/Coming";
import { getImageUrl } from "@api/host";
import { Button } from "@mosanic/fields";
import { Link } from "@mosanic/items";
import { capitalizeFirst } from "@util/Text";
import Image from "next/image";
import { useRouter } from "next/router";
import { createContext } from "react";
import styled from "styled-components";


const Nav = styled.div` 
  z-index:${props => props.top ? 2 : 99};
  width: 100%;
  position: ${props => props.top ? 'absolute' : 'fixed'};
  top: 0;
  left:0;
  right:0; 
  height: 40px;
  background: #fff;

  ul {
    margin-left: 10px;
    padding-left: 10px;
    vertical-align: top;
    display: inline-block;
    a {
      li {
        font-size: 13px;
        display: inline-block;
        padding: 7px 10px;

          color: #000 !important;
      }
    }
  }
  button {
    display: inline-block;
  }
`
const Logo = styled.div` 
    display: inline-block;
`

export const Navigation = ({nav, url, top, translated}) => {
    const router = useRouter()
    const isEnglish = router?.locale === 'en';

    return nav?.length >= 1 ? (
    <Nav top={top}>
        <Link href="/">
            <Logo>
                <Image width="100px" height="40px"  alt="site logo" src={getImageUrl(url)}/>
            </Logo>
        </Link>
        <ul>
            {nav?.map(item => (
                <Link href={`/${item.slug}`} key={item?._id}>
                    <li>
                        {capitalizeFirst(item?.header)}
                    </li>
                </Link>
            ))}
        </ul>
        {translated && (
            <Button float right ghost size="large" onClick={() => router.replace(router.asPath, null, {locale: isEnglish ? 'nl' : 'en'})} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"></path></svg>
                &nbsp; {isEnglish ? "Dutch" : 'English'}
            </Button>
        )}
    </Nav>
) : null
}
