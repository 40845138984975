import { useFieldHandler, useOverwrite } from '@mosanic/hooks';
import { itemDataSelector } from "@redux/map/data/selector";
import { inspectedDataSelector } from "@redux/inspect/selector";
import isEqual from "@util/isEqual";
import { isEmptyString, replacePart } from "@util/Text";
import { useSelector } from "react-redux";
import { useOverwriteSelector } from './useOverwrite';

const convertBasePath = (basePath, path, fieldName) => {
    const replace = isEmptyString(fieldName) ? 
        '.[field]' :    //prepare to remove .[field] replacing
        '[field]';      //prepare for switching with fieldname

    //Bade field group path
    if(basePath) path = replacePart(basePath, replace, fieldName);

    //Fallback if no path or basepath is set 
    if(!basePath && !path) path = `data.${fieldName}`;

    return path
}

/**
 * * Main field handler
 * 
 * A:   Convert to correct field path
 *      data.sizing.[field] => data.sizing.minWidth
 * 
 * B:   Get state overwrite
 *      primary:xl:hover       
 * 
 * C:   Get actual main field source 
 *      retruns full attr data structure
 * 
 * D:   Handler to update pass:
 *      @param value {full field value}
 *      @param boolean {optional prefix value with overwrite state}
 * 
 * @returns {
 *  source,
 *  handler
 * }
 */
export const useFieldSource = ({basePath = null, path = null, fieldName = ''}, canIncludeState = true, locale = null) => {
    //A. Path to field
    path = convertBasePath(basePath, path, fieldName);

    //B. Get overwrite state selector
    const selector = useOverwriteSelector();

    //C. Get source to full field 
    const fullSource = useSelector(state => inspectedDataSelector(state, path, isEqual));

    const source = locale ? fullSource?.[locale] : fullSource;

    //D. Handle with optional overwrite
    const handler = useFieldHandler({path, state: selector}, canIncludeState, locale);

    return {
        source, 
        handler
    };
} 

export const useMosanicInlineEditorField = ({field, ref}, includeOverwriteState = false) => {
    const path = `fields.${field}`;

    //B. Get overwrite state selector
    const selector = useOverwriteSelector();

    //C. Get source to full field 
    const source = useSelector(state => itemDataSelector(state, {ref, path}, isEqual));

    //D. Handle with optional overwrite
    const handler = useFieldHandler({path, state: selector}, includeOverwriteState);
    

    return {
        source, 
        handler
    }
}
export const useMosanicInlineEditorFields = ({ref}, includeOverwriteState = false) => {
    const path = `fields`;

    //B. Get overwrite state selector
    const selector = useOverwriteSelector();

    //C. Get source to full field 
    const source = useSelector(state => itemDataSelector(state, {ref, path}, isEqual));

    //D. Handle with optional overwrite
    const handler = useFieldHandler({path, state: selector}, includeOverwriteState);
    

    return {
        source, 
        handler
    }
}