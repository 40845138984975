import { AdvancedLabel, Card, Block } from "@mosanic/items";

const DashCard = ({header, label = "", help = "", render = null, children, ...other}) => (
    <Block m={0} mb={4} {...other}>
        <h3>{header}</h3>
        <AdvancedLabel 
            label={label} span mb={2}
            helpText={help}
        />
        {render}
        {children}
    </Block>
);
export default DashCard;