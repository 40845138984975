import { hasAttr } from "@util/Obj";
import { createSelector } from "reselect";
import { dataSelector } from "./reducer";
import { get } from "@util/Obj";

const parameters = (state, obj) => obj;

export const getItem = (data, obj) =>
    hasAttr(data, obj?.group, obj?._id) ? 
            data[obj.group][obj._id] : 
            null 

export const itemSelector = createSelector(
    [ dataSelector, parameters ],
    ( data, obj ) => getItem(data, obj)
);


//Get from data reducer
export const itemDataSelector = createSelector(
    [ dataSelector, parameters ],
    ( data, obj ) => 
        hasAttr(data, obj?.ref?.group, obj?.ref?._id) ? 
            get(
                data[obj.ref.group][obj.ref._id], 
                obj.path,
                {}
            ) : 
            null
);



