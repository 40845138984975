import { Spinner } from "@mosanic/items"
import { FullCodeEditor } from "../Code/FullEditor"
import { AddCollection } from "@mosanic/core/Collections"
import { CodeFieldValue } from "@mosanic/core/Collections/Select/SelectField"
 
import dynamic from "next/dynamic"
import { useDataReceiver } from "@mosanic/builder/DataLink/DataReceiver"

const ReactJson = dynamic(() => import('react-json-view'), {
  ssr: false
})

export const SwitchColumns = ({loading, column, children = null, value, ...props}) => {
    if(loading) return <Spinner />
    switch(column) {
        case 'editor': return <SourceEditor {...props} />
        case 'code': return <CodeFieldValue {...props?.codeFieldValue} />;
        case 'add': return <AddCollection ghost/>
        case 'data': return <ReactJson name="Data" collapsed src={props?.dev} />;
        case 'get': return <GetPreview value={value}/>
        default: return children
    }
}


const GetPreview = ({value}) => {
    const {source, clone} = value
    let [content, schema, baseRoute] = useDataReceiver({
        source,
        entry: value,
        clone,
    })
    return (
        <ReactJson name="Data receiver" collapsed src={{params: value, content, schema, baseRoute}} />
    )
}
const SourceEditor = ({fieldPath, ctx, onChange, value}) => {

    return (
        <FullCodeEditor
            noBorder
            onChange={onChange}
            // value={state?.fieldPath ? `$ctx.entry.${combineString(state?.fieldPath?.map(f => f?.name), '.')}` : null}
            value={value}
            ctx={ctx}
            size={{width: '100%', height: '100%', minHeight: '300px'}}
            height="500px"
            maxHeight='100%'
            showPreview 
            // ctx={state?.fieldPath ? {entry: {...convertEntry(activeEntry?.entry), ['Main content']: undefined}} : {
            //     collections,
            //     entry: activeEntry
            // }}
        />
    )
}