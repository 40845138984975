import { ADD_COLLECTION } from "@api/mutations/collections";
import { useCreate } from "./useDataMutate";
import { ADD_ENTRY } from "@api/mutations/entry";
import { getEntryDescription, getEntryImage, getEntryLabel } from "@mosanic/fields/Source/helpers";
import { slugify } from "@util/Text";
import { removeEmpty, removeUndefined } from "@util/Obj";
import { ADD_MAIL } from "@api/mutations/mail";
import { ADD_CHAT } from "@api/mutations/chats";

const useCreateItem = ({formatter, mutation, type, siteCheck = true, ...other}) => {
    const [update, [data, loading, error]] = useCreate({
        formatter,
        mutation,
        type,
        silent: true,
        siteCheck,
        ...other
    });

    return [update, [data, loading, error]]
}


export const useCreateCollection = () => useCreateItem({
    mutation: ADD_COLLECTION,
    type: 'collection',
    formatter: data => ({
        ...data,
        date: new Date(),
        _id: undefined
    })
});

const getInfoFields = (data, schema) => ({
    title: getEntryLabel(data, schema),
    description: getEntryDescription(data, schema),
});

export const createEntryFormatter = (data, collection) => {
    const {_id, schema} = collection;
    const hasSeo = collection?.routing?.catchRoutes ;

    return removeUndefined({
        data: {
            ...data,
            date: undefined,
            updatedAt: undefined,
            userId: undefined,
            _id: undefined,
            siteId: undefined,
        },
        ...getInfoFields(data, schema),
        image: getEntryImage(data, schema),
        seo: hasSeo ? getInfoFields(data, schema) : undefined,
        slug: hasSeo ? slugify(getEntryLabel(data, schema)) : undefined,
        collectionId: _id,
        userId: data?.userId ? data.userId : undefined,
        date: data?.date ? data.date : new Date(),
        updatedAt: new Date(),
        siteId: data?.siteId,
        _id: undefined
    })
}

export const useCreateEntry = ({collection, ...other}) => {
    const formatter = data => createEntryFormatter(data, collection)

    return useCreateItem({
        mutation: ADD_ENTRY,
        loggedInCheck: 'direct',
        siteCheck: 'direct',
        type: 'entry',
        formatter,
        ...other
    });
}

export const useCreateMail = () => useCreateItem({
    mutation: ADD_MAIL,
    type: 'mail',
    formatter: data => ({
        ...data,
        date: new Date(),
        _id: undefined
    })
});



export const useCreateChat = () => useCreateItem({
    mutation: ADD_CHAT,
    type: 'chat',
    siteCheck: false,
    formatter: data => ({
        ...data,
        date: new Date(),
        isActive: true,
        _id: undefined
    })
});