
import { ObjectID } from "@mosanic/utils";
import { resetBounds } from "@redux/bounds/action";
import { getBreakpointIn, getBreakpointsSelector } from "@redux/breakpoints/reducer";
import { clearInspectAction, initInspectAction } from "@redux/inspect/action";
import { selectMapAction } from "@redux/map/action";
import SCREENS_ACTION_IDS, { initNewScreen, initScreen, updateScreenMeta } from './action';
import { baseScreenScale, getScreensSelector, getTotalScreensWidth, screensOffset } from "./reducer";
import { transformBreakPointToScreen } from "./transform";


const Action = SCREENS_ACTION_IDS;

const handleScreensInitialize = ({getState, dispatch}) => next => action => {
 
  next(action);

  if(action.type === Action.SCREENS_INIT){

    const screens = getScreensSelector(getState());

    let previousX = 0;
    const breakPoints = getBreakpointsSelector(getState());
    screens.map(baseScreen => {
      const breakPoint = getBreakpointIn(breakPoints, baseScreen.breakPoint)
      const screen = transformBreakPointToScreen(baseScreen, breakPoint, previousX)
      previousX += screen.size.width + screensOffset;

      return dispatch(initScreen({screen, bp: screen.breakPoint}));
    })
    
  }
    
};

const handleScreenInit = ({getState, dispatch}) => next => action => {
  next(action);

  if(action.type.includes(Action.SCREEN_INIT)){

    if(action.payload?._id) return;

    //Update previous screen meta with new generated id
    return action.payload?.new ?
      dispatch(initNewScreen({screen: action.payload, _id: ObjectID()})) :
      dispatch(updateScreenMeta({screen: action.payload, _id: ObjectID()}))
    
  }
    
};

const handleAddNewScreen = ({getState, dispatch}) => next => action => {
  next(action);

  //Based on breakpoint 
  if(action.type === Action.SCREEN_ADD_NEW){
    const previousX = getTotalScreensWidth(getState()) + screensOffset;
    const baseScreen = {
      name: action.payload.name,
      breakPoint: action.payload._id,
      scale: baseScreenScale,
      new: true,
    }
    const screen = transformBreakPointToScreen(baseScreen, action.payload, previousX)
    dispatch(initScreen({screen, bp: screen.breakPoint}));

  }
    
};
const handleScreenReordering = ({getState, dispatch}) => next => action => {
  if (action.type === Action.SCREENS_REORDER) {
    next(action);
    
    const screens = getScreensSelector(getState());
    let previousX = 0;

    screens.map(screen => {
      const updatedScreen = {
        ...screen,
        position: {
          y: 0,
          x: previousX
        }
      };
      previousX += screen.size.width + screensOffset;

      return dispatch(updateScreenMeta({screen: updatedScreen}))
    })
  } else {
    next(action);
  }
};


const handleInspectScreenFlow = ({getState, dispatch}) => next => action => {
  next(action);

  if (action.type === Action.SCREEN_INSPECT) {
    const {component, bounds, screen} = action.payload
    dispatch(initInspectAction({component, screen, bounds, path: null}));
    // dispatch(setInspectScreenBounds(action));
  }
};



const handleChangePageFlow = ({getState, dispatch}) => next => action => {
  next(action);

  if (action.type === Action.PAGE_SET) {
    const name = action.payload
    dispatch(resetBounds())
    dispatch(clearInspectAction())
    dispatch(selectMapAction({name}))
  }
};


// export const handleCanvasResizeFlow = ({getState, dispatch}) => next => action => {
 
//   if(action.type === HANDLE_CANVAS_RESIZE && !action.meta?.handled){ //HANDLE_CANVAS
//     const breakPoints = getBreakpoints(getState());

//     const updatedCanvas = action.payload;
//     const targetBreakpoint = getBreakPointByWidth(parseFloat(updatedCanvas.width), breakPoints);
    
//     // dispatch(setCanvasPreview(targetBreakpoint)); //Pass preset offsets
//     dispatch({type: UPDATE_DIMENSIONS, payload: targetBreakpoint})
//     // dispatch({type: SET_CANVAS_BREAKPOINT, payload: targetBreakpoint, meta: {...action.meta, ...breakPoints}});


//     // const newPreviewPoints = generatePreviewPoints(targetBreakpoint, breakPoints);
//     // dispatch({type: SET_PREVIEW_POINTS, payload: newPreviewPoints, meta: action.meta})
//     // dispatch({type: SET_THEME, payload: getScreenTheme(newPreviewPoints), meta: {state: 'Todo'}})

//     dispatch({
//         type: SET_CANVAS,
//         payload: updatedCanvas,
//         meta: {handled: true}
//     })
//   } else {
//     next(action);
//   }
    
// };


// /**
//  * Main layouts flow
//  * @param {*} param0 
//  * @returns 
//  */
// export const handleDimensionUpdates = ({getState, dispatch}) => next => action => {
//   next(action);

//   if(action.type === UPDATE_DIMENSIONS){ 
//     const breakPoints = getBreakpoints(getState());

//     let targetBreakpoint = action.payload;

//     //Fallback
//     if(!targetBreakpoint){
//       targetBreakpoint = getBreakPointByWidth(parseFloat(window.innerWidth / 1.2), breakPoints);
//     }

//     //Change breakpoint
//     dispatch({type: SET_CANVAS_BREAKPOINT, payload: targetBreakpoint, meta: {...action.meta, ...breakPoints}});

//     //Generate preview break points
//     const newPreviewPoints = generatePreviewPoints(targetBreakpoint, breakPoints);
//     dispatch({type: SET_PREVIEW_POINTS, payload: newPreviewPoints, meta: action.meta})
  

//     dispatch(resetHoverBounds(CANVAS_ID))
//   }
  

    
   
// };



// export const generatePreviewPoints = (search, breakPoints) => {
//   let clientWidth = window.innerWidth;
//   let newPoints = {};
//   let hit = false;

//   //Check all default breakpoints
//   Object.entries(breakPoints).map(([key, value])=>{
//     //If key is current preview
//     if(!hit && key != search){
//       newPoints[key] = clientWidth - 250;
//     }
//     if(key === search){
//       newPoints[key] = clientWidth - 50;
//       hit = true;
//     }
//     if(hit && key != search){
//       clientWidth = clientWidth + 100;
//       newPoints[key] = clientWidth;
//     }
//   });
//   return newPoints;
// }



export const screensMdl = [
  handleScreensInitialize,
  handleAddNewScreen,
  handleScreenInit,

  handleInspectScreenFlow,
  handleScreenReordering,

  handleChangePageFlow,
  // handleCanvasFlow, 
  // handleCanvasResizeFlow,
  // handleDimensionUpdates,
];
