import { rgba, darken, lighten } from "polished";


const skyBlue = `#1082e9`;
const skyBlueDark = `#0b5aa2`;

const light = {
    primary: skyBlue,
    primaryDark: skyBlueDark,
}

const dark = {
    primary: '#fff',
    primaryDark: '#d0d0d0',
}
 
const utilBase = {
    light: '#fff',
    dark: '#222',
    primary: {
        darkest: '#052543',
        darker: '#083f72',
        dark: light.primaryDark,
        base: light.primary,
        light: '#2A9CFF',
        lighter: '#43B5FF',
        trans: '#067affe1',
        underlay: 'rgba(6, 122, 255, 0.128)'
    },
    secondary: {
        darkest: '#2c2d30',
        darker: '#45464a',
        dark: '#5d5f64',
        base: '#7d818d',
        light: '#e7e9ed',
        lighter: '#f4f7fa',
        lightest: '#f8fafc',
        trans: '#fcfdfe',
    },
    green: {
        dark: '#40792E',
        base: '#84B36A',
        light: '#ffffff46',
        lighter: '#EDFADC',
        trans: '#40792ee1',
        underlay: 'rgba(97, 164, 98, 0.128)',
    },
    red: {
        dark: '#4b0606',
        base: '#de1e0c',
        light: '#b80f0f',
        lighter: '#e71313',
        trans: '#f36d6d',
        underlay: '#f79c9c'
    },
    orange: {
        dark: '#bb7300',
        base: '#ff9d00',
        light: '#ffffff46',
        lighter: '#f9d291',
        trans: '#f79b06e1',
        underlay: 'rgba(255, 157, 0, 0.128)',
        mark: 'rgba(255, 157, 0, 0.228)'
    },
    gray: {
        darkest: '#2c2d30',
        darker: '#45464a',
        dark: '#5d5f64',
        base: '#7d818d',
        light: '#e7e9ed',
        lighter: '#f4f7fa',
        lightest: '#f8fafc',
        trans: '#fcfdfe',
    },
    bright: {
        green: '#2bd67b',
        red: '#f41d1d',
        orange: '#ff9d00',
        blue: light.primaryDark,
        primary: light.primary,
        gray: '#7d818d'
    }
}


const genShades = (base, keep) => ({
    ...keep,
    darkest: darken(0.4, base),
    darker: darken(0.2, base),
    dark: darken(0.05, base),
    base: base,
    light: lighten(0.2, base),
    lighter: lighten(0.4, base),
    lightest: lighten(0.5, base),
    trans: rgba(lighten(0.4, base), 0.3),
    underlay: rgba(lighten(0.4, base), 0.2),
})

let util = {
    ...utilBase,
    primary: {
        ...utilBase.primary,
        ...genShades(utilBase.primary.base, null)
    },
    red: {
        ...utilBase.red,
        ...genShades(utilBase.red.base, null)
    }
}


const panel = {
    light: '#FCFCFD',
    dark: '#222'
}




const colors = {
    light,
    dark,

    util,

    panel
}

export const getEditorAtrColor = (type, shade = 'base') => {
    switch (type) {
        case 'border': return colors.util.primary[shade];
        case 'padding': return colors.util.green[shade];
        case 'margin': return colors.util.orange[shade];
        case 'docking': return colors.util.orange[shade];
        default: return colors.util.primary[shade];
    }
}

export {
    light as lightColors,
    dark as darkColors,
    util as utilColors,
    panel as panelColors,
}
export default colors;