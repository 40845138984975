import { featureSelector } from "@redux/baseSelector";
import produce from "immer";
import { HYDRATE } from 'next-redux-wrapper';
import { default as actions, modalTypes } from "./action";


const initialState = {
  title: null,
  message: null,
  options: null,
  confirmAction: null,
  declineAction: null,
};
const modalReducer = (modal = initialState, action) => produce(modal, draft => {

  switch (action.type) {
    case HYDRATE:
      return draft = action.payload;
    
    case actions.SAVE:
      draft = {...action.payload};
      return draft;

    // case MODAL_SET:
    //   draft = {
    //     ...draft,
    //     type: action?.payload,
    //     active: action?.meta?.state,
    //     data: action?.meta?.data,
    //     msg: action?.meta?.msg,
    //     store: action?.meta?.store,
    //     input: null,
    //   };
    //   return draft;
    
    // case MODAL_CONFIRM:
    //   draft.input = action.payload;
    //   draft.active = action?.payload?.state;
    //   return draft;
      
    case actions.CLEAR:
      draft = initialState;
      return draft;
      
    // case MODAL_UPDATE: 
    //   draft.input = action.payload;
    //   draft.active = action?.meta?.state;
    //   break;

    // case MODAL_STATE:
    //   draft.active = action.payload;
    //   return draft;

    default:
      return draft;
  }
});
export default modalReducer;

const isConfirmation = (action) => action.type === modalTypes.confirmation

export const getModalSelector = state => featureSelector(state, 'modal')

// ! old !
export const getModalState = state => state?.present?.modal;
export const getModalStore = state => state?.present?.modal?.store;