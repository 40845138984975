import Button from "./Button";
import Code from "./Code";
import Expanders, { Expander, ExpanderHeader } from "./Expanders";
import Icon from "./Icn";
import Image from "./Image";
import Script from "./Script";
import Tabs, { Tab, TabHeader } from "./Tabs";
import Text from "./Text";
import Video from "./Video";

import DataLink from "./DataLink";
import DataProvider from "./DataProvider";
import Form from "./Form";

import Account from "./Account";

import Heading from "./Heading";
import ImageSlider from "./ImageSlider";
import Repeater from "./Repeater";
import Textarea from "./Textarea";

import Menu from "./Menu";

import { hasAttr } from "@util/Obj";
import { toLowCase } from "@util/Text";
import {Modal} from "./Modal";
import Gallery from "./Gallery";
 
import SocialGrid from "./SocialGrid";

import Input from "./Form/Input";
import GoogleMap from "./Map";
import { isFunc } from "@util/Funct";
import { AuthComponent } from "./Account/Auth";
import { UserComponent } from "./Account/User";
import StripeProvider from "./DataProvider/StripeProvider";

// const DialogRender = Dialog;
//  341
// export const registery = {

//     menu: Menu,
//     tab: Tab,

//     gallery: Gallery,
//     dialog: Dialog,

//     expander: Expander,
//     expanders: Expanders,
//     expanderheader: ExpanderHeader,

//     dataprovider: DataProvider,
//     form: Form,

//     button: dynamic(() => import("./Button")),
//     icon: dynamic(() => import("./Icn")),
//     code: dynamic(() => import("./Code")),
//     script: dynamic(() => import("./Script")),
//     image: dynamic(() => import("./Image")),
//     text: dynamic(() => import("./Text")),
//     localizedtext: dynamic(() => import("./Text")),
//     video: dynamic(() => import("./Video")),
//     tabs: dynamic(() => import("./Tabs")),
//     tabheader: TabHeader,


//     datalink: dynamic(() => import("./DataLink")),
//     content: dynamic(() => import("./Textarea")),
//     heading: dynamic(() => import("./Heading")),

//     repeater: dynamic(() => import("./Repeater")),
//     imageslider: dynamic(() => import("./ImageSlider")),
// }
const base = {
    content: Textarea,

    menu: Menu,
    heading: Heading,

    localizedtext: Text,
    text: Text,
    button: Button,
    image: Image,
    video: Video,
    icon: Icon,
    code: Code,
    script: Script,

    tab: Tab,
    tabs: Tabs,
    tabheader: TabHeader,

    gallery: Gallery,
    dialog: ({...props}, children) => Modal({...props}, children),

}
export const registery = {
    ...base,
    expander: Expander,
    expanders: Expanders,
    expanderheader: ExpanderHeader,

    repeater: Repeater,
    datalink: DataLink,
    dataprovider: DataProvider,
    stripeprovider: StripeProvider,
    form: Form,
    account: Account,
    auth: AuthComponent,
    user: UserComponent,

    inputfield: Input,

    googlemap: GoogleMap,

    imageslider: ImageSlider,

    socialgrid: SocialGrid,

}


const renderComponent = (type) => {
    type = toLowCase(type);
  
    // const render = getItemSchema(type)?.render
    return hasAttr(registery, type) ? registery[type] : null
    return (!render || !isFunc(render)) ? null : render;
     
  }
  export default renderComponent;