import { redNoticeAttention } from "@mosanic/styles/animations";
import styled, { css } from "styled-components";

const Tooltip = styled.div` 
    ${redNoticeAttention}
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 10px;
    font-weight: 600;
    font-family: sans-serif;
    line-height: 1;
    z-index: 9999;
    white-space: nowrap;
    opacity: 0;
    transition: opacity ease-in-out 0.1s;

    svg {   
        transform: scale(0.8);
        margin: -1px 0 0 0;
        path {
            fill: #fff;
        }
    }
    ${props => props.active && css` 
        opacity: 1;
    `}
    ${props => props.variant === 'notice' && css`
        animation: redNoticeAttention 1s infinite linear;
        box-shadow: 2px 2px 15px 0 rgba(0,0,0,0.4);
    `}
    
    ${props => props.arrow && css`
        &::before {
            content: " ";
            left: 50%;
            border: solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: var(--tooltip-arrow-size);
            margin-left: calc(var(--tooltip-arrow-size) * -1);
        }
    `}

    &[data-popper-placement^='top'] {
        top: calc(var(--tooltip-margin) * -1);
        &::before {
            top: 100%;
            border-top-color: var(--tooltip-background-color);
        }
    }
    &[data-popper-placement^='right'] {
        left: calc(100% + var(--tooltip-margin));
        top: 50%;
        transform: translateX(0) translateY(-50%);
        &::before {
            left: calc(var(--tooltip-arrow-size) * -1);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-right-color: var(--tooltip-background-color);
        }
    }
    &[data-popper-placement^='bottom'] {
        bottom: calc(var(--tooltip-margin) * -1);
        &::before {
            bottom: 100%;
            border-bottom-color: var(--tooltip-background-color);
        }
    }
    &[data-popper-placement^='left'] {
        left: auto;
        right: calc(100% + var(--tooltip-margin));
        top: 50%;
        transform: translateX(0) translateY(-50%);
        &::before {
            left: auto;
            right: calc(var(--tooltip-arrow-size) * -2);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-left-color: var(--tooltip-background-color);
        }
    }
`;

export const TooltipSelector = Tooltip;
const Wrapper = styled.div` 
    /* display: inline-block; */
    /* position: relative; */
    /* position: inherit; */
    /* display: inherit; */
    display: inline-block;

    &:hover {
        ${Tooltip} {
            opacity: 1;
        }
    }
`;
const TooltipStyle = {
    Wrapper,
    Tooltip,
}
export default TooltipStyle;