

// import { initialize } from '@mosanic/data';
import { ObjectID } from '@mosanic/utils';
import { getOptions, initialize } from '@mosanic/utils/data';
import { loopTroughMap, Move } from '@mosanic/utils/map';
import { artboardSelector } from '@redux/artboard';
import { addArtboardAction } from '@redux/artboard/action';
import { DROP_ID } from '@redux/map/drop/action';
import { mapActions } from '@redux/map';
import { itemMapByPathSelector } from '@redux/map/reducer';
import { hasWrapper, isValidated } from '@redux/map/validate';
import { currentPageName } from '@redux/screens/reducer';
import { hideSpinner } from '@redux/ui/uiAction';
import { enlargePayload, includesAction, isAction } from '@redux/util';
import { dataActions, default as actions } from './action';
import { DataFactory as factory } from '@mosanic/utils/data/factory';
import { itemSelector } from './selector';
import { convertPreSaveLayout } from '@mosanic/utils/map/transform';
import { dataSelector } from './reducer';

//Initialization of new item
export const dataActionFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.DATA_INIT)){ 
        const setupData = { 
            ...action.payload,
            ...getOptions(action?.payload?.options),
        };
        const component = initialize({item: setupData})

        dispatch(dataActions.addDataAction({component}, true));
    }   
};
 
//Convert to component
export const dataConvertFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.DATA_CONVERT)){ 
        const {path, target} = action.payload;
        const state = getState();

        //Get copy of original
        const originalItem = itemSelector(state, action.payload);

        //Gen new component id
        const _id = ObjectID()

        //Get item map from state
        const itemMap = itemMapByPathSelector(state, path);
        const storedData = dataSelector(state)
        
        const [
            newLayoutMap,
            layoutData,
            parent,
            count
        ] = convertPreSaveLayout(storedData, itemMap)

        const artboard = {_id: ObjectID(), title: `[local] ${originalItem?.type} ${count}`, saved: false}

        //Store component
        if(target != 'artboards'){
            dispatch(dataActions.addDataAction({component}, true));
        } else {
            dispatch(addArtboardAction({
                artboard, 
                map: newLayoutMap, 
                data: layoutData
            }))
        }
         
        //Compress source item
        const compressedItem = {
            ...originalItem, 
            refItem: _id,
            data: {},
            variants: undefined,
            states: undefined,
        }

        //Update source
        dispatch(dataActions.updateDataAction({component: compressedItem}, true));
    }   
};

//Convert to component
export const dataExtendFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(isAction(action, actions.DATA_EXTEND)){ 
        const {component, itemMap, dropPath} = action.payload;
        const state = getState();
        let ref = null;

        const currentPage = currentPageName(state);

        if(currentPage === 'artboards') {

            ref = artboardSelector(state)
            ref = itemSelector(state, ref);

            //Move item into reference map
            const newMap = Move.handleNewItem({
                map: ref?.map ? [ref.map] : [], 
                dropPath,
                item: itemMap
            })
 
            //Update main component and add new item map
            ref = {
                ...ref,
                components: {
                    ...ref?.components,
                    [itemMap?._id]: itemMap
                },
                map: newMap[0]
            };

            dispatch(dataActions.updateDataAction({component: ref}, true));
            dispatch(hideSpinner({location: 'canvas', feature: DROP_ID}));
        } else {
            // const path = splitPath(dropPath.splice(-1))
            // ref = itemMapByPathSelector(state, path);
            // ref = itemSelector(state, ref);
            // const afterSlotIndex = (parseFloat(dropPath[dropPath.length - 1]) + 1).toString();
            // const lastRemoved = [...dropPath].splice(0, dropPath.length - 1);
            // dispatch({type: 'TESTING DROP PATH', payload: {dropPath, afterSlotIndex, lastRemoved, new: [...lastRemoved, afterSlotIndex]}})

            dispatch(mapActions.addMapAction({itemMap, dropPath}));
        }
        
        

    }   
};


export const dataCopyFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.DATA_COPY)){ 
        const item = action.payload;
        const sourceId = action.meta?.sourceId;

        const obj = {group: item.group, _id: sourceId}
        const component = {
            ...itemSelector(getState(), obj),
            _id: item._id
        };

        dispatch(dataActions.addDataAction({component}, true));
    }   
};

export const dataRemoveFlow = ({getState, dispatch}) => next => action => {
    next(action);
  
      if(includesAction(action, actions.DATA_REMOVE)){ 
          const item = action.payload;
          const sourceId = action.meta?.sourceId;
  
          const component = {group: item.group, _id: sourceId}
          
          dispatch(dataActions.removeDataAction({component}, component._id,  true));
      }   
};

//Create wrapper if confirmed
export const factoryWrapperFlow = ({getState, dispatch}) => next => action => {

    if(hasWrapper(action) && isValidated(action)){ 
        const itemMap = action.payload.item;
        const options = action.options;
 9
        const wrapper = factory.wrapper({itemMap, type: options.wrapper}, dispatch);
        dispatch({type: "WRAPPER ADDED", payload: wrapper})

        action =  enlargePayload(action, {item: wrapper});
        dispatch({ ...action, options: {validated: true} });
        
    } else {
        next(action);
    }
};



export const dataMiddleware = [
    dataActionFlow,
    dataCopyFlow,
    dataConvertFlow,
    dataRemoveFlow,
    factoryWrapperFlow,
    dataExtendFlow
];