
import { Condition } from "@JSX";
import { canMap } from "@util/Arr";
import { isString } from "@util/Text";
import styled, { css } from "styled-components";
import { isLink, RenderLink } from "../Link";
import { SkeletonCard, SkeletonLine } from "../Skeletons";
import { default as s } from "./List.style";


export const List = ({children, ...other}) => <s.List {...other}>{children}</s.List>

export const ListItem = ({children, chevron, ...other}) => (
    <s.ListItem {...other}>
        {children}
        <s.Chevron>{chevron}</s.Chevron>
    </s.ListItem>
)
export const ListItemContent = ({children, ...other}) => <s.ListItemContent {...other}>{children}</s.ListItemContent>
export const ListItemText = ({children, text = null, ...other}) => <s.ListItemText {...other}>{text ? text : children}</s.ListItemText>
export const ListItemSub =  ({children, ...other}) => <s.ListItemSub {...other}>{children}</s.ListItemSub>
export const ListItemIcon = ({children, ...other}) => <s.ListItemIcon {...other}>{children}</s.ListItemIcon>
export const ListItemRight = ({children, ...other}) => <s.ListItemRight {...other}>{children}</s.ListItemRight>
export const ListItemChevron = ({children, ...other}) => <s.Chevron {...other}>{children}</s.Chevron>
export const ListDivider = ({children, ...other}) => <s.ListDivider {...other}>{children}</s.ListDivider>

export const RenderList = ({items, size = null, icons = null}) => canMap(items) ? items.map(item => (
    <ListItem key={item?.href+item?.label+item?.key} active={item?.active} size={size} icons={icons}>
        {Boolean(item?.href) ? <RenderLink item={item}/> :
            item?.handler ? 
                <a onClick={item.handler}>
                    {item?.icon}{item?.label}
                </a>
         : isString(item) ? item : item?.label ? item.label : item}
    </ListItem>
)) : null;


const Item = styled(ListItem)` 
    position: relative;
    
    ${props => props.divider && css` 
        border-bottom: 1px solid #e7e9ed;
    `}
`
export const ListItems = ({items, active, handler, drag, wrap, header, controls, loading = false, divider = false}) => (
    <List style={{width: '100%'}}>
        {items?.length >= 1 ? items?.map(item => (
            <Condition when={Boolean(drag)} wrapper={children => drag(item, children)}>
                <Item 
                    divider={divider}
                    key={item?._id} 
                    onClick={handler ? () => handler(item) : null}
                    active={active === item?._id}
                >   
                    <Condition when={Boolean(wrap)} wrapper={children => wrap(children)}>
                        {header ? header(item) : (
                            <>
                            <b>{item?.title ? item.title : item?.name}</b> <br/>
                            {item?.description}
                            </>
                        )}
                        {controls && controls(item)}
                    </Condition>
                </Item>
            </Condition>
        )) : (
            <Item>
                No results.
            </Item>
        )}
        {loading && (
            <Item>
                {wrap ? wrap(<SkeletonLine />) : (
                    <SkeletonLine />
                )}
            </Item>
        )}
    </List>
)