
const ID = '[breakpoints]'
export const BREAKPOINTS_ID = ID;

const BREAKPOINT_ADD             = `${ID} Add breakpoint`;
const BREAKPOINT_REMOVE          = `${ID} Remove breakpoint`;
const BREAKPOINT_UPDATE          = `${ID} Update breakpoint`;

const BREAKPOINTS_REORDER        = `${ID} Reorder`;
const BREAKPOINTS_RESET          = `${ID} Reset`;


export const handleBreakpointAdd = (breakpoint) => ({
    type: BREAKPOINT_ADD,
    payload: breakpoint,
    meta: {feature: BREAKPOINTS_ID}
})
export const handleBreakpointRemove = ({_id}) => ({
    type: BREAKPOINT_REMOVE,
    payload: _id,
    meta: {feature: BREAKPOINTS_ID}
})
export const handleBreakpointUpdate = ({breakpoint, _id}) => ({
    type: BREAKPOINT_UPDATE,
    payload: {breakpoint, _id},
    meta: {feature: BREAKPOINTS_ID}
})



export const reorderBreakpoints = () => ({
    type: BREAKPOINTS_REORDER,
    meta: {feature: BREAKPOINTS_ID}
});

export const resetBreakpoints = () => ({
    type: BREAKPOINTS_RESET,
    meta: {feature: BREAKPOINTS_ID}
});


const BREAKPOINTS_ACTION_IDS = {
    BREAKPOINT_ADD,
    BREAKPOINT_REMOVE,
    BREAKPOINT_UPDATE,
    BREAKPOINTS_REORDER,
    BREAKPOINTS_RESET 
}
export default BREAKPOINTS_ACTION_IDS;
