
import { ControlDialog, ControlPopper, ErrorAlert } from "@mosanic/items";
import { useEffect, useState } from "react";

import {
    useCollection,
    useCollections
} from "@mosanic/core/Collections";

import { Condition } from "@JSX";

import {
    getEntry,
    getExtendedFieldPath,
    getSourceEntry,
    isDataLink,
    isProvider,
    isProviderSource
} from "@mosanic/fields/Source/helpers";

 
import { colors } from "@mosanic/styles";
import { ClearBoth } from "@mosanic/styles/util";
import { Link1Icon } from "@radix-ui/react-icons";

import { siteIdSelector } from "@redux/auth/reducer";
import {
    selectInspectContext,
    selectInspectItemClone,
    selectInspectItemId,
    selectInspectItemType
} from "@redux/inspect/reducer";
import { capitalizeFirst, combineString, excerptString, isString } from "@util/Text";

import { useSelector } from "react-redux";
import styled from "styled-components";

import TextField from "../TextField/Fields";

import { useDataReceiver } from "@mosanic/builder/DataLink/DataReceiver";
import Header from "./Header";
import { EntrySelector, FieldSelector, ProviderSelector } from "./Select/Provider";
import { SwitchColumns } from "./SourceColumns";
import { SourceFooter } from "./SourceParts";
import { useSources } from "./useSources";
 


 const getSourceType = (state, type) => type != 'collection' ? type : state?.modus === 'page' ? 'Page collection' : 'Collection';

 export const Columns = styled.div` 
    display: flex;
    flex-wrap: nowrap;

    ul {
        min-width: 150px;
        width: 150px;

        border-right: ${colors.util.gray.light} 1px solid;
        margin: 0;
        padding: 0 5px;
        overflow-x: hidden;
    }
`
 

const SourceSelector = ({
    handler = null,
    onChange = null,
    inline = false,
    value = null,
    sourceSelector = false,
    type = 'collection',
    activate = null,

    isEnd = false,

    height = null,
    width = null,

    collectionId
}) => {
    if(handler && !onChange) onChange = handler
    
    //If id is received, set as only option.
    if(collectionId && state?.collection != collectionId) {
        onChange({...state, collection: collectionId})
    }
    //Set base entry tab
    if(!value?.tab?.entry && onChange) {
        onChange({...value, tab: {entry: 'entry', field: 'field'}})
    }

    if(!value?.fieldPath && onChange) {
        onChange({...value, fieldPath: []})
    }

    const {tab} = value || {tab: null};
    const state = value;
 

    const itemType = useSelector(selectInspectItemType)
    const clone = useSelector(selectInspectItemClone)
    const _id = useSelector(selectInspectItemId)


    const [
        providers,
        provider,
        entry,
        schema,
        loading,
        controls
    ] = useSources({value})
    const context = useSelector(selectInspectContext)


    const dataProvider = context?.dataProvider?.[state?.provider]?.entry;



    //Use main retriever for schemas
    const [ collections,  loadings,  error ] = useCollections({
        filter: {
            siteId: useSelector(siteIdSelector), 
            _id: collectionId || undefined
        }, indexOnly: true
    });


    //Use single collection retriever
    const [getColl, [retrieved, loadingRet, errorRet]] = useCollection({
        _id: state?.retrieveEntries || collectionId
    }, true)

    //Get entry by selected value's
    let [collection, schema_, baseRoute, item, fieldPath] = getSourceEntry({
        collections: collections?.map(c => c?._id === retrieved?._id ? retrieved : c),
        context, 
        value,
    })




    const path = (isProviderSource(state) && fieldPath?.length >= 1) ? [
        ...fieldPath, state?.fieldPath
    ] : state?.fieldPath;
    

    const canSave = true


    const activeStateFieldPath = state?.fieldPath;

    const newExtendedFieldPath =  getExtendedFieldPath(activeStateFieldPath, dataProvider?.fieldPath);
    

    const formatFieldPath = () => value?.fieldPath?.map(f => 
        f?.type === 'relation' ? 
            ({type: f?.type, name: f?.name, relation: f?.relationEnd, render: f?.relationRender}) :
            ({type: f?.type, name: f?.name})
    ) 
    
    const getSaveValue = () => ({
        ...value,
        collection: (
            inline ? (
                value?.repeaterSource ? null : value?.collection
            ) : value?.collection
        ),
        fieldPath: formatFieldPath(),
        // extend: 'entry',
        // newPath: undefined,
        
        schema: undefined
    })

    
    const save = () => {
        onChange(getSaveValue())
        // console.log(getSaveValue())
        // console.log(newExtendedFieldPath)
        // console.log(activeStateFieldPath)
    }


    const title = collection ?  (
        type === 'collection' ? 
            excerptString(collection?.title+' ', 9, '...') : 
            collection?.title 
    ) : capitalizeFirst(`${type} source`)

    //Expand dialog
    const [expand, setExpand] = useState(false)
    const handleExpand = () => setExpand(!expand)


    let [content] = useDataReceiver({
        source: value?.source,
        entry: value,
        clone,
    })

    
    const headerData = {
        title,
        dialog: {expand, handleExpand}, 
        helpText: (
            isProvider(itemType) ? 'The latest selected element will be provided to the children of this data provider' :
            isDataLink(itemType) ? 'Select a data provider source and a available field to render.' :
            ''
        ), 
        value, handler: onChange, itemType, context
    }

    const activeEntry = {
        entry: (
            state?.source === 'page' ? context?.pageProvider?.entry : 
            state?.provider && Array.isArray(context.dataProvider?.[state.provider]?.[state?.providerFieldPath?.[0]]) ? context.dataProvider?.[state.provider]?.[state?.providerFieldPath?.[0]]?.find(e => e?.id === state?.entry) :
            getEntry(collection, state?.entry, state?.clone)
        ),
        collection
    };



    
    const displayValue = !value?.fieldPath ? title : 
        `${title ? title : ''} › ${combineString(value?.fieldPath?.map(f => f?.name),' › ')}`

    const isList = true;

    useEffect(() => {
        if(value?.column === 'editor')
            value?.fieldPath?.length >= 1 ? onChange({
                ...value,
                editor: `$ctx.entry.${combineString(value?.fieldPath.map(f => f.name), '.')}`
            }) : onChange({
                ...value,
                editor: `$ctx.collections`
            })
        
    },[value?.fieldPath?.length, value?.column])


    


    return (
        <ControlPopper  noPadding
            header={
                <Header {...headerData} />
            }
            footer={(
                <SourceFooter 
                    collection={collection}
                    state={state}
                    activeEntry={activeEntry}
                    canSave={canSave}
                    save={save}
                    type={type} 
                />)}
            trigger={isEnd ? <>{isEnd}<Link1Icon /></> : <TextField value={displayValue} disabled />}  
            // width={450} 
            // height={300}
            width={width || 950} 
            height={height || 600}  
            scroll
        >    
        <Condition when={expand} wrapper={children => (
            <ControlDialog title="Dynamic data" state={expand} close={handleExpand} ghost>
                {children}
            </ControlDialog>
        )}>
            {isString(item) && (
                <ErrorAlert errorMessage={item}/>
            )}

                
            {error}
            <SwitchColumns 
                loading={loading[0]}
                column={value?.dev || value?.column}
                value={value}
                editor={{
                    onChange: code => onChange({...value, editor: code}),
                    value: value?.editor,
                    ctx: {
                        entry: activeEntry?.entry ? activeEntry.entry : activeEntry,
                        collections,
                    }
                }}
                dev={{
                    state: value,
                    context,
                    collection,
                    collections,
                    providers
                }}
                codeFieldValue={{
                    activeEntry: activeEntry?.entry ? activeEntry.entry : activeEntry,
                    setState: onChange,
                    state: value
                }}
                ctx={{
                    entry: activeEntry?.entry ? activeEntry.entry : activeEntry,
                    collections,
                    output: content
                }}
                // column={}
            >
                <Columns>    

                    {Array.isArray(providers) && providers?.length >= 1 && (
                        <ProviderSelector 
                            type={state?.source}
                            providers={providers}
                            value={value?.collection}
                            onChange={collection => onChange({
                                ...value, 
                                path: [collection],
                                collection, 
                                fieldPath: undefined, 
                                entry: undefined, 
                                tab: 'entry'
                            })}
                        />
                    )}
                    {/* <div>
                        <pre>
                        {JSON.stringify({entry}, null, 2)}
                        </pre>
                    </div> */}

                    <EntrySelector
                        controls={controls}
                        loading={loading?.[1]}
                        provider={provider}
                        value={value}
                        onChange={newState => onChange({...value, ...newState})}
                    />

                    <FieldSelector 
                        onChange={onChange}
                        provider={provider}
                        schema={schema}
                        entry={entry}
                        value={value}
                    />
                    {/* {value?.fieldPath?.length <= 0 && (
                        <List style={{minWidth: 400}}>
                            <FullCodeEditor 
                                showPreview
                                state={value?.output} 
                                ctx={{data: content}}
                                onChange={val => onChange({...value, ...val})} 
                            />
                        </List>
                    )} */}

{/* 
                    <SelectField
                            // todo render entry ass received 
                            // --> id, createdAt, identifier 
                            // ! data [object]
                            //     todo ---> render the schema fields 
                            
                            canSelectField={fieldType => canSelectField(fieldType, itemType)}
                            activeEntry={
                                entry ? entry : null 
                            }
                                // isRepeaterSource(state) ? item?.[state?.clone] :
                                // isSource(state) ? item : activeEntry?.entry}
                            baseRoute={baseRoute}
                            dataProvider={isProviderSource(state) && dataProvider}
                            setState={onChange}
                            state={{...state, 
                                fieldPath: path,
                                newPath: newExtendedFieldPath,
                                schema: schema //fb for repeater nested
                                // schema?.schema ? schema.schema : 
                            }}
                            wrap
                        /> */}


{/* const getState = ({entry = null, preview = null}) => ({entry, preview, tab: 'field', fieldPath: []})
setState(getState({entry: entry.value}))} */}
                    {/* {(state?.source === 'provider' && Object.entries(context?.dataProvider)?.length >=1 ) ? (
                        
                        <>
                        {state?.provider && (
                            <ProviderEntrySelector 
                                provider={context.dataProvider?.[state.provider]}
                                value={state?.providerFieldPath}
                                onChange={providerFieldPath => onChange({...value, providerFieldPath})}
                            />
                        )}
                        {state?.providerFieldPath && (
                            <ProviderFieldSelector 
                                data={context.dataProvider?.[state.provider]?.[state?.providerFieldPath?.[0]]}
                                state={state}
                                activeEntry={activeEntry}
                                onChange={val => onChange({...state, ...val})}

                            />
                        )}
                        </>
                    ) : null} */}

                    {/* {(state?.tab?.entry === 'entry' && state.collection && state?.source != 'provider') && (
                        <SelectEntry 
                            // todo --> select the 'selected' entry
                            // todo --> add option '... collection entries' : 5 entries
                            // !                                ---> add column : 'select entry'
                            as={isList ? "list" : "select"}
                            collection={collection}
                            onChange={val => onChange(val)}
                            state={state}
                            activeEntry={activeEntry}
                        />
                    )} */}

                    {/* {((isList || tab === 'field')) ? (
                        <SelectField
                            // todo render entry ass received 
                            // --> id, createdAt, identifier 
                            // ! data [object]
                            //     todo ---> render the schema fields 
                            
                            canSelectField={fieldType => canSelectField(fieldType, itemType)}
                            activeEntry={
                                isRepeaterSource(state) ? item?.[state?.clone] :
                                isSource(state) ? item : activeEntry?.entry}
                            baseRoute={baseRoute}
                            dataProvider={isProviderSource(state) && dataProvider}
                            setState={onChange}
                            state={{...state, 
                                fieldPath: path,
                                newPath: newExtendedFieldPath,
                                schema: schema //fb for repeater nested
                                // schema?.schema ? schema.schema : 
                            }}
                            wrap
                        />
                    ) : (
                        <AdvancedLabel m="0 auto" as="h5" label={`No fields found for the data retreived from: ${capitalizeFirst(state?.source)}.`}/>
                    )} */}
                    <div>
                        <pre>
                            {/* {JSON.stringify(schema, null, 2)} */}
                        </pre>
                    </div>

                </Columns>
            </SwitchColumns>
            
 

    
    
            <ClearBoth />
        </Condition>
        </ControlPopper>
    )
} 




export default SourceSelector;