
export const serialize = ({content})=> {
    if(!content) return null;
    return content?.map(b => {
         return b?.children?.map(n => `${n?.text} \n`);
    });
}


// import escapeHtml from 'escape-html'
// import { Text } from 'slate'

// const serialize = node => {
//   if (Text.isText(node)) {
//     let string = escapeHtml(node.text)
//     if (node.bold) {
//       string = `<strong>${string}</strong>`
//     }
//     return string
//   }

//   const children = node.children.map(n => serialize(n)).join('')

//   switch (node.type) {
//     case 'quote':
//       return `<blockquote><p>${children}</p></blockquote>`
//     case 'paragraph':
//       return `<p>${children}</p>`
//     case 'link':
//       return `<a href="${escapeHtml(node.url)}">${children}</a>`
//     default:
//       return children
//   }
// }

// import { Node } from 'slate'

// const serialize = nodes => {
//   return nodes.map(n => Node.string(n)).join('\n')
// }