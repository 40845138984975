import { isString } from "./Text";

export const addDays = (days) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() + days);
  return date;
};

export function isValidDate(dateString) {
    if(!dateString || !isString(dateString)) return;
    dateString = dateString.slice(0, 10);
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(regEx) != null;
}


export const getDate = (date) =>  !date ? '' : 
  new Date(date).toLocaleDateString('nl-NL', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const getTime = (date) => {
  if(!date) return '';
  return new Date(date).toLocaleTimeString('nl-NL', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const getDif = (start, end = new Date()) => {
  const expires = start;

  const diffTime = Math.abs(end - expires);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  const hours = parseFloat(diffTime / (1000 * 60 * 60));
  const min = parseFloat(diffTime / (1000 * 60));
  const s = parseFloat(diffTime / (1000));

  if(diffDays >= 1) return (parseFloat(diffDays) + " days");
  if(hours >= 1) return (parseFloat(hours + " hours"));
  if(min >= 1) return (parseFloat(min + " minutes"));
  if(s >= 1) return (parseFloat(s + " seconds"));

}
export const getFullTimeStamp = (data) => `${getDate(data)} ${getTime(data)}`