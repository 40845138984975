
import { Select } from '@mosanic/fields';
import { AdvancedLabel, ControlDialog } from '@mosanic/items';



import { getTooltips } from '@redux/options/reducer';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { EDITOR_MODES } from '../../Adapters/constants';
// import SaveItem from "../Items/SaveItem";





export default function DataModal({state, close, component, cssStyle}) {

  const tooltips = useSelector(getTooltips); 
  const dispatch = useDispatch();

  const elementData = component?.data?.content;



    const [value, setValue] = useState('classic');
    const handleSelect = (event) => {
        setValue(event.target.value);

    }


  return (
    <>
  <ControlDialog size="sm" fullWidth={false} state={state} close={close}> 
      <AdvancedLabel
          renderHelp={true}
          helpText={`Enter a title and short description to save component as a 'builder- item' preset <a className="skyBlue float-right">Learn more</a>`}>
              Component data
      </AdvancedLabel>
       <div className="inputGroup -large">
        {elementData  ? (
          <div className="inputGroup__item">
            <Select
              className="modeSelect" size="small" value={value} label="Editor" onChange={handleSelect}
              MenuProps={{style: {maxHeight: 400,},}}
              options={EDITOR_MODES}
            >
            
            </Select>
          </div>
        ):null}
      </div>
      {elementData  ? (
        <div className={'-'+value}>
          {/* <EditorCo ntainer content={elementData} passEditorData={setComponentData} mode={value}/> */}
        </div>
      ):null}

      <span dangerouslySetInnerHTML={{ __html: cssStyle}} />
      
      <hr/>

    </ControlDialog>
    </>
  );
}