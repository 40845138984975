import { getItemMapFromActionOrState } from '@mosanic/map/get';

import { dropActions, default as actions } from '../drop/action';
import { includesAction } from '@redux/util';

export const copyComponentActionFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.DROP_COPY)){ 
        const state = getState();
        let {preStore} = action.payload;
         
        //Get current inspected item map
        const itemRef = getItemMapFromActionOrState({action, state})
        
        //When not pre storing, append as sibling to copied source
        if(!preStore) {
            dispatch(dropActions.clearPreStoreAction())
            dispatch(dropActions.handleAutoPasteAction({
                sourceItemPath: itemRef.path,
                itemRef
            }))

        } else {
            dispatch(dropActions.handlePreStoreAction({
                ...itemRef,
                sourceItemPath: itemRef.path,
                isCopied: true,
            }))
        }
    }
};

export const copyMiddleware = [
    copyComponentActionFlow
];