import { DEFAULT_FONTS } from '@mosanic/constants/typhography';
import { featureSelector } from '@redux/baseSelector';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';

import { TYPE_ACTION_IDS as actions } from './action';

const initialState = {
    fonts: DEFAULT_FONTS,
    sources: [],
    fontScale: 1.250,
    baseSize: 16
};


const typesReducer = (types = initialState, action) => produce(types, draft => {
    const pl = action.payload;
    switch (action.type) {
        case HYDRATE:
            return {...types, ...action.payload};

        case actions.SET_TYPES: 
            return {...types, ...action.payload};

        case actions.RESET_TO_BASE:
            return initialState;

        case actions.ADD_SOURCE: 
            if(draft?.sources?.filter(s => s?.src === pl?.src)?.length >= 1) return draft;
            return {
                ...draft,
                sources: [...draft?.sources, action.payload]
            }
        case actions.REMOVE_SOURCE: 
            
            return {
                ...draft,
                sources: draft?.sources?.filter(s => s.src != pl.src)
            }
     
        case actions.SET_FONTS: 
            return {
                ...draft,
                fonts: action.payload
            }
        case actions.UPDATE_FONT: 
            return {
                ...draft,
                fonts: draft.fonts.map(f => {
                    if(f.type != pl.type) return f;
                    return {
                        ...f,
                        ...pl
                    }
                })
            }
            
        case actions.TOGGLE_FIELD: 
            return {
                ...draft,
                fonts: draft.fonts.map(f => {
                    if(f.type != pl.current.type) return f;
                    return {
                        ...f,
                        [pl.field]: f[pl.field] ? false : true
                    }
                })
            }
        
        case actions.SET_FIELD: 
            return {
                ...draft,
                fonts: draft.fonts.map(f => {
                    if(f.type != pl.current.type) return f;
                    return {
                        ...f,
                        [pl.field]: pl.value
                    }
                })
            }

        case actions.SET_VALUE: 
            return {
                ...draft,
                [pl.field]: pl.value 
            }

        default:
            return types;
    };  


});
export default typesReducer;


export const typesSelector = state => featureSelector(state, 'types')
export const fontSourcesSelector = state => typesSelector(state)?.sources
export const fontsSelector = state => typesSelector(state).fonts
export const fontScaleSelector = state => typesSelector(state).fontScale
export const baseFontSizeSelector  = state => typesSelector(state).baseSize