import { getCurrentPath } from "./path";
import { isColumns, isRow } from "./type";


export const getLastDropzonePath = (path, childCount) => {
    return path ? `${path}-${childCount}` : `${childCount}`
  }

export const isHorizontalDropzone = (componentType) => isColumns(componentType) || isRow(componentType);

export const getDropzoneClass = (type) =>{
    switch (type) {
      case 'repeater':
        return type+'__dropZone';
      case 'row':
        return type+'__dropZone horizontalDrag';
      case 'columns':
        return 'row__dropZone horizontalDrag'
      case 'column':
        return type+'__dropZone';
      case 'component':
        return type+'__dropZone';
      case 'stack':
        return type+'__dropZone';
      default:
        return 'page__dropZone'; 
    }
  }

export const getDropzoneData = (path, index, children) => ({
  path: getCurrentPath(path, index),
  childrenCount: children?.length
});
