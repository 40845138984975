import { Condition } from '@JSX';
import Render from '@mosanic/core/Map';
import { useBreakpoint } from '@mosanic/hooks/Breakpoint';
import MetaHead from '@mosanic/items/Page/MetaHead';

export const Page = ({page, mode = 'prod', locale = null, seo = null, blockSeo = false, noWrap = false, bp = 'auto'}) => {
  
    const breakpoint = useBreakpoint({overwrite: bp})

    const pageProps = { 
      data: page?.data,
      breakpoint: bp === 'auto' ? breakpoint : bp,
      child: page?.map,
      level: 0,
    } 
    return (
      <> 
        {!blockSeo && (
          <MetaHead {...seo} locale={locale} />
        )}
        <Condition when={!noWrap} wrapper={children => (
          <div className="mosanic_site">
            {children}
          </div>
        ) }>

          
          <Render {...pageProps} mode={mode} />
        </Condition>
      </>
  );
}