import { Condition } from "@JSX";
import renderComponent from "@mosanic/builder/registery";
import { Items } from "@mosanic/constants";
import { hasSlots, manageSlots } from "@mosanic/utils/map/slots";
import { useProductionFieldsRef } from "@mosanic/utils/schema";
import { isFunc } from "@util/Funct";
import { hasAttr } from "@util/Obj";
import { getTag } from "@util/Tag";
import { isString } from "@util/Text";
import Link from "next/link";
import { useRouter } from 'next/router';
import { createContext, useState } from "react";
import { useValidate } from "../../hooks/useValidate";
import Row from "./ItemRow";
import { Component } from "./Styles/Item.styles";
import { useFormProvider } from "../Provider";

const Wrapper = ({props, children}) => {
    if(props?.child?.itemType === Items.ROW)
        return <Row props={{...props, children}} menu={{render: 'Column'}} production />

    return children;
}

export const ProdFieldsContext = createContext(undefined);

//View item with passed data
const Prod = ({props, item, ...other}) => {
    const {child, path, level, breakpoint} = props;
    const validate = useValidate(item?.state, breakpoint)

    const router = useRouter() 
    const isEnglish = router?.locale === 'en';

    const fieldAction = item?.fields?.action;
    const isLink = (!fieldAction || !['trigger','locale'].includes(fieldAction));

    const [hoverState, setHoverState] = useState(false)
    const hover = {
        hoverState, 
        onMouseEnter: () => setHoverState(true),
        onMouseLeave: () => setHoverState(false),
    }

    const Render = renderComponent(item?.type) 
    const reference = useProductionFieldsRef({
        item,
        active: false,
        clone: other?.clone,
        breakpoint,
    });

    const form = useFormProvider();



    let triggerLocale = (fieldAction === 'locale' || (
        fieldAction === 'trigger' && item?.fields?.type === 'locale'
    )) ?
        () => router.replace(router.asPath, null, {locale: isEnglish ? 'nl' : 'en'}) :
        null;

    let triggerForm = (
        fieldAction === 'trigger' && item?.fields?.type === 'form toggle'
    ) ?
        () => form?.toggleEditing() :
        null;

    let linkHref = (hasAttr(reference?.fields, 'href') && isLink) ? ({
        href: reference?.fields?.href,
        target: reference?.fields?.target
    }) : null;

    
    const slotComponent = hasSlots(child?.children)
    const manageComponent = manageSlots(child?.children)
    const noSlots = slotComponent;

    
    return (
        <Condition when={linkHref?.href} wrapper={children => (
            <Link
                href={linkHref.href} 
                target={linkHref.target}>
                    {children}
            </Link>
        )}>


            <Component
                as={isString(item?.data?.tags?.tag) ? item.data.tags.tag : getTag(item?.type)}
                type={item?.type != 'button' ? item?.type : 'submit'}
                className={item?.className}
                childs={child?.children?.length}

                validate={validate}
                data={item?.data}
                onClick={(e) => {
                    if(other?.controlProps?.handler) other.controlProps.handler(e)
                    if(triggerLocale) triggerLocale()
                    if(triggerForm) triggerForm()
                }}
                {...hover}
                production
            >   
                <Wrapper props={props}>
                    {/* <ItemControls menu={controls[item.itemType]} /> */}
                    {slotComponent ? (
                        <ProdFieldsContext.Provider value={reference}>


                            {(isFunc(Render) && manageComponent) ?  
                                Render(reference, props?.children) :
                                props?.children    
                            }


                           
                        </ProdFieldsContext.Provider>
                   ) : (
                        <>
                        {(Render && isFunc(Render)) &&  Render(reference) } 
                        {props?.children}
                        </>
                    )}
                    
                </Wrapper>
            </Component>
        </Condition>
    )
}

export default Prod;