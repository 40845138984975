

const includesAction = (action, id) => action?.type?.includes(id) && action.type != id;
const includesOneAction = (action, ids) => {
    let includes = false;
    ids.map(id => { if(includesAction(action, id)) includes = true; });
    return includes;
}

const isAction = (action, id) => action.type === id;


export {
    includesAction,
    includesOneAction,
    isAction,
}