import { createSelector } from "reselect";
import {
    hoverSelector,
    inspectHoverSelector
} from "./reducer";


const parameters = (state, obj) => obj;
const validateParameters = (state, obj) => ({
    ...obj, isInspected: true //isItemInspected(state, obj)
});

// const hoverIdSelector = state => hoverSelector(state).map?._id
// const inspectedIdSelector = state => inspectedSelector(state).map?._id
// const parameterId = (state, id) => id;

export const isItemHovered = createSelector([
    hoverSelector,
    parameters
],(hover, obj) => 
    obj.validateScreen ?
        hover.map?._id === obj.id && hover.screen === obj.screen :
        hover.map?._id === obj.id
);



export const isItemInspected = createSelector([
    inspectHoverSelector,
    parameters
],(inspected, obj) => 
    obj.validateScreen ?
        inspected?._id === obj.id && inspected.screen === obj.screen :
        inspected?._id === obj.id
);



