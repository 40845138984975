import { useEffect, useState } from 'react';

import { Button, Select, Switch } from '@mosanic/fields';
import { AdvancedLabel, InputGroup, LoadingDots } from '@mosanic/items';
import { ControlTabs } from '@mosanic/items/Control/ControlButtons';
import { Col, Wrap } from '@mosanic/styles';
import { ArrowLeftIcon, BlendingModeIcon, Component1Icon, EyeOpenIcon, FileIcon, InputIcon, ListBulletIcon, RocketIcon, TokensIcon } from '@radix-ui/react-icons';
import { hasAttr } from '@util/Obj';
import { isString, slugify } from '@util/Text';
import { FormProvider, useForm } from "react-hook-form";
import { EditorBase } from './EditorBase';
import { Entry } from '@mosanic/core/Collections';
import { FieldsCollection } from '@mosanic/core/Collections/FieldsCollection';
import { addUnset } from '@mosanic/_Fieldsets';
import { useCreateCollection } from '@mosanic/core/Collections/useCreateCollection';
import { useUpdateCollection } from '@mosanic/core/Collections/useUpdateCollection';
import { useCreate } from '../Mutate/useDataMutate';
import { DataGrid } from '../DataGrid/DataGrid';
import dynamic from 'next/dynamic';
import { List, ListItem } from '@mosanic/items/List';
import Image from 'next/image';
import { getEntryLabel } from '@mosanic/fields/Source/helpers';
import { parseImageSrc } from '@mosanic/utils/Image';
import { EditorTransform } from './EditorTransform';
import {EditorBaseFields} from './EditorBaseFields'
import { EditorVariables } from './EditorVariables';
import { EditorTemplates } from './EditorTemplates';
import { userSchema } from '@api/schemas/users';
import { FieldHeaderLabels } from '@mosanic/core/Collections/Field/Header';

const ReactJson = dynamic(() => import('react-json-view'), {
  ssr: false
})

const Item = ({item}) => (
    <div>
        {item?.id} x
    </div>
)

export const EditorProvider = ({
    initial = null, 
    formType = 'create', 
    redirect = true, 
    callback = null, 
    stopEditing = () => null,
	dataHookMethods = null, 
	entryHookMethods = null,
    type = 'collection',
    types = ['collection', 'form'],
    singular,
	baseSchema = userSchema
    // schema = []
}) => {
    singular = singular || type

	const methods = useForm({mode: 'all', defaultValues: initial});
	const formData = methods.watch();
	const schema = formData?.schema
	// const schema = formData?.schema


	const [action, [data, loading, error]] = dataHookMethods?.[formType] ? dataHookMethods?.[formType]({callback, formData}) : [() => null, [null, false, null]]

 

    const isValid = methods.formState.isValid;

	const [disabled, setDisabled] = useState(false);
	const [init, setInit] = useState(false);

	const [ addCollection, [dataAdd, loadingAdd, errorAdd]] = useCreateCollection({redirect, type})
	const [ update, [dataUpdate, loadingUpdate, errorUpdate]] = useUpdateCollection({callback})

	const [saving, setSaving] = useState(false)
	const [progress, setProgress] = useState(null)
	// const [ids, setIds] = useState([null])
	
	


	const onSubmit = (data) => {
		if(hasAttr(data, 'preventDefault')) data?.preventDefault();
		console.log(data)
		// if(mode === 'transform') return;
		// if(!saving) return
		// setDisabled(true)

		// if(formType === 'create') {
		// 	addCollection({formData: data})
		// } else {
		// 	update({formData: {...data, linked: undefined, baseFields: convert}})
		// }
		console.log(dataHookMethods)
	// 	action({data: {
	// 		...data?.linked ? ({
	// 			...data,
	// 			entries: [...new Set(data?.entries?.map(e => e?._id || e))]
	// 		}) : data,
	// 		linked: undefined
	// 	}
	// })
	action({data: {
		...data,
		linked: undefined
	}})
		// action({data: {
		// 	...data,
		// }})
		setDisabled(false)
		setSaving(false)
	};

	const triggerSave = () => {
		setSaving(true);
	}

	useEffect(() => {
		if(initial || init || formType != 'create') {
			methods.reset(initial)
			return
		} else if(baseSchema.schema){
            methods.reset({schema: baseSchema.schema})
        }
		// methods.reset({
		// 	schema:[{
		// 		formType:"text",
		// 		id: ObjectID(),
		// 		name:"",
		// 		help:"",
		// 		default:"",
		// 		required:true
		// 	}]	
		// })
		setInit(true)
	},[init, methods, initial, formType, baseSchema.schema])

	const [mode, setModeTab] = useState('base')
    const setMode = t => t === 'return' ? stopEditing() : setModeTab(t)

    let tabOptions = [
        {label: <span>Return</span>, value: 'return', icon: <ArrowLeftIcon />},
        {label: <span>Schema</span>, value: 'base', icon: <Component1Icon />},
		{label: <span>Templates</span>, value: 'templates', icon: <FileIcon />},
        {label: <span>Edit fields styling</span>, value: 'styling', icon: <BlendingModeIcon />},
    ]
    if(baseSchema?.groups) tabOptions = tabOptions?.filter(tab => baseSchema?.groups?.includes(tab?.value) || tab?.value === 'return')
	
	if(formData?.schema?.length >= 1 && formData?.schema?.filter(f => !f?.name)?.length === 0) tabOptions = [...tabOptions, 
		{label: <span>Preview</span>, value: 'preview', icon: <EyeOpenIcon />}
	]

	if(formData?.collectionId) tabOptions = [...tabOptions, 
		{label: <span>Variables</span>, value: 'variables', icon: <TokensIcon />}	
	]
	if(formData?.linked) tabOptions = [...tabOptions, {
		label: <span>Base fields</span>, value: 'entry', icon: <InputIcon />
	}]


	if(!isString(formData?.entries?.[0])  && !formData?.linked && formData?.entries?.[0]) tabOptions = [...tabOptions, {
		label: <span>Transform</span>, value: 'transform', icon: <RocketIcon />
	}]
	
	if(initial?.entries && initial?.schema) tabOptions = [...tabOptions, {
		label: <span>Entries</span>, value: 'entries', icon: <ListBulletIcon />
	}]
	if(baseSchema?.groups?.includes('log') && initial?.schema) tabOptions = [...tabOptions, {
		label: <span>Log</span>, value: 'entries', icon: <ListBulletIcon />
	}]

	//form-row mb-3
    const hasField = (name, attr = 'base') => baseSchema[attr]?.filter(f => f?.name === name || f?.slug === name)?.length >= 1
    const getField = (name, attr = 'base') => baseSchema[attr]?.find(f => f?.name === name || f?.slug === name)
	return (
		 <FormProvider {...methods} >
			{/* {JSON.stringify(initial?.entries)} */}
		
		{/* {formType} */}
			<ControlTabs active={mode} setActive={setMode}  lines={2} mx={3} mb={4} options={tabOptions} />
			<form disabled={disabled || formType === 'preview'} onSubmit={methods.handleSubmit(onSubmit)}>
                {mode === 'transform' ? (
					<EditorTransform schema={schema} formData={formData} callback={callback} handler={dataHookMethods?.[formType]} />
				) : mode === 'entry' ? (
					<EditorBaseFields 
						schema={schema} formData={formData} callback={callback} handler={dataHookMethods?.[formType]}
					/>
				) : ['variables'].includes(mode) ? (
					<EditorVariables collectionId={formData?.collectionId} />
				) : mode === 'templates' ? (
					<EditorTemplates 
						collectionId={formData?.collectionId} 
						schema={formData?.schema}
						templates={formData?.templates}
					/> 
				) : (
				<Wrap>
                    <Col  width={[1, 1, 1/2]} px={2}>
						{/* {JSON.stringify(formData?.schema?.find(a => a?.formType === 'markedimage'))} */}
                        {mode === 'entries' ? (
							<>

							<DataGrid 
								formData={methods.watch()}

							/>
							<ReactJson collapsed
								name='data' src={{
									baseSchema,
									formData
								}}
							/>
							</>
						) : mode === 'base' ? (
							<InputGroup>
								<EditorBase 
									type={type} 
									types={types}
									hasField={hasField} 
									getField={getField}
									singular={singular}
									fields={baseSchema?.base}
								/> 
							</InputGroup>
						) :  (
							<Entry 
								cols={1}
								initial={formData?.entries?.[0]}
								collection={{schema: formData?.schema}} 
								formType="preview"
								preview
							 /> 
						)}
                        
                        {/* {JSON.stringify(schema.schema)} */}
							
                        
                        
                         {/* <Entry formData={{schema: schema.schema}} initial={null} formType="preview" /> */}

						{/* {error} */}
						{/* {errorUpdate} */}
                    </Col>
        

                    <Col  width={[1, 1, 1/2]} px={2}>
                        {mode === 'styling' ? (
							<div>
								<AdvancedLabel as="h4" label="Coming soon" />
								<AdvancedLabel width="70%">
									We&apos;re currently working on two ways to style your fields. <br/><br/>The first version will be released inside this module, where you will be able to setup general field styling. We will expand this in the near future and integrate it fully inside the builder, where you can drag an drop your input fields. <br/>
									Stay tuned! <br/><br/>
									-The Mosanic Team 
								</AdvancedLabel>
							</div>
						) : baseSchema?.groups?.includes('schema') ? (
							// <div style={{marginTop: '-50px'}}>
							// <FieldHeaderLabels/>
							// <div style={{maxHeight: '67vh', overflow: 'scroll'}}>
                            <FieldsCollection ctx={{entry: formData?.entries?.[0]}}/>
							// </div>
							// </div>
                        ) : null}
                    </Col>
						
					<Button 
						label={(loading || loadingUpdate) ? <LoadingDots /> : 
							mode === 'transform' ? 'Yes, transform' : formType === 'create' ? `Create ${singular}` : `Update ${singular}`
						}
						theme="primary"
						size="large"
						type="submit"
						disabled={Boolean(disabled || !isValid) && !error && formType != 'preview'}
						onClick={triggerSave}
					/>
					
                </Wrap>
				)}
	
					{error} {loading && <LoadingDots />}
				
			</form>
		 </FormProvider>
	);
};
export default EditorProvider;