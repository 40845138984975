import { validateRegister } from "./register";

class MosanicCore {
  components;
  fields;
  fieldsets;
  
  constructor() {
    this.components = {};
    this.fields = {};
    this.fieldsets = {};
  }
  registerComponent (schema) {

    this.components = {
      ...this.components,
      [schema.name]: {...schema}
    }
  }
  registerField(field, schema) {
    validateRegister({schema, type: 'field'});

    this.fields = {
      ...this.fields,
      [schema.name]: {...schema, render: field}
    }
  }
  registerFieldset(fieldsetType, schema) {
    this.fieldsets = {
      ...this.fieldsets,
      [fieldsetType]: {...schema}
    }
  }

  get schemas() {
    return this.components; 
  }
  get fields() {
    return this.fields;
  }
  get fieldsets() {
    return this.fieldsets;
  }
}
 
const Core = (function(){
    var instance;
    return {
        getInstance: function(){
            if (instance == null) {
                instance = new MosanicCore();
                // Hide the constructor so the returned object can't be new'd...
                instance.constructor = null;
            }
            return instance;
        }
   };
})();


// export const mosanic = MosanicCore.getInstance();
export const mosanic = Core.getInstance();
export default mosanic;
