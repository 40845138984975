import { COLOR_SHADES } from '@mosanic/constants/colors';
import { featureSelector } from '@redux/baseSelector';
import { hasAttr, isObject } from '@util/Obj';
import { toLowCase, upperCase } from '@util/Text';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { DELETE_DESIGN_COLOR, SET_DESIGN_COLOR, UPDATE_DESIGN_COLOR } from "./action";

import { COLOR_ACTION_IDS as actions } from './action';

const initialState = COLOR_SHADES;
const mailColorShadeType = '40'

const colorsReducer = (colors = initialState, action) => produce(colors, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...colors, ...action.payload};

        case actions.SET_COLORS:
            return action.payload;

        case actions.RESET_TO_BASE:
            return initialState;

        case actions.PALETTE_SHADE:
            const payloadHue = action.payload.hue;
            const payloadShade = action.payload.shade;
            draft = draft.map(hue => {
                return (hue.type === payloadHue) ? {
                    ...hue,
                    colors: hue.colors.map(shade => {
                        if(shade.type != payloadShade.type) return shade;
                        const hasPrev = hasAttr(shade, 'inPalette');
                        return {
                            ...shade,
                            inPalette: hasPrev ? !shade.inPalette : true
                        }
                    })
                } : hue;
            })

            return draft;

        case actions.UPDATE_HUE:
            return draft.map(hue => (
                hue.type === action.payload.type ?
                    action.payload :
                    hue
            ));

        case actions.CUSTOM_SHADE:
            const {hue, type, color} = action.payload;
            return draft.map(h => {
                if(h.type != hue) return h;
                return {
                    ...h,
                    colors: h.colors.map(c => {
                        if(c?.type != type) return c;
                        return {
                            ...c,
                            value: color.hex,
                            rgba: color?.rgb?.a != 1 ? color.rgb : undefined,
                        }
                    })
                }
            });

        default:
            return colors;
    };  


});
export default colorsReducer;

const presentSelector = state => state.present;
export const colorsSelector = state => presentSelector(state)?.colors
// export const metricColorsSelector = state => presentSelector(state).colors?.filter(t => t.type === 'metric')

export const hueSelector = (state, hue) => colorsSelector(state).find(h => h.type === hue);
export const colorSelector = (state, hue, shade) => {
    const group = hueSelector(state, hue);
    if(!group) return '#fff';
    return group.colors.find(s => s.type === shade)
}

const isEqual = (valA, valB) => toLowCase(valA) === toLowCase(valB)

export const colorByHexSelector = (colors, hex) => {
    let color = null;
    colors.map(h => {
        if(h.colors.find(s => isEqual(s?.value, hex))) {
            color = {
                shade: h.colors.find(s => isEqual(s?.value, hex))?.type,
                hue: h.type,
                value: hex,
            }
        }
    });
    return color;
}
export const colorByShadeSelector = (colors, hue, shade) => {
    const group = colors.find(h => h.type === hue);
    return group?.colors?.find(c => c.type === shade)
}
export const paletteColorsSelector = state => {
    const colors = colorsSelector(state)
    let pallette = [];
    if(colors) {
        colors.map(hue => {
            hue.colors.map(color => {
                // if(color.inPalette) mailColorShadeType = c.type;
                if(color?.inPalette) 
                    pallette.push(isObject(color?.value) ? {
                            ...color,
                            value :`rgba(${color.value.r}, ${color.value.g}, ${color.value.b}, ${color.value.a})` 
                        }: 
                        color
                    );
            });
        });
    }
    return pallette;
};

export const simplePaletteSelector = state => (
    paletteColorsSelector(state)?.map(c => upperCase(c?.value))
)