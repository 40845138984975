import { HeadingTag } from "@JSX";
import { containsSubstr, startSubString } from "@util/Text";
import dynamic from "next/dynamic";
import { EditableButtonComponent } from "./Element";
import SlateImage from "./ImageElement";

const LinkComponent = dynamic(() => import('./LinkComponent'))
const LinkProd = ({style, attributes, url, children}) => (
  <a
    {...attributes}
    href={url}
    style={style}
  >
    {children}
  </a>
)


const Elements = ({ attributes, children, element, prod = false }) => {
  const style = { textAlign: element?.align }

  if(containsSubstr(element.type, 'heading')){
    const heading = {
      level: startSubString(element.type, 'heading-'), 
      other: {...attributes, style} 
    };
    return (
      <HeadingTag {...heading} >
        {children}
      </HeadingTag>
    )
  }
  switch (element.type) {
    case 'image': 
      return (
        <SlateImage  attributes={attributes} element={element}>
          {children}
        </SlateImage>
      )
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'link':
      const url = containsSubstr(element?.url, '//') ? '/' : element?.url;
      return prod ? (
        <LinkProd 
          style={style} 
          attributes={attributes} 
          url={url}
        >
          {children}
        </LinkProd>
      ) :(
        <LinkComponent
          style={style} 
          attributes={attributes} 
          url={url}
          prod={prod}
        >
          {children}
        </LinkComponent>
      );

    case 'button':
      return (
        <EditableButtonComponent style={style} attributes={attributes}>
          {children}
        </EditableButtonComponent>
      )

    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export {
  Elements,
};
