import { resetColorsAction, setColorsAction } from "@redux/colors/action"
import { setThemeAction } from "@redux/theme/action"
import { resetTypesAction, setTypesAction } from "@redux/typography/action"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTheme } from "./useTheme"

export const useThemeDispatch = () => {
    const dispatch = useDispatch()

    return {
        setTheme: (theme) => {
            dispatch(setThemeAction({...theme, colors:undefined, typography: undefined}))
            dispatch(setColorsAction(theme.colors))
            dispatch(setTypesAction(theme.typography))
        },
        resetTheme: () => {
            dispatch(setThemeAction({}))
            dispatch(resetColorsAction())
            dispatch(resetTypesAction())
        }
    }
}

export const useDispatchActiveTheme = (themeId) => {
    const [handler, [theme, loading, error]] = useTheme({_id: themeId ? themeId : undefined})
    const dispatch = useThemeDispatch();

    useEffect(() => {
        if(theme?.typography && themeId) dispatch.setTheme(theme)
    }, [themeId, theme, dispatch])
   

    return [theme, loading, error]
}
