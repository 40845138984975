
// import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
import { useLocale } from "@mosanic/i18n";
import { isString } from "@util/Text";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Script from "next/script";
import { dark_map, light_map, minimal_map} from "./MapThemes";
import {Loader} from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { isNumber } from "@util/Num";
import { parseImageSrc } from "@mosanic/utils/Image";

const themes = {
    dark: dark_map,
    light: light_map,
    minimal: minimal_map,
    default: null
}
const getTheme = theme => themes[theme]

export function findCenter(markers) {
    if(markers?.length <= 0 || !markers || !Array.isArray(markers)) return {lat: undefined, lng: undefined}

    const lats = markers.map(m => m.lat);
    const lngs = markers.map(m => m.lng);
    return {
        lat: (Math.min(...lats) + Math.max(...lats)) / 2,
        lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
    };
}

const Reload = styled.a` 
   width: 100%;
   height: 100%;
   text-align: center;
   font-size: 12px;
`
const points = [
    { lat: 42.02, lng: -77.01 },
    { lat: 42.03, lng: -77.02 },
    { lat: 41.03, lng: -77.04 },
    { lat: 42.05, lng: -77.02 }
]
const GoogleMap = ({fields, editor, ref, active}) => {
    let apiKey = editor?.source?.apiKey
    if(!apiKey) apiKey = 'AIzaSyAma8GPPHZNC5Gg1bXp7V5GMbfYH1gIGbk';

    // return JSON.stringify(editor)
    return <RenderMap editor={editor} apiKey={apiKey} />
}

const validate = val => isNumber(parseFloat(val))

const RenderMap = ({editor, apiKey, libraries = ["places"]}) => {
    const {source, handler} = editor || {source};
    let {lat, lng, zoom, theme, markers, icon} = source || {lat:  52.377956, lng: 4.897070, zoom: 8, markers: [], theme: 'default'};

    // if(!apiKey) apiKey = 'AIzaSyCdwFEEctdkfVlQMzV5attE6IqM8TrmtuA';
    
    zoom = validate(zoom) ? parseFloat(zoom) : 10;
    lat = validate(lat) ? parseFloat(lat) : 52.377956;
    lng = validate(lng) ? parseFloat(lng) : 4.897070;
    
    const googleMap = useRef();


    const [reload, setReload] = useState(0)


    
    // const icon = parseImageSrc(marker, true)?.thumb
    

      
    useEffect(() => {
        if(!apiKey) return;
        
        const loader = new Loader({
            apiKey,
            language: 'en',
            // version: 'weekly',
            libraries
        });
        let map, marker;



        loader.load().then(() => {



            map = new google.maps.Map(googleMap.current, {
                center: {lat, lng},
                zoom,
                disableDefaultUI: true, // a way to quickly hide all controls
                mapTypeControl: true,
                scaleControl: true,
                zoomControl: true,
                styles: getTheme(theme),
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL 
                },
            });

            const markerIcon = {
                url: parseImageSrc(icon, true)?.thumb,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(30, 30),
                // The origin for this image is (0, 0).
                // origin: new google.maps.Point(0, 0),
                // // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(0, 32),
              };
            // let markers_search = [];
    
            const infoWindow = new google.maps.InfoWindow();

            
            if(markers?.length >=1 ) {
                const points = markers.map(marker => {
                    let content = marker;
                    marker = new google.maps.Marker({
                        position: {lat: marker?.lat, lng: marker?.lng},
                        title: marker?.title,
                        icon: parseImageSrc(icon, true)?.full,
                        map,
                    })

                     marker.addListener("click", () => {
                        console.log({marker, title: marker.getTitle(), content})
                        infoWindow.close();
                        infoWindow.setContent(marker.getTitle());
                        infoWindow.open(marker.getMap(), marker);
                    });

                    return marker;
                })
                const mc = new MarkerClusterer({ 
                    markers: points, 
                    map 
                });
            } else {
                marker = new google.maps.Marker({
                    position: {lat, lng},
                    icon: parseImageSrc(icon, true)?.full,
                    map,
                });
            }
            
        });

        
    },[theme, lat, lng, apiKey, markers])



    return (
        <>

        <div id="map" style={{height: '100%', width: '100%'}} ref={googleMap} >
            {apiKey ? (
                <Reload onClick={() => setReload(reload ++)}>Reload map</Reload>
            ) : (
                <Reload>Provide a valid key.</Reload>
            )}
        </div>
        </>
    )
}
export default GoogleMap;