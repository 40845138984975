// import { gql } from '@apollo/client';
export const BUILDER_ITEM_CORE = ` 
  title
  description
  type
  category
  isUserGenerated
  isBookmarked
  _id
`
export const LAYOUT_ROW = `
  title 
  type
  status
  _id
  children {
      title 
      type
      status
      _id
      children {
        title 
        type
        status
        _id
          
      }
  }
`;
export const COMPONENT_CORE = `
  type
  data
  title
  desc
  icon
  isFocus
`;

export const COMPONENT_EDITOR = `
  editor {
    title
    description
    disableDnd
  }
`;

export const LAYOUT_VISIBILITY = `
  visibility {
    scrollEffect
    offset
    offsetMetric
    display
    displaySm
    displayMd
    displayLg
    displayXl
    anchor
    anchorName
    anchorId
  }
`;
export const LAYOUT_CONTAINER = `
  container {
    state {
      xs
      sm
      md
      lg
      xl
    }
  }
`;

export const CORE = `
  title
  type
  _id
`
export const COLUMN_CORE = `
  ${CORE}
`;
export const ROW_CORE = `
  ${CORE}
`;
export const COLUMN_DATA = `
  data {
    reference
    repeating
    isCloned
    isMain
  }
`;

export const COLUMN_GRID = `
  grid {
    responsive {
      xs
      sm
      md
      lg
      xl
    }
    offset {
      xs
      sm
      md
      lg
      xl
    }
    display {
      xs
      sm
      md
      lg
      xl
    }
  }
`;

export const DESIGN_STATES = `
  design {
    state
    color {
      hex
      color
      shade
      opacity
    }
    background {
      hex
      color
      shade
      opacity
    }
    backgroundSrc {
      filename
      encoding
      mimetype
      url
    }
    border {
      style
      width
      color
      shade
      opacity
    }
    radius {
      top
      bottom
      left
      right
    }
    shadow {
      value
    }
  }
`;
export const DISPLAY_MAIN = `
  display {
    displayType
    position
    flex {
      direction
      align
      justify
      wrap
    }
  }
`;
export const DISPLAY_CHILD_SUB = `
  display {
    displayTypeParent
    position
    flex {
      sizing
      customSizing {
        grow
        shrink
        basis
      }
      align
      order
    }
  }
`;
export const METRIC = `
  {
    value
    metric
    disabled
    reason
    label
  }
`
export const LAYOUT_SIZING = `
  sizing {
    width ${METRIC}
    height ${METRIC}
    minWidth ${METRIC}
    minHeight ${METRIC}
    maxWidth ${METRIC}
    maxHeight ${METRIC}
  }
`
export const LAYOUT_PADDING = `
  padding {
    top ${METRIC}
    bottom ${METRIC}
    left ${METRIC}
    right ${METRIC}
  }
`
export const LAYOUT_MARGIN = `
  margin {
    top ${METRIC}
    bottom ${METRIC}
    left ${METRIC}
    right ${METRIC}
  }
`

export const LAYOUT_DOCKING = `
  docking {
    docked {
      top
      bottom
      left
      right
    }
    top ${METRIC}
    bottom ${METRIC}
    left ${METRIC}
    right ${METRIC}
  }
`
export const COMPONENT_FIELDS = `
  ${COMPONENT_CORE}
  ${COMPONENT_EDITOR}
  ${DESIGN_STATES}

  layout {
    ${LAYOUT_VISIBILITY}
  }
  layouts {
    state
    ${DISPLAY_CHILD_SUB}
    ${LAYOUT_SIZING}
    ${LAYOUT_PADDING}
    ${LAYOUT_DOCKING}
  }
`;
export const COMPONENTS_DATA = `
  components {
    ${COMPONENT_FIELDS}
      _id
  }
`
export const CONTAINER_FIELDS = `
  ${COLUMN_CORE}
  ${DESIGN_STATES}
  ${COLUMN_DATA}
  layout { 
    isLocked
    ${LAYOUT_VISIBILITY}
    ${COLUMN_GRID}
  } 
  layouts {
    state
    ${DISPLAY_MAIN}
    ${LAYOUT_SIZING}
    ${LAYOUT_PADDING}
    ${LAYOUT_MARGIN}
  }
`;

export const COLUMNS_DATA = `
  columns {
    ${CONTAINER_FIELDS}
  }`

export const CONTAINER_DATA = `
  containers {
    ${CONTAINER_FIELDS}
  }`

export const ROWS_DATA = `
  rows {
    ${ROW_CORE}
    ${DESIGN_STATES}
    layout {
      ${LAYOUT_VISIBILITY}
      ${LAYOUT_CONTAINER}
    }
    layouts {
      state
      ${LAYOUT_SIZING}
      ${LAYOUT_PADDING}
      ${LAYOUT_MARGIN}
    }
    repeating {
      collection
    }
  }
  `
// export const BUILDER_LAYOUT_FIELDS = gql`
// fragment VisibilityFields on component {

// fragment Components on page {
//     components {
//       type
//       data
//       title
//       desc
//       icon
//       isFocus
//       _id
//       editor {
//         title
//         description
//         disableDnd
//       }
//       layout {
//         ...VisibilityFields
//       }
//       # Start design array
//       design {
//         state
//         color {
//           color
//           shade
//           opacity
//         }
//         background {
//           color
//           shade
//           opacity
//         }
//         backgroundSrc {
//           filename
//           encoding
//           mimetype
//           url
//         }
//         border {
//           style
//           width
//           color
//           shade
//           opacity
//         }
//         radius {
//           top
//           bottom
//           left
//           right
//         }
//         shadow {
//           value
//         }
//       }
//       # Start layouts array
//       layouts {
//         state
//         display {
//           displayTypeParent
//           position
//           flex {
//             sizing
//             customSizing {
//               grow
//               shrink
//               basis
//             }
//             align
//             order
//           }
//         }
//         sizing {
//           width {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           height {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//         }
//         docking {
//           docked {
//             top
//             bottom
//             left
//             right
//           }
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//         padding {
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//       }
//     }
//     # -----------------------
//     # Start columns
//     columns {
//       title
//       type
//       _id
//       # - start columns layout
//       layout { 
//         visibility {
//           scrollEffect
//           offset
//           offsetMetric
//           display
//           displaySm
//           displayMd
//           displayLg
//           displayXl
//           anchor
//           anchorName
//           anchorId
//         }
//         grid {
//           responsive {
//             xs
//             sm
//             md
//             lg
//             xl
//           }
//           offset {
//             xs
//             sm
//             md
//             lg
//             xl
//           }
//           display {
//             xs
//             sm
//             md
//             lg
//             xl
//           }
//         }
//       } 
//       # Start col layouts array
//       layouts {
//         state
//         display {
//           displayType
//           position
//           flex {
//             direction
//             align
//             justify
//             wrap
//           }
//         }
//         sizing {
//           width {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           height {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//         }
//         margin {
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//         padding {
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//       }
//       # - start columns design
//       design {
//         type
//         state
//         color {
//           color
//           shade
//           opacity
//         }
//         background {
//           color
//           shade
//           opacity
//         }
//         border {
//           color
//           shade
//           opacity
//         }
//         backgroundSrc {
//           filename
//           url
//           encoding
//           mimetype
//         }
//         radius {
//           top
//           right
//           bottom
//           left
//         }
//         shadow {
//           value
//         }
//       }
//       isLocked
// 		  data {
// 			  reference
//         repeating
//         isCloned
//         isMain
//       }
//     }
//     # -----------------------
//     # Start rows array
//     rows {
//       title
//       type
//       container
//       _id
//       # - start rows layout
//       layout {
//         visibility {
//           scrollEffect
//           offset
//           offsetMetric
//           display
//           displaySm
//           displayMd
//           displayLg
//           displayXl
//           anchor
//           anchorName
//           anchorId
//         }
//         container {
//           state {
//             xs
//             sm
//             md
//             lg
//             xl
//           }
//         }
//       }
//       # - start rows layouts array
//       layouts {
//         state
//         sizing {
//           width {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           height {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//           minHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxWidth {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           maxHeight {
//             value
//             metric
//             disabled
//             reason
//             label
//           }
//         }
//         margin {
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//         padding {
//           top {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           bottom {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           left {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//           right {
//             value
//             metric
//             disabled
//             reason
//             label
//           } 
//         }
//       }
//       # - start rows design
//       design {
//         type
//         state
//         color {
//           color
//           shade
//           opacity
//         }
//         background {
//           color
//           shade
//           opacity
//         }
//         border {
//           color
//           shade
//           opacity
//         }
//         backgroundSrc {
//           filename
//           url
//           encoding
//           mimetype
//         }
//         radius {
//           top
//           right
//           bottom
//           left
//         }
//         shadow {
//           value
//         }
//       }
//       repeating {
//         collection
//       }
//     }

//   }
// }
// `;
