
import { highlight, languages } from 'prismjs/components/prism-core';
import { useState } from "react";
import Editor from 'react-simple-code-editor';
import styled from 'styled-components';
import { default as s } from "../Input/Input.styles";
import Prism from 'prismjs/prism';
import 'prismjs/components/prism-json'; // need this
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

import 'prismjs/themes/prism-coy.min.css'; 

import { isEmptyString, isString } from '@util/Text';
export const CodeEditor = styled(Editor)` 
    ${s.BaseField}
    border: none;
    * {
        border: none;
        outline: none;
    }
`;


export const highlighter = (code, lang = 'jsx') =>
     Prism.highlight(code, Prism.languages[lang], lang);

export const Code = ({
     //Essential
    name = '',
    type = 'text',
    value = null,
    onChange = null,

    //Overwrite
    label = null,
    language = 'html',

    //Styling
    fullWidth = false,
    small = null,
    size = null,
    ninja = false, 
    wrap = false, 
    
    //Specific
    endAdornment = null,
    endSource = null,

    //Validation
    valid = null,
    empty = false,
    disabled = false,
    required = false,

    innerRef = null,

    ...other
}) => {
    const [focussed, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);

    return (
        <s.Container 
            className="input_field"
            focussed={focussed}
            disabled={disabled}
            valid={valid} 
            empty={empty}
            ninja={ninja} 
            size={size}
        >
            <s.Label shrink={Boolean(innerRef?.current || value || focussed)}>
                {
                    ninja ? "" :
                    label ? label :
                    name ? name : ""
                }
                {required && <s.Required />}
            </s.Label>

            {(isString(value) || !value) && ( 
            <CodeEditor
                ref={innerRef}
                disabled={disabled}
                value={value || other?.defaultValue || ''}
                {...{
                    ...other,
                    onChange: undefined
                }}
                onValueChange={code => onChange(code)}
                highlight={code => highlighter(code, isEmptyString(language) ? "html" : language)}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                }}
                
                onFocus={(e) => {
                    if(other?.onFocus) other.onFocus(e)
                    onFocus();
                }} 
                onBlur={(e) => {
                    if(other?.onBlur) other.onBlur(e)
                    onBlur();
                }}
            />
            )}
            <s.End>
                {endAdornment}
                {endSource}
            </s.End>
        </s.Container>
    )
}
export default Code;