import { Items } from "@mosanic/constants/";
import { insert, remove, reorder } from "@util/Arr";



export const addChildToChildren = ({children, dropPath, item}) => {
  if (dropPath?.length === 1) {
      const dropZoneIndex = Number(dropPath[0]);
      return insert(children, dropZoneIndex, item);
    }

    const updatedChildren = [...children];
    const curIndex = Number(dropPath.slice(0, 1));
  
    // Update the specific node's children
    const splitItemChildrenPath = dropPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];

    updatedChildren[curIndex] = {
      ...nodeChildren,
      children: addChildToChildren({
        children: nodeChildren?.children,
        dropPath: splitItemChildrenPath,
        item
      })
    };
  
    return updatedChildren;
};


export const handleNewItem = ({map, dropPath, item}) => addChildToChildren({children: map, dropPath, item});