import { useMosanicInlineEditorFields } from "@mosanic/hooks/useFieldSource";
import { Children, cloneElement, isValidElement, useState } from "react";


export const hasExpand = (schema) => schema?.expand;
export const expandType = (schema) => schema?.expand?.type ? schema.expand.type : schema?.expand;

export const isSliceExpand = (schema) => expandType(schema) === 'slice'
export const isTabExpand = (schema) => expandType(schema) === 'tab';

const getRef = (item) => ({
    _id: item?._id, 
    type: item?.type,
    group: item?.group
});

const useContextRef = (item, active, prod, clone) => ({
    fields: item?.fields, 
    editor: useMosanicInlineEditorFields({ref: getRef(item)}),
    ref: getRef(item),
    active,
    clone
})
export const useSchemaFieldsRef = ({item, active, production = false, clone = null, breakpoint = 'xl'}) => ({
    ...useContextRef(item, active, production, clone),
    breakpoint
})

export const useProductionFieldsRef = ({item, active, clone = null, breakpoint = 'xl'}) => {
    const [source, setSource] = useState(item?.fields);
    const handler = ({value, extend}) => {
        setSource({
            ...source,
            [extend]: value
        })
    }
    return {
        fields: item?.fields, 
        editor: {source, handler },
        ref: getRef(item),
        active,
        clone,
        breakpoint,
        production: true
    }
}

export const passControlState = ({children, controlProps}) => {
    if(!children) return children;
    const childrenWithControlProps = Children.map(children, child => {
        if (isValidElement(child)) return cloneElement(child, {controlProps});
        return child;
    });

    return childrenWithControlProps
}
export const passCloneState = ({children, cloneProps}) => {
    if(!children) return children;
    const childrenWithCloneProps = Children.map(children, child => {
        if (isValidElement(child)) cloneElement(child, {cloneProps});
        return child;
    });

    return childrenWithCloneProps
}