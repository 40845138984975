
import { feature } from '@redux/util';
import { get, set } from '@util/Obj';
import { produce } from 'immer';

import { HYDRATE } from 'next-redux-wrapper';
import {default as actions} from './action';

const initialState = {

};
 
const dataReducer = (data = initialState, action) => produce(data, draft => {
    const component = action.payload;

    switch (action.type) {
        case HYDRATE:
            return {...data, ...action.payload};

        case actions.DATA_ADD:
            set(draft, `${component.group}.${component._id}`, component);
            return draft;

        case actions.DATA_REPLACE_STORE:
            return action.payload;

        case actions.DATA_UPDATE:
            set(draft, `${component.group}.${component._id}`, component);
            return draft;

        case actions.DATA_REMOVE:
            set(draft, `${component.group}.${component._id}`, undefined);
            return draft;

        case actions.DATA_SET:
            return action.payload;

        default:
            return data;
    }; //switch
});
export default dataReducer;

export const dataSelector = (state) => feature('data', state);

export const dataMetaSelector = (state) => feature('data', state)?.meta?.pointer;

    