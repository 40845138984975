 

import { UPDATE_COLLECTION } from "@api/mutations/collections";
import { isAuthenticated } from "@api/token";
import { useMutate } from "@hooks/useApi";
import { useNotify } from "@mosanic/hooks";
import { siteIdSelector } from "@redux/auth/reducer";
import { useSelector } from "react-redux";


const success = dataType => {
    if(dataType === 'global') return 'Successfully updated data.'
    if(dataType === 'form') return 'Successfully submitted form.'
    return 'Successfully updated collection.'
}
const err = dataType => {
    if(dataType === 'global') return 'An error occurred during the data update.'
    if(dataType === 'form') return 'Could not submit the form.'
    return 'An error occurred during the collection update.'
}
export const useUpdateCollection = ({callback = null, dataType = 'collection'}) => {

    const site = useSelector(siteIdSelector)
    const isAuth = isAuthenticated()
    const [update, [data, loading, error]] = useMutate('collectionUpdateById', UPDATE_COLLECTION)
    
    const notify = useNotify();

    const handler = ({collection}) => {
        const {_id, siteId} = collection;

        if(!_id) {notify('No collection _id received.');return;}
        if(!isAuth) {notify('You are not authenticated.');return;}

        if(!site) {notify('No active site selected.');return;}
        if(siteId != site && dataType === 'collection') {notify('Collection is linked to a different site.');return;}
        
		update({
            _id,
			record: {
				...collection,
			    updatedAt: new Date(),
                _id: undefined,
			}
		}).then((res) => {
			let {data, error} = res;
            if(data?.collectionUpdateById) data = data.collectionUpdateById
            if(data) {
                notify(success(dataType), 'app', 'success');
                if(callback) callback();

            } else notify(err(dataType))
			console.log(res)
		})
    }
    
    return [handler, [data, loading, error]];
}

export const useDeleteEntry = ({callback = null}) => {
    const [handler, [data, loading, error]] = useUpdateCollection({callback})

    const deleteEntry = ({collection, entry}) => {
        collection = {
            ...collection,
            entries: collection?.entries?.filter(e => e?._id != entry?._id)
        }
        handler({collection})
    }

    return [deleteEntry, [data, loading, error]];
}