import { getImageUrl } from '@api/host';
import { Condition } from '@JSX';
import { MediaUploader } from '@mosanic/components/Media';
import { parseImageSrc } from '@mosanic/utils/Image';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import('react-player'));
import styled, {css} from 'styled-components';
const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  &::after {
    ${props => props.overlay && css`
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left:0;
      right:0;
      background: rgba(0,0,0,0.5);
      `}
  }

  video {
    ${props => props.blur && css` 
      filter: blur(5px);
    `}
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const bool = (value) => value === 1;

const VideoContainer = ({fields, editor, ref, active}) => {
  const {source, handler} = editor || {source: null, handler: null};
  const url = parseImageSrc(source?.videoUrl, true)

  const {controls, alt, autoplay, loop, muted, cover, blur, overlay} = source || {
    controls: true, 
    alt: 'video',
    autoplay: true, 
    loop: true, 
    muted: true, 
    cover: false,
    blur: false,
    overlay: false,
  };

  return active || !source?.videoUrl ?(
    <MediaUploader 
      imageUrl={source?.videoUrl}
      type='video'
      showEditor={active}
      callback={handler}
      autoUpload
      cover
    />
  ) : (
  <Condition when={bool(cover)} 
    wrapper={children => 
      <Cover blur={bool(blur)} overlay={bool(overlay)}>
        {children} 
      </Cover>
    }>

    <ReactPlayer 
      url={url?.full ? url?.full : null}
      controls={bool(controls)}
      autoPlay={bool(autoplay)}
      loop={bool(loop)}
      volume={bool(muted) ? 0 : 1}
      muted={bool(muted)}
      playsinline
      wrapper="span"
      alt={alt}
      playing={bool(autoplay)}
      width="100%"
      height="100%"
    />

  </Condition>
  )
};

export default VideoContainer;

           
             