




// const useCollection = ({form}) => {
//     let [handler, [collection, loading, error]]  = useLazyQuery(
//         'collectionById', 
//         COLLECTION
//     );
//     useEffect(() => {
//         if(form?.collection && !error) handler({_id: form?.collection})
        
//     },[form?.collection])

//     return [collection, loading]
// }

const Input = ({fields, ref, editor, active = false, clone}, children) => {
    // const {source} = editor;
    // const {form, advanced} = source || {form: null, advanced: false};
    
    // let [collection] = useCollection({form})
    
   return (
    <>
    
    {JSON.stringify({editor})}
    input
    
    </>
   )
} 

export default Input;