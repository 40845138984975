
import { ControlDialog } from "@mosanic/items";
import { useState } from "react";

import { Entry, useDeleteEntry } from "@mosanic/core/Collections";
import { DeleteConfirm } from "@mosanic/core/Notifications";
import { getEntryLabel } from "../../fields/Source/helpers";
import { Button, ButtonGroup, Buttons } from "@mosanic/fields";
import { Condition } from "@JSX";
import { ArrowDownIcon, ArrowUpIcon } from "@radix-ui/react-icons";

export const useDataGridHandles = ({refreshData}) => {
    const [handleDelete] = useDeleteEntry({refreshData});

    const [state, setState] = useState({
        entry: null,
        deleteEntry: null,
        modal: false,
        sidebar: false,
        filters: {
            enabled: false
        },
        order: 'desc'
    });

    const setFilters = filters => setState({...state, filters: {...state.filters, ...filters}})

    const gridHandles = {
        open: (type = 'modal') => setState({...state, [type]: true, entry: null}),
        edit: (entry, type = 'modal') => setState({...state, entry, [type]: true}),
        delete: (deleteEntry) => setState({...state, deleteEntry}),
        setFilters
    }
 
    return {
        gridHandles,
        handles: {
            setState,
            refreshData,
            handleDelete,
            setFilters,
            toggleFilters: () => setFilters({enabled: !state?.filters?.enabled})
        },
        state
    }
}

export const GridOrderButton = ({state, setState}) => (
    <Button 
        label=" Order" 
        icon={state?.order === 'desc' ? <ArrowDownIcon/> : <ArrowUpIcon/>} 
        onClick={() => setState({...state, order: state?.order === 'desc' ? 'asc' : 'desc'})} 
    />
)

const getModalStates = ({collection, state, handles}) => {
    const {modal, sidebar, item, deleteEntry} = state;
    const {setState, handleDelete, refreshData} = handles;

    const editCreateState = {
        modal,
        sidebar,
        close: () => setState({...state, sidebar: false, modal: false, item: null}),
        switch: () => setState({...state, sidebar: !state?.sidebar, modal: !state?.modal}),
        title: `${!item ? `Create ${collection?.singular}` : `Update ${collection?.singular}: ${getEntryLabel(item, collection?.schema)}`}`
    };

    const deleteModalState = {
        header: `Delete ${collection?.title} entry`,
        message: `This action is irreversible.`,
        state: deleteEntry,
        validateLabel: '',
        validateValue: getEntryLabel(deleteEntry, collection?.schema),
        action: () => {
            handleDelete(({collection, entry: deleteEntry}));
            setState({...state, deleteEntry: null});
            setTimeout(() => {
                refreshData();
            }, 1000)
        },
        close: () => setState({...state, deleteEntry: null})
    }
    return [editCreateState, deleteModalState];
}
 
export const DataGridModals = ({collection, state, handles}) => {
    const [editCreateState, deleteModalState] = getModalStates({collection, state, handles});
    return (
    <>
    
    <ControlDialog {...editCreateState} state={editCreateState?.modal}>

        <Entry 
            callback={handles.refreshData}
            collection={collection} 
            initial={state?.item} 
            type={!state?.item ? 'create' : 'update'} 
        />
    </ControlDialog>
        <DeleteConfirm {...deleteModalState} />
    </>
    )
}