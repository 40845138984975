import { getImageUrl } from "@api/host";
import { RowWrap } from "@mosanic/styles/flex.styles";
import { combineString, oneLineString } from "@util/Text";
import styled, { css } from "styled-components";

import { ItemColumn } from "./Column.styles";
import { ItemColumns } from "./Columns.styles";

import parseAttr, { parseAttrGroup, parseBorderAttrGroup, parseGridAttr, parseLetterAttrGroup, parseTransformAttrGroup } from "./parseAttr";

import parseDisplayAttr from "./parseDisplayAttr";

import { getAttrSelector, getParsedValue } from "./styleUtils";


// const getTextAlign = (variant, value) => css`text-align: ${value}; * { text-align: ${value}; }`
const getTextColor = (variant, value, validate) => {
    let styles = ``
    if(value?.color) {
        const color = getParsedValue({
            value: value.color, 
            type: 'color'
        }, validate);

        styles += `color: ${color}; svg path { fill: ${color}; }` 
    }
     
    /* color: ${value.gradient?.colors[0]?.color}; */
    if(value?.gradient) styles += `
        background: ${value?.gradient?.value};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    ; * {
        background: ${value?.gradient?.value};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
    }`; 
    return oneLineString(styles);
}


const getVisibility = (variant, validate) => validate.breakpoint === variant ? css` display: none; ` : ``;
const getObjectFit =  (variant, value) =>   `object-fit: ${value}!important;}`

const getBackgroundImg = (image) => {
    if(image?.urlMin && image?.url){
        return `image-set(
            url("${getImageUrl(image?.urlMin)}") 1x,
            url("${getImageUrl(image?.url)}") 2x
        )`;
    }
    return `url(${getImageUrl(image)})`;
}
const getBackgroundSize = bg => {
    if(bg?.size === 'percentage') return bg?.sizeValue ? bg.sizeValue : '1250%';
    return bg?.size ? bg.size : 'cover';
}
const getTransition = (transition) => {
    let property = transition?.target ? transition.target : 'all';
    let timing = transition?.curve === 'custom' ? 
        `cubic-bezier(${combineString(transition?.bezier, ',')})` :
        transition?.curve ? transition.curve :
        'ease-in-out';

    let duration = transition?.duration ? transition.duration : '0.3s';

    let base = `transition: ${duration} ${property};`;
    base += `transition-timing: ${timing};`
    if(transition?.delay){
        base += `transition-delay: ${transition.delay};`;
    }
    return base
}

const ItemStyle = (type, production = false) => {
    switch(type){
        case 'columns': return ItemColumns;
        case 'column': return css` 
            ${ItemColumn}
        `;
        case 'image': return css`position:relative;`;
    }
}




const ParseStyle = (data, validate, production = false) => {

return css`
    position: relative;
    ${!production && `cursor: pointer;`}
    ${(data?.sizing) && parseAttrGroup({
        data: data.sizing, 
        validate
    })}
    ${(data?.padding) && parseAttrGroup({
        data: data.padding, 
        before: 'padding',
        validate
    })}
    ${(data?.margin) && parseAttrGroup({
        data: data.margin, 
        before: 'margin',
        validate
    })}
    ${(data?.docking) && parseAttrGroup({
        data: data.docking, 
        log: true,
        validate
    })}
    ${(data?.corner) && parseAttrGroup({
        data: data.corner, 
        before: 'border', 
        extend: 'radius',
        validate
    })}


    ${(data?.cursor) && parseAttr({
        data: data.cursor, 
        attrName: getAttrSelector('cursor'), 
        validate
    })}
    ${(data?.zIndex) && parseAttr({
        data: data.zIndex, 
        attrName: getAttrSelector('z-index'), 
        validate
    })}
    ${(data?.position) && parseAttr({
        data: data.position, 
        attrName: getAttrSelector('position'), 
        type: 'position',
        validate
    })}
 
    ${(data?.gradient) && parseAttr({
        data: data.gradient, 
        attrName: 'background', 
        validate
    })}
    

    ${(data?.display) && parseDisplayAttr(data.display, validate)}
    ${(data?.grid) && parseGridAttr(data.grid, validate)}

    
    ${(data?.background) && parseAttr({
        data: data.background, 
        attrName: 'background', 
        type: 'color',
        validate
    })}

    ${(data?.bg?.position) && parseAttr({
        data: data.bg.position, 
        attrName: 'background-position', 
        validate
    })}
    ${data?.image && css` 
        background-image: ${getBackgroundImg(data?.image)};
    `}
    ${(data?.bg?.size || data?.bg?.sizeValue) && css` 
        background-size: ${getBackgroundSize(data?.bg)};
    `}
    ${data?.transition && css` 
        ${getTransition(data.transition)}
    `}
    ${data?.bg?.repeat && css` 
        background-repeat: ${data?.bg?.repeat};
    `}

    ${data?.textColor && parseAttr({
        data: data.textColor, 
        attrName: 'color', 
        type: 'color', 
        validate, 
        custom: (variant, value) => getTextColor(
            variant, 
            value, 
            validate
        )
    })}

    ${(data?.hidden) && parseAttr({
        data: data.hidden, 
        attrName: getAttrSelector('display'), 
        custom: (variant) => getVisibility(variant, validate),
        validate
    })}
    ${(data?.objectFit) && css` 
        ${parseAttr({
            data: data.objectFit, 
            attrName: getAttrSelector('object-fit'), 
            // custom: (variant, value) => getObjectFit(variant, value),
            validate
        })} 
        * {
            ${parseAttr({
                data: data.objectFit, 
                attrName: getAttrSelector('object-fit'), 
                custom: (variant, value) => getObjectFit(variant, value),
                validate
            })} 
        }
    `}
    ${data?.hidden?.all && css` display: none; `}


    ${(data?.font) && parseLetterAttrGroup({
        data: data.font, 
        validate
    })}

    ${(data?.border) && parseBorderAttrGroup({
        data: data.border, 
        validate
    })} 
    
     ${(data?.transform) && parseTransformAttrGroup({
        data: data.transform, 
        type: 'transform',
        validate,
    })}
    
`;
}

 
export const Component = styled.div`
 
    ${props => ItemStyle(props.type, props?.production)}
    
    ${props => ParseStyle(props.data, props.validate, props?.production)}

    ${props => props?.data?.css && css` 
        ${props.data.css}
    `}
`;


/**
 * ! Wrappers
 */




const ColWrapper = styled.div` 
    ${props => props.offset && css` 
        max-width: ${`calc(100% - ${props.offset * 3}px)`};
    `}
    margin: 0 auto;
    overflow: hidden;
`
 
const Columns = styled.div` 
  ${RowWrap}
  pointer-events: auto;
  position: relative;
`

const ItemStyles = {
    ColWrapper,
    Columns
}
export default ItemStyles;