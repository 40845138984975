import { useMutate } from '@hooks/useApi';
import { Button, Buttons } from '@mosanic/fields';
import { useNotify } from '@mosanic/hooks';
import { AdvancedLabel, ControlDialog, Hr, LoadingDots } from '@mosanic/items';
import { siteIdSelector, userIdSelector } from '@redux/auth/reducer';
import { mapSelector } from '@redux/map';
import { dataSelector } from '@redux/map/data';

import { FormGroup, FormItems } from '@mosanic/fields/Form';

import { splitPath } from '@mosanic/utils/map/paths';
import { convertPreSaveLayout, getPreSaveLayoutMap } from '@mosanic/utils/map/transform';

import { isNumber } from '@util/Num';
import { capitalizeFirst, replacePart, revertPascalCase } from '@util/Text';

import { useGetUser } from '@mosanic/cms/Query/byId';
import { ELEMENTS_SIDEBAR } from '@mosanic/constants/sidebar.elements';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ADD_BUILDER_LAYOUT, UPDATE_BUILDER_LAYOUT } from '../../../adapters/mutations/layoutItems';


const DataRow = styled.div` 
    display: justify;
    justify-content: space-between;

    div {
        display: inline-block;
        width: 25%;
    }
`

 
const SaveLayout = ({state, close, path, component, data = null}) => {
    const siteId = useSelector(siteIdSelector);
    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const { handleSubmit, control, watch, formState: { errors, isValid } } = useForm({mode: 'all', defaultValues: data});
	const inputProps = {control, fullWidth: true, errors}

    const storedData = useSelector(dataSelector)
    const map = useSelector(mapSelector)

	const [ addBuilderLayout,  [dataAdd, loadingAdd, errorAdd] ] = useMutate('builderLayoutCreateOne', ADD_BUILDER_LAYOUT);
    const [ updateBuilderLayout,  [dataUpdate, loadingUpdate, errorUpdate] ] = useMutate('builderLayoutUpdateById', UPDATE_BUILDER_LAYOUT);

 
    const splittedPath = splitPath(path)
    const itemMap = getPreSaveLayoutMap(map, path);


    const notify = useNotify()

	const onSubmit = (formData) => {
        if(formData?.category?.find(cat => isNumber(cat.value))) {
            notify('Please select a valid layout category.')
            return;
        }
       
        const [
            newLayoutMap,
            layoutData,
            parent,
        ] = convertPreSaveLayout(storedData, itemMap)

        let record = {
            ...formData,
            layoutType: 'section',
            isUserGenerated: (user.userData.isAdmin && !formData?.isUserGenerated) ? false : true,
            siteId,
			updatedAt: Date.now()
        }
        //On create
        if(!data?._id) {
            record = {
                ...record,
                date: Date.now(),
                component: parent,
                map: newLayoutMap,
                data: layoutData, 
            }
            dispatchAdd(record)
        } else {
            dispatchUpdate({
                record: {
                    ...record, 
                    _id: undefined
                },
                recordId: data?._id
            })
        }

	};
    const dispatchAdd = record => {
        addBuilderLayout({record}).then((res) => {
            const {data, error} = res;
            if(!error){
                notify('Layout successfully saved.', 'builder', 'success')
                setTimeout(() => close(), 1000);
            } else if(error) {
                notify('Layout could not be saved, please try again.')
            }
        })
    }
    const dispatchUpdate = ({recordId, record}) => {
        updateBuilderLayout({_id: recordId, record}).then((res) => {
            const {data, error} = res;
            if(!error){
                notify('Layout successfully updated.', 'builder', 'success')
                setTimeout(() => close(), 1000);
            } else if(error) {
                notify('Layout could not be updated, please try again.')
            }
        })
    }
    
    //Get a list of categories
    const list = ELEMENTS_SIDEBAR.map(item => ({
        value: item?.value,
        label: item?.title
    })).map((item, index) => item?.value === 'divider' ? ({
        label: (
            <Hr />
        ), value: index
    }) : item );

    const getSwitch = name => ({name, label: replacePart(revertPascalCase(name), 'is', ''), type: 'switch'})
    
	return (

        <ControlDialog  state={state} size="xs" close={() => close()} title={(
            <AdvancedLabel as="h5" helpText="Save this layout in one of the layout categories to reuse across your pages. ">
                Save {capitalizeFirst(itemMap?.type)} Layout
            </AdvancedLabel>
        )}> 
     
     {data?._id}
            <FormGroup onSubmit={handleSubmit(onSubmit)}>
            {/* {!data && (
                <DataRow>
                    <div>
                        <Label label="Layout Path" value={path} />
                    </div>
                    <div>
                        <Label label="Components" value={count + 1} />
                    </div>
                    <div>
                        <Label label="Children" value={map?.[splittedPath?.[0]]?.children?.length} />
                    </div>
                    <div>
                        <Tooltip force wrap title={combineString(Object.keys(local), ' | ')}>
                            <Label label="Groups" value={Object.keys(local)?.length} />
                        </Tooltip>
                    </div>
                </DataRow>
            )} */}
                <FormItems 
                    controls={inputProps}
                    fields={[
                        {name: 'title'},
                        {name: 'category', label: 'Category', type: 'select', options: list, multiple: true},
                        {name: 'description', type: 'textarea'},
                        user?.userData?.isAdmin ? getSwitch('isUserGenerated') : {name: 'type'},
                        getSwitch('isBookmarked'),
                    ]}
                
                />

                <Buttons right>
                    <Button onClick={() => close()} ghost size="small" label="Cancel" />
                    <Button type="submit" size="small" label={
                        data ? 'Update' :  
                        (loadingAdd || loadingUpdate) ? <LoadingDots /> :
                        'Save'
                    } />
                </Buttons>
            </FormGroup>
            {errorAdd} {errorUpdate}
        </ControlDialog>
           

		
	);
};
export default SaveLayout