
// const LayoutCore = dynamic(() => import("../core/Layout/Core"))
// const Layout = dynamic(() => import("../components/Layout"))


const Notifications = dynamic(() => import("@mosanic/core/Notifications"))

// import LayoutMosanic from "@mosanic/core/Layout/Mosanic";
const LayoutMosanic = dynamic(() => import("@mosanic/core/Layout/Mosanic"))
import BuilderRouting from '@mosanic/routing/Builder';
import { authRevalidate } from '@redux/auth/action';
import { routerSelector } from '@redux/auth/reducer';
import { matches } from '@util/Text';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isAuthenticated, recoverToken } from '@api/token';
import ThemeWrapper from '@mosanic/core/Layout/ThemeWrapper';
import dynamic from 'next/dynamic';

const RoutingProvider = ({children}) => {
  const router = useRouter();
  const match = (paths) => matches(router.pathname, paths);
  const trackRouter = useRouter()
  const dispatch = useDispatch()
  const actions = useSelector(routerSelector)
   
  // const token = use
  // useEffect(() => {
    
  //   const handleRouteChange = () => {
  //     scrollTop();
  //     if(!isServer())  {
  //       window.scrollTo({top: 0, behavior: 'smooth'});
  //     } else {
  //       console.log('server call')
  //     }
  //     console.log('scroll to top')
  //   }
  //   router.events.on('routeChangeComplete', handleRouteChange)

  // },[router])


  const redirect = useRef(0)

  const isMosanicCore = match('core|builder|app|docs');
  const isAuthPage = match('login|logout|reset|coming|register');
  const isAuth = isAuthenticated()
  // useEffect(() => {
  //   const handleRouteChange = (url) => pageView(url)
  //   trackRouter.events.on('routeChangeComplete', handleRouteChange)
  //   return () => trackRouter.events.off('routeChangeComplete', handleRouteChange)
  // }, [trackRouter.events])

  // useEffect(() => {
  //   const handleRouteChange = url => {
  //     pageView(url, document.title);
  //   };
  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => router.events.off('routeChangeComplete', handleRouteChange);
  // }, []);

  // if(isMosanicCore && !isServer() && !isLogin) {
  useEffect(() => {
    let timer;
    if(isMosanicCore && !isAuthPage && !isAuth) {
      timer = setTimeout(() => {
        dispatch(authRevalidate('_app'))
      }, 1000);
    } else {
      if(timer) clearTimeout(timer)
    }
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if(actions?.redirect && (isMosanicCore && !isAuthPage)){
        router.push({
          pathname: actions?.redirect ? actions?.redirect : '/login'
        })
    }
  },[actions?.redirect, router, isMosanicCore, isAuthPage])

  if (isAuthPage) {
    // if(match('login|register') && isAuth) {
    //   router.push({
    //     pathname: '/',
    //     query: { type: 'redirect' }
    //   });
    // }

    return (
      <ThemeWrapper>
        {children}
        <Notifications />
      </ThemeWrapper>
    );
  }
  
  if (isMosanicCore) {
    // if(!isServer() && !isAuth && !getToken() && !getSession()) router.push('/login')
    return (
      <ThemeWrapper>
        <BuilderRouting>
          <LayoutMosanic pageContext={{ layout: "core" }} routerPath={router.pathname}>
            {children}

          </LayoutMosanic>
        </BuilderRouting>
      </ThemeWrapper>
    ) 
  }
 
  return (
    <>
    {children}
    <Notifications />
    </>
  )

}
export default RoutingProvider;