import {default as slug} from 'slugify';
//Text utils
export const countWords = (string) => string.split(' ').length;

//Num string Validate
export const hasNumber = (string) =>  string ? /\d/.test(string.toString()) : false;
export const hasOnlyNumbers = (string) => isString(string) ? 
  string ? /^\d+$/.test(string) : true :
  !isNaN(string);



//Validate
export const containsSubstr = (string, subStr) => (isString(string) && !isEmptyString(string)) && (string.indexOf(subStr) !== -1 || string === subStr);
export const lowCaseContainsSubstr = (string, subStr) => containsSubstr(toLowCase(string), toLowCase(subStr));
export const startSubstr = (string, subStr) => string.startsWith(subStr);
export const lowCaseStarts = (string, subStr) => (string && subStr) ? startSubstr(toLowCase(string), toLowCase(subStr)) : false;
export const matchSubstrList = (string, subStrList) => {
  if(
    (isString(string) && !isEmptyString(string)) && 
    (Array.isArray(subStrList) && subStrList.length >= 1)
  ) {
    let found = false;
    subStrList.map(subStr => {
      if(containsSubstr(string, subStr)) found = true;
    })
    return found;
  };
  return false;
}
export const matches = (string, separatedString, splitChar = '|') => matchSubstrList(string, separatedString.split(splitChar));

export const isEmptyString = (string) => !string || string === '' || string === null
export const isString = (string) => typeof string === 'string';
export const isStartSubString = (string, part) => string ? startSubString(string, part) === part : false

// export const 
//Format
const bulkRemove = (parts, string) => {
  parts.map(part => string = replaceParts(string, part, ''))
  return string
}
export const slugify = (string) => string ? toLowCase(bulkRemove(['.', ',', "'", '"', '|'], slug(string))) : '';

export const cssSelector = (pascalString) => pascalString.replace(/[A-Z]/g, m => "-" + m.toLowerCase());

export const excerptString = (string, max = 7, end = '...') => string && string?.length > (max + 2) ? `${string.substring(0, max)}${end}` : string
export const capitalizeFirst = (string) => isString(string) ?  string.charAt(0).toUpperCase() + toLowCase(string.slice(1)) : '';
export const revertPascalCase = (string) => combineString(string.split(/(?=[A-Z])/), ' ');
export const revertSnakeCase = (string) => replaceParts(string, '_', ' ')
export const revertDevCases = string => string ? capitalizeFirst(
  revertPascalCase(
    revertSnakeCase(
      string
    )
  )
) : string


export const removeVowels = (string) => string ? string.replace(/[aeiou]/gi, '') : '';
export const toLowCase = (string, add = '') => string ? `${string}${add}`.toLowerCase() : '';
export const parseNumberString = (string) => string ? parseFloat(string)?.toString() : '0';
export const oneLineString = (multiLineString) => multiLineString.replace(/\n/g, ``).replace(/\s+/g,' ').trim();
export const startSubString = (string, part) => isString(string) ? string.substring(part.length) : string;
export const subStringTill = (string, before) => string.substring(0, string.indexOf(before));
export const subStringFrom = (string, after) => string.substring(string.indexOf(after));
export const subStringFromLast = (string, after) => string.lastIndexOf(after) ? string.substring(string.lastIndexOf(after)) : string;
export const removePart = (string, part) => string.replace(part, "");
export const replacePart = (string, part, txt) => string ? string.replace(part, txt) : '';
export const replaceParts = (string, part, txt) => isString(string) ? string.replaceAll(part, txt) : '';

export const removeLastChar = (string) => string ? string.slice(0, -1) : '';
export const removeFirstChar = (string) => string ? string.substring(1) : string;
export const upperCase = string => string ? string.toUpperCase() : '';
export const removeSpaces = string => string ? string.replace(/\s/g, '') : '';

export const titleCase = str => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
};

export const combineString = (parts, separator = ' ') => {
  let string = ''
  if(!Array.isArray(parts)) return ``;
  parts.map(part => string = part ? `${string}${part}${separator}` : string);
  return separator ? string.slice(0, -(separator?.length)) : string;
}

export const truncate = (str, num = 25, end = '...') => {
  if (str.length <= num) return str;
  return str.slice(0, num) + end;
};


//Getters
export const getNumberPartOfString = (string) => !isEmptyString(string) ? string.toString().match(/\d+/g) : null;
export const getLetterPartOfString = (string) => string ? string.match(/[a-zA-Z]+/g) : null;
export const getSensitizedString = (string) => string ? string.replace(/[^a-zA-Z0-9 ]/g, '') : null
export const getDotCountInString = (string) => string ? string.match(/\./g).length : 0
 