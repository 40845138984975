import React, { useState } from "react";

const CoreContext = React.createContext();


const CoreProvider = ({ children }) => {

  /**
   * Snackbar Core
   */
  const [snackbar, setSnackbar] = useState({msg: '', active: false, timing: 3000});
  const [snackbarUndo, setSnackbarUndo] = useState();

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmData, setConfirmData] = useState(false);
  const [confirmInput, setConfirmInput] = useState(null);

  const [mediaModal, setMediaModal] = useState(false);
  const [mediaEditor, setMediaEditor] = useState(null);

  /**
   * Main snackbar initialization function
   * @param {*} msg snackbar message
   * @param {*} undoTrigger function reference: () => funcCall
   * @param {*} timing in ms, default: 3000
   * @param {*} active is snackbar visible
   * @returns 
   */
  const setSnackbarData = (msg, undoTrigger = false, timing = 3000, active = true) => {
    if(!msg) return;

    setSnackbar({
      msg: msg, 
      active: active, 
      timing: timing, 
      undo: undoTrigger
    });

    undoTrigger ? setSnackbarUndo(undoTrigger) : setSnackbarUndo(null);
  }
  /**
   * Toggle snackbar at a later stadia
   * Note: snackbar data must have active set to false
   */
  const toggleSnackbar = () => {
    setSnackbar({...snackbar, active: !snackbar.active});
  }
  /**
   * Call from snackbar itself
   */
  const toggleSnackbarUndo = () => {
    snackbarUndo();
  }
  /**
   * Get actual snackbar undo state
   * @returns True if snackbar has undo method
   */
  const hasSnackbarUndo = () => {
    return snackbarUndo != null;
  }



  const [contextPages, setPages] = useState(null);
 
  const [sideBarPanel, setPanel] = useState(''); 
  const [sideBarPanelRight, setSideBarPanelRight] = useState('');

  const [sideBarSubPanel, setSubPanel] = useState('');

  const setSideBarPanel = (panel) => {
    panel?.hasSubPanel ? (panel.subDefault != sideBarSubPanel ? setSubPanel(panel.subDefault) : setSubPanel(false)) : setSubPanel('');

    if(panel?.value != null && panel.value.length >= 3)
      (panel.value == sideBarPanel.value) ? setPanel('') : setPanel(panel)
    else
      setPanel('')
  }

  // const toggleSideBarIcons = () => {
  //   // alert(panel.hasSubPanel)
  //   panel.hasSubPanel ? setSubPanel(panel.subDefault) : setSubPanel('');
  //   (panel.value == sideBarPanel.value) ? setPanel('') : setPanel(panel)
  // }
  // const [contextComponents, setContextComponents] = useState(initialComponents);
 





  // setActiveSnackbar

  return (
    <CoreContext.Provider
      value={{
        contextPages, setPages,
    
        snackbar,
        setSnackbarData,
        toggleSnackbar,
        toggleSnackbarUndo,
        hasSnackbarUndo,

        sideBarPanel, setSideBarPanel, setPanel,
        sideBarPanelRight, setSideBarPanelRight,
        sideBarSubPanel, setSubPanel,

        mediaModal, setMediaModal,
        mediaEditor, setMediaEditor,
        
        confirmModal, setConfirmModal,
        confirmData, setConfirmData,
        confirmInput, setConfirmInput,
      }}
    >
      {children}
    </CoreContext.Provider>
  );
};

export default CoreContext;
export { CoreProvider };
