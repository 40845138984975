
import { featureSelector } from '@redux/baseSelector';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';

import { default as actions } from './action';

const initialState = {
    
};


const themeReducer = (theme = initialState, action) => produce(theme, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...theme, ...action.payload};

        case actions.SET:
            return action.payload;


        default:
            return theme;
    };  


});
export default themeReducer;


export const themeSelector = state => featureSelector(state, 'theme');

// export const metricColorsSelector = state => presentSelector(state).colors?.filter(t => t.type === 'metric')
