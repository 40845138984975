
import { CartProvider, useCartProvider, useDataProvider } from "@mosanic/core/Provider";
import { useCallback, useEffect, useState } from "react";

import { DataProvider as ProviderContext } from "@mosanic/core/Provider";
import { fetchPostJSON } from "@util/api/helpers";
import { Columns } from "@mosanic/items/Page";
import { Col, Wrap } from "@mosanic/styles";
import styled, { css } from "styled-components";
import { Button } from "@mosanic/fields";
import { ControlDialog, ControlPopper } from "@mosanic/items";
import { useCart } from "@mosanic/utils/cart";
import { TrashIcon } from "@radix-ui/react-icons";
import { useGetUser } from "@mosanic/cms/Query/byId";
import { useSelector } from "react-redux";
import { userIdSelector } from "@redux/auth/reducer";
import { useNotify } from "@mosanic/hooks";
import { useRouter } from "next/router";


const combineProductPrices = (products, prices) => products.data.map(product => ({
    ...product,
    prices: prices.data.filter(price =>  price.product === product.id)
}));

const useData = (stripe_key = process.env.STRIPE_SECRET_KEY) => {
    const [data, setData] = useState([null, null])
    
    const getProducts = useCallback(async () => {

        await fetchPostJSON('/api/shop/products', {
            key: stripe_key
        }).then(val => {
            const {products, prices} = val;
            const data = combineProductPrices(products, prices);

            setData([data, prices, products])

        }).catch(err => setData([err]));
    },[stripe_key])

    useEffect(() => {
        if(stripe_key) getProducts()
    },[getProducts, stripe_key])



    return data;


}
const StripeProvider = ({fields, ref, editor, active = false, clone}, children) => {
    const {source} = editor || {source: null};
    const {stripe_key, filters} = source || {entry: null, filters: true, stripe_key: 'sk_live_wPlFpSrxi1qInTthQaSZa4m3'};
    const [cart, setCart] = useState({prices: [], products: []})
    const addProduct = ({product, price}) => setCart({
        prices: [...cart.prices, price],
        products: [...cart?.products, product]
    })
    const removeProduct = ({product, price}) => setCart({
        prices: cart?.prices?.filter(p => p != price),
        products: cart?.products?.filter(p => p != product)
    })

    const providedData = useDataProvider()
    const [products, prices, data] = useData(stripe_key)
            
    // const field = hasAttr(current, entry?.field) ? current[entry.field] : null;

    // if(toLowCase(entry?.field) === 'gallery' ) console.log({content, contentRender})
    // if((Array.isArray(content) && content?.[0]?.url?.url) ) console.log("NOT RENDINGER?")
    // const content = 
    //         field ? field : 
    //         current ? current : 
    //         entries ? entries :
    //         null
 
            // console.log({content, wrapperContent, content, entry: entry?.field} )
            // return JSON.stringify({wrapperContent, contentRender, content})
    return (
        <ProviderContext data={{stripe: {products, prices, info: data}, ...providedData}}>
            <CartProvider data={{cart, addProduct, removeProduct, key: stripe_key ? stripe_key : null}}>
            {/* {filters && (
                <Expander trigger={<h3>Filters  <DropdownMenuIcon /></h3>}>
                    Filter options
                </Expander>
            )} */}
{/*             
            <pre>
                {JSON.stringify({products, source}, null, 2)}
            </pre> */}
            {children}
            {stripe_key === 'sk_live_wPlFpSrxi1qInTthQaSZa4m3' && (
                <RenderProducts products={products} />
            )}
            {/* {lastName} */}
            {/* {JSON.stringify({data, entry})} */}
            </CartProvider>
        </ProviderContext>
    ); 
            // return content ? (
    //     <>
    //     {contentRender}
    //     </>
    // ) : (
    //     <DataContext.Provider value={wrapperContent}>
    //         {children}
    //     </DataContext.Provider>
    // );
}

const getPrice = (product) => {
    if(!product?.prices) return null;
    if(!product?.default_price) {
        return product?.prices?.[0]
    } else {
        return product.prices?.find(p => p.id === product.default_price)
    }
}
const Title = styled.h2` 
    font-size: 33px;
`

const Price = styled.span` 
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    max-width: 120px;
    word-wrap: normal;
    max-height: 20px;
    overflow: hidden;

    span {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 300;
    }
`
const Product = styled(Col)` 
    text-align: center;
`

const getRecurringLabel = recurring => {
    if(recurring?.interval === 'year') return 'yearly'
    if(recurring?.interval_count === 3 && recurring?.interval === 'month') return 'quarterly';
    if(recurring?.interval === 'month') return 'monthly'
    return ''
}
const RenderProducts = ({products}) => {
    const {addProduct, removeProduct, cart, key} = useCartProvider()
    const router = useRouter()
    const notify = useNotify()
    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const handleCheckout = async (event) => {
        // event.preventDefault()

        if(!user?.email) {
            console.log('no login found')
            notify('Please login before checkout.', 'checkout', 'error')
            return null;
        }


        if(!products) {
            console.log(' no products found ')
            return 

        }   


        const items = products?.filter(product => 
            cart.products.includes(product.id) 
        ).map(i => ({
            ...i, 
            price_data: i.prices.find(p => 
                cart.prices.includes(p.id)
            ), 
        }))



        if(!items) {
            console.log('no cart')
            notify('No products in cart.', 'checkout', 'error')
            return null;
        }
        const response = await fetchPostJSON(
            '/api/checkout/cart', {
                items,
                user,
                inventory: products,
                returnTo: router?.asPath,
                key
            }
        ) 
        if (response.statusCode > 399) {
            console.error(response.message)
            return
        } else {
            router.push(response.url)
            // alert('push:'+response.url)
        }
    }


    const PrimaryButton = styled(Button)` 
        transition: all ease-in-out 0.3s;
        width: 150px;
        background: linear-gradient( 90deg,rgba(62,167,247,1) 0%,rgba(9,132,225,1) 100% );
        margin-top: 15px;
        font-size: 14px!important;
        padding: 10px 15px;
        border-radius: 20px;
        border:  none ;
        color:  #fff ;

        &:hover {
            background: linear-gradient( 90deg, rgba(9,132,225,1) 0%,rgba(62,167,247,1) 100% );
        }
    `

    return (
        <Wrap>
            {Array.isArray(products) && [products?.[0]]?.map(product => (
                <ControlDialog size="md"  key={product.id} trigger={(
                <Product  width={1}>
                    {product?.images?.[0] && (
                        <img src={product?.images?.[0]} width="100%" height="auto"/>
                    )}
                    <Title>
                        {product?.name}
                    </Title>
                    {product?.description && (
                        <p>
                            {product?.description}
                        </p>
                    )}
                    {/* {product?.default_price} */}
                    {/* {JSON.stringify(getPrice(product))} */}
                    <Price> 
                        {product?.prices?.filter(price => price.active)?.length} <span>plan options</span>
                    </Price>
                    <Button 
                        label="View options"
                        style={{
                            background: 'linear-gradient( 90deg,rgba(62,167,247,1) 0%,rgba(9,132,225,1) 100% )',
                            fontSize: '14px!important',
                            padding: '8px 10px',
                            marginTop: 5,
                            borderRadius: 18,
                            border: 'none',
                            width: '100%',
                            color: '#fff',
                        }}
                    />
                </Product>
                )} >
                    <Wrap>
                        <Col width={[1, 1/2]} pr={[0, 4]}>
                            {product?.images?.[0] && (
                                <img src={product?.images?.[0]} width="100%" height="auto"/>
                            )}
                            {/* <h2>
                                {product?.name}
                            </h2> */}
                            {product?.description && (
                                <p>
                                    {product?.description}
                                </p>
                            )}
                        </Col>
                        <Col width={[1, 1/2]} pl={[0, 4]}>
                        <h3>
                            {product?.prices?.filter(price => price.active)?.length >= 2 ? 'Price options' : 'Pricing'}
                        </h3>
                        <p>
                            All plans last at least one year, give full access to the platform and give 6 credits.
                        </p>
                        {product?.prices?.filter(price => price.active)?.map(price => (
                            <PriceOption active={cart?.prices?.includes(price.id)}>
                                <Price> 
                                    &euro;{price?.unit_amount / 100}
                                    {price?.recurring && (
                                        <span>
                                        {`/${price?.recurring?.interval_count >= 2 ? price?.recurring?.interval_count : ''} ${price?.recurring?.interval}${price?.recurring?.interval_count >= 2 ? 's' : ''}`}
                                        </span>
                                    )}
                                </Price>
                                {!cart?.products?.includes(product.id) && (
                                <Button key={price.id}
                                    onClick={() => addProduct({product: product.id, price: price.id})}
                                    small
                                    ml='auto'
                                    style={{
                                        width: '150px',
                                        background: price?.id === product?.default_price ? 
                                            'linear-gradient( 90deg,rgba(62,167,247,1) 0%,rgba(9,132,225,1) 100% )' :
                                            'transparent',
                                        marginTop: '-5px',
                                        fontSize: '14px!important',
                                        padding: '8px 10px',
                                        borderRadius: 18,
                                        border:  price?.id === product?.default_price ? 'none' : '1px solid #212121',
                                        color:  price?.id === product?.default_price ? '#fff' : '#212121',
                                    }}
                                >
                                    Start {price?.recurring && getRecurringLabel(price?.recurring)} plan <br/>
                                </Button>
                                )}
                                {cart?.prices?.includes(price.id) && (
                                    <Button icon={<TrashIcon />} ml={0} 
                                        onClick={e => removeProduct({product: product.id, price: price.id})}
                                    />
                                )}
                            </PriceOption>
                        ))}

                        {cart?.products?.includes(product.id) && (
                            <PrimaryButton 
                                // onClick={() => addProduct({product: product.id, price: price.id})}
                                small
                                ml='auto'
                                onClick={e => handleCheckout(e)}
                            >
                                Checkout → 
                            </PrimaryButton>
                        )}
                        </Col>
                    </Wrap>
                    {/* <pre>
                    {JSON.stringify(product, null, 2)}
                    </pre> */}
                </ControlDialog>
            ))}
        </Wrap>
    )
}

const PriceOption = styled.div` 
    transition: all ease-in-out 0.3s;
    border-top: 1px solid #eee; 
    border-bottom: 1px solid transparent; 
    padding: 20px 5px 15px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    ${props => props.active && css` 
        /* background: #eee; */
        padding: 20px 10px 15px;
        border-left: 4px solid;
        border-color: rgba(62,167,247,1);
        box-shadow: 2px 2px 15px 0 rgba(0,0,0,0.2);
    `}
`
export default StripeProvider;