import { canMap } from "@util/Arr";
import { useGet } from "./useDataQuery";

import { FILTER_COLLECTIONS, FILTER_COLLECTIONS_INDEX } from "@api/queries/collections";
import { FILTER_ENTRIES } from "@api/queries/entries";
import { FILTER_MAILS } from "@api/queries/mails";
import { FILTER_MEDIA } from "@api/queries/media";
import { FILTER_USERS } from "@api/queries/users";

import { FILTER_PAGES } from "@api/queries/pages";
import { LIST_ALL_TEAMS } from "@api/queries/teams";

import { combine, getParams, useSiteId } from ".";
import { FILTER_CHATS } from "@api/queries/chats";


//Main call
const useGetMany = ({params, query, type, sort = null, shouldHaveParams = true, ...other}) => {
    const siteId = useSiteId()
    params = combine(params, other, 'limit');
    params = combine(params, other, 'skip');
    if(other?.current) params = {filter: {siteId}}
    if(sort) params = {...params, sort};
    if(!params || Object.keys(params)?.length <= 0) params = null;

    const [get, [data, loading, error]] = useGet({
        method: 'Many',
        params,
        query,
        type,
        silent: true,
        shouldHaveParams
    });

    return [data, loading, error, get]
}

// # Call Hooks

export const useGetEntries = ({filter, _ids, collectionId, sort = '_ID_DESC', ...other}) => useGetMany({
    params: (
        filter ? {filter} : 
        (canMap(_ids) && collectionId) ? {filter: {collectionId, AND: {OR: _ids}}} : 
        collectionId ? {filter: {collectionId}} : null
    ),
    query: FILTER_ENTRIES,
    type: 'entry',
    sort,
    ...other
});

export const useGetMedia = ({filter, siteId, ...other}) => useGetMany({
    params: getParams({filter, siteId}),
    query: FILTER_MEDIA,
    type: 'media',
    ...other
})

export const useGetUsers = ({filter, siteId = undefined, ...other}) => useGetMany({
    params: getParams({filter, siteId}),
    query: FILTER_USERS,
    type: 'user',
    ...other
})


export const useGetMails = ({filter, siteId, ...other}) => useGetMany({
    params: getParams({filter, siteId}),
    query: FILTER_MAILS,
    type: 'mail',
    ...other
})

export const useGetCollections = ({filter, siteId, sort = '_ID_DESC', ...other}) => useGetMany({
    params: getParams({filter, siteId}),
    query: FILTER_COLLECTIONS,
    type: 'collection',
    sort,
    ...other
})

export const useGetCollectionIndexes = ({filter, siteId, ...other}) => useGetMany({
    query: FILTER_COLLECTIONS_INDEX,
    params: getParams({filter, siteId}),
    type: 'collection',
    ...other
})

export const useGetPages = ({filter, siteId, ...other}) => useGetMany({
    params: getParams({filter, siteId}),
    query: FILTER_PAGES,
    type: 'page',
    ...other
})


export const useGetTeams = ({_ids = undefined, userId = undefined, filter = undefined, ...other}) => useGetMany({
    params: (
        filter ? {filter} : 
        (canMap(_ids)) ? {filter: {OR: _ids}} : 
        userId ? {filter: {superAdmin: userId}} : 
        null
    ),
    query: LIST_ALL_TEAMS,
    type: 'team',
    ...other
})

export const useGetChats = ({filter, ...other}) => useGetMany({
    params: {filter},
    query: FILTER_CHATS,
    type: 'chat',
    ...other
})