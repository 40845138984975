import { gql } from '@apollo/client';

export const MEDIA_COUNT = gql` 
query mediaCount($filter:FilterCountMediaInput) {
  mediaCount(filter:$filter) 
}
`
export const MEDIA = gql`
query mediaMany{
	mediaMany {
		title
		alt
		filename
		folder 
		url
		urlMin 
		urlThumb
		siteId
		size
		_id
	}
}
`;
export const PAGINATION_MEDIA = gql`
query mediaPagination($filter: FilterFindManyMediaInput,  $page: Int, $perPage: Int) {
	mediaPagination(filter: $filter, page: $page, perPage: $perPage){
		items{
			title
			alt
			filename
			folder 
			url
			urlMin 
			urlThumb
			siteId
			size
			_id
		}
	}
}`;
export const FILTER_MEDIA = gql`
query mediaMany($filter: FilterFindManyMediaInput,  $limit: Int, $skip: Int) {
	mediaMany(filter: $filter, skip: $skip, limit: $limit){
		title
		alt
		filename
		folder 
		url
		urlMin 
		urlThumb
		siteId
		size
		_id
	}
}`;
export const FOLDERS_MEDIA = gql`
query mediaMany($filter: FilterFindManyMediaInput) {
	mediaMany(filter: $filter){
		folder 
		size
		siteId
	}
}`;
export const MEDIA_ITEM = gql`
query mediaById ($_id: MongoID!) {
	mediaById(_id: $_id){
		title
		alt
		filename
		folder 
		url
		urlMin 
		urlThumb
		siteId
		size
		_id
	}
}
`;
