import { isRenderField } from "@mosanic/constants";

import { getDropzoneData } from "@mosanic/utils/map/dropZone";
import { getCurrentPath } from "@mosanic/utils/map/path";
import { getSlotChildCount, isSlot, isValidSlot } from "@mosanic/utils/map/slots";
import { ArrowRightIcon, InputIcon } from "@radix-ui/react-icons";
import { isServer } from "@util/Device";
import { DropZone } from "../DropZone";
import Child from "./Child";
import Render from "./Render";
import RenderComp from "./RenderComp";
import { LayerLabel } from "./Styles/Layer.styles";


const Slots = ({
  render, 
  path,
  dropZoneState,
  handleDrop,
  breakpoint,
  mode,
  data,
  ...other
}) => {
  
  return render?.length > 0 && render.map((slot, index) => (
    <Slot 
      {...{
        slot,
        index,
        path,
        dropZoneState,
        handleDrop,
        breakpoint,
        mode,
        data,
        render,
        ...other
      }}
      key={slot?.type + index} 
    /> 
  ));
}


const Slot = ({
  slot,
  index,
  path,
  dropZoneState,
  handleDrop,
  breakpoint,
  mode,
  data,
  render,
  ...other
}) => {


  //Render slot dropzones
  // if(
    // isSlot(slot) 
    // && slot?.max &&
    // !isValidSlot(slot, getSlotChildCount(render, index))
  // ) return getSlotChildCount(render, index)+','+slot.max+','+index;
  
  if(
    isSlot(slot) && 
    (mode === 'dev' || mode === 'layers')
  ){
    return isServer() ? null : (
      
      <DropZone
        key={index}
        allowed={slot?.allowed ? slot.allowed : true }
        data={getDropzoneData(path, index, render)}
        onDrop={handleDrop}
        {...dropZoneState}
        slot={true}
      />
    );
  } 

  
  //Render fields like: inputs, editors
  //These must be defined as: props.fields.['content']
  //Note: these don't contain any styling possibilities
  if(isRenderField(slot.type) && slot?.type != 'icon'){
    return breakpoint === 'layers' ? (
      <LayerLabel center level={other?.level}>
        <ArrowRightIcon/> <InputIcon/> Field
      </LayerLabel>
    ) : (
      <>
      <RenderComp
        fieldType={slot.type}
        path={getCurrentPath(path, index)}
        fieldData={{
          ...slot, 
          screen: breakpoint,
          inline: true
        }}
        key={slot?.type + index}
        isDev={(mode === 'dev' || mode === 'layers')}
      />
  
      </>
    )
  }

  //New render loop if slot has childs
  //Example: 'tabber' has slot allowing: containers.
  //Render here the 'container' with its children.
  if(slot?.children) {
    return (
      <>
      <Child
        data={data}
        child={slot}
        path={getCurrentPath(path, index)}
        level={dropZoneState.level + 1}
        breakpoint={breakpoint}
        mode={mode}
        {...other}
      >
        <Render
            data={data}
            child={slot}
            handleDrop={handleDrop}
            path={getCurrentPath(path, index)}
            breakpoint={breakpoint}
            level={dropZoneState.level + 1}
            mode={mode}
            {...other}
          />
      </Child>
  
      </>
      
    )
  }

  //Render child component
  //Note: these don't contain any children itself
  //For example: Image component, Icon Component.
  return (
    <Child
      child={slot}
      data={data}
      path={getCurrentPath(path, index)}
      level={dropZoneState.level + 1}
      breakpoint={breakpoint}
      mode={mode}
      key={slot}
      {...other}
    />
  )
  
};
export default Slots;
