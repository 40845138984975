import { Items } from "@mosanic/constants";
import { toLowCase } from "@util/Text";
import { getItemByPath, getParentItem } from "./get";
import { splitPath } from "./paths";


export const shouldConfirm = ({map, dropPath, itemType}, action) => {
    dropPath = Array.isArray(dropPath) ? dropPath : splitPath(dropPath);
    const parent = getParentItem({map, itemPath: dropPath});

    //Validate column drop
    if(itemType === Items.COLUMN){
      if(dropPath.length === 1) {
        return {
          message: 'Columns can only be inside a wrapper, would you like to initialize a new columns wrapper?',
          wrapper: 'columns'
        };
      }
      if(dropPath.length >= 2){
        if(parent.itemType != Items.ROW){
          return {
            notify: 'Columns can only be dropped inside an columns wrapper.',
          };
        }
      }
    } else {
        //Validate if item isn't dropped directly into column wrapper
        if(dropPath.length === 2 && parent.itemType === Items.ROW) {
            return {
                message: `${toLowCase(itemType)} can't be directly dropped inside an columns wrapper, would you like to initialize a new column?`,
                wrapper: 'column'
            };
        }

        //Ask for wrapper init 
        if(dropPath.length === 1 && itemType === Items.COMPONENT) {
            return {
                message: `You are dropping a free floating component. \n Would you like to wrap it inside an container?`,
                wrapper: 'container',
                declineAction: action
            };
        }

        
    }

   
    
    return false;
 }