import { useCallback, useEffect, useState } from "react"
import { useGetCollection, useGetEntries } from "../Query"
import { Button, ButtonGroup, Buttons, TextField } from "@mosanic/fields"
import { useGetEntryCount } from "../Query/count"
import { ArrowLeftIcon, ArrowRightIcon, EyeOpenIcon, MinusCircledIcon, PlusCircledIcon } from "@radix-ui/react-icons"
import { ControlPopper, Label } from "@mosanic/items"
import { List, ListItem } from "@mosanic/items/List"
import { isString } from "@util/Text"
import SelectField from "@mosanic/core/Collections/Select/SelectField"
import { Columns } from "@mosanic/fields/Source"
import { convertEntry } from "../Query/useDataQuery"
import { getMinimalFieldPath } from "@mosanic/fields/Source/helpers"
import { useNotify } from "@mosanic/hooks"
import { Condition } from "@JSX"




export const EditorVariables = ({collectionId, trigger = false}) => {
    const [collection] = useGetCollection({_id: collectionId, silent: true})
    const [count] = useGetEntryCount({collectionId})
    const [skip, setSkip] = useState(0)

    const [state, setState] = useState(0)

    const [entries] = useGetEntries({
        filter: {collectionId}, 
        limit: 1, skip
    });

    const notify = useNotify()
    const path  = state?.fieldPath ? `{${getMinimalFieldPath(state?.fieldPath)}}` : null; 

    const copyPath = useCallback((silent = false) => {

        if(!state?.fieldPath) return;

        if('clipboard' in navigator) {
            navigator.clipboard.writeText(path);
        } else {
            document.execCommand('copy', true, path);
        }
        if(!silent) notify(`Copied: ${path}`, 'app', 'info')
    },[notify, path, state?.fieldPath])

    useEffect(() => {
        if(!trigger || !state?.fieldPath) return
        copyPath(true)
    },[trigger, state.fieldPath, copyPath])

    return (
        <Condition when={trigger} wrapper={children => (
            <ControlPopper  trigger={trigger} width={550} 
            height={450}  scroll p={0} footer={<div>{path}</div>}>
                {children}
            </ControlPopper>
        )}>
        <div style={{width: '100%'}}>
            <ButtonGroup>
                <Button disabled={skip <= 0} tooltip="Previous entry" label="Previous entry" icon={<ArrowLeftIcon />}  onClick={() => setSkip(skip -1)}/>
                <Button disabled={skip >= count} tooltip="Next entry" label="Next entry" iconRight={<ArrowRightIcon />} onClick={() => setSkip(skip + 1)}/>
            </ButtonGroup>

        {path && !trigger && <Button size="large" label={`Click to copy: ${path}`} onClick={() => copyPath()} style={{cursor: 'copy', border: 'none', minWidth: '100%'}}/>}
    <Columns style={{width: '100%'}}> 
        <SelectField 
            {...{
                state:{
                    ...state,
                    schema: collection?.schema
                }, setState
            }}
            collection={collection}
            activeEntry={convertEntry(entries?.[0])}
            canSelectField={() => true}
        />
    </Columns> 
        
        {/* {JSON.stringify(getMinimalFieldPath(state?.fieldPath))} */}
        {/* {collection?.schema?.length} */}
        </div>
        </Condition>
    )
}