

import { SITE_TEAM } from "@api/queries/sites";
import { TEAM } from "@api/queries/teams";
import { collectionSchema, collectionsSchema } from "@api/schemas/collections";
import { DataTable, useGet } from "@mosanic/cms";
import { useCollectionUserDataGrid } from "@mosanic/cms/DataGrid/retrievers";
import { CreditStatus } from "@mosanic/components/Credits/CreditStatus";
import { useUser } from "@mosanic/components/User";
import { Status } from "@mosanic/components/User/Status";
import UserStyles from "@mosanic/components/Users/Users.styles";
import { Label, Link } from "@mosanic/items";
import { Tag } from "@mosanic/items/Block";
import { SkeletonCard, SkeletonLine } from "@mosanic/items/Skeletons";
import { parseImageSrc } from "@mosanic/utils/Image";
import { userIdSelector } from "@redux/auth/reducer";
import { getDate } from "@util/Date";
import { hasAttr } from "@util/Obj";
import { isString } from "@util/Text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getRole } from "@mosanic/components/Team/roles";
import { removeSchemaInfo, updateSlug } from "@api/schemas/helpers";
import { InlineGroup } from "@mosanic/items/Inputs";
import { useLogout } from "@mosanic/auth";
import { Button } from "@mosanic/fields";
import { getSiteUrl } from "@mosanic/utils/Site";
import { backendSocketAddress } from "@api/address";
import { ArrowRightIcon, PersonIcon } from "@radix-ui/react-icons";


export const UserProfile = ({_id, overwrite = {collections: ''}, groupStyle = null}) => {
    const _loggedInId = useSelector(userIdSelector)
    const [userId, setUserId] = useState(_id);
    const collections = Array?.isArray(overwrite?.collections) ? overwrite?.collections : overwrite?.collections?.split('\n');
    useEffect(() => {
        if(!_id && _loggedInId) setUserId(_loggedInId)
        if(_id) setUserId(_id)
    },[_id, _loggedInId])


    const [user, loading] = useUser({_id: userId})
    const img = parseImageSrc(user?.author?.profilePicture, true)?.full
    
    const [getSite, [site, loadingSite]] = useGet({
        params: user?.siteId ? {_id: user?.siteId} :undefined,
        type: 'site',
        query: SITE_TEAM,
        silent: true,
        siteCheck: false
    })
    const customDomain = getSiteUrl(site)

    const [getTeam, [team, loadingTeam]] = useGet({
        params: site?.team ? {_id: site?.team} : undefined,
        type: 'team',
        query: TEAM,
        silent: true,
        siteCheck: false
    })


    const isSuperiorRole = Boolean(
        team?.superAdmin === _loggedInId ||
        team?.users?.filter(user => user?.userId === _loggedInId)?.length >= 1
    )

    const isOwnProfile = Boolean(_id === _loggedInId || !_id && _loggedInId)

    const loadingExtra = loadingTeam || loadingSite;
    
    const checkPref = attr => !hasAttr(user?.pref?.privacy, attr) || user?.pref?.privacy?.[attr]

    const role = getRole(user?.role)
    const logout = useLogout()
    return checkPref('public') ? ( 
        <>
        <h1> 
            
        <UserStyles.Avatar src={img} width="25px" height="25px" /> 
            {loading ? <SkeletonLine /> :
            user?.firstName ? `${user?.firstName} ${isString(user?.lastName) ? user.lastName : ''}` :
            'No user found'
            }
            {isOwnProfile && (
                <Button float theme="primary" icon={<PersonIcon/>}  label="logout" size="large" right style={{marginTop: -35, fontWeight: 'bold'}} onClick={() => logout()} />
            )}
            {isSuperiorRole && <Tag mb={1}>Viewing user as a superior role</Tag>}
            {role && (isSuperiorRole || isOwnProfile) && <Tag bg={role.color}>{role?.label} </Tag>}
        </h1>
        <p style={{margin: '25px 0'}}>{user?.author?.biography ? user?.author?.biography : user?.author?.bio}</p>
        
        <InlineGroup style={{justifyContent: 'space-between', ...groupStyle}}>
            {checkPref('email') && (
                <div style={{margin: '15px 0'}}>
                    <Link href={`mailto:${user.email}`}>{user?.email}</Link> <br/>
                    {checkPref('messages') && (
                        <span>You can soon send direct messages.</span>
                    )}
                </div>
            )}
            {checkPref('length') && (
                <Label label="Joined on:" style={{fontSize: '12px', marginTop: 15}} value={getDate(user?.registrationDate)} />
            )}

            {checkPref('lastLogin') && (
                <Label label="Last login:" style={{fontSize: '12px', marginTop: 15}} value={getDate(user?.lastLogin)} />
            )}
        </InlineGroup>


        {loadingExtra ? <SkeletonCard height="400px" /> : (isSuperiorRole || isOwnProfile) ? (
            <>
            <div style={groupStyle}>
                <Status user={user} style={{marginBottom: '20px'}} /> 
                <Button  ghost style={{width: '100%'}} size="large" href="https://www.swopyourstay.com/membership">
                    <ArrowRightIcon/> Renew membership
                </Button>
            </div>
            <div style={groupStyle}>
                <CreditStatus data={user?.subscription?.data} key='credit' />

            </div>
            </>
        ) : null}


{/* {JSON.stringify(customDomain)}  */}
        {(isSuperiorRole || isOwnProfile) && <LoadCollections customDomain={customDomain} groupStyle={groupStyle} collections={collections} userId={_id || _loggedInId} />}
        </>
    ) : (
        <>
        <h1>This user has their profile set to private.</h1>
        </>
    )
}

 
const LoadCollections = ({collections, userId, groupStyle = null, customDomain}) => {
    // console.log(useCollectionDataGrid({_id: collections[0]}))
    // const [data, loading, error] = useGetCollection({_id: collections[0], siteCheck: false})
    return (
        <>
        {/* {loading} {error} */}
        {/* {collections?.map(collectionId => ( collectionId) )} */}
        {/* {JSON.stringify(data)} */}

            {collections?.map(collectionId => collectionId ? (
                <div style={{...groupStyle, paddingTop: 5}} key={collectionId}>
                <DataTable
                    config={{
                        defaultType: 'collection',
                        viewOnly: true,
                        disabled: true,
                        frontEnd: true
                    }}
                    schema={removeSchemaInfo(collectionSchema)}
                    controls={{
                        filters: true,
                        limiter: true,
                        search: true
                    }}
                    // domain={customDomain ? `https://${customDomain}` : null}
                    // todo --> remove and fix this
                    domain="https://www.swopyourstay.com"
                    useDataGrid={useCollectionUserDataGrid}
                    hookProps={{_id: collectionId, userId}}
                    p={0} pt={[4, 8 , 25]}
                    // wrap={false}

                />
                </div>
            ): null)}

            <div style={groupStyle}>
                <h3>Hi Swopper!</h3>
                <p>
                    Do you still miss certain bookings or can&apos;t you see your accommodation?
                </p>
                <p>
                    U can give the SwopYourStay admins a notice on:<br/> 
                    <Link href="mailto:info@swopyourstay.com">info@swopyourstay.com</Link><br/> <br/> 
                </p>
                <p>
                    Is your wallet incorrect or the booking form not available? <br/>
                    Contact the developers directly: <Link href="mailto:info@mosanic.io">info@mosanic.io</Link>
                </p>

            </div>
            {/* {userId} */}
        </>
    )
}


