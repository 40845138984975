import { AppearanceForm, PreferencesForm } from "@mosanic/core/Forms";
import { AdvancedLabel, HelpParagraph } from "@mosanic/items";
import { TwoColumns } from "@mosanic/items/Page/Columns";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { capitalizeFirst } from "@util/Text";
import Image from "next/image";

const OFFSET = 15;

const InfoTrigger = ({label}) => (
    <>
    <ArrowRightIcon /> {label}
    </>
)
const Header = ({title, label, help}) => [
    <h3 key="title">{title}</h3>,
    <AdvancedLabel key="label" width='400px' maxWidth='100%' mb={4} span>
       {label}
    </AdvancedLabel>,
    help
]
const uiImage = {
    'System': '/assets/system-ui/system-pref.svg',
    'Light': '/assets/system-ui/light.svg',
    'Dark': '/assets/system-ui/dark.svg',
    'Single': '/assets/system-ui/single.svg',
    'Duo': '/assets/system-ui/duo.svg',
    'Free': '/assets/system-ui/free.svg',
}

export const GeneralPreferences = ({site, loading, update, name, compact = false, ui = true, help = true}) => (
    <>
    {!ui ? null : (
    <TwoColumns 
        first={<Header 
            title="Interface"
            label={`
                Change how the user interface of ${name} looks and feels. These options will be expanded in the future.
            `}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" UI info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <AppearanceForm fields={[{
                name: 'pref.theme',
                type: 'buttonGroup',
                height: '140px',
                maxHeight: '140px!important',
                options: [{value: null, label: 'System'}, 
                    ...'light|dark'.split('|').map(value => ({
                        label: capitalizeFirst(value), value
                }))].map(o => ({
                    ...o,
                    label: (<div>
                        <Image height="100" width="125" src={uiImage[o?.label]} alt="ui-pref" /><br/>
                        {o.label}
                    </div>)
                }))
            },{
                name: 'pref.sidebar.right',
                label: 'Right sidebar',
                options: [...'activity|messages|updates|pages|sites'.split('|'), {value: null, label: 'Automatic'}],
                type: 'select'
            },{
                name: 'pref.sidebar.transparent',
                label: 'Transparent sidebar',
                desc: 'Make the sidebars that float over the UI transparent.',
                type: 'switch'
            },{
                name: 'pref.sidebar.help',
                label: 'Sidebar labels',
                desc: 'Show helper labels within the sidebar.',
                type: 'switch'
            }]}
            // Tables view
        />}
    />
    )}
    {!help ? null : (
    <TwoColumns 
        mt={compact ? 0 : OFFSET}
        first={<Header 
            title="Help"
            label={`Get help and more information within ${name}, you can turn these options off once you've got a clear idea of all the features within mosanic.`}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Help info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'Tooltips',
                name: 'pref.helpers.tooltips',
                desc: `Show helper tooltips across ${name}.`
            },{    
                label: 'Tips and tours',
                name: 'pref.helpers.intro',
                desc: 'Show small tips and tours on new features.'
            },{    
                label: 'Header help',
                name: 'pref.helpers.headers',
                desc: 'Display extra info above sidebar sections.'
            }]}
        />}
    />
    )}
    </>
) 
export const PrivacyPreferences = ({site, loading, update, name, compact = false}) => (
    <TwoColumns 
        first={<Header 
            title="Profile Privacy"
            label={`Control how and by who, your profile will be displayed within the ${name} admin area.`}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Help info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                name: 'pref.privacy.public',
                label: 'List Profile Public',
                desc: `Your profile will be searchable within the /users page for ${name} Members.`,
                type: 'switch'
            },{    
                name: 'pref.privacy.length',
                label: 'Show your starting date',
                desc: `Display how long you have been a ${name} user.`,
                type: 'switch'
            },{    
                name: 'pref.privacy.lastLogin',
                label: 'Show your last login date',
                desc: `Display the last time you've logged in into ${name}.`,
                type: 'switch'
            },{       
                name: 'pref.privacy.messages',
                label: 'Allow messages',
                desc: 'Allow other users to send messages trough your profile page.',
                type: 'switch'
            },{
                name: 'pref.privacy.status',
                label: 'Show your online status',
                desc: 'Allow other users to view if you are online or not.',
                type: 'switch'
            },{
                name: 'pref.privacy.email',
                label: 'Display your email',
                desc: `So ${name} members can email you directly.`,
                type: 'switch'
            }]}
        />}
    />
)
export const EmailPreferences = ({site, loading, name, update, compact = false}) => (
    <>
    <TwoColumns 
        first={<Header 
            title={`${name} notifications`}
            label={` 
                Get emails to find out what's going on around ${name} and its features. You can turn these off if you want.
            `}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Updates info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'News and update',
                name: 'pref.email.news-update',
                desc: `News about ${name} and feature updates.`
            },{    
                label: 'Tips and tutorials',
                name: 'pref.email.tips-tutorial',
                desc: `Tips on getting more out of ${name}.`
            },{    
                label: 'User research',
                name: 'pref.email.beta',
                desc: 'Get involved in our beta testing program and contribute to our product user research.'
            },{    
                label: 'Reminders',
                name: 'pref.email.reminders',
                desc: 'Get email notifications about updates.'
            }]}
        />}
    />
    <TwoColumns 
        mt={compact ? 0 : OFFSET}
        first={<Header 
            title="Account notifications"
            label={` 
                Emails to find out what's going on in your ${name} account when you're not online. We don't recommend turning these off.
            `}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Email info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'Direct Messages',
                name: 'pref.email.direct',
                desc: `Be notified when a ${name} user sends you a direct message trough your profile.`
            },{    
                label: 'Comments',
                name: 'pref.email.comments',
                desc: 'Get an email when someone comments on a page or post publicly posted by you.'
            }]}
        />}
    />
    </>
)

const canvasOptions = [
    ...'single|duo|free'.split('|').map(value => ({
        label: capitalizeFirst(value), 
        value
}))].map(o => ({
    ...o,
    label: (<div>
        <Image height="150" width="275" src={uiImage[o?.label]} alt="ui-pref" /><br/>
        {o.label}
    </div>)
}))
const builder = [{
    title: 'Canvas',
    label: 'Choose your default preferred canvas setup. Note that you can change these options on the fly within the view options in the navigation bar.',
    options: [{
        label: 'Canvas mode',
        name: 'pref.canvas.mode',
        type: 'buttonGroup',
        height: '140px',
        maxHeight: '140px!important',
        plan: 'pro',
        options: canvasOptions
    },{    
        label: 'Dynamic movements',
        name: 'pref.canvas.pinchZoom',
        plan: 'pro',
        desc: 'Zoom, Scroll and Pinch to move the canvas around.'
    },{    
        label: 'Shift scroll x-axis',
        name: 'pref.canvas.shiftScroll',
        plan: 'pro',
        desc: 'When the shift button is pressed you can scroll from left to right with the mousewheel.'
    },{   
        label: 'SpaceBar drag',
        name: 'pref.canvas.spaceBarDrag',
        plan: 'pro',
        desc: 'Press the space bar to drag the canvas around.'
    }]
},{
    title: 'Inspector',
    label: 'Change how the Builder Inspector on the right side reacts to your actions.',
    options: [{    
        label: 'Auto open inspector',
        name: 'pref.inspector.autoOpenInspect',
        desc: 'Open builder inspector sidebar on click.'
    },{    
        label: 'Smart accordion',
        name: 'pref.inspector.autoCloseAccordion',
        desc: 'Auto close the other accordion field groups.'
    }]
},{
    title: 'Inputs',
    label: 'Let the builder perform automatic adjustments based on your inputs.',
    options: [{    
        label: 'Round up values',
        name: 'pref.values.roundUp',
        desc: 'Automatic round up pixel based values to the nearest half (.5) value.'
    },{    
        label: 'Auto switch screen state',
        name: 'pref.values.bpSwitch',
        desc: 'When clicking on an element inside a different breakpoint screen, set the variant to that breakpoint.'
    }]
},{
    title: 'Layers',
    label: 'The builder layers display your current design components in parent-child relations. It can be found in the second tab of the sub navbar.',
    options: [{    
        label: 'Auto open layers',
        name: 'pref.layers.autoOpenLayers',
        desc: 'Layers expand automatic.'
    },{    
        label: 'Show component icons',
        name: 'pref.layers.showIcons',
        desc: 'Display the component icons of each layer.'
    },{    
        label: 'Unfocus non-selected',
        name: 'pref.layers.unfocus',
        desc: 'Make the components that are not hovered or related to the inspected component less visible.'
    },{    
        label: 'Highlight inspected group',
        name: 'pref.layers.highlightGroup',
        desc: 'Highlight the container of the inspected component.'
    }]
}]
export const BuilderPreferences = ({site, loading, update, compact = false}) => builder.map(group => (
    <TwoColumns 
        mt={compact ? 0 : OFFSET}
        key={group?.title}
        first={<Header 
            title={group?.title}
            label={group?.label}
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Updates info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={group?.options}
        />}
    />
))


export const DashboardPreferences = ({site, loading, update, compact = false}) => (
    <>
    <TwoColumns 
        first={<Header 
            title="Mosanic UI"
            // label="C."
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Updates info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'Disable dashboard',
                name: 'pref.dashboard.disable',
                desc: 'When disabled, you will auto redirect to one of your sites .'
            }]}
        />}
    />
    {/* <TwoColumns 
        mt={compact ? 0 : OFFSET}
        first={<Header 
            title="Color picker"
            label=" 
                Emails to find out what's going on in your Mosanic account when you're not online. We don't recommend turning these off.
            "
            help={<HelpParagraph helpTrigger={<InfoTrigger label=" Email info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'Direct Messages',
                desc: 'Be notified when a Mosanic user sends you a direct message trough your profile.'
            },{    
                label: 'Comments',
                desc: 'Get an email when someone comments on a page or post publicly posted by you.'
            }]}
        />}
    /> */}
    </>
)

export const CollectionsPreferences = ({site, loading, update, compact = false}) => (
    <>
    <TwoColumns 
        first={<Header 
            title="Collections"
            label=" 
                Get emails to find out what's going on around Mosanic and its features. You can turn these off if you want.
            "
            // help={<HelpParagraph helpTrigger={<InfoTrigger label=" Updates info"/>} helpText={(<>Some emails like the login revalidation code, messages and team requests can not be turned off.</>)} span />}
        />}
        second={
            <PreferencesForm fields={[{    
                label: 'Auto save',
                name: 'pref.helpers.autoCloseAccordion',
                desc: 'Auto close the inspector accordion field groups.'
            },{    
                label: 'Tips and tours',
                desc: 'Show small tips and tours on new features.'
            }]}
        />}
    />
    </>
)