
import { UPDATE_TEAM } from '@api/mutations/teams';
import { isAuthenticated } from "@api/token";
import { useMutate } from "@hooks/useApi";
import { useNotify } from "@mosanic/hooks";
import { userIdSelector } from "@redux/auth/reducer";
import { useSelector } from "react-redux";

export const useEditTeam = ({callback = null}) => {

    const userId = useSelector(userIdSelector)
    const isAuth = isAuthenticated()
    const [ updateTeam, [data, loading, error]] = useMutate('teamUpdateById', UPDATE_TEAM)

    const notify = useNotify();

    const handler = ({team}) => {
        const _id = team?._id;

        if(!_id) {notify('No team id received.');return;}
		if(!isAuth) {notify('You are not authenticated.');return;}
        if(
            !team?.unsubscribe &&
            team?.superAdmin != userId &&
            team?.users?.find(u => u?._id === userId)?.role != 'admin'
        ){notify('Only admins can update a team.');return;}
        
        const record = {
			...team,
            unsubscribe: undefined,
            _id: undefined,
		}

		updateTeam({
            _id,
            record: {
				...record,
			}
		}).then((res) => {
			let {data, error} = res;
            if(data?.teamUpdateById) data = data.teamUpdateById

			if(!error && data){
                notify("Successfully updated team.", 'app', 'success')
                
                if(callback) callback();
			} else if (error) {
				notify("An error occurred during the team update.")
			}
		})
    }
    
    return [handler, [data, loading, error]];
}