import { Condition } from "@JSX";
import { getLastDropzonePath, isHorizontalDropzone } from "@mosanic/utils/map/dropZone";
import { isDevMode } from "@mosanic/utils/map/mode";
import { hasSlots } from "@mosanic/utils/map/slots";
import { isCol } from "@mosanic/utils/validate";
import { isServer } from "@util/Device";
import { memo } from "react";
import { DropZone } from "../DropZone";
import InView from "../Items/InView";

import Children from "./Children";
import Slots from "./Slots";

export const ProdRender = ({
  child, data, breakpoint
}) => <Render {...{child, data, breakpoint, handleDrop: null, mode:"prod",path:''}} />

const Render = ({
  child, 
  data,
  handleDrop, 
  path, 
  breakpoint, 
  level = 0,
  mode = 'dev',
  ...other
}) => {
  const children = child?.children ? child.children : child;
  const type = child?.type;
  
  const dropZoneState = {
    level,
    breakpoint,
    canExpand: isCol(child?.itemType),
    empty: !children || children.length === 0,
    isHorizontal: isHorizontalDropzone(type),
    slot: false,
  }

  const childrenProps = {
    render: children,
    dropZoneState,
    path,
    handleDrop,
    breakpoint,
    mode,
    data,

    ...other
  }
  if(hasSlots(children)) {
    return (<>
      <Slots {...childrenProps} level={level} />
    </>)
  }

  return isDevMode(mode) ? (
    <Condition when={level === 0}
      wrapper={children => (
        <>
          {children}
        </>
      )}
    >
      <Children {...childrenProps} />
      
      {isServer() ? null : (
        <DropZone
          {...dropZoneState}
          data={{
            path: getLastDropzonePath(path, children?.length),
            childrenCount: children?.length
          }}
          onDrop={handleDrop}
          isLast
        />
      )}
    </Condition>
  ) : (
    // <Condition
    //   wrap={ isSeoMode(mode) }
    //   wrapper={children => <div className="preRenderSeo">{!rendered ? children : null}</div>}
<>
      <Children {...childrenProps} />
      </>
    // </Condition>
  );

};
const MemoizedRender = memo(Render);
export default MemoizedRender;