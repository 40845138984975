
import Input from '@mosanic/fields/Input';
import { Expander, InputGroup, Link } from '@mosanic/items';

import { elementIcon } from '@mosanic/Icons';

import { Button, ButtonGroup, TextField } from '@mosanic/fields';
import { AdvancedLabel } from '@mosanic/items';

import { default as s } from '../Collection.styles';


import { list_roles } from '@mosanic/components/Team/roles';
import { relations } from '@mosanic/fields/Source/Relation';
import { Col, Colors, Wrap, colors } from '@mosanic/styles';
import { FieldsCollection, StepsCollection } from '../FieldsCollection';



import { useGetUser } from '@mosanic/cms/Query/byId';
import { useGetMails } from '@mosanic/cms/Query/many';
import { useDataProvider } from '@mosanic/core/Provider';
import { parseCode } from '@mosanic/fields/Code/PreviewCode';
import { getEntryLabel } from '@mosanic/fields/Source/helpers';
import { ControlButtons } from '@mosanic/items/Control';
import { ControlPopper } from '@mosanic/items/ControlPanel';
import { replaceWithSource } from '@mosanic/utils/value';
import { DotsHorizontalIcon, ExclamationTriangleIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { siteIdSelector } from '@redux/auth/reducer';
import { getDevModePrefSelector } from '@redux/options/reducer';
import { hasAttr } from '@util/Obj';
import { capitalizeFirst, combineString, revertDevCases } from '@util/Text';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FieldControls } from '../FieldControls';



//Generic groups
export const FieldMeta = ({control, fieldId, getField, fieldType, disabled}) => !disabled ? (
    <InputGroup cols={3} border label="Meta controls" force>

        {!getField('populateActive') && (<>
        <Input name={`${fieldId}.required`} label="Required" type="switch" control={control}/>	
        <Input name={`${fieldId}.unique`} label="Unique" type="switch" control={control} />	
        <Input name={`${fieldId}.localized`} label="Localized" type="switch" control={control} />	
        </>)}

        <Input name={`${fieldId}.props`} label="Prop" type="switch" control={control} />	
        
        {['textarea','text'].includes(fieldType) && (
            <Input name={`${fieldId}.description`} label="Description" type="switch"  control={control} />	
        )}
    
        <Input name={`${fieldId}.inMap`} label="In map" type="switch"  control={control} />	

        {(getField('inMap') && ['textarea','text','visual'].includes(fieldType)) && (
            <Input name={`${fieldId}.searchable`} label="Searchable" type="switch"  control={control}/>
        )}
        {(getField('inMap')) && (
            <Input name={`${fieldId}.filterable`} label="Filterable" type="switch"  control={control} />
        )}
        {!getField('populateActive') && (
        <Input name={`${fieldId}.pageProp`} label="Received from page" type="switch" control={control}/>
        )}

        <Input name={`${fieldId}.variable`} label="Mail variable" type="switch"  control={control} />
        
        <Input name={`${fieldId}.restrict`} label="Restrict field" type="select"  control={control} 
            options={list_roles} searchable multiple fullWidth wrap
        />

    </InputGroup>
) : null

export const FieldVisibility = ({control, fieldId, getField, fieldType, disabled}) => !disabled ? (
    <InputGroup cols={3} border label="Visibility" force>
        <Input name={`${fieldId}.hidden`} label="Hidden" type="switch" control={control} />	
        {!getField('populateActive') && (
            <Input name={`${fieldId}.fixed`} label="Not editable" type="switch" control={control}/>	
        )}
    
        <Input name={`${fieldId}.fullWidth`} label="Full width" type="switch"  control={control} />	
        
        <Input name={`${fieldId}.inColumns`} label="In Columns" type="switch"  control={control} />	
        {"label" === fieldType && (
            <>
            <Input name={`${fieldId}.headingSize`} fullWidth  label="Label size" type="select" control={control} options={
                [...'h1|h2|h3|h4|h5|h6|bold|small'.split('|').map(value => ({value, label: value})),{value: null, label: 'default'}]
            }/>	
            <Input name={`${fieldId}.separator`} fullWidth label="Separator" type="select" control={control} options={
                [...'top|bottom'.split('|').map(value => ({value, label: value})),{value: null, label: 'none'}]
            }/>	
            </>
        )}

        {"visual" === fieldType && (
            <>
            <Input name={`${fieldId}.removeBorders`} label="Remove borders" type="switch" control={control}/>	
            <Input name={`${fieldId}.hideToolbar`} label="Hide toolbar" type="switch" control={control}/>	
            </>
        )}

    </InputGroup>
) : null


const RegexValidation = ({fieldId, control, getField}) => {


     // Some regular expression you could try
    // const email = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+/;
    // const phone = /(\\+\\d{1,3} )?\\(\\d{3}\\)-\\d{3}-\\d{4}( Ext: \\d+)?/;
    // const color = /Red|Gr(een|ay)|Blue|Yellow|O(range|live)/;
    
    // A rather complex regular expression (please feel free to user your own)
    const [text, setText] = useState('')
    const regex = getField(`regex`)
    return (
           <>
           <Input name={`${fieldId}.regex`} label="Regex value" fullWidth type="text" control={control} />
            
            <InputGroup>
                <TextField label="Test text" value={text} onChange={e => setText(e.target.value)} />
                <TextField label="Validation" value={JSON.stringify(regex && text && regex.test(text))} disabled />
            </InputGroup>
            <AdvancedLabel mt={3} label={<Link href="https://www.regextester.com/">More information </Link>}/>
           </>
    )
} 
const HelpContent = styled.div` 
    font-size: 12px;
`
const Value = styled.span` 
    ${props => props.clickable && 'cursor: pointer;'}
    font-family: courier;
    font-size: 12px;
    background: ${Colors.util.gray.lighter};
    padding: 2.5px 5px;
`
const Item = styled.div` 
    margin: 2.5px 0;
`
const Bold = styled.strong` 
    display: block;
    margin-top: 5px;
    &:hover {
        background: ${Colors.util.gray.lighter};
        cursor: pointer;
    }
`
const operations = {
    ['+']: 'Add / Unary Plus',
    ['-']: 'Subtract / Unary Minus',
    ['*']: 'Multiply',
    ['/']: 'Divide',
    ['^']: 'Power',
    ['%']: 'Modulo',
    ['(']: 'Begin Group',
    [')']: 'End Group',
    [',']: 'Separate Argument',
}
const functions = {
    ['ABS(x)']: 'Returns the absolute value of a number.',
    ['CEIL(x)']: 'Returns the smallest integer greater than or equal to a number.',
    ['FLOOR(x)']: 'Returns the largest integer less than or equal to a number.',
    ['SUM(x[, y[, ...]])']: 'Returns the sum of one or more numbers.',
}

const PopulationGroup = ({control, fieldId, getField, fieldType}) => {

	// const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
	// 	name: `${fieldId}.populate`, 
	// 	control
	// }); 
    const {ctx} = useDataProvider()
    const isDev = useSelector(getDevModePrefSelector)

    return (
        <InputGroup fullWidth border label="Population config" cols={1} force>
            {getField('dangerousPopulation') ? (
                <>
                <AdvancedLabel>
                    Access <Value>{`$ctx.entry`}</Value> to perform all data manipulations
                </AdvancedLabel>
                <Input name={`${fieldId}.code`} label="Code" type="code" control={control} ctx={ctx} showPreview/>	
                </>
            ): (
            <>
                <ControlPopper trigger={(
                    <AdvancedLabel>
                        Insert <Value>{`{group_name.field_name}`}</Value> and perform math evaluations <QuestionMarkCircledIcon />
                    </AdvancedLabel>
                )}>
                    <HelpContent>

                    <Expander trigger={<Bold>Math operations</Bold>}>
                        {Object.entries(operations).map(([o, label]) => (
                            <Item>
                                <Value>{o}</Value> {label}
                            </Item>
                        ))}
                    </Expander>
                    <Expander trigger={<Bold>Math functions</Bold>}>
                        {Object.entries(functions).map(([o, label]) => (
                            <Item>
                                <Expander trigger={<Value clickable>{o}</Value>}>
                                    {label}
                                </Expander>
                            </Item>
                        ))}
                    </Expander>
                    <Expander trigger={<Bold>Dates</Bold>}>
                        If a pointer is to a date field, only a - operator can be used. <br/>
                        Get days between:<br/> <Value>{`{dateA}`} - {`{dateB}`}</Value>
                    </Expander>

                    </HelpContent>
                </ControlPopper>

                <Input name={`${fieldId}.populateCode`} label="Population" type="text" control={control} />	

                <TextField value={JSON.stringify(replaceWithSource(getField('populateCode'), ctx?.entry, true))} fullWidth wrap label="Preview population" disabled/>
            </>
            )}
            <Input name={`${fieldId}.dangerousPopulation`} label="Dangerous" type="switch" control={control} disabled={!isDev} />	

        </InputGroup>
    )
}

const validateOptions = 
`hide|Hide field
show|Show field
disable|Disable field
enable|Enable field
create|Can create
noCreate|Can't create
update|Can update
noUpdate|Can't update
helper|Display Helper
error|Display Error`
.split('\n').map(f => {
    const o = f.split('|');
    return { value: o[0], label: o[1]}
})
const crossValidate = {
    hide: 'show',
    show: 'hide',
    disable: 'enable',
    enable: 'disable',
    enable: 'disable',
    update: 'noUpdate',
    noUpdate: 'update',
    create: 'noCreate',
    noCreate: 'create',
}

const ValidateGroup = ({control, fieldId, getField, fieldType}) => {

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		name: `${fieldId}.validation`, 
		control
	}); 
    const option = (index) => getField('validation')?.[index]
    const helpers = index => option(index)?.actions?.filter(o => ['error', 'helper'].includes(o))

    const data = useDataProvider()

    const ctx = {
        ...data.ctx,
        user: data?.user,
        validation: fields?.map((f,i ) => 
            parseCode(option(i)?.code, data.ctx)
        )
    }
    const isDev = useSelector(getDevModePrefSelector)

    // validateActive
    const validate = getField('validate')?.field
    const [expanded, setExpanded] = useState(null)
    return (
        <InputGroup fullWidth border label="Validation config" cols={1} force>
            <AdvancedLabel my={3}>
                Access <Value>{`$ctx.entry`}</Value> to perform all data validation. <br/>
                <ExclamationTriangleIcon /> Return a wrapped <Value>Boolean{`(...)`}</Value> else if the value is&apos;nt empty the validation is <Value>true</Value>.
            </AdvancedLabel>

            {/* <Input name={`${fieldId}.validate.code`} label="Code" type="code" control={control} ctx={ctx} showPreview/>	 */}
            <ControlButtons setActive={setExpanded} active={expanded} options={fields.map((f, i) => ({
                    value: `${i}`, 
                    label: `${combineString(option(i)?.actions, ', ')} `
                }))
            } />    

            {fields?.map((f, index) => expanded === `${index}` ? (
                <InputGroup border label={option(index)?.actions?.length >= 1 ? `Validation for: [${combineString(option(index)?.actions, ', ')}]` : 'Validation'} key={f.id} cols={1}>
                    <FieldControls
                        trigger={<Button icon={<DotsHorizontalIcon />} ghost />}
                        count={fields?.length}
                        style={{position: 'absolute', right: 0, top: 0, minWidth: 5}}
                        remove={remove}
                        move={move}
                        index={index}
                    />
    
        
                    <Input name={`${fieldId}.validation.${index}.code`} label="Code" type="code" control={control} ctx={ctx} showPreview/>	
                    <Input name={`${fieldId}.validation.${index}.actions`} label="If validation is true" type="select" control={control} 
                        options={
                            validateOptions.map(o => {
                                //skip update and crate
                                if(!hasAttr(crossValidate, o.value)) return o;
                                const active = option(0)?.actions;
                                
                                if(!active?.includes(crossValidate[o.value])) return o
                                return null
                                // return active.filter(a => crossValidate[a.value]?.includes(o.value))
                            }).filter(o => o?.value)
                        } multiple fullWidth
                    />	
                    
                
                    {helpers(index)?.length >= 1 && (
                        <InputGroup label="Labels" border cols={1}>
                            {helpers(index)?.includes('error') && (
                                <Input name={`${fieldId}.validation.${index}.error`} label="Error if false" type="code" control={control} ctx={ctx} showPreview/>
                            )}
                            {helpers(index)?.includes('helper') && (
                                <Input name={`${fieldId}.validation.${index}.helper`} label="Helper if true" type="code" control={control} ctx={ctx} showPreview/>
                            )}
                        </InputGroup>
                    )}

                </InputGroup>
            ): null)}
            <Button onClick={() => append({code: '$ctx.entry', actions: []})} label='Add validation' style={{width: '100%'}} m={0} ghost />

            {/* {getField('validate')?.code && [
                <InputGroup border label="If validation is true" key="yes" cols={3}>
                    <Input name={`${fieldId}.validate.field.hide`} label="Hide field" type="switch" control={control} disabled={validate?.show}/>	
                    <Input name={`${fieldId}.validate.field.disable`} label="Disable field" type="switch" control={control} disabled={validate?.enable}/>	
                    <Input name={`${fieldId}.validate.field.create`} label="Can create" type="switch" control={control} />	
                    <Input name={`${fieldId}.validate.field.show`} label="Show field" type="switch" control={control} disabled={validate?.hide} />	
                    <Input name={`${fieldId}.validate.field.enable`} label="Enable field" type="switch" control={control} disabled={validate?.disable} />	
                    <Input name={`${fieldId}.validate.field.update`} label="Can update" type="switch" control={control} />	
                </InputGroup>,
                <InputGroup border label="Label" key="labels" cols={1}>
                    <Input name={`${fieldId}.validate.field.true_label`} label="If true" type="text" fullWidth control={control} />
                    <Input name={`${fieldId}.validate.field.false_label`} label="If false" type="text" fullWidth control={control}/>
                </InputGroup>
            ]} */}

        </InputGroup>
    )
}
export const FieldValidation = ({control, fieldId, getField, fieldType, disabled}) => !disabled ? (
        <>
    <InputGroup cols={3} border label="Validation" force>


        {['textarea','text','visual', 'number'].includes(fieldType) && (
            <Input name={`${fieldId}.regexActive`} label="Regex validation" type="switch"  control={control} />
        )}

        <Input name={`${fieldId}.populateActive`} label="Dynamic populate" type="switch"  control={control} />


        {("date" === fieldType) && [
            <Input name={`${fieldId}.range`} type="switch" label="Range" control={control} />,
            // <Input name={`${fieldId}.start_date`} type="date" label="Start date" control={control} key="start_date"/>
        ]}

        {(['textarea','text','visual', 'number'].includes(fieldType) && !getField('populateActive')) && (
            <Input name={`${fieldId}.limit`} label="Limit" type="switch" control={control} />	
        )}

        {(getField('regexActive')) ? (
            <InputGroup fullWidth border label="Regex">
                <RegexValidation control={control} fieldId={fieldId} getField={getField} />
            </InputGroup>
        ) : (
            <Input name={`${fieldId}.validateActive`} label="Advanced validation" type="switch"  control={control} />
        )}

        {(
            ['textarea','text','visual', 'number'].includes(fieldType) &&
            getField('limit')
        ) && (
            <InputGroup fullWidth border label="Limit">
                <Input name={`${fieldId}.min`} label={fieldType === 'number' ? "Minimum value" : "Minimum chars"} type="number" control={control} />
                <Input name={`${fieldId}.max`} label={fieldType === 'number' ? "Maximum value" : "Maximum chars"} type="number" control={control} />
            </InputGroup>
        )}
        {("repeater" === fieldType) && (
            <InputGroup fullWidth border label="Repeater config">
                <Input name={`${fieldId}.limitChildren`} label="Limit repeats" type="number" control={control} />	
                {/* <Input name={`${fieldId}.limitChildren`} label="Limit repeats" type="number" control={control} />	 */}
                <Input name={`${fieldId}.singular`} label="Singular name" type="text" control={control} />	
                <Input name={`${fieldId}.displayChildren`} label="Editor view" type="select" control={control} 
                    options={'list|tabs|select|accordion|row'.split('|').map(value => ({value, label: (
                        <span>
                            {elementIcon(value)} {!['s', 't'].includes(value.charAt(value.length - 1)) ? value+'s' : value}
                        </span>
                    )}))}
                />	
            </InputGroup>
        )}

        

    </InputGroup>
    {(getField('populateActive')) && (
        <PopulationGroup {...{control, fieldId, getField, fieldType}} />
    )}
    {(getField('validateActive')) && (
        <ValidateGroup {...{control, fieldId, getField, fieldType}} />
    )}
    </>
) : null



//Group collections

export const ChoiceType = ({control, fieldId, fieldType, show}) => show ? (
    <InputGroup cols={2} force>
        <Input name={`${fieldId}.options`} label="Values" placeHolder={`Example: \nmain|sub-text \nsub|sub-text \nthird|sub-text`} type="textarea" control={control} />
        
        {fieldType != 'buttonGroup'&& (
            <Input name={`${fieldId}.direction`} label="Direction" options={['horizontal', 'vertical']} type="buttonGroup" control={control} />
        )}
    </InputGroup>
) : null

export const FieldUser = ({control, fieldId, getField, show}) => show ? (
    <InputGroup cols={2} border label="User" force>
        <Input name={`${fieldId}.isAuthor`} label="Is Author" type="switch" control={control} />

        {!getField('isAuthor') && (
            <Input name={`${fieldId}.canView`} label="Can view" type="switch" control={control} />
        )}


        <Input name={`${fieldId}.userId`} label="Active user id" type="switch" control={control} disabled={getField('pageProp')} />
    </InputGroup>
) : null

//Specific groups

export const FieldMarkedImage = ({control, fieldId, getField, collections, show}) => show ? [
    <Input 
        clearable={false} 
        name={`${fieldId}.markerRelation`} 
        label="Marker relation" 
        type="select" 
        control={control} 
        options={[...collections?.options, {value: null, label: 'Unset'}]} 
        fullWidth
    />,
    getField('markerRelation') && (
        <Input 
            name={`${fieldId}.markerDisplay`} label="Marker display" type="select" control={control} 
            options={false && dynamicPages?.length >= 1 ? dynamicPages.map(p => ({
                value: p._id,
                label: `${p?.title} [${p?.type}]`
            })) : [{value: null, label: 'Create an dialog or zone first.'}]} 
            fullWidth 
        />
    )
] : null

export const FieldRelation = ({control, fieldId, getField, collections, zones, setValue, show}) => show ? (
    <InputGroup border label="Relation setup" style={{paddingTop: '25px'}} force>
        <Input name={`${fieldId}.relation`} type="string" control={control} />
        <ButtonGroup>
            <ButtonGroup.Choices
                value={getField(`relation`)}
                handler={(value) => setValue(`${fieldId}.relation`, value)}
                options={relations}
            />
        </ButtonGroup>
        <Wrap width="100%">
            <Col width={[1/4]}>
                <TextField label="Entry" value={getField('name')} wrap fullWidth disabled/>
            </Col>
            <Col width={[1/2]} style={{textAlign: 'center'}}>
                <AdvancedLabel pt={4}>
                    <span>{getField('name')}</span>
                    <s.RelationLabel>
                        {relations?.find(r => r.value === getField(`relation`))?.tooltip}
                    </s.RelationLabel>
                    <span>field</span>
                </AdvancedLabel>
            </Col>
            <Col width={[1/4]}>
                {/* // TODO replace mockup data with collection link */}
                <Input clearable={false} name={`${fieldId}.relationEnd`} label="To" type="select" control={control} 
                    options={collections?.options} fullWidth
                />
            </Col>
        </Wrap>

        <Input 
            name={`${fieldId}.relationRender`} label="Render component" type="select" control={control} 
            options={zones?.length >= 1 ? zones.map(p => ({
                value: p._id,
                label: `${p?.title} [${p?.type}]`
            })) : [{value: null, label: 'Create an dialog or zone first.'}]} 
            fullWidth 
        />

    </InputGroup>
) : null

export const FieldGroup  = ({fieldId, level, show}) => show ? (
    <InputGroup border label="Fields" cols={1} force style={{borderLeft: `2px solid ${colors.util.gray.light}`, paddingLeft: '10px', marginTop: '25px', display: 'block'}}>
        <FieldsCollection 
            id={`${fieldId}.schema`}
            level={level + 1}
            skipFirstField
        />
        
    </InputGroup>
): null;

export const FieldSteps = ({fieldId, level, label, show}) => show ? (
    <InputGroup force
        style={{borderLeft: `2px solid ${colors.util.gray.light}`, paddingLeft: '10px'}} 
        label={label} cols={1} border
    >
        <StepsCollection
            id={`${fieldId}.schema`}
            level={level + 1}
            type='step'
        />
    </InputGroup>
) : null

export const FieldRepeat = ({fieldId, level, label, show}) => show ? (
    <InputGroup force
        style={{borderLeft: `2px solid ${colors.util.gray.light}`, paddingLeft: '10px', display: 'block'}} 
        label={label} cols={1} border
    >
        <FieldsCollection
            id={`${fieldId}.schema`}
            level={level + 1}
        />
    </InputGroup>
) : null

const userSelectCode = 
`({
    ...$ctx.user, 
    updatedAt: new Date()
})`
export const FieldButton = ({fieldId, level, schema, getField, show, control, collectionId, trigger}) => {
    const siteId = useSelector(siteIdSelector)
    const [mails] = useGetMails({filter: {siteId, collectionId}})
    const mail = mails?.find(m => m._id === getField('mailId'));
    const templates = mail?.templates;

   
    const userFieldsName = `${fieldId}.trigger.users`;
    const userFields = [
        {value: 'userId', label: 'Author'},
        ...schema?.filter(f => f?.type === 'user')?.map(f => ({
            label: revertDevCases(f?.label ? f.label : f.name),
            value: f.name, 
        }))
    ]
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		name: userFieldsName, 
		control
	}); 

    const [expanded, setExpanded] = useState(null)
    const toggle = (i) => setExpanded({[i] : !expanded[i]})

    const data = useDataProvider()
    return show ? (
        <>
        <InputGroup label='Trigger' cols={3} border >
            <Input name={`${fieldId}.action`} label="Action" type="select" options={'create|update|delete|send'.split('|')} control={control} fullWidth />
            <Input name={`${fieldId}.enableMail`} label="Mail trigger" type="switch" control={control} />
            <Input name={`${fieldId}.enableUser`} label="User trigger" type="switch" control={control} />
            <Input name={`${fieldId}.enableModify`} label="Modify trigger" type="switch" control={control} />
        </InputGroup>
        {getField('enableMail') && (
            <InputGroup border label="Mail" cols={1}>
                <Input name={`${fieldId}.mailId`} label="Mail trigger" type="select" control={control} options={mails?.map(mail => ({value: mail._id, label: mail.title}))} fullWidth/>
                {getField('mailId') && (
                    <Input name={`${fieldId}.mailTemplate`} label="On submit" type="select" control={control} 
                        options={templates?.map(template => getEntryLabel(template, mail?.schema))} 
                    />
                )}
            </InputGroup>
        )}
        {getField('enableUser') && (
            <InputGroup label="Users" border>
                <AdvancedLabel my={3}>
                    Access <Value>{`$ctx.user`}</Value> to perform all user manipulations on the selected userId. <br/>
                    <ExclamationTriangleIcon /> Return a changed <Value>{`({ ...$ctx.user })`}</Value> object.
                </AdvancedLabel>
                <ControlButtons setActive={setExpanded} active={expanded} options={fields.map((f, i) => ({
                        value: `${i}`, 
                        label: getField('trigger')?.users?.[i]?.userId
                    }))
                } />    
                
                {fields?.map((f, index) => (
                    <UserTrigger 
                        userFields={userFields}
                        controls={{
                            count: fields?.length,
                            remove, 
                            // move
                        }}
                        name={`${userFieldsName}.${index}`}
                        index={index}
                        control={control}
                        field={getField('trigger')?.users?.[index]}
                        expanded={expanded === `${index}`}
                        toggle={toggle}
                    />
                ))}
                <Button onClick={() => append({code: userSelectCode, userId: 'userId'})} label='Add user trigger' style={{width: '100%'}} m={0} ghost />
            </InputGroup>
        )}
        {getField('enableModify') && (
            <InputGroup label="Users" border cols={1}>
                <AdvancedLabel>
                    You must return a <Value>{`$ctx.entry`}</Value>. You can perform changes and return it like this: <Value>{`({...$ctx.entry, newField: true})`}</Value>.
                </AdvancedLabel>
                <Input name={`${fieldId}.modifyCode`} label="Modification Code" type="code" control={control} ctx={data.ctx} showPreview/>	
            </InputGroup>
        )}
        </>
    ) : null
}

const UserTrigger = ({index, field, name, controls, control, userFields, expanded, toggle}) => {
    const data = useDataProvider()

    const [user] = useGetUser({_id: data?.ctx?.entry?.[field?.userId], silent: true})
    const ctx = {
        ...data.ctx,
        user: {
            ...user,
            firstName: undefined,
            lastName: undefined,
            author: undefined
        },
    }

    return expanded ? (
        <InputGroup label={capitalizeFirst(field?.userId)} border key={index} cols={1}>
            <FieldControls
                trigger={<Button icon={<DotsHorizontalIcon />} ghost />}
                {...controls}
                style={{position: 'absolute', right: 0, top: 0, minWidth: 5}}
                index={index}
            >
            </FieldControls>

            <Input name={`${name}.userId`} label="User Id" type="select" control={control} options={userFields} />
            <Input name={`${name}.code`} label="Code" type="code" control={control} ctx={ctx} showPreview/>	

        </InputGroup>
    ) : null
}