


import { copyAndTransformItemMap } from '@mosanic/map/transform';
import { getSiblingPath } from '@mosanic/map/path';

import { selectInspectItemPath } from '@redux/inspect/reducer';
import { setNotification } from '@redux/notification/action';
import { mapActions } from '@redux/map';

import { includesAction } from '@redux/util';

import { default as actions } from '../drop/action';
import { preDropStoreSelector } from '../drop/reducer';


export const pasteComponentActionFlow = ({getState, dispatch}) => next => action => {
    next(action);

    if(includesAction(action, actions.DROP_PASTE)){ 
        const state = getState()
        const preStore = preDropStoreSelector(state)

        //Get source
        const item = !Boolean(preStore?._id) ? 
            action.payload : 
            preStore;

        const {sourceItemPath} = item || {sourceItemPath: null};

        if(!sourceItemPath) return;

        //Convert source
        const itemMap = copyAndTransformItemMap([item], dispatch);

        const currentPath = selectInspectItemPath(state);

        if(currentPath != sourceItemPath) {
            setNotification({
                message: 'Pasting component on different location', 
                feature: 'drop', meta: {duration: 1000, variant: 'info'
            }})
        }

        //Add to map
        const dropPath = getSiblingPath(currentPath)
        dispatch(mapActions.addMapAction({itemMap, dropPath}));

    
    }
};

export const pasteMiddleware = [
    pasteComponentActionFlow
];