
import { ObjectID } from "@mosanic/utils";
import { toVarCase } from "@mosanic/utils";
import { CREATE_DESIGN_TOKEN, setDesignToken } from "./action";



export const handleTokenCreationFlow = () => next => action => {
  action.type === CREATE_DESIGN_TOKEN ?
    next(setDesignToken({
      token: {...action.payload, 
        selector: toVarCase(action.payload.label)}, 
        _id: ObjectID()
    })) : next(action);
};

export const tokensMdl = [
  handleTokenCreationFlow,
];
