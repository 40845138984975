

import { FILTER_COLLECTIONS, FILTER_COLLECTIONS_INDEX } from "@api/queries/collections";
import { FILTER_ENTRIES } from "@api/queries/entries";
import { useLazyQuery, useVarQuery } from "@hooks/useApi";
import { siteIdSelector } from "@redux/auth/reducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const useCollections = ({filter, type = null, indexOnly = false}) => {
    const [callback, [data, loading, error]] = useLazyQuery('collectionMany', FILTER_COLLECTIONS)
    useEffect(() => {
     
        if(filter?.siteId && !type) callback({filter})
        else {
            callback({
                filter: {
                    AND: [{...filter}, {type}]
                }
            });
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter?.siteId, type])

    const collections = indexOnly && Array.isArray(data) ? 
        data?.map(c => ({...c, entries: undefined, count: c?.entries?.length})) :
        data;

    return [
        collections, 
        loading, 
        error,
        callback
    ];
}


export const useCollectionStats = ({siteId}) => {
    const [collections, loading, error] = useCollections({filter: {siteId}})
    if(!collections) return {schemas: 0, entries: 0}
    let entries = 0;
    if(collections?.length >= 1) {
        collections.map(c => {
            if(c.entries?.length >= 1) entries += c.entries.length;
        })
    }
    return {
        schemas: collections?.length,
        entries
    }
}

export const useCollectionSelector = (val) => {
    const siteId = useSelector(siteIdSelector)
    const [collections] = useCollections({filter: {siteId}, indexOnly: true})
    const [value, onChange] = useState(val)

    const options = collections?.length >= 1 ? collections.map(c => ({
        value: c._id,
        label: `${c?.title}` //[${c?.entries?.length ? c?.entries?.length : 0} entries]
    })) : [{value: null, label: 'Create other collections first'}]
    
    useEffect(() => {
        onChange({value: val})
    },[val])
    return {
        options,
        value,
        onChange
    }
}