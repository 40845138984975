const ID = '[map]'
export const MAP_ID = ID;

const MAP_ADD              = `${ID} Add`;
const MAP_SELECT           = `${ID} Select`;
const MAP_MOVE             = `${ID} Move`;
const MAP_UPDATE           = `${ID} Update`;
const MAP_REMOVE           = `${ID} Remove`;

export const addMapAction = ({itemMap, dropPath}) => ({
    type: `${MAP_ADD} ${itemMap.itemType}`,
    payload: {item: itemMap, dropPath},
    meta: {feature: ID}
});
 
export const selectMapAction = ({name}) => ({
    type: MAP_SELECT,
    payload: name,
    meta: {feature: ID}
});

export const clearMapAction = () => ({
    type: MAP_SELECT,
    payload: 'clear',
    meta: {feature: ID}
});


export const moveMapAction = ({item, itemPath, dropPath}) => ({
    type: `${MAP_MOVE} ${item.itemType}`,
    payload: {item, itemPath, dropPath},
    meta: {feature: ID}
});
export const updateMapAction = ({map}) => ({
    type: `${MAP_UPDATE}`,
    payload: map,
    meta: {feature: ID}
});
export const updateMapItemAction = ({itemMap, path}) => ({
    type: `${MAP_UPDATE} ${itemMap.type}`,
    payload: {itemMap, path},
    meta: {feature: ID}
});
export const removeMapAction = ({item}) => ({
    type: `${MAP_REMOVE} ${item.type}`,
    payload: item,
    meta: {feature: ID}
});



const MAP_ACTION_IDS = {
    MAP_ADD,
    MAP_SELECT,
    
    MAP_MOVE,
    MAP_UPDATE,
    MAP_REMOVE
};

export const mapActions = {
    addMapAction,
    selectMapAction,

    moveMapAction,
    updateMapAction,
    updateMapItemAction,
    removeMapAction
};

export default MAP_ACTION_IDS;