

// import { gql } from '@apollo/client';
import { gql } from '@apollo/client';

//TODO refactor to new composer method
export const ADD_BUILDER_ITEM = gql`
mutation builderItemCreateOne($record: CreateOneBuilderItemInput!) {
	builderItemCreateOne (record: $record) {
		recordId
	}
}
`;
