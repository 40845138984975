import { 
    useCreateCollection,
    useCreateEntry,
    useCreateMail
} from "./create";

import { 
    useUpdateCollection,
    useUpdateEntry,
    useUpdateMail,
    useUpdateUser
} from "./update";

import { 
    useRemoveCollection,
    useRemoveEntry
} from "./remove";


export {
    useCreateCollection,
    useCreateEntry,

    useUpdateCollection,
    useUpdateEntry,

    useRemoveCollection,
    useRemoveEntry,
}

export const collectionHooks = {
    create: useCreateCollection,
    update: useUpdateCollection,
    remove: useRemoveCollection
}

export const entryHooks = {
    create: useCreateEntry,
    update: useUpdateEntry,
    remove: useRemoveEntry
}

export const userHooks = {
    create: null,
    update: useUpdateUser,
    remove: null
}

export const mailHooks = {
    create: useCreateMail,
    update: useUpdateMail,
    remove: null
}