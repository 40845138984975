
import { HeadingTag } from "@JSX";
// import TextField from "@mosanic/fields/Fields";
const TextField = dynamic(() => import("@mosanic/fields/TextField/Fields"));
import dynamic from 'next/dynamic';

const Heading = ({editor, active = false}, children) => {
    const {source, handler} = editor || {source: {content: null}, handler: null};

    const handleInput = (value) => {
        if(value){
            handler({value, extend: 'content'})
        }
        
    }
    return active ?       (
         <TextField
            value={source.content}
            onChange={handleInput}
        />
    )  : (
        <HeadingTag level={source?.level ? source.level : '2'}>
            {source?.content ? source.content : 
                !children ? 'Heading' :
                children
            }
        </HeadingTag>
    )
}
export default Heading;