import { colors } from "@mosanic/styles";
import styled, { css } from "styled-components";

const CheckBoxWrapper = styled.label` 
    ${props => props?.index >= 1 && css` 
        margin-left: 10px;
    `}
`
const CheckBoxLabel = styled.span` 
    margin-left: 8px;
    padding-bottom: 4px;
    font-size: 14px;
`
const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`

const Icon = styled.svg`
    margin-top: -14px;
    fill: none;
    stroke: white;
    stroke-width: 3px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    transition: ease-in-out 0.2s all;
    padding: 2px;
    cursor: pointer;
    display: inline-block;
    margin-top: 5px;
    width: 17px;
    height: 17px;
    border: ${props => (props.checked ? `1.5px solid ${colors.util.primary.base}` : `1.5px solid ${colors.util.gray.base}`)};
    /* background: ${props => (props.checked ? colors.util.primary.base : colors.util.gray.lighter)}; */
    border-radius: 9px;
    transition: all 150ms;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px ${colors.util.primary.underlay};
    }

    ::after {
        transition: all ease-in-out 0.3s;
        content: '';
        display: block;
        margin: 0px;
        border-radius: 9px;
        width: 10px;
        height: 10px;
        ${props => props.checked && css` 
            background: ${colors.util.primary.base};
        `}
    }
    &:hover {
        background: ${props => props.checked ? colors.util.gray.light : colors.util.primary.lighter};
        box-shadow: 0 0 0 3px ${colors.util.primary.underlay};
        ::after {
            background: ${colors.util.gray.base};
            ${props => props.checked && css` 
                background: ${colors.util.primary.dark};
                transform: scale(1);
            `}
        }
    }

    /* ${Icon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    } */
`


const RadioButton = styled.div` 
    border: 2px solid ${colors.util.gray.light};
    transition: all ease-in-out 0.3s;
    border-radius: 5px;
    wrap: nowrap;
    display: flex;
    align-items: center

    border-radius: 5px;
    text-align: left;
    padding: 10px 25px;
    min-width: 100%;

    h3, h4, h5 {
        margin-bottom: 0!important;
    }

    &:hover {
        border-color: ${colors.util.primary.base};
        cursor: pointer;

        span {
            transform: scale(1.05);
        }
    }


    ${props => props.checked && css` 
        border-color: ${colors.util.primary.base};
        background: ${colors.util.gray.lightest};

        &:hover {
            border-color: ${colors.util.primary.dark};
        }
    `}
`

const RadioStyles = {
    CheckBoxWrapper,
    CheckBoxLabel,

    CheckboxContainer,
    Icon,
    HiddenCheckbox,
    StyledCheckbox,

    RadioButton,
}
export default RadioStyles;