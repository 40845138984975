import { Button, Code, TextArea } from "@mosanic/fields";
import { AdvancedLabel, Block, Label } from "@mosanic/items";

import { ControlPopper, HelpPopper } from "@mosanic/items/ControlPanel";
import { List, ListItem } from "@mosanic/items/List";

import { parseImageSrc } from "@mosanic/utils/Image";
import { evalReplaceValue } from "@mosanic/utils/value";
import { getFullTimeStamp } from "@util/Date";

import { capitalizeFirst, containsSubstr, excerptString, isString, replacePart, revertDevCases, toLowCase } from "@util/Text";
import { useState } from "react";
import slugify from "slugify";

// import { getFieldValue, getLatestFieldFromFieldPath } from "../../../fields/Source/helpers";
import { 
    getFieldValue, 
    getLatestFieldFromFieldPath, 
    hasPotentialSubFields 
} from "@mosanic/fields/Source/helpers";

import { locationFields } from "@mosanic/fields/Location/Location";
import { ChevronRightIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { getDevModePrefSelector } from "@redux/options/reducer";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import { Condition } from "@JSX";
import { get, hasAttr } from "@util/Obj";
import { createSchema } from "@mosanic/fields/Source/useSources";

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })

const CalculatorIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512" width="18px" height="18px"><path d="M72 160h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm5.09 247.6l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L128 390.63l28.29 28.29c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L150.63 368l28.29-28.29c3.12-3.12 3.12-8.19 0-11.31l-11.31-11.31c-3.12-3.12-8.19-3.12-11.31 0L128 345.37l-28.29-28.29c-3.12-3.12-8.19-3.12-11.31 0L77.09 328.4c-3.12 3.12-3.12 8.19 0 11.31L105.37 368l-28.29 28.28a8.006 8.006 0 0 0 .01 11.32zM448 16H32C14.33 16 0 30.33 0 48v416c0 17.67 14.33 32 32 32h416c17.67 0 32-14.33 32-32V48c0-17.67-14.33-32-32-32zM224 464H32V272h192v192zm0-224H32V48h192v192zm224 224H256V272h192v192zm0-224H256V48h192v192zm-152-80h40v40c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-40h40c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-40V88c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v40h-40c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0 256h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0-64h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8z"></path></svg>

const renderFieldData = (field, data) => {
    const img = parseImageSrc(data, true)?.thumb;
    if(img) return <img src={img} width="20px" height="20px"/>

    return isString(data) ? 
        data.length >= 200 ? `[text] with ${data.length} chars` : excerptString(data, 18, '...') : 
        data ? `[${field?.type}] with value` : data ? JSON.stringify(data) : `[${field?.type}]`
}
    
const getFieldLabel = (field, activeEntry, devMode) => (
    <Condition when={devMode} wrapper={children => (
        <ControlPopper contextOnly trigger={children}  width="400px">
            <ReactJson name='field' src={field} collapsed/>
            <ReactJson name="value" src={{data: activeEntry?.[field?.name]}}/>
        </ControlPopper>
    )}>
        <div style={{width: '100%'}}>
        <b>{capitalizeFirst(field?.name)}</b> <br/>
        {activeEntry ? renderFieldData(field, activeEntry?.[field?.name]) : ''}
        </div>
    </Condition>
)


const getFieldsSchema = (schema, activeEntry, devMode) => Array.isArray(schema) ? [
    ...schema?.map(field => ({
        label: getFieldLabel(field, activeEntry, devMode),
        // value: field?.name,
        value: field,
        schema: field?.schema
    }))
] : [
    'No fields available'
];

const getFieldSlug = (schema, activeEntry, baseRoute = '') => [{
    label: (
        <span>
            <b>Entry link</b> {!baseRoute && <HelpPopper span helpText='Prefix url with code'/>} <br/>
            {`>`} {baseRoute}/{excerptString(slugify(toLowCase(activeEntry?.[schema?.[0]?.name])), 7)}
        </span>
    ),
    value: {name: 'slug', type: 'slug'}
}]

const dateField = (label, preview, value) => ({
    label: (<span><b>{label}</b> <br/>{preview}</span>),
    value: {name: value}
})
const getFieldDates = (activeEntry, baseRoute = '') => [
    dateField('Created at', getFullTimeStamp(activeEntry?.date), 'date'),
    dateField('Updated at', getFullTimeStamp(activeEntry?.updatedAt), 'updatedAt')
]

const getFieldUsers = (schema, activeEntry) => activeEntry?.userId ? [{
    value: {name: 'userId'},
    label: (<span><b>User id</b> <br/>{activeEntry?.userId}</span>),
}] : []

const getFieldOptions = (schema, activeEntry, baseRoute, devMode) => {
    const base = [
        ...getFieldsSchema(schema, activeEntry, devMode),
        ...getFieldSlug(schema, activeEntry, baseRoute),
        ...getFieldDates(activeEntry, baseRoute),
        ...getFieldUsers(schema, activeEntry),
    ]
    return base?.length >= 3 ? [...base, {value: null, label: 'Unset'}] : base
}


const getFieldName = o => o?.value?.name

const SelectField = ({
    state = null,
    setState = null,
    collection = null,
    activeEntry = null,
    baseRoute = null,
    canSelectField = null,
    dataProvider = null,
    ...other
}) => {
 
    
    const getFieldPath = (field, index) => {
        if(index === 0) return [field];
        const copy = [...state?.fieldPath];
        const pathLength = state?.fieldPath?.length;
        // console.log(pathLength, index)
        if(pathLength > index){
            copy.length = index;
            return [...copy, field]
        }
        // console.log({field, newPath: [...state?.fieldPath, field]})
        // console.log([...state?.fieldPath, field])
        return  [...state?.fieldPath, field];
    }
    const setField = (field, index) => {
        const fieldName = field?.name;

        const fieldPath = getFieldPath(field, index)


        setState({
            ...state, 
            field: state.modus?.field != 'picker' ?
                {...state.field, field: fieldName} :
                fieldName,
            fieldPath
        })

    } 

    
    const canLoopSubFields = Boolean(state?.fieldPath && state?.fieldPath?.length >= 1);
    
    const devMode = useSelector(getDevModePrefSelector)

    const getOptions = (fieldSchema, entry, baseFields = true) => {
        const options = getFieldOptions(
            fieldSchema, 
            entry, 
            baseRoute,
            devMode
        )
        return baseFields ? options : options.filter(o => 
            !['date', 'updatedAt', 'slug', 'userId'].includes(o?.value?.name)
        )
    };
    // console.log(state?.schema, activeEntry)
    
    const isDisabledIndex = index => dataProvider?.fieldPath ? (dataProvider?.fieldPath?.length - 1) >= index : false

    const getFieldPathName = index => state?.fieldPath?.[index]?.name
    const isActiveField = (o, index) => getFieldPathName(index) === getFieldName(o)

    // console.log(getOptions(state?.schema, activeEntry))
    return (
        <> 
            <List>
                {/* {dataProvider?.fieldPath?.length && ( */}
                    <ListItem disabled>
                        <AdvancedLabel 
                            label={activeEntry ? "Entry fields " : "Schema fields "} span
                            helpText="If you've selected an entry or are using the page source, the schema fields will be populated with the correct data."
                        />
                    </ListItem>
                {/* )} */}
                {/* {state?.fieldPath?.length} */}
                    {getOptions(state?.schema, activeEntry)?.map((o, itemIndex) => (
                        <ListItem 
                            chevron={hasPotentialSubFields(o?.value) && <ChevronRightIcon />}
                            disabled={!canSelectField(o?.value?.type) || isDisabledIndex(0)}
                            active={isActiveField(o, 0)}
                            key={itemIndex} 
                            onClick={() => isDisabledIndex(0) ? null : setField(o.value, 0)}
                        >
                            {isString(o) ? o : o?.label} 
                        </ListItem>
                    ))}
            </List>
{canLoopSubFields && 
            <SubFields 
                path={state?.fieldPath}
                getOptions={getOptions}
                isActiveField={isActiveField}
                canSelectField={canSelectField}
                setField={setField}
                activeEntry={activeEntry?.data?.[state?.fieldPath[0]?.name]}
                // activeEntry?.
                //todo --> for next, get next active entry sub field
            />
        }
    {/* {JSON.stringify(state.fieldPath?.map(p => p?.name+' '))} */}
        {/* {canLoopSubFields && state.fieldPath.map((field, index) => (
            <SubField 
                key={index}
                field={field}
                getOptions={getOptions}
                isActiveField={(o) => isActiveField(o, index + 1)}
                canSelectField={canSelectField}
                setField={(field) => setField(field, index + 1)}
                activeEntry={activeEntry?.[field?.name]}
                //todo --> for next, get next active entry sub field
            />
        ))} */}

        </>
    )
}
const SubFields = ({path, setField, getOptions, isActiveField, canSelectField, activeEntry}) => {
    // let tempActive = activeEntry
    let copy = [...path]
    return path ? path?.map((field, index) => {
        
        copy.length = index + 1;
        //  tempActive = field?.type === 'repeater' ? tempActive?.[field?.name] : tempActive
        return (
            <>

            {/* {field?.name} */}
            <SubField 
                key={index}
                field={field}
                getOptions={(schema, entry) => getOptions(schema, entry, false)}
                isActiveField={(o) => isActiveField(o, index + 1)}
                canSelectField={canSelectField}
                setField={(field) => setField(field, index + 1)}
                // activeEntry={getFieldValue([...copy], activeEntry)}
                activeEntry={activeEntry?.[field?.name] ? activeEntry?.[field?.name] : activeEntry}
                //todo --> for next, get next active entry sub field
            />
            </>
        )
    }) : null

}



const SubField = ({field, setField, getOptions, isActiveField, canSelectField, activeEntry}) => {
    // activeEntry = field?.type === 'repeater' ? activeEntry?.[0] : activeEntry
    const type = revertDevCases(field?.singular ? field.singular : field?.label ? field.label : field?.name)
    
    let dupe = ``
    if(field.type === 'repeater') dupe = Array.from(new Set(activeEntry?.map(o => o?.[field?.schema?.[0]?.name]))).toString()


    return ['repeater', 'group'].includes(field?.type) ? (
        <>
    
         {field.type === 'repeater' && (
         <List>
                <ListItem disabled>
                    {activeEntry?.length}  {type} entries 
                </ListItem>
        {activeEntry?.length >= 1 && activeEntry?.map((o, itemIndex) => (
            <ListItem 
                chevron={<ChevronRightIcon />}
                key={itemIndex} 
                onClick={() => setField({type: 'index', value: `[${itemIndex}]`})}
            >
                {o?.[field?.schema?.[0]?.name] ? 
                    replacePart(o?.[field?.schema?.[0]?.name] , dupe, '...'): 
                    `${type} [${itemIndex}]`
                }
            
            </ListItem>
        ))}
        </List>
         )}

         {/* <List style={{minWidth: 400}}>
            {JSON.stringify(field)}
         </List> */}
        <List>
            <ListItem disabled>
                    {field?.singular} fields
                </ListItem>
            {getOptions(
                field?.schema,
                Array.isArray(activeEntry) ? activeEntry?.[0] : activeEntry
            )?.map((o, itemIndex) => (
                <ListItem 
                    active={isActiveField(o)}
                    disabled={!canSelectField(o?.value?.type)}
                    onClick={() => setField(o?.value)}
                    key={itemIndex} 
                >
                    {o?.label}
                    {/* {activeEntry} */}
                </ListItem>
            ))}
        </List>
        </>
    ): field?.type === 'relation' ? (
        <>
        <List>
            <ListItem disabled>
                Relation sub fields
            </ListItem>
            {relationSchema?.map((o, itemIndex) => (
                <ListItem 
                    active={isActiveField(o)}
                    disabled={!canSelectField(o?.value?.type)}
                    onClick={() => setField(o?.value)}
                    key={itemIndex} 
                >
                    {o?.label}
                </ListItem>
            ))}
            {JSON.stringify(activeEntry)}
        </List>
        </>
    ): field?.type === 'location' ? (
        <>
        <List>
            <ListItem disabled>
                location map fields
            </ListItem>

            {locationFields?.map((o, itemIndex) => (
                <ListItem 
                    active={isActiveField(o)}
                    disabled={!canSelectField(o?.value?.type)}
                    onClick={() => setField(o?.value)}
                    key={itemIndex} 
                >
                    {o?.label}
                </ListItem>
            ))}
        </List>
        </>
    ):  field?.type === 'user' ? (  
        <>
        <List>
            <ListItem disabled>
                User fields
            </ListItem>
            <ListItem>
                <b>Load user</b>
            </ListItem>
            {userSchema?.map((o, itemIndex) => (
                <ListItem 
                    active={isActiveField(o)}
                    disabled={!canSelectField(o?.value?.type)}
                    onClick={() => setField(o?.value)}
                    key={itemIndex} 
                >
                    {o?.label}
                </ListItem>
            ))}
        </List>
        </>
    ):  field?.type === 'image' ? (  
        <>
        <List>
            <ListItem disabled>
                Image fields
            </ListItem>
            {[...seoSchema, {label: 'File type', value: {type: 'text', name: 'fileType'}}]?.map((o, itemIndex) => (
                <ListItem 
                    active={isActiveField(o)}
                    disabled={!canSelectField(o?.value?.type)}
                    onClick={() => setField(o?.value)}
                    key={itemIndex} 
                >
                    {o?.label}
                </ListItem>
            ))}
        </List>
        </>
    ): null
}

export const relationSchema = [
    ...'relation|relationEnd|relationRender'.split('|').map(name => ({
        label: name,
        value: {
            type: 'text',
            name 
        }
    }))
]
export const userSchema = [
    ...'author.firstName|author.lastName|author.displayName|author.bio|author.biography|author.profilePicture|email|role|siteId|_id'.split('|').map(name => ({
        label: name,
        value: {
            type: 'text',
            name 
        }
    }))
]
export const seoSchema = [{
    label: 'Title',
    value: {
        type: 'text',
        name: 'seo.title'
    }
},{
    label: 'Description',
    value: {
        type: 'text',
        name: 'seo.description'
    }
},{
    label: 'Size Small',
    value: {
        type: 'text',
        name: 'sizes.small'
    }
},{
    label: 'Size Thumb',
    value: {
        type: 'text',
        name: 'sizes.thumb'
    }
}]

const convert = (schema, value) => {
    return [
        // ...createSchema(value?.data),
        ...schema.map(f => ({
            ...f, 
            title: f?.label?.split('.')?.length >= 2 ? f?.label?.split('.')[1] : f?.label, 
            // value: null, 
            value: undefined,
            name: f?.value?.name,
            label: get(value, f?.value?.name, f?.value?.name),
            // test: value
        }))
    ]
}
export const fieldSchemas = (value) => ({
    relation: convert(relationSchema, value),
    location: convert(locationFields, value),
    user: convert(userSchema, value),
    // user: createSchema(value),
    image: convert(seoSchema, value),

})
// relationSchema,
// locationFields,
// userSchema,
// seoSchema,
// gallerySchema

export const CodeFieldValue = ({setState, state, activeEntry}) => {
    // const value = state?.field?.field ? state.field.field : state.field
    let value = getFieldValue(state.fieldPath, activeEntry, state?.schema)
    const field = getLatestFieldFromFieldPath(state.fieldPath)

    const handleCode = code => setState({
        ...state, 
        field: {
            ...state.field, 
            code
        }
    });
 
    if(!containsSubstr(state?.field?.code, '{value}') && field?.type === 'text') handleCode(`{value}`) 
    const toggle = () => setCalculate(!calculate);
    const [calculate, setCalculate] = useState(true);

    const retrieved = value // === 'slug' ? '/entry-slug' : activeEntry?.[value]
    return (
        <Block p={3}>
            <AdvancedLabel as="h4" label="Append / prepend field with code" />
            <AdvancedLabel mb={4} span helpText="We hope to extend this in the near future with more complex calculations. Example shows: This product is on sale for €15">
                You can calculate a part of the string by adding a separator: | <br/>
                For example: &apos;This product is on sale for &euro;|{`{value}`} * 0.8 &nbsp;
            </AdvancedLabel>
            <TextArea 

                label="Code" 
                onChange={handleCode}
                value={state?.field?.code} 
                minRows={1}
                endAdornment={(
                    <Button 
                        mt={2} mr="-4px" ghost
                        tooltip={{title: "Calculate value", wrap: true}}
                        label={<CalculatorIcon />} 
                        active={calculate} onClick={toggle}
                    />
                )}
            />
            <Code label="Code" />
    {/* {JSON.stringify(state.schema?.[0])} */}
{/* {JSON.stringify({field, value})} */}
            <Label mt={2} ml={2} label="Output" value={evalReplaceValue(
                state?.field?.code, 
                value, 
                calculate
            )}/>
        </Block>  
    )
}

export default SelectField;