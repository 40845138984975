
import { isAuthenticated } from "@api/token";
import { useUpdateUser, useUser } from "@mosanic/components/User";
import { useDeleteUser } from "@mosanic/components/User/Handlers";
import { usePasswordValidation } from "@mosanic/components/User/Handlers/usePasswordValidation";
import { useUpdatePassword } from "@mosanic/components/User/Handlers/useUpdateUser";
import { RenderClientInfo, SecurityUser } from "@mosanic/components/User/SecurityUser";
import { TwoFactorAuth } from "@mosanic/components/User/TwoFactorAuth";
import { DeleteConfirm } from "@mosanic/core/Notifications";
import { TextField } from "@mosanic/fields";
import { Button, Buttons } from '@mosanic/fields/Button/Buttons';
import { AdvancedLabel, ControlDialog, Expander, LoadingDots } from "@mosanic/items";
import { DashCard } from "@mosanic/items/Page";
import { TrashIcon } from "@radix-ui/react-icons";
import { userIdSelector } from "@redux/auth/reducer";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";



export const UserSecurity = ({overwrite = {
  twoStepsVerification: true,
  passwordText:  "Your password and login to your account and manage your sites."
}}) => {

      const methods = useForm({mode: 'all'});

  const isAuth = isAuthenticated();
  const _id = useSelector(userIdSelector)

  const [user, loading, error] = useUser({_id})
  const [deleteAccount, dataDelete, loadingDelete, errorDelete] = useDeleteUser()


  const [update, dataUpdate, loadingUpdate, errorUpdate] = useUpdateUser()
  const [updatePassword, dataPass, loadingPass, errorPass] = useUpdatePassword()

  const [
    errorMessage,
    Validations,
    strength,
  ] = usePasswordValidation({
    password: methods.watch()?.password,
    repeat_password: methods.watch()?.repeat_password,
  })

  const [confirm, setConfirm] = useState(null)
  const [prevPassword, setPrevPassword] = useState(null)
  const cancelConfirm = () => setConfirm(false)

  const handleSubmit = (prev) => {
    if(methods.watch().repeat_password && !errorMessage) {
      if(!prev) setConfirm(true)
      else {
        updatePassword({
          _id: user?._id, 
          password: methods.watch()?.password, 
          oldPassword: prevPassword
        });
        update({
          _id: user?._id, 
          user: {...user, ...methods.watch()}
        })
        setConfirm(false)
      }
    } else {
      update({_id: user?._id, user: {...user, ...methods.watch()}})
    }
  }

  
  const [accountDeleteModal, setAccountDeleteModal] = useState(false)

  const dispatchDeleteAction = () => {
    deleteAccount({_id, user});
  } 

 useEffect(() => {
    if(user?._id != methods?.watch()?._id) {
      methods.reset({
        ...user,
      })
    }
  },[methods, user])



    return (
        <>
        <FormProvider {...methods} >
        {loading}
        {error}
        {isAuth && (
          <>
          <DashCard 
              header="Password"
              label={overwrite.passwordText}
              help="Note that once you save these changes an confirm with your old account password will be asked."
              render={<SecurityUser user={user} errorMessage={errorMessage} Validations={Validations} />}
            />
            {overwrite?.twoStepsVerification ? (
              <DashCard 
                mt={5}
                header="2-step verification"
                label="Protect your Mosanic account by enabling an extra layer of security when you log in with an email and password."
                help="This security is enabled by default, you can turn it only off if your password is longer than 10 characters."
                render={<TwoFactorAuth user={user} />}
              />
            ) : null}
          
            <DashCard
                mt={10}
                header="Terminate your account"
                label="This action is irreversible, all of your data will be deleted."
                help="⚠️ Your data will be deleted within 31 days, just make sure to check the consequences. Got a question? Contact us."
                render={(
                    <Expander trigger={<Button label="Yes, continue." small ghost/>}>
                    <Button ghost onClick={() => setAccountDeleteModal(true)} ml={0}>
                        <TrashIcon /> Delete account
                    </Button>
                    </Expander>
                )}
            />

            <RenderClientInfo />

            <Buttons style={{float: 'right', marginRight: '5%'}}>
                <Button 
                    label="Cancel"
                    type="submit"
                />
        
                <Button 
                    disabled={errorMessage}
                    mr={0} theme="primary"
                    label={(loadingPass || loadingUpdate) ? <LoadingDots /> : "Save changes"}
                    onClick={handleSubmit}
                />
            </Buttons>

              {errorUpdate || errorPass}
        
            <ControlDialog state={confirm} close={cancelConfirm} size="xs" title="Confirm password">
              <AdvancedLabel helpText="Can't remember your previous password? Please contact support@mosanic.io" span>
                Confirm your password change by entering your previous password. Note that this action will be applied instantly if you&apos;ve entered your previous password correct.
              </AdvancedLabel>
              <TextField wrap fullWidth label="Previous password" type="password" size="large"
                onChange={e => setPrevPassword(e.target.value)}
                value={prevPassword}
              />
              <Buttons mt={4}>
                <Button ghost label="Cancel" onClick={() => cancelConfirm}/>
                <Button ghost label="Confirm" disabled={!prevPassword || prevPassword?.length <= 6} theme="primary" onClick={() => handleSubmit(prevPassword)}/>
              </Buttons>
            </ControlDialog>
            <DeleteConfirm
                action={dispatchDeleteAction}
                actionState={loadingDelete || dataDelete}
                validateLabel="email"
                validateValue={user?.email}
                header="About to delete your account."
                message="We're sad to see your go. Continue below if you really want to delete your Mosanic account. Note that it will take up-to 31 days to fully remove all of your data. "
                state={accountDeleteModal}
                close={() => setAccountDeleteModal(false)}
            />
          </>
        )}
        </FormProvider>

        
        </>
    )
}