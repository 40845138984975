

const paddingTemplate = {
  top: { 
    base: '10px'
  },
  bottom: { 
    base: '10px'
  },
  left: { 
    base: '10px'
  },
  right: { 
    base: '10px'
  }
}
const verticalTemplate = {
  padding: paddingTemplate,
  display: {
    direction: {base: 'vertical'},
    wrap: 1
  }
}
const horizontalTemplate = {
  padding: paddingTemplate,
  display: {
    direction: {base: 'horizontal'},
  }
}
const containerTemplate = {
  padding: paddingTemplate,
}
const minHeightTemplate = {
  sizing: {
    minHeight: {
      base: '10px'
    }
  }
}
export const templates = {
  vertical: verticalTemplate,
  horizontal: horizontalTemplate,
  container: containerTemplate,
  height: minHeightTemplate,
}