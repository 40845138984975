
const register = {
    core: {
        type: "Schema type is not supported.",
        name: "Schema could not be registered. Name is undefined.",
        props: "Schema could not be registered. Props are undefined.",
    },
};

export const error = {
    register
}