
export const TYPE_ID                 = `[types]`;

export const SET_VALUE        = `${TYPE_ID} Set value`;
export const ADD_SOURCE      = `${TYPE_ID} Add source`;
export const REMOVE_SOURCE  =  `${TYPE_ID} Remove source`;
export const REGEN_SIZES      = `${TYPE_ID} Regen sizes`;
export const SET_FONTS          = `${TYPE_ID} Set fonts`;
export const TOGGLE_FIELD       = `${TYPE_ID} Toggle field`;
export const SET_FIELD       = `${TYPE_ID} Set field`;
export const UPDATE_FONT     = `${TYPE_ID} Update font`
export const SET_CHANGE       = `${TYPE_ID} Set change`;
export const RESET_TO_BASE      = `${TYPE_ID} Reset base`;

export const SET_TYPES       = `${TYPE_ID} Set types`;

export const TYPE_ACTION_IDS = {
    SET_VALUE,

    ADD_SOURCE,
    REMOVE_SOURCE,
    
    REGEN_SIZES,
    SET_FONTS,
    TOGGLE_FIELD,
    SET_FIELD,
    SET_CHANGE,
    UPDATE_FONT,
    RESET_TO_BASE,

    SET_TYPES
}
export const setTypesAction = (data) => ({
    type: SET_TYPES,
    payload: data,
    meta: {feature: TYPE_ID,}
})

export const setFontValue = ({field, value}) => ({
    type: SET_VALUE,
    payload: {field, value},
    meta: {feature: TYPE_ID,}
})
export const resetTypesAction = () => ({
    type: RESET_TO_BASE,
    meta: {feature: TYPE_ID,}
})

export const addFontSourceAction = ({family, weight, style, src}) => ({
    type: ADD_SOURCE,
    payload: {
        family, weight, style, src
    },
    meta: {feature: TYPE_ID,}
});
export const deleteFontSourceAction = ({src}) => ({
    type: REMOVE_SOURCE,
    payload: {
       src
    },
    meta: {feature: TYPE_ID,}
});
export const regenerateFontSizesAction = () => ({
    type: REGEN_SIZES,
    meta: {feature: TYPE_ID,}
});
export const setFontsAction = ({fonts}) => ({
    type: SET_FONTS,
    payload: fonts,
    meta: {feature: TYPE_ID,}
}) 
export const updateFontAction = ({font}) => ({
    type: UPDATE_FONT,
    payload: font,
    meta: {feature: TYPE_ID,}
}) 
export const toggleFontFieldAction = ({field, current}) => ({
    type: TOGGLE_FIELD,
    payload: {field, current},
    meta: {feature: TYPE_ID,}
})
export const setFontFieldAction = ({field, current, value}) => ({
    type: SET_FIELD,
    payload: {field, current, value},
    meta: {feature: TYPE_ID,}
})
export const setFontChangeAction = ({font, currentItem, addSource = true, fontType ='regular'}) => ({
    type: SET_CHANGE,
    payload: {
        font, 
        currentItem, 
        addSource, 
        fontType
    },
    meta: {feature: TYPE_ID,}
})