import { UpdateAction, UpdateField, UpdateItem } from "./action.enums";
import { updateCurrentProps, updateItemProps, updateItemsProps, updateProps } from "./action.types";
import { ItemPath, PathMetric, PathValue } from "./field.types";

const ID = '[update]'
export const UPDATE_ID  = ID;

const START             = `${ID} >`;
const HANDLE            = `${ID} ~`;

//Action type
const UPDATE            = `update`;
const PUSH              = `push`;
const POP               = `pop`;
const REMOVE            = `remove`;
const TOGGLE            = `toggle`;
const SET               = `set`;


//Selectors
const FIELD             = `field`;
const METRIC            = `metric`;
const METRICS           = `metrics`;
const FIELDS            = `fields`;

//Element
const CURRENT           = `current`;
const ITEM_PATHS        = `item paths`;
const ITEM_PATH         = `item path`;



const actionSelector = {
    update: UPDATE,
    push: PUSH,
    pop: POP,
    remove: REMOVE,
    toggle: TOGGLE,
    set: SET,

    start: START,
    handle: HANDLE
};

const fieldSelector = {
    field: FIELD,
    metric: METRIC,
    metrics: METRICS,
    fields: FIELDS,
}

const itemSelector = {
    current: CURRENT,
    item: ITEM_PATH,
    items: ITEM_PATHS,
}
export const updateIds = {
    action: actionSelector,
    field: fieldSelector,
    item: itemSelector,
}



// * Current inspect updaters
export const updateCurrentField = (value: PathValue, from?: string) => 
    updateCurrent({field: UpdateField.FIELD, value}, from);

export const updateCurrentFields = (value: [PathValue], from?: string) => 
    updateCurrent({field: UpdateField.FIELDS, value}, from);

export const updateCurrentMetric = (value: PathMetric, from?: string) => 
    updateCurrent({field: UpdateField.METRIC, value}, from);

export const updateCurrentMetrics = (value: [PathMetric], from?: string) => 
    updateCurrent({field: UpdateField.METRICS, value}, from);


export const updateCurrentToken = (value: PathMetric, from?: string) => 
    updateCurrent({field: UpdateField.METRIC, value}, from);

//Current handler
const updateCurrent = ({
    action = UpdateAction.UPDATE, 
    field,
    value,
}: updateCurrentProps, from?: string) => update({
    action, 
    item: UpdateItem.CURRENT, 
    field, 
    value
}, from);


// * Item update handlers
export const updateItemField = (
    value: PathValue, 
    itemPath: ItemPath
) => updateItem({
    field: UpdateField.FIELD, 
    value, 
    itemPath
});
export const updateItemFields = (
    value: [PathValue], 
    itemPath: ItemPath
) => updateItem({
    field: UpdateField.FIELD, 
    value, 
    itemPath
});
export const updateItemMetric = (
    value: PathMetric, 
    itemPath: ItemPath
) => updateItem({
    field: UpdateField.METRIC, 
    value, 
    itemPath
});
export const updateItemMetrics = (
    value: [PathMetric], 
    itemPath: ItemPath
) => updateItem({
    field: UpdateField.METRICS, 
    value, 
    itemPath
});

//Item handler
const updateItem = ({
    action = UpdateAction.UPDATE, 
    field, 
    value, 
    itemPath
}: updateItemProps) => update({
    action, 
    item: UpdateItem.ITEM_PATH,
    field, 
    value, 
    other: {itemPath}
});



// * Items update handlers
// todo

//Items handler
// const updateItems = ({
//     action = UpdateAction.UPDATE, 
//     field = UpdateField.FIELD, 
//     value, 
//     itemPaths
// }: updateItemsProps) => ({
//     type: `${ID} ${action} ${ITEM_PATHS} ${field}`,
//     payload: {value, itemPaths},
//     meta: {
//         feature: ID, 
//         item: ITEM_PATHS, 
//         action, 
//         field
//     },
// });




//Combining all handler
const update = ({
    action, 
    item, 
    field, 
    value, 
    ...other
}: updateProps, from?: string) => ({
    type: `${START} ${action} ${item} ${field}`,
    payload: Array.isArray(value) ? value : {
        ...value, 
        ...other
    },
    meta: {
        feature: ID, 
        item, 
        action, 
        field,
        from
    },
});
