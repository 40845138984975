import { revertDevCases } from "@util/Text"

export const baseSchemaFields = [{
//     name: 'siteId',
//     type: 'string',
//     disabled: true
// },{
    name: '_id',
    type: 'string',
    disabled: true
}]

export const convertColumnsToSchema = columnsSchema => columnsSchema.map(field => !field?.overview ? ({
    ...field,
    name: field?.slug ? field?.slug : field?.name ? field.name : field?.label,
    label: field?.label ? field.label : revertDevCases(field?.name),
    disabled: false
}) : null)