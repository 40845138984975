import { containsSubstr, replacePart } from "@util/Text";

/* Configuration imported from '.env' file */
const isServer = Boolean(process.env.NEXT_APP_DOMAIN_HOST);

const appHost = isServer ? process.env.NEXT_APP_DOMAIN_HOST : process.env.NEXT_PUBLIC_APP_DOMAIN_HOST;
const coreHost = isServer ? process.env.NEXT_CORE_DOMAIN_HOST : process.env.NEXT_PUBLIC_CORE_DOMAIN_HOST;

const imageLocation = isServer ? `https://${process.env.BUCKET_NAME}.${process.env.IMAGE_LOCATION}` : 
                                `https://${process.env.NEXT_PUBLIC_BUCKET_NAME}.${process.env.NEXT_PUBLIC_IMAGE_LOCATION}`

export const getImageUrl = (imgPath) => {
    if(!imgPath) return '';
    return containsSubstr(imgPath, 'data:image') ? imgPath : 
        `${imageLocation}${containsSubstr(imgPath, '/') ? imgPath : '/'+imgPath}`;
}

export const getCoreUrl = (slug) => slug ? `${coreHost}${slug}` : coreHost;
export const getAppUrl = (slug) => slug ? `${appHost}${slug}` : appHost;

export const getMailSecret = () => isServer ? 
    process.env.SENDGRID_KEY : 
    process.env.NEXT_PUBLIC_SENDGRID_KEY;
    

export const getSubDomainUrl = (slug, sub) => slug ? `${replacePart(appHost, 'app', sub)}${slug}` : replacePart(appHost, 'app', sub);
export const getDocsUrl = (slug) => getSubDomainUrl(slug, 'docs');
export const getNewsUrl = (slug) => getSubDomainUrl(slug, 'news');

export default appHost;
 
 