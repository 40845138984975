
import { feature } from '@redux/util';
import { produce } from 'immer';

import { HYDRATE } from 'next-redux-wrapper';
import { default as actions } from './action';

import {
    recoverCookie,
    deleteCookie,
    saveCookie
} from "@util/server/cookie";

import {
    deleteSession,
    recoverSession,
    saveSession as storeSession
} from "@util/server/session";
import { set } from '@util/Obj';
import { AUTH_TOKEN } from '@api/constants';
import { getDecodedToken, recoverToken } from '@api/token';




const initialState = {
    isAuth: false,
    remember: false,

    userData: null,
    userId: null,
    teamId: null,
    siteId: null,

    router: null,
};

const authReducer = (auth = initialState, action) => produce(auth, draft => {
    const pl = action.payload;

    switch (action.type) {
        case HYDRATE:
            return {...auth, ...action.payload, router: null};

        case actions.ROUTER_PUSH:
            draft.router = action.payload;
            return draft;

        case actions.REMOVE:
            return {
                ...initialState,
                router: pl?.redirect ? {
                    redirect: pl.redirect
                } : null
            }

        case actions.ACTIVATE:
            draft.isAuth = true;
            draft.userData = pl.userData;
            draft.remember = pl.remember;
            draft.router = null;
            return draft;

        case actions.SET_USER_ID:
            draft.userId = pl;
            return draft;

        case actions.SET_SITE_ID:
            draft.siteId = pl;
            return draft;

        default:
            return auth;
    }; //switch
});
export default authReducer;

export const authSelector = (state) => feature('auth', state);


export const isAuthSelector = (state) => getDecodedToken()?._id && authSelector(state).isAuth
export const expSelector = (state) => authSelector(state)?.userData?.exp
export const userSelector = (state) => authSelector(state)?.user
export const userIdSelector = (state) => getDecodedToken()?._id && authSelector(state)?.userId
export const siteIdSelector = (state) => authSelector(state).siteId
export const siteSelector = (state) => authSelector(state).site
 
export const routerSelector = state => authSelector(state).router;
