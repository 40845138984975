import { Items } from "@mosanic/constants/";
import { insert, remove, reorder } from "@util/Arr";

import { addChildToChildren } from "./add";
import { removeChildFromChildren } from "./remove";

export const handleMoveToDifferentParent = ({children, dropPath, itemPath, item}) => {

    // const COLUMN_STRUCTURE = {
    //   type: COLUMN,
    //   _id: columnId,
    //   children: [item]
    // };
  
    // const ROW_STRUCTURE = {
    //   type: ROW,
    //   _id: rowId
    // };
  
    switch (dropPath.length) {
      case 1: {
        // moving column outside into new row made on the fly
        // if (item.type === COLUMN) {
        //   newLayoutStructure = {
        //     ...ROW_STRUCTURE,
        //     children: [item]
        //   };
        // } else {
        //   if (item.type === STACK) {
        //     // alert('found container move - case 1')
        //   }
        //   // moving component outside into new row made on the fly
        //   newLayoutStructure = {
        //     ...ROW_STRUCTURE,
        //     children: [COLUMN_STRUCTURE]
        //   };
        // }
        break;
      }
      case 2: {
        // moving component outside into a row which creates column
        // if (item.type === COMPONENT || item.type === STACK) {
        //   newLayoutStructure = COLUMN_STRUCTURE;
        // } else {
        //   // moving column into existing row
        //   newLayoutStructure = item;
        // }
  
        break;
      }
      default: {
        // //Inside row?
        // if (item.type === STACK) {

        //   // alert('found container move  -- case DEFAULT WRONG !'+splitDropZonePath.length)
        // }
        // //Move component inside new column
        // newLayoutStructure = item;
      }
    }

    let map = removeChildFromChildren({children, itemPath});
    // map = handleAddColumDataToRow(updatedLayout, columnId);
    map = addChildToChildren({
        children: map,
        dropPath,
        item
    });
  
    return map;
  };

  export const handleMoveCreate = ({children, dropPath, itemPath, item}) => {
    //Row is going before previous row
    if(dropPath.length === 1 && dropPath[0] <= itemPath[0]){
      children = addChildToChildren({ children, dropPath, item }); 

      //Move index of itemPath one up
      let path = [parseFloat(itemPath[0]) + 1, ...itemPath.slice(1)];
      children = removeChildFromChildren({ children, itemPath: path });
    } else {
      //Start with adding (save because no new row)
      children = addChildToChildren({ children, dropPath, item }); 
      children = removeChildFromChildren({ children, itemPath });
    }
    return children;
  }