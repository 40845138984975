import { Pagination } from '@mosanic/cms';
import { substrSearch } from '@mosanic/core/Items/useItems';
import { useCurrentLocale } from '@mosanic/i18n/useLocale';
import { Spinner } from '@mosanic/items';
import { CenterCenter } from '@mosanic/styles';
import isBoolean from '@util/Boolean';
import isEmpty from '@util/Empty';
import { isString } from '@util/Text';
import dynamic from 'next/dynamic';
import { createContext, useEffect, useMemo, useState } from 'react';
import { default as Grid } from 'react-data-grid';
import styled from 'styled-components';
import EmptyRows from '../../components/Empty/EmptyRows';
import { initColumns } from './Columns';
import { useSite } from '@mosanic/components/Site';
import { useSelector } from 'react-redux';
import { siteIdSelector, userIdSelector } from '@redux/auth/reducer';
import { useListUsers } from '../Query/list';
import { useDataProvider } from '@mosanic/core/Provider';
import { useGetSite } from '../Query/byId';
import { getSiteUrl } from '@mosanic/utils/Site';
const ImgsViewer = dynamic(() => import("react-images-viewer"));


const DataGridWrapper = styled.div` 
    height: 100%;
    min-height: 100px;
    position: relative;
`
export const FilterContext = createContext(undefined);

function rowKeyGetter(row) {
  return row?._id;
}

const Center = styled.div` 
    position: absolute;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 3;
    background: rgba(255,255,255, 0.2);
    backdrop-filter: blur(2px);
    ${CenterCenter}
`
 
export const DataGrid = ({
    collection, 
    handles, 
    state, 
    
    deleteResponse, 
    pagination = null, 
    loading = null,

    props = null,
    domain = null,
}) => {
    const siteId = useSelector(siteIdSelector)
    const userId = useSelector(userIdSelector)

    const [site] = useGetSite({_id: siteId});
    // const customDomain = site?.customDomain
    const customDomain = getSiteUrl(site)
    const locale = useCurrentLocale()
    const siteNames = [] //useSiteNames()
    const {users} = useDataProvider()
    const userNames = useMemo(() => users?.map(u => u?.value === userId ? ({...u, current: true}) : u), [userId, users]) // useListUsers({siteId})
 
    // const [filters, setFilters] = useState({
    //     task: '',
    //     priority: 'Critical',
    //     issueType: 'All',
    //     developer: '',
    //     complete: undefined,
    //     enabled: false
    // });
    // const setFilter = (filter) => setFilters({...filters, ...filter})
    const [img, setImage] = useState(null)
    const initializeColumns = () => initColumns({
        userNames,
        collection, 
        handles: {...handles, setImage}, 
        filters: state?.filters, 
        locale, 
        siteNames,
        host: domain ? domain : customDomain ? `https://${customDomain}` : '', 
        props
    });

    const [rows, setRows] = useState(Array.isArray(collection?.entries) ? collection?.entries : []);

    const [columns, setColumns] = useState(initializeColumns())
    
    const [selectedRows, setSelectedRows] = useState(() => new Set());

    let filteredRows = useMemo(() => {
        return rows;
        // if(!state?.filters?.enabled || !rows) return rows;
        // const filters = {...state?.filters, enabled: undefined};

        // let filtered = rows;
        // Object.entries(filters).map(([key, value]) => {
        //     if(isBoolean(value) && value) filtered = filtered.filter(r => !isEmpty(r?.[key]))
        //     if(isString(value) && value?.length >= 1) filtered = filtered.filter(r => substrSearch(r?.[key], value))
        //     if(key === 'date') filtered = filtered.filter(r => substrSearch(r?.[key], value))
        // })
        // return filtered;
    }, [
        rows,
        state?.filters,
        state?.order
    ])
    
    // filteredRows = Array.isArray(filteredRows) && filteredRows?.length >= 2 ? filteredRows?.sort((a,b) => state?.order === 'desc'? 
    //     new Date(b?.date) - new Date(a?.date) : 
    //     new Date(a?.date) - new Date(b?.date)
    // ) : filteredRows;
    
    useEffect(()=>{
        if(collection) {
            setColumns(initializeColumns())
            setTimeout(() => {
                setRows( Array.isArray(collection?.entries) ? collection?.entries : [])
            }, 100);
        }
    },[collection, handles, locale, userNames, customDomain, domain])




    //Handle remove deleted
    useEffect(() => {
		if(deleteResponse?.recordId) 
			setRows((r) => r.filter((x) => x._id != deleteResponse?.recordId));
	}, [deleteResponse?.recordId])

    
    return rows ? (
        <DataGridWrapper>

        {img && (
        <ImgsViewer
            imgs={[img]}
            spinner={() => <Spinner/>}
            currImg={0}
            isOpen={img}
            // onClickPrev={() => setIndex(index - 1)}
            // onClickNext={() => setIndex(index + 1)}
            onClose={() => setImage(null)}
            backdropCloseable={true}
        />)}
        {/* {JSON.stringify(props?.routing)} */}
        <FilterContext.Provider value={state?.filters} >
            {loading && <Center><Spinner /></Center>}
            <Grid 
                className={`rdg-light ${state?.filters?.enabled ? '-filters' : ''}`}
                components={{ 
                    noRowsFallback: () => <h1>no rows</h1>
                }}
                rowKeyGetter={rowKeyGetter}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                columns={columns} 
                rows={filteredRows}
                // headerRowHeight={state?.filters.enabled ? 70 : undefined}
                // rows={rows} 
                rowGetter={i => filteredRows[i]}
                onRowsChange={setRows}
                style={{
                    background: 'transparent',
                    border: 'none',
                    height: '100%',
                    // maxHeight: '70vh'
                }}
                // summaryRows={[{name: 'title', render: pagination, formatter: () => 'hi'}]}
                // direction={}
            />
            {(!loading && filteredRows?.length <= 0) && (
                <EmptyRows 
                    plural={collection?.title ? collection.title : 'entries'} 
                    singular={collection?.singular ? collection.singular : 'entry'} 
                    {...props}
                    createCallback={handles?.open}
                /> 
            )}
            {pagination && filteredRows?.length >= 1 && <Pagination type="table" {...pagination} />}
        </FilterContext.Provider>
        </DataGridWrapper>
    ) : null
}