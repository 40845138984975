

import { REMOVE_COLLECTION } from "@api/mutations/collections";
import { useRemove } from "./useDataMutate";
import { REMOVE_ENTRY } from "@api/mutations/entry";

const useRemoveItem = ({mutation, type, siteCheck = true, ...other}) => {
    const [update, [data, loading, error]] = useRemove({
        mutation,
        type,
        silent: true,
        siteCheck,
        ...other
    });

    return [update, [data, loading, error]]
}


export const useRemoveCollection = ({...other}) => useRemoveItem({
    mutation: REMOVE_COLLECTION,
    type: 'collection',
    ...other
});



export const useRemoveEntry = ({...other}) => useRemoveItem({
    mutation: REMOVE_ENTRY,
    type: 'entry',
    ...other
});



