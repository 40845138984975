import { ButtonGroup } from "@mosanic/fields";
import { useState } from "react";

// Todo --> if options.length is to long, switch to select.

export const useTabs = ({tabs = [], tab = null, labelFormat = 'text', iconGroup = null}) => {
    const [current, setTab] = useState(tab);
    const [options, setOptions] = useState(tabs);

    const Tabs = () => (
        <ButtonGroup size="small">
            <ButtonGroup.Choices 
                value={current}
                handler={setTab}
                options={options} 
                labelFormat={labelFormat} 
                iconGroup={iconGroup}
            />  
        </ButtonGroup>
    );
    return {setOptions, current, Tabs};
}