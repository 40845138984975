

export const mousePositionClick = (event, contextMenu, setContextMenu, clear = true) => {
    setContextMenu(
        (contextMenu === null || !clear)
            ? {
                x: event.clientX - 2,
                y: event.clientY - 4,
            }
            : null,
        );
}