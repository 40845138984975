import { formatProduct } from "@mosanic/components/Membership/CombineData";
import { getPlanById, plans } from "@mosanic/content/membership";
import { ErrorAlert, Spinner } from '@mosanic/items';
import { cartDataAction } from "@redux/cart/action";
import { 
    activeContainProductSelector, 
    addonIdsSelector, 
    annualSelector, 
    cartDataSelector, 
    planIdsSelector, 
    productQuantitySelector,
    productsSelector
} from "@redux/cart/reducer";

import { fetchGetJSON, fetchPostJSON } from "@util/api/helpers";
import { removeUndefined, toObject } from "@util/Obj";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const getPrice = (prices, annual = true) => {
    const find = (annual ? 'year' : 'month');
    if(!Array.isArray(prices)) return prices;

    return prices.find(p => (p?.recurring?.interval ?  
        p.recurring.interval : 
        p.interval
    ) === find) 
}

export const parsePrice = (prices, annual) => getPrice(prices, annual)?.price
export const altParsePrice = (prices, annual) => {
    const alt = getPrice(prices, !annual)?.price
    return annual ? alt * 12 : alt / 12;
}

const isLower = (product, active) => plans[getPlanById(active)?.value] > plans[product?.value]
const isPlan = product => product?.metadata?.type === 'plan' || product?.category === 'plan'
const isAddon = product => !isPlan(product) && product?.category === 'addon'
const isUser = product => !isPlan(product) && !isPlan(product) && product?.category === 'user'

const parseProductType = product => 
    isPlan(product) ? 'plan' :
    isAddon(product) ? 'addon' :
    isUser(product) ? 'user' :
    'custom'

const useProduct = (product) => {
    const annual = useSelector(annualSelector);
    const active = useActivePlan();
    product = {
        ...product,
        quantity: useSelector(state => productQuantitySelector(state, product?.id))
    }
   
    switch (parseProductType(product)) {
        case 'plan': product = {
            ...product,
            plan: true,
            isLower: isLower(product, active)
        } 
            break;
        case 'addon': product = {
            ...product,
            addon: true
        }
            break;
        case 'user': product = {
            ...product,
            user: true
        }
            break;

        default:
            break;
    }
    return product;
}

const useActivePlan = (activePlanId = null) => {
    const storePlanId = useSelector(state => activeContainProductSelector(state, planIdsSelector(state)))
    if(!activePlanId) activePlanId = storePlanId;

    const priceData = useSelector(state => cartDataSelector(state, 'data'))
    const plan = activePlanId ? {
        ...getPlanById(activePlanId),
        ...priceData[activePlanId]
     } : null
    return plan ? formatProduct(plan) : plan
}

const useActiveAddons = (addons, products = null) => {
    const storeProducts = useSelector(productsSelector)
    if(!products) products = storeProducts;

    products = removeUndefined(products);

    const ids = useSelector(addonIdsSelector)
    const priceData = useSelector(state => cartDataSelector(state, 'data'))
    const addonIds = products ? Object.keys(products)?.filter(id => ids?.includes(id)) :[];

    return addonIds.map(id => formatProduct({
        ...addons.find(a => a.id === id),
        ...priceData[id],
        count: products[id]
    }))
}

const combineProductPrices = (products, prices) => products.data.map(product => ({
    ...product,
    prices: prices.data.filter(price =>  price.product === product.id)
}));

const getIds = products => products.map(p => p?.id)

export const useProducts = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [plans, setPlans] = useState([]);
    const [addons, setAddons] = useState([]);

    // const getProducts = async () => {
    //     setLoading(true);
    //     await fetchGetJSON('/api/shop/products').then(val => {
    //         const {products, prices} = val;
    //         const data = combineProductPrices(products, prices);

    //         const plans = data.filter(p => p?.metadata?.type === 'plan');
    //         const addons = data.filter(p => p?.metadata?.type != 'plan');


    //         const priceData = toObject(data.map(p => ({
    //             ...p,
    //             point: undefined,
    //             type: p?.metadata?.type === 'plan' ? 'plan' : 'addon'
    //         })), 'id');

    //         dispatch(cartDataAction('plans', getIds(plans)))
    //         dispatch(cartDataAction('addons', getIds(addons)))
    //         dispatch(cartDataAction('data', priceData))
            
    //         setAddons(addons);
    //         setPlans(plans);

    //     }).catch(err => setError(err));
    //     setLoading(false);
    // }


    // sk_live_wPlFpSrxi1qInTthQaSZa4m3
// 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
    const getProductsSoo = async () => {
        setLoading(true);
        await fetchPostJSON('/api/shop/products', {
            key: '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'
        }).then(val => {
            const {products, prices} = val;
            const data = combineProductPrices(products, prices);

            const plans = data.filter(p => p?.metadata?.type === 'plan');
            const addons = data.filter(p => p?.metadata?.type != 'plan');


            const priceData = toObject(data.map(p => ({
                ...p,
                point: undefined,
                type: p?.metadata?.type === 'plan' ? 'plan' : 'addon'
            })), 'id');

            dispatch(cartDataAction('plans', getIds(plans)))
            dispatch(cartDataAction('addons', getIds(addons)))
            dispatch(cartDataAction('data', priceData))
            
            setAddons(addons);
            setPlans(plans);

        }).catch(err => setError(err));
        setLoading(false);
    }

    useEffect(() => {
        // getProducts();
        getProductsSoo();
    },[])

    return [[
            plans, 
            addons
        ], 
        loading ? <Spinner/> : null, 
        error ? <ErrorAlert errorMessage={error} /> :null
    ];
}

export {
    useActiveAddons,
    useActivePlan,
    useProduct
};
