import { Condition } from '@JSX';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor } from 'slate';
import { Editable, Slate } from 'slate-react';
import { EditorContainer, EditorLabel } from './Editor.style';
import { Elements } from './Elements';
import { BeforeInput, KeyDown } from './Handler';
import { getInitialContent } from './Handler/Content';
import { Leaf } from './Leaf';
import withPlugins from './Plugins';
import withSingleLine from './Plugins/SingleLine';
import Toolbars from './Toolbars';


const isMobileDevice = e => {
  if (typeof window === "undefined") return false;
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

const Editor = ({
  content, 
  setContent, 
  fullToolbar = true, 
  readOnly = false, 
  ninja = false, 
  autoFocus = true, 
  singleLine = false,
  location = 'auto',
  endSource = null,
  translate = false,
  label = null,
  fieldName = null,
  name = null,
  disabled = false,
}) => {
  //  const editor = useMemo(() => singleLine ? 
  //     withSingleLine(withPlugins(createEditor())) : 
  //     withPlugins(createEditor())
  // , [singleLine])

  const editorRef = useRef()
  if (!editorRef.current) editorRef.current = singleLine ? 
    withSingleLine(withPlugins(createEditor())) : 
    withPlugins(createEditor())
const editor = editorRef.current

// useEffect(() => {
//   editorRef.current = singleLine ? 
//     withSingleLine(withPlugins(createEditor())) : 
//     withPlugins(createEditor())
// },[singleLine])
  // const [editor, setEditor] = useState()

  // useEffect(() => {
  //   setEditor(singleLine ? 
  //     withSingleLine(withPlugins(createEditor())) : 
  //     withPlugins(createEditor())
  //   );
  // }, [singleLine, setContent])
  if(!content) setContent(getInitialContent('Text', 'p'))



  const handleBeforeInput = (e) => BeforeInput(e)
  const handleKeyDown = (e) => disabled ? null : KeyDown(e, editor);
  const renderElement = useCallback(props => <Elements {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  if(!editor) return '...';


  return editor ? (
    <Condition when={ !ninja && !singleLine}
      wrapper={children => (
        <EditorContainer disabled={disabled}>
            <EditorLabel shrink>
            {
              label ? label :
              fieldName ? fieldName : 
              name ? name : ''
            }
          </EditorLabel>
          {!fullToolbar && <div style={{height: '5px'}}></div>}
          {children}
          </EditorContainer>
      )}
    >
      <Slate editor={editor} value={content} onChange={disabled ? () => null : setContent}>
        <Toolbars full={fullToolbar} location={location} endSource={endSource}/>
        <Editable 
          className={ninja ? '-ninja' : ''}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter text..." 
          onDOMBeforeInput={isMobileDevice() ? null : handleBeforeInput}
          onKeyDown={handleKeyDown}


          readOnly={readOnly || disabled}
          autoFocus={!readOnly && autoFocus}
          spellCheck
        />
      </Slate>
    </Condition>
  ) : null
}


const renderEditor = (props) => {
    const { children, editor } = props

    const wordCount = 2
    // const wordCount = countWords(editor.value.text)
    return (
        <>
            {children}
            <span className="word-count">{wordCount}</span>
        </>
    )
};



export default Editor