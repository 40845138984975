import { ObjectID } from "@mosanic/utils";
import { isStartSubString } from "@util/Text";
import { 
    removeNotification, 
    setNotification, 
    SET_NOTIFICATION, 
    TIMING_DEFAULT_VARIANT, 
    TIMING_DELETE_NOTIFICATION 
} from "./action";
import { getNotificationsSelector } from "./reducer";

export const notificationExists = (notifications, filter) => notifications.filter(n => isStartSubString(n?.message, filter))?.length >= 1

/**
 * Notification enricher
 * @returns next action
 */
const notificationMiddleware = ({getState, dispatch}) => next => action => {
    if(action?.type?.includes(SET_NOTIFICATION)) {
        const {payload, meta} = action; 
        let {
            duration,
            variant,
            persist,
            position,
            delay,
        } = meta

        const _id = ObjectID();

        if(
            meta?.filter && 
            notificationExists(
                getNotificationsSelector(getState()),
                meta.filter
            )
        ) {
            return null;
        }
        const notification = {_id, message: payload, duration, variant, persist, position, time: new Date()}

        setTimeout(() => {
            next(setNotification({message: notification, feature: meta.feature}))
        }, delay);

        
    } else {
        next(action);
    }
}
export const notificationMdl = [
    notificationMiddleware
]