import { PUBLIC_SITE, PUBLIC_SITES } from "@api/queries/sites";
import { PUBLIC_USERS } from "@api/queries/users";
import { isFunc } from "@util/Funct";
import { combineString } from "@util/Text";
import { combine, getParams, useSiteId } from ".";
import { useGet } from "..";

const useGetManyPublic = ({params, query, type, formatter = null, method = 'Many', ...other}) => {
    const siteId = useSiteId()
    params = combine(params, other, 'limit');
    params = combine(params, other, 'skip');
    if(other?.current) params = {filter: {siteId}}

    const [get, [data, loading, error]] = useGet({
        method,
        params,
        query,
        type,
        silent: true
    });

    return isFunc(formatter) ? 
        [Array.isArray(data) ? data?.map(e => formatter(e)) : data, loading, error, get] :
        [data, loading, error, get]
}


export const useGetUsersPublic = ({filter, siteId = undefined, ...other}) => useGetManyPublic({
    params: getParams({filter, siteId}),
    query: PUBLIC_USERS,
    type: 'user',
    formatter: u => ({
        ...u,
        fullName: combineString([
            u?.author?.firstName,
            u?.author?.lastName
        ])
    }),
    ...other
})


export const useGetSitesPublic = ({filter, siteId = undefined, ...other}) => useGetManyPublic({
    params: filter ? filter : {_id: siteId},
    query: siteId ? PUBLIC_SITE : PUBLIC_SITES,
    method: siteId ? 'ById' : undefined,
    type: 'site',
    ...other
})