import { isUrl } from "@util"
import { Wrap } from "../Handler"

const withInlineElements = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element =>
    ['link', 'button'].includes(element.type) || isInline(element)

  editor.insertText = text => {
    if (text && isUrl(text)) {
      Wrap.wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      Wrap.wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}



export default withInlineElements;