import { BuilderSubNav } from "@mosanic/constants/nav.sub.builder";
import { useNotify } from "@mosanic/hooks";
import { clearPreStoreAction, handleCopyAction, handleCutAction, handleDeleteAction, handlePasteAction } from "@redux/map/drop/action";
import { setSidebarPanelAction } from "@redux/navigation";
import { isTouchDevice } from "@util/Device";
import { useDispatch } from "react-redux";


export const useMapItemDelete = (validate = true) => {

    const dispatch = useDispatch();
    const notify = useNotify();
    const del = () => {
        dispatch(handleDeleteAction({item: null}));
        notify(`Component deleted.`, 'controls', 'success');
    }
    return del
  }

export const useMapItemCopy = (validate = true) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const copy = () => {
        dispatch(handleCopyAction({preStore: !isTouchDevice()}))
        notify(`Component copied, click dropZone to paste.`, 'controls', 'info');
    }
    return copy
}
export const useMapItemPaste = (validate = true) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const paste = () => {
        dispatch(handlePasteAction())
        notify(`Component pasted.`, 'controls', 'info');
    }
    return paste
}

export const useMapItemCut = (validate = true) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    return () => {
        dispatch(handleCutAction())
        notify(`Component cut, click to paste.`, 'controls', 'info');
    }
}

export const useEscAction = () => {
    const dispatch = useDispatch();
    const cancel = () => {
        dispatch(clearPreStoreAction())
    }
    return cancel
}
export const useOpenAction = () => {
    const dispatch = useDispatch();
    const open = (panel = BuilderSubNav[0]) => {
        dispatch(setSidebarPanelAction({panel}));
    }
    return open
}
export const useMapControls = (validate = true) => {
    const del =  useMapItemDelete(validate);
    const copy = useMapItemCopy(validate);
    const paste = useMapItemPaste(validate);
    const cut = useMapItemCut(validate);
    const open = useOpenAction(validate);
    const esc = useEscAction();
    
    return { del, copy, paste, esc, open, cut}
}