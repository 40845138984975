import { Col, colors, Wrap } from "@mosanic/styles";
import { isString } from "@util/Text";
import { Card } from "@mosanic/items"
import { Condition } from "@JSX";

const Columns = ({columns, equal = false, cols = 'auto'}) => {
    const columnWidth = cols === 'auto' ? (
        equal ? 
            `${100 / columns?.length}%`
        : columns?.length === 4 ? [
            '30%', '25%', '25%', '20%',
        ] : columns?.length === 3 ? [
            '35%', '35%', '30%',
        ] : columns?.length === 2 ? [
            '50%', '50%'
        ] : columns?.length === 1 ? [
            '100%'
        ] : [
            'auto'
        ]
    ) : `${100 / cols}%`;

    const desktop = i => isString(columnWidth) ? columnWidth : columnWidth[i]
    return (
        <Wrap>
            {columns?.map((column, i) => (
                <Col 
                    key={i}
                    width={[1, 1/2, 1/2, desktop(i)]}
                    py={[2, 4]}
                    px={[0, 2, 4]}
                >
                    {column}
                </Col>
            ))}
            
        </Wrap>
    )
};

export const Column = ({children, card = false, padding, width, maxWidth, ...other}) => (
    <Col {...padding}  width={width} maxWidth={maxWidth}>
        <Condition when={card} wrapper={children => (
            <Card mb={4} {...other}>
                {children}
            </Card>
        )}>
            {children}
        </Condition>            
    </Col>
)

export const TwoColumns = ({first, second, after, card = false, ...other}) =>  (
    <Wrap {...other}>
        <Column card={card} mr={[0, 4]} padding={{ pr:[0,2] }} width={[1, 1/2]}>
            {first}
        </Column>
        <Column card={second && card} ml={[0, 4]} padding={{ pl:[0,2] }} width={[1, 1/2]} >
            {/* maxWidth="600px" */}
            {second}
        </Column>
    </Wrap>
)





export default Columns;