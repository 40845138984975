import { FormItems } from '@mosanic/fields/Form';
import Input from '@mosanic/fields/Input';
import { InputGroup } from '@mosanic/items';
import { useFormContext } from 'react-hook-form';


export const EditUser = ({}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } }= useFormContext();
	const inputProps = {control, errors}

	return (
        <InputGroup >
            <FormItems controls={inputProps} fields={[{    
                name: 'author.profilePicture',
                label: 'Profile image',
                type: 'image',
                fullWidth: true
            },{    
            //     name: 'author.banner',
            //     label: 'Banner color',
            //     type: 'color',
            // },{
                name: 'author.firstName',
                label: 'First name',
                rules: {required: "You can't clear your first name."},
            },{    
                name: 'author.lastName',
                label: 'Last name',
                rules: {required: "You can't clear your last name."},
            },{    
                name: 'email',
                label: 'E-mail',
                rules: {required: "You'r email can not be changed."},
                disabled: true,
                fullWidth: true
            }]}
            />


        </InputGroup>
	);
};