import { dataSelector } from "../map/data/reducer";
import { getScreensSelector } from "@redux/screens/reducer";
import { get } from "@util/Obj";

import { createSelector } from "reselect";
import { inspectSelector } from "./reducer";


const parameters = (state, obj) => obj;

const isScreen = (inspect) => inspect?.group === 'screens';
const hasInspect = (inspect) => (inspect?._id && inspect?.group);
const getScreen = (screens, _id) => screens.filter(screen => screen._id === _id)[0]
 
//Get element from group
export const inspectedItemSelector = createSelector([ 
    dataSelector, 
    getScreensSelector, 
    state => state.present.inspect 
],( data, screens, inspect ) => hasInspect(inspect) ? (
        isScreen(inspect) ? 
            getScreen(screens, inspect._id) : 
            data[inspect.group][inspect._id]
    ) : {
        _id: null, 
        type: null, 
        itemType: null
    }
);


//Get attr from inspect
export const inspectAttrSelector = createSelector(
    [ 
        state => state.present.inspect , 
        parameters 
    ],
    ( inspect, attr ) => (inspect?._id && inspect?.group) ? 
        inspect[attr] : 
        null
);

//Get from data reducer
export const inspectedDataSelector = createSelector(
    [ inspectedItemSelector, parameters ],
    ( item, path ) => item ? get(item, path) : null
);



