import { ControlDialog, InputGroup, LoadingDots, Tooltip } from "@mosanic/items";
import { Button, Buttons, TextField } from "@mosanic/fields";
import { trash } from "@mosanic/Icons/EditIcons";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Value = styled.b`
    user-select: none;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    font-size: 12px;
    vertical-align: middle;
    padding: 0 4px;
    position: relative;
    display: inline-block;

    &::after{
        content: '';
        position: absolute;
        display: inline-block;
        top: 0;
        left: 2px;
        right: 2px;
        bottom: 0;
        user-select: none;
        background: rgba(0,0,0,0.05);
    }
`
export const DeleteConfirm = ({
    action, 
    actionState = false, 
    validateLabel = 'Site name',
    validateValue = null,
    header = 'About to perform a irreversible action.',
    message = '', 
    state, 
    close
}) => {
    const [value, setValue] = useState('')
    useEffect(() => {
        setValue("")
    }, [state])
    return (
        <ControlDialog state={state} close={close}>
            <form
                style={{textAlign: "center"}}
                onSubmit={async (event) => {
                    event.preventDefault();
                    action();
                    // await deleteSite(siteId);
                }}
            >
            <h2>⚠️ {header}</h2>
    
                <p style={{maxWidth: '80%', margin: '0 auto 10px'}}>
                {message}
                <br/> <br/>
                {validateValue && (<>
                    Re-enter {validateLabel} 
                    <Tooltip title="You can't copy this value.">
                        <Value>{validateValue}</Value>
                    </Tooltip> 
                    to confirm.
                </>)}

                </p>
                <InputGroup style={{maxWidth: '50%', margin: '0 auto 10px'}}>
                {validateValue && (
                    <TextField
                        onChange={(e) => setValue(e.target?.value)}
                        value={value}
                        wrap fullWidth
                        type="text"
                        label={validateLabel}
                        placeholder={validateValue ?? ""}
                        pattern={validateValue ?? validateLabel}
                    />
                )}
                </InputGroup>
  
            <Buttons>
                <Button
                    type="button"
                    onClick={close}
                    size="large"
                    label="No, Cancel"
                />
 
    
                <Button
                    type="submit"
                    disabled={validateValue  && (actionState || value != validateValue)}
                    theme="orange"
                >
                {trash} {actionState ? <LoadingDots /> : "Yes, Delete"}
                </Button>
            </Buttons>
            </form>
        </ControlDialog>
    )
}