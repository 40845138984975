import {default as s} from "./Skeleton.styles"
import { Card } from "@mosanic/items";

const SkeletonLine = ({trans = false, as = 'span', ...other}) => (
    <s.SkeletonLine trans={trans} as={as} {...other}/>
);
const SkeletonList = ({trans = false, count = 5, ...other}) => (
    <div style={{paddingLeft: '20px'}}>
        {Array.from({ length: count }, (_, i) => (
            <s.Item mt={4} key={i} as="div">
                <s.SkeletonLine trans={trans} {...other}/>
            </s.Item>
        ))}
    </div>
);
const SkeletonCard = ({trans = false, ...other}) => (
    <Card {...other}>
        <s.SkeletonCard trans={trans} />
    </Card>
);
const Skeleton = ({trans = false, ...other}) => (
     <s.SkeletonCard trans={trans} as="div" full/>
);
const SkeletonCircle = ({trans = false, ...other}) => (
    <s.SkeletonCircle trans={trans} {...other}/>
);
export {
    SkeletonLine,
    SkeletonList,
    SkeletonCard,
    SkeletonCircle,
    Skeleton
}