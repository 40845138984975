import { containsSubstr } from "@util/Text";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Link from "../Link";
import { SkeletonLine } from "../Skeletons";

import { default as s } from "./Page.styles";
import styled, { css } from "styled-components";
import { colors } from "@mosanic/styles";

const SlugPart = styled.span` 
    font-size: 12px;
    ${props => props.isLink && css` 
        color: ${colors.util.primary.base};
    `}
`
const Separator = styled.span` 
    color: #444;
    background: #fff;
`
export const PageNav = ({
    slug= null, 
}) => {
    const router = useRouter()   
    return (
        <s.Nav>
            <s.NavContent>
                <s.NavSlug>
                    {router.back && router?.asPath != '/' && 
                        <s.NavBack onClick={() => router.back()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.854 3.854a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L3.207 7.5l3.647-3.646Zm6 0a.5.5 0 0 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L9.207 7.5l3.647-3.646Z" fill="#000"/></svg>
                        </s.NavBack>
                    }
                
                    {Array.isArray(slug) ? (
                        slug.map((s, index) => (
                            <SlugPart key={index} isLink={s?.href}>
                                {(s?.loading || containsSubstr(s?.href, '//')) ? (
                                <SkeletonLine /> 
                                ): s?.href ? 
                                    <Link href={s?.href}>{s?.label}</Link> :
                                    <a>{s.label}</a>
                                } 
                                <Separator>
                                {(slug?.length - 1) != index ? ' / ' : ''}
                                </Separator>
                            </SlugPart>
                        ))
                    ) : slug}
            
                </s.NavSlug>

            </s.NavContent>
        </s.Nav>
    );
}
