import { getNodeScale, getParentNode } from "./Node";
import { isString } from "./Text";

const getScaledPosition = (item, parent, scale) => (item && parent && scale) ? ({
    x: (item.getBoundingClientRect().x / scale - parent.getBoundingClientRect().x / scale),
    y: (item.getBoundingClientRect().y / scale - parent.getBoundingClientRect().y / scale),
    height: item.offsetHeight,
    width: item.offsetWidth
}) : null;



const getScaledPositionByParent = (ref, parentClass, scaleClass) => {
    const current = ref?.current;
    if(!current) return;
    const parentRef = getParentNode({parent: current?.parentNode, findClass: parentClass})
    if(!parentRef) return;
    const scale = isString(scaleClass) ?  getNodeScale(scaleClass) : scaleClass

    if(!scale) return;

    return getScaledPosition(current, parentRef, scale);
}



export {
    getScaledPosition,
    getScaledPositionByParent
}