
 
/**
 * If there isn't an action dispatched, prevent next.
 * @returns next action
 */
export const preventMiddleware = () => next => action => {
    if(!action || !action?.type) return;
    
    next(action);
}