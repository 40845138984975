import { remove } from "@util/Arr";
import { splitPath } from "./paths";

export const removeChildFromChildren = ({children, itemPath}) => {
    if(!Array.isArray(itemPath)) itemPath = splitPath(itemPath)
    if (itemPath.length === 1 && children?.length >= 1) {
        const itemIndex = Number(itemPath[0]);
        
        if(children?.length == 1){
            alert("Last child element removed")
        }
      
      return remove(children, itemIndex);
    }
  
    const updatedChildren = children ? [...children] : [];
    const curIndex = Number(itemPath.slice(0, 1));
  
    // Update the specific node's children
    const splitItemChildrenPath = itemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
   
    updatedChildren[curIndex] = {
        ...nodeChildren,
        children: removeChildFromChildren({
            children: nodeChildren?.children,
            itemPath: splitItemChildrenPath
        })
    };
  
    return updatedChildren;
};

