import { useEffect } from "react";
import { useTheme } from "./useTheme"
import { RenderFonts } from "../Typography";
import { FontSources } from "../Typography/RenderFontSources";
import { useDispatchActiveTheme } from "./useThemeDispatch";


const RenderTheme = ({themeId}) => {
    const [handler, [theme, loading, error]] = useTheme({_id: undefined})
   
    useEffect(() => {
        if(themeId) handler({_id: themeId})
    },[themeId])

    const {typography} = theme || {typography: null};
    useDispatchActiveTheme(themeId);
    return (
        <>
        {/* {(typography?.fonts && !error) && (
            <RenderFonts 
                fonts={typography?.fonts} 
                baseSize={typography?.baseSize} 
                sources={typography?.sources} 
            />
        )}  */}
    
        <RenderFonts baseSize={typography?.baseSize+'px'} fonts={typography?.fonts} sources={typography?.sources}/>
        </>
    )
}
export default RenderTheme; 