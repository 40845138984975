
//New items
export const SIDEBAR_ITEM = "sidebarItem";
export const SIDEBAR_STACK = "sidebarContainer";
export const SIDEBAR_REPEATER = "sidebarRepeater";
export const SIDEBAR_LAYOUT = "layoutItem";
export const SIDEBAR_PANEL = "sidebarPanel";

//Dynamic
export const ADD_ITEM = "addItem";

//Map items
export const ROW = "ROW";
export const COLUMN = "COLUMN";
export const STACK = "STACK";
export const COMPONENT = "COMPONENT";
export const CONTAINER = "CONTAINER";
export const REPEATER = "REPEATER";

const Items = {
    SIDEBAR_ITEM,
    SIDEBAR_STACK,
    SIDEBAR_REPEATER,
    SIDEBAR_LAYOUT,
    SIDEBAR_PANEL,

    ADD_ITEM,

    ROW,
    COLUMN,
    STACK,
    COMPONENT,
    REPEATER,
    CONTAINER
}
export default Items;