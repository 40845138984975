import { rgba, darken } from "polished";
import { breakpoints } from "./Device";
// import { colors } from "@mosanic/styles";
// todo Use polished in shades
const defaultColors = {
  primary: "#1082e9",
  primaryHover: "#1082e9", 
  secondary: "#f64b4b",
  light: "#fff",
  light10: "#FCFCFD",
  lightGray: '#e7e9ed',
  dark: "#313131",
  dark10: "#222222",
  yellow: "#fedc5a",
  ash: "#413e65",
  green: "#68d585 ",
  info: "#0e567c",
}; 

export const colorTemplates = {
    light: {
      primary: defaultColors.primary,
      primaryHover: darken(10, defaultColors.primaryHover),
      secondary: defaultColors.secondary,
      text: "#212121",
      light: defaultColors.light,
      light10: defaultColors.light10,
      lightGray: defaultColors.lightGray,
      lightShade: rgba(defaultColors.light, 0.7),
      dark: defaultColors.dark,
      dark10: defaultColors.dark10,
      darkShade: rgba(defaultColors.dark, 0.7),
      ash: defaultColors.ash,
      white: '#FFFFFF',
      bg: '#f2f2f2',
      bg2: "#f4f7fa",
      bg3: "#f8f8f8",
      bg4: "#fdfdff",
      bg5: "#ecf2f7",
      bg6: "#fff",
      bg7: "#EDF9F2",
      border: "#e7e9ed",
      shadow: rgba(defaultColors.dark, 0.15),
      heading: defaultColors.dark,
      // text: rgba(defaultColors.dark, 0.7),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
    dark: {
      primary: defaultColors.primary,
      primaryHover: defaultColors.primaryHover,
      secondary: defaultColors.secondary,
      text: "#FEFEFE",
      light: defaultColors.dark,
      light10: defaultColors.dark10,
      lightGray: '#111',
      lightShade: rgba(defaultColors.dark, 0.7),
      dark: defaultColors.light,
      dark10: defaultColors.light10,
      darkShade: rgba(defaultColors.light, 0.7),
      ash: defaultColors.ash,
      white: '#222',
      bg: '#323232',
      bg2: "#f4f7fa",
      bg3: "#f8f8f8",
      bg4: "#fdfdff",
      bg5: "#ecf2f7",
      bg6: "#fff",
      bg7: "#EDF9F2",
      border: "#e7e9ed",
      shadow: rgba(defaultColors.dark, 0.15),
      heading: defaultColors.dark,
      // text: rgba(defaultColors.dark, 0.7),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
  };
const modes = colorTemplates;

const colors = {
  primary: defaultColors.primary,
  primaryHover: darken(10, defaultColors.primaryHover),
  secondary: defaultColors.secondary,
  light: defaultColors.light,
  light10: defaultColors.light10,
  lightShade: rgba(defaultColors.light, 0.7),
  dark: defaultColors.dark,
  dark10: defaultColors.dark10,
  darkShade: rgba(defaultColors.dark, 0.7),
  ash: defaultColors.ash,
  bg: defaultColors.light,
  bg2: "#f4f7fa",
  bg3: "#f8f8f8",
  bg4: "#fdfdff",
  bg5: "#ecf2f7",
  bg6: "#fff",
  bg7: "#EDF9F2",
  border: "#e7e9ed",
  shadow: rgba(defaultColors.dark, 0.15),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.7),
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,
  gray: {
    darkest: '#2c2d30',
    darker: '#45464a',
    dark: '#5d5f64',
    base: '#7d818d',
    light: '#e7e9ed',
    lighter: '#f4f7fa',
    lightest: '#f8fafc',
    trans: '#fcfdfe',
  },

  modes
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [
    0,
    4,
    8,
    12,
    16,
    20, 
    24,
    28,
    32,
    36,
    40,
    48,
    52,
    56,
    60,
    64,
    72,
    84,
    100,
    120,
    140,
  ],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
