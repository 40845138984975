// import { handleRemoveItemFromLayout } from "../../components/Builder/Util/Layout/helpers";
import { produce } from 'immer';
import {default as actions } from "./action";

import { HYDRATE } from 'next-redux-wrapper';
import { set } from '@util/Obj';
import { featureSelector } from '@redux/baseSelector';


const initialState = {
    current: 'root',
    pages: {
        root: {
            name: 'root',
            seo: null,
        }
    }

};
 
const updatePage = (draft, action) => {
    const pageId = action.payload?._id;
    draft.current = pageId;
    draft = set(draft, `pages.${pageId}`, action.payload)
    return draft;
}
export const pageReducer = (pages = initialState, action) => produce(pages, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...pages, ...action.payload};

        case actions.UPDATE_PAGE:
            return updatePage(draft, action); 

        case actions.SET_PAGE:
            return updatePage(draft, action); 

        case actions.RESET_PAGES:
            return initialState;

        default:
            return pages;
    }; //switch
});
 
const pagesFeature = state => featureSelector(state, 'pages')
export const pageNameSelector = state => pagesFeature(state).current;
export const pageMetaSelector = state => pagesFeature(state)?.pages?.[pageNameSelector(state)];
export const pageMetaAttrSelector = (state, attr) => attr ? pageMetaSelector(state)?.[attr] : pageMetaSelector(state)



export const getPresentPages    = state => state?.pages?.entities ? state.pages.entities[PAGES_ID] : null;
export const getSelectedPageId  = state => getPresentPages(state)?.[0]._id;
export const getPagesIds        = state => getPresentPages(state) ? Object.keys(getPresentPages(state)) : null;

export const getSelectedPage    = state => {
    const pages = getPresentPages(state);
    const pageId = getSelectedPageId(state);

    return pages[pageId];
}

export const getPagesArray = state => {
    const pages = getPresentPages(state);

    return pages ? Object.keys(pages).reduce((pageArray = [], pageId) => {
        pageArray.push(pages[pageId])
        return pageArray;
    }, []) : null;
}

export const getFilteredPagesArray = state => {
    const pages = getPresentPages(state);
    
    return pages ? Object.keys(pages).reduce((pageArray = [], pageId) => {
        const {title, _id, author} = pages[pageId];

        pageArray.push({title, _id, author})
        return pageArray;
    }, []) : null; 
}