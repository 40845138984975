import { isClone } from "@mosanic/utils/value";
import isBoolean from "@util/Boolean";
import { getDate } from "@util/Date";
import { hasAttr } from "@util/Obj";
import { combineString, containsSubstr, isString, removeSpaces, slugify } from "@util/Text";


export const setBaseState = (value, onChange) => {

}





//Core retreivers
export const getCollection = (collections, collectionId) => collections?.length >= 1 ? (
    collectionId ? collections?.find(c => c._id === collectionId) : ({
        entries: collections
    })
): collections; 

export const getEntry = (collection, _id, clone) => (isClone(clone) && !_id) ? 
    collection?.entries?.[clone] :
    Array.isArray(collection?.entries) ? 
        collection?.entries?.find(e => e._id === _id) :
        null

export const getEntryLabel = (entry, schema, fallback = 'Entry') => {
    const selector = schema?.[0]?.name;
    const label = getEntryField(entry, selector, fallback);
    return label != fallback ? label : (
        entry?.label ? entry.label :
        entry?.name ? entry.name :
        entry?.value ? entry.value :
        fallback
    )
}
export const getEntryDate = (entry) => {
    const date = entry?.updatedAt ? entry.updatedAt : entry?.date ? entry.date : entry?.lastLogin ? entry.lastLogin : null;
    return date ? getDate(new Date(date)) : null
}

export const getEntryDescription = (entry, schema, fallback = '...') => {
    if(isString(entry?.description)) return entry;
    const temp = [...schema]
    const rmvFirst = temp.shift()
    const selector = temp?.filter(f => ['text', 'textarea'].includes(f?.type))?.[0]?.name;
    return getEntryField(entry, selector, fallback)
}

export const getEntryImage = (entry, schema, fallback = null) => {
    const selector = schema?.filter(f => ['image'].includes(f?.type))?.[0]?.name;
    return entry?.[selector] ? entry[selector] : fallback
}
const getEntryField = (entry, selector, fb) => {
    const value = hasAttr(entry, selector) ? entry[selector] : entry?.title;
    if(value || value?.en) {
        return isString(value) ? value : value?.en
    }
    return fb;
}


export const hasPotentialSubFields = field => ['repeater', 'group', 'user', 'location', 'image', 'relation'].includes(field?.type) 

//Render helpers
export const getFieldOptions = field => {
    let options = field?.values || field?.options;
    if(!options) return options;
    if(!Array.isArray(options)) options = options.split('\n');

    if(containsSubstr(options?.[0], ':')) options = options.map(o => ({
        value: removeSpaces(o.split(':')[0]), 
        label: o.split(':')?.[1] ? o.split(':')[1] : o.split(':')[0]
    }))   

    return options
}
export const getFieldRelation = field => (
    field?.type === 'relation' ? {
        relation: field.relation, 
        relationEnd: field?.relationEnd
    } : null
)
export const shouldPassSchema = field => {
    if( ['repeater', 'markedimage', 'group', 'column', 'select'].includes(field?.type) ) return true;
    return false;
}

export const shouldPassData = field => {
    if(field?.populateActive && field?.populateCode) return true;
    if(field?.populateActive && field?.dangerousPopulation && field?.code) return true;
    if(field?.validateActive && field?.validation?.length >= 1) return true;
    if(shouldPassSchema(field)) {
        return field?.schema?.filter(f => shouldPassData(f))?.length >= 1
    }
    return false;
}

//Data Item source selector
export const isProvider = itemType => itemType === 'dataprovider'
export const isDataLink = itemType => itemType === 'datalink'

export const isSource = state => state?.source === 'source' || state === 'source'
export const isProviderSource = state => state?.source === 'provider' || state === 'provider'
export const isRepeaterSource = state => state?.source === 'repeater' || state === 'repeater'
export const isPageSource = state => state?.source === 'page' || state === 'page'

export const mappableSubFields = [
    'repeater',
    'group',
    'relation',
    'location',
    'user',
    'gallery',
    'image'
]
export const canValueReplaceWithCode = fieldType => ['textarea', 'text', 'number', 'slug', 'code', 'switch'].includes(fieldType)

export const canSelectField = (fieldType, itemType) => {
    if(isProvider(itemType)) return 'repeater|relation'.split('|').includes(fieldType)
    if(isDataLink(itemType)) return !'repeater|relation'.split('|').includes(fieldType)
    return true;
}


 
const findField = (schema, fieldName) => Array.isArray(schema) ? schema?.find(f => f?.name === fieldName) : null


//Parse field path
export const getField = (collection, entry) => {
    if(!collection?.schema || !entry?.fieldPath) return null;
    let fullFieldPath = [];
    let tempSchema = collection?.schema;
    entry?.fieldPath?.map(path => {
        let field = findField(tempSchema, path?.name);
        if(field){
            fullFieldPath = [...fullFieldPath, field]
            tempSchema = field?.schema;
        } else {
            console.warn('no field')
            console.warn({fullFieldPath, field, tempSchema})
        }
    })
    return fullFieldPath;
}
export const getExtendedFieldPath = (fullPath, prevPath) => fullPath?.filter(field => 
    !prevPath?.find(rmv => rmv?.name === field?.name) 
)
export const getLatestFieldFromFieldPath = fieldPath => fieldPath?.[fieldPath?.length - 1]

export const getMinimalFieldPath = fieldPath => Array.isArray(fieldPath) ? combineString(fieldPath.map(field => field?.name), '.') : ''

export const getFieldValue = (fieldPath, entry, schema = null) => {
    let tempValue = entry;

    // todo !check fix
    if(schema?.schema) schema = schema?.schema;

    fieldPath?.map(path => {
        let value = hasAttr(tempValue, path?.name) ? tempValue[path?.name] : null;
        // console.log({path, entry, value})
        if(isBoolean(value)) {
            tempValue = value;
        } else if(path?.name === 'slug' && !value) {
            //Retrieve full entry for slug data.
            tempValue = schema ? 
                slugify(entry?.[schema?.[0]?.name]) : 
                entry;

            // console.log({ slug: slugify(entry?.[schema?.[0]?.name]), name: entry?.[schema?.[0]?.name], attr: schema?.[0]?.name, schema, entry})

        } else if(value){
            tempValue = value;
        } else {
            tempValue = null
            // console.warn('no value')
            // console.warn({tempValue, value, fieldPath})
        }
    })
    return tempValue;
}
export const getSchema = (fieldPath, schema) => {
    let tempSchema = schema;
    if(!Array.isArray(tempSchema) || !fieldPath) return tempSchema;
    
    fieldPath?.map(path => {
        let value = tempSchema?.schema ? 
            tempSchema.schema?.find(s => s?.name === path?.name) :
            Array.isArray(tempSchema) ? 
                tempSchema?.find(s => s?.name === path?.name):
                tempSchema;
        
        if(path?.name === 'slug') value = {schema};

        if(value){
            tempSchema = value;
        } else {
            // console.warn('no tempSchema')
            // console.warn({tempSchema, value, fieldPath})
        }
    })
    return tempSchema;
}








export const getSourceEntry = ({value, collections, context}) => {
    if(isPageSource(value)) {
        const dataProvider = context?.pageProvider;

        return [
            dataProvider?.schema,
            dataProvider?.schema,
            dataProvider?.baseRoute,
            dataProvider?.entries,
            null
        ]
        // return retreivePageContext(value, collections, context.pageProvider.entry)
    }
    
    if(isProviderSource(value)) {
        const dataProvider = context?.dataProvider?.[value?.provider]?.entry;
        const hasReceivedSource = Boolean(dataProvider?.source === 'provider');
        const receivedSource = context?.dataProvider?.[dataProvider?.provider];
        
        return revertEntryPath(
            hasReceivedSource ? receivedSource : collections ,  
            hasReceivedSource ? receivedSource?.entry : dataProvider , 
            value?.clone
        )
    }

    if(isRepeaterSource(value)) {
        const dataProvider = context?.repeatProvider;

        return [
            dataProvider?.schema,
            dataProvider?.schema,
            dataProvider?.baseRoute,
            [dataProvider?.entries],
            null
        ]
    }
    // [
    //     collection,
    //     item,
    //     value.fieldPath
    // ]

    return revertEntryPath(
        collections, 
        value, 
        value?.clone
    )
}

// const retreivePageContext = (value, collections, dataEntry) => {
//     if(!dataEntry?.collectionId) return [null, 'Did not receive collection id', null]
//     const collection = getCollection(collections, dataEntry?.collectionId);
//     const item = dataEntry;

//     return [
//         collection,
//         collection?.schema,
//         collection?.baseRoute,
//         item,
//         value.fieldPath
//     ]

// }
const revertEntryPath = (collections, entry, clone = null) => {
    const collection = getCollection(collections, entry?.collection);
    const item = getEntry(collection, entry?.entry, clone);
    const fieldPath = getField(collection, entry)
    return [
        collection,
        collection?.schema,
        collection?.baseRoute,
        item,
        fieldPath
    ]
}

