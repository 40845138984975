import { useGetSitesPublic, useGetUsersPublic } from "@mosanic/cms/Query/public"
import { getFieldNames, getFields } from "./collection"
import { combineString } from "@util/Text";
import { getSiteUrl } from "./Site";
import { useSiteId } from "@mosanic/cms/Query";


// ? # Manipulation for rendering 

// --> For data ctx and mailings
export const useEntryReplaceUserFields = ({entry, schema}) => {
    const userRefs = getFieldNames(getFields(schema, 'user'));

    let [users, loadingUsers, errorUsers] = useGetUsersPublic({filter: {
        OR: userRefs?.map(fieldName => ({_id: entry?.[fieldName]}))
    }})

    if(!loadingUsers && userRefs) {
        userRefs.map(ref => {
            entry = {...entry, [ref]: users?.find(user => user._id === entry[ref])}
        })
    }
    return entry;
}

// --> For data ctx and mailings
export const useEntryReplaceSlugField = ({entry, baseRoute, pointer = '_id'}) => {
    const siteId = useSiteId();
    const [site, loadingSite, errorSite] = useGetSitesPublic({siteId: entry?.siteId ? entry.siteId : siteId})

    return {
        ...entry,
        slug: combineString([
            getSiteUrl(site), 
            baseRoute,
            entry[pointer]
        ],'/')
    }
}


// ? # Manipulation for saving 

const getPageProp = (type, pageProps) => {
    if(type === 'user' && pageProps?.author) return pageProps.author;
    if(type === 'relation' && pageProps?.entryId) return pageProps.entryId
    return null;
}
export const replaceEntryPageProps = ({entry, schema, pageProps}) => {
    if(pageProps.author){
        entry = {...entry, queryId: pageProps?.author}
    }
    schema.map(f => {
        if(f.pageProp) entry = {...entry, [f.name]: getPageProp(f.type, pageProps)}
        
        //Todo handle repeaters etc.
        if(f?.schema && f.type === 'group') {
                entry = {
                ...entry,
                [f.name]: replaceEntryPageProps({entry: entry[f.name], schema: f.schema, pageProps})
            }
            
        }
    })
    return entry;
}
export const setDefaultEntryValues = ({entry, schema}) => {
    schema.map(f => {
        if(f.default && !entry[f.name]) entry = {...entry, [f.name]: f.default}

        //Todo handle repeaters etc.
        if(f?.schema && f.type === 'group') entry = {
            ...entry,
            [f.name]: setDefaultEntryValues({entry: entry[f.name], schema: f.schema})
        }
    })
    return entry;
}