import { ArrowDownIcon, FileIcon, PlusCircledIcon, ResetIcon } from "@radix-ui/react-icons";
import { getDevModePrefSelector } from "@redux/options/reducer";
import { useSelector } from "react-redux";



export const useDevTabs = ({value, handler}) => {
    const {dev} = value || {dev: undefined}
    const isDevMode = useSelector(getDevModePrefSelector);

    const options = [
        {value: "add", icons: [<ResetIcon />, <PlusCircledIcon />]},
        {value: "data", icons: [<ResetIcon />, <FileIcon />]},
        {value: "get", icons: [<ResetIcon />, <ArrowDownIcon />]},
    ];
    
    const setDevColumn = (mode) => handler({
        ...value,
        dev: dev === mode ? undefined : mode
    })

    return {
        options: isDevMode ? options : [],
        handler: setDevColumn,
        value: dev,
        key: 'dev'
    }
}