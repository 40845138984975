import { selectInspectItemPath } from "@redux/inspect/reducer";
import { mapSelector } from "@redux/map";
import { splitPath } from "./paths";

export const getParentItem = ({map, itemPath}) => getItemByPath({map, path: itemPath.slice(0 , -1)})

//Provide map
export const getItemByPath = ({map, path}) => {
    if(!map || !path) return null;
    path = Array.isArray(path) ? path : splitPath(path);
    let item;
    
    for (let index = 0; index < path.length; index++) {
        let itemIndex = path[index] ? Number(path[index]) : null;
        if(index === 0) item = map[itemIndex];
        if(index != 0 && itemIndex != null) item = item?.children[itemIndex]
    }
    return item;
}
//Get map from state
export const getItemByPathInState = ({path, state, getState}) => {
    if(!state) state = getState();
    return path ? getItemByPath({
        map: mapSelector(state), 
        path
    }) : null
}

//Check if action has item, else get inspected by path
export const getItemMapFromActionOrState = ({action, state}) => {
    if(action?.payload?.item?.path) return action?.payload?.item;

    const path = selectInspectItemPath(state);
    const item = getItemByPathInState({path, state});
    return {
        ...item,
        path
    }
}