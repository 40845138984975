export { sumNumberArray } from "./Num";

export const loop = (count = 4, item) => Array.from({ length: count }, (_, i) => (
  item
))

export const loopFunc =  (count = 4, func) => Array.from({ length: count }, (_, i) => func(i))

export const areEqual = (arrA, arrB, field = null) => {
  if (arrA === arrB) return true;
  if (arrA == null || arrB == null) return false;
  if (arrA.length !== arrB.length) return false;

  for (var i = 0; i < arrA.length; ++i) {
    if(!field){
      if (arrA[i] !== arrB[i]) return false;
    } else {
      if (arrA[i][field] !== arrB[i][field]) return false;
    }
  }
  return true;
}

export const canMap = (arr) => Array.isArray(arr) && arr?.length >= 1;
/**
 *  Function to reorder the result
 * @param {*} list 
 * @param {*} startIndex 
 * @param {*} endIndex 
 * @returns reordered list
 */
 export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

/**
 * Remove item from array
 * @param {*} arr 
 * @param {*} index 
 * @returns new array
 */
export const remove = (arr, index) => !arr ? arr : [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1)
];

/**
 * Insert new item in array
 * @param {*} arr 
 * @param {*} index 
 * @param {*} newItem 
 * @returns new array
 */
export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

export const updateItem = (arr, index, item) => ([
  ...arr.slice(0, index),
  item,
  ...arr.slice(index + 1, arr.length)
]);

export const getIndexByValue = (arr, value, atr = '_id') =>  {
  let result 
  if(arr){
    result  = arr.findIndex(function(o){return o[atr] == value;} );
  }
  return result? result : null; // or undefined
}


export const objectToArray = (obj) => {
  return obj ? Object.keys(obj).reduce((array = [], _id) => {
      array.push(obj[_id])
      return array;
  }, []) : []
}


  
export const orderByModule = (array, get = 'all') => {
  let orderedEvenArray = []
  let orderedUnEvenArray = []
  for (var i = 0; i < array.length; i++) {

    if(i % 2 === 0) orderedEvenArray.push(array[i]);
    else orderedUnEvenArray.push(array[i])
    
  }
  if(get === 'all') return [...orderedEvenArray, ...orderedUnEvenArray]
  return (get === 'even') ? orderedEvenArray : orderedUnEvenArray;
}

export function getByValue(arr, value, atr = '_id')  {
  let result 
  if(Array.isArray(arr)){
    result  = arr.filter(function(o){return o[atr] && o[atr] == value;} );
  } else {

    // console.trace();
  }
  return result ? result[0] : null; // or undefined

}

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr]
  if (chunkSize <= 0) return cache
  while (tmp.length) cache.push(tmp.splice(0, chunkSize))
  return cache
}

export const compact = (arr) => [0, 1, 2].filter(x => !!x)

export const drop = (arr, n = 1) => arr.slice(n)

export const dropRight = (arr, n = 1) => arr.slice(0, -n || arr.length)

export const removeFirst = arr => {
  if(!Array.isArray(arr)) return [];
  arr.shift();
  return [...arr];
}
export const removeLast = arr => {
  if(!Array.isArray(arr)) return [];
  arr.length = arr.length - 1;
  return arr;
}

export const removeDuplicates = arr => {
  if(!arr) return []
  return arr.filter(function (value, index, array) { 
    return array.indexOf(value) === index;
  })
}


export const getLastItem = arr => Array.isArray(arr) ? arr[arr.length - 1] : null;
