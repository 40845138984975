import { getInitialComponent } from '@mosanic/constants/components';
import { addChildToChildren } from '@mosanic/map';
import { dataActions } from '@redux/map/data';
import { extendPath } from '@mosanic/map/paths';
import { transformInitialItem } from './transform';


//Get base item data and map
const getItem = ({type = 'column'}) => {
    const item = getInitialComponent(type)
    if(!item) return null;
    return transformInitialItem({item});
}


//Get base items data and map
const getItems = ({type = 'column', count = 2}) => {
    let items = [];
    const item = getInitialComponent(type)
    if(!item) return [];

    for (let index = 0; index < count; index++) {
        items.push(transformInitialItem({item}))
    }

    return items;
}

//Initialize data of items
const initItems = ({items}, dispatch) => {
    if(!Array.isArray(items) || items.length <= 0) return;
    items.map((item) => {
        dispatch(dataActions.initDataAction({component: item.component}));
    });
};

//Add items to map
const addItems = ({itemMap, items}) => {
    if(!Array.isArray(items) || items.length <= 0) return;
    items.map((item, index) => {
        itemMap.children = addChildToChildren({
            children: itemMap?.children ? itemMap.children : [], //Fallback children
            dropPath: [index],
            item: {...item.itemMap, path: undefined}
        });
    });
    return itemMap;
}

//Automatic factory for columns
const columns = ({itemMap, count = 2}, dispatch) => {
    const items = getItems({count});
    initItems({items}, dispatch);
    return addItems({itemMap, items});
}

//Automatic factory for wrapper
const wrapper = ({itemMap, type}, dispatch) => {
    const wrapperItem = getItem({type});
    initItems({items: [wrapperItem]}, dispatch);
    
    return {
        ...wrapperItem.itemMap,
        children: [itemMap]
    }
       
}
 
export const DataFactory = {
    getItem,
    getItems,
    initItems,
    addItems,

    columns,
    wrapper
}


