import { Condition } from "@JSX";
import { Link, Tooltip } from '@mosanic/items';
import { oneLineClass } from "@util/Class";
import { WrapInput } from "../../items/Inputs";

import { ButtonStyles as s } from "@mosanic/styles";



export const Buttons = ({
  children, 
  size = 'small', 
  noBorder = true, 
  extraClass = 'text', 
  wrap = false,
  ...other
}) => (
  <WrapInput wrap={wrap ? wrap : false}>
      <s.Buttons {
        ...oneLineClass(`
        coreButtons 
        ${noBorder ? '-noBorder' : ''} 
        -${extraClass}
        -${size} 
        -text`)
      }
      {...other}
      >
          {children}
      </s.Buttons>
  </WrapInput>
)

export const ToggleButton = ({
  altLabel = null,
  altIcon = null,
  checked = false,
  ...other
}) => (
  <Button {...other} 
    label={checked ? altLabel : other?.label} 
    icon={checked ? altIcon : other?.icon} 
  />
)

export const Button = ({
  children, 
  handler, 
  onClick = null,

  label = null,
  size = 'small', 

  theme = "",
  variant = "outlined",
  customClass = '', 
  icon = false,
  iconRight= false,
  double = false,

  scale = false,
  maxW = false,

  active = false,
  disabled = false,
  noVal = false, 

  cell = false,
  style = null, 
  bold = false,
  tooltip = null,
  
  wrap = false,
  href = null,
  target = null,
  type = "button",
  lines = 1,
  ui = false,
  ...other
}) => (
  <WrapInput
    wrap={wrap ? wrap : false}
  >
  <Condition
    when={tooltip}
    wrapper={children => 
      <Tooltip 
        title={tooltip?.title ? tooltip.title : tooltip} 
        wrap={tooltip?.wrap ? tooltip.wrap : false}
        placement={tooltip?.placement ? tooltip.placement : null} 
      >
        {children}
      </Tooltip>}
  >
  <Condition
    when={href}
    wrapper={children => <Link href={href} target={target}>{children}</Link>}
  >
    <s.Button 
      uiButton={ui}
      onClick={handler ? handler : onClick} 
      className={`coreButton ${customClass}`} 
      sz={size} 
      theme={theme}
      variant={variant} 

      icon={icon || iconRight}
      // scale={scale}
      maxW={maxW}

      active={active}
      disabled={disabled} 
      noVal={noVal}

      cell={cell}
      bold={bold}
      style={style}
      type={type}
      double={double}
      lines={lines}
      {...other}
    >
      {icon ? icon : ''}
      {(double || lines >= 2) && <br/>}
      {label ? 
        double ? (
          <span>{label}</span>
         ) : label : ''} 
      {!icon && !label && (
        children
      )}
      {iconRight ? (
        <div style={{margin: '0 0 0 2px'}}>{iconRight}</div>
      ) : ''}
       
    </s.Button>
  </Condition>
  </Condition>
  </WrapInput>
);
export default Button;