import { useState } from "react";

export function useControlPopper(closeSecondTime = true) {
  const [anchor, setAnchor] = useState(null);

  const target = (event) => event?.currentTarget ? 
    event.currentTarget : 
    event;

  const open = (event) => setAnchor(anchor ? 
    (closeSecondTime ? null : target(event)) :
    target(event) 
  );

  const close = (event) => {
    if (event && anchor?.current && anchor?.current.contains(event.target)) return;
    if (event && !event?.currentTarget && anchor === event.target) return;
    setAnchor(null);  
  }

  const isOpen = Boolean(anchor);

  return {anchor, open, close, isOpen};
}
