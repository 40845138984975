import { Button } from '@mosanic/fields/Button/Buttons';
import { FormItems } from '@mosanic/fields/Form';
import { AdvancedLabel, ErrorAlert, InputGroup, InputGroupItem } from '@mosanic/items';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { colors } from '@mosanic/styles';
import axios from "axios";
import styled, { css } from 'styled-components';

import { Expander, Label } from "@mosanic/items";
import { TwoColumns } from "@mosanic/items/Page/Columns";
import { DesktopIcon } from "@radix-ui/react-icons";

const ListItem = styled.li` 
    padding: 5px;
    font-size: 14px;
    display: flex;
    ${props => props.valid && css` 
        color: #999;
    `}
`

const Status = styled.div` 
    max-height: 20px;
    max-width: 20px;    
    min-height: 20px;
    min-width: 20px; 
    display: inline-block;
    margin-right: 10px;
    padding: 0 2.5px;
    overflow: hidden;
    border-radius: 15px;
    background: ${props => props.valid ? colors.util.bright.green : colors.util.bright.red};
    svg {
        margin-top: -4px;
        path {
            fill: #fff;
        }
    }
`
const list = (txt) => ({
    match: 'Passwords match',
    length: `At least 8 characters (${txt?.length})`,
    lower: 'Lower case letters (a-z)',
    higher: 'Upper case letters (A-Z)',
    number: 'Contains a number (0-9)',
    special: 'A special character (!@#$%&*)'
})
export const SecurityUser = ({user, Validations, errorMessage}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } } = useFormContext({mode: 'all', defaultValues: user});
	const inputProps = {control, errors}


	return (
         <InputGroup style={{position: 'relative'}}>

            {/* {(errorMessage || watch()?.password)  && (
                <Validations />
            )} */}
            <FormItems controls={inputProps} fields={[{    
                name: 'password',
                label: 'New password',
                type: 'password',
                disabled: true,
                fullWidth: true
            },{    
                name: 'repeat_password',
                label: 'Repeat password',
                disabled: true,
                type: 'password',
                fullWidth: true
            }]}
            />
             <InputGroupItem fullWidth>
                {errorMessage && (
                    <ErrorAlert errorMessage={errorMessage}/>
                )}
            </InputGroupItem>
        </InputGroup>
	);
};

const useClientNetwork = () => {
    const [info, setInfo] = useState({})
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setInfo(res.data);
    }
    
    useEffect( () => {
        //passing getData method to the lifecycle method
        getData()

    }, [])

    return info
}

const useClientInfo = () => {

    const [info, setInfo] = useState({})
    const update = data => setInfo({...info, ...data})

    useEffect(() => {
        if(typeof navigator === 'undefined') return null;
        let base = {
            nVer: navigator?.appVersion,
            appName: navigator.appName,
            nAgt: navigator?.userAgent,
            userAgent: navigator?.userAgent,
            browserName: navigator?.appName,
            fullVersion: ''+parseFloat(navigator?.appVersion),
            majorVersion: parseInt(navigator?.appVersion,10),
            platform: navigator?.userAgentData?.platform || 'Windows'
        }

        var nameOffset,verOffset,ix;

        // In Opera, the true version is after "OPR" or after "Version"
        if ((verOffset=base.nAgt.indexOf("OPR"))!=-1) {
            base.browserName = "Opera";
            base.fullVersion = base.nAgt.substring(verOffset+4);

            if ((verOffset=base.nAgt.indexOf("Version"))!=-1) 
                base.fullVersion = base.nAgt.substring(verOffset+8);
        }
        // In MS Edge, the true version is after "Edg" in userAgent
        else if ((verOffset=base.nAgt.indexOf("Edg"))!=-1) {
            base.browserName = "Microsoft Edge";
            base.fullVersion = base.nAgt.substring(verOffset+4);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=base.nAgt.indexOf("MSIE"))!=-1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = base.nAgt.substring(verOffset+5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset=base.nAgt.indexOf("Chrome"))!=-1) {
            base.browserName = "Chrome";
            base.fullVersion = base.nAgt.substring(verOffset+7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset=base.nAgt.indexOf("Safari"))!=-1) {
            base.browserName = "Safari";
            base.fullVersion = base.nAgt.substring(verOffset+7);
        if ((verOffset=base.nAgt.indexOf("Version"))!=-1) 
            base.fullVersion = base.nAgt.substring(verOffset+8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset=base.nAgt.indexOf("Firefox"))!=-1) {
            base.browserName = "Firefox";
            base.fullVersion = base.nAgt.substring(verOffset+8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ( (nameOffset=base.nAgt.lastIndexOf(' ')+1) < 
                (verOffset=base.nAgt.lastIndexOf('/')) ) 
        {
            base.browserName = base.nAgt.substring(nameOffset,verOffset);
            base.fullVersion = base.nAgt.substring(verOffset+1);
        if (browserName.toLowerCase()==browserName.toUpperCase()) {
            base.browserName = navigator.appName;
        }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix=base.fullVersion.indexOf(";"))!=-1)
            base.fullVersion=base.fullVersion.substring(0,ix);
        if ((ix=base.fullVersion.indexOf(" "))!=-1)
            base.fullVersion=base.fullVersion.substring(0,ix);

        base.majorVersion = parseInt(''+base.fullVersion,10);
        if (isNaN(base.majorVersion)) {
            base.fullVersion  = ''+parseFloat(navigator.appVersion); 
            base.majorVersion = parseInt(navigator.appVersion,10);
        }
        update(base);
    },[])

    return info;
}

export const RenderClientInfo = () => {
    const {browserName, fullVersion, appName, platform, userAgent} = useClientInfo()
    const {IPv4, city, country_name, state} = useClientNetwork()
    return (
        <Expander trigger={<Button m={0} ghost label=" Client info" icon={<DesktopIcon />}/>}>
            <AdvancedLabel my={2} label="To maintain the reliability and security of Mosanic we track the incoming connections to the platform."/>
            <TwoColumns 
                first={<>
                    <Label label="Browser name" value={browserName} />
                <Label label="Version" value={fullVersion} />
                <Label label="App name" value={appName} />
                <Label label="User IP" value={IPv4} />
                </>}
                second={
                <>
                    <Label label="User os" value={ platform || 'Windows'} />
                    <Label label="Location" value={ `${country_name} - ${city}` } />
                    <Label label="User agent" value={userAgent} />
                </>
                }
            />
            <br/>
        </Expander>
    )
}