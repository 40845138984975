import useMediaQueries, {sortMediaQueries} from "@mosanic/hooks/useMediaQueries";
import { inspectOverwriteSelector } from "@redux/inspect/reducer";
import { hasAttr, set, toObject } from "@util/Obj";
import { removeFirstChar } from "@util/Text";
import { useSelector } from "react-redux";
import { interactionStates as interact } from "@mosanic/constants/variants";
import { currentPageName } from "@redux/screens/reducer";
import { useWindowSize } from "@hooks/useWindowSize";
import { useCallback, useEffect, useState } from "react";
import { colorByHexSelector, colorByShadeSelector, colorsSelector } from "@redux/colors/reducer";
import { useModeProvider } from "@mosanic/core/Provider";
import { useCanvasScreenSize } from "./Breakpoint";

const parseMedia = (media, breakpoint, width) => {
    if(hasAttr(media, breakpoint)){
        const index = media[breakpoint].index;
        let newMap = Object.entries(media).map(([id, value]) => {
            if(value?.index < index || !value?.index) return {id, ...value};
            if(value?.index === index) return {
                id,
                ...value,
                selector: `(min-width: ${width-50}px) and (max-width: ${width+50}px)`
            }
            return false; // skip
        })
        media = toObject(newMap, 'id')
    }
    return media;
} 
export const useValidate = (state, breakpoint, isProduction = true) => {
    const {width, height, breakpointMode} = useCanvasScreenSize();
    const mode = useModeProvider();
    const overwrite = useSelector(inspectOverwriteSelector)
    const page = useSelector(currentPageName)
    const colors = useSelector(colorsSelector);
    const getColor = (hue, shade) => colorByShadeSelector(colors, hue, shade)?.value

    
    const media = useMediaQueries();

    const sorted = sortMediaQueries(media, false);
    const current = sorted.find(s => s.id === breakpoint)

    return {
        overwrite: (variant, type) => (page != 'artboards' && hasAttr(overwrite, type)) ? (
            overwrite[type].filter(s => s?.value === variant)?.length >= 1 
        ) : false,
        
        interact: (variant) => interact.filter(s => (
            removeFirstChar(s?.value) === variant
        ))?.length >= 1,

        media: (variant) => hasAttr(media, variant),

        variant: (variant) => state ? (
            Object.values(state)?.filter(s => (
                Array.isArray(s) && s?.includes(variant) ||
                !Array.isArray(s) && s === variant
            ))?.length >= 1 
        ) : false,

        vh: (vh) => (!breakpointMode && mode?.isDev) ? `${parseFloat(vh)/100 * height}px` : vh,
        vw: (vw) => (!breakpointMode && mode?.isDev) ? `${parseFloat(vw)/100 * width}px` : vw,
        
        interactions: interact.map(i => removeFirstChar(i?.value)),       

        bps: media,
        sorted,
        ids: sorted.filter(s => (s.index <= current?.index || !s.index)).map(s => s.id),
        rmv: isProduction ? [] : sorted.filter(s => (s.index > current?.index)).map(s => s.id),
        breakpoint,
        bp: breakpoint,
        current,
        prod: mode?.isDev ? false : isProduction,
        color: (hue, shade) => getColor(hue, shade)

    }
}