import { getImageUrl } from '@api/host';
import { Condition } from '@JSX';
import { Button, Buttons } from '@mosanic/fields/Button/Buttons';
import { ControlDialog, Expander, Label, Link } from '@mosanic/items';
import { ClearBoth } from '@mosanic/styles/util';
import { isImage } from '@mosanic/utils/Image';

import { ArrowRightIcon, Pencil1Icon, SizeIcon, TrashIcon } from '@radix-ui/react-icons';

import { roundPrecise } from '@util/Round';
import { containsSubstr, excerptString, replacePart } from '@util/Text';
import { useEffect } from 'react';
import { useDeleteAsset } from './Handlers';

import { default as s } from './Media.styles';

import { MetaFields } from './MetaFields';

export const MediaPreview = ({
    previewImage, 
    close, 
    initiate, 
    initiateImage, 
    dialog, 
    callbackDelete, 
    hasImages = false
}) => {

    const [
		handleDelete,
		dataDelete,
		loadingDelete,
		errorDelete
	] = useDeleteAsset();

    // useEffect(() => {
	// 	if(dataDelete?.recordId && callbackDelete) 
	// 		callbackDelete(dataDelete?.recordId)
	// }, [dataDelete?.recordId, callbackDelete])

    const metaFields = [
        {label: "Asset Title", value: previewImage?.title},
        {label: "Alt Text", value: previewImage?.alt},
        {label: "Filename", value: previewImage?.filename},
        {label: "Folder", value: previewImage?.folder ? previewImage?.folder : '/'},
        {label: "Size", value: !previewImage?.size ? 'unknown' : roundPrecise(previewImage?.size, 2)+' mb'},
        {label: "SiteId", value: previewImage?.siteId},
    ]
    return (
        <Condition 
            when={dialog} 
            wrapper={children => (
                <ControlDialog 
                    size="large"
                    state={previewImage?.url} 
                    close={close}
                >{children}</ControlDialog>
            )}
        > 
            <s.MediaSideBar className="CoreSidebar MediaGallery__sidebar" dialog={dialog}>
                {previewImage?.url ? (
                    <div className="MediaGallery__itemPreview mt-10">
                        <s.SideBarImage>
                            {!isImage(previewImage?.url) ? (
                                <video 
                                    src={getImageUrl(previewImage?.url)}
                                    alt={'Video'} 
                                    controls={true}
                                />
                            ) : (
                                // eslint-disable-next-line @next/next/no-img-element
                                <img src={getImageUrl(!containsSubstr(previewImage?.urlMin, '.svg') && previewImage?.urlMin ? previewImage?.urlMin : previewImage?.url)} alt="" width="100%" />	
                            )}

                        </s.SideBarImage>
                        <s.MediaContent>
                            {loadingDelete}
                            {errorDelete}
                            
                            <Expander trigger={
                                <h4>
                                    Title: {excerptString(previewImage?.title, 22, '...')}
                                </h4>
                            }>
                                {metaFields?.map(field => (
                                    <Label mt={2} label={field?.label} value={field?.value} key={field.label} />
                                ))}
                            </Expander>

                            <Label mt={2} label="Minimized version" value={previewImage?.urlMin ? (
                                <Link href={getImageUrl(previewImage?.urlMin)} target="_blank">
                                    <ArrowRightIcon /> {roundPrecise(previewImage?.size * 0.3, 2)} mb
                                </Link>
                            ) : 'No'} />
                            
                            <Label mt={2} label="Public Url" value={(
                                <Link href={getImageUrl(previewImage?.url)} target="_blank">
                                    {replacePart(previewImage?.url, `${previewImage?.siteId}/`, '')}
                                </Link>
                            )} />

                            <Buttons mt={4}>
                                <Button 
                                    onClick={initiateImage} 
                                    icon={<SizeIcon />}
                                    label={initiate ? ' Select' : ' Preview'} 
                                />
                                    
                                <MetaFields 
                                    meta={{...previewImage}}
                                    trigger={(
                                        <Button icon={<Pencil1Icon />} label=" SEO" />
                                    )}
                                />
                                
                                <Button     
                                    onClick={() => handleDelete(previewImage)} 
                                    icon={<TrashIcon />} 
                                    label=" Delete" 
                                />
    
                            </Buttons>
                            

                        </s.MediaContent>
                    </div>
                ) : (
                    <div className="empty__state">

                        <span className="label">
                            {hasImages && "Click an image to see and edit its properties here."}
                        </span>
                    </div>
                )}
            </s.MediaSideBar>	
        </Condition>
    )
}