import { getBreakpointsSelector } from '@redux/breakpoints/reducer';
import { produce } from 'immer';

import { HYDRATE } from 'next-redux-wrapper';
import { createSelector } from 'reselect';

import SCREENS_ACTION_IDS from './action';
const Action = SCREENS_ACTION_IDS;

const screens = [{
    name: 'Base',
    _id: null,
    type: 'screen',
    breakPoint: 'xl',
    scale: 0.9,
    size: {
        minWidth: 0,
        width: 0,
        maxWidth: 0
    },
    position: {
        x: 0,
        y: 0
    }
}, { 
    name: 'Mobile',
    _id: null,
    type: 'screen',
    breakPoint: 'xs',
    scale: 1.2,
    size: {
        minWidth: 0,
        width: 0,
        maxWidth: 0
    },
    position: {
        x: 0,
        y: 0
    }
}]
 
export const screensOffset = 50;
export const smallestDefaultWidth = 300;
export const baseScreenScale = 1;
 
const initialState = {
    isDragging: false,
    scale: 1,
    currentBp: 'xl',
    currentDevice: {
        type: 'desktop',
        device: 'Desktop'
    },
    current: 'root',
    pages: {
        root: { 
            name: 'root',
            screens,
        },
        artboards: {
            name: 'artboards',
            type: 'artboard',
            screens: null
        }
    }
};

const screensReducer = (screens = initialState, action) => produce(screens, draft => {
    const payloadScreen = action.payload;
    const currentPage = draft.current;
 
    switch (action.type) {
        case HYDRATE:
            return {...screens, ...action.payload};


        case Action.SCREENS_SET_SCALE:
            draft.scale = action.payload;
            return draft;

        case Action.SCREEN_SET_BP:
            draft.currentBp = action.payload;
            return draft;

        case Action.SCREEN_SET_DEVICE:
            draft.currentDevice = action.payload;
            return draft;

        case Action.PAGE_UPDATE_SEO:
            draft.pages[currentPage].seo = action.payload.seo
            return draft;

        case Action.SCREENS_UPDATE_META:
            draft.pages[currentPage].screens = draft.pages[currentPage].screens.map(screen => {

                if(!screensAreEqual(screen, payloadScreen)) return screen;

                return {
                    ...screen, 
                    ...payloadScreen
                };
            })
            return draft;

        case Action.SCREEN_INIT_NEW:
            
            draft.pages[currentPage].screens.push({...action.payload, new: undefined})
            return draft;

        case Action.PAGE_SET:
            draft.current = action.payload;
            return draft;
 
        case Action.PAGE_ADD_NEW:
            
            draft.pages = {
                ...draft.pages,
                [action.payload?._id]: {
                    ...draft?.pages?.root,
                    name: action.payload.name,
                }
            }
            return draft;

        
        case Action.SCREEN_REMOVE:
            draft.pages[currentPage].screens = draft.pages[currentPage].screens.filter(screen => screen._id != action.payload);
            return draft;

        case Action.SCREENS_REORDER:
            draft.pages[currentPage].screens = draft.pages[currentPage].screens.slice().sort( compareWidths ).reverse();
            return draft;

        default:
            return screens;
    }; //switch
});
export default screensReducer;

/**
 * Sort screens by min width
 * @param {bp} a 
 * @param {bp} b 
 * @returns {sorted array}
 */
function compareWidths( a, b ) {
    if ( a.size.minWidth < b.size.minWidth ) return -1;
    if ( a.size.minWidth > b.size.minWidth ) return 1;
    return 0;
}

//_id's are equal or _id is unset and bp is same.
const screensAreEqual = (screen, payloadScreen) => (screen?._id === payloadScreen?._id) || (!screen?._id && screen.breakPoint === payloadScreen.breakPoint)

//Select current redux feature
const screensFeature = state => state.present.screens;

export const currentBpSelector = state => screensFeature(state)?.currentBp
export const currentDeviceSelector = state => screensFeature(state)?.currentDevice

//Select all pages
export const pagesSelector = state => screensFeature(state).pages;
export const currentPageName = state => screensFeature(state).current;
export const currentPage = state => pagesSelector(state)[currentPageName(state)];



//Get screens
export const getScreensSelector = (state) => currentPage(state)?.screens ? 
    currentPage(state)?.screens : 
    pagesSelector(state).root.screens; // fallback

// export const getScreenSelector = (state, bp) => 
//     getScreensSelector(state)?.filter(screen?.breakPoint === bp)[0];

export const getNonPreviewedScreensSelector = createSelector(
    getBreakpointsSelector,
    getScreensSelector,
    (breakpoints, screens) => breakpoints.filter(bp => {
        if(screens.some(screen => screen?.breakPoint === bp?._id)) return;
        return bp;
    })
)



//Screens scale
export const getScreensScale = state => screensFeature(state).scale;


export const getTotalScreensWidth = createSelector(
  getScreensSelector,
  (screens) => screens.reduce((total, screen) => total + screen.size.width + screensOffset, 0) - screensOffset // subtract last offset
)