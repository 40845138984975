import { createElement } from "react";

const Element = ({tag, children, ...other}) => createElement(tag, other, children);

const HeadingTag = ({level, other= null, children = null, ...more}) => (
    <Element tag={`h${level}`} {...other} {...more}>{children}</Element>
);

export {
    Element,
    HeadingTag,
};

export default Element;