import { Button } from '@mosanic/fields';
import { elementIcon } from '@mosanic/Icons';
import { ControlDialog } from '@mosanic/items';
import { GetListOfMedia } from './Resolvers';


export const MediaDialog = ({callback, type = 'image', ...other}) => (
    <ControlDialog 
        trigger={(
            <Button ghost {...other}>
                {elementIcon("archive")}
            </Button>
        )} 
        size="xl" 
        fullWidth
    > 
        <GetListOfMedia header dialog initiate={callback} direct type={type} upload={false}/>
    </ControlDialog> 
)