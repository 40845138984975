import { useMetaFields, useStoreMedia, useUploadFile } from '@mosanic/components/Media/Handlers';
import { remove } from '@util/Arr';
import { useEffect, useState } from "react";



export const useUploadFactory = ({url, urls = null, callback = null}) => {
    const [images, setImages] = useState(url ? [{url}] : null)
    const [meta, updateMeta, removeMeta] = useMetaFields({images})

    const [load, setLoading] = useState(false)

    useEffect(() => {
        if(url) setImages([{url}] )
    },[url])

    useEffect(() => {
        if(urls) setImages(urls)
    },[urls])

    //Upload handler
    const [uploadFiles, progress, loadingUpload, errorUpload, dataUpload] = useUploadFile({
        storeMediaCallback: (data) => storeMediaFile(data)
    });
 
    //Media upload handler
    const [storeMediaFile, dataMedia, loadingMedia, errorMedia] = useStoreMedia({
        useUrlCallback: (e) => {
            if(callback) callback(e);
            setLoading(false)
        }
    });

    const handleRemove = (index) => {
        setImages(remove(images, index))
        removeMeta(index)
        if(callback) callback(images);
    }

    const uploadOne = (i) => {
        setLoading(true)
        uploadFiles(images[i], meta[i]);
        handleRemove(i)
    }

    const uploadFile = (file, folder) => {
        setLoading(true)
        console.log(file)
        uploadFiles({file}, {folder});
    }

    const uploadAll = () => images.map((img, index) => {
        uploadFiles(img, meta[index]);
        handleRemove(index)
    })

    useEffect(() => {
        if(errorMedia || errorUpload) setLoading(false)
    },[errorMedia, errorUpload])

    const loading = load || loadingUpload || loadingMedia || null;
    const error = errorUpload || errorMedia || null;

    const handlers = {
        updateMeta,
        setImages,
        uploadOne, 
        uploadAll,
        uploadFile,
        handleRemove
    }
    return [
        images,
        meta,
        progress,
        handlers,
        loading,
        error
    ]
}