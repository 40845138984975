import styled, {css} from "styled-components";
import {default as style} from "../../styles/panels"
import { layout, space } from 'styled-system';

const Panel = styled.div` 
    background-color: ${props => props.reversed ? style.dark : style.light };
    border-radius: ${style.borderRadius};
    position: relative;
    display: block;
    overflow: hidden;
    margin: 20px;
    ${props => props?.scroll && css` 
        max-height: ${props.height ? `${parseFloat(props.height - 100)}px`  : '320px'};
        overflow-y: scroll;
    `}

    ${props => props?.noPadding ? css` 
    ` : css` 
        padding: 16px;
    `}

    ${props => props?.overflow && css` 
        overflow: visible;
    `}

    ${props => props?.hasHeader && css` 
        /* padding-top: 5px; */
    `}
    ${props => props?.hasFooter && css` 
        padding-bottom: 0;
        /* margin-bottom: -5px; */
    `}

    ${layout}
    ${space}
`

const PartStyles = {
    Panel
};

export default PartStyles;