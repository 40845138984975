
import { Variants } from '@mosanic/constants';
import { feature } from '@redux/util';
import { hasAttr } from '@util/Obj';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { default as actions } from './action';

const initialState = {
    _id: null,
    group: null,
    type: null,
    screen: null,
    state: null,
    path: null,
    clone: null,
    repeat: null,
    overwrite: {
        variant: [Variants.BASE],
        breakpoint: [],
        state: [],
    },
    context: null,
    editor: false,
};
 
const inspectReducer = (inspect = initialState, action) => produce(inspect, draft => {

    switch (action.type) {
        case HYDRATE:
            return {...inspect, ...action.payload};

        case actions.EDITOR_SET:
            if(draft.screen != action.payload.screen) 
                draft.screen = action.payload.screen;

            draft.editor = action.payload.state;
            return draft;

        case actions.OVERWRITE_INSPECT:
            const overwriteSelector = action.payload?.type;
            const overwriteValue = action.payload?.value;

            if(!hasAttr(draft.overwrite, overwriteSelector)) return

            let source = draft.overwrite[overwriteSelector];

            draft.overwrite[overwriteSelector] = containsOverwrite(source, overwriteValue) ? 
                source.filter(s => s?.value != overwriteValue?.value) :
                [...source, overwriteValue];

            return draft;

        case actions.INSPECT_SET:
            const {component, screen, path} = action.payload;
            draft = {
                ...initialState,
                _id: component._id,
                type: component?.type,
                group: component.group,
                state: component?.state,
                clone: component?.clone,
                repeat: component?.repeat,
                context: component?.context,
                path,
                screen,
            };
            return draft;
        
        case actions.INSPECT_CLEAR: 
            draft = initialState;
            return draft;
       
        default:
            return inspect;
    }; //switch
});
export default inspectReducer;

export const containsOverwrite = (source, overwrite) => {
    if(!source) return false;
    return source.filter(o => 
        o?.value === overwrite?.value && 
        o?.type === overwrite?.type
    )?.length >= 1
}

export const inspectSelector = (state) => state.present.inspect ;//feature('inspect', state);

//Todo --> make all direct
// * it is to confusing to use a strange hook to receive this data




//Inspect element selectors
export const selectInspectItemId = (state) => inspectSelector(state)._id;
export const selectInspectItemType = (state) => inspectSelector(state).type;
export const selectInspectItemGroup = (state) => inspectSelector(state).group;
export const selectInspectItemPath = (state) => inspectSelector(state).path;
export const selectInspectItemScreen = (state) => inspectSelector(state)?.screen;

export const selectInspectItemState = (state) => inspectSelector(state).state;
export const selectInspectItemRepeat = (state) => inspectSelector(state).repeat;
export const selectInspectItemClone = (state) => inspectSelector(state).clone;

export const selectInspectContext = state => inspectSelector(state)?.context;
export const selectInspectDataProvider = state => selectInspectContext(state)?.dataProvider
export const selectInspectRepeatProvider = state => selectInspectContext(state)?.repeatProvider


export const isInspectedSelector = (state, _id) => Boolean(selectInspectItemId(state) === _id)
export const hasInspectedSelector = (state, _id) => Boolean(selectInspectItemId(state))

//Editor selectors
export const isEditingSelector = (state, _id) => Boolean(inspectSelector(state).editor)
 
//Inspect overwrite selectors
export const inspectOverwriteSelector = (state) => inspectSelector(state).overwrite;