
import { RenderText } from "@mosanic/fields";
const Editor = dynamic(() => import("@mosanic/fields/Editor"))
import { hasAttr, omitDeep } from "@util/Obj";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";


const Text = ({editor, active}) => {
    const {locale} = useRouter()
    const {source, handler} = editor || {source: {content: null}};
    let content = source?.content;
    const localized = hasAttr(content, locale);
    const handleInput = (e) => handler({value: e, extend: `content${localized ? `.${locale}` : ``}`});

    if(!content) return null;

    if(localized) content = content[locale];
    if(active) return (
        <Editor 
            content={content} 
            setContent={handleInput}
            fullToolbar={false}
            ninja 
            autoFocus={false} 
        />
    )

    return (
        Array.isArray(content) && 
        hasAttr(content[0], 'children')
    ) ? (
        <RenderText content={omitDeep(content)}/> 
    ) : null
}
export default Text; 