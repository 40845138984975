
import { Button, TextField } from '@mosanic/fields';
import Input from '@mosanic/fields/Input';
import { ControlDialog, Tooltip } from '@mosanic/items';

import { default as s } from '../Collection.styles';


import { Condition } from '@JSX';
import { elementIcon } from '@mosanic/Icons';
import { ControlButtons } from '@mosanic/items/Control';
import { colors } from '@mosanic/styles';
import { CrossCircledIcon, SizeIcon } from '@radix-ui/react-icons';
import { lowCaseContainsSubstr } from '@util/Text';
import { useState } from 'react';
import FieldTypes from './FieldTypes';


const types =  [
	{value: "text", type: 'input'},
	{value: "textarea", type: 'input'},
	{value: "email", type: 'input'},
	{value: "code", type: 'advanced'},
	{value: "visual", type: 'input'},
	{value: "link", type: 'input'},
	{value: "number", type: 'input'},
	{value: "select", type: 'input'},
	{value: "switch", type: 'input'},
	{value: "radio", type: 'input'},
	{value: "slider", type: 'input'},
	{value: "checkbox", type: 'input'},
	{value: "buttonGroup", type: 'input'},
	{value: "button", type: 'input'},
	{value: "date-time", type: 'input'},
	{value: "date", type: 'input'},
	{value: "time", type: 'input'},
	{value: "color", type: 'input'},
	{value: "image", type: 'input'},
	{value: 'markedimage', type: 'advanced'},
	{value: "gallery", type: 'input'},
	{value: "location", type: 'input'},
	{value: "file", type: 'input', coming: true},
	{value: "video", type: 'input', coming: true},
	{value: "relation", type: 'advanced'},
	{value: "user", type: 'advanced'},
	{value: "repeater", type: 'advanced'},
	{value: "label", type: 'visual'},
	{value: "help", type: 'visual'},
	{value: "accordion", type: 'visual'},
	{value: "tabs", type: 'visual'},
	{value: "group", type: 'visual'},
	{value: "row", type: 'visual', coming: true},
	{value: "column", type: 'visual', coming: true},
	{value: "steps", type: 'visual'},
	{value: "dialog", type: 'visual', coming: true},
	{value: "component", type: 'advanced', coming: true},
	{value: 'id', type: 'advanced'},
]
export const inputFields = types?.filter(t => !t?.coming)?.map(t => t.value)


 

export const FieldType = ({
    isFirstField,
    inputProps, 
    
    fieldId, 
    fieldType,
    setValue
}) => {
	

	const fieldTypes = isFirstField ? ["text"] : inputFields;
	const [state, setState] = useState(false)
    const close = () => setState(false)
    return (
        <>
        <Button style={{fontSize: '10px', fontWeight: '100'}} ghost icon={<SizeIcon />} onClick={() => setState(true)} disabled={isFirstField} label={isFirstField ? "Can't change the identifier field" : "Expand field types"} />

        <Input 
            name={`${fieldId}.type`} label="Field type" type="select" {...inputProps} required
            options={fieldTypes}
            searchable
            disabled={isFirstField}
            rules={{required: "Field type is required, otherwise you can't input data."}}
            maxMenuHeight={140}
            
        />
		{!isFirstField && (
			<ControlDialog title="Select field type" titleSize={4} size="sm" state={state} close={close}>
				<FieldTypes {...{
					state,
					close,
					types,
					fieldId,
					fieldType,
					setValue
				}}/>
			</ControlDialog>
		)}
        </>
    )
}