import { HYDRATE } from "next-redux-wrapper";
import { DISMISS_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATION } from "./action";

const initState = [];

export const notificationsReducer = (notifications = initState, action) => {
    switch(true){
        case HYDRATE:
            return {...notifications, ...action.payload};
        case action.type.includes(SET_NOTIFICATION):
            return [...notifications, action.payload];

        case action.type.includes(REMOVE_NOTIFICATION):
            return notifications.filter(notification => notification._id !== action.payload);

        case action.type.includes(DISMISS_NOTIFICATION):
            return notifications.map(notification => (
                notification._id === action.payload ? 
                    {...notification, dismissed: true} : 
                    {...notification}
            ));
        default:
            return notifications;
    }
}
export const getNotificationsSelector = (state) => state.present.notifications