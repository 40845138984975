
import { AdvancedLabel } from "@mosanic/items";
import { HelpPopper } from "@mosanic/items/ControlPanel";
import { isNumber } from "@util/Num";
import { capitalizeFirst } from "@util/Text";



export const plans = {
  'starter': 1,
  'basic': 2,
  'advanced': 3,
  'pro': 4,
  'custom': 5,
}
export const plan = (lvl) => capitalizeFirst(Object.keys(plans)[lvl]);


export const subscriptionPlans = [{
  tooltip: 'Start creating for free.',
  name: 'Starter Plan',
  id: 'prod_MJK678l0B5kMJL',
  category: 'plan',
  priceNotice: 'per team/month',
  notice: 'Includes 30 days Pro trial',
  subNotice: '*With sub domain and 1 user only',
  value: 'starter',
  label: "Start building, learn Mosanic and publish for free to Mosanic.io ",
  helpLower: "Lower than your current plan with less features, less band-width and less possibilities to make your website go fire.",
  helpHigher: "A great starting point, enjoy the benefits of exploring Mosanic and make something beautiful.",
  points: [
      {
        label: "Free mosanic.io hosting",
        help: "Your website is hosted for free on your chosen custom sub domain name!",
        key: 'slots',
        amount: 1,
        metric: ' slot'
      },{
        label: "Unlimited collections",
        help: "A collection contains data you can load in dynamically. These can contain anything, ex; projects, articles, pages.",
        key: 'all',
        amount: 'unlimited',
      },{
        label: "7-days version history",
        key: "version-history",
        amount: 7,
        metric: ' days'
      },{
        label: "1K Monthly page views",
        help: "All delivered by our blazingly fast CDN. We will let you know if you go over.",
        key: 'views',
        amount: 1,
        metric: 'K'
      }, {
        label: "Unlimited publishes",
        help: "Made some edits? No problem, you can publish them without any restrictions.",
        key: 'all'
      }, {
        label:"50 CMS entries", 
        help: "The number of items you can store in the CMS for free. Example: 25 articles, 15 pages and 10 portfolio items.",
        key: 'cms-entries',
        amount: 50,
        metric: ' entries'
      }, {
        key: 'storage',
        amount: 10,
        metric: 'GB'
      },{
        label: "15GB bandwidth",
        help: "Data transfer from our CDN. Included with 10GB file and image storage for free.",
        key: 'bandwidth',
        amount: 15,
        metric: 'GB'
      }, {
        label: "3 Un-hosted sites",
        key: 'unhosted',
        amount: 3
      }, {
        label: "Image optimization",
        key: 'all',
      }, {
        label: "Template library",
        key: 'all',
      }, {
        label: "Visual editor",
        key: 'all',
      },{
        key: 'users',
        amount: 1,
        metric: ' user'
      }, 
      ...(
        'features-req|contact-plus|whatsapp|domain|chat|support|editors|roles|canvas|addons|transfer|team|cms-addons|schedule|basic-add|ab-add|analytics-add|comm-add|api-add|local-add|shop-add'.split('|').map(key => ({key, amount: 'none'}))
      )
  ]
}, {
  tooltip: 'Build a basic mosaic in Mosanic.',
  id: 'prod_MJKGUjWbUVHslM',
  // "price_data": {
  //   recurring: {
  //     interval: 'month',
  //     "interval_count": 1
  //   },
  //   // "tax_code": "txcd_10102000",
  //   "tax_behavior": "exclusive",
  // },
  // "tax_rates": [],
  category: 'plan',
  priceNotice: 'per team/month',
  notice: 'Includes 3 users',
  subNotice: 'Add more for €[value]/user',
  subNoticeValue: 1000,
  value: 'basic', 
  addons: {
    slots: 3,
    users: 7
  },
  color: 'green',
  label: "For small teams, ready to test layouts, scale and converse visitors to customers.",
  helpLower: "Lower than your current plan with less features, less band-width and less possibilities to make your website go fire.",
  helpHigher: "5K visitors is a lot, 50GB of bandwidth as well. But if you really want to get more out of you're current plan, you can add up to 3 addons.",
  points: [
      {
        label: `All ${plan(0)} points!`,
        bolder: true
      },{
        label: "1 Custom domain slot",
        help: "You can bring your own domain name and host multiple mosanic.io slots.",
        key: 'domain',
        amount: 1,
      },{
        label: "4 mosanic.io slots",
        help: "Convert your un-hosted projects to live demo's. Share them easily with your stake holders.",
        key: 'slots',
        amount: 4,
        metric: ' slots'
      },{
        label: "14-days version history",
        key: "version-history",
        amount: 14,
        metric: ' days'
      },{
        label: "5K Monthly page views",
        help: "All delivered by our fast CDN. We will let you know if you go over.",
        key: 'views',
        amount: 5,
        metric: 'K'
      }, {
        label: "Up to 10 users per team",
        help: `By default, there are 3 users included in the ${plan(1)} plan. You can add 7 additional, for a total of 10 users.`,
        key: 'users',
        amount: 3,
        metric: '+ users'
      },{
        label:"0 CMS entries", 
        help: `CMS entries are not included in the ${plan(1)} plan, you can add up to 250 under CMS the addons. Not enough entries? Check out the ${plan(2)} Plan with 1500 CMS entries.`,
        key: 'cms-entries',
        amount: '0 - 250',
        metric: ' entries'
      }, {
        key: 'storage',
        amount: 25,
        metric: 'GB +'
      },{
        label: "50GB bandwidth",
        help: "Data transfer from our CDN. Included with 25GB file and image storage.",
        key: 'bandwidth',
        amount: 50,
        metric: 'GB +'
      }, {
        label: "10 Un-hosted sites",
        help: "Get fancy and experiment with designs, you can create up to 10 un-hosted sites in de builder. Note that sites configured in an domain slot do not count towards this limit.",
        key: 'unhosted',
        amount: 10
      }, {
        label: "Editor +",
        // help: "We've included the Editor + canvas for Basic Mosaic members as well !",
        amount: "Editor +",
        key: 'canvas'
      },{
        key: 'roles',
        amount: 3,
        metric: ' roles'
      },{
        key: 'addons',
        amount: 'Basic'
      },
      ...(
        'features-req|cms-addons|whatsapp|editors|chat|schedule|ab-add|analytics-add|comm-add|api-add|local-add|shop-add'.split('|').map(key => ({key, amount: 'none'}))
      )
  ],
},{
  tooltip: 'Data that compliments your UI.',
  id: 'prod_MJKJjn5qGjSOYI',
  category: 'plan',
  priceNotice: 'per team/month',
  notice: 'Includes 4 users + 2 editors',
  subNotice: 'Add more for €[value]/user',
  subNoticeValue: 2000,
  value: 'advanced',
  addons: {
    slots: 10,
    users: 7
  },
  color: 'orange',
  label: "Businesses that want to go to the next level. You understand the benefit of a great site, we've got the tools.",
  helpLower: "Lower than your current plan with less features, less band-width and less possibilities to make your website go fire.",
  helpHigher: "Go grazy, convert al your data fields to CMS entries, hire an content writer, analyze your users and make your designs pop with the Advanced Editor features.",
  points: [
    {
      label: `All ${plan(1)} points!`,
      bolder: true
    },{
      label: "30-days version history",
      key: "version-history",
      amount: 30,
      metric: ' days'
    },{
      label: "25K Monthly page views",
      help: "All delivered by our fast CDN. We will let you know if you go over.",
      key: 'views',
      amount: 25,
      metric: 'K'
    },{
      label:"1500 CMS entries", 
      help: "Not enough entries? You can keep adding up to 10 sets of 500 more under CMS addons.",
      key: 'cms-entries',
      amount: 1.5,
      metric: 'K+ entries'
    }, {
      label: "100GB bandwidth",
      help: "Data transfer from our CDN. Included with 50GB file and image storage. Data addons are available",
      key: 'bandwidth',
      amount: 100,
      metric: 'GB'
    }, {
      key: 'storage',
      amount: 50,
      metric: 'GB'
    },{
      label: "2 Editor accounts",
      help: `The same user limit and upgrade possibilities as the ${plan(1)} plan with the addition of 1 extra user and 2 content editor accounts.`,
      key: 'editors',
      amount: 2,
      metric: ' editors'
    }, {
      key: 'users',
      amount: 4,
      metric: '+ users'
    },{
      label: "6 user roles",
      help: `Setup your included and added users with different roles; Admin, Developer, Designer, Viewer, Analyst and Editor. Heads-up: the 2 guest content editors cannot be changed.`,
      amount: 6,
      metric: ' roles',
      key: 'roles',
    },{
      label: "A/B and Analytics*",
      help: `Coming soon! Test, track and improve the conversion by sending a percentage of you users to a different variant of an page.`,
      key: 'addons',
      amount: 'A/B testing',
    },{
      key: 'addons',
      amount: 'Analytics'
    },{
      label: "Advanced editor",
      help: `Edit your components live from the canvas. Auto Round-ups, Themes and many more features. So you work even faster in the Mosanic builder.`,
      amount: "Advanced",
      key: 'canvas'
    },{
      key: 'slots',
      amount: 7,
      metric: ' slots'
    },{
      key: 'domain',
      amount: '1 - 3',
    },{
      key: 'unhosted',
      amount: 15
    },
    ...(
        'basic-add|whatsapp|chat|schedule|comm-add|api-add|local-add|shop-add'.split('|').map(key => ({key, amount: 'none'}))
      )
      
  ],
},{
  tooltip: 'Features that bring you to the moon.',
  id: 'prod_MJKJ22GOKWPgtU',
  category: 'plan',
  // "price_data": {
  //   recurring: {
  //     interval: 'month',
  //     "interval_count": 1
  //   },
  //   // "tax_code": "txcd_10102000",
  //   "tax_behavior": "exclusive",
  // },
  priceNotice: 'per team/month',
  notice: 'Includes 10 users',
  subNotice: 'Add more for €[value]/user',
  subNoticeValue: 3000,
  value: 'pro',
  addons: {
    slots: 25,
    users: 25
  },
  color: 'primary',
  label: "You're there...",
  helpLower: "Lower than your current plan with less features, less band-width and less possibilities to make your website go fire.",
  helpHigher: (<>
    Page views aren&apos;t one of your problems, you scale, you grow. You can offer your content in multiple languages, connect to data externally and make your design 100% consistent with the pro editor tools. <br/><br/>
    Ain&apos;t enough? Discuss your needs, let us work together. Check out the <HelpPopper trigger={(<strong>enterprise</strong>)} hover span helpText="Don't let te name scare you. Just like you, we value our time. But we're here to help."/> plan.
  </>),
  points: [
    {
      label: `All ${plan(2)} points!`,
      bolder: true
    },{
      label: "75K Monthly page views",
      help: "All delivered by our blazingly fast CDN. We will let you know if you go over, additional visits are possible on Pro plans.",
      key: 'views',
      amount: 75,
      metric: 'K +'
    },{
      label:"2500 CMS entries", 
      help: "Not enough entries? You can add more under CMS addons.",
      key: 'cms-entries',
      amount: 2.5,
      metric: 'K+ entries'
    },{
      label: "90-days version history",
      key: "version-history",
      amount: 90,
      metric: ' days +'
    },{
      key: 'users',
      amount: 10,
      metric: '+ users'
    },{
      amount: 6,
      metric: '+ roles',
      key: 'roles',
    },{
      amount: 'custom',
      key: 'editors',
    },{
      label:"Private connections (API)", 
      help: "Coming soon! Built out api connectors to any homegrown or third party software systems to link your collections and data sources.",
      key: 'addons'
    },{
      label:"Localization", 
      help: "You will be able to add the localization Addon to translate your Site/ platform to as many languages as you want.",
      key: 'addons'
    }, {
      label: `Support +`,
      help: `You will be abble to submit tickets with a priority status and have a private chat channel with our team.`
    },{
      label:"Pro editor", 
      help: "Create artboards, Tokens, Mixins and stacked Variants. Feel like a absolute god and keep everything consistent. And uh.. Before we forget, you can even add your own code!",
      key: 'canvas'
    },{
      key: 'domain',
      amount: '1 - 6',
    },{
      key: 'slots',
      amount: 10,
      metric: '+ slots'
    },{
      key: 'bandwidth',
      amount: 150,
      metric: 'GB +'
    }, {
      key: 'storage',
      amount: 75,
      metric: 'GB +'
    }, {
      key: 'unhosted',
      amount: 20
    },{
      key: 'addons',
      amount: 'Ecommerce'
    },
      ...(
        'basic-add|whatsapp|comm-add|shop-add'.split('|').map(key => ({key, amount: 'none'}))
      )
  ],
}] 

export const enterprisePlan = {
    title: '📈 Enterprise',
    name: '📈 Enterprise',
    fullPrice: "custom",

    category: 'plan',
    priceAdd: '+',
    currency: 'EUR',
    id: 'SKU-MSN22-ENT-PLN005',
    priceNotice: 'Annual billing only',
    notice: 'Includes 20+ Users',
    subNotice: 'These points are the base indications',
    value: 'enterprise',
    addons: {
        slots: 99,
        users: 99
    },
    color: 'gray',
    cta: [{
        label: 'Schedule meeting',
        href: '/meeting'
    },{
        label: 'Contact us',
        href: 'mailto:enterprise@mosanic.io'
    }],
    label: (<>
        All our members get attention.

            With the enterprise package you will get a 
            <HelpPopper 
                trigger={(<u>&quot;little&quot; bit more</u>)} hover span
                helpText="Monthly onboarding, priority support, queue skipping tickets, contact addons and our focus. ❤️"
            />.

    </>),
    points: [
        {
            label: `All ${plan(3)} points!`,
            bolder: true
        },{
            label: "500K+ Monthly page views",
            help: "The sky is the limit, we can scale with your needs. We will setup a custom plan for your company alone and give full transparency about the costs and usage."
        },{
            label:"10.000+ CMS entries", 
            help: "Not enough entries? You can keep adding more without a limit under CMS addons."
        },{
            label: `180-days+ version history`,
            help: `With the possibility to discuss a 360 or more days history.`
        },{
            label: 'Custom feature priority',
            help: `Just like all premium plans you can submit feature requests. These get priority and we will work close together to fulfill your needs.`
        },{
            label: `Monthly onboarding`,
            help: `We will schedule once a month a digital onboarding to discuss your success, points of attention, needs and feature requests.`
        },{
            label: `Analytics +`,
            help: `A fully custom dashboard based on your needs, also the possibility to use Google Analytics without the 'G.A.' Addon.`
        },{
            label: `Priority Support`,
            help: `Tickets will go in front of the queue and you will be able to add the 'Whatsapp' or 'Private slack channel' Addons.`
        },{
            label: `2+ Spaces`,
            help: (<>
                A Space is the container for your hosted and un-hosted sites, with shared data, components and designs assets like tokens. <br/><br/>
                With multiple spaces you can separate these, just like if you create a second account on Mosanic but with a lot of Additional features and control. <br/><br/>
                For agencies we would like to utilize this functionally we have additional discounts and automatic space creation. 
            </>)
        },{
          label:"White labeling", 
          help: "Make it your own and receive advanced client control by adding the 'White label Mosanic' addon for Agencies."
        },{
          label:"Custom roles", 
          help: "Extend the possibilities of user roles by adding your own. Available with the 'Custom roles' addon."
        },{
          label: "spaces +", 
          key: 'team'
        },
        ...(
          'cms-entries|cdnd|slots|domain|unhosted|version-history|views|storage|bandwidth|transfer|roles|users|editors'.split('|').map(key => ({key, amount: 'custom'}))
        )
        
    ],
  }

  export const getSubScriptionPlan = (key) => isNumber(key) ? subscriptionPlans[key - 1] : subscriptionPlans.filter(plan => plan.value === key)[0]
export const getPlanById = id => subscriptionPlans.find(p => p.id === id);
