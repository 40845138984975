import delve from 'dlv';
import {get as objectGet} from 'object-path';

import {default as mrg} from 'merge'
import { dset } from 'dset';

function isObjectLike(value) {
  return typeof value === 'object' && value !== null
}
const isObject = value => isObjectLike(value);

const merge = (objA, objB, empty = {}) => {
  if(!objA || !isObject(objA)) objA = empty 
  if(!isObject(objB)) return objA;
  return mrg(objA, objB)
}
const get = (obj, path, defaultValue = undefined) => objectGet(obj, path, defaultValue)
const set = (obj, path, defaultValue = undefined) => dset(obj, path, defaultValue)

export const getByPath = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');

    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

const hasAttr = (src, attr, sub = null) => !src ? false : ( sub ? 
  (src.hasOwnProperty(attr) && src[attr]?.hasOwnProperty(sub)) :
  src.hasOwnProperty(attr) 
);

const combineAttr = (obj, other, attr) => ({...obj, [attr]: other[attr] })

function omitDeep(value, key) {
    if (Array.isArray(value)) {
      return value.map(i => omitDeep(i, key))
    }
    if(value instanceof Date) {
      return value
    }
    else if (typeof value === 'object' && value !== null) {
      return Object.keys(value)
        .reduce(
          (newObject, k) => {
            if (k == key) return newObject
            return Object.assign(
              { [k]: omitDeep(value[k], key) },
              newObject
            )
          },
          {}
        )
    }
    return value
  }
  
  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => value != null)
        .map(([key, value]) => [
          key,
          (value === Object(value) && !value instanceof Date) ? removeEmpty(value) : value,
        ]),
    );
  }

function toObject(arr, id = 'state') {
  var rv = {};
  if(!Array.isArray(arr) || arr.length <= 0) return arr;
  for (var i = 0; i < arr.length; ++i)
    rv[arr[i][id]] = arr[i];
  return rv;
}

function arrToObj(arr, obj = {}){
  if(!Array.isArray(arr)) return null;
  return arr.reduce((accumulator, value) => {
    return {...accumulator, [value]: obj};
  }, {});
}

const removeUndefined = obj => JSON.parse(JSON.stringify(obj));
const Obj = {
  get,
  set,

  merge,
  hasAttr,
  omitDeep,
  toObject,
  arrToObj,

  isObjectLike,
  removeUndefined
}
export {
  get,
  set,
  
  merge,
  
  hasAttr,
  omitDeep,
  toObject,
  arrToObj,

  isObjectLike,
  isObject,
  removeUndefined,
  removeEmpty,
  combineAttr
}

export default Obj;