import { gql } from '@apollo/client';

export const LIST_ALL_USERS = gql`
query userMany($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
	userMany(filter: $filter, limit: $limit, skip: $skip){
		email,
		isAdmin,
		isActive
		registrationDate
		lastLogin
		sites {
			siteId
		}
		teams {
			_id
			role
		}
		siteId
		altId
		role 
		stripeCustomer
		subscription {
			premium
			active
			ending
			started
			
			data
			addons
			trial
		}
		data
		author {
			firstName
			lastName
			displayName
			bio
			biography
			profilePicture
		}
		_id
	}
}
`;
export const USER_IDS = gql`
query userMany($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
	userMany(filter: $filter, limit: $limit, skip: $skip){
		author { 
			firstName
			lastName
		}
		_id 
		siteId
	}
}`
export const USER_COUNT = gql` 
query userCount($filter:FilterCountUserInput) {
  userCount(filter:$filter) 
}
`

export const FILTER_USERS = gql`
query userMany($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
	userMany(filter: $filter, limit: $limit, skip: $skip){
		email,
		isAdmin,
		isActive
		registrationDate
		lastLogin
		sites {
			siteId
		}
		teams {
			_id
			role
		}
		siteId
		altId
		role 
		status
		stripeCustomer
		subscription {
			premium
			active
			ending
			started
			
			data
			addons
			trial
		}
		author {
			firstName
			lastName
			displayName
			bio
			biography
			profilePicture
		}
		_id
	}
}
`;
export const PUBLIC_USERS = gql`
query userMany($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
	userMany(filter: $filter, limit: $limit, skip: $skip){
		email,
		altId
		author {
			firstName
			lastName
			displayName
			profilePicture
		}
		_id
	}
}
`;
export const PLATFORM_USERS = gql`
query userMany($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
	userMany(filter: $filter, limit: $limit, skip: $skip){
		email,
		siteId
		altId
		role 
		subscription {
			premium
			active
			ending
			started
			
			data
			addons
			trial
		}
		author {
			firstName
			lastName
			displayName
		}
		_id
	}
}
`;

export const FIND_USER = gql`
query userMany($filter: FilterFindManyUserInput) {
	userMany(filter: $filter){
		email 
		author {
			firstName 
			lastName
			profilePicture
		}
		status
		siteId
		altId
		role 
		isActive 
		_id
	}
}
`;


export const USER = gql`
query userById ($_id: MongoID!) {
	userById(_id: $_id){
		email
		isAdmin
		isActive
		registrationDate
		lastLogin
		_id
		author {
			firstName
			lastName
			displayName
			bio
			biography
			profilePicture
		}
		sites {
			siteId
			role
		}
		teams {
			_id
			role
		}
		status
		siteId
		altId
		role 
		data

		stripeCustomer
		pref
		subscription {
			premium
			active
			ending
			started

			data
			addons
			trial
		}
		security
		notifications
	}
}
`;
