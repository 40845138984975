import { css } from "styled-components";
import { default as w } from "./Item.wrapper.styles";

const ItemColumns = css` 
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    cursor: initial;
    pointer-events: all;
    
    &:hover {
        /* cursor: pointer; */

        ${w.RowAdd} {
            opacity: 0.2;

            &:hover {
                margin-top: -20px;
                transform: scale(1);
                opacity: 1;
            }
        }
    }    
`;

export {
    ItemColumns
};
