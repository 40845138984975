import { Button } from '@mosanic/fields/Button/Buttons';
import { CenterCenter } from '@mosanic/styles';
import { removeLastChar } from '@util/Text';
import styled from 'styled-components';

const Container = styled.div` 
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: #fff;
    ${CenterCenter}
`;
const Content = styled.div` 
    text-align: center;
    
    button {
        margin: 0 auto;
    }
`
const EmptyRows = ({title, label, plural = 'entries', singular = 'entry', createCallback = null}) => (
    <Container style={{ textAlign: 'center', gridColumn: '1/-1' }}>
        <Content>
            <h2>
                {title ? title : `No ${plural} found.`}
            </h2>
            <p>
                {label ? label : `Let's start with creating your first ${singular}.`}
            </p>
            <Button onClick={() => createCallback()} label="Create" size="large"/>
        </Content>
    </Container>
);
export default EmptyRows