import { gql } from '@apollo/client';

/** Auth login */
export const LOGIN = gql`
mutation loginUser($email: String!, $password: String!, $verify: Boolean, $templateId: String, $mailData: MailDataInput, $from: String) {
	loginUser (email: $email, password: $password, verify: $verify, templateId: $templateId, mailData: $mailData, from: $from) {
		response
		status
		token
	}
}
`;
export const AUTH_LOGIN = gql`
mutation authUser($email: String!, $code: String!) {
	authUser (email: $email, code: $code) {
		token
	}
}
`;


/** Forgot password rests */
export const RESET_PASSWORD = gql`
mutation resetPassword($email: String!, $templateId: String, $mailData: MailDataInput, $from: String ) {
	resetPassword (email: $email, templateId: $templateId, mailData: $mailData, from: $from) {
		response 
		status
	}
}
`;

export const AUTH_RESET_PASSWORD = gql`
mutation authResetPassword($email: String!, $code: String!, $newPassword: String) {
	authResetPassword (email: $email, code: $code, newPassword: $newPassword) {
		token
	}
}
`;




/** Register */
export const REGISTER = gql`
mutation registerUser($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
	registerUser (email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
		token
	}
}
`;
/** Account changes */
export const CHANGE_PASSWORD = gql`
mutation authResetPassword($password: String!, $oldPassword: String!) {
	authResetPassword (password: $password, oldPassword: $oldPassword) {
		email
	}
}
`;

export const DELETE_ACCOUNT = gql`
mutation deleteMyUserAccount {
	deleteMyUserAccount {
		deletedCount
	}
}
`;