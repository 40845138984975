import { HeadingTag } from '@JSX';
import { extendClone } from '@mosanic/utils/clone';
import { CrossCircledIcon } from '@radix-ui/react-icons';
import Event from '@util/Event';
import { isNumber } from '@util/Num';
import dynamic from 'next/dynamic';
// import Dialog from 'rc-dialog';
const Dialog = dynamic(() => import('rc-dialog'), {
  ssr: false
})

import { useState } from "react";
import Draggable from 'react-draggable';

const sizes = {
    xxs: '250px',
    xs: '400px',
    small: '600px',
    sm: '600px',
    md: '700px',
    large: '800px',
    xl: '1000px',
    xxl: '1150px'
}

const ControlDialog = ({
  children, 
  title = null, 
  state = false, 
  close = null, 
  size = 'sm', 
  sizing = null,
  ghost= false, 
  titleSize = 2, 
  trigger = null,
  wrapTrigger = false,
  bodyProps= null,
  headingProps= null,
  ...other
}) => {
  const [localState, setLocalState] = useState(false);
  const isOpen = Boolean(trigger) ? localState : state;

  const [disabled, setDisabled] = useState(true);

  const onClose = (event, force = false) => {
    if(Event.isValidCurrentTarget(event) || force) {
      trigger ? 
        setLocalState(false) :
        close ? close() : null
  
    }
  };

  // useEffect(() => {
  //   const escFunction = (event) => {
  //     if (event.key === "Escape") close(event, true)
  //   }
  //   document.addEventListener("keydown", escFunction, false);
  //   return () =>  document.removeEventListener("keydown", escFunction, false);
  // })

  const getSizeValue = value => isNumber(value) ? `${value}px` : value;
  
  return (
    <>
    {trigger && (
      wrapTrigger ? (
        <span onClick={() => setLocalState(true)}>
          {trigger}
        </span>
      ) : extendClone(
        {onClick:() => setLocalState(true)},
        trigger
      )
    )}
    <Dialog 
      visible={isOpen} 
      onClose={onClose}
      animation="fade"
      wrapStyle={{pointerEvents: ghost ? 'none' : 'initial'}}
      maskClosable={!ghost}
      mask={!ghost}
      maskAnimation="fade"
      bodyProps={{style: {
        alignSelf: "center",
        marginTop: other?.minimize ? '-10px' : 0,
        padding: ghost ? '0px 20px 20px' : '15px',
        position: 'relative',
        height: sizing?.height ? getSizeValue(sizing?.height) : null, 
        maxHeight: '90vh',
        overflowX: 'hidden',
        overflowY: sizing?.height ? 'auto' : 'auto', 
        ...bodyProps,
        ...other
      }}}
      style={{ 
        // pointerEvents: 'all',
        width: sizing?.width ? getSizeValue(sizing.width) : size ? sizes[size] : 600, 
        // overflow: sizing ? 'scroll' : null,
        maxWidth: '95vw',
        zIndex: 0,
      }}
      title={title ? (
          <HeadingTag
            level={titleSize}
            style={{
                pointerEvents: 'all',
                width: '90%',
                cursor: 'pointer',
                color: '#212121',
                ...headingProps,
            }}
            onMouseOver={() => (disabled) ? setDisabled(false) : null}
            onMouseOut={() => setDisabled(true)}
            onFocus={ () => {} }
            onBlur={ () => {}} 
          >
            {title}
          </HeadingTag>
      ) : null}
      closeIcon={<CrossCircledIcon onClick={onClose}/>} 
      modalRender={modal => title ? (
        <Draggable disabled={disabled} style={{pointerEvents: 'all'}}>
          <div style={{
            boxShadow: ghost ? '5px 5px 15px 0 rgba(0,0,0,0.1)' : '5px 0px 35px 0 rgba(0,0,0,0.2)',
            pointerEvents: 'all',
          }}>
            {modal}
            
          </div>
        </Draggable>
      ) : (
        <div style={{boxShadow: ghost ? '5px 5px 15px 0 rgba(0,0,0,0.1)' : '5px 0px 35px 0 rgba(0,0,0,0.2)'}}>
          {modal}
        </div>
      )}
    >
      {children}
      {/* <ClearBoth /> */}
    </Dialog> 
  </>
  );
}
export default ControlDialog 