import styled, {css} from "styled-components";

const Large = css` width: 42px; height: 26px; `
const Small = css` margin-top: 5px; width: 30px; height: 16px; `
const Xs = css` margin-top: 0px; width: 24px; height: 12px; `

const ControlCircleLarge = css` width: 18px; height: 18px; `
const ControlCircleSmall = css` width: 9px; height: 9px; `
const ControlCircleXs = css` width: 6px; height: 6px; `

const DisplayLabel = styled.div` 
    font-size: 12px;
    display: inline-block;
    margin-top: -5px;
    padding: 0 0 5px 10px;
`
const SubLabel = styled(DisplayLabel)` 
  max-width: 300px;
  margin-left: 30px;
  display: block;
  opacity: 0.5;
`
const CheckBoxLabel = styled.label`
    position: absolute;
    top: 3px;
    left: 0;
    ${Small}

    border-radius: 15px;
    background: #bebebe;
    cursor: pointer;

    &:hover {
        &::after {
            transform: scale(1.1);
        }
    }

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        ${ControlCircleSmall}
        margin: 3px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`;
const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  ${Small}

  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      ${ControlCircleSmall}
      margin-left: 16px;
      transition: 0.2s;
    }
  }
  background: red!important;
`;

const CheckBoxWrapper = styled.div`
  position: relative;

  ${props => props.size === 'large' && css` 
    ${CheckBoxLabel} {
        ${Large}
        &::after { ${ControlCircleLarge} }
    }
    ${CheckBox} {
        ${Large}
        &:checked + ${CheckBoxLabel}:after { 
            ${ControlCircleLarge}
            margin-left: 21px;
        }
    }
    ${DisplayLabel} { font-size: 16px; vertical-align: middle; }
  `}

  ${props => props.size === 'xs' && css` 
    ${CheckBoxLabel} {
        ${Xs}
        &::after { ${ControlCircleXs} }
    }
    ${CheckBox} {
        ${Xs}
        &:checked + ${CheckBoxLabel}:after { 
            ${ControlCircleXs}
            margin-left: 15px;
        }
    }
    ${DisplayLabel} { font-size: 12px; }
  `}

  ${props => props.disabled && css` 
    ${CheckBoxLabel} {
        background: #eee;
        transition: all ease-in-out 0.2s;
        &:hover {
            opacity: 0.8;
        }
    }
  
  `}
`;

const SwitchStyles = {
    CheckBoxWrapper,
    CheckBoxLabel,
    CheckBox,
    DisplayLabel,
    SubLabel
}
export default SwitchStyles;
