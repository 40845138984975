import { createContext } from "react";
import { useContextHandler } from "./Helpers";

const PageContext = createContext(undefined);
const HeaderContext = createContext(undefined);
const FooterContext = createContext(undefined);
  
export const PageProvider = ({entry, pageData, children}) => (
  <PageContext.Provider value={{entry, ...pageData}}>
    {children}
  </PageContext.Provider>
) 
export const HeaderProvider = ({nav, locales, children}) => (
  <HeaderContext.Provider value={{nav, locales}}>
    {children}
  </HeaderContext.Provider>
) 
export const FooterProvider = ({nav, locales, children}) => (
  <FooterContext.Provider value={{nav, locales}}>
    {children}
  </FooterContext.Provider>
) 


export const usePageContext = () => useContextHandler(PageContext, 'PageContext')
export const useHeaderContext = () => useContextHandler(HeaderContext, 'HeaderContext')
export const useFooterContext = () => useContextHandler(FooterContext, 'FooterContext')