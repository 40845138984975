import { colors } from "@mosanic/styles";
import { capitalizeFirst, isString } from "@util/Text";
import { default as Animated } from 'react-select/animated';
import { WrapInput } from "../../items/Inputs";
// import { Select as Selector } from "./External";
const Selector = dynamic(() => import('./External'))

import dynamic from "next/dynamic";
import { useState } from "react";
import { InputStyles as s } from "../Input";
import TextField from "../TextField/Fields";

const animatedSelect = Animated();

const selectStyles = (labelSize = '12px', minWidth = null, indicator = 'block') => ({
  control: base => ({
    ...base,
    // border: `0.5px solid ${colors.util.gray.lightest}`,
    minHeight: '27px',
    border: 'none',
    boxShadow: 'none',
    background: 'none'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    // padding: '2px 5px',
    padding: 0,
    minHeight: '20px',
    opacity: (
        state?.selectProps?.focussed || 
        state?.hasValue
      ) ? 1 : 0,
  }),
  option: (provided, state) => ({
    ...provided,
    zIndex: 5,
    fontSize: labelSize,
    color: state.isSelected ? colors.util.primary.base : 'initial',
    background: state.isSelected ? colors.util.gray.lighter : 'transparent',
    padding: '5px 10px',
    width: 'auto',
    '&:hover': {
      background: colors.util.gray.lighter,
      cursor: 'pointer'
    }
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: colors.util.gray.lighter,
    // fontSize: '12px',
    width: 'fit-content',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: '12px',

    // padding: '5px 10px',
  }),
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    padding: 0,
    minWidth,
    // border: `1px solid ${colors.util.gray.lightest}`,
    border: 'none',
    '&:hover': {
      border: 'none'
    }
  }),
  menuPortal: base => ({
    ...base, 
    zIndex: 99
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    display: 'flex',
    '& svg': {
      transform: 'scale(0.7)'
    }
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
})

export const Select = ({
    fieldName = null,
    label = null,
    name = null,
    tooltip= null,

    options = [],
    wrap = false,
    value = null,

    defaultValue = null,
    
    multiple = false,
    searchable = false,
    clearable = true,
    autoClose = false,

    animated = true,
    wrapperClass = null,

    handler = () => null,
    onChange = null,
    required = false,
    labelSize=  '12px',
    menuIsOpen = undefined,
    fullWidth= false,
    loading = false,
    disabled = false,

    getValue = e => e,
    getOptionValue = e => e?.value,
    getOptionLabel = e => e?.label ? e.label : e,

    ...other
}) => {
   const [focussed, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);
    const shrink = Boolean(focussed || value || ( 
      !value && options?.filter(o => !o?.value) //Has unset value
    ));

    if(!options[0]?.label){
        options = options.map(option => {
            return !option?.label ? 
                {value: option, label: capitalizeFirst(option)} : 
                option;
        })
    }
    const handleSelect = (val) => {
      if(disabled) return;
      if(!onChange && handler) onChange = handler;
      
      //Get only the value field
      if(multiple) {
        onChange(val.map(o => getOptionValue(o)))
      } else {
        onChange(getOptionValue(val))
      }
    }

    //Get option from received value [option]
    const getOption = option => {
      //Check if option is correctly passed
      if(getOptionValue(option)) option = getOptionValue(option)
      //Get multiple single option
      if(Array.isArray(options)) return options?.find(o => getOptionValue(o) === option);

      //Fallback
      return option
    }

    const renderValue = () => {
      if(multiple && value) return (
        Array.isArray(value) ?
          value.map(option => getOption(option)) :
          [getOption(value)]
      )
      return value ? getOption(value) : value
    }


        //       value={Array.isArray(value) && !value?.[0]?.label ? (
        //     value?.map(v => getCollectionOptions(collections, !_idOnly)?.find(o => o.value === v))
        // ) : value}
        // onChange={o => other?.multiple ? onChange([...o?.map(o => o?.value)]) : onChange(o.value)}
    return (
      <WrapInput wrap={wrap ? wrap : false} fullWidth={fullWidth}>
        {/* {(fieldName || label) && <AdvancedLabel label={label ? label : capitalizeFirst(fieldName)} helpText={tooltip} my={"-2px"} />} */}
        <s.Container 
          className="input_field"
          focussed={focussed}
          style={other?.container}
        >
        <s.Label shrink={shrink}>
            {
              label ? label :
              fieldName ? fieldName : 
              name ? name : ""
            }
            {required && <s.Required />}
          </s.Label>
          
          {options?.length >= 1 ? (
        <Selector 
            // getOptionLabel={getOptionLabel}
            disabled={disabled}
            isDisabled={disabled}
            focussed={focussed}
            options={options} 
            value = {renderValue()}
            isLoading={loading}
            defaultValue={value}
            onChange={handleSelect}
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={autoClose}
            isClearable={clearable}
            isSearchable={searchable}
            isMulti={multiple}
            minWidth="400px"
            styles={selectStyles(labelSize, other?.minWidth, other?.indicator)}
            components={animated ? animatedSelect : null}
            className={wrapperClass}
            {...other}
            onFocus={(e) => {
                if(other?.onFocus) other.onFocus(e)
                onFocus();
            }} 
            onBlur={(e) => {
                if(other?.onBlur) other.onBlur(e)
                onBlur();
            }}
        />): (
          <s.Field value={isString(value) ? value : `${value?.length ? value.length : '0'} entries`} disabled={disabled}/>
        )}
        </s.Container>
      </WrapInput>
    )
}
export default Select;