import { Condition } from "@JSX";
import { Link, Tooltip } from '@mosanic/items';
import { colors } from "@mosanic/styles";
import styled, {css} from "styled-components";
import { ButtonGroup } from "../Button/ButtonGroup";
import Button from "../Button/Buttons";
import { space, layout } from "styled-system";

const StyledTab = styled.div` 
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: none!important;
    margin: 0;
    transition: background-color ease-in-out 0.2s;
    background: ${props => props.active ? colors.util.gray.lighter : 'transparent'};
    border-color: ${colors.util.primary.light};
    border-bottom: ${props => props.active ? `1px solid ${colors.util.primary.base}` : `none`};
    font-size: 12px;
    padding: 5px 15px;
    svg {
        margin: 5px;
    }
    ${props => !props.active && css` 
        &:hover {
            border-bottom: 1px solid ${colors.util.primary.lighter};
            background: ${colors.util.gray.lightest};
        }
    `}
`
const StyledTabs = styled.div` 
    display: flex;
    width: auto;
    border-bottom: 1px solid ${colors.util.gray.lighter};
    ${space}
    ${layout}
`
export const Tabs = ({
    children,
    ...other
}) => (
    <StyledTabs {...other}>
        {children}
    </StyledTabs>
)
export const Tab = ({
  children, 
  handler, 
  onClick = null,

  label = null,
  size = 'small', 

  theme = "",
  variant = "outlined",
  customClass = '', 
  icon = false,


  scale = false,
  maxW = false,

  active = false,
  disabled = false,
  noVal = false, 

  cell = false,
  style = null, 
  bold = false,
  tooltip = null,
  
  wrap = false,
  href = null,
  target = null,
  type = "button",
  lines = 1,
  ...other
}) => (
  <Condition
    when={tooltip}
    wrapper={children => 
      <Tooltip 
        title={tooltip?.title ? tooltip.title : tooltip} 
        wrap={tooltip?.wrap ? tooltip.wrap : false}
        placement={tooltip?.placement ? tooltip.placement : null} 
      >
        {children}
      </Tooltip>}
  >
  <Condition
    when={href}
    wrapper={children => <Link href={href} target={target}>{children}</Link>}
  >
    <StyledTab 
      onClick={handler ? handler : onClick} 
      className={`coreButton ${customClass}`} 
      sz={size} 
      theme={theme}
      variant={variant} 

      icon={icon}
      // scale={scale}


      active={active}
      disabled={disabled} 
      noVal={noVal}


      bold={bold}
      style={style}
      type={type}
      lines={lines}
      {...other}
    >
      {icon ? icon : ''}
      {label ? label : ''} 
      {!icon && !label && (
        children
      )}
       
    </StyledTab>
  </Condition>
  </Condition>
);
export default Tab;