import { Children, cloneElement } from "react";

export const If = ({children, condition}) => condition ? children : null;
export const Else = ({children, condition = null, invertCondition = true}) => 
    invertCondition ? 
        !condition ? children : null :
        condition ? children : null;

export const When = ({children, condition}) => <If condition={condition}>{children}</If>


const controls = {
  CHOOSE: "Choose",
  WHEN: "When",
  ELSE: "Else"
};

/**
 * Choose blocks are the wrapper for when and else statements
 */
export const Choose = ({children, condition = null}) => {
    let whenRef = {
        hasStatement: false,
        hasTrueCondition: false,
    }
    if(children.length <= 0) {
        console.warn(`<${controls.CHOOSE}> block doesn't contain any children.`)
    }
    const render = Children.map(children, child => {
        const childType = child.type.name;
   
        switch (childType) {
            case controls.WHEN:
                whenRef.hasStatement = true;
                if(child.props.condition) whenRef.hasTrueCondition = true;
                return child;

            case controls.ELSE:
                if(!whenRef.hasTrueCondition){
                    return cloneElement(child, {
                        condition: true, 
                        invertCondition: false
                    });
                } else {
                    return cloneElement(child, {
                        condition: false,
                        invertCondition: false
                    });
                }
            default:
                console.error(`Direct children of <${controls.CHOOSE}> statements can only be <${controls.WHEN}> and/or <${controls.ELSE}>.`);
                break;
        }
    })
    if(!whenRef.hasStatement) {
        console.error(`A <${controls.CHOOSE}> statement should always contain an When statement.`);
    }
    return render;

}

