import { Editor, Element as SlateElement, Text, Transforms } from 'slate'
import { LIST_TYPES, TEXT_ALIGN_TYPES } from '../Constants'
import Validate from "../Validate"


const toggleBlock = (editor, format) => {
  const isActive = Validate.isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : 
              isList ? 'list-item' : format,
    }
  }
  
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleFormat = (editor, format) => {
  const isActive = Validate.isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  )
}


const toggleMark = (editor, format) => {
  const isActive = Validate.isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}



const Toggle = {
  toggleBlock,
  toggleFormat,
  toggleMark
}

export default Toggle;