import { gql } from '@apollo/client';


export const SEND_MAIL = gql`
query mailSendOne($to: String!, $from: String!, $mailData: MailDataInput!, $templateId: String) {
	mailSendOne(to: $to, from: $from, mailData: $mailData, templateId: $templateId){
		response
	}
}
`;



export const MAIL_COUNT = gql` 
query mailCount($filter:FilterCountMailInput) {
  mailCount(filter:$filter) 
}
`

export const FILTER_MAILS = gql`
query mailMany($filter: FilterFindManyMailInput, $limit: Int, $skip: Int) {
	mailMany(filter: $filter, limit: $limit, skip: $skip){
		title
		_id
		description
		type
		limit
		# config{
		# 	senderName
		# 	defaultSender 
		# 	defaultReceiver 
		# 	data 
		# }
		collectionId 
		schema 
		entries 
		status 
		templates
		date 
		updatedAt 
		userId 
		siteId 

		trigger 
		data 
		updates


	}
}
`;

export const MAIL = gql`
query mailById($_id: MongoID!) {
	mailById(_id: $_id){
		title
		_id
		description
		type
		limit
		templates
		# config{
		# 	senderName
		# 	defaultSender 
		# 	defaultReceiver 
		# 	data 
		# }
		collectionId 
		schema 
		entries 
		status 
		date 
		updatedAt 
		userId 
		siteId 

		trigger 
		data 
		updates


	}
}
`;