import { TextArea, TextField } from "@mosanic/fields";
import { canMap } from "@util/Arr";
import { getSensitizedString, hasOnlyNumbers, removeSpaces, toLowCase } from "@util/Text";
import { useEffect, useState } from "react";

const getVal = (event, strict = false) => !event.target?.value ? null :
  strict === 'extreme' ? 
    getSensitizedString(
      removeSpaces(
        toLowCase(event.target.value)
      )
    ) :
  strict ? 
    getSensitizedString(
      event.target.value
    ) :
    event.target.value;


export const RenderTextFields = ({handlers}) => (
  handlers.map((handler, i) => !handler?.type ? (
      <TextField {...handler} key={i} wrap/>
    ) : handler?.type === 'text-area' ? (
      <TextArea {...handler} key={i} rows={3} fullWidth wrap />
    ) : handler?.label)
)
export function useTextFields({
  fields = [],
  initData = {},
  size = 'small', 
  fullWidth = false,
  strict = false
}) {
  const [data, setData] = useState(initData)
  const update = (lbl, val) => {
    setData({
      ...data,
      [lbl]: val
    })
  }
  let handlers = []

  handlers = canMap(fields) ? fields?.map(field => ({
      ...field,
      label: field?.label,
      onChange: (e) => update(field?.label, getVal(e, strict)),
      value: data?.[field?.label] ? data[field.label] : '',
      fullWidth,
      size,
  })) : [];
  return [
    handlers,
    setData
  ];
}
export function useTextField({
  value = null, 
  label, 
  handler = null, 
  size = 'small', 
  fullWidth = false,
  placeholder = null,
  strict = false,
  autoFocus = false,
  type = 'text',
  validate = null,
  ...other
}) {
  const [updatedValue, setUpdatedValue] = useState(value ? value : '');
  const onChange = (event) => !handler ? 
    setUpdatedValue(getVal(event, strict)) :
    handler(getVal(event, strict));
  
  const getValidated = () => (handler ? value : updatedValue) ?
    validate(handler ? value : updatedValue) ? 'true' : 'false' :
    null;

  return {
    label,
    value: handler ? value : updatedValue,
    onChange,
    size,
    fullWidth,
    placeholder,
    autoFocus,
    type,
    valid: validate ? getValidated() : null,
    setValue: setUpdatedValue,
    ...other
  }
}

export function useNumField({value, label, handler = null, size = 'small', fullWidth = false}) {
  const [updatedValue, setUpdatedValue] = useState(value);
  const onChange = (event) => {
    if(!hasOnlyNumbers(event.target.value)) return;
    handler ? handler(event.target.value) : setUpdatedValue(event.target.value);
  }

  return {
    label,
    value: updatedValue,
    onChange,
    size,
    fullWidth
  }
}