
const getNodeScale = ({className}) => {
    const target = document.getElementsByClassName(className)[0];
    if(!target) return null;
    const scale = target.getBoundingClientRect().width / target.offsetWidth;
    // console.log(target.style)

    // var style = getComputedStyle(target, null);
    // var style = getComputedStyle(target, null);

    // console.log(style.getPropertyValue('transform'));
    // console.log(style.getPropertyValue('translate'));
    // console.log(style.getPropertyValue('scale'));
    // console.log('======');

    // console.log(window.screen.width)
    return scale;
}

const getParentNode = ({parent, findClass, stopCount = 25, count = 0}) => {
    if(parent?.classList?.contains(findClass)) return parent;
    if(count >= stopCount) return null
    if(!parent?.parentNode) return null;

    return getParentNode({parent: parent?.parentNode, findClass, stopCount, count: count +1});
};

const Node = {
    getScale: getNodeScale,
    getParent: getParentNode
}

export {
    getNodeScale,
    getParentNode
}

export default Node;