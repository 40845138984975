import { isString } from "./Text";


const roundPrecise = (number, decimalPlaces = 2) => Number( Math.round(number + "e"+decimalPlaces) + "e-" + decimalPlaces );

const round5 = (number) => Math.ceil(number/5)*5;

const roundTo = (number, to) => {
    if(isString(number)) number = parseFloat(number);
    if(isString(to)) to = parseFloat(to);

    return Math.ceil(number/to)*to;
}

const Round = {
    round5,
    roundPrecise,
    roundTo
}
export {
    round5,
    roundPrecise,
    roundTo
};
export default Round;