import { gql } from '@apollo/client';

export const ADD_COLLECTION = gql`
mutation collectionCreateOne($record: CreateOneCollectionInput!) {
	collectionCreateOne (record: $record) {
		recordId
		record {
			title
			description
			schema
			entries
			excerpt 
			date 
			updatedAt 
			status 
			userId 
			siteId 
			_id
		}
		error {
			message
		}
	}
}
`;

export const UPDATE_COLLECTION = gql`
mutation collectionUpdateById($_id: MongoID!, $record: UpdateByIdCollectionInput!) {
	collectionUpdateById (_id: $_id, record: $record) {
		recordId
		record {
			title
			description
			schema
			entries
			excerpt 
			date 
			updatedAt  
			status 
			userId 
			siteId 
			_id
		}
		error {
			message
		}
	}
}
`;

export const REMOVE_COLLECTION = gql`
mutation collectionRemoveById($_id: MongoID!) {
	collectionRemoveById (_id: $_id) {
		recordId
	}
}
`;