// const FieldIcons = dynamic(() => import("@mosanic/Icons/FieldIcons"))
import FieldIcons from "@mosanic/Icons/FieldIcons";
import { parseIcon } from "@mosanic/_Fieldsets";
import { hasAttr } from "@util/Obj";
import { WrapInput } from "../../items/Inputs";
import { Button } from "./Buttons";

import { ButtonGroupStyles as s } from "@mosanic/styles";
import { Tooltip } from "@mosanic/items";
import dynamic from "next/dynamic";
import { capitalizeFirst, isString } from "@util/Text";

import { InputLabel, InputRequired } from "../Input/Input.styles";
export const ButtonGroup = ({
    children, 
    theme = '',
    size = 'small', 
    variant = 'outlined', 
    noBorder = true, 
    extraClass = '', 
    active = false,
    disabled = false,
    wrap = false,
    tooltip = null,
    isText = true,
    fullWidth = false,
    label = null,
    name = null,
    required = false,
    ...other
}) => (
    <Tooltip title={tooltip} placement="left">
        <WrapInput wrap={wrap ? 1 : 0} fullWidth={fullWidth}>
            <InputLabel shrink>
                {
                    label ? label :
                    name ? name : ""
                }
                {required && <InputRequired />}
            </InputLabel>
            <s.ButtonGroup 
                size={size} 
                theme={theme}
                variant={variant} 
                active={active}
                disabled={disabled} 
                isText={isText}
                className={` ${noBorder ? `-noBorder` : ``} ${extraClass}`}
                {...other}
            >
                {children}
            </s.ButtonGroup>
        </WrapInput>
    </Tooltip>
);


const isActive = (value, optionValue) => !Array.isArray(value) ?
    value == optionValue :                          // single select
    value.reverse().filter(([key, keyValue]) => {   // multi select
        if(key === 'all' && keyValue) return true // all
        if(key === optionValue) return keyValue
    })[0];

const getValue = (option) => option?.label ? option.value : option;
const getLabelValue = (option) => option?.value ? option.value : option;

const Label = ({labelFormat, iconGroup, option}) => (
    parseIcon(labelFormat) && hasAttr(FieldIcons, iconGroup, getLabelValue(option))
) ? FieldIcons[iconGroup][getLabelValue(option)] : (
    option?.label  ? 
        option?.label : 
        isString(option) ? 
            capitalizeFirst(option) : 
            ''
);

ButtonGroup.Choices = ({
    options = [], 
    value = null,
    handler = null,

    labelFormat = 'text', 
    iconGroup = null,
    ...other
}) => Array.isArray(options) ? options.map(option => (
    <ChoiceButton 
        {...other}
        type="button"
        active={isActive(value, getValue(option))}
        label={<Label 
            labelFormat={labelFormat} 
            iconGroup={iconGroup} 
            option={option} 
        />}
        handler={() => handler(getValue(option))}
        tooltip={option?.tooltip}
        key={option?.label ? option.label : option}
    />
)) : null

const ChoiceButton = ({active, label, tooltip = null, handler, ...other}) => (
    <Button 
        {...other}
        active={active}
        handler={handler} 
        tooltip={tooltip}
    >
        {label}
    </Button>
);
