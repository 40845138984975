import { itemSelector } from "@redux/map/data";
import { useRouter } from 'next/router';
import { useSelector } from "react-redux";

import useItemDrag from "@mosanic/hooks/useItemDrag";
import useItemHover from "@mosanic/hooks/useItemHover";
import useItemInspect from "@mosanic/hooks/useItemInspect";

import { appendControl } from "@mosanic/utils/controlContext";
import { isDevMode, isProdMode, isSeoMode } from "@mosanic/utils/map/mode";

import { canVariantLocaleSelector } from "@redux/options/reducer";
import { useDataProvider, usePageContext, useRelationProvider, useRepeatProvider } from "../Provider";
import Item from "./ChildItem";
import Layer from "./ChildLayer";
import Prod from "./ChildProd";
import Seo from "./ChildSeo";


const Child = ({children, child, path, level, breakpoint, data, mode, ...other}) => {
    const props = {children, child, path, level, data, breakpoint};
    const router = useRouter() 
    
    const ctxLocale = useSelector(canVariantLocaleSelector)


    if( isDevMode(mode) ) return <DevChild mode={mode} props={props} {...other} />;
 
    let item = data?.[child?.group]?.[child?._id];
    const fieldAction = item?.fields?.action;
    const showLocale = Boolean(fieldAction === 'locale' || ctxLocale)
    item = appendControl(
        item, 
        other?.controlProps, 
        showLocale ? router?.locale : null
    );
    
    // if(other?.controlProps) console.log(other?.controlProps)

    //Render with base props
    if( isProdMode(mode) ) return <Prod props={props} item={item} {...other} />
    
    if( isSeoMode(mode) ) return <Seo props={props} item={item} />
}
 

 
const DevChild = ({mode, props, ...other}) => {
    const router = useRouter() 

    const {breakpoint, path, child, data} = props;
    const map = {...child, path};
 
    //Get item from store
    let item = useSelector(state => 
        itemSelector(state, props.child)
    );

    const fieldAction = item?.fields?.action;
    
    if(!item || (data && data?.[child?._id])) {
        item = data?.[child?._id]
    } 

    if(item && data?.extend){
        item = {
            ...item,
            ...data.extend
        }
    }

    const ctxLocale = useSelector(canVariantLocaleSelector)
    const showLocale = Boolean(fieldAction === 'locale' || ctxLocale)

    //Add other control options to item 'states'
    item = appendControl(
        item, 
        other?.controlProps, 
        showLocale ? router?.locale : null
    );

    
    const dataProvider = useDataProvider()
    const repeatProvider = useRepeatProvider()
    const pageProvider = usePageContext()
    const relationProvider = useRelationProvider()

    // console.log(dataProvider)

    const {ref, isDrag} = useItemDrag({map})
    const inspect = useItemInspect({item, ref, breakpoint, map, mode, 
        data: {
            clone: other?.clone, 
            repeat: {
                collection: other?.collection
            },
            context: {
                dataProvider,
                repeatProvider,
                relationProvider,
                pageProvider
            }
        }
    })
    const hover = useItemHover({item, ref, breakpoint, map, clone: other?.clone})

    const extendedProps = {...props, item, inspect, hover, ref, isDrag};

    //Render interactive dev layer
    if(mode === 'layers') return (
        <Layer props={extendedProps} {...other} />
    );
    //Render dev item container
    return item ? (
        <Item props={extendedProps} {...other}>
            {props.children}
        </Item>
    ) : props?.children ? props.children : null;
}


export default Child;