

import { SelectEntry } from "@mosanic/core/Collections";
import { AdvancedLabel, ControlPopper, Expander, Tooltip } from "@mosanic/items";
import { List, ListItem } from "@mosanic/items/List";
import { ListItemIcon } from "@mosanic/items/List/List";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronRightIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { capitalizeFirst, combineString, containsSubstr, excerptString, isString, replaceParts, revertDevCases } from "@util/Text";
import { getEntryDate, getEntryLabel, getField, hasPotentialSubFields, mappableSubFields } from "../helpers";
import { loopFunc, remove } from "@util/Arr";
import { SkeletonLine } from "@mosanic/items/Skeletons";
import { ButtonGroup } from "@mosanic/fields/Button/ButtonGroup";
import Button from "@mosanic/fields/Button/Buttons";
import { InlineGroup } from "@mosanic/items/Inputs";
import TextField from "@mosanic/fields/TextField/Fields";
import { useSelector } from "react-redux";
import { getDevModePrefSelector } from "@redux/options/reducer";
import { Condition } from "@JSX";
import dynamic from "next/dynamic";
import { parseImageSrc } from "@mosanic/utils/Image";
import Image from "next/image";
import { isFunc } from "@util/Funct";
import styled from "styled-components";
import isEmpty from "@util/Empty";
import { get } from "@util/Obj";
import { createSchema } from "../useSources";
import { colors } from "@mosanic/styles";
import { fieldSchemas } from "@mosanic/core/Collections/Select/SelectField";
import { isNumber } from "@util/Num";
import { useGetUser } from "@mosanic/cms/Query/byId";

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })


//Data provider
const getProviderEntryLabel = value => {
    if(Array.isArray(value)) return `List of ${value.length} entries`;
    return `${Object.keys(value)?.length} sub groups`
}

const removePath = title => title?.split('.')?.length >= 2 ? title?.split('.')[1] : title;

const getTitle = title => excerptString(revertDevCases(replaceParts(title, '&amp;', '&')), 14, '...')

const renderFieldData = (field, data) => {
    const img = parseImageSrc(data, true)?.thumb;
    if(img) return <Image src={img} width="20px" height="20px" alt=""/>

    return (isString(data) && data) ? 
        data.length >= 200 ? `[text] with ${data.length} chars` : excerptString(data, 14, '...') : 
        data ? `[${field?.type}] with value` : data ? JSON.stringify(data) : `[${field?.type}]`
}



const loader = (count = 3) => loopFunc(
    count <= 10 ? count : 10, 
    () => (
        <ListItem>
            <SkeletonLine />
        </ListItem>
    )
)

const Item = ({actions, trigger, title}) => (
    <ControlPopper contextOnly p={0} m={0} ml={10} width="120px" trigger={trigger} bigShadow>
        <List compact m={0} p={0}>
            <ListItem title>
                {title}
            </ListItem>
            {actions?.map(action => (
                <ListItem disabled={action?.disabled} onClick={action?.onClick}>
                    {action?.label}
                </ListItem>
            ))}
        </List>
    </ControlPopper>
)

export const ProviderSelector = ({type, providers, onChange, value}) => (
    <List>
        <ListItem title disabled>
            {capitalizeFirst(type)} providers 
        </ListItem>
        {providers?.map(provider => (
            <Item title={getTitle(provider?.title)}
            trigger={
                <ListItem 
                    chevron={<ChevronRightIcon />}
                    active={value === provider._id}
                    key={provider._id} 
                    onClick={() => onChange(provider._id)}
                >
                    <b>{getTitle(provider?.title)}</b> <br/>
                    {provider?.label ? provider?.label : provider?.count}
                </ListItem>
            } 
            actions={[
                {label: 'Open in editor', action: () => null, disabled: true},
                {label: 'Load Data', action: () => onChange?.load(provider?._id)},
                {label: 'Set Preview', action: () => onChange?.preview(provider?._id), disabled: value?.preview === provider?._id},
                {label: 'Unset', action: () => onChange?.unset(), disabled: value?.preview != provider?._id},
            ]}
            />
        ))}
    </List>
)




export const EntrySelector = ({provider, controls, onChange, value, loading}) => {

    if(!Array.isArray(provider?.entries) && !loading) return 'SELECT GROUPS'

    return (
        <GroupSelector 
            controls={controls}
            loading={loading && loader(provider?.count)}
            provider={provider}
            actions={{
                set: entry => onChange({entry: entry?._id}),
                set: entry => onChange({entry: entry?._id})
            }}
            value={{
                active: value?.entry,
                preview: value?.preview
            }}
        />
    )
}
const GroupSelector = ({provider, actions, value, loading, controls}) => (
    <List>
        <ListItem title disabled>
            {excerptString(provider?.title, 10, '...')} fields
        </ListItem>
        {provider?.schema && (
            <ListItem
                active={value?.active === 'schema'}
                chevron={<ChevronRightIcon />}
                onClick={() => actions.set({_id: 'schema'})}
            >
                <b>Schema</b> {provider?.schema.length} fields
            </ListItem>
        )}
        <ListItem
            active={value?.active === 'provider'}
            chevron={<ChevronRightIcon />}
            onClick={() => actions.set({_id: 'provider'})}
        >
            <b>Provider</b> {Object.keys(provider)?.length} fields
        </ListItem>
        <OptionsList 
            options={provider?.entries?.map(e => ({
                ...e,
                title: getEntryLabel(e, provider?.schema),
                label: getEntryDate(e)
            }))} 
            actions={actions}
            provider={provider}
            controls={controls} 
            value={value} 
            loading={loading}
        />
    </List>
)


const skipFields = ['__typename', 'entries', 'schema']
const skipEntrySchemaFields = ['__typename', 'singular', 'count', 'entries', 'catchRoutes', 'frontEnd', 'dynamicPage', 'description', 'schema']


export const FieldSelector = ({provider, schema, entry, value, onChange}) => {
    const devMode = useSelector(getDevModePrefSelector)

    const getFieldPath = (field, index) => {
        if(index === 0) return [field];
        const copy = [...value?.fieldPath];
        const pathLength = value?.fieldPath?.length;

        if(pathLength > index){
            copy.length = index;
            return [...copy, field]
        }
        return  [...value?.fieldPath, field];
    }
    const setField = (field, index) => {
        const fieldName = field?.name;
        const fieldPath = getFieldPath(field, index)
        onChange({
            ...value, 
            field: value.modus?.field != 'picker' ?
                {...value.field, field: fieldName} :
                fieldName,
            fieldPath
        })
    } 
    const getFieldPathName = index => value?.fieldPath?.[index]?.name
    const isActive = (field, index) => (
        [field?.name, field?.value?.name].includes(getFieldPathName(index)) && 
        getFieldPathName(index)
    )
    
    const isNotEntry = (value?.entry === 'schema' || value?.entry === 'provider');
    const label = isNotEntry ? getTitle(`${value.entry} fields`) : 'Entry fields';
    const getFieldValue = (field, data = entry) => {
        if(isNotEntry && field?.value) return field?.value;

        return  get(data, isString(field) ? field : field?.name, null)
    }
    const createOptions = (schema, data = entry, type = null, path = null) => schema ? (
        schema
        ?.filter(field => field?.type != 'label')
        ?.filter(f => !skipFields.includes(f.name))
        ?.map(field => ({
            ...field,
            title: field?.label ? field.label : removePath(field?.name),
            label: renderFieldData(field, getFieldValue(field, data)),
            // todo -> fix for repeater childs: isEmpty: !isNotEntry && !Boolean(getFieldValue(field, data))
        }))
    ) : mappableSubFields?.includes(type) ? (
        fieldSchemas({...path, ...data})?.[type]
    ) : null

    const options = createOptions(schema);
    
    const returnValue = getFieldValue(
        combineOptionPath(value?.fieldPath),
        isNotEntry ? provider : entry
    )

    return (
        <>
        {/* <List>
            <pre>
                {JSON.stringify({...provider, entries: undefined}, null, 2)}
            </pre>
        </List> */}
        <List>
            <OptionsList
                label={label}
                options={options}
                value={{
                    active: isActive
                }}
                actions={{
                    set: (field, index) => setField(field, 0)
                }}
                chevronValidation
            />
            
            {!isNotEntry && (
                <Expander trigger={
                    <ListItem title disabled
                        chevron={<ChevronDownIcon />}
                    >
                        Direct schema
                    </ListItem>
                }>
                <OptionsList 
                    chevronValidation
                    label={null}
                    value={{
                        active: isActive
                    }}
                    options={createOptions(createSchema(provider).filter(f => (
                        !skipEntrySchemaFields.includes(f.name)
                    )))}
                    actions={{
                        set: (field) => setField(field, 0)
                    }}
                />
                </Expander>
            )}
        </List>
        {(value?.fieldPath && value?.fieldPath?.length >= 1) && (
            <OptionPaths 
                getFieldValue={getFieldValue}
                createOptions={createOptions}
                fieldPath={value.fieldPath}
                setField={setField}
                isActive={isActive}
            />
        )}
        {/* <List style={{minWidth: '250px'}}>
            <ListItem title disabled>
                Returned data
            </ListItem>
            {devMode ? (
                <pre>
                    {JSON.stringify(returnValue, null, 2)}
                </pre>
            ):(
                <b>{isString(returnValue) ? returnValue : isEmpty(returnValue) ? 'No value' : typeof returnValue} </b>
            )} <br/>
            <OptionLabel>
                {combineOptionPath(value?.fieldPath).split('.').map(part => (
                    <span><ChevronRightIcon /> {part}</span>
                ))}
            </OptionLabel>
        </List> */}

        </>
    )

}

const combineOptionPath = (path, till = null) => combineString(
    (till ? remove(path, till) : path)?.map(f => f?.name || f?.value || f), 
    '.'
)

const OptionPaths = ({getFieldValue, createOptions, fieldPath, isActive, setField}) => {

    return fieldPath.map((path, index) => (
        mappableSubFields.includes(path?.type) ? (
            <OptionPath 
                getFieldValue={getFieldValue}
                entry={getFieldValue(path?.name)}
                createOptions={createOptions}
                isActive={isActive}
                path={path}
                index={index}
                setField={setField}
                combinedPath={
                    combineOptionPath(fieldPath, index + 2)
                }
            
            />

    ) : null)) 
}
const OptionPath = ({path, index, entry, createOptions,getFieldValue, setField, isActive, combinedPath}) => {

    const getLast = () => {
        const splitted = combinedPath.split('.');
        const last = splitted?.[splitted?.length - 1]
        return {splitted, last}
    }
    const removeLast = () => {
        const {splitted, last} = getLast()

        return isNumber(parseFloat(last)) ?
            combineOptionPath(splitted, splitted?.length - 1) :
            combinedPath;
    }
    let newGet = null;
    if(path?.type === 'user') {
        newGet = (other) => {
            const id = getFieldValue(other)
            return {
                id,
                author: {
                    firstName: 'hi',
                    lastName: 'zijdel'
                }
            }
        }
    }
    const [user] = useGetUser({_id: path?.type === 'user' && entry})

    if(user) entry = {
        ...user
    }
    return (
        <>

        {/* {path?.type === 'user' && (
            <List style={{minWidth: 400}}>
                extend user
                <pre>
                {JSON.stringify({
                    entry,
                    o: createOptions(
                    path?.schema, 
                    entry ,
                    path?.type,
                    path
                )}, null, 2)}
                </pre>
            </List>
        )} */}
        {(path.type === 'repeater' || path?.type === 'relationEnd') && (
            <List>
                <OptionsList 
                    chevronValidation
                    label={getTitle(path?.title ? path.title : path?.name)}
                    value={{
                        active: getLast()?.last
                    }}
                    options={
                        getFieldValue(removeLast())?.map((e, itemIndex) => ({
                            title: e?.[path?.schema?.[0]?.name],
                            _id: `${itemIndex}`,
                            name: `${itemIndex}`,
                            type: 'index',
                        }))
                    }
                    actions={{
                        set: (field) => setField(field, index + 1)
                    }}
                />
            </List>
        )}
            {/* <List style={{minWidth: '250px'}}>
            <ListItem title disabled
            >
                Schema data
            </ListItem>
            <pre>
            {JSON.stringify({
                path,
                sch: path?.schema,
                options: 
                    createOptions(
                        path?.schema, 
                        getFieldValue(combinedPath),
                        path?.type
                    )
                    
                }, null, 2)}
            </pre>
            
        </List> */}
        <List>

        

        <OptionsList 
            label={`${getTitle(path?.singular ? path.singular : path?.title ? path.title : path?.name)} fields`}
            // path={value.fieldPath}
            // getOptions={getOptions}
            // isActiveField={isActiveField}
            // canSelectField={canSelectField}
            // setField={setField}
            options={createOptions(
                path?.schema, 
                entry ? entry : getFieldValue(combinedPath),
                path?.type,
                path
            )}
            actions={{
                set: (field) => setField(field, index + (path?.type === 'repeater' ? 2 : 1))
            }}
            value={{
                active: field => isActive(field, index + (path?.type === 'repeater' ? 2 : 1))
            }}
            chevronValidation
        />
        </List>
        </>
    )
}

const OptionLabel = styled.span` 
    opacity: 0.3;
`

//Render the options
const OptionsList = ({options, value, controls, actions, provider, loading, label = "Entries", chevronValidation = false}) => (
    <>
    {label && (
        <ListItem disabled title chevron={
            <ControlPopper m={0} overflow p={0} width="150px" trigger={<MagnifyingGlassIcon />}>
                <TextField ghost label="Search" />
            </ControlPopper>
        }>
            {label}
        </ListItem>
    )}
    {loading ? loading : Array.isArray(options) ? options?.map((entry, index) => (
        <Item title={getTitle(provider?.singular)}
        trigger={
            <ListItem 
                chevron={(
                    (chevronValidation && mappableSubFields.includes(entry?.type)) ||
                    !chevronValidation
                ) ? <ChevronRightIcon /> : null}
                
                active={(
                    (isFunc(value?.active) && value?.active(entry, 0)) ||
                    entry?._id === value?.active
                )}

                key={entry?._id} 
                onClick={() => entry?.isEmpty ? null : actions?.set(entry)}
                disabled={entry?.isEmpty}
            >

                <Tooltip placement="left" m={2} wrap title={entry?.title?.length >= 17 ? entry.title : ''} force={entry?.title?.length >= 17}>
                    <b>{getTitle(entry?.title)}</b> <br/> 
                </Tooltip>
                <OptionLabel>{entry?.label}</OptionLabel> 
            </ListItem>
        } 
        actions={[
            {label: 'Edit entry', action: () => null, disabled: true},
            {label: 'Set Preview', action: () => actions?.preview(provider?._id), disabled: value?.preview === provider?._id},
            {label: 'Unset', action: () => actions?.unset(), disabled: value?.preview != provider?._id},
        ]}
        />
    )) : null}
    {controls?.next() && (
        <InlineGroup justify="space-between" align="center" pt={0} style={{fontSize: 10}}>
            <Button ghost icon={<ArrowLeftIcon />} m={0} onClick={controls?.prev()}/>
                {controls?.skip} - {controls?.skip + controls?.limit} {`/`} {provider?.count}
            <Button ghost icon={<ArrowRightIcon />} m={0} onClick={controls?.next()}/>
        </InlineGroup>
    )}
    </>
)

