import { useState } from "react";
import {default as s} from "./Radio.style";
import Radios from "./Radios";

const Radio = ({
    className,
    label, 
    checked, 
    value = null,
    onChange = null,
    disabled = false,
    size ="small",
    options = null,
    index = 0,
    renderOption = null,
    ...other
}) => {
    const [localState, setLocal] = useState(false);
    const toggleLocal = () => setLocal(prev => !prev)
     if(Array.isArray(options)) return <Radios options={options} value={value} onChange={onChange} renderOption={renderOption} {...other} />
    const isChecked = (checked ? checked : value ? value : localState);

    return (
        <s.CheckBoxWrapper size={size} disabled={disabled} index={index}>
             <s.CheckboxContainer className={className}>
                <s.HiddenCheckbox 
                    checked={checked ? checked : value ? value : localState} 
                    onChange={Boolean(disabled) ? null : ( 
                        onChange ? onChange : toggleLocal
                    )} 
                />
                
                {!renderOption && (
                <s.StyledCheckbox 
                    id={label} 
                    {...other}
                    type="checkbox" 
                    checked={isChecked} 
                    disabled={disabled}
                    onChange={() => Boolean(disabled) ? null : ( 
                        onChange ? 
                            onChange : toggleLocal
                    )}
                >
                </s.StyledCheckbox>
                )}
                <s.CheckBoxLabel>
                    {renderOption ? renderOption(label, isChecked) : label}
                </s.CheckBoxLabel>
            </s.CheckboxContainer>

        </s.CheckBoxWrapper>
    );
};
export default Radio;