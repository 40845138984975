import { ControlMenu } from "@mosanic/items";
import ComponentControls from "src/components/Builder/Layout/Component/Controls";



const ItemControls = ({menu}) => (  
    <ControlMenu 
        position={{
            x: menu?.x, 
            y: menu?.y
        }} 
        close={() => menu?.setMenu(null)}
    >
        {menu?.render ? menu.render : 
            <ComponentControls component={menu?.data} listOnly />
        }
    </ControlMenu> 
);

export default ItemControls; 