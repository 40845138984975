
import { useMutate } from '@hooks/useApi';
import { Button } from '@mosanic/fields/Button/Buttons';
import { TextField } from '@mosanic/fields/TextField/Fields';
import { AdvancedLabel, ControlDialog } from '@mosanic/items';
import { ListItem, ListItemText } from '@mosanic/items/List/List';

import { omitDeep } from '@util';
import { Fragment, useState } from 'react';
import { ADD_BUILDER_ITEM } from '../../../adapters/mutations/builderItems';


const SaveItem = (props) => {

	const [disabled, setDisabled] = useState(false);
	const [error, setError] = useState(null);
    const [data, setData] = useState(null);


	const [ addBuilderItem ] = useMutate(ADD_BUILDER_ITEM);

    const [modalState, setModalState] = useState(false);

    const [title, setTitle] = useState(props.component.title);
    const [desc, setDesc] = useState(props.component.desc);

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };
    const handleDesc = (event) => {
        setDesc(event.target.value);
    };

    // const setComponentData = (content) => {
    //     setEditorData(content);
    // };

	const handleSubmit = (event) => {
		event.preventDefault();
		setDisabled(true);
		setError(null);

        let cleanComp = omitDeep(props.component, '__typename');
        cleanComp.title = title;
        cleanComp.desc = desc;
        cleanComp._id = undefined;
        
		let variables = { 
			record: { 
				type: 'sidebarItem', 
                isUserGenerated: true,
                isBookmarked: false,
				componentType: props.component.type,
				component: cleanComp, 
			} 
		};



		addBuilderItem({ variables }).then(({ data }) => {
			setData(data);
			setDisabled(false);
			// activateAuth(token);
		}).catch(e => {
			setError(e.message);
			setDisabled(false);
		});
	};

	return (
        <Fragment>
            <ControlDialog state={modalState} close={() => setModalState(false)} title={(
                <AdvancedLabel 
                    renderHelp={true}
                    helpText={`Enter a title and short description to save component as a 'builder- item' preset <a className="skyBlue float-right">Learn more</a>`}>
                        Save Component 
                </AdvancedLabel>
            )}> 
             
                <div className="inputGroup -large">
                    <div className="inputGroup__item w-100">
                        <TextField id="component-title" label="Component Title" variant="outlined" value={title} onChange={handleTitle} size="small" disabled={data}/> <br/>
                    </div>
                    <div className="inputGroup__item w-100">    
                        <TextField id="component-title" label="Short Description" variant="outlined" value={desc} onChange={handleDesc} size="small" className="w-100" disabled={data}/>
                    </div>
                </div>
                <div className="clearfix"></div>
                {!data &&(
                    <>
                     <Button onClick={() => setModalState(false)} autoFocus size="small" >
                        Cancel 
                    </Button>
                    <Button onClick={handleSubmit}  size="small" variant="outlined">
                        Save
                    </Button>
                    </>
                )}
                <div className="clearfix"></div>
                {/* {error && <ErrorAlert errorMessage={error} />}
                {data && <SuccessAlert successMessage={'Builder item is successfully uploaded'} />} */}
            </ControlDialog>
            <span  className="" disabled={disabled} onClick={() => setModalState(true)}>
                Save
            </span>    
        </Fragment>
		
	);
};
export default SaveItem