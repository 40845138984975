const ID = '[inspect]'
export const INSPECT_ID = ID;
export const EDITOR_ID = '[editor]';

const INSPECT_SET               = `${ID} Set`;

const INSPECT_CLEAR           = `${ID} Clear`;

const EDITOR_SET           = `${EDITOR_ID} Set`;

const OVERWRITE_INSPECT           = `${ID} Overwrite`;

export const resetInspectAction = () => ({
    ...initInspectAction({})
})
export const initInspectAction = ({component, screen, bounds, path = null}, handled = false, options = null) => { 
    component = {...component, variants: undefined};
    return {
        type: !handled ? `${INSPECT_SET} ${component?.type ? component.type : '(empty)'}` : INSPECT_SET,
        payload: {component, screen, bounds, path},
        meta: {feature: ID},
        options
    }
};
 
export const clearInspectAction = () => ({
    type: INSPECT_CLEAR,
    meta: {feature: ID},
});

export const setEditorAction = ({screen, state}) => ({
    type: EDITOR_SET,
    payload: {screen, state},
    meta: {feature: ID},
});

export const setOverwriteAction = (value, type = 'breakpoint') => ({
    type: OVERWRITE_INSPECT,
    payload: {value, type},
    meta: {feature: ID},
});


const INSPECT_ACTION_IDS = {
    INSPECT_SET,
    INSPECT_CLEAR,

    EDITOR_SET,
    
    OVERWRITE_INSPECT,
};

export const inspectActions = {
    initInspectAction,
    clearInspectAction,

    setOverwriteAction,
    setEditorAction
};

export default INSPECT_ACTION_IDS;