
import { colors } from "@mosanic/styles";
import styled from "styled-components";
import Button from "../Button/Buttons";
import { BaseField } from "../Input"
import { BaseFieldStyles } from "../Input/Input.styles";
import {ButtonStyles as Btn} from "@mosanic/styles";
import { useBaseField } from "../Input/Base";
import { MediaDialog } from "@mosanic/components/Media/MediaDialog";
import { ControlDialog, LoadingDots, Spinner } from "@mosanic/items";
import Image from "next/image";
import { getImageUrl } from "@api/host";
import { parseImageSrc } from "@mosanic/utils/Image";

const ImgsViewer = dynamic(() => import("react-images-viewer"), {
    ssr: false
});

import dynamic from "next/dynamic";
import { useState } from "react";
import { capitalizeFirst, subStringFromLast } from "@util/Text";
import { isVideo, Video } from "../Video";

const UploadButton = styled.div` 
    font-size: 12px !important;
    display: inline-block;
    transform-origin: bottom;
    pointer-events: none;
    margin-right: 5px;
    svg {
        transition: all ease-in-out 0.3s;
        transform-origin: center center;
        transform: scale(0.9);
    }
`
const InputLabel = styled.label `
    ${BaseFieldStyles}
    display: flex;
    margin: 0;
    padding-top: 2px;
    font-size: 12px;
    vertical-align: center;
   
    &:hover svg {
        transform: scale(1.1)
    }

`;
const PreviewImg = styled.div` 
    transition: all ease-in-out 0.3s;
    padding: 0 5px !important;
    z-index: 2;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
    video, img {
        margin: 2.5px 0 -2.5px !important;
    }
`
const Input = styled.input` 
    display: inline-block;
    display: none;
` 
const getTitle = other => 
        other?.value?.url?.seo?.title ? other.value.url.seo.title :
        other?.value?.url?.url ? other.value.url.url : 
        other?.value?.url ? other.value.url :
        other?.value ? other.value : 
        other?.label ? other?.label :
        `${capitalizeFirst(other?.type)} upload`;
        
const FileField = ({mediaLib = true, ...other}) =>  {
    const [focussed, onFocus, onBlur] = useBaseField({...other})
    const [preview, setPreview] = useState(false)
    const togglePreview = () => setPreview(prev => !prev)
    const source = parseImageSrc(other?.value?.url ? other?.value?.url : other?.value, true);
    const title = getTitle(other)
    
    const props = {
        ...other,
        label: source?.thumb ? other?.label ? other.label : capitalizeFirst(other?.type) : null,
        type: 'file',
        focussed,
        onFocus,
        onBlur
    }
    return (
        <>
        <BaseField {...props}
            endSource={!mediaLib ? null : (
                <MediaDialog callback={other?.initiate} style={{margin: '5px 0',}} type={other?.type} />
            )}
        >

            {other?.thumb ? other?.thumb : source?.thumb && (
                <PreviewImg >
                    {!isVideo(source?.full) ? 
                        <Image src={source?.thumb} height="20px" width="20px" alt="uploaded" onClick={togglePreview} /> : 
                        <Video url={source?.full} height="20px" width="20px" />
                    }
                </PreviewImg>
            )}
            
            <InputLabel>
                {!source?.thumb && (
                    <UploadButton ghost>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15px" height="15px"><g data-name="Layer 2"><g data-name="cloud-upload"><rect width="8" height="8" opacity="0"></rect><path d="M12.71 11.29a1 1 0 0 0-1.4 0l-3 2.9a1 1 0 1 0 1.38 1.44L11 14.36V20a1 1 0 0 0 2 0v-5.59l1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"></path><path d="M17.67 7A6 6 0 0 0 6.33 7a5 5 0 0 0-3.08 8.27A1 1 0 1 0 4.75 14 3 3 0 0 1 7 9h.1a1 1 0 0 0 1-.8 4 4 0 0 1 7.84 0 1 1 0 0 0 1 .8H17a3 3 0 0 1 2.25 5 1 1 0 0 0 .09 1.42 1 1 0 0 0 .66.25 1 1 0 0 0 .75-.34A5 5 0 0 0 17.67 7z" ></path></g></g></svg>
                    </UploadButton>
                )}
                <Input {...other} value={''} type="file" />

                {other?.loading ? <LoadingDots /> : subStringFromLast(title, '/')}
            </InputLabel>
        </BaseField>
        {source?.full && 
        <ImgsViewer
			imgs={[{ src: source?.full }]}
			spinner={() => <Spinner/>}
			isOpen={preview && source?.full}
			onClose={togglePreview}
		/> }
        </>
    );
}
    // <BaseField 
    //     {...other}
    //     noFocus
    //     label={''}
    //     type='file' 
    //     field={(props) => (
           
    //     )}
    // />
// );
export default FileField