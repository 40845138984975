import { desktops, phones, tablets } from '@mosanic/constants/devices';
import { ControlPopper } from '@mosanic/items';
import { List, ListItem } from '@mosanic/items/List';
import { ListItemIcon } from '@mosanic/items/List/List';
import { ButtonGroup } from '@mosanic/fields';
import { getBreakpointsSelector } from '@redux/breakpoints/reducer';
import { setScreenBpAction, setScreenDeviceAction } from '@redux/screens/action';
import { currentBpSelector, currentDeviceSelector } from '@redux/screens/reducer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBreakpointIcon } from 'src/components/Builder/Util/getBreakpointIcon';
import { ItemList } from './useBreakpoints';
import styled from 'styled-components';
import { capitalizeFirst } from '@util/Text';
import { Condition } from '@JSX';

const Space = styled.div` 
    display: flex;
    justify-content: space-between;
`
export const devices = {
    desktop: desktops,
    tablet: tablets,
    phone: phones
}
export const getDeviceSize = device => {
    let size = devices?.[device?.type]?.[device?.device];
    if(!size) return null;
    size = size?.split('x')

    return {
        maxWidth: parseFloat(size[0]),
        maxHeight: parseFloat(size[1])
    }
}

export const DevicesList = ({devices, device, type}) => {
    const dispatch = useDispatch();
    const setDevice = device => dispatch(setScreenDeviceAction({device: {type, device}}))


    return (
        <List>
            <ItemList 
                items={Object.entries(devices)?.map(([_id, size]) => ({size, _id}))}
                active={device}
                handle={device => setDevice(device?._id)}
                label={device => (
                    <Space>
                    {device?._id} <span>{device?.size} </span>
                    </Space>
                )}

            />
        </List>
    )
}
export const ControlDevices = ({trigger = null}) => {
    const device = useSelector(currentDeviceSelector)
    const [type, setType] = useState(device?.type);

    return (
        <Condition when={trigger} wrapper={children => (
            <ControlPopper trigger={trigger} width={250}>
                {children}
            </ControlPopper>
        )}>
            <ButtonGroup>
                <ButtonGroup.Choices
                    options={Object.keys(devices).map(value => ({
                        label: value === device?.type ? 
                            <b>{capitalizeFirst(value)}</b> : 
                            capitalizeFirst(value),
                        value 
                    }))}
                    value={type}
                    handler={t => setType(t)}
                />
            </ButtonGroup>
            <DevicesList devices={devices[type]} device={device?.device} type={type}/>
        </Condition>
    )
}

