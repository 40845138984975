
import { ObjectID } from "@mosanic/utils";
import { toVarCase } from "@mosanic/utils";
import { isAction } from "@redux/util";
import cloneDeep from "@util/cloneDeep";
import { getShades } from "@util/color";
import { omitDeep } from "@util/Obj";
import { CREATE_DESIGN_COLOR, regenerateHueShadesAction, setDesignColor, updateHueAction } from "./action";

import { COLOR_ACTION_IDS as actions } from './action';
import { colorsSelector, hueSelector } from "./reducer";


export const handleColorCreationFlow = () => next => action => {
  action.type === CREATE_DESIGN_COLOR ?
    next(setDesignColor({
      color: {...action.payload, 
        selector: toVarCase(action.payload.label)}, 
        _id: ObjectID()
    })) : next(action);
};



export const handleRegenHueShades = ({getState, dispatch}) => next => action => {
  
  if(isAction(action, actions.REGENERATE_HUE)){
    const {hue, mainShade, saturation} = action.payload;
    const advancedMethod = Boolean(hue.colors.length != 6);

    let newShades = getShades(
      mainShade.value, 
      advancedMethod, 
      hue.colors.length,
      4,
      saturation
    );

    dispatch(updateHueAction({...hue, colors: newShades}))
    
  } else {
    next(action);
  }
};

//! not imported yet
export const handleHueSaturation = ({getState, dispatch}) => next => action => {
  
  if(isAction(action, actions.SET_SATURATION)){
    const {saturation, index, hue} = action.payload;
    const state = getState();

    if(!hue){
      const colors = colorsSelector(state)
      colors.map(hueSet => {
        dispatch(regenerateHueShadesAction(
          hueSet, 
          hueSet.colors[index], 
          saturation
        ));
      })

    } else {
      dispatch(regenerateHueShadesAction(
        hue, 
        hue.colors[index], 
        saturation
      ));
    }
    
  } else {
    next(action);
  }
};

export const handleShadeCount = ({getState, dispatch}) => next => action => {
  
  if(isAction(action, actions.SHADE_COUNT)){
    let {hue, operator} = action.payload;

    hue = structuredClone(hue);
    const shadeCount = hue?.colors?.length;

    //Get new shade based on last
    let newShade = hue?.colors[shadeCount - 1];
    newShade = {
      ...newShade, 
      type: ((shadeCount + 1) * 10).toString()
    }

    //Update hue
    operator === '-' ?
      (shadeCount > 6 && hue.colors.pop()) :
      hue.colors.push(newShade);
       
    dispatch(updateHueAction(hue))
    
  } else {
    next(action);
  }
};


export const colorsMdl = [
  handleColorCreationFlow,
  handleRegenHueShades,
  handleHueSaturation,
  handleShadeCount,
];
