import Menu from '@mui/material/Menu';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ControlPanel } from '../ControlPanel/Parts';

type PositionProps = {
  x: number
  y: number
} 

export type ControlMenuProps = {
  position?: null | PositionProps
  close?: Function
  autoClose?: boolean
  customClass?: string
  bigShadow?: boolean
  reversed?: boolean
  delay?: number
  children?: ReactNode
}

const milliseconds = 500
const ControlMenu = ({position = null, close = () => null, autoClose = true, customClass = '', bigShadow = false, reversed = false, delay = 1, children}:ControlMenuProps) => {

  const isOpen = Boolean(position?.x && position?.y)
  const [hover, setHover] = useState(false);
  const onEnter = useCallback(() => {
    if(autoClose) setHover(true);
  }, [autoClose]);

  const onLeave = useCallback(() => {
    if(autoClose) setHover(false);
  }, [autoClose]);
  
  const hide = useCallback(() => {
    if(!hover) close();
  }, [close, hover]);
 
  
  useEffect(() => {
    let timer: any;

    if(isOpen && autoClose) timer = setTimeout(() => { hide(); }, delay * milliseconds);

    return () => {
      clearTimeout(timer);
    };

  }, [hover, isOpen, hide, autoClose, delay] );

  return (
    <Menu 
      style={{    
        background: 'transparent',
        boxShadow: 'none',
        zIndex: 3,
      }}
      open={isOpen} 
      onClose={(e) => close(e)}
      anchorReference="anchorPosition"
      anchorPosition={ (position && isOpen) ? 
        { top: position.y, left: position.x }
        : undefined
      }
    >
      <div
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <ControlPanel extraClass={customClass} bigShadow={bigShadow} reversed={reversed}>
          {children}
        </ControlPanel>
      </div>
    </Menu>
  );
};
export default ControlMenu;