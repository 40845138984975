import { getDate } from "@util/Date";
import {
  Table,
  Td,
  Th,
  Tr
} from "../Table";
import { Label } from "@mosanic/items";
import { capitalizeFirst, revertSnakeCase } from "@util/Text";
import { hasAttr } from "@util/Obj";
import { HelpPopper } from "@mosanic/items/ControlPanel";

import { platform_roles as roles } from "../Team/roles";
export const Status = ({user, ...other}) => {

    const data = {
        registration: getDate(user?.registrationDate),
        status: hasAttr(roles, user?.role) ? roles[user.role] : user?.role,
        active: Boolean(
            user?.isActive && !'member_o|member_d'?.split('|')?.includes(user?.role)
        ) ? 'yes' : 'no',
        membership_started: getDate(user?.subscription?.started),
        membership_ending: getDate(user?.subscription?.ending)
    }
    return [
        <h4 key="heading" >
            Status &nbsp;
            <HelpPopper  helpText="If you have any question regarding the status of your account, feel free to contact the admins."/>
        </h4>,
        <br/>,
        <Table entries={Object?.values(data)?.filter(d => d)?.length} {...other} key="data">
            <Tr>
                {Object.entries(data)?.map(([key, value]) => value ? (
                    <Td>
                        <Label 
                            label={capitalizeFirst(revertSnakeCase(key))} 
                            value={value} 
                        />
                    </Td>
                ) : null)}
            </Tr>
        </Table>
    ]
}