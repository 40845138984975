
const interact = (variant) => `:${variant}`;
const media = (variant, bps) => `@media ${bps[variant]?.selector}`;

const attr = (attr, value) => `${attr}: ${value};`;
const getAttr = attr;

export {
    interact,
    media,
    getAttr
}