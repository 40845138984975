import { DropZone } from "@mosanic/core/DropZone/DropZone.style";
import { colors } from "@mosanic/styles";
import { CenterCenter } from "@mosanic/styles/flex.styles";
import { css } from "styled-components";

const EmptyColumn = css`
  background: rgba(255,255,255,0.5);
  position: relative;
  min-height: 35px;
  text-align: center;

  &::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0.5px dashed ${colors.util.gray.base};
  }

  &::after {
    font-size: 12px;
    content: 'Column';
    height: 100%; 
    ${CenterCenter}
  }

    ${DropZone} {
      height: 100%;
      max-height: 100%;
    }
`
const ItemColumn = css` 
    pointer-events: all;
    z-index: 0;
    min-width: calc(100% / 12);
    ${props => props.childs === 0 && !props?.production ? EmptyColumn : ''}
`;

export {
    EmptyColumn,
    ItemColumn
}