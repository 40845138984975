import { CenterCenter, colors } from "@mosanic/styles";
import styled, { css } from "styled-components";


const getType = type => {
    if(type === 'table') return css` 
        justify-content: center;
        width: 100%;
    `
}
const Pagination = styled.ul` 
    display: flex;
    list-style-type: none;

    ${props => getType(props.type)}
`

const Item = styled.li` 
     padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: all ease-in-out 0.2s;

    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    width: 32px;
    overflow: hidden;
    ${CenterCenter}

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: ${colors.util.gray.lighter};
      cursor: pointer;
    }

    ${props => props.selected && css` 
      background-color:  ${colors.util.gray.light};
    `}

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    ${props => props.disabled && css`
        pointer-events: none;

        .arrow::before {
            border-right: 0.12em solid rgba(0, 0, 0, 0.43);
            border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
            background-color: transparent;
            cursor: default;
        }
    `}
`
const styles = {
    Pagination,
    Item
}
export default styles;