import { PAGES } from "../../adapters/queries/pages";
import { setNotification } from "../notification/action";
import {API_REQUEST, API_MUTATE, API_ERROR} from "./action";
import { runGQLMutation, runGQLRequest } from "./adapters";

// // this middleware care only for API calls
// export const api = ({dispatch}) => next => action => {

//   if(action.type === API_REQUEST) {
//     const { method, url, onSuccess, onError } = action.meta;

//     fetch(url, { method }).then(response => response.json())
//       .then((data) => dispatch({ type: onSuccess, payload: data }))
//       .catch(error => dispatch({ type: onError, payload: error }))
//   }
//   return next(action)
// };

/**
 * The middleware where we check for GQL calls
 * @param {thunk}
 * @param {dispatch}
 * @param {action}  
 * @returns required action
 */
export const apiMiddleware = ({getState, dispatch}) => next => action => { 
  next(action)

  if(action.type.includes(API_REQUEST) ) {
    return dispatch(runGQLRequest(action));
  }

  if(action.type.includes(API_MUTATE) ) {
    return dispatch(runGQLMutation(action));
  } 

  if(action.type.includes(API_ERROR)){
    return dispatch(
      setNotification({
        message: action?.payload ? action?.payload : 'no message',
        feature: action.meta.feature
      }));

  }

};

// export const apiMiddleware = [gqlApi]; //api
