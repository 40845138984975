export const UI_ID = '[UI]';

export const SHOW_SPINNER      = `${UI_ID} show spinner`;
export const HIDE_SPINNER      = `${UI_ID} hide spinner`;

/**
 * Target locations
 */
export const SPINNER_TARGETS = {
  GLOBAL: 'global',
  CANVAS: 'canvas',
  INSPECT: 'inspect panel',
  SIDEBAR: 'sidebar',
  PANEL: 'panel',
  MODAL: 'modal',
  DASHBOARD: 'dashboard',
  EDITOR: 'editor',
  LIBRARY: 'library',
};
export const SPINNER_ID   = `_id`;


export const SET_THEME_MODE = `${UI_ID} Theme mode`;

export const ORDER_IN_PROGRESS = `${UI_ID} Order in progress`;
export const ORDER_COMPLETE    = `${UI_ID} Order complete`;

export const OPEN_PANEL         = `Open panel`;
export const CLOSE_PANEL        = `Close panel`;
export const SET_PANEL          = `Set panel`;

export const OPEN_INSPECT_PANEL = `${UI_ID} ${OPEN_PANEL} inspect`;
export const CLOSE_INSPECT_PANEL = `${UI_ID} ${CLOSE_PANEL} inspect`;

export const OPEN_LEFT_PANEL = `${UI_ID} ${OPEN_PANEL} left`;
export const CLOSE_LEFT_PANEL = `${UI_ID} ${CLOSE_PANEL} left`;
export const SET_LEFT_PANEL = `${UI_ID} ${SET_PANEL} left`;

export const START_DRAGGING    = `${UI_ID} start dragging`;
export const END_DRAGGING    = `${UI_ID} end dragging`;

export const ENABLE_ZOOM_PINCH    = `${UI_ID} enable zoom pinch`;
export const START_ZOOM_PINCH    = `${UI_ID} start zoom pinch`;
export const END_ZOOM_PINCH    = `${UI_ID} end zoom pinch`;


export const SECONDS = 1000; //ms
export const TIMING_STOP_SPINNER = (10 * SECONDS);

export const showSpinner = ({location, feature}) => {
  if(!location) alert('no lcoation')
  if(!location) location = feature
  
  return {
    type: `${feature} ${SHOW_SPINNER}`,
    payload: location,
    meta: {feature}
  }
};
export const hideSpinner = ({location, feature}) => ({
  type: `${feature} ${HIDE_SPINNER}`,
  payload: location,
  meta: {feature, attribute: 'location'}
});
export const hideSpinnerById = ({spinnerId, feature}) => ({
  type: `${feature} ${HIDE_SPINNER}`,
  payload: spinnerId,
  meta: {feature}
});

export const toggleInspectorPanel = (isOpen) => ({
  type: isOpen ? CLOSE_INSPECT_PANEL : OPEN_INSPECT_PANEL,
  payload: 'inspect',
  meta: {feature: UI_ID, mode: 'toggle'}
})

export const openInspectorPanel = () => ({
  type: OPEN_INSPECT_PANEL,
  payload: 'inspect',
  meta: {feature: UI_ID}
})
export const closeInspectorPanel = () => ({
  type: CLOSE_INSPECT_PANEL,
  payload: 'inspect',
  meta: {feature: UI_ID}
})

export const setThemeModeAction = (mode) => ({
  type: SET_THEME_MODE,
  payload: mode
});

export const orderInProgress = () => ({
  type: ORDER_IN_PROGRESS
});
export const orderComplete = () => ({
  type: ORDER_COMPLETE
});


export const startDragging = () => ({
  type: START_DRAGGING
});
export const endDragging = () => ({
  type: END_DRAGGING
});
export const toggleDragging = (isDragging) => ({
  type: isDragging ? END_DRAGGING : START_DRAGGING
});
export const setDragging = (isDragging) => ({
  type: isDragging ? START_DRAGGING : END_DRAGGING
});

export const enableZoomPinch = (from = null) => ({
  type: ENABLE_ZOOM_PINCH,
  payload: from,
});
export const startZoomPinch = () => ({
  type: START_ZOOM_PINCH,
});
export const endZoomPinch = () => ({
  type: END_ZOOM_PINCH
});