
import { RepeatProvider } from "@mosanic/core/Provider";
import { loopFunc } from "@util/Arr";
import { hasAttr } from "@util/Obj";
import { useRouter } from "next/router";
import { Children, cloneElement, useEffect, useState } from "react";
import { useDataReceiver } from "../DataLink/DataReceiver";
import { useCollection } from "@mosanic/core/Collections";

const AccommodationsLoader = dynamic(() => import("./Accommodations"));

import dynamic from "next/dynamic";
import { isString } from "@util/Text";
import { isSource } from "@mosanic/fields/Source/helpers";
import { COLLECTION } from "@api/queries/collections";
import { useLazyQuery, useVarQuery } from "@hooks/useApi";

const accommodations = '63359058737d68d97014da2d';


const Repeater = ({editor}, children) => {
    const {source} = editor;
    const {collection} = source || {collection: null};
    const router = useRouter()


    const isAccommodations = (collection?.collection === accommodations && collection?.source === 'source')

    const _id = collection?.source != 'provider' ? 
        source?.collection?.collection :
        null;

    // let [entries, schema, baseRoute] = useDataReceiver({
    //     source: (source?.dynamic) ? 'page' : 
    //         !collection?.source ? 'source' : 
    //         collection?.source, //Temp: transform old inputs.
    //     entry: !isAccommodations ? collection : null, 
    // })

    if(isAccommodations) return <AccommodationsLoader source={source} >{children}</AccommodationsLoader>
    else return <RepeaterRender source={source}>{children}</RepeaterRender>

}

const RepeaterRender = ({data, source, children}) => {
    const [clones, setClones] = useState([])

    const [handler, [collection, loading, error]] = useCollection({_id: source?.collection?.collection, singleEntry: false}, true)

    let [entries, schema, baseRoute, add] = useDataReceiver({
        source: source?.collection?.source, 
        // source: 'source',
        entry: source?.collection
    })
    

    // let [collection, loading, error] = useVarQuery('collectionById', COLLECTION, {_id: source?.collection?.collection}) 


    if(!schema) schema = collection?.schema;
    if(!entries || isString(entries?.[0])) entries = collection?.entries;
    if(!baseRoute) baseRoute = collection?.baseRoute

    const _id = schema?._id;
 
    const breakpoint = 'base';
    const cloneCount = hasAttr(source.clones, breakpoint) ?
        source.clones[breakpoint] :
        source.clones.base;

    useEffect(() => {
        const newClones = []
        let i = 0;
        do {
            newClones.push(i)
            i = i + 1;
        } while (i < cloneCount);
 
        setClones(newClones)
    },[cloneCount, children])

    const entryCount = entries?.length;
    const count = cloneCount <= entryCount ? cloneCount : entryCount;
    
// alert(collection?._id)
  
    return (
        <RepeatProvider data={{entries, schema, baseRoute}}>
            {/* {`Loading items from ${itemOffset} to ${endOffset}`} */}

            {/* <pre style={{color: '#fff'}}>
        {JSON.stringify({
            entries, 
            // schema,
            entry: source?.collection,
            // schema,
            isSource: isSource(source?.collection?.source),
            add
            // collection
        }, null, 2)}
        </pre> */}
            {loopFunc(count, index => children ? 
                Children.map(children, (child) => 
                    child ? (
                        // <InView>
                            cloneElement(child, {clone: index, collection: _id})
                        // </InView>
                    ) : null
                ) : index 
            )}
            {/* {JSON.stringify( entries)} */}
        </RepeatProvider>
    );

}

export default Repeater;