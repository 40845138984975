import { Button, RenderText } from "@mosanic/fields";

import { LoginForm } from "./LoginForm";

// import { providers, signIn, useSession, signOut } from "next-auth/react"
import { AUTH_EMAIL_REF } from "@api/constants";
import { isAuthenticated } from "@api/token";
import { isAuthSelector } from "@redux/auth/reducer";
import { recoverCookie } from "@util/server/cookie";
import { useState } from "react";
import { useSelector } from "react-redux";
import { VerifyForm } from "./VerifyForm";



export const AuthForm = ({verifyCode, verify, accountActions = false, setStep = null, content = {platformName:'Mosanic', header: null}, redirect = '/'}) => {

    const isAuth = useSelector(isAuthSelector) && isAuthenticated()

	const [mode, setMode] = useState({step: 'login'})
    const email = mode?.email || recoverCookie(AUTH_EMAIL_REF);
    const isLogin = mode?.step === 'login' && !verifyCode || !email

    const title = (
        isAuth ? 'Login successfully.' :
        isLogin ? 
            'Welcome Back' : 
            'Verify login'
    );
    const desc = (
        isAuth ? 'Hold on, we are redirecting you.' :
        isLogin ? 
            'Continue your work by singing in below.' : 
            'Enter your verification code below.'
    );


    return (
        <>
        
        {Array.isArray(content?.header) ? <RenderText content={content.header} /> : (
            <>
            <h1>{title}</h1>
            <p>{desc}</p>
            </>
        )}
        {Array.isArray(content?.header) && !isAuth && !isLogin && <p>{desc}</p>}
        
        {!isAuth ? isLogin ? (
            <LoginForm setMode={setMode} verify={verify} redirect={redirect} accountActions={accountActions} setStep={setStep}/>
        ):(
            <VerifyForm  redirect={redirect} mode={mode} setMode={setMode} verifyCode={verifyCode} setStep={setStep}/>
        ) : (
            <Button
                href={redirect}
                label="Dashboard"
                width={[1, '90%']}
                size="large"
                theme="primary"
                style={{color: "white"}}
            />
        )}
    </>
    )
    
}