import React, { useRef, useState } from "react";
import { default as s } from "./Tooltip.style";
import { useTooltips } from "@mosanic/hooks";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";
import { Portal } from "@mosanic/fields/Editor/Wrappers";
import { isHorizontal } from "@mosanic/utils/placement";
import { Condition } from "@JSX";

const Tooltip = ({children, title = null, placement = 'bottom', variant = null, arrow = false, wrap = false, force = false}) => {
  const active =  useTooltips()
  const ref = useRef(null);
  const popperRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [view, setView] = useState(false);

  const { styles, attributes } = usePopper(
    ref?.current, 
    popperRef.current, {
      placement,
      modifiers: [{ 
        name: 'arrow', 
        options: { 
          element: arrowRef 
        } 
      },{
        name: "offset",  
        options: {
          offset: [
            0,
            arrow ? 10 : 
                isHorizontal(placement) ? 10 : 5
          ]
        }
      }],
    }
  );
  const childProps = {
    ref,
    onMouseEnter: () => setView(true),
    onMouseLeave: () => setView(false),
  }

  const childrenWithProps = React.Children.map(children, child => 
      React.isValidElement(child) ?
        React.cloneElement(child, childProps) : 
        child
  );




  //No tooltip
  if(!force && (!Boolean(title) || !active)) return children;

  // s.Wrapper
  return (
    <Condition when={wrap} wrapper={children => (
      <span {...childProps} >
        {children}
      </span>
    )}>
      {wrap ? children : childrenWithProps} 
      {active && view && (
        <Portal>
            <s.Tooltip 
                variant={variant}
                ref={popperRef} 
                style={styles.popper}  
                {...attributes.popper} 
                active={view}
                arrow={arrow}
            >
                {title}
                <div ref={setArrowRef} style={styles.arrow} />
            </s.Tooltip>
        </Portal>
      )}
    </Condition>
  )
}


export default Tooltip;
