import { getBreakpointsSelector } from "@redux/breakpoints/reducer"
import { canMap } from "@util/Arr";
import { toObject } from "@util/Obj";
import { useSelector } from "react-redux"


  
const getSelector = (bp) => 
    (bp?.minWidth && bp?.maxWidth) ? 
        `(min-width: ${bp?.minWidth}px) and (max-width: ${bp?.maxWidth}px)` :
    (bp?.minWidth) ? `(min-width: ${bp?.minWidth}px)` :
    (bp?.maxWidth) ? `(max-width: ${bp?.maxWidth}px)` : 
    '(min-width: 0px)';

  
function compareIndex(a, b) {
  if (a.index < b.index) return -1;
  if (a.index > b.index) return 1;
  return 0;
}

export const sortMediaQueries = (mediaObj, desktopFirst = true) => {
    const mediaArray = Object.entries(mediaObj).map(([id, value]) => ({id, ...value}));
    const sortedArray = mediaArray.sort(compareIndex)
    const desktopFirstArray = [...sortedArray].reverse();

    return desktopFirst ? 
        desktopFirstArray :
        sortedArray;
}

const useMediaQueries = () => {
    const bps = useSelector(getBreakpointsSelector);

    const mobileFirst = bps.map((bp, index) => ({
        id: bp._id, 
        selector: getSelector(bp),
        index
    }));

    const media = mobileFirst//.reverse()

    return toObject(media, 'id')
}
export default useMediaQueries;