
export const desktops = {
    'iMac 24"': '1800 x 1152',
    'iMac': '1280 x 720',
    'MacBook Pro 16"': '1536 x 960',
    'MacBook Pro': '1440 x 900',
    'MacBook': '1152 x 700',
    'Surface Book': '1500 x 1000',
    'Desktop': '1440 x 1024',
    // 'Laptop Low DPI': ' 1280 x 670',
}
export const phones = {
    'iPhone 14 Pro Max': '428 x 928',
    'iPhone 14 Pro': '393 x 852',
    // 'iPhone 14': '390 x 844',
    // 'iPhone 12 Pro Max': '428 x 926',
    // 'iPhone 12 Pro': '390 x 844',
    // 'iPhone 11 Pro Max': '414 x 896',
    // 'iPhone 11 Pro / X': '375 x 812',
    'iPhone 8 Plus': '414 x 736',
    'iPhone 8': '375 x 667',
    'iPhone SE': '320 x 568',
    'Galaxy Note 5': '480 x 853',
    'Google Pixel 3': '412 x 824',
    'Android': '304 x 555',
}

export const tablets = {
    'iPad Pro 12.9': '1024 x 1366',
    'iPad Pro': '834 x 1194',
    'iPad mini': '768 x 1024',
    'Surface Pro 4': '1368 x 912',
    'Surface Pro 3': '1440 x 990',
}