

import { Register, Reset } from "@mosanic/auth";
import { AuthForm } from "@mosanic/auth/AuthForm";
import { useGetSite } from "@mosanic/cms/Query/byId";
import { siteIdSelector } from "@redux/auth/reducer";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

const groupStyle = {margin: '30px 0', background: '#fff', padding: '20px', borderRadius: '8px', border: '1px solid #eee'}


export const AuthComponent = ({fields, ref, editor, active = false, clone}, children) => {
    const router = useRouter()
    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})
    const {source, handler} = editor;

    const paths = 'register|login|reset'.split('|').includes(router?.query?.slug?.[0])
    const step =  paths ? router?.query?.slug[0] : source?.type;

    const siteConfig = site?.options?.users;
    const {
        platformName,
        register,
        login
    } = siteConfig?.content || {
        platformName: 'Mosanic', 
        login: null, 
        register: null
    };

    const resetMail = site?.options?.users?.mails?.accountReset
    const loginMail = site?.options?.users?.mails?.loginCode

    return (
        <>
        {/* {JSON.stringify(router?.query?.code)} */}
        {step === 'login' ? (
            <AuthForm verify={false} accountActions onlyForm content={{platformName, header: login, mail: loginMail}} redirect='/account' />
        ) : step === 'register' ? (
            <Register accountActions onlyForm content={{platformName, header: register}}/>
        ) : step === 'reset' ? (
            <Reset accountActions onlyForm content={{mail: resetMail}} resetCode={router?.query?.resetCode} redirect='/account' />
        ) : null}
        </>
    )


}