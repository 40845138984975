import { AUTH_EMAIL_REF } from "@api/constants";
import { Button } from "@mosanic/fields";
import { Link } from "@mosanic/items";
import { activateAuthAction } from "@redux/auth/action";
import { isAuthSelector } from "@redux/auth/reducer";
import { recoverCookie } from "@util/server/cookie";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetForm } from "./ResetForm";
import { VerifyResetForm } from "./VerifyResetForm";
import AuthWrapper from "./Wrapper";



const Reset = ({onlyForm = false, resetCode = null, accountActions, content = {mail: null}, redirect = '/'}) => {
    const dispatch = useDispatch()
    const activateAuth = (resetData) => dispatch(activateAuthAction(resetData));
    const isAuth = useSelector(isAuthSelector)

    const [mode, setMode] = useState({step: 'reset'})
    const email = mode?.email || recoverCookie(AUTH_EMAIL_REF);
    const isReset = mode?.step === 'reset' && !resetCode


    const title = (
        isAuth ? 'Login successfully.' :
        isReset ? 
            "Reset your Password" :
            "Verify reset request"
    );
    const desc = (
        isAuth ? 'Hold on, we are redirecting you.' :
        isReset ? 
            "Enter your email below to reset your account." : 
            "Check your email to authenticate." 
    );
    return ( 
        <AuthWrapper colorSchema={ 'light'} mt="-40px" onlyForm={onlyForm}>
            {/* usePrefColorSchema() || */}
            <h1>{title}</h1>
            <p>{desc}</p>
            

            {!isAuth ? isReset ? (
                <ResetForm setMode={setMode} accountActions={accountActions} mail={content?.mail} />
            ) : (
                <VerifyResetForm activateAuth={activateAuth} mode={mode} setMode={setMode} resetCode={resetCode} redirect={redirect} />
            ) : (   
                <Link href={redirect}>
                    <Button size="large" m="0 auto">
                        Dashboard
                    </Button>
                </Link> 
            )}
        </AuthWrapper>
    )
}
export default Reset;