import { gql } from '@apollo/client';

export const ADD_CHAT = gql`
mutation chatCreateOne($record: CreateOneChatInput!) {
	chatCreateOne (record: $record) {
		recordId
		record {
			name
			_id
		}
		error {
			message
		}
	}
}
`;

export const UPDATE_CHAT = gql`
mutation chatUpdateById($_id: MongoID!, $record: UpdateByIdChatInput!) {
	chatUpdateById (_id: $_id, record: $record) {
		recordId
		record {
			name
			_id
		}
		error {
			message
		}
	}
}
`;
