export const MODAL_ID       = `[modal]`
const ID = MODAL_ID;

//Creation
const REQUEST   = `${ID} Request`;

//Store request
const SAVE     = `${ID} Store`;
const CLEAR    = `${ID} Clear`;

//Submit
const CONFIRM   = `${ID} confirm`
const DECLINE   = `${ID} Decline`
const SUBMIT    = `${ID} Submit`;


//Types
const CONFIRMATION = `(confirmation)`;
const CHOICE = `(choice)`;



//Creation
export const confirmationModalAction = ({message, confirmAction, declineAction, title = null}) => ({
  type: `${REQUEST} ${CONFIRMATION}`,
  payload: {
    title,
    message,
    confirmAction,
    declineAction
  }
})
export const choiceModalAction = ({message, options, confirmAction, declineAction = null}) => ({
  type: `${REQUEST} ${CHOICE}`,
  payload: {
    message, 
    options, 
    confirmAction, 
    declineAction
  }
})

//Storing
export const storeModalAction = ({action, type = null}) => ({
  type: SAVE,
  payload: action.payload,
  meta: type
});
export const clearModalAction = () => ({
  type: CLEAR,
})

//Submitting
export const submitModalAction = ({value}) => ({
  type: SUBMIT,
  payload: value
})
export const confirmModalAction = () => ({
  type: CONFIRM
});
export const declineModalAction = () => ({
  type: DECLINE
});
export const closeModal = () => ({
  type: DECLINE, 
});




const MODAL_ACTION_IDS = {
  REQUEST,

  SAVE,
  CLEAR,

  SUBMIT,
  CONFIRM,
  DECLINE,
  
};

export const modalTypes = {
  confirmation: CONFIRMATION,
  choice: CHOICE
}

export const modalActions = {
  confirmationModalAction,
  choiceModalAction,

  storeModalAction,
  clearModalAction,

  submitModalAction,
  confirmModalAction,
  declineModalAction,
  closeModal,
};

export default MODAL_ACTION_IDS;