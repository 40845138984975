import { useEffect, useState } from 'react';

import { useCreate } from '@mosanic/cms';
import { Button, Select, Switch } from '@mosanic/fields';
import { AdvancedLabel, InputGroup, LoadingDots } from '@mosanic/items';
import { ControlTabs } from '@mosanic/items/Control/ControlButtons';
import { List, ListItem } from '@mosanic/items/List';
import { Col, Wrap } from '@mosanic/styles';
import { ObjectID } from '@mosanic/utils';
import { createUpdateEntry } from '@mosanic/utils/collections';
import { parseImageSrc } from '@mosanic/utils/Image';
import { addUnset } from '@mosanic/_Fieldsets';
import { BlendingModeIcon, Component1Icon, EyeOpenIcon, InputIcon, RocketIcon } from '@radix-ui/react-icons';
import { hasAttr } from '@util/Obj';
import { isString, slugify } from '@util/Text';
import Image from 'next/image';
import { FormProvider, useForm } from "react-hook-form";
import { BaseCollection } from './BaseCollection';
import { Entry } from './Entry';
import { FieldsCollection } from './FieldsCollection';
import { getEntryLabel } from '../../fields/Source/helpers';
import { useCreateCollection } from './useCreateCollection';
import { useUpdateCollection } from './useUpdateCollection';

const Item = ({item}) => (
    <div>
        {item?.id} x
    </div>
)

export const AddCollection = ({initial = null, type = 'create', redirect = true, callback = null, dataType = 'collection'}) => {
	const methods = useForm({mode: 'all', defaultValues: initial});
	const collection = methods.watch();
	const schema = collection?.schema

	const convertFields = (select = ['name', 'text','textarea']) => addUnset(collection?.schema?.filter(f => select.includes(f?.type))?.map(f => ({value: f?.name, label: `${f.name} [${f.type}]`})));

	const [convert, setConvert] = useState({
		title: schema?.[0]?.name,
		description: schema?.[0]?.name,
		image: null,
		slug: true
	})

    const isValid = methods.formState.isValid;
	const [disabled, setDisabled] = useState(false);
	const [init, setInit] = useState(false);
	const [ addCollection, [data, loading, error]] = useCreateCollection({redirect, dataType})
	const [ update, [dataUpdate, loadingUpdate, errorUpdate]] = useUpdateCollection({callback})

	const [saving, setSaving] = useState(false)
	const [progress, setProgress] = useState(null)
	const [ids, setIds] = useState([null])
	
	
	//Converting method
	const [addEntry, addEntryMethods] = useCreate({
		callback: id => {
			setProgress(`Converting: ${id}`)
			setIds(ids => ([...ids, id]))
		}
	})

	const onSubmit = (data) => {
		if(hasAttr(data, 'preventDefault')) data?.preventDefault();
		if(mode === 'transform') return;
		if(!saving) return
		setDisabled(true)

		if(type === 'create') {
			addCollection({collection: data})
		} else {
			update({collection: {...data, linked: undefined, baseFields: convert}})
		}
		setDisabled(false)
		setSaving(false)
	};

	const triggerSave = () => {
		setSaving(true);
		if(mode === 'transform') {
			setProgress('Starting transformation.');
			setIds([])

			collection?.entries?.forEach((entry, i) => {
				addEntry({data: 
					createUpdateEntry({
						title: getEntryLabel(entry, schema),
						description: entry?.[convert?.description],
						slug: convert?.slug && slugify(getEntryLabel(entry, [{name: convert?.title}])),
						image: convert?.image && entry?.[convert?.image],
						seo: convert?.slug && {
							title: getEntryLabel(entry, schema),
							description:  entry?.[convert?.description],
						},
						date: entry?.date,
						data: {
							...entry,
							date: undefined,
							updatedAt: undefined,
							_id: undefined
						},
						siteId: collection?.siteId,
						collectionId: collection?._id,
					})
				})
			});

			setTimeout(() => {
				setProgress('Updating collection.');
				update({collection: {
					...collection,
					entries: ids
				}})
				setTimeout(() => setProgress('Finished.'), 750);
			}, 750);
			
		} else {
			methods.handleSubmit(onSubmit)
		}
	}

	useEffect(() => {
		if(initial || init || type != 'create') {
			methods?.reset(initial)
			return
		};
		methods.reset({
			schema:[{
				type:"text",
				id: ObjectID(),
				name:"",
				help:"",
				default:"",
				required:true
			}]	
		})
		setInit(true)
	},[init, methods, initial, type])
	const [mode, setMode] = useState('base')
	//form-row mb-3
	return (
		 <FormProvider {...methods} >

			<ControlTabs active={mode} setActive={setMode}  lines={2} mx={3} mb={4} options={[
				{label: <span>Collection base information</span>, value: 'base', icon: <Component1Icon />},
				{label: <span>Preview {dataType} fields</span>, value: 'preview', icon: <EyeOpenIcon />},
				{label: <span>Edit fields styling</span>, value: 'styling', icon: <BlendingModeIcon />},
				collection?.linked && {label: <span>Base fields</span>, value: 'entry', icon: <InputIcon />},
				(!isString(collection?.entries?.[0])  && !collection?.linked) && {label: <span>Transform</span>, value: 'transform', icon: <RocketIcon />},
			]} />
			<form disabled={disabled || type === 'preview'} onSubmit={methods.handleSubmit(onSubmit)}>
                <Wrap>
                    <Col  width={[1, 1/2]} px={2}>
						{/* {JSON.stringify(collection?.schema?.find(a => a?.type === 'markedimage'))} */}
                        <InputGroup>
                            {mode === 'transform' ? (
							<div>
								<AdvancedLabel as="h4" label="Transform collection " span/>
								<AdvancedLabel mb={4}>
									Once submitted all the entries will be converted to separate files. <br/> 
									This way more data can be saved inside of them. <br/><br/> Note that this action is irreversible.
								</AdvancedLabel>

								<AdvancedLabel as="h5" label="Setup the entry data" span/>
								<InputGroup>
									{['title', 'description'].map(field => (
										<Select wrap options={convertFields()} label={field} value={convert?.[field]} onChange={val => setConvert({...convert, [field]: val})} />
									))}
									<Select wrap options={convertFields(['image'])} label={'image'} value={convert?.image} onChange={val => setConvert({...convert, 'image': val})} />
									
									<Switch wrap label="Slugify" checked={convert?.slug} onChange={() => setConvert({...convert, slug: !convert?.slug})} />
								</InputGroup>
								{addEntryMethods?.[2]}

								{progress}
							</div>
						) : mode === 'entry' ? (
							<div>
								<AdvancedLabel as="h4" label="Change base fields" span/>
								<AdvancedLabel mb={4}>
									Select the base fields of this collection
								</AdvancedLabel>

								<InputGroup>
									{['title', 'description'].map(field => (
										<Select wrap options={convertFields()} label={field} value={convert?.[field]} onChange={val => setConvert({...convert, [field]: val})} />
									))}
									<Select wrap options={convertFields(['image'])} label={'image'} value={convert?.image} onChange={val => setConvert({...convert, 'image': val})} />
									
									<Switch wrap label="Slugify" checked={convert?.slug} onChange={() => setConvert({...convert, slug: !convert?.slug})} />
								</InputGroup>
								{addEntryMethods?.[2]}

								{progress}
							</div>
						) : mode === 'base' ? <BaseCollection dataType={dataType}/> : <Entry collection={collection} initial={null} type="preview" />}
                        </InputGroup>

						{error}
						{errorUpdate}
                    </Col>
        

                    <Col  width={[1, 1/2]} px={2}>
                        {mode === 'transform' ? (
							<div>
								<AdvancedLabel as="h4" label=" Entries"/>
								{JSON.stringify(ids)}
								<List>
									{collection?.entries?.map((entry, index) => (
										<ListItem key={index} style={{display: 'flex'}}>
											{convert?.image && (
												<Image src={parseImageSrc(entry?.[convert?.image], true)?.full} width="35px" height="20px" alt="preview" />
											)}
											<div style={{paddingLeft: '5px'}}>
												{getEntryLabel(entry, schema)}	

												{convert?.slug &&
													` > ${slugify(getEntryLabel(entry, [{name: convert?.title}]))}`
												} <br/>
												{convert?.description != convert?.title && (
													entry?.[convert?.description]
												)}
											</div>
										</ListItem>
									))}
								</List>
							</div>
						) : mode === 'styling' ? (
							<div>
								<AdvancedLabel as="h4" label="Coming soon" />
								<AdvancedLabel width="70%">
									We&apos;re currently working on two ways to style your fields. <br/><br/>The first version will be released inside this module, where you will be able to setup general field styling. We will expand this in the near future and integrate it fully inside the builder, where you can drag an drop your input fields. <br/>
									Stay tuned! <br/><br/>
									-The Mosanic Team 
								</AdvancedLabel>
							</div>
						) : <FieldsCollection />}
                    </Col>
						
					
					<Button 
						label={(loading || loadingUpdate) ? <LoadingDots /> : 
							mode === 'transform' ? 'Yes, transform' : type === 'create' ? `Create ${dataType}` : `Update ${dataType}`
						}
						theme="primary"
						size="large"
						type="submit"
						disabled={Boolean(disabled || !isValid) && !error && type != 'preview'}
						onClick={triggerSave}
					/>
                </Wrap>
	
					
                
			</form>
		 </FormProvider>
	);
};
export default AddCollection;