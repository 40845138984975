import {default as s} from '@mosanic/builder/Image/Image.style';


export const MediaDropTarget = ({onImageUpload, images, dragProps, type}) => (
    <s.DropTarget size="small" onClick={onImageUpload} count={images?.length} type={type} {...dragProps}>
        <s.Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="cloud-upload"><rect width="8" height="8" opacity="0"></rect><path d="M12.71 11.29a1 1 0 0 0-1.4 0l-3 2.9a1 1 0 1 0 1.38 1.44L11 14.36V20a1 1 0 0 0 2 0v-5.59l1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"></path><path d="M17.67 7A6 6 0 0 0 6.33 7a5 5 0 0 0-3.08 8.27A1 1 0 1 0 4.75 14 3 3 0 0 1 7 9h.1a1 1 0 0 0 1-.8 4 4 0 0 1 7.84 0 1 1 0 0 0 1 .8H17a3 3 0 0 1 2.25 5 1 1 0 0 0 .09 1.42 1 1 0 0 0 .66.25 1 1 0 0 0 .75-.34A5 5 0 0 0 17.67 7z" ></path></g></g></svg>
        </s.Icon>

        {images?.length >= 1 ? "Drop or click to upload more" : "Drop or click"}
    </s.DropTarget>
)