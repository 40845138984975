
import { Label } from "@mosanic/items";
import { Fragment } from "react";


import { AddCollection } from "@mosanic/core/Collections";
import { Tag } from "@mosanic/items/Block";
import { Columns } from "@mosanic/items/Page";
import { getDate, getFullTimeStamp } from "@util/Date";
import { DataGridModals } from "./DataGrid";
import { DataGrid } from "@mosanic/cms/DataGrid/DataGrid";


export const CollectionHeaderColsInfo = ({collection}) => (
    <Columns equal columns={[
        <Label label="Entry count" value={`${collection?.entries?.length ? collection?.entries?.length : 0} entries`} key="entries"/>,
        <Label label="Field count" value={`${collection?.schema?.length ? collection?.schema?.length : 0} fields`} key="fields"/>,
        <Label label="Published" value={`${getDate(collection?.date)}`} key="publish"/>,
        <Label label="Updated" value={`${getFullTimeStamp(collection?.updatedAt)}`} key="updated"/>,
    ]} />
);

export const CollectionHeader = ({collection}) => (
    <Fragment>
        <h4>
            {collection?.catchRoutes && (
                <Tag float right>Catch routes</Tag>
            )}
        </h4>

        <CollectionHeaderColsInfo 
            collection={collection}
        />
        
    </Fragment>
);

export const CollectionEditing = ({collection, refreshData}) => (
    <AddCollection initial={collection} dataType={collection?.type} type="update" callback={refreshData}/>
)

 
export const CollectionEntries = ({collection, gridHandles, handles, state, pagination = null, loading = null}) => (
    <>
  
        <DataGrid 
            collection={collection}
            state={state}
            handles={gridHandles}
            pagination={pagination}
            loading={loading}
        />
        <DataGridModals 
            collection={collection}
            handles={handles}
            state={state}
        />
    </>
)
