import { usePage } from "@mosanic/components/Pages"
import { Button, Buttons } from "@mosanic/fields"
import { ScaledFrame } from "@mosanic/items/Frames"
import { getSiteUrl, siteSlugUrl } from "@mosanic/utils/Site"
import { siteIdSelector, siteSelector } from "@redux/auth/reducer"
import Link from "next/link"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RenderHeaderFooter, SwitchPageRender } from "./Render"
import { useGetSite } from "@mosanic/cms/Query/byId"




export const PreviewDynamicPage = ({pageId, entry, baseRoute, links = false}) => {
    const [refreshPage, [page, loadingPage, errorPage]] = usePage({_id: pageId})
    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})

    useEffect(() => {
        if(pageId) refreshPage({_id: pageId})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pageId])
    return (
        <>
        {errorPage}
        
        {loadingPage ? loadingPage : (
            <ScaledFrame border float>
                {page && (
                    <RenderHeaderFooter>
                        <SwitchPageRender page={page} site={site} entry={entry}/>
                    </RenderHeaderFooter>
                )}
            </ScaledFrame>
        )}
        {links && (
            <Buttons>
                <Button ghost label="Open page" href={siteSlugUrl(`page/${page?._id}`)}/>
                <Link href={`https://${getSiteUrl(site)}/${baseRoute}`} target="_blank">
                    <Button ghost label="View page"/>
                </Link>
            </Buttons>
        )}
        </>
    )
}