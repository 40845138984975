import { gql } from '@apollo/client';

export const SINGLE_UPLOAD = gql`
mutation uploadFile($file: Upload!, $path: String) {
	uploadFile (file: $file, path: $path) {
		filename
        mimetype
        encoding
        url
		urlMin 
		urlThumb
	}
}
`;

export const FONT_UPLOAD = gql`
mutation uploadFont($file: Upload!) {
	uploadFont (file: $file) {
		filename
        mimetype
        encoding
        url
	}
}
`;
// useUploadFile(file: Upload!): UploadedFileResponse!

export const ADD_MEDIA = gql`
mutation mediaCreateOne($record: CreateOneMediaInput!) {
	mediaCreateOne (record: $record) {
		recordId
		record {
			title
			alt
            filename
            folder 
            url
			urlMin 
			urlThumb

			_id
		}
		error {
			message
		}
	}
}
`;

export const DELETE_MEDIA = gql`
mutation mediaRemoveById($_id: MongoID!) {
	mediaRemoveById (_id: $_id) {
		error {
			message
		}
		recordId
	}
}
`;

export const DELETE_UPLOAD = gql`
mutation deleteFile($filename: String!, $url: String!) {
	deleteFile (filename: $filename, url: $url) {
		message
	}
}
`;