import { Component2Icon, TargetIcon } from "@radix-ui/react-icons";
import { isProvider } from "../helpers";
import { isClone } from "@mosanic/utils/value";

const getEntryOptions = (value, isProvider) => {
    if(isProvider) return [{value: 'entry'}];

    return isClone(value?.clone) ? [
        {value: 'entry', icon: <Component2Icon />},
        {value: 'dynamic', icon: <TargetIcon />}
    ] : [{value: 'entry'}];

}
export const useEntryTabs = ({value, handler, itemType}) => {
    const {tab} = value || {tab: {entry: 'entry'}}
    const setEntryTab = entry => handler({...value, tab: {...tab, entry}})

    const options = getEntryOptions(value, isProvider(itemType))

    return {
        value: tab?.entry,
        handler: setEntryTab,
        options,
        key: 'entry'
    }
}


