import { WrapInput } from "@mosanic/items";
import { useState } from "react";
import {default as s} from "./Switch.style";

const Switch = ({
    label, 
    checked = null, 
    value = null,
    onChange = null,
    disabled = false,
    size ="small",
    wrap = false,
    name = null,
    desc = null,
    ...other
}) => {
    const [localState, setLocal] = useState(false);

    return (
        <WrapInput wrap={wrap ? wrap : false} fullWidth={true}>
            <s.CheckBoxWrapper size={size} disabled={disabled}>
                <s.CheckBox
                    id={label ? label : name} 
                    type="checkbox" 
                    checked={checked ? checked : localState} 
                    disabled={disabled}
                    onChange={Boolean(disabled) ? null : ( 
                        onChange ? 
                            onChange : 
                            setLocal
                    )}
                /> 
                <s.CheckBoxLabel htmlFor={label ? label : name} />
                <s.DisplayLabel>
                    {label} 
                </s.DisplayLabel>
                {desc && (
                    <s.SubLabel>
                        {desc}
                    </s.SubLabel>
                )}
                
            </s.CheckBoxWrapper>
        </WrapInput>
    );
};
export default Switch;
//  <s.CheckBoxWrapper size={size} disabled={disabled}>
//             <s.CheckBox
//                 id={label} 
//                 type="checkbox" 
//                 checked={checked ? checked : localState} 
//                 disabled={disabled}
                // onChange={Boolean(disabled) ? null : ( 
                //     onChange ? 
                //         onChange : 
                //         setLocal
                // )}
//             />
//             <s.CheckBoxLabel htmlFor={label} />
//             <s.DisplayLabel>
//                 {label}
//             </s.DisplayLabel>
//         </s.CheckBoxWrapper>
