
const ID = '[canvas]'
export const CANVAS_ID = ID;

export const HANDLE_CANVAS          = `${ID} Handle`;
export const HANDLE_CANVAS_RESIZE   = `${ID} Handle resize`;


export const SET_CANVAS             = `${ID} Set`;


export const SET_IS_DRAGGING        = `${ID} isDragging`;
export const SET_PAGE_SCALE         = `${ID} Set pageScale`;



export const FETCH_LAYOUT_SUCCESS   = `${ID} Fetch: Success`;
export const FETCH_LAYOUT_ERROR     = `${ID} Fetch: Error`;




export const setPageScale = (scale, source = 'set') => ({
    type: SET_PAGE_SCALE,
    payload: {scale, source},
});

export const changePageScale = (scale, type = '+', source = null) => {
    const newScale = (type === '+') ? (scale * 10 + 0.1 * 10) / 10 : (scale * 10 - 0.1 * 10) / 10;
    return {
        type: SET_PAGE_SCALE,
        payload: {scale: newScale, source},
        meta: {feature: CANVAS_ID, oldScale: scale, type}
    }
}