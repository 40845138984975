import { useEffect, useMemo, useState } from "react"
import {default as s} from "./Pagination.styles";


export const DOTS = '...'

export const usePagination = ({data, limit = 10, skip = 0, currentPage = 1, siblings = 1, count = null}) => {
    const [controls, setControls] = useState({ limit, skip, currentPage });

    const endRange = controls?.skip + controls?.limit;
    const pageCount = Math.ceil((count ? count : data?.length) / controls?.limit);
    const items = Array.isArray(data) ? data?.slice(controls?.skip, endRange) : data;

    const canNext = controls.currentPage < pageCount;
    const canPrev = controls.currentPage > 1;

    const nextPage = () => canNext && setControls({
        ...controls, 
        currentPage: controls.currentPage + 1, 
        skip: controls.skip + controls.limit
    })
    const prevPage = () => canPrev && setControls({
        ...controls, 
        currentPage: controls.currentPage - 1, 
        skip: controls.skip - controls.limit
    })
    const setPage = (index) => setControls({
        ...controls,
        skip: index === 0 ? limit : limit * (index - 1),
        currentPage: index === 0 ? 1 : index
    })

    useEffect(() => {
        setControls({ limit, skip, currentPage })
    },[limit, skip, currentPage, count])

    useEffect(() => {
        if(controls?.currentPage > pageCount) setControls({ ...controls, currentPage: pageCount })
    },[controls, pageCount])
    
    const methods = [nextPage, prevPage, setPage];
    const validate = {pageCount, canNext, canPrev};

    return {
        items,
        methods, 
        validate: {
            ...controls, 
            ...validate, 
            totalCount: count ? count : data?.length,
            siblings
        }        
    }
}

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
  };

export const usePaginationRange = (validate) => {
    
    const { limit, currentPage, totalCount, siblings} = validate;

    const paginationRange = useMemo(() => {
  
        const totalPageCount = Math.ceil(totalCount / limit);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblings + 5;
    
        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
          return range(1, totalPageCount);
        }
        
        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblings, 1);
        const rightSiblingIndex = Math.min(
          currentPage + siblings,
          totalPageCount
        );
    
        /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    
        const firstPageIndex = 0;
        const lastPageIndex = totalPageCount;
    
        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
          let leftItemCount = 3 + 2 * siblings;
          let leftRange = range(1, leftItemCount);
    
          return [...leftRange, DOTS, totalPageCount];
        }
    
        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
          
          let rightItemCount = 3 + 2 * siblings;
          let rightRange = range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
          );
          return [firstPageIndex, DOTS, ...rightRange];
        }
         
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
          let middleRange = range(leftSiblingIndex, rightSiblingIndex);
          return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

      }, [totalCount, limit, siblings, currentPage]);
    
      return paginationRange;

}
