import { Button, ButtonGroup } from "@mosanic/fields"
import Tab, {Tabs} from "@mosanic/fields/Tab/Tab";
import { capitalizeFirst, isString } from "@util/Text"

const parseOptions = (options) => isString(options) ? options.split('|') : options;
const val = option => option?.value ? option.value : option
const lbl = option => option?.label ? option.label : option

const ControlButtons = ({setActive, active, options, size, lines, children, ...other}) => (
    <ButtonGroup {...other}>
        {parseOptions(options).map(option => (
            <Button 
                size={size}
                lines={lines}
                icon={option?.icon}
                label={isString(lbl(option)) ? capitalizeFirst(lbl(option)) : lbl(option)} 
                onClick={() => setActive(val(option))}
                active={val(option) === active}
                className={val(option) === active ? 'active' : ''}
                key={val(option)}
            />
        ))}
        {children}
    </ButtonGroup>
);

const ControlTabs = ({setActive, active, options, size, lines, children, ...other}) => (
    <Tabs {...other}>
        {parseOptions(options).map(option => (
            <Tab 
                size={size}
                lines={lines}
                icon={option?.icon}
                label={isString(lbl(option)) ? capitalizeFirst(lbl(option)) : lbl(option)} 
                onClick={() => setActive(val(option))}
                active={val(option) === active}
                className={val(option) === active ? 'active' : ''}
                key={val(option)}
            />
        ))}
        {children}
    </Tabs>
);
export {
    ControlTabs
}
export default ControlButtons;