import { copyMiddleware } from '../actions/copyMiddleware';
import { cutMiddleware } from '../actions/cutMiddleware';
import { pasteMiddleware } from '../actions/pasteMiddleware';
import { removeMiddleware } from '../actions/removeMiddleware';

import { getItemByPath } from '@mosanic/map/get';
import { transformInitialItemFull } from '@mosanic/data';

import { inspectAttrSelector } from '@redux/inspect/selector';
import { dataActions, dataSelector } from '@redux/map/data';
import { mapActions, mapSelector } from '@redux/map';
import { getItem } from '@redux/map/data/selector';

import { hideSpinner, showSpinner } from '@redux/ui/uiAction';
import { includesAction, isAction } from '@redux/util';

import { isMoveDropItem, isNewDropItem, isNewDropLayout } from './validate';

import { dropActions, default as actions, DROP_ID } from './action';

import {
    handleAddCopiedItemAction,
    handleAddCutItemAction,
    handleItemMoveDropAction,
    handleNewItemDropAction,
    handleNewLayoutDropAction,
    setStartStateDropAction,
    transformDropActionPayload
} from '@mosanic/map/drop';

import {
    isItemCopied,
    isItemCut,
    isPreStoreCut,
    isUnhandledActionItem
} from '@mosanic/map/mode';

import { preDropStoreSelector } from './reducer';


const SPINNER = {location: 'canvas', feature: DROP_ID };

//Main drop handler
export const coreDropActionStartFlow = ({dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.DROP_HANDLE)){

        setStartStateDropAction(dispatch)
        
        const [
            dropZone, 
            item, 
            dropPath
        ] = transformDropActionPayload(action)


        if(isUnhandledActionItem(item)){

            if(isItemCopied(item)) handleAddCopiedItemAction({dispatch, item, dropPath})

            if(isItemCut(item)) handleAddCutItemAction({dispatch, item, dropPath}) 

            dispatch(dropActions.clearPreStoreAction())
            return;
        }


        if(isNewDropItem(item.type)){
            handleNewItemDropAction({dispatch, item, dropZone, dropPath})
            return;
        } 

        if(isNewDropLayout(item)){
            handleNewLayoutDropAction({dispatch, item, dropZone, dropPath})
            return;
        }

        if(isMoveDropItem(item.type && item?.path)){
            handleItemMoveDropAction({dispatch, item, dropPath})
            return;
        }

    }
};

//When wrap action is performed
export const dropWrapActionFlow = ({dispatch, getState}) => next => action => {
    next(action);
  
      if(includesAction(action, actions.DROP_WRAP)){
          dispatch(dropActions.clearPreStoreAction())
          dispatch(showSpinner(SPINNER));

          const state = getState();
          const {item, wrapItem} = action.payload;

           //Get inspected item to wrap
           const path = inspectAttrSelector(state, 'path');
           const itemRef = path ? getItemByPath({
               map: mapSelector(state), 
               path
           }) : null;

            if(!path) {
                dispatch(hideSpinner(SPINNER))
                return;
            }
            //Remove item from map first
            dispatch(mapActions.removeMapAction({item: {...item, path}}));
         
            //Create wrapper component
            const [itemMap, component] = transformInitialItemFull({item: wrapItem}, dispatch);
            //Store new wrapper compnent
            dispatch(dataActions.initDataAction({component}));

    
            dispatch(mapActions.addMapAction({itemMap: {
                ...itemMap,
                children: [itemRef]
            }, dropPath: path}));
            
        }   
            
};

//Check if clear store should be cleared
export const validateClearPreStoreFlow = ({getState, dispatch}) => next => action => {
    if(isAction(action, actions.DROP_CLEAR_STORE)){
        const state = getState();
        const preStore = preDropStoreSelector(state);
        
        //Remove preStore states from data component
        if(isPreStoreCut(preStore)){
            const component = {...getItem(dataSelector(state), preStore), isCut: undefined}
            dispatch(dataActions.updateDataAction({component}, true));
        }

        next(action);
    } else {
        next(action);
    }
};

export const dropMiddleware = [
    coreDropActionStartFlow,

    ...removeMiddleware,
    ...pasteMiddleware,
    ...copyMiddleware,
    ...cutMiddleware,

    dropWrapActionFlow,
    validateClearPreStoreFlow
];