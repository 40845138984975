import { gql } from '@apollo/client';
import { AUTHOR_FIELDS } from '../fragments/core';
import { COLUMNS_DATA, COMPONENTS_DATA, CONTAINER_DATA, LAYOUT_ROW, ROWS_DATA } from '../fragments/layouts';
import { PAGE_CORE, PAGE_SEO, SEO_FRAGMENT } from '../fragments/pages';

const PAGE_FIELDS = ` 
  title
  description  
  unique
  slug 

  date 
  map 
  data 
  type
  dynamic 

  ${SEO_FRAGMENT}
  
  siteId 
  isEditing
  status 
  
  version
  date
  updatedAt 
  _id
`; 

const PAGE_UPDATES = ` 
  title
  description 
  siteId 
  version
  date
  updatedAt 
  _id
  updates
`

export const PAGES = gql`
query pageMany($filter: FilterFindManyPageInput) {
	pageMany(filter: $filter){
    ${PAGE_FIELDS}
  }
}`;
export const FILTER_PAGES = PAGES;

export const PAGE = gql`
query pageById($_id: MongoID!) {
  pageById(_id: $_id) {
    ${PAGE_FIELDS}
  }
}`;

export const PAGE_VERSIONS = gql`
query pageById($_id: MongoID!) {
  pageById(_id: $_id) {
    ${PAGE_UPDATES}
  }
}`;