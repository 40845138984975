
import { colors } from '@mosanic/styles';
import styled from 'styled-components';
import { color, flexbox, layout, space } from 'styled-system';


const a = styled.a`
    ${space}
    ${layout}
    ${color}
    ${flexbox}
    transition: color ease-in-out 0.1s;
    cursor: pointer;
    color: #212121;
    height: fit-content;
    text-decoration: none;
    width: fit-content;
    /* font-weight: 900; */

    :visited,
    :hover,
    :active {
        /* color: inherit; */
        cursor: pointer;
    }

    &:hover {
        color: ${colors.util.primary.base};

        a {
            color: #212121;

            &:hover {
                color: ${colors.util.primary.base};
            }
        }
    }

    > * {
        /* color: inherit; */
        &:hover {
            color: ${colors.util.primary.base};
        }
    }
    /* > :is(h1, h2, h3, h4, h5, h6) {
        &:hover {
            color: ${colors.util.primary.base}!important;
        }
    } */
`;

const LinkStyles = {

    a
}
export default LinkStyles