import { hasAttr } from "@util/Obj";
import { containsSubstr, isString } from "@util/Text";
import { FieldTypes } from "./constants";

import { getInitialContent } from "./fields/Editor/Handler/Content";
import { mosanic } from "./root";
import { addUnset, defaultSizingArray } from "./_Fieldsets";
import { templates } from "./_templates";
import { inputFields } from "./core/Collections/Field/FieldType";

// const registery = dynamic(() => import("./builder/registery"));

//Rules template
const noWidthRules = type => ({
  sizing: {
    width: {
      value: "auto",
      desc: `${type} width is always 100%.`
    },
    minWidth: {
      value: "auto",
      desc: `Min width can not be set to ${type}.`
    },
    maxWidth: {
      value: "auto",
      desc: `Max width can not be set to ${type}.`
    }
  }
})

//Default padding template
const paddingTemplate = {
  top: { 
    base: '10px'
  },
  bottom: { 
    base: '10px'
  },
  left: { 
    base: '10px'
  },
  right: { 
    base: '10px'
  }
}

//Default padding box template
const boxTemplate = {
  template: {
    padding: paddingTemplate,
  }
}

//Data slot that renders children
const managedSlot = {
  slots: {
    data: {
      manage: true,
      type: "slot",
    },
  },
}
//Identifier input field
const identifierField = {
  fields: {  
    identifier: {
      type: "string",
    },
  },
}
//Identifier input and content field
const identifierFieldContent = {
  fields: {  
    identifier: {
      type: "string",
    },
    content: {
      type: "string",
    },
  },
}
mosanic.registerComponent ({
  name: "image",
  props: {
    fields: {
      imageUrl: 'imageUrl', 
      alt: 'string',
      optionsGroup: {
        placeholder: {
          type: 'choice',
          options: 'blur|empty'.split('|')
        },
        quality: {
          type: 'slider',
          defaultValue: 100,
          min: 1,
          max: 100
        }
      }
    },
  },
  template: {
    sizing: {
      width: {base: '100px'},
      height: {base: '100px'}
    }
  },
  expand: 'slice',
});
mosanic.registerComponent ({
  name: "video",
  props: {
    fields: {
      videoUrl: 'videoUrl', 
      controlGroup: {
        alt: 'string',
        controls: 'toggle',
        autoplay: 'toggle',
        loop: {
          type: 'toggle',
          defaultValue: true,
        },
        cover: 'toggle',
        muted: 'toggle',
        blur: 'toggle',
        overlay: 'toggle',
        youtubeUrl: 'string',
      }
    },
  }, 
  expand: {
    type: 'tab',
    defaultValue: 'videoUrl',
  },
});



mosanic.registerComponent ({
  name: "imageslider",
  addQuick: true,
  displayName: "Image slider",
  props: {
    ...managedSlot,
    fields: { 
      infinite: "toggle",
      centerMode: "toggle",
      dots: "toggle",
      arrows: "toggle",
      autoplay: "toggle",
      fade: "toggle",
      autoplaySpeed: {
        type: "number",
        defaultValue: 3000,
        baseOnly: true,
        hide: props => props?.autoplay != 1
      },
      speed: {
        type:"number",
        defaultValue: 500,
        baseOnly: true,
      },
      slidesToShow: {
        type:"number",
        defaultValue: 2
      },
      slidesToScroll: {
        type:"number",
        defaultValue: 1
      },
      devMode: {
        type: 'toggle',
        baseOnly: true,
        defaultValue: 1
      }
    },
  },
  template: {
    ...templates.height
  }
});

mosanic.registerComponent ({
  name: "datalink",
  addQuick: true,
  displayName: "Data Link",
  props: {
    ...managedSlot,
    fields: { 
      entry: {
        type: "entry",
        // receive: "hasParentLink",
      },
      dynamic: "toggle",
      // hasParentLink: "toggle",
      excerpt: "toggle",
      limit: {
        type: 'string',
        defaultValue: '50',
        display: props => props.excerpt
      },
      end: {
        type: 'string',
        defaultValue: '...',
        display: props => props.excerpt
      },
      iframe: "toggle",
      html: 'toggle',
    },
  },
  path: 'fields.[field]',
});


mosanic.registerComponent ({
  name: "dataprovider",
  addQuick: true,
  displayName: "Data Provider",
  props: {
    ...managedSlot,
    fields: { 
      entry: {
        type: "collection",
      },
    },
  },
  path: 'fields.[field]',
});
 mosanic.registerComponent ({
  name: "stripeprovider",
  addQuick: true,
  displayName: "Stripe Provider",
  props: {
    ...managedSlot,
    fields: { 
      stripe_key: {
        type: "string",
      },
    },
  },
  path: 'fields.[field]',
});
mosanic.registerComponent ({
  name: "repeater",
  addQuick: true,
  displayName: "Repeater",
  props: {
    ...managedSlot,
    fields: { 
      collection: {
        type: "collection",
        display: props => !props?.hasParentLink
      },
      clones: {
        defaultValue: 2,
        type: "number"
      },
      info: {
        type: "label",
        label: 'Enable this button also on the child data links to receive the data.',
        display: props => props?.hasParentLink
      },
      hasParentLink: "toggle"
    },
  },
  template: {
    padding: paddingTemplate
  }
});
mosanic.registerComponent ({
  name: "icon",
  addQuick: true,
  displayName: "Icon",
  description: "Select or drop an custom icon",
  props: {
    fields: { 
      icon: {
        type: "icon",
      },
      invert: 'toggle',
      fill: 'color',
    },
    hide: ["stack", "grid", "scroll"],
    selected: {
      sizing: ["width"]
    },
  },
});

mosanic.registerComponent ({
  name: "dialog",
  props: {
    slots: {
      data: {
        manage: true,
        type: "slot",
      },
    },
    fields: { 
      dialog: {
        type: 'dialogSelector',
        baseOnly : true,
      },
      title: {
        type: "string",
      },
      size: {
        type: 'choice',
        baseOnly : true,
        defaultValue: 'md',
        options: [
          {value: 'xxs', label: 'Mini'},
          {value: 'xs', label: 'Extra small'},
          {value: 'sm', label: 'Small'},
          {value: 'md', label: 'Medium'},
          {value: 'lg', label: 'Large'},
          {value: 'xl', label: 'Extra Large'},
          {value: 'xxl', label: 'Oversized'},
        ]
      }
    },
    hide: ["stack", "grid", "scroll"],
  },
});

mosanic.registerComponent ({
  name: "gallery", 
  addQuick: true,
  description: "Gallery for images",
  props: {
    fields: {
      baseGroup: {
        images: {
          type: 'imageUrls'
        },
        cols: {
          type: 'number',
          defaultValue: 3
        },
      },
      controlGroup: {
        width: 'string',
        height: 'string',
        animate: {
          type: 'toggle',
          defaultValue: true
        },
        scrollable: {
          type: 'toggle',
          defaultValue: {base: true}
        },
      }
    }
  },
  expand: {
    type: 'tab',
    defaultValue: 'baseGroup'
  },
  template: {
    padding: paddingTemplate
  }
});

mosanic.registerComponent ({
  name: "tabheader", 
  addQuick: true,
  description: "Header for custom tabs",
  props: {
    ...managedSlot,
    ...identifierFieldContent,
  },
});
mosanic.registerComponent ({
  name: "googlemap", 
  addQuick: true,
  description: "Google map",
  props: {
    fields: {  
      apiKey: {
        type: "string",
        baseOnly: true
      },

      lat: {
        type: "string",
        baseOnly: true
      },
      lng: {
        type: "string",
        baseOnly: true
      },
      zoom: {
        type: "string",
        defaultValue: '8',
        baseOnly: true
      },
      theme: {
        type: 'select',
        options: addUnset('dark|light|minimal|custom'.split('|'))
      },
      custom: {
        type: 'code',
        display: props => props?.theme === 'custom'
      }
    },
  },
  template: {
    sizing: {
      height: {base: '150px'},
      width: {base: '150px'}
    },
  }
});

mosanic.registerComponent ({
  name: "tab",
  addQuick: true,
  description: "Multi purpose container",
  props: {
    ...managedSlot,
    ...identifierField
  },
  ...boxTemplate
});

mosanic.registerComponent ({
  name: "tabs",
  addQuick: true,
  description: "Schema for custom tabs",
  props: {
    slots: {
      header: {
        manage: true,
        type: "slot",
        allowed: ["tabheader", "tabheaders", "container"]
      },
      tabs: {
        manage: true,
        type: "slot",
        allowed: ["tab", "container"]
      },
    },
    fields: {  
      content: {
        type: "string",
        defaultValue: "Tabs"
      },
      active: {
        type: "string",
        defaultValue: "1"
      },
    },
  },
  ...boxTemplate
});




mosanic.registerComponent ({
  name: "expanderheader", 
  addQuick: true,
  description: "Header for expander",
  props: {
    ...managedSlot,
    ...identifierFieldContent,
  },
});

mosanic.registerComponent ({
  name: "expander",
  addQuick: true,
  description: "Multi droppable accordion expander",
  props: {
    ...managedSlot,
    ...identifierFieldContent,
  },
  ...boxTemplate
});
mosanic.registerComponent ({
  name: "expanders",
  addQuick: true,
  description: "Schema for expanders",
  props: {
    slots: {
      expanders: {
        manage: true,
        type: "slot",
        allowed: ["expander", "expanderheader", "container", "stack"]
      },
    },
    fields: {  
      active: {
        type: "string",
        defaultValue: "1"
      },
      closeOther: {
        type: "toggle",
        label: 'Auto close other expanders',
        defaultValue: "1"
      },
    },
  },
  ...boxTemplate
});


mosanic.registerComponent({
  name: "button",
  addQuick: true,
  description: "Schema for a customizable button",
  props: {
    slots: {
      start: {
        max: 1,
        type: "slot",
        allowed: ["icon", "image"],
      },
      content: "render",
      // renderAter: "2after -  ",
      // preBuild: {
      //   type: "string",
      //   defaultValue: 'preBlTest '
      // },
      end: {
        max: 1,
        type: "slot",
        allowed: ["icon", "image"],
      },
    },
    fields: {  
      content: {
        type: "string",
        defaultValue: "Button",
        localized: (props) => props?.translate === 1,
        baseOnly: true,
      },
      translate: {
        type: 'toggle',
        label: 'translate text',
        defaultValue: 0,
        baseOnly: true,
      },
      action: {
        type: 'choice',
        options: addUnset(['link', 'trigger']),
        baseOnly: true,
      },
      href: {
        type: "link",
        baseOnly: true,
        display: props => props?.action === 'link'
      },
      target: {
        type: "select",
        clearable: false,
        searchable: true,
        multiple: false,
        autoClose: true,
        baseOnly: true,
        options: ["default", "_blank", "_window"],
        display: props => props?.action === 'link'
      },
      toTop: {
        type: "toggle",
        display: props => props?.action === 'link'
      },
      type: {
        type: "select",
        clearable: false,
        searchable: true,
        multiple: false,
        autoClose: true,
        baseOnly: true,
        options: ["locale", "form submit", "form toggle"],
        display: props => props?.action === 'trigger'
      }
    },
    hide: ["grid", "scroll"],
    rules: {
      sizing: {
        height: {
          value: "auto",
          desc: "Height is always auto"
        },
        maxHeight: {
          value: "auto",
          desc: "Max height is always auto"
        }
      }
    }
  },
  fieldPath: 'fields',
  template: {
    padding: paddingTemplate,
    sizing: {
      minWidth: {
        base: '100px'
      },
    }
  }
});
mosanic.registerComponent({
  name: "link",
  addQuick: true,
  props: {
    // slots: {
    //   start: {
    //     type: "slot",
    //   },
    // },
    fields: {  
      href: "link",
      target: {
        type: "select",
        clearable: false,
        searchable: true,
        multiple: false,
        autoClose: true,
        baseOnly: true,
        options: ["default", "_blank", "_window"]
      },
      toTop: "toggle",
    },
    
  },
  template: {
    sizing: {minHeight: {base: '10px'}}
  },
  fieldPath: 'fields',
});
//Visual text area
mosanic.registerComponent ({
  name: "text",
  props: {
    fields: { 
      content: {
        type: "editor",
        display: props => props?.translate != 1,
        baseOnly: true,
        defaultValue: getInitialContent('Mosanic visual text area', 'p')
      }, 
      i18: {
        type: "editor",
        display: props => props?.translate,
        defaultValue: {
          en: getInitialContent('English visual area', 'p'), 
          nl: getInitialContent('Dutch visual area', 'p')
        },
        localized:true
      }, 
      translate: {
        type: 'toggle',
        label: 'translate text',
        defaultValue: 0,
        baseOnly: true,
      },
    },
    hide: ["stack", "grid", "scroll"],
    rules: {
      sizing: {
        height: {
          value: "auto",
          desc: "Height is always auto"
        },
        maxHeight: {
          value: "auto",
          desc: "Max height is always auto"
        }
      }
    },
    selected: {
      sizing: ["maxWidth"]
    },
  },
  fieldPath: 'fields',
});
//Localized content
mosanic.registerComponent ({
  name: "localizedtext",
  props: {
    fields: { 
      content: {
        type: "editor",
        defaultValue: {
          en: getInitialContent('English visual area', 'p'), 
          nl: getInitialContent('Dutch visual area', 'p')
        },
        localized:true
      },
    },
    hide: ["stack", "grid", "scroll"],
  },
});
 
//Simple text area
mosanic.registerComponent ({
  name: "content",
  props: {
    fields: { 
      content: {
        type: "content",
        defaultValue: 'Mosanic text area content.',
      },
    },
    hide: ["stack", "grid", "scroll"],
  },
});
mosanic.registerComponent ({
  name: "heading",
  props: {
    slots: {
      data: {
        manage: true,
        type: "slot",
        allowed: ['datalink']
      },
    },
    fields: { 
      content: {
        type: "string",
        defaultValue: 'Mosanic Heading',
      },
      level: {
        type: 'choice',
        baseOnly : true,
        defaultValue: '2',
        options: [
          {value: '1', label: 'H1'},
          {value: '2', label: 'H2'},
          {value: '3', label: 'H3'},
          {value: '4', label: 'H4'},
          {value: '5', label: 'H5'},
          {value: '6', label: 'H6'},
        ]
      }
    },
    hide: ["stack", "grid", "scroll"],
  },
});
mosanic.registerComponent ({
  name: "code",
  addQuick: true,
  displayName: "Code editor",
  description: "Edit code.",
  props: {
    fields: { 
      language: {
        type: 'select',
        options: "js|html|css|json".split("|"),
        baseOnly: true,
      },
      content: {
        type: "code",
      },
    },
    show: ['fields'],
  },
}); 
mosanic.registerComponent ({
  name: "script",
  addQuick: true,
  displayName: "Script link",
  description: "Insert script tag.",
  props: {
    fields: { 
      url: {type: "string"},
      strategy: {
        type: "select",
        options: "afterInteractive|beforeInteractive|lazyOnload|worker".split("|"),
      },
      dataAttrObject: {type: "code"},
      inlineScriptCode: {type: "code"},
      onLoadCode: {type: "code"},
      onReadyCode: {type: "code"},
      onErrorFunc: {type: "code"},
      dangerCode: {type: "code"},
    },
    show: ['fields'],
  }, 
});

mosanic.registerComponent({
  name: "menu",
  addQuick: true,
  description: "Schema for a menu rendering",
  props: {
    fields: {
      menu: {
        type: 'select',
        option: ['main','sub','footer']
      },
    },
    hide: ["grid", "scroll"],
  },
  // fieldPath: 'fields',
});


mosanic.registerComponent ({
  name: "form",
  addQuick: true,
  displayName: "Form",
  props: {
    slots: {
      data: {
        manage: true,
        type: "slot",
      },
    },
    fields: { 
      formGroup: {
        form: {
          type: "form",
          display: props => !props.login
        },
        when: {
          type: "form",
          display: props => !props.login
        },
        verifyEmail: {
          baseOnly: true,
          type: 'toggle',
          display: props => props.login
        },
        registerLink: {
          baseOnly: true,
          type: 'link',
          display: props => props.login
        },
        forwardSlug: {
          baseOnly: true,
          type: 'link',
          display: props => props.login
        },
      },
      inputGroup: {
        background: "color",
        color: "color",
        border: "string",
        radius: {
          type: "number",
          baseOnly: true
        }
      },
      actionGroup: {
        respond: 'string',
        advanced: 'toggle',
        login: 'toggle',

      }

    },
    // show: ['fields'],
  }, 
  expand: {
    type: 'tab',
    defaultValue: 'formGroup',
  },
  path: 'fields.[field]',
});
mosanic.registerComponent ({
  name: "account",
  addQuick: true,
  displayName: "Account",
  props: {
    fields: { 
      pageGroup: {
        screen: {
          baseOnly: true,
          type: 'select',
          options: [...'user|profile|account|preferences|security'.split('|'),{value: null, label: 'All'}]
        },
        tab: {
          type: 'select',
          baseOnly: true,
          options: 'user|profile|account|preferences|security'.split('|'),
          searchable: false,
          display: props => !props.screen
        },
      },
      accountGroup: {
        settings: {
          type: 'string',
          defaultValue: 'Your personal details and information.'
        },
        author: {
          type: 'string',
          defaultValue: "This information is displayed under the articles created by you."
        },
        collections: {
          type: 'content',
        },
      },
      prefGroup: {
        interface: {
          baseOnly: true,
          type: 'toggle'
        },
        help: {
          baseOnly: true,
          type: 'toggle'
        },
        privacy: {
          baseOnly: true,
          type: 'toggle'
        },
        builder: {
          baseOnly: true,
          type: 'toggle'
        },
        dashboard: {
          baseOnly: true,
          type: 'toggle'
        },
        mail: {
          baseOnly: true,
          type: 'toggle'
        },
        platformName: {
          type: 'string',
        },
      },
      securityGroup: {
        twoStepsVerification: {
          baseOnly: true,
          type: 'toggle'
        },
        passwordText: {
          defaultValue: "Your password and login to your account.",
          baseOnly: true,
          type: 'string'
        },
      },
      loginGroup: {
        loginText: {
          type: 'string',
          baseOnly: true,
        },
        verifyEmail: {
          baseOnly: true,
          type: 'toggle',
        },
        registerText: {
          type: 'string',
          baseOnly: true,
        },
        registerLink: {
          baseOnly: true,
          type: 'link',
        },
        forwardText: {
          type: 'string',
          baseOnly: true,
        },
        forwardSlug: {
          baseOnly: true,
          type: 'link',
        },
      }


    },
    // show: ['fields'],
  }, 
  expand: {
    type: 'tab',
    defaultValue: 'page',
  },
  path: 'fields.[field]',
});

mosanic.registerComponent ({
  name: "auth",
  addQuick: true,
  displayName: "Auth forms",
  props: {
    fields: { 
      type: {
        type: 'select',
        baseOnly: true,
        options: 'login|register|reset|all'.split('|'),
      },
      onePage: {
        type: 'switch',
        baseOnly: true,
      },

    },
  }, 
  // expand: {
  //   type: 'tab',
  //   defaultValue: 'page',
  // },
  path: 'fields.[field]',
});

mosanic.registerComponent ({
  name: "user",
  addQuick: true,
  displayName: "User",
  props: {
    fields: { 
      type: {
        type: 'select',
        baseOnly: true,
        options: 'login|register|reset|all'.split('|'),
      },
      onePage: {
        type: 'switch',
        baseOnly: true,
      },

    },
  }, 
  // expand: {
  //   type: 'tab',
  //   defaultValue: 'page',
  // },
  path: 'fields.[field]',
});

mosanic.registerComponent ({
  name: "inputfield",
  addQuick: true,
  displayName: "Input",
  props: {
    fields: { 
      field: {type: "inputfield"},
      fieldType: {
        type: 'select',
        options: inputFields,
        baseOnly: true
      }

    },
    // show: ['fields'],
  }, 
});


mosanic.registerComponent ({
  name: "screen",
  props: {
    hide: ["stack", "grid", "flex", "margin"],
    fields: {
      name: 'string',
    },
    rules: {
      sizing: {
        height: {
          value: "auto",
          desc: "Height is always auto"
        },
        minHeight: {
          value: "auto",
          desc: "Min height can't be defined yet"
        },
        maxHeight: {
          value: "auto",
          desc: "Max height can't be defined yet"
        }
      }
    },
    selected: {
      sizing: ["maxWidth"]
    },
  },
});


mosanic.registerComponent ({
  name: "column",
  props: {
    // "hide": ["stack", "grid", "scroll"],
    rules: {
      ...noWidthRules('column')
    },
    selected: {
      sizing: ["height"]
    },
  },
  ...boxTemplate
});

mosanic.registerComponent ({
  name: "row",
  props: {
    // "hide": ["stack", "grid", "scroll"],
    fields: {
      
    },
    rules: {
      ...noWidthRules('row')
    },
    selected: {
      sizing: ["height"]
    },
  },
  ...boxTemplate
});
mosanic.registerComponent ({
  name: "columns",
  props: {
    fields: {
      containerMaxWidth: 'string',
      containerMinWidth: 'string'
    },
    // "hide": ["stack", "grid", "scroll"],
    rules: {
      ...noWidthRules('columns')
    },
    selected: {
      sizing: ["height"]
    },
  },
  ...boxTemplate
});


mosanic.registerComponent ({
  name: "socialgrid",
  addQuick: true,
  description: "Schema for social grid",
  props: {
    fields: {  
      tokenKey: {
        type: "string",
        defaultValue: "IGQVJVa2pjM3pCbU9seENnaUQ0XzJRWXp2c0FYZAXA3b1RlbWlyYmpqMmJia0VTSzJvMEdjWkJkeXNnSWNON2ZAhT2tDeUNhS2xrenNfVHJwRFhKdF9COWdmd1dGT0xpRkZAyejJ3R3ZAlU0x4NEI3eDM3MgZDZD",
        baseOnly: true
      },
      show: {
        type: "number",
        baseOnly: true,
        defaultValue: 6
      },
    },
  },
  template: {
    paddingTemplate
  }
});



export const LAYOUT_SCHEMAS = mosanic.schemas;
export const getItemSchema = (type) => LAYOUT_SCHEMAS[type]




const getRules = (type) => getItemSchema(type)?.props?.rules

export const getItemRules = (type, fieldset) => getRules(type) && getRules(type)?.[fieldset];
export const hideFieldset = (type, fieldset) => Boolean(getItemSchema(type)?.props?.hide?.includes(fieldset))
export const showFieldset = (type, fieldset) => {
  const show = getItemSchema(type)?.props?.show;
  return Boolean(show) ? 
    show?.includes(fieldset) :
    true;
}

export const getItemSchemaRules = (type, fieldset) => getRules(type) && getRules(type)?.[fieldset]
export const fieldHasNoRules = (rules, field) => !hasAttr(rules, field);


//Initialization
export const getMosanicTemplate = (type) => {
  if(containsSubstr(type, 'stack')) type = 'stack';
  return mosanic.schemas[type]?.template;
}

export const getMosanicFields = (type) => {
  const fields = mosanic.schemas[type]?.props?.fields

  if(!fields) return null;
  let data = {};
  Object.entries(fields).map(([attr, field]) => {
    data = {
      ...data ,
      [attr]: field?.defaultValue ?  field.defaultValue : null
    }
  });
  return data;
};
export const getMosanicSlots = (type) => mosanic.schemas[type]?.props?.slots;



const isField = (child) => (child === 'fields' || child?.type === 'fields')
const isSlot = (child) => (child === 'slot' || child?.type === 'slot')

export const validateMosanicItem = (item) => {
  const slots = getMosanicSlots(item?.type)
  if(!slots) return item;

  let children = [];
  Object.entries(slots).map(([slot, slotValue], index)=> {
    if(isField(slotValue)) return;

    if(isSlot(slotValue)){
      children.push({
        ...slotValue,
        position: slot,
      });
    } else {
      let field = {
        data: slot,
      };
      field = !isString(slotValue) ?  
        {...field, ...slotValue} : 
        {...field, type: slotValue}

      children.push(field);
    }
    
  });
  return { ...item, children }
}

