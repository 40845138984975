import { hasAttr } from "@util/Obj";
import { combineString, toLowCase } from "@util/Text";
import { slugify } from "@util/Text";

// * Index paths 


const hasIndexes = site => site?.nav || site?.indexes || site?.dynamic
const hasIndex = (site, type) => hasAttr(site, type) && Array.isArray(site?.[type])

//Get all sites domains
export const getSubdomains = sites => sites?.filter((site) => site?.subdomain);
export const getCustomDomains = sites => sites?.filter((site) => site?.customDomain);

const getSingleSlugParam = (indexItem, domain) => ({
    params: {
        site: domain,
        slug: [indexItem?.slug],
    },
})

//If site has index type attr, get params
const getSlugs = (site, domainAttr, typeAttr) => !hasIndex(site, typeAttr) ? [] : 
site[typeAttr].map(indexItem => (
    getSingleSlugParam(indexItem, site[domainAttr])
))

//Get site indexes map
const getIndexesMap = (site, domainAttr) => ([
    ...getSlugs(site, domainAttr, 'nav'),
    ...getSlugs(site, domainAttr, 'indexes'),
    ...getSlugs(site, domainAttr, 'dynamic'),
])

//Map trough sites and specific domain attribute [subdomain or customDomain]
export const getSitesDomainPathMap = (sites, domainAttr) => sites.flatMap((site) => {
    if(!hasIndexes(site)) return []
    return getIndexesMap(site, domainAttr)
})



// * Collections paths


const isValidPathCollection = collection => Boolean(
        collection?.baseRoute && 
        collection?.entries?.length >= 1 && 
        collection?.siteId
        // collection?._id != '63359058737d68d97014da2d'
)

//Get specific site domain
const getSiteDomain = (sites, siteId) => {
    const site =  sites?.find(site => site?._id === siteId);
    
    //Todo ==> should we validate which is passed in params?
    // * or return just both? as two times path
    return site?.subdomain ? site.subdomain : site?.customDomain;
};

const getEntrySlug = (entry, collection) => slugify(toLowCase(entry[collection?.schema[0]?.name]));

const getMultiSlugParam = (slug, domain) => ({
    params: {
        site: domain,
        slug
        // slug: [combineString(slug, '/')],
    },
})

const getEntryPaths = (domain, collection) => collection.entries.map(entry => 
    getMultiSlugParam(
        [collection.baseRoute, getEntrySlug(entry, collection)], 
        domain,
    )
);

export const getCollectionsPathMap = (sites, collections) => !collections || !Array.isArray(collections) ? [] :
collections.flatMap(collection => {
    if(!isValidPathCollection(collection)) return []
    const domain = getSiteDomain(sites, collection.siteId)
    return [
        ...getEntryPaths(domain, collection), 
        getMultiSlugParam(
            [collection.baseRoute], 
            domain
        )
    ]
})



// * Fallback getStaticProps
export const getIndexPageId = (site, attr = '404') => {
    if(!hasIndex(site, 'indexes')) return null;
    return site?.indexes?.find(item => item?.[attr])?.pageId;
}
export const getDynamicPart = (site, pages, attr = 'header') => ( site?.[attr] ? 
    pages?.find(p => p?._id === site[attr]) : 
    null
);
export const findPage = (pages, pageId) => {
    const page = pages?.find(p => p?._id === pageId);
    return page ? page : pages[0];
}
// export const convert



export const getPageIndexes = (site) => site ? ([
    ...Array.isArray(site?.nav) ? site?.nav?.map(i => ({pageId: i?.pageId, slug: i?.slug})) : [],
    ...Array.isArray(site?.indexes) ? site?.indexes?.map(i => ({pageId: i?.pageId, slug: i?.slug})) : [],
    ...Array.isArray(site?.dynamic) ? site?.dynamic?.map(i => ({pageId: i?.pageId, slug: i?.slug})) : [],
]) : null