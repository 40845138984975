import { hasAttr } from "@util/Obj";
import { roundPrecise } from "@util/Round";
import { combineString, containsSubstr, cssSelector, isStartSubString, oneLineString, startSubString } from "@util/Text";
import { interact, media } from "./selectors";

const COL_WIDTH = 100 / 12;

// ? Validation 
//Validate if property exists and has value
const containsValue = (data, variant) => hasAttr(data, variant) && data?.[variant];


// ? Helpers
const splitVariant = (variant) => variant.split(/(<![:(]):/); //variant.split(/(?<![:(]):/);

//Get css selector for attr 
const getAttrSelector = (attr, before, extend) => {
    let parsed = cssSelector(attr);
    if(before) parsed = `${before}-${parsed}`;
    if(extend) parsed = `${parsed}-${extend}`;
    return parsed;
}

//Transform parser
const value = (val, fb = 0, metric = 'px', times = 100) => `${(val ? val : fb) * times}${metric}`;
const skewValue = val => value(val,  0, 'deg', 90)

const translate = move => (move?.x || move?.y) ?`translate(${value(move?.x)}, ${value(move?.y)})` : ``;
const skew = skew => (skew?.x || skew?.y) ?`skew(${skewValue(skew?.x)}, ${skewValue(skew?.y)})` : ``;

const rotate = rotate => (rotate?.deg) ?`rotate(${rotate.deg * 180}deg)` : ``;
const scale = scale => (scale?.percent) ?`scale(${scale.percent >= 0 ? (scale.percent + 1 ) : 1 - Math.abs(scale.percent) })` : ``;


export const parseTransform = props => oneLineString(`
    ${translate(props?.move)} 
    ${rotate(props?.rotate)}
    ${scale(props?.scale)} 
    ${skew(props?.skew)} 
`) 

//Parse and get potential metric values     //! change to validate CmpCntnr34459cb8a1f
const getParsedValue = ({value, type}, validate) => {
    if(type === 'metric') {

        if(containsSubstr(value, 'vw'))return validate.vw(value) 
        if(containsSubstr(value, 'vh'))return validate.vh(value) 

        if(value === 'contain') return 'fit-content';
        if(value === 'stretch') return '100%';
        if(isStartSubString(value, '--')) return `var(--${value})`;
    }
    if(type === 'object-fit'){
        return `object-fit: ${value}; `
    }
    if(type === 'grid') {
        if(value === 'base') return `min-width: ${roundPrecise(COL_WIDTH, 3)}%;`
        const roundedWidth = roundPrecise(value * COL_WIDTH, 3);
        return `width: ${roundedWidth}%; \n max-width:${roundedWidth}%;`;
    }
    if(type === 'color' && validate?.color) {
        return (value?.hue && value?.shade) ?
            validate.color(value.hue, value.shade) :
            value?.rgb ? `rgba(${combineString(Object.values(value.rgb), ',')})` :
            value?.hex ? value.hex :
            value;
    }
    if(type === 'position'){
        return (value === 'fixed' && !validate?.prod) ? 'sticky' : value
    }
    if(type === 'width') {
        return `${value}px`
    }

    if(type === 'transform'){
        return parseTransform(value)
    }

    return value?.value ? value.value : value;
}

//Get correct values of passed variant
const getValues = ({variant, value, attr, validate}) => {
    let base = ``;
    //Initial styling
    if(variant === 'base'){
        base += attr(value);
    }
    //Validate breakpoint
    else if(validate?.media(variant) || validate?.overwrite(variant, 'breakpoint')){
        if(validate.prod) base += `${media(variant, validate?.bps)}{`;
        base +=  `${attr(value, variant === validate?.breakpoint)}`;
        if(validate.prod) base += `}`;

        // if(isPreviewMedia(variant, preview)){
        //     base += attr( `${value}!important` );
        // }
    
    }
    //Validate variant
    else if(validate?.variant(variant) || validate?.overwrite(variant, 'variant')){
        base += attr( value+'!important' );

        // if(isPreviewVariant(variant, preview)){
        //     base += attr( `${value}!important` );
        // }

    } 
    //Validate interaction
    else if(validate?.interact(variant) || validate?.overwrite(variant, 'state')) {
        base += `${interact(variant)}{ ${attr(value)} }`;
        // if(isPreviewInteract(variant, preview)) {
        //     base += attr(value);
        // }
    } 
    else {
        //TODO --> fix combined
        // console.log(variant)
        //
        const split = splitVariant(variant)
        // console.log(validate.ids, validate.ids.includes(split[0]), split[0])
        if(
            validate?.media(split[0]) && 
            validate.ids.includes(split[0])
        ){
            if(validate.prod) base += `${media(split[0], validate?.bps)}{`
            base += getValues({
                variant: split[1],
                value,
                attr,
                validate
            })
            if(validate.prod) base += `}`
        }
        // // console.log(combined)
        // console.log(base)
        // let combinedValue = ``;
        // let cState = {
        //     media: false,
        //     variant: false,
        //     interact: false,
        // };
        // combined.map(part => {
        //     if(isMedia(part, preview, bps)) cState = {...cState, media: part}
        //     if(isVariant(part, preview, state)) cState = {...cState, variant: part}
        //     if(isInteract(part, preview)) cState = {...cState, interact: part}
        // })
        // // console.log(base)
        // //open parts
        // if(cState.media) base+= `${media(cState.media, bps)}{`
        // if(cState.interact) base += `${interact(cState.interact)}{`
        // base += attr(value)
        // if(cState.interact) base += `}`;
        // if(cState.media) base += `}`;

        // console.log(base)
        // base += 
        // console.log(cState)
        // console.log(state)
    }
    return oneLineString(base);
}

const getInspectValues = (variant, value, validate) => {
    let base;
    //Initial styling
    if(variant === 'base'){
       base = value
    }
    //Validate breakpoint
    else if(validate.overwrite(variant, 'breakpoint')){
        // base += `${media(variant, validate?.bps)}{ 
        //     ${attr(value, variant === validate?.breakpoint)}
        // }`;
         base = value
    
    }
    //Validate variant
    else if(validate.overwrite(variant, 'variant')){
        // base += attr( value+'!important' );
         base = value

    } 
    //Validate interaction
    else if(validate.overwrite(variant, 'state')) {
        // base += `${interact(variant)}{ ${attr(value)} }`;
        base = value
    } 
    else {
        //TODO --> fix combined
    }
    return base;
}


// ? Removers
const removeVariantFromData = (data, variant) => ({...data, [variant]: undefined})

const removeSmallerBpsFromData = (data, validate) => {
    if(!validate?.rmv?.length) return data;
    validate.rmv.map(bp => {
        data = removeVariantFromData(data, bp)
    })
    return data;
}

export {
    getAttrSelector,
    getParsedValue,
    containsValue,
    getInspectValues,

    removeVariantFromData,
    removeSmallerBpsFromData
};
export default getValues;