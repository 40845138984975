import jwt_decode from "jwt-decode";

import { getDif } from "@util/Date";
import { deleteCookie, recoverCookie, saveCookie } from "@util/server/cookie";
import { deleteSession, recoverSession, saveSession } from "@util/server/session";
import { AUTH_TOKEN } from "./constants";
import isEmpty from "@util/Empty";
import { isServer } from "@util/Device";


export const decodeToken = (token) => {
    const decodedToken = token ? jwt_decode(token) : {};

    if(!decodedToken?._id || !decodedToken?.email) return false;

    return {
        email: decodedToken.email,
        isAdmin: decodedToken.isAdmin,
        isActive: decodedToken.isActive,
        _id: decodedToken._id,
        exp: decodedToken.exp
    };
} 

export const isTokenExpired = (exp) => {
    const expires = new Date(exp*1000)
    const now = new Date();

    if(expires > now) return false;
    return getDif(expires)
}

export const isTokenValid = (token) => {
    const {exp, isActive} = decodeToken(token);

    return Boolean(!isTokenExpired(exp) && isActive)
}


const getToken = () => recoverCookie(AUTH_TOKEN);
const saveToken = (token) => saveCookie(AUTH_TOKEN, token);
const getSessionToken = () => recoverSession(AUTH_TOKEN)
const saveSessionToken = (token) => saveSession(AUTH_TOKEN, token);
const getLocalToken = () => !isServer() && localStorage.getItem(AUTH_TOKEN);
const saveLocalStorage = (token) => !isServer() && localStorage.setItem(AUTH_TOKEN, token)


export const recoverToken = () => getToken() || getSessionToken() || getLocalToken();
export const hasToken = () => !isEmpty(recoverToken())

export const storeToken = (token, remember = true) => {
    if(remember || true) saveToken(token);
    if(remember || true) saveLocalStorage(token);
    saveSessionToken(token);
}

export const clearSession = () => {
    if(!isServer()) localStorage.removeItem(AUTH_TOKEN);
    deleteCookie(AUTH_TOKEN);
    deleteSession();
}

export const isAuthenticated = () => Boolean(hasToken() && isTokenValid(recoverToken()))
export const getDecodedToken = () => hasToken() ? decodeToken(recoverToken()) : null;
