import { getItemGroup, getItemType, isColumns, isContainer } from '@mosanic/map/type';
import { ObjectID, toClassName } from '@mosanic/utils';
import { DataFactory } from './factory';

export const transformInitialItem = ({item}) => {
    const base = item?.component ? item.component : item;
    const ref = {
        itemType: getItemType(base.type),
        group: getItemGroup(base.type),
        _id: ObjectID()
    };
    const itemMap = {
        type: base.type, 
        ...ref,
        // wrapper: base?.wrapper ? base.wrapper : undefined, // automatic 
        children: transformItemChildren(base, ref),
    };
    const component = {
        ...base, 
        ...ref
    };
   
    return {itemMap, component};
}; 

export const transformInitialItemFull = ({item}, dispatch) => {
    let {itemMap, component} = transformInitialItem({item});
    if(isColumns(component.type) && dispatch) itemMap = DataFactory.columns({itemMap}, dispatch);
    return [itemMap, component]
}

export const copyItem = ({item}) => {
    const ref = {
        itemType: getItemType(item.type),
        group: getItemGroup(item.type),
        _id: ObjectID()
    };
    const itemMap = {
        type: item.type,
        ...ref,
        // wrapper: base?.wrapper ? base.wrapper : undefined, // automatic 
        children: transformItemChildren(item, ref),
    };
    const component = {
        ...item, 
        ...ref,
        className: toClassName(ref.itemType, item?.type, ref._id),
        sourceItemPath: undefined // todo validate
    };
   
    return {itemMap, component};
};

const transformItemChildren = (base, ref) => {
    if(base?.children) return base.children;
    if(isContainer(ref.itemType)) return base?.children ? base.children : [];
    return undefined;
}