import { toast } from 'react-toastify';

export const NOTIFICATION = '[notification]';
export const SET_NOTIFICATION = `${NOTIFICATION} set`;
export const REMOVE_NOTIFICATION = `${NOTIFICATION} remove`;
export const DISMISS_NOTIFICATION = `${NOTIFICATION} dismiss`;

export const TIMING_DELETE_NOTIFICATION = 3000; //ms
export const TIMING_DEFAULT_VARIANT = 'warning'; //ms

export const setNotification = ({
    message, 
    feature, 

    duration = 300,
    variant = 'warning',
    persist = false,
    position = 'top-center',
    delay = 0,

    meta
}) => ({
    type: `${feature} ${SET_NOTIFICATION}`,
    payload: message,
    meta: {
        feature, 
        duration,
        variant,
        persist,
        position,
        delay,
        ...meta
    }
});
// export const setProgress = ({progress, feature = 'upload', meta = null}) => ({
//     type: `${feature} ${SET_NOTIFICATION}`,
//     payload: message,
//     meta: {feature, ...meta}
// });
export const removeNotification = ({notificationId, feature}) => ({
    type: `${feature} ${REMOVE_NOTIFICATION}`,
    payload: notificationId,
    meta: {feature}
});
export const dismissNotification = ({notificationId, feature ='auto'}) => ({
    type: `${feature} ${DISMISS_NOTIFICATION}`,
    payload: notificationId,
})


export const presetNotification = (preset, replaceString = false, feature = 'undefined') => {
    const presets = {
        'not-supported' : `[replace] is not yet supported.`
    };

    const message = replaceString ? 
        presets[preset].replace('[replace]', replaceString) : 
        presets[preset];

    setNotification({message, feature});
}