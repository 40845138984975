import { getEntryLabel } from "@mosanic/fields/Source/helpers";
import { slugify } from "@util/Text";
import { ObjectID } from "./ObjectID";

//Entries

export const createNewEntry = (entry, userId) => ({
	date: new Date(),
	updatedAt: new Date(),
	_id: ObjectID(),
	userId,
	...entry
});

export const createUpdateEntry = entry => ({
	...entry,
	updatedAt: new Date()
})

// Collections

export const addEntry = (collection, entry) => collection?.entries ?  
	{...collection, entries: [...collection.entries, entry]} :
	{...collection, entries: [entry]} 

export const updateEntry = (collection, entry) => ({
	...collection,
	entries: collection.entries.map(e => e._id === entry._id ? ({...e, ...entry}) : e)
})

