import { colors } from '@mosanic/styles';
import styled, { keyframes, css } from 'styled-components';

const Container = styled.div` 
   position: relative;
  /*display: block; */
  overflow: hidden;
  width: 100%;
  height: auto; 
  min-height: ${props => 
    props.type === 'background' ? '250px' : 
    props.type === 'uploader' ? '50px' : 
    '50px'
  };
  ${props => props.type === 'uploader' ? 'height: 75px;' : ''}
  
`;


const DisplayImage = styled.div`
    position: relative;
    height: inherit;
    width: inherit;
    min-width: inherit;
    min-height: inherit;
    max-width: inherit;
    max-height: inherit;
    min-height: 25px;
    min-width: 50px;
    /* min-height: 100px; */
`
const Pulse = keyframes`
  0%  {transform: scale(1.1); }
  50% {transform: scale(1); }
  75% {transform: scale(1); }
  100%{transform: scale(1.1); }
`
const Icon = styled.div` 
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  animation: ease-in-out ${Pulse} 2s infinite;
  fill: ${colors.util.primary.base};
  margin: 0 auto;
  width: 35px;
  height: 35px;
`
const DropTarget = styled.button` 
  transition: all ease-in-out 0.3s;
  ${Icon}, svg {
    transition: all ease-in-out 0.3s;
  }
  width: 100%;
  min-height: 120px;
  height: 100%;
  max-height: none;
  border: 2px dashed ${colors.util.gray.light};
  background: ${colors.util.gray.lightest} ;
  margin: 0 auto;
  color: ${colors.util.gray.dark};

  &:hover {
    background: ${colors.util.gray.lighter} ;
    border-color: ${colors.util.primary.lighter};
    ${Icon} {
      animation-duration: 10s;
      svg {
        transform-origin: bottom center;
        transform: scale(1.1);
      }
    }
  }
  ${props => props.count >= 1 && css` 
    display: relative;
    height: 50px;
  `}
  /* ${props => props.type === 'input' && css` 
    height: 25px;
    min-height: 45px;
  `} */
`
const DropTargetWrapper = styled.div` 

`


const UploadedListItem = styled.li`
  list-style-type: none;
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const UploadedList = styled.ul` 
  padding: 0;
  width: 100%;

  ${UploadedListItem}{
    border-bottom: 1px solid ${colors.util.gray.light};
    
    &:last-child {
      border-bottom: none;
    }
  }
`;
const UploadedImage = styled.div` 
  transition: all ease-in-out 0.2s;
  position: relative;
  height: 75px;
  width: 75px;
  transform: scale(0.85);
  transform-origin: left center;
  video {
    width: 100%;
    height: 100%;
  }
  &:hover {
    transform: scale(0.9);
    cursor: pointer;
  }
`
const Controllers = styled.div` 
  /* position: ${props => (props.editor && !props.minSize) ? 'absolute' : 'relative'}; */
  display: inline-block;
  z-index: 3;
  /* position: absolute; */
`;

const Meta = styled.div` 
  margin-right: auto;
  margin-left: 5px;
  margin-top: 0px;
  min-width: 50%;
`

const ImageStyles = {
    Container,
    DisplayImage,
    Icon,
    DropTargetWrapper,
    DropTarget,
    Controllers,

    UploadedImage,
    UploadedList,
    UploadedListItem,

    Meta
}

export default ImageStyles;