import dynamic from "next/dynamic";
// const canRenderSchema = dynamic(() => import("@mosanic/_Schemas"));
import renderComponent from "@mosanic/builder/registery";

import { isFunc } from '@util/Funct';
import { useContextReference } from './Context';
// import { InlineEditor } from "../Editor";

// const InlineEditor = dynamic(() => import('@mosanic/fields/Editor/Inline'))


// import { RenderField } from "./Render";

// const RenderField = dynamic(() => import("./Render"))
// const InlineEditor = dynamic(
//     import("../Editor/Inline"),{
//         loading: () => (<span>...</span>),
//         ssr: false
//     }
// )
// const compMap = {
// 'Button':  dynamic(() => import("@mosanic/builder/Button")),
// 'Icon':  dynamic(() => import("@mosanic/builder/Icn")),
// 'Code':  dynamic(() => import("@mosanic/builder/Code")),
// 'Script':  dynamic(() => import("@mosanic/builder/Script")),
// 'Image':  dynamic(() => import("@mosanic/builder/Image")),
// 'Text':  dynamic(() => import("@mosanic/builder/Text")),
// 'Video':  dynamic(() => import("@mosanic/builder/Video")),
// 'Tabs':  dynamic(() => import("@mosanic/builder/Tabs")),
// 'TabHeader':  dynamic(() => import("@mosanic/builder/Tabs/TabHeader")),
// 'Container':  dynamic(() => import("@mosanic/builder/Container")),
// 'DataLink':  dynamic(() => import("@mosanic/builder/DataLink")),
// 'Textarea':  dynamic(() => import("@mosanic/builder/Textarea")),
// 'Heading':  dynamic(() => import("@mosanic/builder/Heading")),
// 'Repeater':  dynamic(() => import("@mosanic/builder/Repeater")),
// 'ImageSlider':  dynamic(() => import("@mosanic/builder/ImageSlider")),
// }


export const RenderComp = ({fieldType, fieldData, isDev = true}) => {

    const reference = useContextReference(isDev)
    // console.log(reference)
    
    if(fieldType === 'render' && reference){
        const Render = renderComponent(reference.ref.type) 
        if(!Render || !isFunc(Render)) {
            console.warn(`Could not find a valid render method for: ${JSON.stringify(reference.ref)}`);
            return null
        }
        return (
            <>
            {Render(reference)}
            </>
        )
    }
    return null
}
export default RenderComp; 