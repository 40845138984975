import styled, {css} from "styled-components";

const Track = styled.div` 
    height: ${props => props.height+'px'};
    background: ${props => props.track};
    transition: all ease-in-out 0.3s;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
`

const Progress = styled.div` 
    transition: all ease-in-out 0.3s;
    height: 100%;
    width: ${props => props.value+'%'};
    background: ${props => props.color};
    border-radius: 4px;
`

const ProgressStyles = {
    Track,
    Progress
}

export default ProgressStyles;