
import { useState } from 'react';

import { Button } from '@mosanic/fields';
import { AdvancedLabel, ControlPopper, Expander, InputGroup, LoadingDots } from '@mosanic/items';
import { List, ListItem } from '@mosanic/items/List';
import { parseImageSrc } from '@mosanic/utils/Image';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useCreateEntry } from '../Mutate';
import { createEntryFormatter } from '../Mutate/create';
import { TwoColumns } from '@mosanic/items/Page/Columns';
import { ArrowDownIcon, ArrowLeftIcon } from '@radix-ui/react-icons';
import { removeEmpty } from '@util/Obj';
import { useGetCollection, useGetEntries } from '../Query';
import { Col, Wrap } from '@mosanic/styles';

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })
export const EditorBaseFields = ({schema, callback, formData, handler}) => {
    const [action, [data, loading, error]] = handler ? handler({callback, collection: formData}) : [() => null, [null, false, null]]
    // const entries = formData?.entries;

    const [state, setState] = useState({
        progress: '',
        loading: false,
        ids: [],
        log: []
    })

    const [entries] = useGetEntries({collectionId: formData._id, silent: true})
    const [collection] = useGetCollection({_id: formData._id, silent: true})

    // const [addEntry, [addData, loadingAdd, errorAdd]] = useCreateEntry({
	// 	callback: id => setState(state => ({
    //         ...state, 
    //         progress: `Converting: ${id}`,
    //         ids: [...state.ids, id],
    //         log: [...state.log, `Converting: ${id}`]
    //     })),
    //     collection: formData
		
	// })
   
    const linkEntries = () => {
        action({
            data: {
                ...collection,
                entries: entries?.map(e => (e._id)),
                linked: undefined
            }
        })
    }

    const isLoading = (loading || state.loading)

 
//     Converting: 640b562df6eb9a91e2ca8ba7
// Converting: 640b562df6eb9a91e2ca8ba9
// Converting: 640b562df6eb9a91e2ca8bab
// Converting: 640b562df6eb9a91e2ca8bad
    return (
        <InputGroup cols={1}>
            <Wrap>
                {collection?.entries?.length < entries?.length && (
                    <Col width={[1, 1/2]} px={2}>
                        <AdvancedLabel as="h4" label="Relink entries" span/>
                        {entries?.map(entry => (
                            <ListItem  key={entry?.title} style={{display: 'flex'}}  >
                                {entry?.image && (
                                    <Image src={parseImageSrc(entry?.image, true)?.full} width="35px" height="20px" objectFit="contain" alt="preview" />
                                )}
                                <div style={{paddingLeft: '10px'}}>
                                    <b>{entry.title}	
                                    </b>
                                    <AdvancedLabel label={
                                        collection?.entries?.includes(entry?._id) ?
                                            'Included' : 'Not linked'
                                    }/>

                                </div>
                            </ListItem>
                        ))}
                        <Button 
                            label={isLoading ? <LoadingDots /> : 'Link entries'}
                            theme="primary"
                            size="small"
                            disabled={isLoading}
                            onClick={linkEntries}
                        />
            
                    </Col>
                )}
                <Col width={[1, 1/2]} px={2}>
                     <AdvancedLabel as="h4" label="Change base fields" span/>
                    <AdvancedLabel mb={4}>
                        Select the base fields of this formData
                    </AdvancedLabel>
                </Col>
            </Wrap>
        

            {error}
        </InputGroup>

    )
}