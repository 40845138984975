import { Button } from '@mosanic/fields/Button/Buttons';
import { useControlPopper } from '@mosanic/hooks';
import { ControlPopper } from '@mosanic/items';

import { Tooltip } from '@mosanic/items';
import { getTooltips } from '@redux/options/reducer';
import React, { Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { SIDEBAR_ITEM } from "../../Adapters/constants";

import dynamic from "next/dynamic";
import { circlePlus, trash } from '@mosanic/Icons/EditIcons';
const SelectLayout = dynamic(() => import("../../Util/SelectLayout"))




export default function RowAddSection(props) {

  const [grid, setGrid] = React.useState(false);

  const dispatch = useDispatch();
  const tooltips = useSelector(getTooltips); 
  const addNewRow = () => {
    setOpen(false); //close

    const newComponent = {
      // ...initialData.componentAdd,
      
    };
    const newItem = {
      _id: newComponent._id,
      type: SIDEBAR_ITEM,
      component: newComponent
    };


  }


  const {anchor, open, close, isOpen} = useControlPopper()
  const handleClick = (event) => {
    open(event);
    setGrid(false);
    props.passRowState(true);

  };
  const handleClose = (event) => {
    close(event);
    setGrid(false);
    props.passRowState(false);
  };

  return (
    <Fragment>
      <ControlPopper anchor={anchor} close={handleClose}>
        <span>
          Choose a section layout
        </span>
        {!grid ? (
        <div className="controlThumbnails -list">
          <div className="item thumbnail-wrapper" onClick={addNewRow}>
            <Tooltip title={!tooltips ? "" : "Blank"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path fillRule="evenodd" d="M8 21v3h3v2H8c-1.103 0-2-.897-2-2v-3h2zm10 3v2h-4v-2h4zm8-3v3c0 1.103-.897 2-2 2h-3v-2h3v-3h2zM8 14v4H6v-4h2zm18 0v4h-2v-4h2zm-2-8c1.103 0 2 .897 2 2v3h-2V8h-3V6zM11 6v2H8v3H6V8c0-1.103.897-2 2-2h3zm7 0v2h-4V6h4z"></path>
              </svg> 
            </Tooltip>
          </div>
          <div className="item thumbnail-wrapper" onClick={() => setGrid(!grid)}>
            <Tooltip title={!tooltips ? "" : "Grid"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path fillRule="evenodd" d="M17 15V8h7l.001 7H17zm7.002 9H17v-7h7.001l.001 7zM8 24h7v-7H8v7zm0-9h7V8H8v7zm16-9H8c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2z"></path>
              </svg>
            </Tooltip>
          </div>
          <div className="item thumbnail-wrapper" onClick={addNewRow}>
            <Tooltip title={!tooltips ? "" : "Layout"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path fillRule="evenodd" d="M20 24v-4h4.001l.001 4H20zM8 24h10v-4H8v4zm0-12h4V8H8v4zm0 6h16.001v-4H8v4zm6-6h10V8H14v4zm10-6H8c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2z"></path>
              </svg>
            </Tooltip>
          </div>
        </div>
        ): (
          <SelectLayout rowData={null}></SelectLayout>
        )}
                
      </ControlPopper>
      <Button
          variant="text"
          id="row-controls-btn"
          aria-controls="row-controls"
          className="trigger"
          onClick={handleClick}
        >
          <span className="add">
            {Boolean(anchor) ? (
                trash
            ) : (
              <Tooltip title={!tooltips ? "" : "Add section"}>
                {circlePlus}
              </Tooltip>
            )}
          </span>
        </Button>
      </Fragment>
  );
}
