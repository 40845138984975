
import { getItemByPath } from '@mosanic/utils/map/get';
import { splitPath } from '@mosanic/utils/map/paths';
import { feature } from '@redux/util';
import { hasAttr } from '@util/Obj';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { default as actions } from './action';

const initialState = {
    currentMap: 'root',
    maps: {
        root: []
    }
};

 
const mapReducer = (map = initialState, action) => produce(map, draft => {

    switch (action.type) {
        case HYDRATE:
            return {...map, ...action.payload};

        case actions.MAP_UPDATE:
            draft.maps[draft.currentMap] = action.payload;
            return draft;

        case actions.MAP_SELECT: 
            const name = action.payload;

            //Preset
            if(name === 'clear') { draft.currentMap = null; return draft; }
            if(name === 'artboards') { draft.currentMap = 'artboards'; return draft; }

            //Create new map if not initialized yet.
            if(!hasAttr(draft.maps, name)){
                draft.maps[name] = []
            }
            draft.currentMap = name;

            return draft;
            
        default:
            return map;
    }; //switch
});
export default mapReducer;

const mapStoreSelector = (state) => feature('map', state);

export const currentMap = state => mapStoreSelector(state)?.currentMap;

export const mapSelector = (state) => mapStoreSelector(state).maps[currentMap(state)];

export const itemMapByPathSelector = (state, path) => getItemByPath({map: mapSelector(state), path});

