import { gql } from '@apollo/client';



export const CHAT_COUNT = gql` 
query chatCount($filter:FilterCountChatInput) {
  chatCount(filter:$filter) 
}
`

export const FILTER_CHATS = gql`
query chatMany($filter: FilterFindManyChatInput, $limit: Int, $skip: Int) {
	chatMany(filter: $filter, limit: $limit, skip: $skip){
		_id
		name
		isActive
		status
		subscription
		updatedAt
		date
		siteId
		userId
		unreadFor
		queryId
		teamId
		receivers {
			teams 
			users
		}
		latestMessage
		priority
		messages
		history
		assets
	}
}
`;
