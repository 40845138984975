
import { AuthForm } from "@mosanic/auth/AuthForm";
import { UserProfile } from "@mosanic/components/User/Profile";
import { UserAccount } from "@mosanic/core/Account/Account";
import { UserPreferences } from "@mosanic/core/Account/Preferences";
import { UserSecurity } from "@mosanic/core/Account/Security";
import { ControlTabs } from "@mosanic/items/Control/ControlButtons";
import { isAuthSelector, siteSelector, userIdSelector } from "@redux/auth/reducer";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

const groupStyle = {margin: '30px 0', background: '#fff', padding: '20px', borderRadius: '8px', border: '1px solid #eee'}


const Account = ({fields, ref, editor, active = false, clone}, children) => {
    const router = useRouter()
    const {source, handler} = editor;
    const {screen, tab} = source || {screen: 'account', tab: 'account'};
    const {options} = useSelector(siteSelector) || {options: null}
    const setTab = (tab) => handler({value: tab, extend: 'tab'});
    const _loggedInId = useSelector(userIdSelector)
    const viewingId = router?.query?.slug?.[1];
    const otherUser = viewingId != _loggedInId && viewingId;



    const login = options?.users?.content?.login

    const isAuth = useSelector(isAuthSelector)

    return isAuth ? (
        <>
        {!screen && !otherUser && (
            <ControlTabs mb={4} setActive={t => setTab(t)} active={tab || 'account'} options={'profile|account|preferences|security'} />
        )}
        <Render screen={otherUser ? 'profile' : (!screen || screen === 'all') ? tab : screen } source={source} _id={viewingId}/>
        </>
    ) : (
        <div style={{...groupStyle, textAlign: 'center'}}>
            <AuthForm verify={false} accountActions content={{header: login}} />
        </div>
    )

    //todo --> make 'collection' entries
        // ? ----> linked to users
        // ? - add option in collection schema
        // ? - add correct user id to collection

    // todo --> for 'user' management
        // ? -> Select what user can create/ edit
        // ! : view: wp user roles management
} 
 
const Render = ({screen, source, _id}) => {

    source = {overwrite: source}
    if(screen === 'profile') return <UserProfile {...source} _id={_id} groupStyle={groupStyle} />
    if(screen === 'security') return <UserSecurity {...source}/>
    if(screen === 'preferences') return <UserPreferences {...source}/>
    if(screen === 'account') return <UserAccount {...source}/>
    return null
}

export default Account;