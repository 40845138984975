import { If } from "@JSX";
import { default as s } from "./Page.styles";
import { PageNav } from "./PageNav";
export const Page = ({
    white = true, 
    preTitle = null, 
    title = null, 
    slug= null, 
    fullWidth = false, 
    subSlug = null,
    center= false, 
    children, 
    auth = false, 
    sideLinks = null,
    ...other
}) => {
    return (
        <s.Page white={white} fullWidth={fullWidth} center={center} {...other}>
   
            <If condition={ Boolean(slug) }>
                <PageNav slug={slug} />
            </If>
            {subSlug}
                <If condition={ Boolean(title) || Boolean(preTitle) }>
                    <s.Header>
                        {preTitle && (
                            <s.PreTitle>
                                {preTitle}
                            </s.PreTitle>
                        )}
                        <h2 className="title gr-text-4">
                            {title}
                        </h2>
                    </s.Header>
                </If>
                
                <s.Content center={center} m={4}>
                    {children}
                </s.Content>
        </s.Page>
    );
}
