import { setEditorAction } from '@redux/inspect/action';
import { isEditingSelector } from '@redux/inspect/reducer';
import { useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

export const useEditorMenu = (screen = 'undefined') => {
    const showEditor = useSelector(isEditingSelector);
    const dispatch = useDispatch();

    //Handlers
    const toggle = useCallback((newState) => {
        dispatch(setEditorAction({screen, state: newState})) 
    },[dispatch, screen]);

    const toggleEditor = useCallback(() => {
        toggle(!showEditor)
    }
    , [showEditor, toggle]);
    
    return [
        showEditor,
        toggleEditor
    ]
}
