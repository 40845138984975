import { useEffect, useMemo } from "react"

import { useGetMail } from "@mosanic/cms/Query/byId"
import { useSendOne } from "@mosanic/components/Mail"
import { useEntryMailConversion } from "@mosanic/utils/mail"

import { removeSpaces, toLowCase } from "@util/Text"


export const useEntryMails = ({mail, collection, state, setState}) => {
    const [sendMail, sendData, sendLoading] = useSendOne()
 
    const [mail_data, loading] = useGetMail({_id: mail?.mailId, silent: true}) 
    const template = mail_data?.templates?.find(t => t.template_id === mail?.template);

    const parsedMail = useEntryMailConversion({
        baseRoute: collection?.routing?.baseRoute,
        schema: collection?.schema, 
        template, 
		entry: mail?.entry,
	})


// d-aafe89c6f02644e6be7c8362642570af 
    useEffect(() => {
        if(parsedMail?.to && template && !loading && mail && mail?.entry?.slug && mail_data?._id && !state?.send && mail?.mailId) {
            sendMail({
                templateId: removeSpaces(mail_data?.data?.templateId),
                from: toLowCase(mail_data?.data?.defaultSender),
                subject: parsedMail.subject, 
                to: parsedMail?.to,
                // to: 'thijs.zijdel@gmail.com', 
                preHeader: parsedMail.preHeader, 
                subHeader: "dep.", 
                mainHeader: "dep.", 
                mainText: parsedMail?.content
            }) 


            setState(state => ({...state, send: 'mail'}))
        }
    },[mail_data, mail, loading, parsedMail, template, setState, state])

    return loading ? loading : sendLoading ? sendLoading : 'Send mail'
}