import { apiError, apiSuccess } from "./action";


/**
 * Run graphql request on client
 * @param {action} action {name, query, variables, feature } = action.meta;
 * @returns dispatched action
 */
export const runGQLRequest = (action) => (dispatch, _, {client}) => {
  const {name, query, variables, feature } = action.meta;


  return client({query, variables})
    .then((data) => dispatch(apiSuccess({response: data?.[name], feature })))
    .catch(error => dispatch(apiError({error: error, feature })))
    // .finally(()=> console.log('done'))
};

/**
 * Run graphql mutation on client
 * @param {action} action {name, query, variables, feature } = action.meta;
 * @returns dispatched action
 */
export const runGQLMutation = (action) => (dispatch, _, { client }) => {
  const {name, mutation, variables, feature} = action.meta;
  
  return client
    .mutate(name, mutation, variables)
    .then((data) => dispatch(apiSuccess({response: data, feature})))
    .catch(error => dispatch(apiError({error: error, feature})))
};
