import { combineAttr, hasAttr } from "@util/Obj"
import { useSelector } from "react-redux"
import { siteIdSelector } from "@redux/auth/reducer"

import {
    useGetCollections,
    useGetCollectionIndexes,
    useGetEntries,
    useGetMedia,
    useGetUsers,
    useGetTeams,
    useGetPages,
} from "./many"

import { 
    useGetCollection,
    useGetTeam
} from "./byId"

import {
    useGetMediaPage
} from "./pagination"

import { 
    useGetMediaCount 
} from "./count"


//Helpers
export const useSiteId = () => useSelector(siteIdSelector)
export const getParams = ({filter, siteId}) => filter ? {filter} : siteId ? {filter: {siteId}} : {}
export const compareAttrs = (obj, other, attr) => !hasAttr(obj, attr) && hasAttr(other, attr)
export const combine = (params, other, attr) => compareAttrs(params, other, attr) ? 
    combineAttr(params, other, attr) :
    params

export {
    //Many
    useGetCollectionIndexes,
    useGetCollections,
    useGetEntries,
    useGetMedia,
    useGetUsers,
    useGetTeams,
    useGetPages,

    //Single
    useGetCollection,
    useGetTeam,

    //Pagination
    useGetMediaPage,

    //Count
    useGetMediaCount
}
