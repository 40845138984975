export const API_REQUEST = '[API] Request ';
export const API_MUTATE = ' [API] Mutate ';
export const API_DELETE = ' [API] Delete ';
export const API_SUCCESS = '[API] Success ';
export const API_ERROR   = '[API] Error ';


// export const apiRequest = (method, url, body, onSuccess, onError) => ({
//     type: `${feature} ${API_REQUEST}`,
//     payload: body,
//     meta: { method, url, onSuccess, onError}
// })

export const apiRequest = (name, query, variables, body, feature) => ({
    type: `${feature} ${API_REQUEST}`,
    payload: body,
    meta: {name, query, variables, feature}
})


//Dep --> //todo fix to new apollo mutation
export const apiMutate = (name, mutation, variables, body, feature) => null

// ({
//     type: `${feature} ${API_MUTATE}`,
//     payload: body,
//     meta: {name, mutation, variables, feature}
// })


export const apiSuccess = ({response, feature}) => ({
    type: `${feature} ${API_SUCCESS}`,
    payload: response,
    meta: {feature}
})
export const apiError = ({error, feature}) => ({
    type: `${feature} ${API_ERROR}`,
    payload: error,
    meta: {feature}
})

export const isSuccesCall = (action, feature) => action.type === `${feature} ${API_SUCCESS}`
export const isErrorCall = (action, feature) => action.type === `${feature} ${API_ERROR}`