
export const MAIN_SHADE = 3;

export const COLOR_SHADES = [
    {
        name: 'Main shades', 
        type: 'shades',
        colors: [
            {type: '10', name: 'snow', value: '#F4F7FA'},
            {type: '20', name: 'mist', value: '#E7E9ED'},
            {type: '30', name: 'storm', value: '#D5D7DD'}, 
            {type: '40', inPalette: true, name: 'stone', value: '#7D818D'},
            {type: '50', name: 'night', value: '#5A5E68'},
            {type: '60', name: 'gone', value: '#1E1E20'},
        ]
    },{
        name: 'Primary', 
        type: 'main',
        colors: [
            {type: '10', name: 'soft', value: '#D7EFFE'},
            {type: '20', name: 'sky', value: '#A5DBFD'},
            {type: '30', name: 'soft', value: '#8ED1FC'}, 
            {type: '40', inPalette: true, name: 'bright', value: '#0693E3'},
            {type: '50', name: 'ocean', value: '#046095'}, 
            {type: '60', name: 'sea', value: '#023350'}, 
        ]
    },{
        name: 'Secondary', 
        type: 'secondary',
        colors: [
            {type: '10', name: 'Color', value: '#eef1f3'},
            {type: '20', name: 'Color', value: '#d5dce1'},
            {type: '30', name: 'Color', value: '#b3bfc9'}, 
            {type: '40', inPalette: true, name: 'Color', value: '#9aa6b0'},
            {type: '50', name: 'Color', value: '#565c62'}, 
            {type: '60', name: 'Color', value: '#222527'}, 
        ]
    },{
        name: 'Error', 
        type: 'error',
        colors: [
            {type: '10', name: 'Color', value: '#fbd0db'},
            {type: '20', name: 'Color', value: '#f37294'}, 
            {type: '30', name: 'Color', value: '#ef4370'},
            {type: '40', inPalette: true, name: 'Color', value: '#EB144C'}, 
            {type: '50', name: 'Color', value: '#bc103d'},
            {type: '60', name: 'Color', value: '#8d0c2e'}, 
        ]
    },{
        name: 'Warning', 
        type: 'warning',
        colors: [
            {type: '10', name: 'Color', value: '#fef1cc'},
            {type: '20', name: 'Color', value: '#fedc80'}, 
            {type: '30', name: 'Color', value: '#fdce4d'},
            {type: '40', inPalette: true, name: 'Color', value: '#FCB900'}, 
            {type: '50', name: 'Color', value: '#ca9400'},
            {type: '60', name: 'Color', value: '#654a00'}, 
        ]
    },{
        name: 'Success', 
        type: 'success',
        colors: [
            {type: '10', name: 'Color', value: '#ccf6e6'},
            {type: '20', name: 'Color', value: '#99ecce'}, 
            {type: '30', name: 'Color', value: '#4ddea9'},
            {type: '40', inPalette: true, name: 'Color', value: '#00D084'}, 
            {type: '50', name: 'Color', value: '#00a66a'},
            {type: '60', name: 'Color', value: '#005335'}, 
        ]
    },{
        name: 'Utility colors', 
        type: 'util',
        colors: [
            {type: '10', name: 'Color', value: '#fbcfec'},
            {type: '20', name: 'Color', value: '#f79fd9'}, 
            {type: '30', name: 'Color', value: '#f056bd'},
            {type: '40', inPalette: true, name: 'Color', value: '#EA0EA0'}, 
            {type: '50', name: 'Color', value: '#bb0b80'},
            {type: '60', name: 'Color', value: '#750750'}, 
        ]
    },
];