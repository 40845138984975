import { useKeyPress } from "@hooks/useKeyPress";
import { canDragDropSelector } from "@redux/options/reducer";
import { setDragging } from "@redux/ui/uiAction";
import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";

import { useDispatch, useSelector } from "react-redux";



const useItemDrag = ({map, isClone = false}) => {
    const dispatch = useDispatch()
    const canDragDrop = useSelector(canDragDropSelector)
    
    //Ref initialization to pass down
    const ref = useRef();
    const [isDrag, setIsDrag] = useState(false);

    const [{ isDragging }, drag] = useDrag({
        type: map?.itemType ? map?.itemType : 'none',
        canDrag: canDragDrop,
        item: map,
        collect: monitor => ({
            isDragging: monitor.isDragging(), 
        })
    });

    useEffect(() => {
        dispatch(setDragging(isDragging));
        setIsDrag(isDragging);
    }, [dispatch, isDragging]);

    if(!isClone) drag(ref);
    
    return {
        ref,
        isDragging: isDrag
    }

};
export default useItemDrag;
