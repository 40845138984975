import { containsSubstr, hasOnlyNumbers } from "@util/Text";
import { css } from "styled-components";
import { getAttr } from "./selectors";
import getValues, { containsValue, getAttrSelector, getParsedValue, removeSmallerBpsFromData, removeVariantFromData } from "./styleUtils";

// ? Inspector data
//Get attr group data for inspectors
const getAttrGroup = (data, validate) => {
    if(!data) return null;
    let base = {}
    
    data = removeSmallerBpsFromData(data, validate);

    Object.entries(data).map(([attribute, variants]) => {

        base = {
            ...base,
            [attribute]: getInspectAttr(variants, validate, attribute)
        }
    })
    return base;
}
 
//Get current breakpoint (if exists) from data
const getInspectAttr = (data, validate) => containsValue(data, validate.breakpoint) ? 
        data[validate.breakpoint] :
        data.base;

 
// ? Styling data
/** 
 * * Parse attribute group
* @param data object: { attr: data, attr2: data, ...}
* @param validate useValidate object
*
* @param before? string prepend css selector
* @param extend? string append css selector
*/
const parseAttrGroup = ({data, validate, before = null, extend = null, type = 'metric', log = false}) => {
    let base = ``;

    Object.entries(data).map(([attribute, variants]) => {
        base += parseAttr({
            data: variants, 
            attrName: getAttrSelector(attribute, before, extend), 
            validate,
            type
        });
    })
 
    return base;
}

const getTextAlign = (variant, value) => `text-align: ${value}; * { text-align: ${value}; }`
const getLineHeight = value => `line-height: ${hasOnlyNumbers(value) ? `${value}px` : value};`;
const getLetterSpacing = value => `letter-spacing: ${hasOnlyNumbers(value) ? `${value}px` : value};`;
const getFontStyle = value => `font-style:${value}!important;`;
const getFontTransform = value => `text-transform:${value}!important;`;
const getFontSize = value => `font-size:${value}px!important;`;

const parseLetterAttrGroup = ({data, validate}) => {
    let base = ``;

    // Object.entries(data).map(([attribute, variants]) => {
    //     base += parseAttr({
    //         data: variants, 
    //         attrName: getAttrSelector(attribute, before, extend), 
    //         custom: (variant, value) => getTextAlign(variant, value),
    //         validate,
    //     });
    // });

    if(data?.textAlign) base += parseAttr({
        data: data.textAlign, 
        attrName: 'text-align', 
        custom: (variant, value) => getTextAlign(variant, value),
        validate
    })
    if(data?.lineHeight) base += parseAttr({
        data: data.lineHeight, 
        attrName: 'line-height', 
        custom: (variant, value) =>  getLineHeight(value),
        validate
    })
    if(data?.letterSpacing) base += parseAttr({
        data: data.letterSpacing, 
        attrName: 'letter-spacing', 
        custom: (variant, value) =>  getLetterSpacing(value),
        validate
    })
    if(data?.style) base += parseAttr({
        data: data.style, 
        attrName: 'font-style', 
        custom: (variant, value) =>  getFontStyle(value),
        validate
    })
    if(data?.size) base += parseAttr({
        data: data.size, 
        attrName: 'font-size', 
        custom: (variant, value) =>  getFontSize(value),
        validate
    })
    
    if(data?.transform) base += parseAttr({
        data: data.transform, 
        attrName: 'text-transform', 
        custom: (variant, value) =>  getFontTransform(value),
        validate
    })
    return base

}
const parseBorderAttrGroup = ({data, validate}) => {
    let base = ``;

    Object.entries(data).map(([attribute, variants]) => {
        // const meta = getBorderMeta(attribute);

        base += parseAttr({
            data: variants, 
            attrName: getAttrSelector('border', null, attribute), 
            validate,
            type: attribute
        });

    })

    return base;
}

const parseTransformAttrGroup = ({data, validate}) => {
    let base = ``;
    
    Object.entries(data).map(([attribute, variants]) => {
        // const meta = getBorderMeta(attribute);

        base += parseAttr({
            data: variants, 
            attrName: 'transform', 
            validate,
            type: 'transform'
        });

    })

    return base;
}

//Parse pre-defined and validated attributes
const parseControlledAttr = ({map, base, data, append}) => {
    if(!map?.length) return [data, base]
    map?.map(variant => {
        if(!containsValue(data, variant)) return;
        //Add data
        base += append(variant, data[variant]) 

        //Rmv handled breakpoint
        data = removeVariantFromData(data, variant)
    })
    return [data, base]
}

/** 
 * * Parse single attribute
 * @param data object: { xs: data model, md: data model, ...}
 * @param attrName string
 * @param validate useValidate object
 * @param type string getValue type
 */
const parseAttr = ({data, attrName, validate, type = null, custom = null}) => {
    let base = ``;

    const append = (variant, value) => custom ? custom(variant,value) : getValues({
        variant, 
        value: getParsedValue({value, type}, validate), 
        attr: (value) => getAttr(attrName, value), 
        validate
    });
 
    //Handle base first
    if(containsValue(data, 'base')){
        base = append('base', data.base)
        data = removeVariantFromData(data, 'base')
    }

    //Remove non rendered breakpoints for dev
    if(!validate?.prod) {
        data = removeSmallerBpsFromData(data, validate);
    }
    
    //First media queries
    [data, base] = parseControlledAttr({
        map: validate?.ids,
        base, data, append
    });

    //Interactions
    [data, base] = parseControlledAttr({
        map: validate?.interactions,
        base, data, append
    });

    //Parse other data attributes
    Object.entries(data).map(([variant, value]) => {
        if(!value || !variant) return 
        base += append(variant, value)
    })

    return base;
}

// parse column grid 
const parseGridAttr = (data, validate) => {
    let base = getParsedValue({value: 'base', type: 'grid'});
    
    validate.ids.map(id => {
        if(containsValue(data, id)) base += getValues({
            variant: id, 
            value: data[id], 
            attr: value => getParsedValue({value, type: 'grid'}), 
            validate
        });
    })

    return base;
}


export {
    getAttrGroup,
    parseAttrGroup,
    parseBorderAttrGroup,
    parseLetterAttrGroup,
    parseTransformAttrGroup,

    parseGridAttr,
    getInspectAttr
};
export default parseAttr;