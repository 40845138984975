
import { default as Area } from "@mosanic/fields/TextArea";

const Textarea = ({fields, ref, editor, active = false}) => {
    const {source, handler} = editor;

    const handleInput = (value) => {
        if(value){
            handler({value, extend: 'content'})
        }
        
    }
    
    return active ?       (
         <Area
            value={source?.content}
            onChange={handleInput}
        />
    )  : (
        source?.content
    )
}
export default Textarea;