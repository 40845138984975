/* eslint-disable react/display-name */
import { EditorIcon } from '@mosanic/Icons/EditorIcons';
import { forwardRef } from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

const Btn = styled.span` 
  cursor: pointer;
  color: ${props => props.reversed ? 
    props.active ? '#fff' : '#aaa' : 
    props.active ? '#1082e9' : '#a5a5a5'
  };
  :hover {
    color: ${props => props.reversed ? 
      props.active ? '#d0d0d0' : '#939393' : 
      props.active ? '#0b5aa2' : '#6f6f6f'
    };
  }
`
const ValAsTxt = styled.div` 
    font-size: 14px;
    padding: 5px 20px;
    color: #404040;
    border-top: 2px solid #eeeeee;
    background: #f8f8f8;
`
const TxtLines = styled.div` 
  color: #404040;
  font: 12px monospace;
  white-space: pre-wrap;
  padding: 10px 20px;
  div {
    margin: 0 0 0.5em;
  }
`

const TxtBtn = styled.span` 
  text-transform: uppercase;
  font-size: 14px !important;
  vertical-align: text-bottom;
  padding: 0 5px;
  `
const TextInstr = styled.div` 
  white-space: pre-wrap;
    margin: 0 -20px 10px;
    padding: 10px 20px;
    font-size: 14px;
    background: #f8f8e8;
`

const Button = forwardRef(({ className, active, reversed, ...props}, ref) => (
    <Btn {...props} ref={ref}
        className={className}
        reversed={reversed}
        active={active}
    />
));

const Mnu = styled.div` 
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }

    position: relative;
    margin: 0;
    ${props => props.auto ? `
      padding: 1px 18px 5px;
      border-bottom: 2px solid #eee;
      margin-bottom: 20px;
    ` : `
    margin-top: -px;
    > span {
      margin: 2px 6px;
    }
    `}
`

const EditorValue = forwardRef(({ className, value, ...props}, ref) => {
    const textLines = value.document.nodes.map(node => node.text).toArray().join('\n');

    return (
        <div ref={ref} {...props}
            className={className}
            style={{margin: `30px -20px 0`}}
        >
          <ValAsTxt>
            Value as text
          </ValAsTxt>
          <TxtLines>
              {textLines}
          </TxtLines>
        </div>
    )
});


const Icon = forwardRef(({ className, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={'mosanic-icon '+className}
      style={{
        fontSize: "18px",
          verticalAlign: "text-bottom" 
      }}
    >
      <EditorIcon icon={props.children}/>
      </span>
));

const Text = forwardRef(({ className, ...props }, ref) => (
    <TxtBtn
      {...props}
      ref={ref}
      className={'mosanic-text '+className}
    >
      {props.children}
      </TxtBtn>
));

const Instruction = forwardRef(({ className, ...props }, ref) => (
    <TextInstr
      {...props}
      ref={ref}
      className={className}
    />
))


const Menu = forwardRef(({ className, auto=true, ...props }, ref) => (
    <Mnu {...props} ref={ref} auto={auto}
      className={className}
    />
));

const Portal = ({ children }) => ( typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
)


const Toolbar = forwardRef(({ className, minified, location = 'auto', ...props }, ref) => (
    <Menu {...props} ref={ref} auto={!minified && location === 'auto'}
      className={className}
    />
));

export {
  Button,
  EditorValue,
  Icon,
  Text,
  Instruction,
  Menu,
  Portal,
  Toolbar,

};

