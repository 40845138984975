
import { getItemMapFromActionOrState } from '@mosanic/map/get';
import { convertItemMap } from '@mosanic/map/transform';

import { resetInspectAction } from '@redux/inspect/action';
import { dataActions } from '@redux/map/data';
import { mapActions } from '@redux/map';

import { showSpinner } from '@redux/ui/uiAction';
import { isAction } from '@redux/util';

import {
    default as actions,
    DROP_ID
} from '../drop/action';


const SPINNER = {location: 'canvas', feature: DROP_ID };


export const removeComponentActionFlow = ({getState, dispatch}) => next => action => {
    next(action);
  
    if(isAction(action, actions.DELETE_HANDLE)){
        dispatch(showSpinner(SPINNER)); 
        const state = getState();

        const item = getItemMapFromActionOrState({action, state})
        
        if(!item?.path) return;

        //Remove item from map first
        dispatch(mapActions.removeMapAction({item}));

        //Handler for removing child components
        const returnAndDeleteItemMap = (child) => {
            dispatch(dataActions.removeDataAction({component: child}, child?._id));
            return child;
        }
        
        //Recursive delete children
        convertItemMap([item], returnAndDeleteItemMap)
        dispatch(resetInspectAction())
    }
};



export const removeMiddleware = [
    removeComponentActionFlow,

];