import { gql } from '@apollo/client';


export const ADD_TEAM = gql`
mutation teamCreateOne($record: CreateOneTeamInput!) {
	teamCreateOne (record: $record) {
        record {
            name
        }
        recordId
	}
}
`;

export const UPDATE_TEAM = gql`
mutation teamUpdateById($_id: MongoID!, $record: UpdateByIdTeamInput!) {
	teamUpdateById (_id: $_id, record: $record) {
		record {
			name
		}
	}
}
`;
