import { list_roles } from "@mosanic/components/Team/roles"
import { removeFirst } from "@util/Arr"
import { baseSchemaFields, convertColumnsToSchema } from "./base"

export const subscriptionColumns = [{
    name: 'gained',
    type: 'number'
},{
    name: 'Gained Pending',
    slug: 'gained_pending',
    type: 'number'
},{
    name: 'spend',
    type: 'number'
},{
    name: 'Spend Pending',
    slug: 'spend_pending',
    type: 'number'
},{
    name: 'credits',
    type: 'number'
}].map(f => ({
    ...f,
    slug: `subscription.data.${f?.slug ? f.slug : f.name}`
}))



export const userColumns = [{
    name: 'First name',
    slug: 'author.firstName',
    type: 'text',
},{
    name: 'Last name',
    slug: 'author.lastName',
    type: 'text'
},{
    name: 'Biography',
    slug: 'author.biography',
    type: 'text'
},{
    name: 'Started',
    slug: 'subscription.started',
    type: 'date'
},{
    name: 'Ending',
    slug: 'subscription.ending',
    type: 'date'
},{
    name: 'role',
    type: 'select',
    options: list_roles,
    base: true
},{
    name: 'Stripe Identifier',
    slug: 'stripeCustomer',
    type: 'text',
    fullWidth: true
},{
    name: 'status',
    type: 'select',
    options: ['active', 'disabled']
},{
    name: 'email',
    type: 'email',
    disabled: true
}, 
...baseSchemaFields
]

const overwrite = f => {
    if(f?.label === 'Biography') return [
        {label: 'Profile picture', name: 'author.profilePicture', type: 'image', fullWidth: true, base: true}, 
        {label: 'Short Bio', name: 'author.bio', type: 'text', fullWidth: true, base: true}, 
        {...f, type: 'textarea', fullWidth: true, base: true,},
    ]
    if(f?.name === 'role') return {...f, fullWidth: true, base: true,}
    return {...f, base: true};
}


const shouldGet = f => !'email|status|Ending|Started'.split('|').includes(f?.name)

const formatUserForDataGridColumns = user => ({
    ...user, 
    // slug: `/user/${user?._id}`,
    date: user.registrationDate,
    status: user?.isAdmin ? 'admin' : user?.isActive ? 'active' : ''
})

export const getSiteUserSchema = (site, format = true) => {
    if(!site?.schemas) return {}

    const schema = site?.schemas?.users ? site?.schemas?.users : site?.schemas?.user;
    return !schema  ? schema : format ? (
        schema 
            .filter((o, i) => i != 0)                                     // todo --> move subscription data to: 'data.'
            .map(o => ({...o, slug: `subscription.data.${o?.name}`, name: `subscription.data.${o?.name}`, base: true, fullWidth: o?.name === 'credits'}))
    ) : schema
}

export const userSchema = {
    formatting: {
        columns: formatUserForDataGridColumns
    },
    site: {
        schema: getSiteUserSchema
    },
    columns: [
        ...userColumns,
        // ...subscriptionColumns
    ],
    groups: [
        'base',
        'mail'
    ],
    base: [
        {
            name: 'email',
            type: 'email',
            disabled: true,
            base: true,
            fullWidth: true
        },{
            name: 'password',
            type: 'password',
            base: true,
            fullWidth: true
        },
        ...convertColumnsToSchema(userColumns).filter(f => shouldGet(f)).flatMap(f => overwrite(f)),
        {
            name: 'siteId',
            type: 'text',
            // type ===> // todo --> 'site'
            base: true,
            disabled: true,
            fullWidth: true
        }
    ],
    props: {
        multiple: 'users',
        singular: 'user',
        types: list_roles,
        info: {
            title: 'Users',
            label: "Manage your platform users",
            help: "Mosanic users cms are the place where you can view an manage your user",
            slug: [{href: '/site', label: 'Site'}],
        },
        empty: {
            title: 'No users found.',
            label: "Let's start with creating your site's first user."
        },
        controls: {
            create: 'create',
            update: 'update',
            delete: 'delete',
            view: 'view',
        },
        routing: {
            basePath: 'user',
        },
        editor: {
            schema: false,
            entry: 'editor_provider'
        },
        entries: false
    },
    schema: []
}