import { Condition } from "@JSX";

import { oneLineString } from "@util/Text"
import InputStyles from "./Inputs.styles";

const Input = InputStyles;

const InputGroupDivider = () => <Input.Divider className={`inputGroup__divider`}></Input.Divider>

const InlineGroup = ({children, ...other}) => <Input.InlineGroup {...other}>{children}</Input.InlineGroup>
const InputGroupHeader = ({children, extraClass= '', active=false, divider = false}) => (
    <Input.Header 
        className={`inputGroup__header ${extraClass}`} 
        divider={divider}
        active={active}
    >
        {children}
    </Input.Header>
)
 
const InputGroupItem = ({children, extraClass= '', fullWidth = false, active = false, customStyle = null, endAdornment = null, ...other}) => (
    <Input.Item 
        className={oneLineString(`
            inputGroup__item 
            ${extraClass}
            ${fullWidth ? '-full' : ''}
        `)} 
        active={active}
        customStyle={customStyle}
        fullWidth={fullWidth}
        {...other}
    >
        {children}
        {endAdornment && (
            <Input.End>
                {endAdornment}
            </Input.End>
        )}
    </Input.Item>
)

/** 
 * Wrapper for input groups
 */
const InputGroup = ({children, extraClass= '', fullWidth = false, inset = false, style = {}, cols = 2, label = null, ...other}) => (
    <Input.Group style={style} {...other} cols={cols} fullWidth={fullWidth} className={oneLineString(`
        inputGroup 
        ${extraClass} 
        ${fullWidth ? '-full' : ''} 
        ${inset ? '-inset' : ''}
    `)}>
        {label && <Input.Label>{label}</Input.Label>}
        {children}
    </Input.Group>
);

const RenderInputGroup = ({items}) => (
    <InputGroup>
        {items.map(item => (
            <InputGroupItem {...item?.wrapper} key={item?.key}>
                {item.render}
            </InputGroupItem>
        ))}
    </InputGroup>
)
const WrapInput = ({children, wrap = false, fullWidth = false}) => (
    <Condition when={wrap} wrapper={children => <InputGroupItem fullWidth={fullWidth}>{children}</InputGroupItem>}>
        {children}
    </Condition>
)

const IconLabel = ({icon, txt}) => <>{icon} &nbsp; {txt}</>


export {
    RenderInputGroup,
    InputGroupDivider,
    InputGroupHeader,
    InputGroupItem,
    InputGroup,
    IconLabel,
    WrapInput,
    InlineGroup,
}

