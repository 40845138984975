import { icon } from "@mosanic/Icons";


 
const Icon = ({fields, editor, ref, active}) => {
  const {source, handler} = editor || {source: null, handler: null}
  const icn = icon(source?.icon?.set, source?.icon?.icon);
  return (source?.invert) ? 
    <span style={{filter: 'invert(1)'}}>{icn}</span> :
    icn
};

export default Icon;

           
             