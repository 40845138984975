import { isString } from "@util/Text";
import dynamic from "next/dynamic";

const InstagramFeed  = dynamic(() => import('react-ig-feed'),{
    ssr: false
})


const defaultValue = {show: 6, tokenKey: "IGQVJVa2pjM3pCbU9seENnaUQ0XzJRWXp2c0FYZAXA3b1RlbWlyYmpqMmJia0VTSzJvMEdjWkJkeXNnSWNON2ZAhT2tDeUNhS2xrenNfVHJwRFhKdF9COWdmd1dGT0xpRkZAyejJ3R3ZAlU0x4NEI3eDM3MgZDZD"};

const SocialGrid = ({editor, active}) => {
    const {source} = editor || {source: defaultValue}
    const token = source?.tokenKey || defaultValue?.tokenKey
    return (token?.length >= 9 && !active && isString(token)) ?(
        null
    ) : ''
} 

{/* <InstagramFeed 
            token={token}
            counter={source?.show ? source?.show?.toString() : '6'}
        />   */}
export default SocialGrid;

// IGQVJXaWxyVFUxUnNBWFlMemNnQkFDRDVQMjdfc1hVdkVldFp4bjlPXzZAFR0xiZATNzWGo0WkZA6Si15S2JwLXNoMzFCMGhzV1B3TVRfY0plZAGVBYWZAiYTE1cFozdndBMW85bmpOTXNsM2tDT1M5ZAWk1QQZDZD