import { INITIAL_ACCORDION_STATE } from '@mosanic/constants/inspector';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { TOGGLE_ACCORDION, UPDATE_CAP, UPDATE_PREF } from "./action";



const initialState = {
    caps: {
        styling: {
            colors: {
                preferred: true, //todo
                secondary: true, //shade
                util: true, //shade
                gradients: true,
                custom: true,
                palette: true, //can generate
                opacity: true, //todo
                display: true, //type
                editing: true, //color type
                count: true,
                saturation: true,
            },
            background: {
                color: false,
                image: false,
                pattern: false,
                gradient: false,
            }
        },
        builder: {
            canvas: {
                markers: true,
                advancedBps: true,
                resizeCanvas: true,
                zoomPinch: true,
                multipleScreens: false,
                contextDev: false,
                breakpointMode: true,
                outlines: false,
            },
            dnd: {
                active: true,
                newCols: true,
            }
        },
        util: {
            inspector: {
                expandable: true,
            },
            smart: {
                autoSwitchState: true,
                autoCloseAccordion: true,    
            }, 
            helpers: {
                roundUp: true,
            },
            dev: {
                dataPreview: true,
                devMode: true,
                inspector: false,
                devLabels: true,
                fps: false,
            }
        }
    },
    preferences: {
        styling: {
            editor: 'ChromePicker', //SketchPicker
            secondary: false,
            util: false,
            colorDisplay: 'pallette'
        },
        builder: {
            previewMode: false,
            dragDrop: true,
            moveCanvas: true,
            cleanView: false,
            resizeCanvas: false,
            newColumns: true,
            zoomPinch: true,
            multipleScreens: true,
            breakpointMode: true,
            horizontalMode: false,
            screenPreviewMode: false,
            dataPreview: false,
            outlines: false,
            ctxLocale: true,
        },
        helpers: {
            intro: true,
        },
        util: {
            // autoSwitchState: false,
            // roundUp: false,
            devMode: false,
            autoSave: false,
            expandedInspector: true,
        }
    },
    accordion: {...INITIAL_ACCORDION_STATE}
};


const optionReducer = (options = initialState, action) => produce(options, draft => {
    let pl;
    const draftPref = draft?.preferences; 
    switch (action.type) {
        case HYDRATE:
            return {...options, ...action.payload};

        case UPDATE_CAP:
            pl = action.payload;
            draft.caps[pl.group][pl.sub][pl.cap] = !draft.caps[pl.group][pl.sub][pl.cap];
            return draft;

        case UPDATE_PREF:
            
            pl = action.payload;
            draft.preferences[pl.group][pl.pref] = !draft.preferences[pl.group][pl.pref];
            // draft.preferences[pl.group][pl.pref] = !action.meta?.state ? !draft.preferences[pl.group][pl.pref] : action.meta.state;
            return draft;

        case TOGGLE_ACCORDION:
            if(draftPref.helpers?.autoCloseAccordion){
                draft.accordion = {
                    ...INITIAL_ACCORDION_STATE,
                    [action.payload.field]: !action.payload.state
                }
            } else {
                draft.accordion[action.payload.field] = !action.payload.state;
            }
            
            return draft;

        default:
            return options;
    }; //switch
});
export default optionReducer;

const getOptions = state => state?.present?.options;

export const getCap = state => getOptions(state)?.caps;
export const getPref = state => getOptions(state)?.preferences;
export const getPreferences = state => getPref(state);
export const getAccordion = state => getOptions(state)?.accordion;
export const getTooltips = state => getPref(state)?.helpers.tooltips;
export const sideBarHelpSelector = state => getPref(state)?.helpers.sidebarHelp;


const helperPref = state => getPref(state)?.helpers
export const getAutoOpenInspect= state => helperPref(state)?.autoOpenInspect;
export const getAutoOpenLayers = state => helperPref(state)?.autoOpenLayers;
export const getAutoCloseAccordion = state => helperPref(state)?.autoCloseAccordion;



export const getShouldStartIntro = state => getPref(state)?.helpers.intro;

export const getAutoSavePref = state => getPref(state)?.util.autoSave;
export const getDevModePrefSelector = state => getPref(state)?.util.devMode;


export const colorCaps = state => getCap(state).styling.colors
export const canEditColorsSelector = state => colorCaps(state)?.editing
export const canPalletteSelector = state => colorCaps(state).palette;
export const canToggleColorDisplaySelector = state => colorCaps(state)?.display;
export const toggleColorDisplayPref = state => getPref(state)?.styling.colorDisplay;


export const canResizeCanvasSelector = state => getPref(state).builder.resizeCanvas;

export const canDragDropSelector = state => getPref(state).builder.dragDrop;
                    // TODO  ++ ---> is not editing !! (text field, button, live editor)

export const canDevInspectCap = state => getCap(state).builder.canvas.contextDev;

export const isOutlineCanvasSelector = state => getPref(state).builder.outlines;
export const canVariantLocaleSelector = state => getPref(state).builder.ctxLocale
export const isPreviewCanvasSelector = state => getPref(state).builder.previewMode;
export const canZoomPinchSelector = state => getPref(state).builder.zoomPinch;
export const canMoveCanvasSelector =  state => getPref(state).builder.moveCanvas;
export const showMultiScreenSelector = state => getPref(state).builder.multipleScreens;
export const showDynamicDataPreviewSelector = state => getPref(state).builder.dataPreview;
export const breakpointModeSelector = state => getPref(state).builder.breakpointMode;
export const horizontalModeSelector = state => getPref(state)?.builder.horizontalMode;
export const screenPreviewModeSelector = state => getPref(state)?.builder.screenPreviewMode;

export const canRoundUpEditors = state => getPref(state).util.roundUp;
// ==> Get components / rows / columns --> based on current state 