


// source string: repeater
// entry string: 6390cf218598d23fb16ec7b6
// collection string: 63359058737d68d97014da2d
// fieldPath Array:
// [
// type string:
// text
// name string:
// short_room_name
// help string:
// default string:
// localized boolean:
// ]

import { COLLECTION } from "@api/queries/collections";
import { useLazyQuery } from "@hooks/useApi";

import { getEntry, getFieldValue, getSchema, isPageSource, isProviderSource, isRepeaterSource, isSource } from "@mosanic/fields/Source/helpers"
import { useDataProvider, usePageContext, useRelationProvider, useRepeatProvider } from "@mosanic/core/Provider";
import { isClone } from "@mosanic/utils/value";

import { useEffect } from "react";

import { useCollection as useHandler } from "@mosanic/core/Collections";
import { isString } from "@util/Text";
import { useGetCollection, useGetEntries } from "@mosanic/cms/Query";
import { convertEntry } from "@mosanic/cms/Query/useDataQuery";

const useCollection = ({collectionId, getCollection}) => {
    const _id = Boolean(collectionId && getCollection) ? collectionId : null;

    // let [handler, [collection, loading, error]]  = useHandler(({_id}, true));
    let [collection] = useGetCollection({
        _id,
        silent: true
    })

    //Get linked entries
    const [entries, loading] = useGetEntries({
        filter: {collectionId: _id},
        silent: true
    })

    collection = {
        ...collection,
        entries: (Array.isArray(entries) && entries?.length >= 1) ? entries.map(e => convertEntry(e)) : collection?.entries
    }
    // const entry = useRetrieveEntry((
    //     getEntries && (singleEntry || entryId)
    // ) && (
    //     entryId || collection?.entries?.[0]
    // ))
    
    return [collection, loading]
}

export const useDataReceiver = ({source, entry, clone, map = false}) => {
    let data = null, schema = null, baseRoute = null, receiveData = false;

    const [collection, loading] = useCollection({
        getCollection: isSource(source), 
        collectionId: entry?.collection, 
    })

    const relation = useRelationProvider()
    const provider = useDataProvider();
    const repeater = useRepeatProvider()
    const page = usePageContext()

    

    if(isRepeaterSource(source) || isClone(clone)) {
        // if(!repeater?.entries) console.log({message: 'No repeater wrapper entries found.', entry})
        data = repeater?.entries?.[clone]
        schema = repeater?.schema;
        baseRoute = repeater?.baseRoute;
        // console.log({repeater, data, entry, clone})
        // console.log('Repeater source')
    } else if(isProviderSource(source)) {
        //No data provider context
        if(!provider) console.log({message: 'Wrap with data provider.', entry});
// 
        //Get selected provider;
        const providedData = provider?.[entry?.provider];

        //Parse provider;
        data = providedData?.data;
        const providedEntry = providedData?.entry;

        //Validate provided entry
        if(!data || !providedEntry?.fieldPath) {
            data = providedData;
        }

        if(Array.isArray(data)) {
            data = isClone(clone) ? data[clone] : data[0] 
        }

    } else if(source === 'relation' && relation?.entry) {
        
        data = relation?.entry;
        schema = relation?.collection?.schema;
        baseRoute = relation?.baseRoute;

    } else if(isPageSource(source)) {
        // if(!page) console.log({message: 'No page provider found.', entry})
        data = page?.entry;
        schema = page?.schema;
        baseRoute = page?.baseRoute;
        
    } 
    
    if(isSource(source)) {
        data = collection?.entries;
        schema = collection?.schema;
        baseRoute = collection?.baseRoute;
        // console.log(collection)

        if(entry?.entry) data = getEntry(collection, entry.entry)
        if(entry?.fieldPath) schema = getSchema(entry.fieldPath, schema)?.schema
        // if(!entry?.collection) console.log({message: 'No provider collection selected.', entry})
    }

    // // todo !check fix
    // if(schema?.schema) schema = schema?.schema;
    

    schema = (entry?.fieldPath) ? 
        getSchema(entry.fieldPath, schema) :
        schema;

    data = entry?.fieldPath ? 
        getFieldValue(
            entry.fieldPath, 
             data,  //(!isRepeaterSource(source) && relation) ? relation :
            schema
        ) : data;

    return [data, schema, baseRoute, collection]
}