
import Editor from "@monaco-editor/react";
import { useRef, useState } from "react";

import styled from 'styled-components';
import { default as s } from "../Input/Input.styles";

import 'prismjs/themes/prism-coy.min.css';
import { Condition } from "@JSX";
import { TwoColumns } from "@mosanic/items/Page/Columns";
import { PreviewCode } from "./PreviewCode";
import { InlineGroup } from "@mosanic/items/Inputs";
import { isString } from "@util/Text";

const CodeEditor = styled(Editor)` 
    ${s.BaseField}
    border: none;
    * {
        border: none;
        outline: none;
    }
`;


const editorOptions = { 
    lineNumbers: 'off',
    lineNumbersMinChars: 0,
    lineDecorationsWidth: 0,
    folding: false,
    glyphMargin: false,

    renderLineHighlight: "none",
    scrollBeyondLastLine: false,
    smoothScrolling: true,
    wordWrap: "on", //wordWrapColumn
    minimap: {
        showSlider: false,
        enabled: false
    },
    overviewRulerLanes: 0,
    scrollbar: {
        vertical:"hidden",
        horizontal: "hidden",
        // handleMouseWheel:false,
    },
}

export const FullCodeEditor = ({
     //Essential
    name = '',
    type = 'text',
    value = null,
    onChange = null,

    //Overwrite
    label = null,
    language = 'javascript',

    //Styling
    fullWidth = false,
    small = null,
    size = null,
    wrap = false, 
    noBorder = false,
    

    //Validation
    valid = null,
    empty = false,
    disabled = false,
    required = false,

    loading=false,

    ctx = null,
    showPreview = false,
    container = null,
    height='200px',
    maxHeight="200px",

    ...other
}) => {
    const [lang, setLanguage] = useState(language || 'javascript');
    const [state, setState] = useState(value)
    const editorRef = useRef(null);
    const [focussed, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);

    function handleEditorDidMount(editor, monaco) {
        monaco.languages.typescript.javascriptDefaults.addExtraLib(`
            declare const $mos : () => mosanic state", "mosanic.d.ts
            declare const $ctx : () => context", "mosanic.d.ts
        `)

        // monaco.editor.defineTheme('mosanic', {
        //     base: 'vs',
        //     inherit: true,
        //     rules: [],
        //     colors: {
        //         'editor.background': '#333333',
        //     },
        // });
        editorRef.current = editor; 
    }

    return (
        <s.Container 
            noBorder={noBorder}
            className="input_field"
            focussed={focussed}
            disabled={disabled}
            size={size}
            style={{maxHeight: height >= maxHeight ? height : maxHeight, height}}
        >
            
            <s.Label shrink={Boolean(value || focussed)}>
                {
                    label ? label :
                    name ? name : ""
                }
                {required && <s.Required />}
            </s.Label>
            <Condition when={showPreview} wrapper={children => (
                <InlineGroup 
                    m={0} p={0} style={{overflow: 'hidden', maxHeight: '100%', width: '100%'}}
                >   
                    <div style={{overflow: 'auto', width: '50%', height: '100%'}}>
                        {children}
                    </div>
                    <PreviewCode ctx={ctx} value={onChange ? value : state}/>
                </InlineGroup>
            )}>
                <CodeEditor
                    {...other}
                    loading={loading}
                    height={height}
                    width={'100%'}
                    // theme="vs-dark"
                    defaultLanguage={lang}
                    onChange={val => onChange ? onChange(val) : setState(val)}
                    value={onChange ? 
                        isString(value) ? 
                            value :
                            JSON.stringify(value)    
                    : state}
                    onMount={handleEditorDidMount}
                    options={editorOptions}
                    onFocus={(e) => {
                        if(other?.onFocus) other.onFocus(e)
                        onFocus();
                    }} 
                    onBlur={(e) => {
                        if(other?.onBlur) other.onBlur(e)
                        onBlur();
                    }}
                />
            </Condition>
        </s.Container>
    )
}