import { getImageUrl } from '@api/host';
import { Button } from '@mosanic/fields/Button/Buttons';
import { AdvancedLabel, ControlDialog, Spinner } from '@mosanic/items';
import { useState } from 'react';
import { default as s } from './Media.styles';

import MediaUploader from './MediaUploader';

import { isImage } from '@mosanic/utils/Image';
import ImgsViewer from "react-images-viewer";
import { MediaItem, NoMediaItems } from './MediaItem';
import { MediaPreview } from './MediaPreview';
import { scrollTop } from '@mosanic/hooks/useScrollTop';
import { Pagination } from '@mosanic/cms';

export const ListOfMedia = ( { media, header = true, initiate = false, callback = null, dialog = false, upload= false, type = 'image', size = null, pagination = null}) => {
	const [previewImage, initiatePreview] = useState();
	const [preview, setFullPreview] = useState(false);

	const setPreview = (img) => {
		initiatePreview(img);
		if(!dialog) scrollTop()
	}

	const initiateImage = () => {
		if(initiate) {
			initiate(previewImage?.urlMin ? {
				urlThumb: previewImage?.urlThumb,
				urlMin: previewImage.urlMin,
				url: previewImage?.url,
				seo: {
					title: previewImage?.title,
					alt: previewImage?.alt
				}
			} : previewImage?.url)

			if(dialog) setPreview(null)
		}
		else {
			setFullPreview(true)
		}
	}

	const callbackDelete = recordId => {
		callback(recordId);
		setPreview(null);
	}

	const shouldShow = img => (type === 'video' && !isImage(img?.urlThumb ? img?.urlThumb : img?.url)) || type != 'video'

	const [modalStateManager, setModalStateManager] = useState(false);
	return (
		<s.Media className="MediaGallery" dialog={dialog}>
			{header && (
				<div>
					<h3>Media</h3>
					<Button onClick={() => setModalStateManager(true)}>
						Upload media  
					</Button>  
				</div>	
			)}
			<s.MediaItems className="MediaGallery__items">
				{media?.length >= 1 ? media.map((image, index) => shouldShow(image) && (
					<MediaItem
						key={image?._id}
						setPreview={setPreview}
						image={image}
						isActive={previewImage?.url === image.url}
						size={size}
					/>
				)) : (
					<NoMediaItems size={size}/>
				)}	
				{pagination && <Pagination type="table" {...pagination} />}
			</s.MediaItems>

			<MediaPreview
				previewImage={previewImage}
				close={() => setPreview(null)}
				initiate={initiate}
				initiateImage={initiateImage}
				callbackDelete={callbackDelete}
				hasImages={media?.length >= 1}
				dialog={dialog}
			/>
				
			<s.Clear />		
			<div className="clearfix clear-both"></div>		
			
			{upload && (
				<Button onClick={() => setModalStateManager(true)} label="Upload media" />
			)}	
				
			<ControlDialog state={modalStateManager} close={() => setModalStateManager(false)} size="sm" fullWidth={true}> 
				<AdvancedLabel helpText="From here you can view, load and open your pages.">
					Upload item 
				</AdvancedLabel>
				<MediaUploader showEditor maxNumber={3}/>
				
			</ControlDialog>
			{previewImage?.url >= 1 && (
			<ImgsViewer
				imgs={[
					{ src: getImageUrl(previewImage?.url) },
					// ...media.map(img => ({ 
					// 	src: getImageUrl(img?.url) 
					// }))
				]}
				spinner={() => <Spinner/>}
				// currImg={previewImage}
				isOpen={preview}
				// onClickPrev={this.gotoPrevious}
				// onClickNext={this.gotoNext}
				onClose={() => setFullPreview(false)}
			/>
			)}

		</s.Media>	 
	);
};


// ListOfArticles.propTypes = {
// 	articles: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			title: PropTypes.string.isRequired,
// 			_id: PropTypes.string.isRequired,
// 			published: PropTypes.bool.isRequired,
// 			description: PropTypes.string.isRequired,
// 		})
// 	),
// 	startPolling: PropTypes.func.isRequired,
// 	stopPolling: PropTypes.func.isRequired
// };
