import { isComp, isComponent } from '@mosanic/utils/validate';
import { capitalizeFirst, isString, removeVowels, toLowCase } from "@util/Text";


// * Identifiers

const toVarCase = (string) => isString(string) ? toLowCase(string.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9_-]/g,'')) : null;

/**
 * Generate unique styling classname
 * @param {*} objType 
 * @param {*} type 
 * @param {*} _id 
 * @param {*} parseId 
 * @returns 
 */
const toClassName = (objType, type, _id, parseId = 12) => {
    if(!objType || !type || !_id ) return '';
    if(isComponent(objType)) objType = 'cmp';

    const base = objType != type ? 
        `${capRemoveVowels(objType)}${capRemoveVowels(type)}` :
        `${capRemoveVowels(objType)}`;

    const parsed_id = `${_id.substring(1, (parseId / 2))}${_id.substring(_id.length - (parseId / 2) )}`;

    return isString(_id) ? 
        `${base}${parsed_id}` : 
        `${base}${_id}`;
}
/**
 * ! local
 * Shorthand remove vowels and capitalize
 * @param {*} string 
 * @returns 
 */
const capRemoveVowels = (string) => capitalizeFirst(removeVowels(string));



// * Custom classes 


/**
 * Get container item classname
 * @param {*} type 
 * @param {*} className 
 * @returns 
 */
function getContainerClass(type, className) {
    return `draggable ${toLowCase(type)} ${className != undefined ? className : ''}`;
}

/**
 * Get Current inspector state
 * @returns classes (string)
 */
function getInspectorClass(data)  {
    const {isHovered, isInspected, isCloned} = data;

    let classes = isHovered ? '-hover ' : '';
        classes += isInspected ? '-active ' : '';
        classes += isCloned ? '-clone ' : '';
    
    const getCurrentState = () => {
        return classes; 
    }

    return getCurrentState();
}

/**
 * Used for dropBox empty sizing
 * @param {childCount} length 
 * @returns 
 */
const getChildrenCountClass = (length) => length ? 'childs-'+length : 'empty';


// * Types

const getGroupType = (data) => data?.type ? toLowCase(data.type, 's') : '';
const getElementType = (element, map) => isComp(map?.type)  ? element?.type : map?.type;
const getLowCaseElementType = (element, map) => toLowCase(getElementType(element, map));
const getElementTitle = (element, type) => element?.title ? element.title : capitalizeFirst(type);

 export {
    //Identifiers
    toVarCase,
    toClassName,

    //Custom classes
    getContainerClass,
    getInspectorClass,
    getChildrenCountClass,

    //Types
    getGroupType,
    getElementType,
    getLowCaseElementType,
    getElementTitle
}