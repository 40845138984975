import { setOverwriteAction } from '@redux/inspect/action';
import { inspectOverwriteSelector } from '@redux/inspect/reducer';
import { canMap } from '@util/Arr';
import { hasAttr } from '@util/Obj';
import { combineString, removeFirstChar, removePart } from '@util/Text';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const order = [
    'variant',
    'breakpoint',
    'state',
]
export const getOverwriteSelector = (value, overwrites,  attr = 'value', skip = []) => {
    const selector = appendState(value, activeOverwrites(overwrites, attr, skip))
    if(!value) return removeFirstChar(selector)
    return selector;
}


//Combine states
const appendState = (value, state) => state ? 
    combineString([value, ...state], '') : 
    value;

//Get overwrite labels
const activeOverwrites = (overwrite, attr = 'value', skip = []) => {
    let active = [];
    order.map(selector => {
        if(skip.includes(selector)) return;
        active = [...active, ...getValues(overwrite, attr, selector)];
    })
    return active;
}
//Format active group labels for displaying combined
const getValues = (overwrite, attr = 'value', type = 'breakpoint') => 
    canMap(overwrite[type]) ? overwrite[type]?.map(variant => {
        return hasAttr(variant, attr) ? `:${removePart(variant[attr], ':')}` : ''
    }) : ''



const useOverwrite = () => {
    const dispatch = useDispatch();

    const overwrite = useSelector(inspectOverwriteSelector)
    const setOverwrite = (variant, type = 'state') => {
        dispatch(setOverwriteAction({
            value: variant?.value, 
            label: variant?.label,
            type: variant?.type
        }, type))
    }

    return {
        overwrite,
        setOverwrite,
        prefixSelector: (value) => getOverwriteSelector(value, overwrite),
        selector: getOverwriteSelector('', overwrite)
    }
}

export const useOverwriteSelector = () => {
    const overwrite = useSelector(inspectOverwriteSelector)
    return useMemo(() => getOverwriteSelector('', overwrite),[overwrite])
}

export default useOverwrite;