import { removeDataAction } from "@redux/map/data/action";
import { includesAction } from "@redux/util";
import { default as actions } from "./action";

const isNewAddAction = (action) => includesAction(action, actions.MAP_ADD);

//Use this date to validate if modal should be launched
export const getValidationData = ({action}) => isNewAddAction(action) ? ({
    declineAction: removeDataAction({component: action.payload}),
    title: `Action required before initializing.`
}) : ({
    declineAction: null,
    title: `Action required before moving.`
});



export const isValidated = (action) => action.payload?.validated || action?.options?.validated
export const hasWrapper = (action) => action?.payload?.wrapper || action?.options?.wrapper