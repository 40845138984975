import { Move, shouldConfirm, validate, ValidateMove as Validate } from '@mosanic/map';
import { DROP_ID } from '@redux/map/drop/action';
import { modalActions } from '@redux/modal/action';
import { setNotification } from '@redux/notification/action';
import { hideSpinner } from '@redux/ui/uiAction';
import { addOptions, includesAction, includesOneAction } from '@redux/util';
import { default as actions, mapActions, MAP_ID } from './action';

import { mapSelector } from './reducer';
import { getValidationData, hasWrapper, isValidated } from './validate';



const mapValidationFlow = ({getState, dispatch}) => next => action => {

    if( 
        includesOneAction(action, [actions.MAP_ADD, actions.MAP_MOVE]) && 
        !isValidated(action) && //already passed map validation
        !hasWrapper(action) //handle by data middleware
    ){ 
        const map = mapSelector(getState());
        action = addOptions(action, {validated: true});

        //Destruct payload
        const {item, dropPath} = action.payload;
        const itemType = item.itemType;

        //Check for modal
        let modal = shouldConfirm({map, dropPath, itemType}, action);
        if(!modal) next(action); //Continue with dispatch.
        
        //Add wrapper if confirmed
        if(modal?.wrapper) action = addOptions(action, {wrapper: modal.wrapper});
        
        //Construct modal
        modal = { ...getValidationData({action}), confirmAction: action, ...modal };
    
        //Dispatch modals
        if(modal?.message) dispatch(modalActions.confirmationModalAction(modal));
        if(modal?.notify) dispatch(setNotification({message: modal.notify, feature: MAP_ID, meta: {variant: 'error', persist: true}}));
        
        //Stop spinner since we wait on user input
        dispatch(hideSpinner({location: 'canvas', feature: DROP_ID})); 

    } else {
        //Skip
        next(action);
    }
}

//New item addition
const mapAddActionFlow = ({getState, dispatch}) => next => action => {
    next(action);

    if(
        includesAction(action, actions.MAP_ADD) && 
        !hasWrapper(action) //handle by data middleware
    ){ 
        let map = mapSelector(getState());
        const {item, dropPath} = action.payload;

        //Check mosanic component schema

        const validatedItem = validate({item});
        
        map = Move.handleNewItem({map, dropPath, item: validatedItem});

        dispatch(mapActions.updateMapAction({map}));
        dispatch(hideSpinner({location: 'canvas', feature: DROP_ID}));
    }
};

const mapRemoveActionFlow = ({getState, dispatch}) => next => action => {
    next(action);

    if(
        includesAction(action, actions.MAP_REMOVE)
    ){ 
        let map = mapSelector(getState());
        const item = action.payload;

        const newMap = Move.removeChildFromChildren({ children: map, itemPath: item.path });
        dispatch(mapActions.updateMapAction({map: newMap}));
       
        dispatch(hideSpinner({location: 'canvas', feature: DROP_ID}));
    }
};


//Item move
const mapMoveActionFlow = ({getState, dispatch}) => next => action => {
    next(action); 


    if(
        includesAction(action, actions.MAP_MOVE) && 
        !hasWrapper(action) //handle by data middleware
    ){ 
        let map = mapSelector(getState());
        const {item, itemPath, dropPath} = action.payload;

        if(Validate.isPureMove({itemPath, dropPath})){

            if(Validate.isMoveWithinParent({itemPath, dropPath})) {
                map = Move.handleMoveWithinParent({map, dropPath, itemPath});

            } else {
                map = Move.handleMoveToDifferentParent({children: map, dropPath, itemPath, item});
            }

        } else {
            map = Move.handleMoveCreate({children: map, dropPath, itemPath, item});
        }

        dispatch(mapActions.updateMapAction({map}));
        dispatch(hideSpinner({location: 'canvas', feature: DROP_ID}));
    }
};


const mapUpdateItemActionFlow = ({getState, dispatch}) => next => action => {
    next(action); 

    if(
        includesAction(action, actions.MAP_UPDATE)
    ){ 
        let map = mapSelector(getState());
        const {itemMap, path} = action.payload;

        let newMap = Move.removeChildFromChildren({ children: map, itemPath: path });
        newMap = Move.addChildToChildren({ children: newMap, dropPath: path, item: itemMap }); 
        
        
        dispatch(mapActions.updateMapAction({map: newMap}));
        dispatch(hideSpinner({location: 'canvas', feature: DROP_ID}));
    }
};



export const mapMiddleware = [
    mapValidationFlow,
    mapAddActionFlow,
    mapRemoveActionFlow,
    mapMoveActionFlow,

    mapUpdateItemActionFlow
];