import Editor from "./Editor";
import Text from "./Text";
import {InlineEditor} from "./Inline";
import { serialize as Serialize } from "./Serialize";
export {
  Text,
  InlineEditor,
  Serialize
}

export default Editor;