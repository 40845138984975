import { featureSelector } from '@redux/baseSelector';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { default as actions } from './action';

export const initialPanel = {
    value: null,
    small: null,
    width: null,
    active: null,
    icon: false
}
const initialState = {
    main: {
        open: true,
        width: 150,
        value: 'dashboard'
    },
    sub: {
        open: false,
        width: null,
        value: null
    },

    


    left: initialPanel,
    right: initialPanel,
    subLeft: initialPanel,
    subRight: initialPanel,
    small: true,
    large: true,
    mobileOpen: false,

};




const navReducer = (nav = initialState, action) => produce(nav, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...nav, ...action.payload};

        case actions.NAV_SET:
            draft[action.payload.panel] = {
                ...draft[action.payload.panel],
                ...action.payload, 
                panel: undefined
            };
            return draft;

        case actions.NAV_SET_PANEL:
            draft[action.payload.panel] = action.payload.value;
            return draft;

        case actions.NAV_TOGGLE_MOBILE:
            draft.mobileOpen = !draft.mobileOpen;
            return draft;

        case actions.TOGGLE_SMALL:
            draft.small = !draft.small;
            return draft;

        default:
            return nav;
    }; //switch
});
export default navReducer;

export const navSelector = state => featureSelector(state, 'nav');

export const mobileNavSelector = state => navSelector(state)?.mobileOpen;
export const sideBarSmallSelector = state => navSelector(state)?.small;


export const leftPanelSelector = state => navSelector(state)?.left;
export const leftSubPanelSelector = state => navSelector(state)?.subLeft;

export const rightPanelSelector = state => navSelector(state)?.right;



const mainPanelSelector = state => navSelector(state)?.main
const subPanelSelector = state => navSelector(state)?.sub;
export const sideBarSelector = state => ({
    main: mainPanelSelector(state),
    sub: subPanelSelector(state),
})

// export const getCap = state => state.present.options.caps;
// export const getBreakpoints = state => state.present.canvas.breakpoints;
