import { Fragment, useState } from 'react';

import { REGISTER } from '@api/mutations/auth';

import { useMutate } from '@hooks/useApi';
import { useSendOne } from '@mosanic/components/Mail';
import { Button, Slider } from '@mosanic/fields';
import Input from '@mosanic/fields/Input';
import { useNotify } from '@mosanic/hooks';
import { InputGroup, InputGroupItem, Link } from '@mosanic/items';
import { validateRegisterForm } from '@util/server/validations';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { RoundedImage, colors } from '@mosanic/styles';

import {default as radio} from '@mosanic/fields/Radio/Radio.style';
import Image from 'next/image';
import { ImageStack } from '@mosanic/styles/image.styles';
import { Progress } from '@mosanic/items/Progress/Progress';
import { useDispatch } from 'react-redux';
import { activateAuthAction } from '@redux/auth/action';

const Images = ({urls}) => urls ? (
	<ImageStack width={urls?.length * 30}>
		{urls.map((url, index) => (
			<span style={{
				transitionDelay: index % 2 ? '0.9s' : 0,
				transition: 'all ease-out 0.3s',
				margin: index % 2 ? '' : '0 -7.5px', 
				zIndex: index % 2 ? 1 : 0,
			}}>
			<RoundedImage src={url} 
				width={index % 2 ? '40px' : '30px'} 
				height={index % 2 ? '40px' : '30px'} 
				alt="img" 
			/>
			</span>
		))}
	</ImageStack>
) : null;

export const RegisterForm = ({ activateAuth, accountActions = false}) => { 
	const { handleSubmit, control, watch, formState: { errors, isValid } } = useForm({mode: 'all'});
	const inputProps = {control, fullWidth: true, errors}

	const dispatch = useDispatch();

	const [sendMail, sendData, sendLoading] = useSendOne()
	const router = useRouter()
	const [disabled, setDisabled] = useState(false);
	const [error, setError] = useState(null);
	const [agree, setAgree] = useState(false);

	const [registerUser, [token, loading, registerError]] = useMutate('registerUser', REGISTER)

	// pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!*^?+-_@#$%&]{8,}$"

	const notify = useNotify()

	const onSubmit = (data) => {
		setDisabled(true);
		setError(null);

		const variables = {
			...data,
			agree: undefined,
			repeatPassword : undefined,
		};

		registerUser(variables).then(res => {
			let {data, error} = res;
			if(data?.registerUser) data = data.registerUser
			if(!error){
				const { token } = data;
				dispatch(activateAuthAction({token, redirect: false, remember: true}))
				sendMail({
					subject: `Welcome on Mosanic ${variables.firstName}!`, 
					to: variables.email, 
					preHeader: "You're journey starts today! Start exploring, start building and grow your online presence.", 
					subHeader: "Time to explore 🚀", 
					mainHeader: `Welcome ${variables.firstName} ${variables.lastName}.`, 
					mainText: `We will guarantee you that the next steps will be as simple as the signup.\n
You're curious on how you can start as soon as possible, right?\n
We've setup a quick start guide that will explain the main topics and features. \n
You will find it in the header of your dashboard or at https://docs.mosanic.io/quick-start \n
It contains a few short visual pages so you know the basics and can start building.\n
But we really recommend you to dive deeper and explore the docs after.\n\n
Ain't a big fan of reading?\n
Mosanic offers a great experience out of the box, so if you prefer learning by try error.\n
Go ahead! Just now that there is enough reference material to learn more..  \n \n Kind Regards, \n The Mosanic Team`
				});
				setTimeout(() => {
					router.push({
						pathname: '/',
						query: { type: 'setup' }
					});
				}, 500);
			} else {
				notify('Registration failed, please reload and try again.')
				setError(e.message);
				setDisabled(false);
			}
		});
	};

	const isValidated = validateRegisterForm(watch().email, watch().password, watch().repeatPassword);

	const status = watch();
	const step = () => {
		if(status?.group) return 1;
		if(
			status?.firstName &&
			status?.lastName &&
			status?.email && 
			status?.password &&
			status?.repeatPassword
		) return 2;
	}
	const percentage =  (Object?.values(status)?.filter(o => o)?.length / Object.keys(status)?.length) * 100
	return (
		<Fragment>

			<form  disabled={disabled || !isValidated || !isValid} onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>

					{/* {!watch()?.group && (
						<Input 
							style={{maxWidth: '100%'}}
							size="full"
							direction="vertical"
							name="group" label="Are you a solo creator or part of a team?" type="radio"
							renderOption={(o, checked) => (
								<radio.RadioButton checked={checked}>
									<Images urls={o?.urls} />
									<div>
										<h3>{o?.label}</h3>
										{o?.help}
									</div>
								</radio.RadioButton>
							)}
							options={[{
								label: "I'm a solo creator", 
								help: "I want to learn and explore mosanic while creating my own site.", 
								value: 'solo',
								urls: [
									'https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/min_juan-encalada.jpg',
									'https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/alexander-hipp-iEEBWgY_6lA-unsplash.jpg',
									'https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/min_alex-starnes.jpg',
								]
							}, {
								label: "I'm part of a team",
								help: "We want to develop our business site/platform on mosanic.",
								value: 'team',
								urls: [
									"https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/escooters.jpg",
									"https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/mosanic.jpg",
									"https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/swop.jpg",
								]
							}]} {...inputProps} required
						/>
					)}

					{percentage} */}


					<Input
						name="firstName" label="First name" type="text" {...inputProps} required
						rules={{required: "First name is required."}}
					/>

					<Input 
						name="lastName" label="Last name" type="text" {...inputProps} required
						rules={{required: "Your last name is required."}}
					/>

					<Input 
						name="email" type="email" label="E-mail" {...inputProps} required
						rules={{required: "You email is required since you login with it."}}
					/>

					<Input 
						name="password" type="password" {...inputProps} required
						rules={{required: "You email is required since you login with it."}}
					/>

					<Input 
						name="repeatPassword" label="Repeat Password" type="password" {...inputProps} required
						rules={{required: "Repeat and match entered password. Copy paste is not allowed"}}
					/>

				</InputGroup>
				<Progress value={percentage} />
				{step()}
			
				<InputGroupItem mb={4} fullWidth>
					<Input 
						name="agree" type="checkbox" label="I agree to the Terms & conditions" {...inputProps}
						rules={{required: (<>
							Required, more information can be found under <Link href="/terms">/terms</Link>
						</>)}}
					/>
					
				</InputGroupItem>
				
				<div className="mt-2 ml-1">

					<Button 
						width={[1, '90%']}
						size="large"
						theme="primary"
						type="submit"
						disabled={disabled || !isValidated || !isValid}
					>
						{(isValidated || isValid) ? 'Create account' : 'Disabled, complete register'}
					</Button>

				</div>
				{loading}
				{registerError}
			</form>

				{error}


			<div className="form-bottom text-center">
				<br/>
				<p className="sign-up-text">
					Already have an account?{" "}
					<Link href="/login" color="primary" style={{fontWeight: 'bold'}}>

						Login

					</Link>
				</p>
				{accountActions && (
				<p className="sign-up-text" style={{opacity: 0.7, fontSize: '70%'}}>
					Want to reset your password?{" "}
					<Link href="/reset" color="primary">
						Reset
					</Link>
				</p>
				)}
			</div>
			
		</Fragment>
	);
};
