

 
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useCallback, useEffect, useRef, useState } from "react";
import { dark_map, light_map, minimal_map} from '@mosanic/builder/Map/MapThemes';

import { parseImageSrc } from "@mosanic/utils/Image";
import { isNumber } from '@util/Num';
import { hasAttr } from '@util/Obj';

const themes = {
    dark: dark_map,
    light: light_map,
    minimal: minimal_map,
    default: null
}
const getTheme = theme => themes[theme]
const validate = val => isNumber(parseFloat(val))

export function findCenter(markers) {
  const lats = markers.map(m => m.lat);
  const lngs = markers.map(m => m.lng);
  return {
    lat: (Math.min(...lats) + Math.max(...lats)) / 2,
    lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
  };
}

const ClickMap = ({value, onChange, control, search}) => {
    let apiKey = value?.apiKey
    if(!apiKey) apiKey = 'AIzaSyAma8GPPHZNC5Gg1bXp7V5GMbfYH1gIGbk';

    // return JSON.stringify(editor)

    const updateProp = useCallback(({prop, val}) => 
        onChange(prop ? {...value, [prop]: val} : {...value, ...val}), 
    [
        value, 
        onChange
    ]);
    

    let {lat, lng, zoom, theme, markers, icon} = value || {
        lat:  52.377956, 
        lng: 4.897070, 
        zoom: 8, 
        markers: [], 
        theme: 'default'
    };

    // if(!apiKey) apiKey = 'AIzaSyCdwFEEctdkfVlQMzV5attE6IqM8TrmtuA';
    
    zoom = validate(zoom) ? parseFloat(zoom) : 10;
    lat = validate(lat) ? parseFloat(lat) : 52.377956;
    lng = validate(lng) ? parseFloat(lng) : 4.897070;
    
    const googleMap = useRef();


    const [reload, setReload] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const input = useRef()
    
    // const icon = parseImageSrc(marker, true)?.thumb
    

      
    useEffect(() => {
        if(!apiKey || !loaded) return;
        
        const loader = new Loader({
            apiKey,
            language: 'en',
            // version: 'weekly',
            libraries: ["places"]
        });
        let map, marker, placesService, autocomplete;


        function isIconMouseEvent(e) {
            return "placeId" in e;
        }

        loader.load().then(() => {
            // const input = document.getElementById("pac-input");
            
            let address ={
                city: null, 
                street_number: null, 
                route: null,
                state: null, 
                state_short: null, 
                postal_code: null, 
                country: null, 
                country_short: null
            };

            map = new google.maps.Map(googleMap.current, {
                center: {lat, lng},
                zoom,
                disableDefaultUI: true, // a way to quickly hide all controls
                mapTypeControl: true,
                scaleControl: true,
                zoomControl: true,
                styles: getTheme(theme),
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL 
                },
            });

            placesService = new google.maps.places.PlacesService(map);
            // autocomplete = new google.maps.places.Autocomplete(input, options);

            const setPlace = (place) => {
                const data = place?.address_components;
                                    
                if(Array.isArray(data)) data.map(info => {
                    info.types.map(type => {
                        if(['country'].includes(type)){
                            address = {...address, 
                                country_short: info.short_name,
                                country: info.long_name
                            }
                        } else if(['administrative_area_level_1'].includes(type)){
                            address = {...address, 
                                state_short: info.short_name,
                                state: info.long_name
                            }
                        } else if(['locality'].includes(type)){
                            address = {...address, 
                                city: info.long_name
                            }
                        }else if(hasAttr(address, type)) {
                            address = {
                                ...address,
                                [type]: info.long_name
                            }
                        }
                    })
                })
                address = {
                    ...address,
                    street_name: address.route,
                    route: undefined,
                    post_code: address.postal_code,
                    postal_code: undefined
                }

                updateProp({val: {
                    'place-id': place.place_id,
                    'address': place.formatted_address,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    ...address,
                }})

            }
            const markerIcon = {
                url: parseImageSrc(icon, true)?.thumb,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(30, 30),
                // The origin for this image is (0, 0).
                // origin: new google.maps.Point(0, 0),
                // // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(0, 32),
              };
            // let markers_search = [];
    

            if(control === 'zoom') {
                map.addListener("zoom_changed", () => {
                    updateProp({prop: 'zoom', val: map.getZoom()})
                });
            } else if (control === 'marker') {
                map.addListener("click", (event) => {

                    if (isIconMouseEvent(event)) {
                        event.stop(); // prevent place to show

                        if (event.placeId) {
                            
                            placesService.getDetails({ 
                                placeId: event.placeId 
                            }, (place, status) => {
                                
                                if (
                                    status === "OK" &&
                                    place &&
                                    place.geometry &&
                                    place.geometry.location
                                ) {
                                    setPlace(place)
                                }
                            });



                        }
                    }  else {
                        console.log(event)
                        updateProp({val: event.latLng.toJSON()})
                    }
                });
            } 

            if (search) {
                const request ={
                    query: search,
                    fields: ["name", "geometry"],
                };
                placesService.findPlaceFromQuery(request, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    // for (let i = 0; i < results.length; i++) {
                    //     createMarker(results[i]);
                    // }

                        map.setCenter(results[0].geometry.location);
                        map.setZoom(18)
                    }
                });
                
            }
            
            
            
            marker = new google.maps.Marker({
                position: {lat, lng},
                icon: parseImageSrc(icon, true)?.full,
                map,
            });

            
        });

        
    },[apiKey, icon, lat,  search, lng, loaded, theme, updateProp, zoom, control])

    useEffect(() => {
        setLoaded(true)
        return () => setLoaded(false);
    },[])

    return (
        <>

        <div id="map" style={{height: '100%', width: '100%'}} ref={googleMap} >

        </div>
        </>
    )
}
export default ClickMap;