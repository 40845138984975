
import Pagination, { PaginationLimit } from '@mosanic/cms/Pagination/Pagination';
import { ListOfMedia } from '../ListOfMedia';

import { GetMedia } from './GetMedia';
import { useGetMediaCount, useGetMediaPage, useSiteId } from '@mosanic/cms/Query';
import { usePaginateDataGrid } from '@mosanic/cms/DataGrid';

export const GetListOfMedia = ({header = true, dialog = false, upload = true, initiate=null, type='image', size = null}) => {
	const mediaCountReceiver = useGetMediaCount({})
	const {
        limiter, pagination, actions, state,
        data,
        status,

        loading, error,
        Selector,
    } = usePaginateDataGrid({
        countResponse: mediaCountReceiver,

        useEntriesReceiver: useGetMediaPage,
        entriesReceiverParams: {siteId: useSiteId()},

        filtering: false,
        
    })

	
	// return 'test';
	return (
		<>
		<PaginationLimit {...limiter} />
		<ListOfMedia 
			media={data?.entries} 
			initiate={initiate} 
			header={header} 
			dialog={dialog} 
			upload={upload} 
			type={type}
			size={size}
		/>
		{pagination && <Pagination type="table" {...pagination} />}
		</>
	);
};
