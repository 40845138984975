import { DropZone } from "@mosanic/core/DropZone/DropZone.style";
import { containsSubstr, removeLastChar, replacePart } from "@util/Text";
import getValues from "./styleUtils";
import { getAttr } from "./selectors";
import parseAttr from "./parseAttr";
import { hasAttr } from "@util/Obj";

const flexBoxAttr = {
    direction: 'flex-direction',
    align: 'align-items',
    justify: 'justify-content',
    wrap: 'flex-wrap',
    reverse: 'reverse'
}

const getFlexAttr = (attr, value, direction = 'column') => {
    if(attr === 'direction'){
        value = value === 'vertical' ? 'column' : 'row'
    }
    if(
        attr === 'align' &&
        !['stretch', 'baseline', 'center'].includes(value)
    ){
        value = `flex-${value}`;
    }
    if(attr === 'justify') {

        if(['around', 'between'].includes(value)) {
            value = `space-${value}`;
        } else if(value === 'center'){
            
        } else {
            value = `flex-${value}`;
        }
    }
    if(attr === 'reverse'){
        if(!value) return;
        attr = 'direction';
        value = `${direction}-reverse`
    }
    // console.log(attr)
    if(attr === 'wrap'){
        if(!value || value === 0 ) return;
        value = 'wrap';
    }
    
    return getAttr(flexBoxAttr[attr], value)


}

const parseDisplayAttr = (data, validate) => {
    let base = `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;

    ` 
    //Stored direction for applying reverse
    let storeDir = 'column', direction = '';

    //Loop trough attributes 
    //Display: --> Attribute = FlexDirection: Variants
    if(hasAttr(data, 'wrap')){
        if(data?.wrap === 1) base += 'flex-wrap: wrap;'
        // data = {...data, wrap: undefined}
        // console.log(data)
    }
    Object.entries(data).map(([attribute, variants]) => {

        const attr = (value) => getFlexAttr(attribute, value, storeDir)
        const handleValue = (variant, value) => {
            const received = getValues({
                variant, 
                value, 
                attr, 
                validate
            });

            if(attribute === 'direction') {
                direction =  value
                storeDir = replacePart(removeLastChar(received), 'flex-direction: ', '');
            }
            return received

        }                   
        base += parseAttr({
            data: variants, 
            attrName: null,
            validate, 
            type: null, 
            custom: handleValue
        });

        // Object.entries(variants).map(([variant, value]) => {

           
        //     if(value && variant){
        //         const received = getValues({
        //             variant, 
        //             value, 
        //             attr, 
        //             validate
        //         });
        //         base += received;

                
        //     }
        // })
    })
    if(data){
        if(direction === 'horizontal'){  //direction === 'horizontal' || 
            base += `& > ${DropZone} { 
                max-width: 2%;
                min-height: 25px;
                top: 0;
                bottom: 0;
                height: 100%;
                min-height: 100%;
                margin: 0 -1%;
                // position: absolute;
            }`
        } else {
            base += `& > ${DropZone} { 
                width: 100%;
                left: 0;
                right: 0;
                min-height: 25px;
                height: 25px;
                min-width: 100%;

                margin: -12.5px 0;
                // position: absolute;
            }`
        }
        
    } 

    return base;
}


export default parseDisplayAttr;