import { FILTER_USERS } from "@api/queries/users"
import { useGetUsers } from "./many"
import { combineString } from "@util/Text"
import { useMemo } from "react"



export const useListUsers = ({filter, siteId = undefined, ...other}) => {
    const getLabel = user => combineString([user?.author?.firstName, user?.author?.lastName], ' ')

    const [users, loading, error] = useGetUsers({
        filter, 
        siteId,
        query: FILTER_USERS,
        ...other
    })
    
    return [
        useMemo(() => (users?.length >= 1) ? (
        users.map(user => ({
            value: user?._id,
            label: getLabel(user)
        }))
    ) : ['No users found'], [users?.length])
    , loading, error]
}

