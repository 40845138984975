import { Colors, SpaceBetween, colors } from "@mosanic/styles";
import styled, { css } from "styled-components";
import { layout, space } from 'styled-system';

const OFFSET_TOP = '15px';

const ActiveStyle = (active, fixMargin = false) => active && css`
    background: ${Colors.util.gray.lightest};

    ${fixMargin && css` 
        margin-top: 0;
        padding-top: ${OFFSET_TOP};
    `}
`;


const Header = styled.div` 
    ${SpaceBetween}
    font-size: 12px;
    margin: 5px 0 10px;
    font-weight: 600;

    ${props => ActiveStyle(props.active)}
`

const Item = styled.div` 
    margin: ${OFFSET_TOP} 0 0 0;
    width: 50%;
    ${props => ActiveStyle(props.active, true)}
    ${props => props.customStyle}

    ${props => props.fullWidth && css` 
        width: 100%!important;

        textarea {
            width: 100%;
        }
    `}
    ${props => props.divider && css` 
        border-bottom: 1px solid #e7e9ed;
    `}
    ${space}
    ${layout}
`;

const Divider = styled.div` 
    margin: ${OFFSET_TOP} 0 0px;
    width: 100%;
    border-top: 1px solid #e7e9ed;
`

const childWidth = { 
    '4': '22.5%',
    '3': '32.3%',
    '2': '48%',
    '1': '100%',
}
const getChildWidth = props => css` 
    width: ${props => childWidth?.[props.cols]}!important;
`
const Group = styled.div` 
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    ${props => props.fullWidth && css` 
        width: 100%;
    `}
    ${Item} {
        width: 48%;
    }
    ${props => (props.inline === 'two' || props.cols === 2) && css` 
        ${Item} {
            width: 48% ;
        }
    `}
    ${props => props.breath && css` 
        ${Item} {
            margin: 0!important;
            padding: ${OFFSET_TOP} 5px 25px;
        }

        ${Divider} {
            margin: ${OFFSET_TOP} 0 -25px;
        }
    `}
    ${props => (props.inline === 'four' || props.cols === 4) && css` 
        .stateIndicator {
            top: calc(25% - 2px);
            left: -5px;
            transform: scale(0.9)
        }
        ${Item} {
            width: 22.5% ;
            margin: 20px 2.5% 0 0;

            &:last-child {
                margin-bottom: 0;
            }
            label {
                padding-top: 2px;
                font-size: 10px;
            }
        }
    `}
    ${props => (props.inline === 'three' || props.cols === 3) && css` 

        ${Item} {
            width: 33.33% ;
            margin: 20px 0 0 0;

            &:last-child {
                /* margin-bottom: 0; */
                margin-right: auto;
            }
        }
    `}
    ${props => (props.inline === 'one' || props.cols === 1) && css` 
        /* display: block; */
        ${Item} {
            width: 100% ;
            margin: 20px 0 0 0;

            /* &:last-child {
                margin-bottom: 0; */
            }
        }
    `}

    ${props => props?.cols && css` 
        > ${Item}:not(.-full) {
            ${getChildWidth(props)}
        }
    `}
    ${props => props.extra === 'corners' && css` 
        svg {
           transform: rotate(-45deg) scale(1.2) !important;
        }
    `}
    ${props => props.center && css`
        display: flex; 
        flex-wrap: wrap;
        align-items: center;
        ${Item}, * {
            margin-left: auto;
            margin-right: auto;
        }
    `}

    ${props => props.border && css` 
        border: 2px solid ${colors.util.gray.light};
        margin-top: 25px;
        border-radius: 5px;
        padding: 10px 15px;
        /* ${Item}:first-child {
            margin-top: 0;
        } */
    `}

    .inputGroup {
        margin: 10px 0;
        width: 100%;
        ${Item}:last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }
    ${layout}
    ${space}
`
const End = styled.span` 
    position: absolute;
    top: 5px;
    right: -15%;
`
const Label = styled.label` 
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: -10px;

    /* &::after {
        background: #F9FBFC;
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right:0;
        top: 0;
        bottom: 0;
        content: ' ';
    } */

`
const InlineGroup = styled.div` 
    display: flex;
    flex-wrap: nowrap;
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};


    ${layout}
    ${space}
`
const InputStyles = {
    InlineGroup,
    Label,
    Header,
    Item,
    Divider,
    Group,
    End
};

export default InputStyles;