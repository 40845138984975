

import { useUpdate } from "@mosanic/cms/Mutate/useDataMutate";
import { useGetUser } from "@mosanic/cms/Query/byId";
import { convertEntryBack } from "@mosanic/cms/Query/useDataQuery";
import { useEntryMails } from "@mosanic/core/Collections/EntryMails";
import { convertEntry } from "@mosanic/core/Collections/useEntries";
import { FormProvider as _FormProvider, usePageContext } from "@mosanic/core/Provider";
import { parseCode } from "@mosanic/fields/Code/PreviewCode";
import { getSchema } from "@mosanic/fields/Source/helpers";
import { LoadingDots } from "@mosanic/items";
import { getFields } from "@mosanic/utils/collection";
import { userIdSelector } from "@redux/auth/reducer";
import isBoolean from "@util/Boolean";
import { hasAttr } from "@util/Obj";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider as Provider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import client from "@api/client";
import { FILTER_USERS } from "@api/queries/users";
import { useUpdateUser } from "@mosanic/cms/Mutate/update";
import { useSendOne } from "@mosanic/components/Mail";
import { useNotify } from "@mosanic/hooks";
import { toLowCase } from "@util/Text";
import styled from "styled-components";


const Error = styled.div` 
    position: absolute;
    width: 400px;
    margin: 25px auto;
    padding: 25px;
    border: 1px solid red;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 40px rgba(0,0,0,.3);
`
const ErrorOverlay = styled.div` 
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;   
    bottom: 0;
    background: rgba(0,0,0,.5);
    backdrop-filter: blur(5px);
`

const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
const tenSecondsInMs = 10000;
const UserError = () => (
    <Error>
        <h4>⚠️ Updating stopped..</h4>
        <h6>
            Note: No actions were performed.
        </h6>
        <p>
            We could not find or validate any users to update. <br/><br/>
            Please sign out and sign in to try again. <br/><br/>
            If the problem persists, please contact us. <br/>
            We are sorry for the inconvenience. <br/><br/><br/>

            <p>
                 The page will be reloaded in 10 seconds. 
            </p>
            <LoadingDots/>
        </p>
    </Error>
)

const MailError = () => (
    <Error>
        <h4>⚠️ Mail not send stopped..</h4>
        <h6>
            Note: No actions were performed.
        </h6>
        <p>
            The previous action were successfully performed. <br/><br/>
            However, we could not send the mail. <br/><br/>

            Please contact us so we can send it manually. <br/><br/>
            We are sorry for the inconvenience. <br/><br/><br/>
            <p>
                 The page will be reloaded in 10 seconds. 
            </p>
            <LoadingDots/>
        </p>
    </Error>
)
const FormProvider = ({children, collection, when}) => {
    const [state, setState] = useState({
        editing: false,
        button: null
    })
    const toggleEditing = () => setState({...state, editing: !state?.editing})
    const setSubmitButton = (name) => setState({...state, button: name})

    // ? Utils
    const router = useRouter()
    const notify = useNotify();
    const methods = useForm({mode: 'all'});
    const {handleSubmit, watch, reset, formState} = methods;
    const [err, setErr] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    // ? Core handler
    const [updateEntry, [c_update_data, c_update_loading, c_update_error]] = useUpdate({})

    // ? Handlers
    const [updateUser, [d, loadingUpdateUser, errorUpdatingUser]] = useUpdateUser(true)
    const [sendMail, sendData, sendLoading, sendingError] = useSendOne()

    // ? Selectors
    const page = usePageContext()
    const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})


    // ? Mail handler
    const [updateState, setUpdateState] = useState({
		step: 'null',
		data: null
	})
    const mails = useEntryMails({
		state: updateState, 
		setState: setUpdateState, 
		mail: updateState?.mail,  
		collection, 
	})




    useEffect(() => {
        if(page?.entry?._id) reset({...page?.entry, retrieve: null})
    },[page?.entry?._id])

    let validate = null;

    let validation = {
        hide: null,
        show: null,
        disable: null,
        enable: null,
        enable: null,
        update: null,
        noUpdate: null,
        create: null,
        noCreate: null,
        error: null,
        helper: null,
    }

    if(when) {
        validate = getSchema(when?.fieldPath, collection?.schema)
        const shouldValidate = (validate?.validateActive && validate?.validation?.length >= 1)

        if(shouldValidate)  {
            const ctx = {entry: page?.entry, user};

            validate.validation.filter(rule => (
                rule.code && rule.actions?.length >= 1
            )).map((rule, i) => {
                const actions = rule.actions;
                
                const result = parseCode(rule.code, ctx, false);

                //Parse a action key
                const parseKey = key => {
                    //Get error || label validation
                    if(['error', 'helper'].includes(key)) return (
                        rule?.[key] ? 
                            parseCode(rule?.[key], ctx, false) :
                            null
                    )
                    //Return stringified result
                    return !isBoolean(result) ? result : (
                        result ? 'true' : 'false'
                    )
                }

                actions.map(key => {
                    validation[key] = parseKey(key)
                })

            })
            validation = Object.fromEntries(Object.entries(validation).filter(([_, value]) => value != null))
            if(hasAttr(validation, 'hide') && validation?.hide === 'true') return null;
            if(hasAttr(validation, 'show') && validation?.show === 'false') return null;

            if(validation?.show === 'true' && !validation?.hide) {
                if(!state?.editing) setState({...state, editing: true})
            }
        }
    }
    

    const schemaButtons = getFields(collection?.schema, 'button');

    const onSubmit = async (data) => {
        setSubmitting(true);
        if(!data?._id) return;

        const button = schemaButtons?.find(b => b?.name === state?.button);

        // const button = schemaButtons?.find(b => b.action === 'update')
        const hasUserTriggers = (button?.enableUser && button?.trigger?.users?.length >= 1)
        const hasMailTriggers = (button?.enableMail && button?.mailId && button?.mailTemplate)


        if(button?.enableModify){
            const ctx = {entry: data};
            data = parseCode(button.modifyCode, ctx, false);
        }

    

        const entry = data;

        if(!hasUserTriggers && !hasMailTriggers) return;

        if(hasUserTriggers) {   
            const userToTrigger = button.trigger.users
                .filter(t => t?.code && t?.userId)
                .map(trigger => ({
                    userId: data?.[trigger.userId],
                    code: trigger.code
                }));
            


            client.query({ 
                query: FILTER_USERS,
                variables: {
                    filter: {OR: Array.isArray(userToTrigger) ? userToTrigger?.map(t => ({_id: t?.userId}) ) : null}
                }
            }).then(({data}) => {
                if(!data?.userMany) notify({type: 'error', content: 'No users found'})
                const users = data?.userMany;


                userToTrigger.map(trigger => {
                    const user = users?.find(u => u?._id === trigger?.userId)
                    if(!user) return;
                    const ctx = {entry, user};
                    const result = parseCode(trigger?.code, ctx, false);
                    updateUser({data: result})
                    if(trigger.userId === userId) {
                        notify(`You wallet has been updated by your "${toLowCase(button?.name)}" response.`, 'entry', 'success')
                    } else {
                        setTimeout(() => {
                            notify(`We have updated ${user?.author?.firstName}'s their wallet also.`, 'entry', 'info')
                        }, 500);
                    }
                    
                })

                console.log('userMany')
                // console.log(userToTrigger)
            }).catch(err => {
                console.log(err)
                notify(`Updating stopped..`, 'entry', 'error')
                if(entry?.collectionId === '6401045f800fef330f4f4c04') {
                    setErr('user')
                }       
                setTimeout(() => {
                    router.reload();
                }, tenSecondsInMs );
                return;
            })    
        
        }

        if(hasMailTriggers && button.mailId) {

            // mailId: button.mailId,
        //         template: button.mailTemplate,
        //         entry: convertEntry(data, collection?.schema)



        // Todo fix this
        // ? We should implement the async await here
        // ? Because we can be sure that the mail is send

            // client.query({ 
            //     query: MAIL,
            //     variables: {
            //         _id: button.mailId
            //     }
            // }).then(({data}) => {
            //     const mail = data?.mailById;
            //     if(!mail) return;
            //     console.log('mailById', mail)
            //     const template = mail?.templates?.find(t => t.template_id === mail?.template);
            //     setParsingMailData({
            //         baseRoute: collection?.routing?.baseRoute,
            //         schema: collection?.schema, 
            //         template, 
            //         entry: convertEntry(entry, collection?.schema),

            //         // ! temp fix to handle over here
            //         base_mail: mail
            //     })
            // }).catch(err => {
            //     console.log(err)
            //     notify(`Mail could not be send..`, 'entry', 'error')
            //     if(entry?.collectionId === '6401045f800fef330f4f4c04') {
            //         setErr('mail')
            //     }       
            //     setTimeout(() => {
            //         router.reload();
            //     }, tenSecondsInMs );
            //     return;
            // });

        }

        setUpdateState({
            ...updateState, 
            data,
            step: 'loading',
            mail: !hasMailTriggers ? null : {
                mailId: button.mailId,
                template: button.mailTemplate,
                entry: convertEntry(data, collection?.schema)
            }

        })

        updateEntry({data: {
            _id: data?._id,
            record: {
                ...convertEntryBack(data, collection?.schema),
                updatedAt: new Date(),
                _id: undefined,
                retrieve: undefined

                //Set slug by id ...
            }
        }})

        setTimeout(() => {
            router.reload()
        }, tenSecondsInMs / 2);

    }


   return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <Provider {...methods}>
        <_FormProvider data={{data: methods.watch(), collection, setState, state, toggleEditing, setSubmitButton}}>
            
            {/* {JSON.stringify({data: watch() ? watch() : 'no data'})} */}
            {/* {JSON.stringify({...page?.entry, retrieve: null}?._id)}  */}
            {children}
            {!submitting && state?.button}
            {(err || errorUpdatingUser || sendingError) ? (
                <ErrorContainer>
                    <ErrorOverlay />
                    {(err === 'mail' || sendingError) ? <MailError/> : <UserError />}
                </ErrorContainer>
            ) : null}


            
            {c_update_error}
        </_FormProvider>
    </Provider>
    </form>
   )
}
export default FormProvider;