
import { setInspectBoundsAction } from '@redux/bounds/action';
import { addDataAction, replaceFullDataStoreAction } from '@redux/map/data/action';
import { clearMapAction, selectMapAction, updateMapAction } from '@redux/map/action';
import { setNotification } from '@redux/notification/action';
import { setPageAction } from '@redux/pages/action';
import { setCurrentPage } from '@redux/screens/action';
import { openInspectorPanel } from '@redux/ui/uiAction';
import { isInspectorOpen } from '@redux/ui/uiReducer';
import { includesAction, isAction } from '@redux/util';
import isEmpty from '@util/Empty';
import { isString } from '@util/Text';
import { default as actions, artboardActions, initArtboardAction, setArtboardPointer } from './action';
import { isInspectedSelector } from './reducer';



export const artboardActionFlow = ({getState, dispatch}) => next => action => {
    next(action);

    if(includesAction(action, actions.ARTBOARD_SET)){ 
        const {artboard, map, data} = action?.payload;
        // if(component?.group === 'components' && !isString(component?.data)){
        //     dispatch(setNotification({message: "Item is not correctly converted to component.", meta: {variant: 'warning'}}));
        //     return;
        // }  
         
        dispatch(setPageAction({type: 'artboard', _id: 'artboard'}))

        // if(isString(component?.data)) {
        //     dispatch(initArtboardAction({_id: component?.data, group: 'items'}, true))

        // } else if(component?.group === 'artboards') {
        dispatch(setArtboardPointer({_id: map?._id, group: map?.group}, true))
        // }


        dispatch(replaceFullDataStoreAction({data}))

        dispatch(updateMapAction({map: [map]}))

         
        // const state = getState();
        // const payload = action.payload;
         

        // dispatch(artboardActions.initInspectAction({...payload}, true))
        // dispatch(setInspectBoundsAction({...payload}))

        // if(!isInspectorOpen(state)) dispatch(openInspectorPanel())
        // if(isInspectedSelector(state, payload.component._id)) return;
    }

    if(isAction(action, actions.ARTBOARD_SET)){ 
        dispatch(clearMapAction())
        dispatch(setCurrentPage({_id: 'artboards'}));
    }
    
   
};

export const artboardMiddleware = [
    artboardActionFlow
];