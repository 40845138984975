import { isString } from "@util/Text";
import { default as actions, setSidebarPanelAction } from "./action";
import { navSelector } from "./reducer";

const getSubPanelValue = (panel, activeSub) => (
    panel?.hasSubPanel ? (
        Boolean(panel?.subDefault != activeSub) ? 
            panel.subDefault : null
    ) : null
);
const getLeftPanelValue = (panel, activeLeft) => 
    Boolean(
        panel?.value != null && 
        panel.value.length >= 3
    ) ? panel : null;
    // (
    //     Boolean(panel.value != activeLeft?.value) ?  
    //         panel : 
    //         null //prevent reset for now 
    // ) : null;

const sidebarPanelActionFlow = ({getState, dispatch}) => next => action => {
    // if(
    //     action.type === actions.NAV_SET_PANEL &&
    //     !isString(action.payload.panel)
    // ){ 
    //     const state = getState();
    //     const panel = action.payload.panel;
    //     const panels = navSelector(state);

    //     //Handle sub panel
    //     dispatch(setSidebarPanelAction({
    //         value: getSubPanelValue(panel, panels.sub),
    //         panel: 'subLeft'
    //     }));

    //     //Handle sub panel
    //     dispatch(setSidebarPanelAction({
    //         value: getLeftPanelValue(panel, panels.left),
    //         panel: 'left'
    //     }));
    // } else {
        next(action); 
    // }
};

export const navMiddleware = [
    sidebarPanelActionFlow,
];