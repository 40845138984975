 

import { ADD_COLLECTION } from '@api/mutations/collections';
import { isAuthenticated } from "@api/token";
import { useMutate } from "@hooks/useApi";
import { useNotify } from "@mosanic/hooks";
import { siteIdSelector, userIdSelector } from "@redux/auth/reducer";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

export const useCreateCollection = ({callback = null, redirect = true, dataType = 'collection'}) => {
	const router = useRouter()

	const isAuth = isAuthenticated()
	const userId = useSelector(userIdSelector)
	const siteId = useSelector(siteIdSelector)
    const [ addCollection, [data, loading, error]] = useMutate('collectionCreateOne', ADD_COLLECTION)

    const notify = useNotify();

    const handler = ({collection}) => {
		
		if(!isAuth) {notify('You are not authenticated.');return;}
        if(!siteId) {notify('No active site selected.');return;}
        
        const record = {
			...collection,
			date: new Date(),
			updatedAt: new Date(),
		}

		addCollection({
            record: {
				siteId,
				userId,
				type: dataType,
				...record,
			}
		}).then((res) => {
			let {data, error} = res;
			if(data?.collectionCreateOne) data = data.collectionCreateOne

			if(!error && data?.recordId){
                notify("Successfully created collection.", 'app', 'success')
                
                if(callback) callback();
                if(redirect) router.push(`/collection/${data.recordId}`);
			} else if (error) {
				notify("An error occurred during the collection creation.")
			}
		})
    }
    
    return [handler, [data, loading, error]];
}