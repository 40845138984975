import { defaultEndAdornmentValueFallback } from "@mosanic/_Fieldsets";

export const validateMetric = ({metric, updated}) => {
    //Reset
    if(!updated?.value && !updated?.metric && !updated?.token) return {}

    // Complete
    if(updated?.metric && updated?.value) {
        return updated;
    }

    if(updated?.token) {
        return {token: updated.token};
    }

    // Previous has end adornment set
    if(!updated?.metric && metric?.metric) {
        return {
            value: updated.value,
            metric: metric.metric
        }
    }

    //Fallback set
    if(!updated?.metric && !metric?.metric) {
        return {
            value: updated.value,
            metric: defaultEndAdornmentValueFallback
        }
    }
}



/**
 *  Helper methods for metric update paths
 */
const shouldClearAll = (values) => !values.endValue && !values.newValue && !values.selector //Has no end metric and no selector
const isValueEndRequired = (values, metric) => values.newValue && (!values.valueEnd && !metric?.metric && !values.selector) 

//If selector is updated
const shouldClearNewValue = (values, metric) => (metric?.value && values.selector) || (metric?.value && !values.newValue) || (metric?.value && values.newValue === '');
const shouldClearValueEnd = (values, metric) => !values.newValue && ((values.selector && metric?.metric) || (metric?.metric && !values.endValue));
const isSameValueEnd = (values, metric) => (values.valueEnd === metric?.metric);

//If no new selector is passed
const shouldClearSelector = (values, metric) => !values?.selector && metric?.selector;
const isSameSelector = (values, metric) => (values.selector === metric?.selector);

const formatValues = (values) => ({
    value: values.newValue,
    metric: values.valueEnd,
    selector: values.selector    
});
/**
 * Validation before metric update paths
 * @param {array} values [newValue, valueEnd, selector]
 * @param {object} metric full metric field object
 * @param {string} defaultEndValue fallback for valueEnd
 * @returns formatted values
 */
export const validateUpdateProps = (values, metric, defaultEndValue) => {
    if(shouldClearAll(values)) return formatValues(values);
    
    if(isValueEndRequired(values, metric)) values.valueEnd = defaultEndValue;
    else if(isSameValueEnd(values, metric)) values.valueEnd = null;

    if(shouldClearValueEnd(values, metric)) values.valueEnd = 'clear';
    if(shouldClearNewValue(values,metric)) values.newValue = 'clear';
    if(shouldClearSelector(values, metric)) values.selector = 'clear';
    
    if(isSameSelector(values, metric)) values.selector = null;

    return formatValues(values);
}


// export const getMetricUpdatePaths = (pathFunc, values) => {
//     let pathValues = [];
//     Object.entries(values).map(([fieldKey, value]) => {
//         if(value === 'clear' || value === '') pathValues.push(pathFunc(null, fieldKey));
//         else if(value) pathValues.push(pathFunc(value, fieldKey))
//     })
//     return pathValues;
// }



export const getPresetUpdatePaths = (metricPath, type, field, value, metric) => {
    if(!metric) metric = defaultEndAdornmentValueFallback;
    let pvs = [];
    ALL_SIDES.forEach(side => {
        if(type != 'all') {
            if(type === 'axis' && getOppositeSide(field) === side) pvs.push(...metricPath(side, value, metric));
            if(field === side) pvs.push(...metricPath(side, value, metric));
        } else {
            pvs.push(...metricPath(side, value, metric));
        }
    });
    return pvs;
}