import { setThemeAction } from '@redux/theme/action';
import { setThemeModeAction } from '@redux/ui/uiAction';
import { isServer } from '@util/Device';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// export type ColorScheme = 'light' | 'dark' | any | Array<any>;

// export interface PrefersColorSchemeOptions {
//   ssr?: boolean;
// }

export function usePrefColorSchema(
  callback = false,
) {
  const dispatch = useDispatch()
  const savedAutomatic = !isServer() ? localStorage.getItem('automaticColorScheme') : null;
  const savedPreferred = !isServer() ? localStorage.getItem('preferredColorScheme') : null;

  //Initiate automatic
  const [automatic, setAutomatic] = useState(null);
  const [_, setCurrent] = useState(savedPreferred || savedAutomatic);

  const setPref = (mode) => {
    localStorage.setItem('preferredColorScheme', mode);
      
    setCurrent(mode)
    dispatch(setThemeModeAction(mode))
  }

  const clearMode = savedPreferred ? () => {
    localStorage.removeItem('preferredColorScheme')
    setCurrent(null)
    dispatch(setThemeModeAction(null))
  } : null

  //On switch window preference, reset automatic storage 
  useEffect(()=> {
    if(savedAutomatic != automatic) 
      localStorage.setItem('automaticColorScheme', automatic);
  },[automatic, savedAutomatic])

  //Main handler for window mode
  useEffect(() => {
    if (!window.matchMedia) {
      setAutomatic('light');
      return;
    }
    
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setAutomatic(mediaQuery.matches ? 'dark' : 'light');

    function onChange(event) {
      const autoMode = event.matches ? 'dark' : 'light'
      setAutomatic(autoMode);
      dispatch(setThemeModeAction(savedPreferred ? savedPreferred : autoMode))
    }

    mediaQuery.addEventListener('change', onChange);

    return () => {mediaQuery.removeEventListener('change', onChange);};
  }, [dispatch, savedPreferred]);

  return [
    savedPreferred ? savedPreferred : savedAutomatic, 
    setPref,
    clearMode
  ]
}