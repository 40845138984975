import { ADD_MEDIA } from "@api/mutations/media";
import { useMutate } from "@hooks/useApi";
import { siteIdSelector } from "@redux/auth/reducer";
import { setNotification } from "@redux/notification/action";
import { getSensitizedString, slugify } from "@util/Text";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const ERROR_DURATION = 10000;
const STATUS_DURATION = 4000;

const dataReceivedNotValid = (url, filename) => ({
    message: `Media could not be saved. ${!url && `File url not received.`} ${!filename && `Filename not received.`}`, 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
})
const noActiveSiteError = () => ({
    message: `Media could not be saved. No active site selected.`, 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
})
const uploadSuccess = () => ({
    message: `New media saved and url & properties stored.`, 
    feature: "ImageContainer", 
    meta: {duration: STATUS_DURATION, delay: 1000}
});
const uploadError = () => ({
    message: "Media could not be saved. Please try again.", 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
});

export const useStoreMedia = ({useUrlCallback}) => {
    const dispatch = useDispatch();
    const siteId = useSelector(siteIdSelector)
    const cb = useUrlCallback;


    const [ addMedia, [data, loading, error] ] = useMutate('mediaCreateOne', ADD_MEDIA);
    const notify = useCallback((notification) => {
        dispatch(setNotification(notification))
    },[dispatch]);

    const handler = useCallback(({uploadedImage, size = 0, meta}) => {
        const {url, urlMin, urlThumb, filename, encoding, mimetype} = uploadedImage;
        const {title, alt, folder} = meta || {title: getSensitizedString(filename), alt: '', folder: ''}

        if(!url || !filename) {
            notify(dataReceivedNotValid(url, filename));
            return;
        }
        if(!siteId) {
            notify(noActiveSiteError());
            return;
        }

        const variables = { 
            record: { 
                title, 
                filename,
                mimetype, 
                encoding,
                folder: folder ? slugify(folder) : '',
                alt,
                url, 
                urlMin, 
                urlThumb,
                size,
                siteId
            } 
        };
        addMedia(variables).then(res => {
            const {data, error} = res;
            if(!error) {
                notify(uploadSuccess());
                cb({
                    url,
                    urlMin, 
                    urlThumb,
                    seo: meta
                });
            }
            if(error){
                notify(uploadError());
                //Todo remove uploaded file
            }
        
        })
    
    },[addMedia, notify, siteId, cb]);

    return [
        handler, 
        data, 
        loading, 
        error, 
    ]
}