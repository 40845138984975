import { useEffect, useState } from "react";
// import useCollapse from "react-collapsed";
// import {UnmountClosed, Collapse } from 'react-collapse';
// import Collapse from 'rc-collapse';
import {SlideDown} from 'react-slidedown'
const Expander = ({children, expanded = false, trigger = null,  timeout = 400, direction = null, fullWidth = false, ...other}) => {

    const [isExpanded, setExpanded] = useState(expanded)
    // const { getCollapseProps, getToggleProps } = useCollapse({ 

    //     isExpanded
    // })

    useEffect(() => {
        setExpanded(expanded)
    }, [expanded])

    return (
        <>
        {trigger ? (
            <span 
                className={isExpanded ? 'expanded' : 'closed'}
                style={{width: fullWidth ? '100%' : 'auto'}}
                
                onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
             
            >
                {trigger}
                
            </span>
        ):null}
        <Expandable 
            {...other}
            expanded={isExpanded} 
            timeout={timeout}
            direction={direction}
        >
            {children}
        </Expandable>
        
        </>
    )
}

const Expandable = ({children, expanded, timeout, direction, ...other}) => (
    <SlideDown  as="div" closed={!expanded} transitionOnAppear {...other}>
        {children}
    </SlideDown>
);
export default Expander;