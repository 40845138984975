
function bold(text){
    const bold = /\*\*(.*?)\*\*/gm;
    return text.replace(bold, '<strong>$1</strong>');            
}
function underline(text){
    const underline = /\_\_\_(.*?)\_\_\_/gm;
    return text.replace(underline, '<u>$1</u>');            
}
function strike(text){
    const underline = /\~\~(.*?)\~\~/gm;
    return text.replace(underline, '<del>$1</del>');            
}
function italic(text){
    const underline = /\_\_(.*?)\_\_/gm;
    return text.replace(underline, '<i>$1</i>');            
}
function upper(text){
    // const upperCase = /\[\[(.*?)\]\]/gm;
    // text = text.replace(underline, upperCase)
    // return text.replace(underline, '$1');     
    return text;       
}

function heading(text){
    const headings = {
        h1: /\h1(.*?)\h1/gm,
        h2: /\h2(.*?)\h2/gm,
        h3: /\h3(.*?)\h3/gm,
        h4: /\h4(.*?)\h4/gm,
        h5: /\h5(.*?)\h5/gm,
        h6: /\h6(.*?)\h6/gm,
    }
    Object.entries(headings).map(([tag, regex]) => {
        text = text.replace(regex, `<${tag}>$1</${tag}>`)
    })
    return text;
}

export const formatText = text => text ? bold(underline(strike(heading(italic(text))))) : text;
