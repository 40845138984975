


import { USER_COUNT, USER_IDS, LIST_ALL_USERS } from "@api/queries/users";
import { useGet } from "@mosanic/cms";
import { siteIdSelector, userIdSelector } from "@redux/auth/reducer";
import { useSelector } from "react-redux";
import Select from "../Select/Select";
import { useGetCount } from "@mosanic/cms/Query/useDataQuery";
import { useNotify } from "@mosanic/hooks";
import { get } from "@util/Obj";
import { PreviewDataTable } from "./Convert";
import { getDevModePrefSelector } from "@redux/options/reducer";
import { useDataProvider } from "@mosanic/core/Provider";
 



const UserSelector = ({
    value = null,
    relation = null,
    onChange = null,
    label = null,
    name = null,
    disabled = false,
    pointer = 'data.id'
}) => {
    const siteId = useSelector(siteIdSelector)
    const devMode = useSelector(getDevModePrefSelector)
    // const {users} = useDataProvider()

    const [getUsers, [users, loadingUsers]] = useGet({
        params: {filter: {siteId}},
        type: 'user',
        method: 'Many',
        query: LIST_ALL_USERS,
        silent: true,
        siteCheck: false
    })
    const [userCount] = useGetCount({ 
        type: 'user',
        query: USER_COUNT,
        site_id: siteId
    })
    const notify = useNotify()
    if(users?.length >= 101) notify('Default limit reached. Contact info@mosanic.io for user expansion.')

    const getLabel = user => `${user?.author?.firstName} ${user?.author?.lastName ? user?.author?.lastName : ''}`


    const options = (users?.length >= 1 && siteId) ? (
        users.map(user => ({
            value: user?._id,
            label: getLabel(user)
        }))
    ) : ['No users found']
    


    
    return (
        <>
        <Select 
            searchable
            disabled={disabled}
            label={label}
            name={name}
            options={options}
            onChange={onChange}
            value={value}
            loading={loadingUsers}
            // multiple={type?.multiple}
        />
        {devMode && !options?.map(o => o.value)?.includes(value) && Array.isArray(options) && (
            <PreviewDataTable value={value} data={users} options={options} onChange={onChange} label="User" getLabel={getLabel} />
        )}

        </>
    )
}  




export default UserSelector;