import {CREATE_ORDER, SUBMIT_ORDER, updateOrder} from "./orderAction";
import {orderComplete} from "../ui/uiAction";

// translate to update order action
export const layoutOrder = ({dispatch}) => next => action => {
  next(action);

  if (action.type === CREATE_ORDER) {
    dispatch(updateOrder({date: new Date(), _id: action.payload}))
  }
};

// update action ant notify about completing
export const completeOrder = ({dispatch}) => next => action => {
  next(action);

  if (action.type === SUBMIT_ORDER) {
    dispatch(updateOrder({data: action.payload}));
    dispatch(orderComplete())
  }
};

export const orderMdl = [layoutOrder, completeOrder];