
import { handleHover, setHoverAction } from '@redux/bounds/action';
import { hoveringLayersSelector } from '@redux/bounds/reducer';
import { isItemHovered } from '@redux/bounds/selectors';
import { getPageScale } from '@redux/canvas/reducer';
import { getScaledPositionByParent } from '@util';
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';



const useItemHover = ({item, map, ref, breakpoint = null, clone = null}) => {
  const dispatch = useDispatch();
  // const hoverLayers = useSelector(hoveringLayersSelector);
  const isHovered = useSelector(state => isItemHovered(state, {id: item?._id}))
  const pageScale = useSelector(getPageScale)

   
  const setHoverState = (state) => {
    const bounds = breakpoint === 'layers' ? null :
      getScaledPositionByParent(ref, 'inspectRef', pageScale);

    dispatch(setHoverAction({item: {...item, clone}, state, map, bounds, screen: breakpoint}));
  }
  // ,[dispatch, item, map, ref, breakpoint, pageScale, clone]);

  // useEffect(() => {
  //     if(hoverLayers && isHovered && breakpoint != 'layers')
  //       setHoverState(true)

  // },[isHovered, setHoverState, breakpoint, hoverLayers])

  return {
    hoverState: breakpoint === 'layers' ? isHovered : false, 
    onMouseEnter: () => setHoverState(true),
    onMouseLeave: () => setHoverState(false),
  };
};
export default useItemHover;  