import { isComponent } from '@mosanic/utils/validate';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';

import { splitPath } from "@mosanic/map/paths";
import {
    CLEAR_HOVER_BOUNDS,
    RESET_BOUNDS_STATE,
    RESET_HOVER_BOUNDS,
    SET_HOVER_ELEMENT,
    SET_INSPECTED_BOUNDS, SET_INSPECT_SCREEN_BOUNDS,
    TOGGLE_LAST_HOVER, UPDATE_INSPECT_BOUNDS
} from "./action";
    

const initialState = {
    preventReset: false,
    element: null,
    bounds: null,
    map: null,
    screen: null,
    inspect: {
        _id: null,
        group: null,
        bounds: null,
        screen: null,
    },
    history: [],
};


const boundsReducer = (bounds = initialState, action) => produce(bounds, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...bounds, ...action.payload};

        case SET_HOVER_ELEMENT:
            draft.element = action.payload;
            draft.bounds = action.meta.bounds;
            draft.map = action.meta.map;
            draft.screen = action.meta?.screen;

            draft.history = [...draft.history, {
                element: action.payload,
                bounds: action.meta.bounds, 
                map: action.meta.map,
                screen: action.meta?.screen,
            }];
            return draft;
        
        case TOGGLE_LAST_HOVER:
            let levels = 0;

            //remove old
            draft.history = draft.history.filter((el) => el.map?.path != draft.map?.path);

            if(draft.map?.path) levels = splitPath(draft.map.path).length;
            
            //Remove last two
            if(levels >= 2) { 
                if(draft.map?.path) draft.map.path = draft.map.path.substring(0, draft.map.path.length -2)
            } else {
                if(draft.map?.path) draft.map.path = action.meta.map.path
            }

            draft.history.map((item) => {
                if(item?.map?.path === draft?.map?.path) {
                    draft.element = item.element;
                    draft.bounds = item.bounds;
                    draft.map = item.map;
                    draft.screen = item.screen;
                }
            } )

            if(action?.meta?.bounds?.reset) draft.bounds = null
           
            return draft;
        
        case SET_INSPECT_SCREEN_BOUNDS:
            draft.inspect._id = action.payload.component?._id;;
            draft.inspect.bounds = action.payload.bounds;
            // draft.inspect.map = action.payload.map;
            draft.inspect.group = 'screens'
            draft.inspect.screen = action.payload.screen;

            return draft;

        case SET_INSPECTED_BOUNDS:
            draft.inspect = {
                _id: action.payload.component._id,
                group: action.payload.component.group,
                bounds: action.payload.bounds,
                screen: action.payload.screen
            }
            return draft;
        

        case UPDATE_INSPECT_BOUNDS:
            let prev = null;
            draft.inspect.bounds = action.payload;
            draft.history.map((item) => {if(item.path === action.meta.path) prev = item; }); //store previous
            draft.history = draft.history.filter(el => el.path != action.meta.path) //remove previous
            draft.history = [...draft.history, {...prev, bounds: action.payload, path: action.meta.path}]; //update
            return draft;

        

        case RESET_HOVER_BOUNDS:
            draft.element = null;
            draft.map = null;
            draft.bounds = null;
            draft.history = [];
            draft.screen = null;

            draft.preventReset = false;
            return draft;

        case RESET_BOUNDS_STATE:
            draft = initialState;
            return draft;

        case CLEAR_HOVER_BOUNDS:
            draft.history = [];
            return draft;

        default:
            return bounds;
    }; //switch


});
export default boundsReducer;

//Global bounds selectors
// export const featureSelector = state => state.present;
export const hoverSelector = state => state.present.bounds
export const inspectHoverSelector = state => hoverSelector(state).inspect



//Screen selectors
export const hoverScreenSelector = state => hoverSelector(state).screen;
export const hoveringLayersSelector = state => hoverSelector(state).screen === 'layers';


export const sameBounds = (bounds, newBounds) => (bounds?.top && newBounds?.top) ? (
    bounds.top === newBounds.top &&
    bounds.bottom === newBounds.bottom &&
    bounds.left === newBounds.left &&
    bounds.right === newBounds.right
) : false;

// const selectItems = state => state.items
// const selectItemId = (state, itemId) => itemId

// const selectItemById = createSelector(
//   [selectItems, selectItemId],
//   (items, itemId) => items[itemId]
// )

// const item = selectItemById(state, 42)

export const hoverBoundsSelector = state => ({
    element: hoverSelector(state).element,
    bounds: hoverSelector(state).bounds, 
    screen: hoverSelector(state).screen,
    map: hoverSelector(state).map,
});



export const hoverPathSelector = state => hoverSelector(state).map?.path;
export const isAnComponentHovered = state => hoverSelector(state).map?.type && isComponent(hoverSelector(state).map?.type);
export const hasHoverBoundsSelector = (state) => {

    return(
        Boolean(hoverSelector(state).history?.length >= 1) ||
        Boolean(hoverSelector(state).bounds) ||
        Boolean(hoverSelector(state).element?.type)
    );
}

export const hasScreenBoundsSelector = (state) => Boolean(inspectedSelector(state)?.element?.type === 'screen')