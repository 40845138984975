
import isBoolean from "@util/Boolean";
import { replaceWithSource } from "./value";
import { parseCode } from "@mosanic/fields/Code/PreviewCode";
import { hasAttr } from "@util/Obj";




const canPopulateValue = field => (field?.populateActive && (field?.populateCode || field?.code));

export const populateFieldValue = ({field, entry, onChange, value}) => {
    if(!canPopulateValue(field) || !entry || !onChange) return false

    const populatedValue = !field?.dangerousPopulation ?
        replaceWithSource(field.populateCode, entry) : 
        parseCode(field.code, {entry}, false);
    
    if((!value || value != populatedValue) && populatedValue) onChange(populatedValue)    

    return true;
}


export const canValidateField = field =>  (field?.validateActive && field?.validation?.length >= 1)
const getValidationRules = field => field.validation.filter(rule => (
    rule.code && rule.actions?.length >= 1
))

const validationRefArray = 'hide|show|disable|enable|update|noUpdate|create|noCreate|error|helper'.split('|');
const validationRefObj = validationRefArray.reduce((obj, v) => ({ ...obj, [v]: null}), {}) ;

export const validateFieldRules = ({field, user, entry}) => {
    if(!canValidateField(field) || (!user || !entry)) return false;
    
    let validation = {...validationRefObj}
    const ctx = {entry, user};

    // console.log({rules: getValidationRules(field), ctx})
    getValidationRules(field).map((rule, i) => {
        const actions = rule.actions;
        
        const result = parseCode(rule.code, ctx, false);

        //Parse a action key
        const parseKey = key => {
            //Get error || label validation
            if(['error', 'helper'].includes(key)) return (
                rule?.[key] ? 
                    parseCode(rule?.[key], ctx, false) :
                    null
            )
            //Return stringified result
            return !isBoolean(result) ? result : (
                result ? 'true' : 'false'
            )
        }

        actions.map(key => {
            validation[key] = parseKey(key)
        })

    })
    return Object.fromEntries(
        Object.entries(validation).filter(([_, value]) => value != null)
    )

}