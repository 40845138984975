import Items  from "@mosanic/constants/items";
import { toLowCase } from "@util/Text";


const CONTAINERS = [Items.ROW, Items.CONTAINER, Items.COLUMN, 'link'];

export const isColumns = (componentType) => componentType === 'columns';
export const isRow = (componentType) => componentType === 'row';
export const isContainer = (itemType) => CONTAINERS.includes(itemType);


//Get item type before initialize

export const getItemType = (componentType) => {
    switch (componentType) {
        case 'container': return Items.CONTAINER;
        case 'stack': return Items.CONTAINER;
        case 'link': return Items.CONTAINER;
        case 'columns': return Items.ROW;
        case 'row': return Items.ROW;
        case 'column': return Items.COLUMN;
        default: return Items.COMPONENT;
    };
};
export const getItemGroup = (componentType) => toLowCase(getItemType(componentType), 's');
