
import { feature } from '@redux/util';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { default as actions } from './action';

const initialState = {
    _id: null,
    group: null,
    artboards: {}
};
 
const artboardReducer = (artboard = initialState, action) => produce(artboard, draft => {
    const pl = action?.payload;
    switch (action.type) {
        case HYDRATE:
            return {...artboard, ...action.payload};

        case actions.ARTBOARD_ADD:

            draft.artboards ={
                ...draft.artboards,
                [pl?.artboard?._id]: pl
            }
            return draft;

        case actions.ARTBOARD_SET_POINTER:
            // if(draft.screen != action.payload.screen) 
            //     draft.screen = action.payload.screen;

            draft = {
                ...action.payload,
                artboards: draft?.artboards
            };
            return draft;

        
        case actions.ARTBOARD_CLEAR: 
            draft = initialState;
            return draft;
       
        default:
            return artboard;
    }; //switch
});
export default artboardReducer;
 
// export const containsOverwrite = (source, overwrite) => {
//     if(!source) return false;
//     return source.filter(o => 
//         o?.value === overwrite?.value && 
//         o.type === overwrite?.type
//     )?.length >= 1
// }

export const artboardSelector = (state) => feature('artboard', state);
export const localArtboardsSelector = state => artboardSelector(state)?.artboards

//Inspect element selectors
// export const artboardIdSelector = (state) => artboardSelector(state)._id;
// export const isInspectedSelector = (state, _id) => Boolean(artboardIdSelector(state) === _id)
// export const hasInspectedSelector = (state, _id) => Boolean(artboardIdSelector(state))

// //Editor selectors
// export const isEditingSelector = (state, _id) => Boolean(artboardSelector(state).editor)
 
// //Inspect overwrite selectors
// export const artboardOverwriteSelector = (state) => artboardSelector(state).overwrite;