import { Fragment, useCallback, useEffect, useState } from 'react';


import { AUTH_EMAIL_REF } from '@api/constants';
import { getDocsUrl } from '@api/host';
import { AUTH_RESET_PASSWORD } from '@api/mutations/auth';
import { useMutate } from '@hooks/useApi';
import { usePasswordValidation } from '@mosanic/components/User/Handlers/usePasswordValidation';
import { Button } from '@mosanic/fields';
import Input from '@mosanic/fields/Input';
import { useNotify } from '@mosanic/hooks';
import { AdvancedLabel, ControlDialog, InputGroup, Link, LoadingDots } from '@mosanic/items';
import { colors } from '@mosanic/styles';
import { activateAuthAction } from '@redux/auth/action';
import { replacePart } from '@util/Text';
import { deleteCookie, recoverCookie } from '@util/server/cookie';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';

export const VerifyResetForm = ({ activateAuth, mode, setMode, resetCode = null, redirect = '/'}) => {
	const email = mode?.email || recoverCookie(AUTH_EMAIL_REF);

	const { handleSubmit, control, watch,reset, formState: { errors, isValid } } = useForm({mode: 'all'});
	const inputProps = {control, fullWidth: true, errors, size: 'large'}

	const [disabled, setDisabled] = useState(false);
	const dispatch = useDispatch();
	const [authUserResetCode, [token, loading, error]] = useMutate('authResetPassword', AUTH_RESET_PASSWORD)
	const notify = useNotify()

	useEffect(() => {
		if(resetCode) {
			reset({code: resetCode, email})
		} else if(email) {
			reset({...watch(), email})
		}
	},[resetCode, email])
	

	const onSubmit = useCallback((data) => {
		setDisabled(true);

		console.log(data)
		if(!email && !data?.email) {
			notify('Did not receive a email.', 'login', 'error')
			return;
		}
		if(!data?.code) {
			notify('Did not receive a reset confirmation code.', 'login', 'error')
			return;
		}

		const variables = {
			code: data.code,
			email: data?.email || email,
			newPassword: data?.password ? data.password : undefined
		}

		authUserResetCode(variables).then(res => {
			let {data, error} = res;
			if(data?.authResetPassword) data = data.authResetPassword
			if(data && !error){
				notify(`The reset code you provided was correct.`, 'login', 'info')

				setTimeout(() => {
					notify(`Your password has been reset.`, 'login', 'success')
					dispatch(activateAuthAction({token: data.token, redirect: false, remember: true}))
					deleteCookie(AUTH_EMAIL_REF)
				}, 500);
			}
			if(error) {
				setDisabled(false)
				
			}
		});

	},[activateAuth, authUserResetCode, email, notify]);

	// useEffect(()=> {
	// 	if(resetCode && email) {
	// 		onSubmit({code: resetCode})
	// 	}
	// },[email, resetCode, onSubmit])
	//form-row mb-3

	const [
		errorMessage,
		Validations,
		strength,
	] = usePasswordValidation({
		password: watch()?.password,
		repeat_password: watch()?.repeatNewPassword,
		firstOnly: true
	})
	return (
		<Fragment>
			<form disabled={disabled} onSubmit={handleSubmit(onSubmit)}>

				<InputGroup center>
					<Input 
						sanitize={e => replacePart(e, ' ', '-')}
						style={{maxWidth: '300px'}}
						name="code" label="Verification reset code" {...inputProps}
						rules={{required: "Enter the reset code received in your mail."}}
					/>

					<Input 
						type="email" name="email"
						style={{maxWidth: '300px'}}
						 {...inputProps}
						rules={{required: "Email address is required."}}
					/>


					<Input 
						style={{maxWidth: '300px'}} type="password"
						name="password" label="New password" {...inputProps}
						rules={{required: "Enter your new password."}}
					/>

					{watch()?.password && (
						<Input 
							style={{maxWidth: '300px'}} type="password"
							name="repeatNewPassword" label="Repeat New password" {...inputProps}
							rules={{required: "Repeat your new password."}}
						/>
					)}
					{(watch()?.password && watch()?.repeatNewPassword) && (
						<Validations center/>
					)}	

					<Button 
						wrap
						width={[1, '200px']}
						label={loading ? <LoadingDots /> : "Verify Reset"}
						theme="primary"
						size="large"
						type="submit"
						disabled={Boolean(loading || !isValid || errorMessage)}
					/>
				</InputGroup>


			</form>
			{error}

			<div className="form-bottom text-center">
				<br/>
				<p className="sign-up-text">
					Don’t have an account?{" "}
					<Link href="/register" color="primary">

						Register
					</Link>
				</p>
				<ControlDialog 
					title="📩 Verification code help"
					size="sm"
					trigger={(
						<AdvancedLabel label="Didn't receive a code? " span style={{cursor: 'pointer'}}/>
					)}
				>
					You should receive a verification reset email within 5 minutes after login. <br/>
					Send to: {mode?.email} <br/><br/>

					If you&apos;re not seeing it in your inbox, check out these suggestions:
					<ul>
						<li>Refresh mailbox</li>
						<li>Check Spam &amp; junk folder</li>
						<li>Check for typos in the login</li>
						<li>Add @mosanic.io to your contact safelist</li>
					</ul>
					<br/>
					If you&apos;ve tried these steps without any success, <Link href={getDocsUrl('account/login')}>learn more.</Link> <br/>
					Or <Link href="mailto:help@mosanic.io">contact our support team</Link>.

				</ControlDialog>

				<AdvancedLabel 
					onClick={() => setMode({step: 'reset'})}
					style={{
						color: colors.util.primary.base,
						display: 'block', 
						cursor: 'pointer'
					}}
					label="Go back" 
				/>
			</div>
		</Fragment>
	);
};
