import { useWindowSize } from '@hooks/useWindowSize';
import { getDeviceSize } from '@mosanic/core/Breakpoints/useDevices';
import { getBreakpointById, getBreakPointByWidth, getBreakpointsSelector } from '@redux/breakpoints/reducer';
// import { getBreakPointByWidth } from '@redux/canvas/reducer';
import { breakpointModeSelector, horizontalModeSelector } from '@redux/options/reducer';
import { currentBpSelector, currentDeviceSelector } from '@redux/screens/reducer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBreakpoint = ({overwrite = 'auto'}) => {
  const [breakpoint, setBreakpoint] = useState('xl');
  const {width} = useWindowSize()
  const bp = useSelector(state => getBreakPointByWidth(state, width))?._id

  useEffect(() => {
    if(width && bp != breakpoint) {
      setBreakpoint(bp)
    }
  },[bp, breakpoint, width])
  
  if(!breakpoint) setBreakpoint(bp)

  if(overwrite && overwrite != 'auto') return overwrite;
  return breakpoint;
}

export const useCanvasScreenSize = () => {
  //Active mode
  const breakpointMode = useSelector(breakpointModeSelector)

  const activeBp = useSelector(currentBpSelector)
  const full = useSelector(state => getBreakpointById(state, activeBp))

  const device = useSelector(currentDeviceSelector);
  const horizontalMode = useSelector(horizontalModeSelector) && device?.type != 'desktop';
  const size = getDeviceSize(device);

  

  const width = 
    breakpointMode ? (full?.minWidth ? full.minWidth : 350) : 
    !horizontalMode ? size?.maxWidth : size?.maxHeight;
  

  const breakpoint = useSelector(state => getBreakPointByWidth(state, width))?._id
  return {
    width,
    height: horizontalMode ? size?.maxWidth : size?.maxHeight,
    breakpoint,
    breakpointMode
  }
}