
//Base variants
export const BASE = 'base';
export const ALL = 'all';


export const HOVER = ":hover";
export const NOT_HOVER = ":not(:hover)";
export const FOCUS = ":focus";
export const NOT_FOCUS = ":not(:focus)";
export const PRESSED = ":active";
export const NOT_PRESSED = ":not(:active)";

export const States = {
    HOVER,
    NOT_HOVER,
    FOCUS,
    NOT_FOCUS,
    PRESSED,
    NOT_PRESSED
}

export const interactionStates = [{
    label: 'Hover',
    value: HOVER
},{
    label: 'Not Hover',
    value: NOT_HOVER
},{
    label: 'Pressed',
    value: PRESSED
},{
    label: 'Not Pressed',
    value: NOT_PRESSED
},{
    label: 'Focussed',
    value: FOCUS
},{
    label: 'Not Focussed',
    value: NOT_FOCUS
}]


export const variantGroupTypes = [{
    label: 'Toggle variant',
    type: 'toggle',
    helpText: 'This variant can be switched on and off by a single toggle.'
}, {
    label: 'Single select variant',
    type: 'single',
    helpText: 'This variant group contains multiple options where only one can be selected.'
}, {
    label: 'Multi select variant',
    type: 'multi',
    helpText: 'This variant group contains multiple options where multiple can be selected.'
}];

export const Variants = {
    BASE,
    ALL
}