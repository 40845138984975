import { SINGLE_UPLOAD } from "@api/mutations/media";
import { useMutation } from "@apollo/client";
import { siteIdSelector } from "@redux/auth/reducer";
import { setNotification } from "@redux/notification/action";
import { biteSizeToMb } from "@util/Num";
import { slugify } from "@util/Text";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ERROR_DURATION = 10000;
const STATUS_DURATION = 4000;

const uploadSuccess = (fileName) => ({
    message: `Successfully uploaded: ${fileName}`, 
    feature: "ImageContainer", 
    meta: {duration: STATUS_DURATION}
});
const uploadError = () => ({
    message: "Error during file upload", 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
});
const noActiveSiteError = () => ({
    message: `Media could not be saved. No active site selected.`, 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
})
export const useUploadFile = ({storeMediaCallback}) => {
    const dispatch = useDispatch()
    const siteId = useSelector(siteIdSelector)

    const [progress, setProgress] = useState(0);
    const [abort, setAbort] = useState(null);
    const [handleUpload, { loading, error, data }] = useMutation(SINGLE_UPLOAD);

    // const [ handleUpload, [data, loading, error] ] = useMutate('uploadFile', SINGLE_UPLOAD);
    const notify = useCallback((notification) => dispatch(setNotification(notification)), [dispatch]);

    //Abort controller
    useEffect(() => {
         return () => (abort) ? abort() : null
    },[abort])

    const handler = useCallback((image, meta) => {
        setProgress(0);
        if(!image?.file) return;

        if(!siteId) {
            notify(noActiveSiteError());
            return;
        }
        const path = meta?.folder ? `${siteId}/${slugify(meta?.folder)}/` : `${siteId}/`;

        const variables = { 
            file: image.file,
            path
        };
        console.log(variables)
        handleUpload({
            variables,
            context: {
                fetchOptions: {
                    onUploadProgress: (ev) => {
                        setProgress(ev.loaded / ev.total)
                    },
                    // onAbortPossible: (abortHandler) => {
                    //     setAbort(abortHandler);
                    // }
                }
            }
        }).then(res => {
            let {data, error} = res;
            if(data?.uploadFile) data = data.uploadFile

            const fileName = data?.filename;

            if(!error) {
                notify(uploadSuccess(meta?.title ? meta.title : fileName));
                storeMediaCallback({
                    size: biteSizeToMb(image.file.size),
                    uploadedImage: data, 
                    meta
                });
            }

            if(error) notify(uploadError());
        });

    }, [siteId, handleUpload, notify, storeMediaCallback, abort]);

    return [
        handler, 
        progress, 
        loading, 
        error, 
        data,
    ]
}