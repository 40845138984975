import { produce } from 'immer';
import { isNumber } from '@util/Num';
import { HYDRATE } from 'next-redux-wrapper';
import { LARGEST_BP, SMALLEST_BP } from "../../components/Builder/Adapters/constants";
import { 
    // INIT_THEME, 
    SET_CANVAS, 
    // SET_CANVAS_BREAKPOINT, 
    SET_IS_DRAGGING,
     SET_PAGE_SCALE, 
    //  SET_PREVIEW_POINTS, 
    //  SET_THEME 
} from "./action";
// import { generatePreviewPoints } from './middleware';


export const DYNAMIC_SCREEN = 'xl';
export const DEFAULT_BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 850,
    lg: 1200,
    xl: 1400,
};
export const getMobileFirstScreens = () => Object.keys(DEFAULT_BREAKPOINTS);
export const getDesktopFirstScreens = () => Object.keys(DEFAULT_BREAKPOINTS).reverse();

const initialState = {
    isDragging: false,
    pageScale: 1,
    scaleSource: 'init',
    canvasBreakpoint: LARGEST_BP,
    breakpoints: {
        ...DEFAULT_BREAKPOINTS
    },
    previewPoints: {
        ...DEFAULT_BREAKPOINTS
    },
    builderCanvas: {
        x:0, 
        y:0, 
        width:900, 
        height:'85vh',
    },
    screens: [{
        name: 'base',
        breakPoint: DYNAMIC_SCREEN,
        points: null,
        scale: 0.9,
        ref: null,
    },{
        name: 'small',
        breakPoint: 'sm',
        points: null,
        scale: 1.2,
        ref: null,
    },{
        name: 'mobile',
        breakPoint: 'xs',
        points: null,
        scale: 1.2,
        ref: null,
    }],
    theme: null
};

const canvasReducer = (canvas = initialState, action) => produce(canvas, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...canvas, ...action.payload};

        case SET_CANVAS:
            const newWidth = getBreakpointWidth(action.payload, draft.breakpoints, action.meta?.padding)
            
            draft.builderCanvas = {
                ...draft.builderCanvas,
                width: newWidth
            };
            draft.screens = draft.screens.map(screen => {
                if(screen.breakPoint === DYNAMIC_SCREEN){
                    return {...screen, canvas: draft.builderCanvas}
                }
                const offset = getContainerOffset(screen.breakPoint) < 50 ? 60 : getContainerOffset(screen.breakPoint) * 1.15;
                return {
                    ...screen,
                    canvas: {
                        x: 0,
                        y: 0,
                        width: getBreakpointWidth(screen.breakPoint, draft.breakpoints, offset),
                        height: 'auto'
                    }
                };
            })
            return draft;
        
        // case SET_THEME:
        //     // draft.theme = action.payload;
        //     return draft;

        // case INIT_THEME:
            // draft.theme = action.payload;
            // return draft;

        // case SET_PREVIEW_POINTS:
        //     draft.previewPoints = action.payload;
        //     draft.screens = draft.screens.map(screen => {
        //         if(screen.breakPoint === DYNAMIC_SCREEN){
        //             return {...screen, points: action.payload}
        //         }
        //         return {...screen,
        //             // points: generatePreviewPoints(screen.breakPoint, draft.breakpoints)
        //         }
        //     })
        //     return draft;

        // case SET_CANVAS_BREAKPOINT:
        //     draft.canvasBreakpoint = action.payload;
        //     return draft;
 
        case SET_IS_DRAGGING:
            draft.isDragging = action.payload;
            return draft;
        
        case SET_PAGE_SCALE:
            draft.scaleSource = action?.payload?.source;
            draft.pageScale = action.payload.scale;
            return draft;

        default:
            return canvas;
    }; //switch
});
export default canvasReducer;

// export const getCap = state => state.present.options.caps;
export const getBreakpoints = state => state.present.canvas.breakpoints;

export const getCanvasBreakpoint = state => state.present.canvas.canvasBreakpoint;
export const getPageScale = state => state.present.canvas.pageScale;
export const getScaleSource = state => state.present.canvas.scaleSource;

export const getCanvasTheme = state => state.present.canvas.theme;
export const getScreensSelector = state => state.present.canvas.screens;

export const isSmaller = (state, bp) => {
    const previewPoints = getBreakpoints(state)
    const canvasBreakpoint = getCanvasBreakpoint(state)

    let hit = 0;
    let current = 0;
    Object.entries(previewPoints).reverse().forEach(([key, value], index) => {
      if(key === bp) hit = index
      if(canvasBreakpoint === key) current = index;
    });
    return hit < current;
  }

export const getContainerOffset = (breakpoint) => {
    switch (breakpoint) {
      case 'xs': return 5;
      case 'sm': return 25;
      case 'md': return 50;
      case 'lg': return 69;
      case 'xl': return 90;
      case 'default': return 100;
      default: return 0;
    }
  }

const getBreakpointWidth = (bp, breakpoints, padding = 0) => isNumber(breakpoints[bp]) ? parseFloat(breakpoints[bp]) + padding : 0;

export const getContainerMaxWidth = (state, bp = 'auto', maxWidth = true) => {
    const breakPoints = getBreakpoints(state)
    const canvasBreakpoint = getCanvasBreakpoint(state)
    if(bp === 'auto' || bp === 'xl') bp = canvasBreakpoint;
    if(maxWidth) return {maxWidth: (breakPoints[bp] - getContainerOffset(bp))+'px'};
    if(bp === SMALLEST_BP) return {width: '350px'};
    return {width: (breakPoints[bp] - getContainerOffset(bp))+'px'};
}

const isBpLarger = (value, width) => value <= width;
const isBpSmaller = (value, width) => value >= width;

export const getBreakPointByWidth = (width, breakpoints) => {
  let previous, start, current, next, index = 0;
  const last = Object.entries(breakpoints).length;

  Object.entries(breakpoints).map(([key, value])=>{
    index++;

    if(isBpLarger(value, width) && !current)
      previous = key;
    
    if(isBpSmaller(value, width) && !current){ 
      current = previous;
      next = key;
    }

    if(!current && index === last)
      current = previous;
  });

  return current;
}  
