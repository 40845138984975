import { ControlPopper } from "@mosanic/items";
import { BlockButton } from "../Components";
import { CenterCenter } from "@mosanic/styles";


const ControlGroup = ({triggerProps, group, reversed = false, placement = 'top'}) => (
    <ControlPopper style={{justifyContent: 'space-around', display: 'flex',}}
        reversed={reversed} placement={placement}
        trigger={<BlockButton {...triggerProps} />}
    >
        {group}
    </ControlPopper>
);

export {
    ControlGroup
};
