/* eslint-disable no-nested-ternary */

import { PAGES } from '@api/queries/pages';
import { useLazyQuery } from '@hooks/useApi';
import { useEffect } from 'react';

export const usePages = ({siteId, getData = false, admin = false, countOnly = false, pagesOnly = false}) => {
	const [getPages, [pages, loading, error]] = useLazyQuery('pageMany', PAGES, siteId === 'admin' ? undefined : {siteId});

	useEffect(() => {

        if(siteId) getPages({filter: {siteId} ? {
			siteId
		} : !admin ? {
			siteId: 'msncc0re'
		} : undefined});
		
    },[siteId])

	if(pagesOnly) return pages;
	if(countOnly) return pages?.length ? pages?.length : 0
	if(getData) return [getPages, [pages, loading, error]]

	if(loading) return loading;
	if(error) return error;

	return pages ? 'list' : null
};
