import { pathValue } from "@mosanic/update";
import {
    updateCurrentField,
    updateCurrentFields,
    updateItemField,
    updateItemFields
} from "@redux/update/action";
import { isEmptyString } from "@util/Text";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

//Single
export const useFieldHandler = ({path, state = 'base', itemPath = null}, canIncludeState = true, locale = null) => {
    const dispatch = useDispatch();
   
    //Dispatch handling
    const dispatchUpdate = useCallback((newValue, includeState = true) => {
        // if(!newValue) return;
        const overwrite = isEmptyString(state) ? 'base' : state;

        let updatePath = (includeState && canIncludeState) ? `${path}.${overwrite}` : path;
        let value = newValue;
        
        if(newValue?.extend || locale) {
            if(newValue.extend) updatePath += `.${newValue.extend}`
            if(locale) updatePath += `.${locale}`
            value = (locale) ? newValue : newValue.value;
        }

        if(locale && !value) return;

        dispatch(itemPath ?
            updateItemField(pathValue({path: updatePath, value}), itemPath) :
            updateCurrentField(pathValue({path: updatePath, value}))

        );
    },[state, canIncludeState, path, locale, dispatch, itemPath])

    return dispatchUpdate;
}

//Multiple
export const useFieldsHandler = ({path, itemPath = null}) => {
    const dispatch = useDispatch();
   
    //Dispatch handling
    const dispatchUpdate = useCallback((object) => {
        dispatch(itemPath ?
            updateItemFields(pathValue({path, object}), itemPath) :
            updateCurrentFields(pathValue({path, object}))

        );
    },[dispatch, itemPath, path])

    return dispatchUpdate;
}
