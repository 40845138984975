
import { COLLECTION } from "@api/queries/collections";
import { useLazyQuery } from "@hooks/useApi";
import { AuthForm } from "@mosanic/auth/AuthForm";
import { Entry } from "@mosanic/core/Collections";
import { usePageContext } from "@mosanic/core/Provider";
import { getSchema } from "@mosanic/fields/Source/helpers";
import { useEffect } from "react";
import FormProvider from "./FormProvider";



 
const useCollection = ({form}) => {
    let [handler, [collection, loading, error]]  = useLazyQuery(
        'collectionById', 
        COLLECTION
    );
    useEffect(() => {
        if(form?.collection && !error) handler({_id: form?.collection})
        
    },[form?.collection])

    return [collection, loading]
}

const Form = ({fields, ref, editor, active = false, clone}, children) => {
    const {source, handler} = editor;

    const {form, when, advanced, login, verifyEmail, registerLink, forwardSlug, isEditing} = source || {
        form: null, 
        advanced: false, 
        login: false,
        verifyEmail: true,
        registerLink : null,
        forwardSlug : null,
    };

    const handleInput = (e) => handler({value: !isEditing, extend: 'isEditing'});

    const page = usePageContext()
    const page_props = {author: page?.entry?.userId, entryId: page?.entry?._id}
    
    let [collection] = useCollection({form})
    

    return login ? (
        <AuthForm verify={verifyEmail} />
    ) : !advanced ? (
        <Entry 
            cols={1} 
            collection={collection} 
            type="create" 
            dataType="form" 
            submitTrigger={children} 
            page_props={page_props} 
            hideMeta 
            hideDisabled
        />
    ) : (
        <FormProvider collection={collection} when={when?.collection === form?.collection ? when : null}>

            {children}
        {/* {JSON.stringify({isEditing})} */}
        {/* <button onClick={() => handleInput()}>test</button> */}
            {/* 
            
            // todo --> render these data links by default,
            // ? ----> when: isEditing === true -> render input fields
            // ? ---> make 'base entry fields' viewable
            //          --> ex. 'author field' published field


            // todo --> fix render methods for :
                // ? - selector
                // ? - relation
                // ? - checkboxes
                // ! : semi in the <Input/Fields.../>
                //          --> so these can render: builder/components (for each type)
            */}
        </FormProvider>
    )
} 

export default Form;