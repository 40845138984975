
import { getImageUrl } from "@api/host";
import { parseImageSrc } from "@mosanic/utils/Image";
import { PersonIcon } from "@radix-ui/react-icons";
import { default as s } from "./Users.styles";

const getImg = user => parseImageSrc(user.profilePicture, true)?.thumb
const UserCard = ({user, width = 20, height = 20, mr= 2, fallback = 'https://mosanic-cdn.ams3.digitaloceanspaces.com/62e7a50082b1bd8d84a13845/thumb_mosanic.jpg', ...other}) => (
    <s.Card {...other} mr={mr}>
        {(getImg(user) || user?.img) ? (
            <s.Avatar 
                alt="user icon"
                width={width}
                height={height}
                src={getImg(user) || user.img}
            />
        ): (
            <PersonIcon />
        )}

        {/* <span className="nameLabel">
           {user.firstName} {user.lastName}
        </span> */}
    </s.Card>
)

export default UserCard;