
import { Condition } from "@JSX";
import { elementIcon } from "@mosanic/Icons";
import renderComponent from "@mosanic/builder/registery";
import { splitPath } from "@mosanic/utils/map/paths";
import { ChevronDownIcon, ChevronRightIcon, DotIcon } from "@radix-ui/react-icons";
import { hoverBoundsSelector } from "@redux/bounds/reducer";
import { selectInspectItemPath } from "@redux/inspect/reducer";
import { getAutoOpenLayers } from "@redux/options/reducer";
import { capitalizeFirst } from "@util/Text";
import { useState } from "react";
import { useSelector } from "react-redux";
import { default as s } from "./Styles/Layer.styles";


const isHigher = (activeInspect, itemPath) => activeInspect?.length >= (itemPath?.length + 1)

const validatePaths = (activePath, itemPath) => {
    let toValidate = [...activePath]
    toValidate.length = itemPath?.length 

    const isClosed = (
        activePath[0] != itemPath[0] ||
        toValidate.toString() != itemPath.toString()
    )

    const isActive = activePath.toString() === itemPath.toString();

    const isOpen = (
        open || isActive || isHigher(activePath, itemPath) 
    ) && !isClosed

    return [isActive, isOpen]
}



const Layer = ({props}) => {
     const {item, child, path, level, breakpoint, hover, inspect, ref} = props;
    const autoOpen = useSelector(getAutoOpenLayers)
    const {onClick, contextMenu, setContextMenu, isInspected} = inspect;

    let Render = renderComponent(item?.type) 
    const inspectedPath = useSelector(selectInspectItemPath)


    const toggleOpen = (e) => onClick(e, true);
     
    const {map} = useSelector(hoverBoundsSelector)
    const activePath = splitPath(map?.path) ? splitPath(map?.path) : [];
    const activeInspect = inspectedPath ? splitPath(inspectedPath) : [];
    const itemPath = splitPath(path);

    const [isActive, isOpenInspect] = validatePaths(activeInspect, itemPath)
    const [isHovered, isOpenHover] = validatePaths(activePath, itemPath)

    const isOpen = isOpenInspect || isOpenHover
    return (
        <s.Layer 
            level={level} 
            ref={ref} {...hover} 
            open={isOpen} 
            hasChildren={props?.children} 
        >
            <s.Label 
                isOpen={isOpen}
                onClick={toggleOpen}
                active={isActive} 
                hovered={hover.hoverState}
                hasChildren={props?.children}
            >

                {!props.children ? (
                    <DotIcon />
                ) : isOpen ? (
                    <ChevronDownIcon />
                ) : (
                    <ChevronRightIcon />
                )}

                {elementIcon(item?.type)} 
                {item?.title ? item.title : capitalizeFirst(item?.type)}
            </s.Label>

            {isOpen && (
                <Condition when={Render}
                    wrapper={children => (
                        <s.Render level={level + 1}>{children}</s.Render>
                    )}
                >
                    {props?.children}
                </Condition>
            )}

        </s.Layer>
    ) 
}
export default Layer;