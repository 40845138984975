import { baseSchemaFields, convertColumnsToSchema } from "./base";

export const mailColumns = [{
    name: 'title',
    type: 'text'
},{
    name: 'description',
    type: 'text'
},{
    name: 'type',
    type: 'string'
},{
    name: 'defaultSender',
    type: 'string'
},{
    name: 'collectionId',
    type: 'text'
},{
    name: 'entries',
    type: 'text'
},
...baseSchemaFields
]

const shouldGet = f => !'_id|entries'.split('|').includes(f?.name)

const overwrite = f => {
    if(f?.name === 'Biography') return {...f, type: 'textarea'}
    return f;
}
export const mailsSchema = {
    groups: [
        'base',
        'schema',
        'variables',
        'templates',
        'log'
    ],
    props: {
        multiple: 'mails',
        singular: 'mail',
        // types: list_roles,
        info: {
            title: 'Mails',
            label: "Manage your platform mail templates",
            help: "Create mail templates which can be triggered manual or by a entry edit/ creation.",
            slug: [{href: '/site', label: 'Site'}],
        },
        empty: {
            title: 'No mail templates found.',
            label: "Let's start with creating your site's first mail template."
        },
        controls: {
            create: 'create',
            update: 'update',
            delete: 'delete',
            view: 'view',
        },
        routing: {
            basePath: 'mail',
        },
        editor: {
            schema: false,
            entry: 'editor_provider'
        },
        entries: false
    },
    base: [
        
        ...convertColumnsToSchema(mailColumns).filter(f => shouldGet(f)).flatMap(f => overwrite(f)),
         
        {
            name: 'collectionId',
            label: 'Linked Collection',
            type: 'collections',
            base: true,
            fullWidth: true
        },{
            name: 'type',
            label: 'Template type',
            type: 'text',
            base: true,
            fullWidth: true
        },{
            label: 'Mail config',
            name: 'data',
            schema: [{
                name: 'senderName',
                label: 'Sender name',
                type: 'text'
            },{
                name: 'templateId',
                label: 'Template id',
                type: 'text'
            },{
                name: 'defaultSender',
                label: 'Default sender',
                type: 'email'
            },{
                name: 'defaultReceiver',
                label: 'Default receiver',
                type: 'email'
            }]
        }
    ],
    schema: [{
        name: 'template_name',
        type: 'text'
    },{
        name: 'subject',
        type: 'text'
    },{
        name: 'preHeader',
        type: 'text',
    },{
        name: 'content',
        type: 'visual',
    },{
        name: 'content',
        type: 'visual',
    }]
}