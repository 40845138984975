
import { Condition } from '@JSX';
import { PreviewDynamicPage } from '@mosanic/core/Page';
import { Col, Wrap } from '@mosanic/styles';


export const PreviewDynamicEntry = ({collection, entry, preview = false, children}) => (
    <Condition when={preview && collection?.baseRoute && collection?.dynamicPage}
        wrapper={children => (
            <Wrap>
                <Col width={[1, 1/2]} pr={4}>
                    {children}
                </Col>
                <Col width={[1, 1/2]} pl={4}>
                    <PreviewDynamicPage 
                        pageId={collection?.dynamicPage} 
                        baseRoute={collection?.baseRoute}
                        entry={entry} 
                        />
                </Col>
            </Wrap>
        )}
    >
        {children}
    </Condition>
);

