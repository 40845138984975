


//Global mode
export const isDevMode  = (mode) => (mode === 'dev' || mode === 'layers')
export const isProdMode = (mode) => (mode === 'prod' || mode === 'live')
export const isSeoMode  = (mode) => (mode === 'seo')

export const isItemCopied   = item => item?.isCopied
export const isItemCut      = item => item?.isCut
export const isUnhandledActionItem = item => isItemCopied(item) || isItemCut(item)

export const isPreStoreCut = preStore => preStore?.isCut