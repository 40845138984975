import { addonsMap } from "@mosanic/content/addons";
import { subscriptionPlans } from "@mosanic/content/membership";
import { getPrice } from "@mosanic/utils/products";
import { upperCase } from "@util/Text";

const addProductPrice = (product, price) => ({
    ...product,
    priceId: price?.id,
    'price_data': {
        tax_behavior: price?.tax_behavior,
        recurring: {
            interval: price?.recurring.interval,
            interval_count: price?.recurring.interval_count,
        }
    },
    price: price?.unit_amount,
    currency: upperCase(price?.currency)
})
export const formatProduct = product => ({
    ...product,
    prices: product?.prices?.map(p => ({
        id: p.id,
        interval: p.recurring.interval,
        price: p.unit_amount,
    })),
    default_price: undefined,
    package_dimensions: undefined,
    tax_code: undefined
})
const parseProduct = (product, annual) => formatProduct(
    addProductPrice(
        product, 
        getPrice(product.prices, annual)
    )
)

export const parseProducts = (products, annual, source) => source.map(product => parseProduct({
    ...product,
    ...products.find(p => p.id === product.id)
}, annual));

export const parseMemberships = (products, annual) => parseProducts(products, annual, subscriptionPlans);
export const parseAddons = (products, annual) => parseProducts(products, annual, addonsMap)
