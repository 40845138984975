import cookieCutter from 'cookie-cutter'
// import Cookies from 'cookies'

/**
 * Save data in Client Cookie Storage
 * @param {string} name - key for data
 * @param {string} data - data to store
 */
function saveCookie(name, data) {
	if(!recoverCookie(name)) cookieCutter.set(name, data);
}

/**
 * Recover data from Cookie Storage
 * @param {string} name - key for data to recover
 */
function recoverCookie(name) {
	if (typeof window != 'undefined') {
		return cookieCutter.get(name) ? cookieCutter.get(name) : null
	} else {
		return null;
	}
}

/**
 * Delete all data in Session Storage
 * @param {string} name - key for data to delete
 */
function deleteCookie(name) {
	if(recoverCookie(name)) cookieCutter.set(name, '', { expires: new Date(0) })
}


export {
	saveCookie,
	recoverCookie,
	deleteCookie,

	// saveServerCookie,
	// recoverServerCookie,
	// deleteServerCookie,
};