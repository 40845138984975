
import { featureSelector } from '@redux/baseSelector';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import {default as actions} from './action';
const initialState = {

};


const dropReducer = (drop = initialState, action) => produce(drop, draft => {

    switch (action.type) {
        case HYDRATE:
            return {...drop, ...action.payload};

        case actions.DROP_HANDLE:

            return drop;

        case actions.DROP_PRE_STORE:
            draft.pre_store = action.payload;
            return draft;

        case actions.DROP_CLEAR_STORE: 
            draft = {};
            return draft;

        default:
            return drop;
    }; //switch
});
export default dropReducer;


const dropSelector = state => featureSelector(state, 'drop');

export const preDropStoreSelector = state => dropSelector(state)?.pre_store