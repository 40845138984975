import { HYDRATE } from "next-redux-wrapper";
import {UPDATE_ORDER} from "./orderAction";

const initOrder = {
  date  : null,
  bookId: null,
  email : 'test@gmail.com'
};

const orderReducer = (order = initOrder, action) => {

  switch (action.type) {
    case HYDRATE:
      return {...order, ...action.payload};
    case UPDATE_ORDER:
      return {...order, ...action.payload};

    default:
      return order;
  }
}
export default orderReducer;