
// import { default as CodeEditor } from '@mosanic/fields/Code';
import dynamic from 'next/dynamic';
const CodeEditor = dynamic(() => import('@mosanic/fields/Code/Code'),{
    ssr: false
})

const Code = ({editor, active = false}) => {
    const {source, handler} = editor;

    const handleInput = (value) => {
        if(value){
            handler({value, extend: 'content'})
        }
        
    }

    return (
        <>
        {active ?         
            <CodeEditor 
                onChange={handleInput} 
                language={source?.language ? source.language : 'js'}
                value={source?.content}
                active={active}
            />  
            : <span dangerouslySetInnerHTML={{ __html: source?.content }} />
            }
        </>
    )
}
export default Code;