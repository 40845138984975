import { createContext } from "react";
import { useContextHandler } from "./Helpers";

const ModeContext = createContext(undefined);
const DataContext = createContext(undefined);
const CartContext = createContext(undefined);
const RepeatContext = createContext(undefined);
const CloneContext = createContext(undefined);
const RelationContext = createContext(undefined);
const FormContext = createContext(undefined);
 
export const ModeProvider = ({data, children}) => (
    <ModeContext.Provider value={data}>
        {children}
    </ModeContext.Provider>
) 

export const DataProvider = ({data, children}) => (
    <DataContext.Provider value={data}>
        {children}
    </DataContext.Provider>
) 
export const CartProvider = ({data, children}) => (
    <CartContext.Provider value={data}>
        {children}
    </CartContext.Provider>
) 
export const RepeatProvider = ({data, children}) => (
    <RepeatContext.Provider value={data}>
        {children}
    </RepeatContext.Provider>
)

export const RelationProvider = ({data, children}) => (
    <RelationContext.Provider value={data}>
        {children}
    </RelationContext.Provider>
) 

export const FormProvider = ({data, children}) => (
    <FormContext.Provider value={data}>
        {children}
    </FormContext.Provider>
) 

export const useModeProvider = (validate = false) => useContextHandler(ModeContext, 'ModeContext', validate)
export const useDataProvider = (validate = false) => useContextHandler(DataContext, 'DataContext', validate)
export const useCartProvider = (validate = false) => useContextHandler(CartContext, 'DataContext', validate)
export const useRepeatProvider = (validate = false) => useContextHandler(RepeatContext, 'RepeatContext', validate)
export const useCloneProvider = (validate = false) => useContextHandler(CloneContext, 'CloneContext', validate)
export const useRelationProvider = (validate = false) => useContextHandler(RelationContext, 'RelationContext', validate)

export const useFormProvider = (validate = false) => useContextHandler(FormContext, 'FormContext', validate)