
import { HANDLE_HOVER, setHoverElement, toggleLastHover } from "./action";


const isOnMouseLeave = (meta) => !meta.state

export const handleHoverElementFlow = ({getState, dispatch}) => next => action => {
  next(action);

  if (action.type === HANDLE_HOVER)
    isOnMouseLeave(action.meta) ? dispatch(toggleLastHover(action)) : dispatch(setHoverElement(action));
    
};

export const handleResetHoverBounds = ({getState, dispatch}) => next => action => {
  next(action);

  // if (action.type === RESET_HO VER_BOUNDS) {
  //   // if(hasScreenBoundsSelector(getState())) dispatch({type: CLEAR_INSPE CT_BOUNDS})
  //   // if(!hasHoverBoundsSelector(getState())) return;
  //   next(action);

  // } else {
  //   next(action);
  // }
};

export const boundsMdl = [
  handleHoverElementFlow,
  handleResetHoverBounds
];
