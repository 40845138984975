import { ReactNode } from "react";
import {useInView} from 'react-intersection-observer';

type InViewProps = {
    children: ReactNode | any
    height: number
}

export default function InView({children, height}: InViewProps){
    const [ref, inView] = useInView({threshold: 0, triggerOnce: true});
    return (
        <div ref={ref} style={{height: '100%', width: '100%'}}>
            {inView && children}
        </div>
    )
}