import { COLLECTIONS_COUNT } from "@api/queries/collections";
import { ENTRY } from "@api/queries/entries";
import { isAuthenticated } from "@api/token";
import { useLazyQuery } from "@hooks/useApi";
import { getEntryDescription, getEntryLabel } from "@mosanic/fields/Source/helpers";

import { useNotify } from "@mosanic/hooks";
import { isClone } from "@mosanic/utils/value";
import { siteIdSelector } from "@redux/auth/reducer";
import isEmpty from "@util/Empty";
import { isString, toLowCase } from "@util/Text";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const convertEntry = entry => ({
    ...entry, 
    ...entry?.data, 
    ...entry?.map, 
    ...entry?.filters, 
    ...entry?.props, 
    map: undefined, 
    data: undefined,
    filters: undefined,
    props: undefined 
})

const getFields = (schema, validate = field => field.inMap) => ([
    schema?.[0]?.name, 
    ...schema?.filter(f => validate(f))?.map(f => f?.name)
])

const moveFields = ({entry, fields, property, remove = false}) => {
    fields.map(field => {
        entry = {
            ...entry, 
            [property]: {
                ...entry?.[property], 
                [field]: entry[field]
            },
            [field]: remove ? undefined : entry[field]
        }
    })
    return entry;
}

const removeFields = ({entry, fields}) => {
    fields.map(field => {
        entry = {...entry, [field]: undefined}
    })
    return entry
}

export const convertEntryBack = (data, schema) => {
    let entry = {
        ...data,
        map: {}
    }

    entry = moveFields({entry, fields: ['author', 'id', 'location_id', 'sys_group', 'accommodation_type_category'], property: 'props', remove: true})

    const imageFields = schema?.filter(f => f?.type === 'image');
    if(imageFields?.length >=1 ) {
        entry = {
            ...entry,
            image: data[imageFields[0]?.name]
        }
    }
    entry = moveFields({entry, 
        fields: getFields(schema, f => f?.filterable), 
        property: 'filters', 
    })
    entry = moveFields({entry, 
        fields: getFields(schema, f => !f?.inMap), 
        property: 'data'
    })
    entry = moveFields({entry, 
        fields: getFields(schema, f => f?.inMap), 
        property: 'data'
    })
    entry = moveFields({entry, 
        fields: getFields(schema, f => f?.inMap), 
        property: 'map'
    })



    entry = removeFields({entry, 
        fields: getFields(schema, f => f), 
    })

    entry = {
        ...entry,
        title: getEntryLabel(data, schema),
        description: isString(getEntryDescription(data, schema)) ? getEntryDescription(data, schema) : '...',
        queryId: data?.queryId,
        userId: data?.userId,
        updatedAt: new Date(),
        image_title: undefined,
        image_desc: undefined,
        image_link: undefined,
        accommodation_status: undefined,
    }

        

    entry = removeFields({entry, fields: ['short_description', 'align_main_image', 'sys_type_bb', 'sys_type_bb_plus', 'sys_type_glamping']})
    
    
        //TODO --> Ûnder de Wol --> no host && accommodation _id
        // Boutique Yurt Puck --> images
        // Slapen bij Hein -> images 
        // Boutique B&B Locanda Toscanini --> no host
        // Bed & Broodje Ouddorp --> no host
        // Tiny House Damhert --> no host
        // Villa Sabandari --> no host


    Object.keys(entry).forEach(key => entry[key] === undefined && delete entry[key])

    // ! dep
    // ! entry = moveFields(entry, getFields(schema, f => f?.name === 'sys_activities'), 'data')

    return entry
}
 
const useDataQuery = ({
	callback = null, 
	redirect = null, 
	type = 'entry',

    action = 'get',
    method = 'ById',
	query = ENTRY,
    formatter = data => convertEntry(data),

	loggedInCheck = false,
	siteCheck = false,
    silent = false,
}) => {
	const router = useRouter()
	const shouldRedirect = Boolean(redirect && isString(redirect))
    const siteId = useSelector(siteIdSelector)
    const isAuth = isAuthenticated()

    const [ dispatch, [data, loading, error]] = useLazyQuery(`${type}${method}`, query)

    const notify = useNotify();

    const handler = (params) => {

		if(!isAuth && loggedInCheck && false) {notify(`You are not authenticated to ${action} a ${type}.`);return;}
        if(!siteId && siteCheck && false) {notify('No active site selected.');return;}


        // if(method === 'ById' && (!params?._id)) console.log('no params!')
    
		dispatch(params).then((res) => {
            
			let {data, error} = res;

			if(!error && data){
                if(!silent) notify(`Success: ${toLowCase(`${action} ${type} ${method}`)}.`, 'app', 'success')
                
                if(callback) callback(data);
                if(shouldRedirect) router.push(data?.recordId ? `${redirect}/${data.recordId}` : redirect);

                if((isClone(data?.data) || data?.data) && formatter) data = {
                    ...formatter(data),
                }
			} else if (error) {
				if(!silent) notify(`An error occurred during the ${type} ${action}.`)
				console.log({error, params, method, type, action});
			}

		})
    }
    
    return [handler, [data, loading, error]];
}

export const useGet = ({
	callback = null, 
	redirect = null, 
	type = 'entry',
    method = 'ById',
	query = ENTRY,

	loggedInCheck = true,
	siteCheck = true,
    alert = false,

    params = null,
    silent = false,
    shouldHaveParams = true,
}) => {
    const [calledParams, setCalledParams] = useState()

    const [handler, [data, loading, error]] = useDataQuery({
        callback,
        redirect,
        method,
        type,
        query,
        loggedInCheck,
        siteCheck,
        alert,
        action: 'get',
        silent,
    });

    useEffect(() => {
        if(
            JSON.stringify(params) === JSON.stringify(calledParams) ||
            (method === 'ById' && !params?._id) ||
            (method === 'Pagination' && !params?.filter) 
        ) {
            return;
        }
        if(params || !calledParams ) {
            handler(params);
        }
        setCalledParams(params);

    },[params, calledParams, shouldHaveParams, method, handler]);

    return [handler, [data, loading, error]] 
}

export const useGetCount = ({
    type = 'collection',
    query = COLLECTIONS_COUNT,
    params = null,
    site_id,
}) => {
    const siteId = useSelector(siteIdSelector);


    let [getCount, [count, loadingCount, errorCount]] = useGet({
        query,
        type,
        action: '',
        method: 'Count',
        params: params ? params : siteId ? {filter: {siteId: site_id ? site_id : siteId}} : null,
        silent: true
    });


    return [count, loadingCount, errorCount, getCount]
}

