
import { inspectSelector } from '@redux/inspect';
import { addOptions, includesAction, replaceType } from '@redux/util';
import { dset } from 'dset';
import { updateIds, UPDATE_ID } from './action';
const actions = updateIds.action;
const field = updateIds.field;
const item = updateIds.item;

import { validateMetric } from '@mosanic/update/metric';
import { dataActions as data, itemSelector } from '@redux/map/data';
import { setNotification } from '@redux/notification/action';
import { replacePayload } from '@redux/util/action';
import { get } from '@util/Obj';
import { replacePart } from '@util/Text';
import produce from 'immer';

import { klona } from 'klona';

export function deepset(obj, path, val) {
    console.log({obj, path, val })
  let copy = klona(obj);
  dset(copy, path, val);
  return copy;
}

const handleUpdateActionFlow = ({getState, dispatch}) => next => action => {
  next(action);

    if(includesAction(action, actions.start)){ 
        const state = getState();
        const payload = action.payload;
        const itemPaths = [];

        //Get items
        if(action.meta.item === item.current){
            const {_id, group} = inspectSelector(getState())
            if(_id && group) itemPaths.push({_id, group});

        } else if(payload?.other?.itemPath) {

            itemPaths.push(payload.other.itemPath);
        }  else if(payload?.other?.itemPaths) {
       
            itemPaths.push(...payload.other.itemPaths);
        }

        //Next action type
        const HandleActionType = replacePart(action.type, actions.start, actions.handle);

        if(itemPaths.length <= 0) {
            dispatch(setNotification({message: 'Could not execute update, no items selected.', feature: UPDATE_ID}))
            return;
        }

        //Update items
        itemPaths.forEach(itemPath => {
                                                    // todo --> item object
            const component = itemSelector(state, itemPath);
            // console.log(component)
            action = replaceType(action, HandleActionType);
            action = addOptions(action, action.payload);
            action = replacePayload(action, component);
            // console.log(action)
            dispatchUpdateActionFlow(action, dispatch);
        });
    }
};

const checkValue = (value) =>
    value === 'true' ? 1 : 
    value === 'false' ? 0 : 
    value === 'clear' ? null :
    value
    
const dispatchUpdateActionFlow = (action, dispatch) => {

    if(includesAction(action, actions.handle)){ 
        const options = action.options;
        let newValue = options.value;

        const component = produce(action.payload, draft => {

            if(includesAction(action, actions.update)) {
                //Multiple path values
                if(Array.isArray(options)){
                    options.forEach(pathValue => {
                        dset(draft, pathValue.path, checkValue(pathValue.value));
                    })
                } else {
                    //Single

                    //Check if action changes metric
                    if(includesAction(action, field.metric)){
                        //Check if metric has metric set
                        newValue = validateMetric({
                            metric: get(draft, options.path), 
                            updated: newValue
                        });
                    } 
                    //Update
                    // if(draft && get(draft, options.path)){
                    //     console.log(newValue)
                        dset(draft, options.path, checkValue(newValue));

                    //     // console.log('found get & update [!}')
                    // } else {
                    
                    //     //Set empty data object
                    //     dset(draft, options.path.substr(0, options.path.lastIndexOf(".")), {});

                    //     dset(draft, options.path, checkValue(newValue));
                      
                    // }
                }
                

            }
            if(includesAction(action, actions.push)) {
                
            }
            if(includesAction(action, actions.pop)) {
                
            }
            if(includesAction(action, actions.remove)) {
                
            }
            if(includesAction(action, actions.toggle)) {
                
            }
            if(includesAction(action, actions.set)) {
                
            }
        })

        dispatch(data.updateDataAction({component}, true))      
    }
};



export const updateMiddleware = [
    handleUpdateActionFlow
];