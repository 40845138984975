

import { COLLECTION } from "@api/queries/collections";
import { useLazyQuery, useVarQuery } from "@hooks/useApi";
import { isString } from "@util/Text";
import { useEffect, useCallback } from "react";
import { convertEntry, useEntries, useEntry } from "./useEntries";
import { useGetCollection, useGetEntries } from "@mosanic/cms/Query";

const convert = convertEntry

 

const useRetrieveEntries = (params  = null) => {
    const [entries, loading, error] = useGetEntries({...params})
    return Array.isArray(entries) ? entries.map(entry => convert(entry)) : []
}

const useRetrieveEntry = (_id = null) => {
    const [handleEntry, [entry, loadingEntry, errorEntry]] = useEntry({_id})

    return entry ? [convert(entry)] : [];
}

export const useCollection = ({_id, singleEntry = false, entryId = null}, retrieveEntries = false) => {
    let [collection, loading, error] = useGetCollection({_id, silent: true}) 

    const setEntries = data => ({...collection, entries: data})


    //Validate external api call
    const linkedEntries = !Array.isArray(collection?.entries) ;
    const getEntries = Boolean(retrieveEntries && linkedEntries)

    
    //Get linked entries
    const [entries, loadingEnt, errorEnt] = useGetEntries({collectionId: _id})

    const entry = useRetrieveEntry((
        getEntries && (singleEntry || entryId)
    ) && (
        entryId || collection?.entries?.[0]
    ))

    


    //Format entries return
    if(getEntries && !loading) collection = setEntries(entries);
    if(singleEntry && !loading) collection = setEntries([collection?.entries?.[0]])

    return [() => null, [collection, loading, error]];
}
