import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import { Transforms } from "slate"
import { ReactEditor, useFocused, useSelected, useSlateStatic } from "slate-react"
import Button, { Buttons } from "../Button/Buttons"

const SlateImage = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        style={{position: 'relative'}}
        contentEditable={false}
      >
        <img
          src={element.url}
          style={{maxHeight: '20em', width: 'auto'}}
        //   className={css`
        //     display: block;
        //     max-width: 100%;
        //     max-height: 20em;
        //     box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
        //   `}
        />
        {selected && (
            <Buttons style={{position: 'absolute', top: 5, left: 0, zIndex: 2}}>
                <Button
                    active ghost
                    onClick={() => Transforms.removeNodes(editor, { at: path })}
                    icon={<TrashIcon />}
                />
                <Button
                    active ghost
                    onClick={() => Transforms.removeNodes(editor, { at: path })}
                    icon={<Pencil1Icon />}
                />
            </Buttons>
        )}
      </div>
    </div>
  )
}
export default SlateImage;