import { gql } from '@apollo/client';


// export const EDIT_USER = gql`
// mutation editUserAccount($_id: String!,$firstName: String!, $lastName: String!, $bio: String!) {
// 	editUserAccount (_id: $_id,firstName: $firstName, lastName: $lastName, bio: $bio) {
// 		email
// 	}
// }
// `;

export const EDIT_PASSWORD = gql`
mutation editUserPassword($_id: String!, $password: String!, $oldPassword: String!) {
	editUserPassword (_id: $_id, password: $password, oldPassword: $oldPassword) {
		email
	}
}
`; 
export const UPDATE_USER = gql`
mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
	userUpdateById(_id: $_id, record: $record) {
		recordId
		error {
			message
		}
	} 
}
`;

export const UPDATE_USERS = gql`
mutation userUpdateMany($record: UpdateManyUserInput!) {
	userUpdateById(record: $record) {
		numAffected
		error {
			message
		}
	} 
}
`;


export const REMOVE_USERS = gql`
mutation userRemoveMany($filter: FilterRemoveManyUserInput!) {
	userRemoveMany (filter: $filter) {
		numAffected
		error {
			message
		}
	}
}
`;

export const CREATE_USER = gql`
mutation userCreateOne($record: CreateOneUserInput!) {
	userCreateOne(record: $record) {
		record {
            _id
        }
	} 
}
`;

