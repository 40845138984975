import { reorder } from "@util/Arr";

 export const reorderChildren = ({children, dropPath, itemPath}) => {
    if (dropPath.length === 1) {
      const dropZoneIndex = Number(dropPath[0]);
      const itemIndex = Number(itemPath[0]);
  
      return reorder(children, itemIndex, dropZoneIndex);
    }
  
    const updatedChildren = [...children];
    const curIndex = Number(dropPath.slice(0, 1));
  
    // Update the specific node's children
    const splitDropZoneChildrenPath = dropPath.slice(1);
    const splitItemChildrenPath = itemPath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
        ...nodeChildren,
        children: reorderChildren({
            children: nodeChildren.children,
            dropPath: splitDropZoneChildrenPath,
            itemPath: splitItemChildrenPath
        })
    };
  
    return updatedChildren;
  };


  export const handleMoveWithinParent = ({map, dropPath, itemPath}) => reorderChildren({children: map, dropPath, itemPath});
