import { ColIcons } from '@mosanic/Icons';
import { Tooltip } from '@mosanic/items';
import { useDispatch } from "react-redux";
import { COLUMN } from "../Adapters/constants";

const SelectLayout = ({rowData, passHandleClose, hideOthers = false}) => {

    // TODO receive --> this data as 'layouts'

    // ! Layouts are preset sections without much template content and styling.
    const data = [ 
        {
            id: ColIcons.oneCol,
            title: '1 column',
            cols: 1,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: '12', lg: '12', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
            
        },
        {
            id: ColIcons.twoCols,
            title: '2 columns',
            cols: 2,
            grid: [
                {
                    responsive: {xs: '12', sm: null, md: '6', lg: null, xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '12', sm: null, md: '6', lg: null, xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
            
        },{
            id: ColIcons.threeCols,
            title: '3 columns',
            cols: 3,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.fourCols,
            title: '4 columns',
            cols: 4,
            grid: [
                {
                    responsive: {xs: '6', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '6', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '6', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '6', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.sideBarLeftCol,
            title: 'Sidebar left + column',
            cols: 2,
            grid: [
                {
                    responsive: {xs: '2', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '10', sm: null, md: null, lg: '9', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.sideBarRightCol,
            title: 'Sidebar right + column',
            cols: 2,
            grid: [
                {
                    responsive: {xs: '10', sm: null, md: null, lg: '9', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: '2', sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.centerThreeCols,
            title: 'Column + Center + Column',
            cols: 3,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '6', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '3', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.fiveCols,
            title: '5 columns',
            cols: 5,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.topFullFourCols,
            title: 'Center top + 3 columns',
            cols: 4,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '12', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.bottomFullFourCols,
            title: 'Center bottom + 3 columns',
            cols: 4,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '4', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '12', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        },{
            id: ColIcons.customCols,
            title: 'Custom columns',
            cols: 6,
            grid: [
                {
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                },{
                    responsive: {xs: null, sm: null, md: null, lg: '2', xl: null},
                    offset:   {xs: null, sm: null, md: null, lg: null, xl: null},
                    display: {xs: true, sm: true, md: true, lg: true, xl: true},
                }
            ],
        }
    ]
   
    if(!rowData || rowData === null){
        rowData = {children:[{items:'none'}]}
    }

    const setRowData = (item) => {
        let index = 0;
        let ids = [];
        let pathValues = [];

        
        rowData.children.forEach(child => {
            ids.push(child._id)
            pathValues.push({
                path: 'layout.grid.responsive',
                value: item.grid[index].responsive,
                _id: child._id,
                type: COLUMN
            },{
                path: 'layout.grid.offset',
                value: item.grid[index].offset,
                _id: child._id,
                type: COLUMN
            },{
                path: 'layout.grid.display',
                value: item.grid[index].display,
                _id: child._id,
                type: COLUMN
            });
            index++;
        });


        // dispatch(bulkUpdateFields({
        //     pathValues, 
        //     rerenderIds: ids
        // }));

        if(passHandleClose)
            passHandleClose();
    }
    //// ${rowData.children.length === item.cols ? '-active' : ''}
    return ( 
       <div className="controlThumbnails -list -large">
            {data.map((item) => ((rowData.children.length === item.cols) || !hideOthers) ? (
                <div key={item?.id} className={`item thumbnail-wrapper`}  onClick={() => setRowData(item)}>
                    <Tooltip title={item.title}>
                        {/* <img className="img-fluid" src={item.id} alt={item.title} /> */}
                        {item.id}
                    </Tooltip>
                </div>
            ) : null)} 
        </div>
    )
}
export default  SelectLayout;