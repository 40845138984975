import { SEO_FRAGMENT } from '@api/fragments/pages';
import { gql } from '@apollo/client';

const SITE_FIELDS = ` 
	name
	description
	
	mode
	subdomain
	customDomain
	domains

	userId
	users {
		userId 
		role
	}
	teams {
		teamId
		role
	}
	team


	theme
	themeId
	locales
	${SEO_FRAGMENT}
	logo
	pages

	code
	header
	footer

	options
	schemas

	nav
	indexes
	dynamic

	tokens
	mixins
	breakpoints
	screens
	notifications

	createdAt
	updatedAt

	analytics {
		gaMeasurementId
		disableTracking
		respectPrivacy
		analyticsId
		referenceSlug
	}


	_id
`
export const DOMAINS = gql`
query siteMany{
	siteMany {
		name
      	subdomain
		customDomain
		domains
		nav
		indexes 
		dynamic 
		themeId
		footer 
		header
		locales
		logo
		code 
		theme
		analytics {
			gaMeasurementId
			disableTracking
			respectPrivacy
			analyticsId
		}

		_id
	}
}
`;

export const SITES = gql`
query siteMany{
	siteMany {
		${SITE_FIELDS}
	}
}
`;
export const FILTER_SITES = gql`
query siteMany($filter: FilterFindManySiteInput) {
	siteMany(filter: $filter){
		name
      	description
      	subdomain
		customDomain
		domains
      	userId
		pages
		team
		createdAt
		updatedAt
		users {
			userId 
			role
		}
		_id
	}
}
`;

export const SITE = gql`
query siteById($_id: MongoID!) {
	siteById(_id: $_id){
		${SITE_FIELDS}
	}
}
`;

export const PUBLIC_SITES = gql`
query siteMany($filter: FilterFindManySiteInput) {
	siteMany(filter: $filter){
		name
      	description
      	subdomain
		customDomain
		domains
	}
}
`;
export const PUBLIC_SITE = gql`
query siteById($_id: MongoID!) {
	siteById(_id: $_id){
		name
      	description
      	subdomain
		customDomain
		domains
	}
}
`;
export const SITE_TEAM = gql`
query siteById($_id: MongoID!) {
	siteById(_id: $_id){
		team
		customDomain
		domains
		subdomain
	}
}
`;

export const SITE_ONE = gql`
query siteOne($filter: FilterFindOneSiteInput) {
	siteOne(filter: $filter){
		name
      	description
      	subdomain
		customDomain
		domains
      	userId
		_id
	}
}
`;
export const VALIDATE_SUBDOMAIN = gql`
query siteValidateSubdomain($subdomain: String!){
	siteValidateSubdomain(subdomain: $subdomain) {
		response
	}
}
`;