import { getImageUrl } from '@api/host';
import CoreContext from '@context/CoreContext';
import { default as s } from '@mosanic/builder/Image/Image.style';
import { useUploadFactory } from '@mosanic/components/Media/Handlers';
import { Button } from '@mosanic/fields';
import VideoField from '@mosanic/fields/Video';
import { useNotify } from '@mosanic/hooks';
import { elementIcon } from '@mosanic/Icons';
import { AdvancedLabel, ControlDialog, LoadingDots } from '@mosanic/items';
import { parseImageSrc } from '@mosanic/utils/Image';
import { containsSubstr, matchSubstrList } from '@util/Text';
import Image from 'next/image';
import { useContext, useEffect, useState } from "react";
import ImageUploading from 'react-images-uploading';
import { MediaController } from './MediaController';
import { MediaListImage } from './MediaListImage';
import { GetListOfMedia } from './Resolvers';
import { Progress } from '@mosanic/items/Progress/Progress';


const MediaUploader = ({
    imageUrl, 

    type = 'image',
    maxNumber = 1, 
    autoUpload = false,
    callback = null,

    selector = false,
    inlineSelect = false,

    showEditor = false, 
    cover = true, 
    activeFolder = '',
    isRemote = true,
    onChange = null,

    shouldUploadAll = false,
    toggleUploadAll = null
}) => {

    const [images, meta, progress, handlers, loading, error] = useUploadFactory({url: imageUrl, callback});

    const notify = useNotify()

    const canUploadImage = Boolean(
        images?.length < maxNumber ||
        !images
    );

    //Store in local array
    const handleUpload = (imageList, addUpdateIndex) => {
        if(!addUpdateIndex) {
            notify("No update index found");
            return;
        }
        if(!imageList[0]){
            notify("No file found");
            return;
        }

        (autoUpload) ? 
            handlers.uploadFile(imageList[0], activeFolder) :
            handlers.setImages(imageList);
    };

    // const {uploadAll} = handlers;

    const cContext = useContext(CoreContext);

    const [mediaLib, setMediaLib] = useState(false)

    
    if(!showEditor) {
        if(loading) return loading;
        if(error) return error;

    }

    const url = isRemote ? 
        getImageUrl(imageUrl?.url ? imageUrl.url : imageUrl) :
        imageUrl?.url ? imageUrl.url : imageUrl

    

    const inlineCallback = (e) => {
        console.log(e)
        handlers.setImages([{url: e}])
        callback(e)
    }
  

    const Library = () => selector ? (
        <Button ghost
            handler={() => inlineSelect ? setMediaLib(true) : cContext.setMediaModal(true)}
            style={{top: '5px', zIndex: 4, position: 'absolute'}}
        >
            {elementIcon("archive")} &nbsp; Library
        </Button>
    ) : null;

    const source = parseImageSrc(imageUrl, true)?.min
    // if(loading) return <LoadingDots />
  return ((showEditor || !images) && !source) ? (
    <>
    {inlineSelect && (
        <ControlDialog state={mediaLib} close={() => setMediaLib(false)} size="md" fullWidth> 
            <GetListOfMedia header dialog initiate={inlineCallback} direct upload={false}/>
            <Button onClick={() => cContext.setMediaModal(false)} size="small" label="Close" />
        </ControlDialog>
    )}
    {type === 'video' ? (
        <>
        <Library/>
        <VideoField 
            onChange={(e) => handlers.uploadFile(
                e.target.files[0], 
                activeFolder
            )}
        />
        </>
    ) : (
        <>
    <Library/>
    <ImageUploading
        type="button"
      multiple
      value={images}
      onChange={handleUpload}
      maxNumber={maxNumber}
      dataURLKey="url"
    >
      {(actions) => (
        <s.Container type={type} className="input_field">
            <MediaController 
                type={inlineSelect ? 'input' : null}
                canUpload={canUploadImage}
                showEditor={showEditor}
                images={images}
                actions={actions}
            />
            {images?.length >= 1 ? (
                <s.UploadedList>
                    {images?.map((image, index) =>  image['url'] ? (

                        <MediaListImage 
                            type={type}
                            remote={containsSubstr(image.url, 'data:/image')}
                            index={index}
                            key={index}
                            image={image}
                            meta={meta && (meta?.length -1) >= index && meta[index]}
                            actions={{...handlers, ...actions}}
                            showEditor={showEditor}

                        />        
                
                    ): ( 
                        <s.UploadedListItem>
                            Image does not have a valid url. <br/>
                            Refresh page to reload.
                        </s.UploadedListItem>
                    ))}
                </s.UploadedList>
            ): (
                <AdvancedLabel>
                No images in upload queue.
                </AdvancedLabel>
            )}
        </s.Container>
      )}
      
    </ImageUploading>
    </>
    )}
    <Progress value={progress * 100} />
    {/* {progress ? JSON.stringify(progress * 100) : 'null'} */}
    {loading && <LoadingDots />}
    </>
  ) : (
  <s.DisplayImage type={type}>
    {source ? (
      <>
      {(type === 'video' && matchSubstrList(source, ['mp4', 'mov']) ) ? (
            <video 
            src={source} 
            alt={'Video'} 
            controls={true}
            />
        ) : (
            <Image 
                layout="fill"
                src={source} 
                alt={'test'} 
                objectFit={cover ? 'cover' : 'contain'}
            />
        )}
      </>        
    ): (
      <AdvancedLabel label="No url received." />
    )}
    </s.DisplayImage>
  )
};

export default MediaUploader;

           
             