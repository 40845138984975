const ID = '[artboard]'
export const ARTBOARD_ID = ID;

const ARTBOARD_SET      = `${ID} Set`;
const ARTBOARD_CLEAR    = `${ID} Clear`;
const ARTBOARD_ADD      = `${ID} Add`;
const ARTBOARD_SET_POINTER = `${ID} Pointer`;

export const addArtboardAction = ({artboard = null, map, data}) => ({
    type: ARTBOARD_ADD,
    payload: {artboard, map, data}
}) 
export const initArtboardAction = ({artboard, map, data}, handled = false, options = null) => { 
    return {
        type: `${ARTBOARD_SET} ${map.type}`,
        payload: {artboard, map, data},
        meta: {feature: ID},
        options
    }
};
export const setArtboardPointer = ({_id, group}) => ({
    type: ARTBOARD_SET_POINTER,
    payload: {_id, group},
})

export const clearArtboardAction = () => ({
    type: ARTBOARD_CLEAR,
    meta: {feature: ID},
});





const ARTBOARD_ACTION_IDS = {
    ARTBOARD_SET,
    ARTBOARD_CLEAR,
    ARTBOARD_ADD,
    ARTBOARD_SET_POINTER
};

export const artboardActions = {
    initArtboardAction,
    clearArtboardAction,

};

export default ARTBOARD_ACTION_IDS;