// import { useCallback, useMemo } from 'react'

// import { createEditor } from 'slate'
// import { withHistory } from 'slate-history'
// import { Editable, Slate, withReact } from 'slate-react'
import { Elements } from './Elements'
import { Leaf } from './Leaf'

const RenderContent = (content) => Array.isArray(content) ? content.map((element, topI) => (
  <Elements element={element} prod key={topI}>
    {element?.children?.map((child, childI) => (
      <Leaf {...child} leaf={child} prod key={childI}>
        {child?.type ? RenderContent([child]) : child?.text} 
      </Leaf>
    ))}
  </Elements>
)) : [];

const Text = ({content, children}) => {
  // const editor = useMemo(() => withReact(withHistory(createEditor())), [])
  // const renderElement = useCallback(props => <Elements {...props} />, [])
  // const renderLeaf = useCallback(props => <Leaf {...props}/>, [])

  content = content ? content : children;
  return (
    <>
    {/* {content.map((item, index) => (
      <div key={index}>
        {JSON.stringify(item)} <br/><br/>
      </div>
    ))}
     <br/><br/> <br/> */}
    {RenderContent(content)}
    {/* <br/><br/> <br/>
      <Slate editor={editor} value={content ? content : children}>
      <Editable 
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        readOnly
      />
    </Slate> */}
    </>
  )

  // return (
  //   <Slate editor={editor} value={content ? content : children}>
  //     <Editable 
  //       renderLeaf={renderLeaf}
  //       renderElement={renderElement}
  //       readOnly
  //     />
  //   </Slate>
  // )
}


export default Text