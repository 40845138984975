
import { Button } from '@mosanic/fields/Button/Buttons';
import { useControlPopper, useInspectState, useNotify } from '@mosanic/hooks';
import { ControlPopper } from '@mosanic/items';


import { Tooltip } from '@mosanic/items';

import { getTooltips } from '@redux/options/reducer';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import SaveItem from "../Items/SaveItem";
// import SubMenuItem from "./SubMenuItem";
import SaveItem from '../../Items/SaveItem';

import { copy, cut, duplicate, expand, greenTrash, hide, paste, save, trash } from '@mosanic/Icons/EditIcons';
import { align_bottom, align_middle, align_top, format_align_center, format_align_left, format_align_right, rotate, rotate_box } from '@mosanic/Icons/EditorIcons';
import { globeIcon } from '../../Tabs/Layout/Icons';
import DataModal from './DataModal';

import { getAddItem } from '@mosanic/constants/components';
import { elementIcon } from '@mosanic/Icons';
import { LocalizedTextIcon } from '@mosanic/Icons/ElementIcons';
import { rulersIcon } from '@mosanic/Icons/ScreenIcons';
import { mainRefIcon } from '@mosanic/Icons/StateIcons';
import { ListItemIcon } from '@mosanic/items/List/List';
import MenuList from '@mosanic/items/MenuList';
import { useMapControls, useMapItemDelete } from '@mosanic/utils/map/controls';
import { splitPath } from '@mosanic/utils/map/paths';
import { DotsHorizontalIcon } from '@radix-ui/react-icons/';
import { initArtboardAction } from '@redux/artboard/action';
import { convertDataAction } from '@redux/map/data/action';
import { handleDropWrap } from '@redux/map/drop/action';
import { isString } from '@util/Text';
import SaveLayout from '../../Items/SaveLayout';


const ControlList = MenuList;
export default function ComponentControls({component, listOnly = false, map}) {

  const tooltips = useSelector(getTooltips); 
  const dispatch = useDispatch();
  // const data = useInspectedAttr()
  const {path} = useInspectState()
  
  const [saveLayout, setSaveLayout] = useState(false)

  const notify = useNotify()

  // const [anchorEl, setAnchorEl] = React.useState(null);
  const {anchor, open, close, isOpen} = useControlPopper();

  const controls = useMapControls()
  const handleDelete = useMapItemDelete()


  const setAllBreakpoints = () => {

  }
  const removeBreakpoints = () => {

    // dispatch(handleLayoutState(component, 'default'))
    // dispatch(deleteAllGroupStates())

  }
  const deleteGroupState = () => {
    // dispatch(handleLayoutState(component, 'default'))
    // dispatch(deleteGroupState({stateName: layoutState}));

    // resetState();
  }
  const wrapWithAction = (wrapper) => dispatch(handleDropWrap({item: component, wrapItem: wrapper.component}))
  const convertToComponent = () => dispatch(convertDataAction({_id: component._id, group: component.group}, path, 'artboards'));
  const viewInArtboard = () => dispatch(initArtboardAction(component))

  const [modalState, setModalState] = useState(false);


  const menuMap = [{
    icon: cut,
    label: 'Cut',
    shortcut: '⌘X',
    action: controls.cut
  },{
    icon: copy,
    label: 'Copy',
    shortcut: '⌘C',
    action: controls.copy
  },{
    icon: paste,
    label: 'Paste',
    shortcut: '⌘V',
    action: controls.paste
  },{
    icon: save,
    label: <SaveItem component={component}></SaveItem>,
    shortcut: '⌘S',
  },{
    icon: duplicate,
    label: 'Duplicate',
    action: () => {
      controls.copy();
      setTimeout(() => {
        controls.paste();
      }, 500);
    }
  },{
    icon: trash,
    label: 'Delete',
    shortcut: '⌫',
    action: controls.del
  },{
    type: 'subMenu',
    label: 'Set visibility:',
    toggle: (
      <>
      <ListItemIcon>
        {hide}
      </ListItemIcon>
        Visibility
      </>
    ),
    map: [{
      icon: globeIcon,
      label: 'Visible on all breakpoints',
      action: () => setAllBreakpoints
    },{
      icon: greenTrash,
      label: 'Hide on all breakpoints',
      action: () => removeBreakpoints
    },{
      label: "Hide on active breakpoint",
      // action: () => handleDelete
    }]
  }, {
    icon: expand,
    label: 'View item data',
    action: () => setModalState(true)
  }, {
    type: "divider"
  }, (!isString(component?.data) ? {
      icon: mainRefIcon,
      label: 'Convert to comp',
      action: convertToComponent
    } : {
      icon: rulersIcon,
      label: 'View in artboard',
      action: viewInArtboard
    }
  ),(
    splitPath(path)?.length === 1 ? {
      icon: save,
      label: saveLayout ? 'Saving layout' : 'Save layout', 
      action: () => setSaveLayout(true)
    } : {
      icon: <DotsHorizontalIcon />,
      label: 'Save comp. [Coming]',
    }

  ),{
    icon: <LocalizedTextIcon />,
    label: 'Enable Translate',
    action: null
  }, {
    type: 'subMenu',
    label: 'Parent component:',
    toggle: (
      <>
      <ListItemIcon>
        {elementIcon('container')}
      </ListItemIcon>
        Wrap with ...
      </>
    ),
    map: [{
      icon: elementIcon('repeater'),
      label: 'Repeater',
      action: () => wrapWithAction(getAddItem('repeater'))
    },{
    //   icon: <Link1Icon />,
    //   label: 'Data Link',
    //   action: () => wrapWithAction('repeater')
    // },{
      icon: elementIcon('container'),
      label: 'Container',
      action: () =>  wrapWithAction(getAddItem('container'))
    },{
      icon: elementIcon('stack'),
      label: 'Stack',
      action: () => wrapWithAction(getAddItem('stack'))
    },{
      icon: elementIcon('link'),
      label: 'Link',
      action: () => wrapWithAction(getAddItem('link'))
    }]
  }, {
    type: "divider"
  }, {
    type: 'subMenu',
    label: 'Align component:',
    toggle: (
      <>
      <ListItemIcon>
        {format_align_left}
      </ListItemIcon>
        Align to
      </>
    ),
    map: [{
      icon: format_align_left,
      label: 'Left'
    },{
      icon: format_align_center,
      label: 'Center'
    },{
      icon: format_align_right,
      label: 'Right'
    },{
      icon: align_top,
      label: 'Top'
    },{
      icon: align_middle,
      label: 'Middle'
    },{
      icon: align_bottom,
      label: 'Bottom'
    }]
  }, {
    type: 'subMenu',
    label: 'Rotate component:',
    toggle: (
      <>
      <ListItemIcon>
        {rotate_box}
      </ListItemIcon>
        Rotate
      </>
    ),
    map: [{
      icon: rotate,
      label: 'Rotate + 90°'
    },{
      icon: (
        <span style={{transform:'scaleY(-1);'}}>
          {rotate}
        </span>  
      ),
      label: 'Rotate - 90°'
    }]
  }
  
]


  if(listOnly) return <ControlList menuMap={menuMap} />;
  return (
    <>
    <DataModal  state={modalState} close={() => setModalState(false)} component={component} cssStyle={null}/>
      <ControlPopper
       anchor={anchor} 
       close={close} 
       placement="bottom-start"
      //  customClass="-large -scaleDown -fly -hidePresets"
      >
        <span>
          Component actions  
        </span>
        <ControlList menuMap={menuMap} />
            

      </ControlPopper>
      <SaveLayout state={saveLayout} close={() => setSaveLayout(false)} path={path} component={component} />

     <Button
        className="component__controlsBtn"
        size="small"
        variant="text"
        id="component-controls-btn"
        aria-controls="component-controls"
        onClick={open}
      >
        <span className="add">
          {isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M0.877075 7.49988C0.877075 3.84219 3.84222 0.877045 7.49991 0.877045C11.1576 0.877045 14.1227 3.84219 14.1227 7.49988C14.1227 11.1575 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1575 0.877075 7.49988ZM7.49991 1.82704C4.36689 1.82704 1.82708 4.36686 1.82708 7.49988C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49988C13.1727 4.36686 10.6329 1.82704 7.49991 1.82704ZM9.85358 5.14644C10.0488 5.3417 10.0488 5.65829 9.85358 5.85355L8.20713 7.49999L9.85358 9.14644C10.0488 9.3417 10.0488 9.65829 9.85358 9.85355C9.65832 10.0488 9.34173 10.0488 9.14647 9.85355L7.50002 8.2071L5.85358 9.85355C5.65832 10.0488 5.34173 10.0488 5.14647 9.85355C4.95121 9.65829 4.95121 9.3417 5.14647 9.14644L6.79292 7.49999L5.14647 5.85355C4.95121 5.65829 4.95121 5.3417 5.14647 5.14644C5.34173 4.95118 5.65832 4.95118 5.85358 5.14644L7.50002 6.79289L9.14647 5.14644C9.34173 4.95118 9.65832 4.95118 9.85358 5.14644Z" fill="#fff"></path></svg>
            // <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" fill="black"></path></svg>
          ) : (
            <Tooltip title={!tooltips ? "" : "Edit component"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#fff"></path></svg> 
            </Tooltip>
          )}
        </span>
      </Button>
            
    </>
  );
}