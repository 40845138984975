import { CenterCenter, colors } from "@mosanic/styles";

import { RowWrap } from "@mosanic/styles/flex.styles";
import Image from "next/image";
import styled, { css } from "styled-components";
import { color, layout, space } from 'styled-system';

const Card = styled.div` 
    cursor: pointer;
    font-size: 14px;
    margin-top: -2.5px;
    span {
        vertical-align: middle;
        padding: 4px;
    }

    img {
        display: inline-block;
        margin: 0 5px;
    }

    @media (max-width: 700px) {
        span {
            display: none;
        }
    }

    &:hover {
        color: ${colors.util.primary.base};
    }

    ${layout} 
    ${space}
`
const Avatar = styled(Image)` 
    border-radius: 50%;
`
const UserStyles = {
    Card,Avatar
}
export default UserStyles;