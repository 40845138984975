const ID = '[drop]'
export const DROP_ID = ID;

const DROP_HANDLE                   = `${ID} Handle`;
const DROP_COPY                     = `${ID} Copy`;
const DROP_CUT                     = `${ID} Cut`;

const DROP_WRAP                     = `${ID} Wrap`;

const DROP_PASTE                    = `${ID} Paste`;
const DELETE_HANDLE                 = `${ID} Delete`

const DROP_PRE_STORE                = `${ID} Pre Store`;
const DROP_CLEAR_STORE              = `${ID} Clear Store`;

//Default drop & move actions
export const handleDropAction = ({dropZone, item}, handled = false) => ({
    type: !handled ? `${DROP_HANDLE} ${item?.type}` : DROP_HANDLE,
    payload: {dropZone, item},
    meta: {feature: ID}
});

//Delete item from canvas
export const handleDeleteAction = ({item = null}) => ({
    type: DELETE_HANDLE,
    payload: {item},
    meta: {feature: ID}
});

 
export const handleDropWrap = ({item, wrapItem}, handled = false) => ({
    type: !handled ? `${DROP_WRAP} ${item?.type}` : DROP_WRAP,
    payload: {item, wrapItem},
    meta: {feature: ID}
});


//Copy handling
export const handleCopyAction = ({preStore = false}, handled = false) => ({
    type: !handled ? `${DROP_COPY} (PreStore)` : DROP_COPY,
    payload: {preStore},
    meta: {feature: ID}
});
export const handleAutoPasteAction = ({sourceItemPath, itemRef}, handled = false) => ({
    type: !handled ? `${DROP_PASTE} ${itemRef?.type}` : DROP_PASTE,
    payload: {sourceItemPath, itemRef},
    meta: {feature: ID}
});
export const handleCutAction = (handled = false) => ({
    type: !handled ? `${DROP_CUT} (PreStore)` : DROP_CUT,
    meta: {feature: ID}
});

export const handlePasteAction = (handled = false, itemMap) => ({
    type: !handled ? `${DROP_PASTE} (PreStore)` : DROP_PASTE,
    meta: {feature: ID}
});

//Pre store on click sidebar item
export const handlePreStoreAction = (data) => ({
    type: DROP_PRE_STORE,
    payload: data,
    meta: {feature: ID}
});

export const clearPreStoreAction = () => ({
    type: DROP_CLEAR_STORE,
    meta: {feature: ID}
});



const DROP_ACTION_IDS = {
    DROP_HANDLE,
    DROP_COPY,
    DROP_CUT,
    DROP_PASTE,
    DELETE_HANDLE,
    DROP_PRE_STORE,
    DROP_CLEAR_STORE,
    DROP_WRAP
};
 
export const dropActions = {
    handleDropAction,
    handleDeleteAction,

    handleCutAction,
    handleCopyAction,
    handlePasteAction,
    handleAutoPasteAction,

    handlePreStoreAction,
    clearPreStoreAction
};

export default DROP_ACTION_IDS;