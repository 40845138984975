const ID = '[options]'
export const OPTIONS_ID = ID;

export const UPDATE_CAP             = `${ID} UPDATE Cap`;
export const UPDATE_PREF            = `${ID} UPDATE Pref`;
export const TOGGLE_ACCORDION       = `${ID} Toggle Accordion`;


export const updateCap = ({group, sub, cap}) => ({
    type: UPDATE_CAP,
    payload: {
        group, sub, cap
    },
    meta: {feature: OPTIONS_ID}
})

export const updateDragDropState = (state = 'auto') => {
    return {
        type: UPDATE_PREF,
        payload: state === 'auto' ? {
            group: 'builder', 
            pref: 'dragDrop'
        } : {
            group: 'builder', 
            pref: 'dragDrop',
            state
        },
        meta: {feature: OPTIONS_ID, type: 'drag drop'}
    };
}

export const updatePref = ({group, pref}, state = 'auto') => {
    return {
        type: UPDATE_PREF,
        payload: state === 'auto' ? {
            group, pref
        } : {
            group, pref, state
        },
        meta: {feature: OPTIONS_ID}
    };
}

export const toggleAccordion = ({field, state}) => ({
    type: TOGGLE_ACCORDION,
    payload: {field, state},
    meta: {feature: OPTIONS_ID}
})
    