
const ID = '[page]'
export const PAGES_ID = ID;

export const SET_PAGE             = ID+' Set';
export const UPDATE_PAGE          = ID+' Update';
export const SELECT_PAGE          = ID+' Select';
export const DELETE_PAGE          = ID+' Delete';

export const RESET_PAGES          = ID+' Reset';

const PAGES_IDS = {
    SET_PAGE,
    UPDATE_PAGE,
    SELECT_PAGE,

    RESET_PAGES
}
export default PAGES_IDS;
 

export const updatePageAction = (data) => ({
    type: UPDATE_PAGE,
    payload: data,
})
export const selectPage = (data) => ({
    type: SELECT_PAGE,
    payload: data,
})
export const setPageAction = (data) => ({
    type: SET_PAGE,
    payload: data,
})

export const deletePage = (data) => ({
    type: DELETE_PAGE,
    payload: data,
})

export const resetPagesAction = () => ({
    type: RESET_PAGES,
})