import { getImageUrl } from '@api/host';

const MediaUploader = dynamic(() => import('@mosanic/components/Media/MediaUploader'));
import { parseImageSrc } from '@mosanic/utils/Image';
import dynamic from 'next/dynamic';
import Image from 'next/image';


 
const ImageContainer = ({fields, editor, ref, active}) => {
  const {source, handler} = editor || {source: {imageUrl: null}, handler: null}
  const img = parseImageSrc(source.imageUrl)

  return active || !source?.imageUrl ?(
    <MediaUploader 
      imageUrl={img.full}
      showEditor={active}
      callback={handler}
      autoUpload
      cover
    />
  ) : ( 
    <Image 
      layout="fill"
      src={getImageUrl(img.full)} 
      alt={source?.alt ? source.alt : 'Image'} 
      placeholder={source?.placeholder ? source?.placeholder : 'blur'}
      blurDataURL={getImageUrl(img.min)}
      objectFit={'contain'}
    />
  )
};

export default ImageContainer;

           
              