import {SHOW_SPINNER, HIDE_SPINNER, START_DRAGGING, END_DRAGGING, ORDER_COMPLETE, ORDER_IN_PROGRESS, OPEN_PANEL, CLOSE_PANEL, SPINNER_ID, START_ZOOM_PINCH, END_ZOOM_PINCH, ENABLE_ZOOM_PINCH, SET_THEME_MODE} from "./uiAction";
import {HYDRATE} from 'next-redux-wrapper';
import produce from "immer";

const initialUi = {
  pending: false,
  orderInProcess: false,
  spinners : [],
  isDragging: false,
  isZoomPinch: false,
  canZoomPinch: false,
  panels: {
    left: false,
    inspect: false,
  },
  theme: 'light'
};

const uiReducer = (state = initialUi, action) => produce(state, draft => {

  switch (true) {
    case HYDRATE:
      return {...state, ...action.payload};

    case action.type === SET_THEME_MODE:
      draft.theme = action.payload;
      break;

    case action.type.includes(SHOW_SPINNER):
      draft.spinners.push(action.payload);
      break;

    case action.type.includes(HIDE_SPINNER):
      let attribute = action?.meta?.attribute ? action.meta.attribute : SPINNER_ID;
      draft.spinners = draft.spinners?.filter(spinner => spinner[attribute] !== action.payload);
      break;
      // return {...state, pending: false};

    case action.type.includes(OPEN_PANEL):
      if(draft.panels[action.payload] != undefined) draft.panels[action.payload] = true;
      break;
    
    case action.type.includes(CLOSE_PANEL):
      if(draft.panels[action.payload] != undefined) draft.panels[action.payload] = false;
      break;

    case action.type.includes(ORDER_IN_PROGRESS):
      return {...state, orderInProcess: true};

    case action.type.includes(ORDER_COMPLETE):
      return {...state, orderInProcess: false};

    case action.type === START_DRAGGING:
      draft.isDragging = true;
      break;
    case action.type === END_DRAGGING:
      draft.isDragging = false;
      break;

    case action.type === START_ZOOM_PINCH:
      draft.isZoomPinch = true;
      break;
    case action.type === END_ZOOM_PINCH:
      draft.isZoomPinch = false;
      draft.canZoomPinch = false;
      break;

    case action.type === ENABLE_ZOOM_PINCH:
      draft.canZoomPinch = true;
      break;

    default:
      return state;
  }
});
export default uiReducer;

const presentUI = state => state.present.ui
export const isActive = (state, location) => presentUI(state).spinners?.filter(s => s.location === location)?.length >= 1;
export const isInspectorOpen = state => presentUI(state).panels.inspect
export const isDraggingSelector = state => presentUI(state).isDragging
export const isZoomPinchSelector = state => presentUI(state).isZoomPinch
export const zoomPinchEnabledSelector = state => presentUI(state).canZoomPinch

export const themeModeSelector = state => presentUI(state).theme