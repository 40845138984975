enum UpdateAction {
    UPDATE = `update`,
    PUSH   = `push`,
    POP    = `pop`,
    REMOVE = `remove`,
    TOGGLE = `toggle`,
    SET    = `set`,
};
enum UpdateItem {
    CURRENT    = `current`,
    ITEM_PATHS = `item paths`,
    ITEM_PATH  = `item path`,
};
enum UpdateField {
    FIELD  = `field`,
    METRIC = `metric`,
    METRICS = `metrics`,
    FIELDS = `fields`,
};

export {
    UpdateAction,
    UpdateItem,
    UpdateField
}