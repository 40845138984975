import colors, { panelColors } from "./colors";
import styled, { css } from "styled-components";
import panelStyles from "./panels";
import { Transitions } from "./transition.styles";
import { space, layout, color, variant, typography } from 'styled-system'
import { Col } from "./grid.styles";
import { RowWrap } from "./flex.styles";
import { lighten } from "polished";
import { hex2Rgb } from "@util/color/Convert";
import { isNumber } from "@util/Num";



const Label = css` 

    transform-origin: top left;
    transform: scale(0.9);
    content: 'Limit hit';
    display: block;
    position: absolute;
    font-size: 10px;
    background: ${colors.util.gray.light};
    font-weight: 600;
    /* letter-spacing: 0.5px; */
    padding: 2.5px 6px;
    border-radius: 4px;
    top: 4px;
`
export const Card = styled.div` 
    ${Transitions().easeOut}
    
    background-color: ${props => props.trans ? 'transparent' : props?.theme?.colors?.light}; 
    /* props.theme.colors.white */
    
    border-radius: ${panelStyles.borderRadius};
    padding: 10px 15px;

    p.small {
        font-size: 14px;
    }

    
    width: 100%;

    ${props => props.float && css` 
        box-shadow: ${`5px 5px 15px 0 rgba(31, 31, 31, ${isNumber(props.float) ? props.float : 0.045})`};
    `}
    & > :is(h1, h2, h3, h4, h5, h6) {
        color: ${props => props?.theme.colors?.text};
        transition: color ease-in-out 0.1s;
    }

    ${props => props.clickable && css` 
        &:hover {
            pointer: cursor;
            transform: scale(1.025);
            & > :is(h1, h2, h3, h4, h5, h6):hover {
                color: ${props => colors.util.primary[props.theme.alt]};
            }
        }
    `}

    ${props => props.fullWidth && css` 
        width: 100%;
    `}
    ${props => props.center && css` 
        text-align: center;
    `}
    ${props => props.inline && css` 
        display: inline-block;
        vertical-align:top;
    `}

    ${props => props.transparent && css` 
        background: none;
    `}
    ${props => (props.pointer || props.onClick) && css` 
        cursor: pointer;
    `}

    ${props => props.active && css` 
        border: 2px solid ${colors.util.gray.base};
    `}

   
    
    ${typography}
    ${layout}
    ${color}
    ${space}
`
export const getPlanColor = c => c ? colors.util[c]?.base : colors.util.gray.base

const MembershipCard = styled(Card)` 
    position: relative;
    ${props => props.current && css` 

        ${props => !props.header ? css` 
            border: 3px solid ${props => getPlanColor(props?.bColor)};
            box-shadow: 4px 4px 25px 0 ${props => lighten(0.3, getPlanColor(props?.bColor)) }!important;
        ` : css` 
           /* border: 0.5px dashed ${props => getPlanColor(props?.bColor)}; */
        `}
        &::before {
            transform-origin: top right;
            transform: scale(0.8);
            content: ${props => props.addon ? 'Active addon' : 'Current plan'};
            display: block;
            position: absolute;
            font-size: 10px;
            background: ${props => getPlanColor(props?.bColor)};
            color: #fff;
            font-weight: 600;
            letter-spacing: 0.5px;
            padding: 2.5px 6px;
            border-radius: 4px;
            top: 4px;
            right: 3px;
        }
    `}

    ${props => props.unavailable && css` 
        opacity: 0.5;
        filter: grayscale(1);
        transform: scale(0.9);

        &:hover {
            opacity: 1;
            filter: grayscale(0.5);
        }

        ${(props.addon && !props.current) && css` 
            &::after {
                ${Label}
                content: 'Upgrade plan';
                left: 3px;
            }
        `}

        ${(props?.addon && props.current) && css` 
            opacity: 0.8;
            filter: grayscale(0);

            &:hover {
                transform: scale(1);
            }

        `}
        
    `}
    ${props => props?.hitLimit && css` 
        &::after {
            ${Label}
            left: 3px;
        }
    `}
`
export const Wrapper = styled.div` 
    ${RowWrap}
    width: 100%;
    ${Card, Col} {
        /* display: inline-block; */
    }
`

export const Tag = styled.span` 
    ${Transitions().easeOut}
    display: block;
    font-size: 10px;
    background-color: ${props => props.bg ? colors.util[props.bg]?.dark : colors.util.gray.darker};
    color: #fff;
    border-radius: 4px;
    padding: 4px 6px;
    line-height: 100%;
    height: 20px;
    ${props => props.right && css` 
        float: right;
    `}
    ${props => props.float && css` 
        box-shadow: 5px 5px 10px 0 rgba(31, 31, 31, 0.045);
    `}
    ${props => props.clickable && css` 
        &:hover {
            pointer: cursor; 
            transform: scale(1.025);
        }
    `}
    ${props => props.xs && css` 
        transform: scale(0.7);
        transform-origin: top right;
        letter-spacing: 1px;
    `}
    ${props => props.abs && css` 
       position: absolute;
       ${props => props.right && css` 
            right: 0px;
            top: 0px;
       `}
    `}
    ${props => props.round && css` 
        height:25px;
        border-radius: 50%;
        svg {
            transform: scale(0.8);
        }
    `}

    ${typography}
    ${layout}
    ${color}
    ${space}
`
const Block = styled.div` 
    position: relative;
  ${typography}
    ${layout}
    ${color}
    ${space}
`
const CardStyles = {
    MembershipCard,
    Wrapper,
    Card,
    Block,
    Tag,
}
export default CardStyles;