
const Slider = dynamic(() => import("react-slick"));
// import Slider from "react-slick";
import Child from "@mosanic/core/Map/Child";
import { ProdRender } from "@mosanic/core/Map/Render";
import dynamic from "next/dynamic";


const ImageSlider = ({fields, editor, ref, active, breakpoint}, children) => {
    const {source, handler} = editor;
    let {slidesToShow, slidesToScroll, devMode, speed, dots, infinite} = source;
    const {centerMode, autoplay, autoplaySpeed, arrows} = source || {centerMode: false, autoplay: true, autoplaySpeed: 3000, arrows: false}
    const fade = source?.fade ? source.fade : false;
    const getValue = (val, fb = 0) => 
    // (hasAttr(val, breakpoint) && !isNumber(val[breakpoint])) ? 
    //     val[breakpoint] : 
        val?.base ? 
            val.base : 
            val ? val :
                fb;

    const responsive = slidesToShow?.xs ? [{
        breakpoint: 480,
        settings: {
            slidesToShow: parseFloat(slidesToShow?.xs),
            slidesToScroll: parseFloat(slidesToScroll?.xs ? slidesToScroll.xs : slidesToScroll?.base ? slidesToScroll.base : 1)
        }
    }] : undefined;
    let settings = {
        dots: dots === 1 ? true : false,
        infinite: infinite === 1 ? true : false,
        autoplaySpeed: parseFloat(getValue(autoplaySpeed, 3500)),
        centerMode: centerMode === 1 ? true : false,
        arrows: arrows === 1 ? true : false,
        speed,
        autoplay:  autoplay === 1 ? true : false,
        slidesToShow: parseFloat(getValue(slidesToShow, 1)),
        responsive,
        slidesToScroll: parseFloat(getValue(slidesToScroll, 1)),
        fade
    };
 
    const props = children?.props

    //Remove slot components
    const slides = props?.child?.children.filter(child => !child?.manage);
    //Pass props
    const prod = {breakpoint: props?.breakpoint, mode: props?.mode ? props.mode : 'prod', data: props?.data}


    return (
        <>
        {(active || devMode || !slides) ? children :
        (

             <Slider {...settings} >
                {slides?.length >= 1 ? slides.map((child, index) => (
                    <Child 
                        {...prod}
                        key={child?._id}
                        child={child}
                    >
                        {child?.children?.length >= 1 ? (
                            <ProdRender
                                {...prod}
                                child={child}
                            />
                        ) : null}
                    </Child>
                )) : null}
             </Slider>
        )
        }
    
        </>
    )
}
export default ImageSlider;
