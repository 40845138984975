import { useUploadFactory } from '@mosanic/components/Media/Handlers';
import FileField from '@mosanic/fields/File';
import { WrapInput } from '@mosanic/items';


const MediaSingleUploader = ({
    imageUrl, 
    type = 'image',
    stopServerUpload = false,
    onChange = null,
    callback = null,
    label = null,
    wrap = null,
    fullWidth = false,
    value = null,
    ...other
}) => {
    const [images, meta, progress, handlers, loading, error] = useUploadFactory({url: imageUrl, callback});

    return (
        <WrapInput wrap={wrap ? wrap : false} fullWidth={fullWidth}>
        <FileField 
            label={label}
            onChange={(e) => {
                if(onChange) onChange(e)
                if(!stopServerUpload) handlers.uploadFile(e.target.files[0], '')
            }} 
            initiate={(url) => {
                handlers?.setImages([{url}]);
                callback(url);
            }}
            value={stopServerUpload ? value : images?.[0]}
            loading={loading}
            type={type}
            {...other}
        />
        </WrapInput>
    )
};

export default MediaSingleUploader;

           
             