import { addChildToChildren, handleNewItem } from "./add";
import { shouldConfirm } from "./confirm";
import { handleMoveCreate, handleMoveToDifferentParent } from "./move";
import { handleMoveWithinParent } from "./reorder";
import { isMoveWithinParent, isPureMove, validate } from "./validate";
import { removeChildFromChildren } from "./remove";

import { loopTroughMap } from "./transform";

const Move = {
    handleNewItem,
    handleMoveWithinParent,
    handleMoveToDifferentParent,
    handleMoveCreate,

    removeChildFromChildren
};

const ValidateMove = {
    isPureMove,
    isMoveWithinParent,
}

export { 
    validate,
    ValidateMove,
    shouldConfirm,
    loopTroughMap,

    //Actions
    Move,
    addChildToChildren,
};

