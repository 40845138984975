import { containsSubstr } from "./Text";


function prevent(syntheticEvent) {
    syntheticEvent.preventDefault();
}

function isMouseEvent(syntheticEvent){
    const { nativeEvent } = syntheticEvent;

    return nativeEvent instanceof MouseEvent
}

function isTouchEvent(syntheticEvent){
    const { nativeEvent } = syntheticEvent;

    return nativeEvent instanceof TouchEvent
}

function isTouchPad(syntheticEvent){
    const e = syntheticEvent;
    return e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;
}

function isValidCurrentTarget(syntheticEvent) {
    const {
        target,
        currentTarget
    } = syntheticEvent;

    return target === currentTarget
}

function isCurrentTarget(syntheticEvent, targetClass) {
    const {target} = syntheticEvent;
    return containsSubstr(target.className, targetClass)
}
const isZoomIn = (syntheticEvent) => {
    const {wheelDeltaY} = syntheticEvent
    return wheelDeltaY > 0
}

const Event = {
    prevent,
    isMouseEvent,
    isTouchEvent,
    isTouchPad,
    isValidCurrentTarget,
    isCurrentTarget,
    isZoomIn
}
export {
    prevent,
    isMouseEvent,
    isTouchEvent,
    isTouchPad,
    isValidCurrentTarget,
    isCurrentTarget,
    isZoomIn
}
export default Event;