import { MEDIA_COUNT } from "@api/queries/media";
import { useGetCount } from "./useDataQuery";
import { ENTRY_COUNT } from "@api/queries/entries";



export const useGetMediaCount = ({params}) => useGetCount({ 
    type: 'media',
    query: MEDIA_COUNT
})

export const useGetEntryCount = ({_id, collectionId, filter}) => useGetCount({ 
    type: 'entry',
    params: (
        _id ? {filter: {collectionId: _id}} : 
        collectionId && filter ? {filter: {...filter, collectionId}} :
        filter ? {filter} : null
    ),
    query: ENTRY_COUNT
})

