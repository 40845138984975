const cornersIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M10.1 3c.428 0 .72 0 .944.019.22.018.332.05.41.09a1 1 0 0 1 .437.437c.04.078.072.19.09.41.019.225.019.516.019.944v.6a.5.5 0 0 0 1 0v-.62c0-.403 0-.735-.022-1.006-.023-.281-.072-.54-.196-.782a2 2 0 0 0-.874-.874c-.243-.124-.501-.173-.782-.196C10.856 2 10.523 2 10.12 2H9.5a.5.5 0 0 0 0 1h.6ZM5.5 2h-.62c-.403 0-.735 0-1.006.022-.281.023-.54.072-.782.196a2 2 0 0 0-.874.874c-.124.243-.173.501-.196.782C2 4.144 2 4.477 2 4.88V5.5a.5.5 0 0 0 1 0v-.6c0-.428 0-.72.019-.944.018-.22.05-.332.09-.41a1 1 0 0 1 .437-.437c.078-.04.19-.072.41-.09C4.18 3 4.472 3 4.9 3h.6a.5.5 0 0 0 0-1ZM13 9.5a.5.5 0 0 0-1 0v.6c0 .428 0 .72-.019.944-.018.22-.05.332-.09.41a1 1 0 0 1-.437.437c-.078.04-.19.072-.41.09-.225.019-.516.019-.944.019h-.6a.5.5 0 0 0 0 1h.62c.403 0 .735 0 1.006-.022.281-.023.54-.072.782-.196a2 2 0 0 0 .874-.874c.124-.243.173-.501.196-.782.022-.27.022-.603.022-1.005V9.5ZM2.5 9a.5.5 0 0 1 .5.5v.6c0 .428 0 .72.019.944.018.22.05.332.09.41a1 1 0 0 0 .437.437c.078.04.19.072.41.09.225.019.516.019.944.019h.6a.5.5 0 0 1 0 1h-.62c-.403 0-.735 0-1.006-.022-.281-.023-.54-.072-.782-.196a2 2 0 0 1-.874-.874c-.124-.243-.173-.501-.196-.782C2 10.856 2 10.523 2 10.12V9.5a.5.5 0 0 1 .5-.5Z" fill="#000"/></svg>
const rulersIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M.5 0a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5h9.5a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H.5ZM1 4.075V1h3.075v3.075H1Zm0 .85V14h3v-1.075H2.75a.425.425 0 1 1 0-.85H4v-1.15H2.25a.425.425 0 0 1 0-.85H4v-1.15H2.75a.425.425 0 1 1 0-.85H4v-1.15H2.75a.425.425 0 1 1 0-.85H4v-1.15H1ZM4.925 4h1.15V2.75a.425.425 0 0 1 .85 0V4h1.15V2.75a.425.425 0 0 1 .85 0V4h1.15V2.25a.425.425 0 1 1 .85 0V4h1.15V2.75a.425.425 0 0 1 .85 0V4H14V1H4.925v3Z" fill="#000"/></svg>
const bookmarkIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M3 2.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.765.424L7.5 11.59l-3.735 2.334A.5.5 0 0 1 3 13.5v-11ZM4 3v9.598l2.97-1.856a1 1 0 0 1 1.06 0L11 12.598V3H4Z" fill="#000"/></svg>;
const interactionIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M8.697.04a.5.5 0 0 1 .296.542L8.09 6h4.41a.5.5 0 0 1 .4.8l-6 8a.5.5 0 0 1-.893-.382L6.91 9H2.5a.5.5 0 0 1-.4-.8l6-8a.5.5 0 0 1 .597-.16ZM3.5 8h4a.5.5 0 0 1 .493.582L7.33 12.56 11.5 7h-4a.5.5 0 0 1-.493-.582L7.67 2.44 3.5 8Z" fill="#000"/></svg>;
const dragHandleIcon = <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 4.625a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm4 0a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM10.625 7.5a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM5.5 8.625a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm5.125 2.875a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM5.5 12.625a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/></svg>
const moveHandleIcon = <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.818.932a.45.45 0 0 0-.636 0l-1.75 1.75a.45.45 0 1 0 .636.636L7 2.386V5.5a.5.5 0 0 0 1 0V2.386l.932.932a.45.45 0 0 0 .636-.636L7.818.932ZM8 9.5a.5.5 0 0 0-1 0v3.114l-.932-.932a.45.45 0 0 0-.636.636l1.75 1.75a.45.45 0 0 0 .636 0l1.75-1.75a.45.45 0 0 0-.636-.636L8 12.614V9.5Zm1-2a.5.5 0 0 1 .5-.5h3.114l-.932-.932a.45.45 0 0 1 .636-.636l1.75 1.75a.45.45 0 0 1 0 .636l-1.75 1.75a.45.45 0 0 1-.636-.636L12.614 8H9.5a.5.5 0 0 1-.5-.5ZM3.318 6.068 2.386 7H5.5a.5.5 0 0 1 0 1H2.386l.932.932a.45.45 0 0 1-.636.636l-1.75-1.75a.45.45 0 0 1 0-.636l1.75-1.75a.45.45 0 1 1 .636.636Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/></svg>;
const doubleArrowUpIcon = <svg width="15" height="15" version="1.1" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" ><path d="M11.1464,6.85355c0.1953,0.19527 0.5119,0.19527 0.7072,0c0.1952,-0.19526 0.1952,-0.51184 0,-0.7071l-4.00005,-4c-0.19526,-0.19527 -0.51184,-0.19527 -0.7071,0l-4,4c-0.19527,0.19526 -0.19527,0.51184 0,0.7071c0.19526,0.19527 0.51184,0.19527 0.7071,0l3.64645,-3.64644l3.6464,3.64644Zm0,6.00005c0.1953,0.1952 0.5119,0.1952 0.7072,0c0.1952,-0.1953 0.1952,-0.5119 0,-0.7072l-4.00005,-3.99995c-0.19526,-0.19527 -0.51184,-0.19527 -0.7071,0l-4,3.99995c-0.19527,0.1953 -0.19527,0.5119 0,0.7072c0.19526,0.1952 0.51184,0.1952 0.7071,0l3.64645,-3.64649l3.6464,3.64649Z" fill="#000" fillRule="evenodd"></path></svg>;

const ScreenIcons = {
    cornersIcon,
    rulersIcon,
    bookmarkIcon,
    interactionIcon,
    dragHandleIcon,
    moveHandleIcon,
    doubleArrowUpIcon
}

export default ScreenIcons;

export {
    cornersIcon,
    rulersIcon,
    bookmarkIcon,
    interactionIcon,
    dragHandleIcon,
    moveHandleIcon,
    doubleArrowUpIcon
}

 