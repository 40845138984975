import { InputGroup } from '@mosanic/items';


const FormGroup = ({onSubmit, disabled, children, ...other}) => (
    <form disabled={disabled} onSubmit={onSubmit} style={{maxWidth: '100%'}}>
        <InputGroup {...other} cols={1} fullWidth>

            {children}

        </InputGroup>
    </form>
		
);
export default FormGroup;