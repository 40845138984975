import useClient from '@api/client';
import { ErrorAlert, Spinner } from '@mosanic/items';
import isEmpty from '@util/Empty';
import isEqual from '@util/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePolling } from './usePolling';
import { isClone } from '@mosanic/utils/value';
import { 
	useQuery as useApolloQuery, 
	useLazyQuery as useApolloLazyQuery,
	useMutation as useApolloMutation
} from '@apollo/client';
import { omitDeep } from '@util/Obj';

const errorMessage = (message) => ({message})
const interval = 100;


const checkResponseErrors = (response, options = null) => {
	let error = null;

	if(isEmpty(response) && options?.mustReturn) {
		error = errorMessage(`No data retrieved.`);

	} else if(Array.isArray(response?.errors)) {
		error = errorMessage(response.errors[0]?.message);
		

	} else if(response?.error) {
		error = errorMessage(response.error?.message ? response.error.message : response.error);

	};

	return error;
}

const fireApi = async (type, name, gql, vars) => 
	(type === 'query') ? await useClient.query(name, gql, vars) :
	(type === 'varQuery') ? await useClient.query(name, gql, vars) :
	(type === 'lazyQuery') ? await useClient.query(name, gql, vars) :
	(type === 'mutate') ? await useClient.mutate(name, gql, vars) :
	errorMessage(`Query type: ${type}, is unsupported.`);

	// const fireApi = async (type, name, gql, vars) => {
	// 	const useClient = (await import('@api/client')).default; 
	// 	return (type === 'query') ? await useClient.query(name, gql, vars) :
	// 	(type === 'varQuery') ? await useClient.query(name, gql, vars) :
	// 	(type === 'lazyQuery') ? await useClient.query(name, gql, vars) :
	// 	(type === 'mutate') ? await useClient.mutate(name, gql, vars) :
	// 	errorMessage(`Query type: ${type}, is unsupported.`);
	// }
		 
const useApi = ({name, gql, vars = null}, options) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	usePolling({callback: () => getData(vars), active: options?.type === 'query'})

	//Api runner
	const getData = useCallback(async (queryVars) => {
		setLoading(true);

		const response = await fireApi(options?.type, name, gql, queryVars);
		const error = checkResponseErrors(response, options)
		const data = error ? null : response;

		if(isClone(response) || response) setData(response);
		setLoading(false);
		setError(error);

		return {
			data, 
			error,
		}
	},[options, name, gql]);

	
	return [(queryVars) => getData(queryVars), [
		data,
		Boolean(loading) ? <Spinner /> : null,
		Boolean(error) ? <ErrorAlert errorMessage={error.message} /> : null, 
	]]
};

// {data, loading, error}
export const useQuery = (name, query, vars = null, options = null) => {

	const { loading, error, data } = useApolloQuery(query, {
		variables: vars
	});


	// const [callback, [data, loading, error]] = useApi({
	// 	gql: query, 
	// 	name, 
	// 	vars
	// }, {
	// 	type: 'query',
	// 	...options
	// });

	// if(!data && !loading && !error) callback(vars)

	return [
		data ? data?.[name] : data, 
		loading ? <Spinner /> : null, 
		error ? (
			<ErrorAlert errorMessage={error.message} />
		) : null
	];
};

export const useVarQuery = (name, query, vars, hasVars = false, options = null) => {
	// const calls = useRef(0);

	const { loading, error, data } = useApolloQuery(query, {
		variables: vars
	});

	// const [callback, [data, loading, error]] = useApi({
	// 	gql: query, 
	// 	name, 
	// 	vars
	// }, {
	// 	type: 'varQuery',
	// 	...options
	// });

	// if(!data && !loading && !error && calls.current === 0 && hasVars) {
	// 	calls.current++
	// 	callback(vars)
	// }
	// useEffect(() => {
	// 	if(hasVars) callback(vars)
	// },[hasVars])

	return [
		data ? data?.[name] : data, 
		loading ? <Spinner /> : null, 
		error ? (
			<ErrorAlert errorMessage={error.message} />
		) : null
	];
};
 
// [getData, {data, loading, error}]
export const useLazyQuery = (name, query, vars = null, options = null) => {

	const [call, { loading, error, data }] = useApolloLazyQuery(query, {
		variables: vars
	});

	
	const callback = variables => call({variables})


	// const [callback, [data, loading, error]] = useApi({
	// 	gql: query, 
	// 	name, 
	// 	vars
	// }, {
	// 	type: 'lazyQuery', 
	// 	...options
	// });
	return [callback, [
		data ? data?.[name] : data, 
		loading ? <Spinner /> : null, 
		error ? (
			<ErrorAlert errorMessage={error.message} />
		) : null
	]];
};

// [getData, {data, loading, error}]
export const useMutate = (name, mutation, vars = null, options = null) => {

	 const [dispatch, { data, loading, error }] = useApolloMutation(mutation);

	const mutate = (variables, omit = true) => dispatch({
		variables: omit ? 
			omitDeep(variables, '__typename') : 
			variables
	})
	
	
	// const [callback, [data, loading, error]] = useApi({
	// 	gql: mutation, 
	// 	name, 
	// 	vars
	// }, {
	// 	type: 'mutate', 
	// 	...options
	// });
	return [mutate, [
		data ? data?.[name] : data, 
		loading ? <Spinner /> : null, 
		error ? (
			<ErrorAlert errorMessage={error.message} />
		) : null
	]];
};