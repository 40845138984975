import { colors } from "@mosanic/styles";
import styled from "styled-components"
import {space, layout} from 'styled-system';

const StyledSeparator = styled.hr` 
    border-top: 1px solid ${props => colors.util.gray[props.tint]};
    ${space}
    ${layout}
`
const Hr = ({tint = 'light', ...other}) => <StyledSeparator tint={tint} {...other} />
const Separator = Hr;

export {
    Separator
};
export default Hr;