import { mousePositionClick } from "@hooks/events";
import { isDiffCol, isDiffParent, isDiffRow, isSameLevel, splitPath as getSplitPath } from "@mosanic/utils/map/paths";
import { hasSlots } from "@mosanic/utils/map/slots";
import { updateInspectBounds } from "@redux/bounds/action";
import { getPageScale } from "@redux/canvas/reducer";
import { initInspectAction, setOverwriteAction } from "@redux/inspect/action";
import { selectInspectItemId, selectInspectItemPath, selectInspectItemScreen } from "@redux/inspect/reducer";
import { getParentNode, getScaledPositionByParent } from "@util";
import Event from "@util/Event";
import { capitalizeFirst, containsSubstr, replacePart } from "@util/Text";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


const useInspectState = () => {
  const inspectScreen = useSelector(selectInspectItemScreen)
  const inspectPath = useSelector(selectInspectItemPath)
  const inspectId = useSelector(selectInspectItemId)

  const splitPath = inspectPath ? getSplitPath(inspectPath) : null;
  return [splitPath, inspectScreen, inspectId]
}
const validateIsInspected = ({inspectScreen, inspectId, itemScreen, itemId}) => {
  if(itemScreen === 'layers') return inspectScreen === inspectId;
  return inspectScreen === itemScreen && inspectId === itemId;
}

const interval = 150;
 
const useItemInspect = ({item, ref, breakpoint, map, mode, data = null}) => {
  const {path, children} = map;
  const className = data?.clone ? `${item?.className}${data?.clone}` : item?.className;
  const isEnd = hasSlots(children) || !children
  const itemPath = getSplitPath(path);
  const itemId = item?._id;
  
  // const isInspected = useInspectState(item?._id, mode === 'layers' ? 'any' : breakpoint, true, data?.clone)
  const [splittedInspect, inspectScreen, inspectId] = useInspectState()

  const itemScreen = mode === 'layers' ? 'layers' : breakpoint;
  const isInspected = validateIsInspected({
    inspectScreen, inspectId, itemId, itemScreen
  })

  const shouldValidate = Boolean(splittedInspect && breakpoint != 'layers');
  const validateChild = Boolean(splittedInspect?.length < itemPath?.length)
  const passRowAndScreen = Boolean(
    isDiffRow(splittedInspect, itemPath) || 
    inspectScreen != itemScreen
  )

  const [contextMenu, setContextMenu] = useState(null);
  const pageScale = useSelector(getPageScale)

  const dispatch = useDispatch(); 

  const setInspector = (e, isLayer = false) => { 
    if(isLayer) handleInspector(e)
    else if(isEnd || Event.isCurrentTarget(e, className)){

      if(e?.type === "contextmenu") {
        Event.prevent(e)
        mousePositionClick(e, contextMenu, setContextMenu, false);
      }
      if(!isInspected) handleInspector(e)
    } 
  };

  const handleInspector = (e) => {
    const bounds = getScaledPositionByParent(ref, 'inspectRef', pageScale);
    
    if( shouldValidate && !Event.isCurrentTarget(e, className) ) {

      if(passRowAndScreen) {
        // All good.

      } else if(validateChild) {

        if( 
          !isDiffRow(splittedInspect, itemPath) && 
          !isDiffCol(splittedInspect, itemPath) && 
          !getParentNode({
            parent: e.target, 
            findClass: className
          })
        ) return false

      } else if(
        isSameLevel(splittedInspect, itemPath) &&
        isDiffParent(splittedInspect, itemPath)
      ) {
        //Other child
      } else {
        return false;
      }
      
    }
     

    // todo --> handle screen
    // if not xl screen is available 
    // fallback to the screen with the most items
    dispatch(initInspectAction({
      component: {
        _id: item._id,
        type: item?.type,
        group: item?.group,
        itemType: item?.itemType,
        state: item?.state, 
        // variants: item?.variants,
        ...data 
      }, 
      screen: itemScreen != 'layers' ? itemScreen : 'xl',
      bounds, 
      path,
    })); 

    //Convert on artboards item state to correct overwrite value
    if(containsSubstr(itemScreen, 'ab-')){
      const variant = Object.entries(item?.state).map(([type, value]) => ({
        type, 
        value,
        label: capitalizeFirst(replacePart(value, `${type}-`, ''))
      }))

      dispatch(setOverwriteAction(variant[0], 'variant'))
    }

  };

  const [size, setSize] = useState(null)

  useEffect(() => {
    let timer;

    if(isInspected){

      timer = setInterval(() => {
        const clientRect = ref?.current?.getBoundingClientRect();
        if(JSON.stringify(size) != JSON.stringify(clientRect)){
          setSize(clientRect)
          dispatch(updateInspectBounds(
            path,  
            getScaledPositionByParent(ref, 'inspectRef', pageScale), 
            breakpoint
          ));
        }
      }, 250);

    }
    return () => clearTimeout(timer);
  }, [breakpoint, dispatch, isInspected, pageScale, path, ref, size]);
  

  return {
    onClick: setInspector, 
    contextMenu, 
    setContextMenu,
    isInspected
  };
};
export default useItemInspect;
