import { useCollection } from "@mosanic/core/Collections";
import { Tooltip } from "@mosanic/items";
import { parseImageSrc } from "@mosanic/utils/Image";
import Image from "next/image";
import { Value } from "slate";
import styled, { css } from "styled-components";

const InlineStack = styled.div` 
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;

    ${props => props.source === 'repeater' && css` 
        flex-wrap: nowrap;
        max-width: 100%;
        overflow: hidden;
    `}

`
const Label = styled.span` 
    font-size: 14px;
`
const Item = styled(Label)` 
    margin: 0 10px 10px;
    
    ${props => props.source === 'repeater' && css` 
        margin-right: 5px;
        background: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        padding: 2px;
    `}
    img {
        object-fit: cover!important;
    }
`

const room = '639303aac4f0348b9f479d24_dev';
const facility = '639306b5c4f0348b9f479ee2';
const acc_type = '639304afc4f0348b9f479d9e';

export const hasRelationRender = collection => [
    room,
    facility,
    acc_type
].includes(collection)

export const RelationRender = ({entry, link, content}) => {
    // const id = entry?.collection;
    // return JSON.stringify(entry)

    /// ---> if relation can render ==> pass as single option to zone
    // todo (let zones, select input type == multiple / single)

    if(room === link) return <Rooms content={content} />
    if(facility === link) return <Facilities content={content} source={entry?.source}/>
    if(acc_type === link) return <AccType content={content} source={entry?.source} entry={entry} />
    // return JSON.stringify(content)
}

const Rooms = ({content}) => {
    const [handler, [collection, loading, error]] = useCollection({_id: room})
    const getBed = value => collection?.entries?.find(e => (e?.Value === value || e?._id === value))
    return (
        <>
        {content?.map((bed, i) => (
        <Label key={i}>
            <Image 
                src={parseImageSrc(getBed(bed?.value ? bed.value : bed)?.Icon, true)?.full}
                width="35px"
                height="35px"
                alt="none"
            /><br/>
            <p>
            {bed?.label ? bed.label : getBed(bed?.value ? bed.value : bed)?.Label}
            </p>
        </Label>

    ))}
    {/* {JSON.stringify(collection?.entries[1])} */}

        </>
    )
}

const Facilities = ({content, source}) => {
    const [handler, [collection, loading, error]] = useCollection({_id: facility})
    const getFacility = value => collection?.entries?.find(e => (e?.Label === value || e?._id === value))
    return (
        <InlineStack source={source}>
        {Array.isArray(content) && content?.map((point, i) => (
            <Tooltip key={i} title={source === 'repeater' && point?.label} force>
                <Item source={source}>
                    <Image 
                        src={parseImageSrc(getFacility(point?.label ? point.label : point)?.Icon, true)?.full}
                        width="15px"
                        height="15px"
                        alt="none"
                    /><br/>
                    {source != 'source' ? null : source != 'repeater' && point?.label ? point.label : getFacility(point?.label ? point.label : point)?.Label}
                </Item>
            </Tooltip>
        ))}
        </InlineStack>
    )
}
const AccType = ({content, source, entry}) => {
    const [handler, [collection, loading, error]] = useCollection({_id: acc_type})
    const type  = collection?.entries?.find(e => e?.sys_type === content || e._id === content)
    return (
        <Image 
            src={parseImageSrc(type?.Icon, true)?.full}
            width="25px"
            height="25px"
            alt={type?.Description}
        />
    )
}