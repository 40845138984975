

import { gql } from '@apollo/client';



export const ADD_BUILDER_LAYOUT = gql`
mutation builderLayoutCreateOne($record: CreateOneBuilderLayoutInput!) {
	builderLayoutCreateOne (record: $record) {
		recordId
	}
}
`;


export const UPDATE_BUILDER_LAYOUT = gql`
mutation builderLayoutUpdateById($_id: MongoID!, $record: UpdateByIdBuilderLayoutInput!) {
	builderLayoutUpdateById (_id: $_id, record: $record) {
		recordId
	}
}
`;
