import { ImageIcon } from "@radix-ui/react-icons"
import { hasAttr } from "@util/Obj"
import { circlePlus as plus } from "./EditIcons"

const format_bold = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M5.105 12c-.397 0-.681-.088-.853-.264-.168-.18-.252-.465-.252-.853V4.117c0-.397.086-.681.258-.853.176-.176.458-.264.847-.264H9.03c1.108 0 2.025.982 2.025 2.185 0 .9-.45 1.634-1.35 2.051 1.162.213 1.814 1.392 1.814 2.245 0 1.031-.528 2.519-2.24 2.519H5.104Zm3.274-3.997H5.8v2.628h2.579c.521 0 1.25-.51 1.25-1.332 0-.823-.729-1.296-1.25-1.296ZM5.8 4.37v2.327h2.38c.36 0 1.097-.337 1.097-1.196 0-.86-.797-1.131-1.097-1.131H5.8Z" fill="#000"/></svg>
const format_italic = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.675 3.5a.45.45 0 0 1 .45-.45h4.5a.45.45 0 1 1 0 .9h-1.62l-1.774 7.1h1.644a.45.45 0 0 1 0 .9h-4.5a.45.45 0 1 1 0-.9h1.619l1.775-7.1H6.125a.45.45 0 0 1-.45-.45Z" fill="#000"/></svg>
const format_underlined = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5 2.75a.5.5 0 0 0-1 0v5.3a3.5 3.5 0 0 0 7 0v-5.3a.5.5 0 1 0-1 0v5.3a2.5 2.5 0 1 1-5 0v-5.3ZM3.5 13.1a.4.4 0 1 0 0 .8h8a.4.4 0 0 0 0-.8h-8Z" fill="#000"/></svg>

const code = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9.964 2.686a.5.5 0 1 0-.928-.372l-4 10a.5.5 0 1 0 .928.372l4-10Zm-6.11 2.46a.5.5 0 0 1 0 .708L2.207 7.5l1.647 1.646a.5.5 0 1 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708 0Zm7.292 0a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L12.793 7.5l-1.647-1.646a.5.5 0 0 1 0-.708Z" fill="#000"/></svg>
const text = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M3.95 2.95V4.5a.45.45 0 0 1-.9 0v-2a.45.45 0 0 1 .45-.45h8a.45.45 0 0 1 .45.45v2a.45.45 0 1 1-.9 0V2.95h-3v9.1h1.204a.45.45 0 0 1 0 .9h-3.5a.45.45 0 1 1 0-.9H6.95v-9.1h-3Z" fill="#000"/></svg>
const looks_one = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.754 2.05a.45.45 0 1 0 0 .9H9.95v4.1h-4.9v-4.1h1.204a.45.45 0 1 0 0-.9h-3.5a.45.45 0 1 0 0 .9H3.95v9.1H2.754a.45.45 0 0 0 0 .9h3.5a.45.45 0 0 0 0-.9H5.05v-4.1h4.9v4.1H8.754a.45.45 0 0 0 0 .9h3.5a.45.45 0 0 0 0-.9H11.05v-9.1h1.204a.45.45 0 0 0 0-.9h-3.5Z" fill="#000"/></svg>;
const looks_two = looks_one;
const headings = looks_one;

const format_quote = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9.425 3.441c.631-.204 1.359-.2 1.954.105 1.374.706 1.969 2.526 1.416 4.454-.248.865-.685 1.705-1.609 2.552-.924.848-2.206 1.348-2.8 1.348A.38.38 0 0 1 8 11.525c0-.207.176-.375.386-.375.679 0 1.286-.37 2.005-.914.55-.417.98-.95 1.217-1.414.455-.888.47-2.14-.265-2.473-.353.386-.813.61-1.366.61-1.2 0-1.907-.965-1.876-1.839.029-.835.56-1.43 1.324-1.679Zm-6 0c.631-.204 1.359-.2 1.954.105C6.753 4.252 7.348 6.072 6.795 8c-.248.865-.685 1.705-1.609 2.552-.924.848-2.206 1.348-2.8 1.348A.38.38 0 0 1 2 11.525c0-.207.176-.375.386-.375.679 0 1.286-.37 2.005-.914.55-.417.98-.95 1.217-1.414.455-.888.47-2.14-.265-2.473-.353.386-.814.61-1.366.61-1.2 0-1.907-.965-1.876-1.839.029-.835.56-1.43 1.324-1.679Z" fill="#000"/></svg>


const format_list_bulleted = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 5.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM4 4.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5ZM4.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9Zm0 3a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9ZM2.25 7.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm-.75 3.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" fill="#000"/></svg>
const format_list_numbered = format_list_bulleted;

const format_align_left = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M2 4.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5Zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm0 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Z" fill="#000"/></svg>
const format_align_center = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 4.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5Zm2 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5Zm-1 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Z" fill="#000"/></svg>
const format_align_right = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 4.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5Zm5 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-3 3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Z" fill="#000"/></svg>
const format_align_justify = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.5 4a.5.5 0 0 0 0 1h10a.5.5 0 0 0 0-1h-10ZM2 7.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5Zm0 3a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5Z" fill="#000"/></svg>

const align_top = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M1.5 0a.5.5 0 0 0 0 1H6v11a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V1h4.5a.5.5 0 0 0 0-1h-12Z" fill="#000"/></svg>;
const align_middle = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7 1a1 1 0 0 0-1 1v5H1.5a.5.5 0 0 0 0 1H6v5a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8h4.5a.5.5 0 0 0 0-1H9V2a1 1 0 0 0-1-1H7Z" fill="#000"/></svg>;
const align_bottom = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M9 3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v11H1.5a.5.5 0 0 0 0 1h12a.5.5 0 0 0 0-1H9V3Z" fill="#000"/></svg>;

const rotate_box = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.597 2.936A.25.25 0 0 0 8 2.74V2c1.981 0 3.185.364 3.91 1.09C12.637 3.814 13 5.018 13 7a.5.5 0 0 0 1 0c0-2.056-.367-3.603-1.382-4.618C11.603 1.368 10.056 1 8 1V.261a.25.25 0 0 0-.403-.197L6.004 1.303a.25.25 0 0 0 0 .394l1.593 1.24ZM9.5 5h-7a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5Zm-7-1A1.5 1.5 0 0 0 1 5.5v7A1.5 1.5 0 0 0 2.5 14h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 9.5 4h-7Z" fill="#000"/></svg>;
const rotate = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15"><path d="M21.962 12.875A10.03 10.03 0 1 1 19.122 5H16a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4.143A1.858 1.858 0 0 0 22 5.143V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v2.078A11.985 11.985 0 1 0 23.95 13.1a1.007 1.007 0 0 0-1-1.1.982.982 0 0 0-.988.875Z"/></svg>;

const source = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.512 3.005c.676-.46 1.531-.468 2.167-.05.144.094.298.244.71.656.412.412.562.566.657.71.417.636.408 1.49-.051 2.167-.105.155-.267.32-.694.747l-.62.619a.5.5 0 0 0 .708.707l.619-.619.043-.043c.37-.37.606-.606.771-.849.675-.994.71-2.287.06-3.278-.159-.241-.39-.472-.741-.823l-.045-.045-.044-.045c-.352-.351-.583-.582-.824-.74-.99-.65-2.284-.616-3.278.06-.243.164-.48.4-.85.77l-.042.043-.619.62a.5.5 0 1 0 .707.706l.62-.618c.426-.427.592-.59.746-.695ZM4.318 7.147a.5.5 0 0 0-.707-.707l-.619.618-.043.043c-.37.37-.606.606-.771.85-.675.993-.71 2.287-.06 3.277.159.242.39.473.741.824l.045.045.044.044c.352.351.583.583.824.741.99.65 2.284.616 3.278-.06.243-.165.48-.401.849-.771l.043-.043.619-.619a.5.5 0 1 0-.708-.707l-.618.619c-.427.427-.593.59-.747.694-.676.46-1.532.469-2.167.051-.144-.094-.298-.245-.71-.657-.412-.412-.562-.566-.657-.71-.417-.635-.408-1.49.051-2.167.105-.154.267-.32.694-.747l.619-.618Zm5.304-1.061a.5.5 0 0 0-.707-.708L5.379 8.914a.5.5 0 1 0 .707.707l3.536-3.535Z" fill="#000"/></svg>
const chain_link = source;

const link = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M4.625 4h-.061c-.523 0-.857 0-1.146.055-1.18.225-2.12 1.116-2.36 2.275C1 6.613 1 6.94 1 7.437v.126c0 .497 0 .824.058 1.107.24 1.16 1.18 2.05 2.36 2.275.289.055.623.055 1.146.055H5.5a.5.5 0 1 0 0-1h-.875c-.604 0-.836-.002-1.02-.037-.802-.154-1.413-.752-1.568-1.496C2.002 8.297 2 8.083 2 7.5c0-.582.002-.798.037-.967.155-.744.766-1.342 1.569-1.496C3.789 5.002 4.02 5 4.625 5H5.5a.5.5 0 0 0 0-1h-.875Zm5.75 1c.604 0 .835.002 1.019.037.803.154 1.414.752 1.568 1.496.035.17.038.385.038.967 0 .583-.003.798-.038.967-.154.744-.765 1.342-1.568 1.496-.184.035-.415.037-1.02.037H9.5a.5.5 0 0 0 0 1h.935c.523 0 .857 0 1.146-.055 1.18-.225 2.12-1.116 2.36-2.275C14 8.387 14 8.06 14 7.563v-.126c0-.497 0-.824-.059-1.107-.24-1.16-1.18-2.05-2.36-2.275C11.293 4 10.958 4 10.435 4H9.5a.5.5 0 0 0 0 1h.875ZM5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1H5Z" fill="#000"/></svg>;
const link_off = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M13.354 2.354a.5.5 0 0 0-.708-.708l-11 11a.5.5 0 0 0 .708.708l11-11ZM2.037 8.467c.137.66.632 1.204 1.302 1.426l-.76.76a2.91 2.91 0 0 1-1.52-1.983c-.06-.283-.06-.61-.06-1.107v-.126c0-.497 0-.824.06-1.107.24-1.16 1.179-2.05 2.36-2.275C3.706 4 4.04 4 4.563 4H5.5a.5.5 0 0 1 0 1h-.875c-.604 0-.836.002-1.02.037-.802.154-1.413.752-1.568 1.496-.035.17-.037.385-.037.967 0 .583.002.798.037.967Zm10.925-1.934a1.935 1.935 0 0 0-1.301-1.426l.76-.76a2.91 2.91 0 0 1 1.52 1.983c.059.283.059.61.059 1.107v.126c0 .497 0 .824-.059 1.107-.24 1.16-1.18 2.05-2.36 2.275-.288.055-.623.055-1.146.055H9.5a.5.5 0 1 1 0-1h.875c.604 0 .835-.002 1.019-.037.803-.154 1.414-.752 1.568-1.496.035-.17.038-.384.038-.967 0-.582-.003-.798-.038-.967Z" fill="#000"/></svg>;
const smart_button = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 5h11a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1ZM0 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6Zm4.5.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm2.25.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm3.75-.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z" fill="#000"/></svg>
 

const envelopeIcon = <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H1Zm0 1h13v.925a.448.448 0 0 0-.241.07L7.5 7.967 1.241 3.995A.448.448 0 0 0 1 3.925V3Zm0 1.908V12h13V4.908L7.741 8.88a.45.45 0 0 1-.482 0L1 4.908Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/></svg>;
const targetIcon = <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.9 7.5a6.6 6.6 0 1 1 13.2 0 6.6 6.6 0 0 1-13.2 0Zm6.6-5.7a5.7 5.7 0 1 0 0 11.4 5.7 5.7 0 0 0 0-11.4ZM3.075 7.5a4.425 4.425 0 1 1 8.85 0 4.425 4.425 0 0 1-8.85 0ZM7.5 3.925a3.575 3.575 0 1 0 0 7.15 3.575 3.575 0 0 0 0-7.15Zm0 1.325a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM6.05 7.5a1.45 1.45 0 1 1 2.9 0 1.45 1.45 0 0 1-2.9 0Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/></svg>

const image = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5C1 1.67157 1.67157 1 2.5 1ZM2.5 2C2.22386 2 2 2.22386 2 2.5V8.3636L3.6818 6.6818C3.76809 6.59551 3.88572 6.54797 4.00774 6.55007C4.12975 6.55216 4.24568 6.60372 4.32895 6.69293L7.87355 10.4901L10.6818 7.6818C10.8575 7.50607 11.1425 7.50607 11.3182 7.6818L13 9.3636V2.5C13 2.22386 12.7761 2 12.5 2H2.5ZM2 12.5V9.6364L3.98887 7.64753L7.5311 11.4421L8.94113 13H2.5C2.22386 13 2 12.7761 2 12.5ZM12.5 13H10.155L8.48336 11.153L11 8.6364L13 10.6364V12.5C13 12.7761 12.7761 13 12.5 13ZM6.64922 5.5C6.64922 5.03013 7.03013 4.64922 7.5 4.64922C7.96987 4.64922 8.35078 5.03013 8.35078 5.5C8.35078 5.96987 7.96987 6.35078 7.5 6.35078C7.03013 6.35078 6.64922 5.96987 6.64922 5.5ZM7.5 3.74922C6.53307 3.74922 5.74922 4.53307 5.74922 5.5C5.74922 6.46693 6.53307 7.25078 7.5 7.25078C8.46693 7.25078 9.25078 6.46693 9.25078 5.5C9.25078 4.53307 8.46693 3.74922 7.5 3.74922Z" fill="currentColor"></path></svg>



const EditorIcons = {
    format_bold,
    format_italic,
    format_underlined,

    code,
    text,
    headings,
    looks_one,
    looks_two,

    format_quote,
    format_list_bulleted,
    format_list_numbered,

    format_align_left,
    format_align_center,
    format_align_right,
    format_align_justify,

    align_top,
    align_middle,
    align_bottom,
    rotate_box,
    rotate,

    source,
    link,
    link_off,
    smart_button,

    plus,
    envelopeIcon,
    targetIcon,
}

export {
    format_bold,
    format_italic,
    format_underlined,

    code,
    text,
    headings,
    looks_one,
    looks_two,

    format_quote,
    format_list_bulleted,
    format_list_numbered,

    format_align_left,
    format_align_center,
    format_align_right,
    format_align_justify,

    image, 

    align_top,
    align_middle,
    align_bottom,
    rotate_box,
    rotate,

    source,
    link,
    link_off,
    smart_button,

    plus,
    envelopeIcon,
    targetIcon,
}

export const EditorIcon = ({icon}) => hasAttr(EditorIcons, icon) ? EditorIcons[icon] : null;
export default EditorIcons;