import { getDocsUrl } from "@api/host";
import Link from "next/link";

export const SIDEBAR_ITEM = "sidebarItem";
export const SIDEBAR_STACK = "sidebarContainer";
export const SIDEBAR_REPEATER = "sidebarRepeater";
export const SIDEBAR_PANEL = "sidebarPanel";


export const BuilderSubNav = [
    {
        _id: 'elements',
        type: SIDEBAR_PANEL,
        value: 'elements',
        tooltip: 'Add Elements',
        icon: 'elements',
    },
    {
        _id: 'layers',
        type: SIDEBAR_PANEL,
        value: 'layers',
        tooltip: 'Layers',
        icon: 'layers'
    },
    {
        _id: 'pages',
        type: SIDEBAR_PANEL,
        value: 'pages',
        tooltip: 'Structures',
        icon: 'global',
    },
    {
        _id: 'styles',
        type: SIDEBAR_PANEL,
        value: 'styles',
        tooltip: 'Global Styles',
        icon: 'styles'
    },
    {
        _id: 'content',
        type: SIDEBAR_PANEL,
        value: 'content',
        tooltip: 'Content Manager',
        icon: 'content'
    },
    {
        _id: 'options',
        type: SIDEBAR_PANEL,
        value: 'options',
        tooltip: 'Options',
        icon: 'gear'
    },
    {
        _id: 'dev',
        type: SIDEBAR_PANEL,
        value: 'dev',
        tooltip: 'Dev Console',
        icon: 'code'
    },
];

