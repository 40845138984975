import backendAddress, { backendSocketAddress } from '@api/address';
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { containsSubstr, subStringTill } from '@util/Text';
import { createUploadLink } from 'apollo-upload-client';
import { deleteSession } from '../utils/server/session';
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import axios from 'axios';
import { createClient } from 'graphql-ws';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { recoverToken } from './token';

const combineHeaders = ({headers}) => {
    const token = recoverToken()

	const authorization = token ? `Bearer ${token}` : '';
	
	return {
		headers: {
			...headers,
			authorization,
            'Apollo-Require-Preflight': 'true',
		}
	}
}
const authLink = setContext((_, { headers }) => combineHeaders({headers}));



//  createHttpLink
const httpLink = createUploadLink({
    uri: backendAddress,
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
      ...config,
      onUploadProgress: init.onUploadProgress,
    })),
});

// const wsLink =  typeof window !== "undefined" ? (
//     new GraphQLWsLink(createClient({
//         url: backendSocketAddress,
//     }))
// ) : null;


// const hasSocketLink = Boolean(typeof window !== "undefined" && wsLink != null)

// const splitLink = hasSocketLink ? split(({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     httpLink,
// ) : (
//     httpLink
// );

 
export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        // addTypename: false
    }),
    defaultOptions: {
        watchQuery: {
            nextFetchPolicy: (currentFetchPolicy) => (
                    currentFetchPolicy === 'network-only' ||
                    currentFetchPolicy === 'cache-and-network'
                ) ? 'cache-first' : currentFetchPolicy

        }
    },
    connectToDevTools: process.env.NODE_ENV !== 'production'
});




// const validate = (headers) => isEmptyString(headers?.authorization) && !isServer()

// export const getGqlClient = () => {

// 	const requestHeaders = getHeaders();

// 	const client = new GraphQLClient(backendAddress, {
// 		headers: requestHeaders
//   	})

// 	if(validate(requestHeaders)) client.setHeader("authorization", getHeaders().authorization)

// 	const query = async (name, query, variables) => {
// 		if(validate(requestHeaders)) client.setHeader("authorization", getHeaders().authorization)
// 		try {
// 			const data = await client.request( query, variables );
// 			return hasAttr(data, name) ? data[name] : data;
// 		} catch (error) {
// 			return handleGqlErrors(error?.response)
// 		}
// 	}
// 	const mutate = async (name, mutation, variables) => {
// 		if(validate(requestHeaders)) client.setHeader("authorization", getHeaders().authorization)
// 		try {
// 			const data = await client.request( mutation, variables );
// 			return data[name];
// 		} catch (error) {
// 			return handleGqlErrors(error?.response)
// 		}
// 	}
// 	return {query, mutate};
// }


export const getSSrClient = {
	query: getDataFromTree,
	mutate: getDataFromTree
}
// {
//     query : async (name, query, variables = null, multi = false) => {
// 		try {
// 			const data = await request(backendAddress, query, variables);
// 			return !multi ? data[name] : data;
// 		} catch (error) {
// 			handleGqlErrors(error.response)
// 		}
// 	},
// 	mutate : async (name, mutation, variables=  null) => {
// 		try {
// 			const data = await request(backendAddress, mutation, variables);
// 			return data[name];
// 		} catch (error) {
// 			handleGqlErrors(error.response)
// 		}
// 	}
// }

const handleGqlErrors = (error) => {
    const isProduction = Boolean(
        process.env.NEXT_PUBLIC_NODE_ENV === 'production' ||
        process.env.NODE_ENV === 'production'
    )

    if(Array.isArray(error?.errors)){
        error.errors = error.errors.map((err, index) => {
            //Authentication
            if (
                err.extensions.code === 'UNAUTHENTICATED' || 
                err.extensions.code === 'FORBIDDEN'
            ) {
                deleteSession();
				if(window != 'undefined') window.location.href = '/';
            }
            if(isProduction && index === 0){
                if(containsSubstr(err.message, 'Cannot query field')) {
                    return {
                        ...err,
                        message: `${subStringTill(err.message, '.')}.`
                    }
                }
				if(containsSubstr(err.message, 'Variable "$email"')){
					return {
                        ...err,
                        message: `Something is wrong with the auth query. \n
						We did not receive an valid email address.`
                    }
				}
            }
            return err;
            // if (networkError && networkError.response === 'invalid_token') {
            //     deleteSession();
            //     window.location.href = '/';
            // }
        })
    } 
    return error;
}
 
// const useClient = getGqlClient();
export default client;
