export let INITIAL_ACCORDION_STATE = {
        align: false,

        repeater: true,
        clone: true,
        state: true,
        mixins: false,
        preview: false,


        layoutStates: true,
        dataState: false,
        layout: false,
        stack: false,
        grid: false,
        position: false,
        margin: false,
        tags: false,

        sizing: false,
        scroll: false,
        padding: false,
        visibility: false,
        anchor: false,
        css: false,

        state: false,
        colors: false,
        background: false,
        image: false,
        border: false,
        radius: false,
        shadow: false,

        interaction: false,
    };