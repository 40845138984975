import { useContextReference } from "@mosanic/core/Map/Context";
import { useLocale } from "@mosanic/i18n";
import { isString } from "@util/Text";
import styled from "styled-components";

const Input = styled.input` 
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    width: inherit;
    background: none;

    &:focus {
        outline: none;
    }
`
const Button = ({fields, editor, ref, active}) => {
    // const reference = useContextReference(true)
    
    const {source, handler} = editor || {source: {content: ''}};
    const handleInput = (e) => handler({value: e.target.value, extend: 'content'});
    let content = source?.content;

    const translate = useLocale(JSON.stringify(content ? content : {}));
    
    return (
        <>
        {(active && source && isString(content)) ? 
            <Input onChange={handleInput} value={content} style={{width: `${content?.length ? content.length : 2}ch`}} /> : 
            isString(content) ? content : isString(translate) ? translate : ''
        }
        </>
    )
}
export default Button;