import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import { DELETE_DESIGN_TOKEN, SET_DESIGN_TOKEN, UPDATE_DESIGN_TOKEN } from "./action";
    

const initialState = [];


const tokensReducer = (tokens = initialState, action) => produce(tokens, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...tokens, ...action.payload};

        case SET_DESIGN_TOKEN:
            draft = [...draft, action.payload]
            return draft;

        case DELETE_DESIGN_TOKEN:
            draft = draft.filter(token => token._id != action.payload)
            return draft;

        case UPDATE_DESIGN_TOKEN:
            const previous = draft.filter(token => token._id === action.payload._id);
            const filtered = draft.filter(token => token._id != action.payload._id);
            draft = [{...previous, ...action.payload}, ...filtered];
            return draft;

        default:
            return tokens;
    };  


});
export default tokensReducer;

const presentSelector = state => state.present;
export const tokensSelector = state => presentSelector(state).tokens
export const metricTokensSelector = state => presentSelector(state).tokens?.filter(t => t.type === 'metric')


