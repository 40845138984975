import styled, {css} from "styled-components";

const Base = css` 
    display: flex;
`;

const RowNoWrap = css` 
    ${Base}
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
`;

const RowWrap = css` 
    ${Base}
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`

const SpaceBetween = css` 
    ${RowNoWrap}
    justify-content: space-between;
    align-content: stretch;
`;

const CenterCenter = css` 
    ${Base}
    align-items: center; /* y */
    justify-content: center;  /* x */
`;

export const Flex = styled.div` 
    ${RowNoWrap}

    ${props => props.justify && css` 
        justify-content: ${props.justify};
    `}
`

const FlexStyles = {
    Base,
    RowNoWrap,
    SpaceBetween,
    CenterCenter,
    RowWrap,
};

export default FlexStyles;

export {
    SpaceBetween,
    CenterCenter,
    RowWrap
}