

import { useDataProvider } from '@mosanic/core/Provider';
import { Button } from '@mosanic/fields';
import { Spinner } from '@mosanic/items';
import { isExternalImageSrc, parseImageSrc } from '@mosanic/utils/Image';
import { isClone } from '@mosanic/utils/value';
import { hasAttr } from '@util/Obj';
import { matchSubstrList } from '@util/Text';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import ImageMasonry from "./Masonry";

const ImgsViewer = dynamic(() => import("react-images-viewer"));

const buttonPosition = {
    position: 'absolute',
    bototm: 5,
    right: 5,
    zIndex: 3
}

const metricCheck = val => matchSubstrList(val, 'px|px|vh|vw|%'.split('|')) ? val : val+'px'
const Gallery = ({editor, active = false, breakpoint}, children) => {
    let {source, handler} = editor || {source: {content: null}, handler: null};
    let parsed = true;
    const getValue = (val, fb = 0) => hasAttr(val, breakpoint) ? 
        val[breakpoint] : 
        val?.base ? 
            val.base : 
            val ? val :
                fb;

    const data = useDataProvider()

    let imageSource  = source?.images;
    let images = null, full_source = [], min_source = [];
    if(Array.isArray(data)){
        imageSource = data;
        //Get from provider
        full_source = imageSource?.map(img => parseImageSrc( isExternalImageSrc(img) ? img : img?.url , true))
    }  else {
        full_source = imageSource?.map(img => parseImageSrc(img?.url, true))
    }
    images = full_source?.map(img => ({src: img?.full}))
    min_source = full_source?.map(img => img?.min ? img?.min : img?.full)
    const [index, setIndex] = useState()
    if(!images) return null;

    // return JSON.stringify(imageSource)    
    // if(!source?.images) return null;
    const {cols, width, height, animate, scrollable} = source || {cols: 3, width: null, height: null, animate: false, scrollable: false}

   
    return (
        <>

        {images?.length >= 1 && (
        <ImgsViewer
			imgs={images}
			spinner={() => <Spinner/>}
			currImg={index}
			isOpen={isClone(index)}
			onClickPrev={() => setIndex(index - 1)}
            onClickNext={() => setIndex(index + 1)}
			onClose={() => setIndex(null)}

            backdropCloseable={true}
		/>)}

        <ImageMasonry
            handle={(i) => setIndex(i)}
            numCols={getValue(cols)}
            animate={animate}
            scrollable={getValue(scrollable, true) === 1 ? true : false}
            containerWidth={width ? metricCheck(getValue(width)) : null}
            containerHeight={height ? metricCheck(getValue(height)) : null}
            imageUrls={min_source}
        />
        {/* <Button label="View gallery" style={buttonPosition}/> */}
        </>
    )
}
export default Gallery;