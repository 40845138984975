import { getItemMapFromActionOrState } from '@mosanic/map/get';

import { default as actions, dropActions } from '../drop/action';
import { dataActions, dataSelector} from '@redux/map/data';
import { getItem } from '@redux/map/data/selector';
import { includesAction } from '@redux/util';


export const cutComponentActionFlow = ({getState, dispatch}) => next => action => {
    next(action);
  
    if(includesAction(action, actions.DROP_CUT)){
        const state = getState();
 
        //Get inspected for saving reference
        const itemRef = getItemMapFromActionOrState({action, state})

        //Set cut state
        const component = {...getItem(dataSelector(state), itemRef), isCut: true }
        dispatch(dataActions.updateDataAction({component}, true));

        //Pre store
        dispatch(dropActions.handlePreStoreAction({...itemRef, sourceItemPath: itemRef.path, isCut: true}))
    }
};

export const cutMiddleware = [
    cutComponentActionFlow
];