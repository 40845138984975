

import { colors } from "@mosanic/styles";
import { hasAttr } from "@util/Obj";
import { capitalizeFirst, revertSnakeCase } from "@util/Text";
import { lighten } from "polished";
import {
  Table,
  Td,
  Th,
  Tr
} from "../Table";
import { AdvancedLabel, Label } from "@mosanic/items";
import { HelpPopper } from "@mosanic/items/ControlPanel";

const colorIndex = {
  gained: lighten(0.3, colors.util.green.base),
  gained_pending: lighten(0.4, colors.util.green.base),
  spend: lighten(0.3, colors.util.red.light),
  spend_pending: lighten(0.1, colors.util.red.trans),
  credits: colors.util.gray.lighter,
}
const getStatusProps = field => ({
  bg: colorIndex?.[field]
})
export const CreditStatus = ({data, properties}) => {
  if(!hasAttr(data, 'gained')) return null;
  const fields = properties ? properties : 'start|gained|gained_pending|spend|spend_pending|credits'.split('|').filter(field => hasAttr(data, field))

  return [
    <h4 key="heading" >
      Credits &nbsp;
      <HelpPopper helpText="Note that the spend pending credits are subtracted from your current credit count."/>
    </h4>,
    <br/>,
    <Table cells={fields?.length} key="data">
      <Tr>
        {fields?.map(field => (
          <Td {...getStatusProps(field)}>
            <Label label={capitalizeFirst(revertSnakeCase(field))} value={data?.[field]}/>
          </Td>
        ))}
      </Tr>
    </Table>
  ]
}