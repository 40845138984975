import { If } from '@JSX';
import {default as s} from '@mosanic/builder/Image/Image.style';
import { Button, Buttons } from '@mosanic/fields';
import { trash } from '@mosanic/Icons/EditIcons';
import { MediaDropTarget } from './MediaDropTarget';

// {
//         imageList,
//         onImageUpload,
//         onImageRemoveAll,
//         onImageUpdate,
//         onImageRemove,
//         isDragging,
//         dragProps,
//       }

export const MediaController = ({actions, canUpload, showEditor, images, type = 'large'}) => (showEditor || images?.length <= 0) ? (
    <s.DropTargetWrapper 
        // minSize={(!images) || images?.length >= 2} 
        minSize
        editor={showEditor} 
        className="controls"
        canUpload={canUpload}
    >
        <If condition={canUpload}>
            <MediaDropTarget 
                type={type}
                onImageUpload={actions?.onImageUpload} 
                dragProps={actions?.dragProps}
                images={images}
            />
        </If>
        <If condition={!canUpload}>
            <div style={{position: 'absolute', right: 0, top: -8, zIndex: 3}}>
                <Buttons size="small">
                    <Button 
                        handler={actions.onImageUpload} 
                        size="small" 
                        label="Update"
                        ghost
                    />
                    <Button 
                        variant="text"
                        size="small"
                        ghost
                        handler={actions.handleRemoveAll}
                    >
                        {trash} Remove all
                    </Button>
                </Buttons>
            </div>
        </If>
    </s.DropTargetWrapper>
) : null
