import { toVarCase } from "@mosanic/utils";


export const TOKEN_ID                 = `[tokens]`;

export const CREATE_DESIGN_TOKEN       = `${TOKEN_ID} Create`;
export const SET_DESIGN_TOKEN          = `${TOKEN_ID} Set`;
export const UPDATE_DESIGN_TOKEN       = `${TOKEN_ID} Update`;
export const DELETE_DESIGN_TOKEN       = `${TOKEN_ID} Delete`;


export const createDesignToken = ({label, value, endValue, type = "metric"}) => ({
    type: CREATE_DESIGN_TOKEN,
    payload: {
        label, value, endValue, type
    },
    meta: {feature: TOKEN_ID,}
});

export const setDesignToken = ({token, _id, meta = null}) => ({
    type: SET_DESIGN_TOKEN,
    payload: {...token, _id},
    meta: {feature: TOKEN_ID, ...meta}
});

export const deleteDesignToken = ({_id}) => ({
    type: DELETE_DESIGN_TOKEN,
    payload: _id,
    meta: {feature: TOKEN_ID}
});

export const updateDesignToken = (token) => ({
    type: UPDATE_DESIGN_TOKEN,
    payload: token,
    meta: {feature: TOKEN_ID}
});
export const updateDesignMetricToken = (token, label, metric) => ({
    type: UPDATE_DESIGN_TOKEN,
    payload: {
        ...token, 
        label, 
        value: metric.value, 
        endValue: metric.metric, 
        selector: toVarCase(label)
    },
    meta: {
        feature: TOKEN_ID, 
        dispatched: 'updateDesignMetricToken'
    }
});

