

import Input from '@mosanic/fields/Input';

import { default as s } from '../Collection.styles';


import { uniqueIcon } from '@mosanic/Icons/StateIcons';
import { combineString, revertDevCases } from '@util/Text';

export const FieldHeaderLabels = ({level = 0, info = {
	field_name:'Field name', 
	field_label: 'Display Label',
	field_type: 'field type',
	field_controls: 'Controls'
}}) => (
	<s.FieldHeader expanded>
		<s.HeaderField>
			<s.HeaderLabel>
				{level ? `${level}.` : ''}{info.field_name}
			</s.HeaderLabel>
		</s.HeaderField>
		<s.HeaderLabel>
			{info.field_label}
		</s.HeaderLabel>
		<s.HeaderType>
			{info.field_type}
		</s.HeaderType>
		<div style={{width: '100px', paddingLeft: '20px'}}>
			<s.HeaderLabel>
			{info.field_controls}
			</s.HeaderLabel>
		</div>
	</s.FieldHeader>
)

export const FieldHeader = ({
	displayName = false,
	isFirstField = false,
    controls, 
    inputProps, 
	restricted,
	duplicate,
    
    expanded,
    toggle, 
    level, 
    
    fieldId, 
    index, 
    
    getField, 
    getType
}) => {
	const name = getField('name');
	const alert = [...restricted, ...duplicate]?.includes(name)
	const notAllowed = restricted?.includes(name)
	const repeated = duplicate?.includes(name)

	return (
		<s.FieldHeader expanded={expanded?.[index]} level={level}
			style={{border: `1px solid ${alert ? 'red' : 'white'}`}}
		>
			<s.HeaderField>
				{/* {displayName ? (
					<Input 
						name={`${fieldId}.label`} 
						label={revertDevCases(getField(fieldId, `name`))} type="text" 
						{...inputProps} 
						ninja={getField(index, 'label')}
						rules={{required: true}}
					/>
				): ( */}
					<Input 
						wrap={false}
						name={`${fieldId}.name`} 
						label={combineString([
							`Field name`, 
							notAllowed && '[restricted]', 
							repeated && '[repeated]'
						], ' ')} 
						type="text" 
						{...inputProps} 
						ninja={!alert && name}
						rules={{required: true}}

					/>
				{/* )} */}
			</s.HeaderField>
			<s.HeaderLabel onClick={() => name ? toggle(index) : null}>
				{!isFirstField ? (
					getField('label') ? getField('label') : revertDevCases(name)
				): ''}
			</s.HeaderLabel>
			<s.HeaderType onClick={() => name ? toggle(index) : null}>
				{isFirstField ? (
					<span>
						{uniqueIcon} Entry ID
					</span>
				) : getType(index)}
			</s.HeaderType>
			
			{controls}

		</s.FieldHeader>
	)
}