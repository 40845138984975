import { Items } from "@mosanic/constants";

import dynamic from "next/dynamic";
// const canRenderSchema = dynamic(() => import("@mosanic/_Schemas"));
import renderComponent from "@mosanic/builder/registery";

import { getTag } from "@util/Tag";

import { Condition, Else, If } from "@JSX";
import { hasSlots } from "@mosanic/utils/map/slots";
import { useProductionFieldsRef, useSchemaFieldsRef } from "@mosanic/utils/schema";
import { hasAttr } from "@util/Obj";
import Link from "next/link";
import { createContext } from "react";
import { useValidate } from "../../hooks/useValidate";
import Row from "./ItemRow";
import styled from "styled-components";

const Wrapper = ({props, children}) => {
    if(props?.child?.itemType === Items.ROW)
        return <Row props={{...props, children}} menu={{render: 'Column'}} production />

    return children;
}

export const ProdFieldsContext = createContext(undefined);

const Component = styled.div` 

`

const Seo = ({props, item}) => {
    const {child} = props;

    const Render = renderComponent(item?.type) 
    const reference = useProductionFieldsRef({
        active: false,
        item,
        production: true
    });

    let linkHref = hasAttr(reference?.fields, 'href') ? ({
        href: reference?.fields?.href,
        target: reference?.fields?.target
    }) : null;

    
    const slotComponent = hasSlots(child?.children)

    return (
        <Condition when={linkHref?.href} wrapper={children => (
            <Link
                href={linkHref.href} 
                target={linkHref.target}>
                    {children}
            </Link>
        )}>
            <Component
                as={getTag(item?.type)}
                type={item?.type}
            >   
                <Wrapper props={props}>
                    {slotComponent ? (
                        <ProdFieldsContext.Provider value={reference}>
                            {props.children}
                        </ProdFieldsContext.Provider>
                    ):(
                        <>
                        {Render &&  Render(reference) }
                        {props.children}
                        </>
                    )}
                </Wrapper>
            </Component>
        </Condition>
    )
}

export default Seo;