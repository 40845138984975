import { validateMosanicItem } from "@mosanic/_Schemas";
import { pathToItem } from "./paths";

//Moves
export const isPureMove = ({itemPath, dropPath}) => itemPath?.length === dropPath?.length;
export const isMoveWithinParent = ({itemPath, dropPath}) => pathToItem(itemPath) === pathToItem(dropPath);


//Validate by schema

export const validate = ({item, children}) => {
    if(children) return children.map(child => validate({item: child}));
    if(!item?.children) return validateMosanicItem(item);
    
    return {
        ...validateMosanicItem(item),
        children: validate({children: item?.children})
    };
}; 