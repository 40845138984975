import { Toggle } from ".";


const BeforeInput = (event) => {
    event.preventDefault()
    
    switch (event.inputType) {
        case 'formatBold':
            return Toggle.toggleFormat(editor, 'bold')
        case 'formatItalic':
            return Toggle.toggleFormat(editor, 'italic')
        case 'formatUnderline':
            return Toggle,toggleFormat(editor, 'underlined')
    }
};

export default BeforeInput;