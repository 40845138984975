
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const FieldsDragContext = ({id, move, children}) => {
    const handleDrag = ({ source, destination }) => {
		if (destination) {
			move(source.index, destination.index);
			// alert(`${source.index} > ${destination.index}`)
		}
	};

    return (
        <DragDropContext onDragEnd={handleDrag}>
			<Droppable droppableId={id}>
			 	{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
                        {children}
                        {provided.placeholder}
					</div>
				)}
			</Droppable>
        </DragDropContext>
    )
}

export const FieldDragItem = ({index, id, render}) => (
	<Draggable
		key={`drag[${index}]`}
		draggableId={`item-${index}`}
		index={index}
	>
		{(provided, snapshot) => (
			<div
				key={id}
				ref={provided.innerRef}
				{...provided.draggableProps}
			>
				{render(provided.dragHandleProps)}
			</div>
		)}
	</Draggable>
)