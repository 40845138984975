
import { Button, TextField } from '@mosanic/fields';
import { Tooltip } from '@mosanic/items';

import { default as s } from '../Collection.styles';

import { Condition } from '@JSX';
import { elementIcon } from '@mosanic/Icons';
import { ControlButtons } from '@mosanic/items/Control';
import { colors } from '@mosanic/styles';
import { CrossCircledIcon } from '@radix-ui/react-icons';
import { lowCaseContainsSubstr } from '@util/Text';
import { useState } from 'react';


export const useSearchItems = ({components}) => {
    const [input, setInput] = useState('')
    
    const isSubstr = val => !val ? false : lowCaseContainsSubstr(val, input)
	const isMatch = (c) => isSubstr(c.value) 

    const filteredComponents = input?.length >= 1 ? 
		components.filter(c => isMatch(c)) : 
		components;

    return [input, setInput, filteredComponents]
}  


export const FieldTypes = ({types, fieldType, fieldId, setValue}) => {

	const [tab, setTab] = useState('all')
	const filteredFieldTypes = tab === 'all' ?  types : types?.filter(t => t.type === tab)

	const [input, setInput, searchedItems] = useSearchItems({components: types})
	const filteredItems = input ? searchedItems : filteredFieldTypes

    return (
        <>
        
        <TextField 
            autoFocus
            label="Search field type" 
            value={input}
            style={{width: '100%', border: '2.5px solid #eee', marginBottom: 5}} 
            onChange={e => setInput(e.target.value)}
            endAdornment={input ? (
                <Button ghost icon={<CrossCircledIcon /> } onClick={() => setInput('')}/>
            ): null}
        />
        {!input && (
            <ControlButtons mt={2} setActive={setTab} active={tab} options="all|input|advanced|visual"/>
        )}
        <s.FieldTypes>
            {filteredItems?.map(type => (
                <Condition 
                    key={type.value} when={type?.coming} 
                    wrapper={children => <Tooltip force title="Coming soon">{children}</Tooltip>}
                >
                    <s.FieldType 
                        active={type?.value === fieldType}
                        coming={type?.coming} 
                        onClick={() => type?.coming ? null : setValue(`${fieldId}.type`, type?.value)} 
                    >
                        <div>
                            {elementIcon(type.value)}	
                        </div>
                        <h5 style={{margin: '5px 0 0'}} dangerouslySetInnerHTML={{ __html: type.value.replace(new RegExp(input, "gi"), (match) => `<mark style="background: ${colors.util.orange.mark}">${match}</mark>`) }} />
                            
                    </s.FieldType>
                </Condition>
            ))}	
        </s.FieldTypes>

        </>
    )
}

export default FieldTypes