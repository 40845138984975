const DATA_NORMALIZED = 'normalized';
const DATA_NORMALIZING = 'normalizing start';


export const dataNormalizing = ({data, feature}) => ({
    type: `${feature} ${DATA_NORMALIZING}`,
    payload: data,
    meta: {feature}
})


export const dataNormalized = ({data, feature}) => ({
    type: `${feature} ${DATA_NORMALIZED}`,
    payload: data,
    meta: {feature}
})

