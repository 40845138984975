import { colors } from "@mosanic/styles";
import { darken } from "polished";
import styled, { css } from "styled-components";
import { color, layout, space } from 'styled-system';

const ListItem = styled.li` 
    list-style-type: none;
    padding: 5px 10px;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    font-size: 12px;

    a {
        color: #212121;
        font-size: 13px;
        line-height: 30px;
        -webkit-font-smoothing: antialiased;

        svg {
            margin-top: -2px;
            /* margin-right: 8px; */
            transform: scale(0.8);
            transition: all ease-in-out 0.2s;
            path {
                transition: all ease-in-out 0.3s;
            }
        }
    }

    &:hover {
        background: ${colors.util.gray.lighter};

        a {
            color: ${colors.util.primary.base};

            svg path {
                fill:  ${colors.util.primary.base};
            }
        }
        svg {
            transform: scale(0.85);
            transform-origin: center center;
            
            path {
                fill:  ${colors.util.gray.darker};
            }
        }
    }
    
    ${props => props.active && css`
        color: ${colors.util.primary.base};
        background: ${colors.util.gray.lighter};

        ${ListItemText}, a {
            color: ${colors.util.primary.base};
        }
        ${ListItemText}{
            font-weight: 600;
        }

        ul > li {
            ${ListItemText}, a {
                color: #212121;
            }
            ${ListItemText}{
                font-weight: inherit;
            }
        }

        svg path {
            fill:  ${colors.util.primary.base};
            transform: scale(1.1);
            transform-origin: center center;
        }
        &:hover {
            svg path {
                transform: scale(0.9);
            }
        }
    `}

    ${props => props.borderLeft && css` 
        border-left: 2px solid ${colors.util.primary.base};
    `}
    ${props => props.disabled && css` 
        opacity: 0.5!important;
        background: none;
    `}

    ${props => props.fullWidth && css`
        width: 100% !important;
        min-width: 100px !important;
    `}

    ${props => props.inline ? css` 
        * {
            display: inline-block;
        }
    ` : css` 
        width: 100%;
    `}

    ${props => props.size === 'small' && css` 
        padding: 0 7px;

        svg {
            transform: scale(0.5)!important;
        }
    `}
    ${props => props.icons === 'large' && css` 
        svg {
            transform: scale(0.7)!important;
            margin-right: 5px;
        }
    `}
    ${props => props.title && css` 
        font-size: 12px;
        font-weight: 400;
        background: ${colors.util.gray.light};
        margin: 0 -5px;
        width: calc(100% + 10px);
        border-bottom: 2px solid ${colors.util.gray.light} !important;
        /* padding: 5px 0; */
        &:hover {
            background: ${colors.util.gray.light};
        }
    `}
    position: relative;
    ${layout}
    ${space}
`
const ListItemContent = styled.span` 

`
const Chevron = styled.div` 
    transition: all ease-in-out 0.2s;
    position: absolute;
    right: 5px;
    top: calc(50% - 10px);
    &:hover {
        transform: scale(1.2);
        svg path {
            fill: ${colors.util.primary.base};
        }
    }
`
const ListDivider = styled(ListItem)` 
    border-bottom: #e7e9ed 1px solid;
    padding: 0!important;
    margin: 5px 0;
`;

const ListItemSub = styled.div` 
    margin-left: 28px;
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.util.gray.base} !important;
    &:hover {
        color: ${colors.util.gray.base} !important;
    }
`
const ListItemText = styled.span` 
    margin-left: 10px;
    font-size: 12px;

    color: #212121;
    font-size: 13px;
    line-height: 30px;
    -webkit-font-smoothing: antialiased;
`

const ListItemIcon = styled.span` 
    margin: 2px 4px 0px 0;
    width: 25px;
`
const ListItemRight = styled.span` 
    font-family: ${props => props.code && `'Courier New', monospace`};
    color: ${colors.util.gray.base};
    font-size: 12px;
    float: right;
`

const List = styled.ul` 
    padding: 0 15px 0 0;

    ${props => props.sub && css` 
        
    `}

    ${props => props.compact && css` 
        padding: 0 5px 0 0;
        ${ListItem} {
            padding: 5px 4px;
        }
    `}
    ${props => props.min && css` 
        display: block;
        padding: 0 0 0 0;
        ${ListItem} {
            padding: 5px 2px;
            font-size: 11px;
            width: 100%;
            min-width: 100%;
            display: flex;

            &::after {
                clear: both;
                display: block;
                content: '';
            }
        }
    `}

    ul > ${ListItem} {
        /* margin-left: 15px!important; */
        &:hover {
            background: ${darken(0.02, colors.util.gray.lighter)};
        }
    }

    ${layout}
    ${space}
`;

const ListStyles = {
    List, 
    ListDivider,

    ListItemContent,
    ListItemIcon,
    ListItemRight,
    ListItemText,
    ListItemSub,
    ListItem,
    Chevron
}
export default ListStyles