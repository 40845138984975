import { getImageUrl } from '@api/host';
import { useUploadFactory } from '@mosanic/components/Media/Handlers';
import { Button } from '@mosanic/fields';
import FileField from '@mosanic/fields/File';
import { GalleryIcon } from '@mosanic/Icons/ElementIcons';
import { ControlDialog, Spinner } from '@mosanic/items';
import styled from 'styled-components';
import { FieldControls } from '@mosanic/core/Collections/FieldControls';
import { reorder } from '@util/Arr';
import { useEffect, useState } from 'react';
import { parseImageSrc } from '@mosanic/utils/Image';
import dynamic from 'next/dynamic';
import { isClone } from '@mosanic/utils/value';

const ImgsViewer = dynamic(() => import("react-images-viewer"));

const Controls = styled.div` 
    position: absolute;
    width: 150px;
    height: 40px;
    background: #fff;
    opacity: 0;
    span:not(:first-child) {
        transform: rotate(-90deg)!important;
    }
`
const Thumb = styled.div` 
    display: inline-block;
    position: relative;
    margin: 5px;

    &:hover{
        ${Controls} {
            opacity: 1;
        }
    }
    img {
        width: 100px;
        height: 100px;
    }
`

const MediaMultiUploader = ({
    imageUrls, 
    type = 'image',
    callback = null,
    label = null,
}) => {
    const [images, meta, progress, handlers, loading, error] = useUploadFactory({callback, urls: imageUrls});
    const [index, setIndex] = useState(null)

    useEffect(() => {
        let timer = null;
        timer = setTimeout(() => {
            if(images && callback) callback(images);
        }, 1500);
        return () => clearTimeout(timer)
    },[images?.length])

    const ImagesGallery = () => images?.length >= 1 ? (
        <ImgsViewer
            imgs={images?.map(img => ({src: parseImageSrc(img, true)?.full}))}
            spinner={() => <Spinner/>}
            currImg={index}
            isOpen={isClone(index)}
            onClickPrev={() => setIndex(index - 1)}
            onClickNext={() => setIndex(index + 1)}
            onClose={() => setIndex(null)}
        />
    ) : null
    const Preview = ({trigger}) => (
        <ControlDialog title="Gallery setup" trigger={<span>{trigger}</span>}>
            {Array.isArray(images) && images?.map((i, index) => (
                <Thumb>
                    {callback && (
                    <Controls>
                        <FieldControls 
                            wrap={false}
                            move={(currIndex, newIndex) => {
                                const imgs = reorder(images, currIndex, newIndex);
                                handlers?.setImages(imgs)
                                callback(imgs)
                            }}
                            remove={() => handlers?.handleRemove(index)}
                            index={index}
                            count={images?.length}
                        />
                    </Controls>
                    )}
                    <span onClick={() => setIndex(index)}>
                        <img src={parseImageSrc(i?.sizes ? i : i?.url, true)?.thumb} />
                    </span>
                </Thumb>
            ))}
        </ControlDialog>
    )


    return callback ? (
        <>
        <ImagesGallery />
        <FileField 
            label={label}
            onChange={(e) => handlers.uploadFile(e.target.files[0], '')} 
            initiate={(url) => {
                handlers?.setImages([{url}]);
                callback(images ? [...images, {url}]: [{url}]);
            }}
            value={images?.length ? `${images?.length} images` : null}
            loading={loading}
            thumb={<Preview trigger={<GalleryIcon />} />}
            type={type}
        />

        </>
    ) : (
        <>
        <ImagesGallery />
        <Preview trigger={images?.length ? `${images?.length} images` : 'No images'} />
        </>
    )
};

export default MediaMultiUploader;

           
             