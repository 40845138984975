import { hasAttr } from "@util/Obj"
import { isString } from "@util/Text"
import { useRouter } from "next/router"

export const useCurrentLocale = () => {
    const { locales = [], defaultLocale, ...nextRouter} = useRouter()
    const locale = locales.includes(nextRouter.locale || '')
        ? nextRouter.locale
        : defaultLocale
        
    return locale;
}
export const useLocale = (obj) => {
    const locale = useCurrentLocale()
    const {defaultLocale} = useRouter()

    const data = JSON.parse(obj);

    return hasAttr(data, locale) ? data[locale] : 
        hasAttr(data, defaultLocale) ? data[defaultLocale] :
        '';
}

export const Translate = ({txt}) => {
    const text = useLocale(txt);
    return (
        <span style={{whiteSpace: 'pre-line'}}>
            {text}
        </span>
    );
}