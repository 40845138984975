import { FileTextIcon, LoopIcon, OpenInNewWindowIcon } from "@radix-ui/react-icons";
import isEmpty from "@util/Empty";


const validateContextProviders = (options, context) => {
    if(!isEmpty(context?.dataProvider)) options.push({value: 'provider', icon: <OpenInNewWindowIcon />});
    if(!isEmpty(context?.relationProvider)) options.push({value: 'relation', icon: <OpenInNewWindowIcon />});
    if(!isEmpty(context?.repeatProvider)) options.push({value: 'repeater', icon: <LoopIcon />});
    if(!isEmpty(context?.pageProvider?.entry)) options.push({value: 'page', icon: <FileTextIcon />});
    
    return options;
}
const getSourceTabOptions = (context) => {
    let options = [{value: 'source'}]
    return validateContextProviders(options, context);
}

export const useSourceTabs = ({value, handler, context = null}) => {
    const {source} = value || {source: 'source'}
    const setSourceTab = source => handler({
        ...value,
        collection: undefined,
        entry: undefined,
        column: 'field',
        source
    })
    if(!value?.source) setSourceTab('source')

    return {
        value: source,
        options: getSourceTabOptions(context),
        handler: setSourceTab,
        key: 'source'
    }
}
 
