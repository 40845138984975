import { helpCircle, largeCircle } from '@mosanic/Icons';
import styled, {css} from 'styled-components';
import { HelpPopper } from "./ControlPanel";
import { space, layout, color } from 'styled-system'

const getTypeStyles = (type) => {
  if(type === 'top') return css` 
    border-bottom: 2px solid #303031;
  `
}
export const Val = styled.span` 
  display: block;
  font-size: 14px;
  padding: 3px 4px;
`

export const Lbl = styled.span` 
  ${space}
  ${layout}
  ${color}
  ${props => props.bold && css`
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.87);
  `}
  ${props => getTypeStyles(props.type)}

  ${props => !props.as && css` 
    font-size: 12px;  
  `}
  svg {
    transform: scale(0.9);
  }
`
export const LblIcon = styled.span` 
  padding: 2px;
  margin: 0 4px 0 0;
  svg {
    transform: scale(0.9);
    margin-top: -2.5px;
  }
`

const TriggerIcon = ({largeIcon}) => (
  <span className='help__icon -sm'>
    {largeIcon ? largeCircle : helpCircle}
  </span>
);

export const AdvancedLabel = ({
  children, 
  label = null,
  helpText, 
  help = null,
  className = "", 
  renderHelp = false,

  //Styling
  style = { display:'block' }, 
  bold = false,
  header = false, 
  title = false, 
  type = null,

  icon = null,
  largeIcon = false,
  hover = false,
  span = false,
  ...other
}) => {

  return (
    <Lbl className={`label ${className} ${header ? '-title' : ''}`} 
      bold={bold} 
      style={style} 
      type={type}
      px={title ? 4 : 0}
      {...other}
    >
      {icon && (
        <LblIcon>
          {icon}
        </LblIcon>
      )}
      {label ? label : children}

      <HelpPopper helpText={helpText || help} trigger={<TriggerIcon largeIcon={largeIcon}/>} hover={hover} renderHelp={renderHelp} span={span}/>
    </Lbl>
  );
};

export const Label = ({
  children, 
  label = null,
  className = "", 

  //Styling
  style = { display:'block' }, 
  bold = false,
  header = false, 
  title = false, 
  type = null,

  icon = null,
  largeIcon = false,
  hover = false,
  span = false,
  value = null,
  ...other
}) => {
  return (
    <Lbl className={`label ${className} ${header ? '-title' : ''}`} 
      bold={bold} 
      style={style} 
      type={type}
      px={title ? 4 : 0}
      {...other}
    >
      {icon && (
        <LblIcon>
          {icon}
        </LblIcon>
      )}
      {label ? label : children}
      <Val>
        {value}
      </Val>
    </Lbl>
  );
};
