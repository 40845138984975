import { PAGES } from "../../adapters/queries/pages";
import { apiRequest } from "../api/action";
import { showSpinner } from "../ui/uiAction";
// import { GET_PAGES, PAGES_ID } from "./action";




// this middleware only care about the getPages action
export const getPagesFlow = ({dispatch}) => next => action => {
  next(action);

  // if (action.type === GET_PAGES) {
  //   next([
  //     apiRequest('pageMany', PAGES, {}, null, PAGES_ID),
  //     showSpinner({location: 'canvas', feature: 'pages'}),
  //   ]);
  // }

};



export const pageMdl = [
  getPagesFlow,
];
