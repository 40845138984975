import { containsSubstr, isString } from "@util/Text";
import { Fragment, useState } from "react";
import styled, {css} from "styled-components";
import Checkbox from "./Checkbox";
import {default as s} from "./Checkbox.style";

const Label = styled.div` 
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    margin-right: 5px;
`
const Direction = styled.div` 
    display: flex;
    ${props => props.direction === 'vertical' ? css` 
        flex-wrap: wrap;
        label {
            width: 100%;
        }
    ` : css` 
        flex-wrap: nowrap;
        label {
            width: 100%;
        }
    `}

    ${props => props?.behavior === 'justify' && css` 
        justify-content: space-between;
        flex-wrap: wrap;
        label {width: auto;}
        max-width: 100%;
    `}
`
const Checkboxes = ({
    className,
    options, 
    value, 
    onChange = null,
    disabled = false,
    size ="small",
    direction = 'horizontal',
    style = null,
    behavior = null,
    ...other
}) => (
    <Direction direction={direction} behavior={behavior}>
        {options?.map((option) => renderOption({option, value, onChange, size, style, ...other}))}
    </Direction>
);

const renderOption = ({option, value, onChange, size, style, ...other}) => {
    let label = isString(option?.label) ? option.label : option;
    const canSplit = containsSubstr(label, '|')
    const val = (
        option?.value ? option.value : 
        canSplit ? 
            label.split('|')?.[0] : 
            label
    )
    return (
        <Checkbox 
            size={size}
            {...other}
            disabled={option?.disabled}
            unfocus={option?.unfocus}
            img={option?.img}
            count={option?.count}
            label={canSplit ? (
                <Label style={style}>
                    <b>{label?.split('|')[0]}</b><br/>
                    {label?.split('|')[1]}
                </Label>
            ) : label } 

            checked={value?.[val]} 
            onChange={state => onChange({
                ...value, 
                [val]: !value?.[val]
            })}
            // onChange={state => onChange(value?.find(o => o.label === label ? !o.value : o))}
        />
    )
}
export default Checkboxes;