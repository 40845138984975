
import { Select } from "@mosanic/fields";
import { default as s } from "./Pagination.styles";
import { DOTS, usePaginationRange } from "./usePagination";


export const PaginationLimit = ({count, limit, setLimit}) => (
    <Select 
        options={count <= 100 ? [
        ...['5', '10', '25', '50', '100'].filter(value => parseFloat(value) < count),
            `${count}`
        ]:[
        ...['5', '10', '25', '50', '100'].filter(value => parseFloat(value) < count),
        ]}
        onChange={value => setLimit(value)}
        label="Limit"
        value={limit}
        minWidth="55px"
        indicator="none"
        container={{border: 'none'}}
        clearable={false}
    />
)
const Pagination = ({methods, validate, type = 'default'}) => {

    const paginationRange = usePaginationRange(validate);

    const {canPrev, canNext, currentPage}  = validate;
    const [nextPage, prevPage, setPage] = methods;
  
    // If there are less than 2 times in pagination range we shall not render the component
    if (paginationRange?.length < 2) {
      return null;
    }

    return (
      <s.Pagination type={type}>


        <s.Item 
            disabled={!canPrev}
            onClick={() => prevPage()}
        >
            <div className="arrow left" >
            </div>
        </s.Item>
        {paginationRange && paginationRange?.map(pageNumber => {
           
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) return (
                <s.Item className="pagination-item dots">&#8230;</s.Item>
            );
          
            return (
                <s.Item 
                    selected={pageNumber === currentPage}
                    onClick={() => setPage(pageNumber)}
                >
                    {pageNumber}
                </s.Item>
            );

        })}
        {/*  Right Navigation arrow */}
        <s.Item
          disabled={!canNext}
          onClick={() => nextPage()}
        >
            <div className="arrow right" >
            </div>
        </s.Item>
      </s.Pagination>
    );
  };
  
  export default Pagination;