
export const getInitialContent = (content = '', wrapper = 'span') => {
    if(!Array.isArray(content)) {
        content = [{
            type: wrapper,
            children: [{
                text: content ? content?.toString() : ''
            }]
        }];
    };
    return content;
}