export const breakpoints = {
  sm: 576, //Small screen start
  md: 768, //Medium screen start
  lg: 992, //Large screen start
  xl: 1200, //Extra Large screen start
};

export const device = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
};

export function isTouchDevice() {
  return typeof window === "undefined" ? false : (
    (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
  );
}

export const getOperatorPlatform = () =>
  typeof navigator != 'undefined' ? 
  navigator?.userAgentData?.platform :
  'unknown'
     

export const isServer = () => typeof window === 'undefined'