import { getRGB, invertColor } from '@util/color/Color';
import styled, { css } from "styled-components";

const ColorBoxes = styled.div` 
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;
    min-width: 150px;
    min-height: 30px;
`
const ColorBox = styled.div` 
    width: 28px;
    cursor: pointer!important;
    height: 28px;
    cursor: pointer;
    position: relative;
    outline: none!important;
    border-radius: 4px;
    margin: 2px;
    transform: scale(0.9);
    transition: all ease-in-out 0.3s;

    &:hover {
        transform: scale(1.01);
    }

    ${props => props.active && css` 
        transform: scale(1.01);
        border: 1.5px solid rgba(3, 3, 3, 0.496);
        box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    `}
`;

const ColorInput = styled.input` 
    width: 90px;
    appearance: none;
    border: 1px solid;
    border-color: ${props => props.color};
    color: ${props => invertColor(props,color, true)};
    background: ${props => props.color};
    box-shadow: ${props => `1px 1px 7.5px ${getRGB(props.hex, '0.7')}`};
`

const ColorPickerStyles = {
    ColorBoxes,
    ColorBox,

    ColorInput
}

export default ColorPickerStyles;