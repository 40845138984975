import { useTextField } from "@hooks/inputs"
import { CenterCenter, colors } from "@mosanic/styles";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { isFunc } from "@util/Funct";
import { getSensitizedString, removeSpaces, toLowCase } from "@util/Text";
import { useEffect, useRef, useState } from "react";
import slugify from "slugify";

import styled, {css} from "styled-components";
import { WrapInput } from "../../items/Inputs";
import Button from "../Button/Buttons";

import { InputStyles as s } from "../Input";


const sanitizeValue = (sanitize, e) => !e.target?.value ? e.target?.value :
    isFunc(sanitize) ? sanitize(e.target.value) :
    sanitize === 'extreme' ? getSensitizedString(removeSpaces(toLowCase(e.target.value))) :
    sanitize === 'slugify' ? toLowCase(slugify(e.target.value)) : 
    sanitize ? getSensitizedString(e.target.value) :
    e.target.value;


const TextField = ({
    //Essential
    name = '',
    type = 'text',
    value = null,
    onChange = null,
    placeholder = null,

    //Overwrite
    label = null,

    //Styling
    fullWidth = false,
    small = null,
    size = null,
    ninja = false, 
    wrap = false, 
    center = false,
    
    //Specific
    endAdornment = null,
    endSource = null,
    defaultValue = null,

    //Validation
    valid = null,
    empty = false,
    disabled = false,
    required = false,

    innerRef = null,

    //Dep
    inputProps = null,

    sanitize = null,
    
    noCase= false,
    style = null,
    inputStyle = null,
    ...other
}) => {
    const [focussed, setFocused] = useState(false)
    const [fieldType, setType] = useState(type)
    const updated = useRef(0)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);
    if(inputProps) {
        console.error(`inputProps are deprecated: ${label ? label : name}`)
        // console.log(inputProps)
    } 

    useEffect(() => {
        if(defaultValue && !value && updated?.current === 0) {
            onChange({target: {value: defaultValue}})
            updated.current = 1;
        }
    },[defaultValue, onChange, value]) 
    return (
        <WrapInput wrap={wrap ? wrap : false} fullWidth={fullWidth}> 
            <s.Container 
                focussed={focussed}
                disabled={disabled}
                valid={valid 
                //     || (
                //     type != 'email' ? null : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? "true" : "false"
                // )
            } 
                empty={empty}
                ninja={ninja} 
                size={size}
                center={center}
                style={style}
                className="input_field"
            >
                <s.Label shrink={Boolean(innerRef?.current || value || focussed)} noCase={noCase}>
                    {
                        ninja ? "" :
                        label ? label :
                        name ? name : ""
                    }
                    {required && <s.Required />}
                </s.Label>
                <s.Field 
                    ref={innerRef}
                    type={fieldType ? fieldType : 'text'}
                    fullWidth={fullWidth}
                    placeholder={focussed ? placeholder : undefined}
                    size={small ? 'small' : size ? size : null}
                    {...other}
                    style={inputStyle}
                    disabled={disabled}
                    value={value || ""}
                    onChange={e => {
                        if(sanitize) onChange(sanitizeValue(sanitize, e));
                        else onChange(e);
                        // todo -> check!
                    }}
                    onFocus={(e) => {
                        if(other?.onFocus) other.onFocus(e)
                        onFocus();
                    }} 
                    onBlur={(e) => {
                        if(other?.onBlur) other.onBlur(e)
                        onBlur();
                    }}
                />
                <s.End>
                    {type === 'password' && (
                        <Button ghost mt={1}
                            icon={fieldType === 'password' ? <EyeOpenIcon/> : <EyeClosedIcon/>} 
                            onClick={() => setType(fieldType === 'password' ? 'text' : 'password')}
                        />
                    )}
                    {endAdornment}
                    {endSource}
                </s.End>
            </s.Container>
        </WrapInput>
    )
}

const TextArea = ({
    handler, 
    placeholder = null,
    ninja = false, 
    wrap = false, 
    inputProps = {},
    name = '',
    small = null,
}) => {
    console.error('dep text area from fields')
    return (
    <WrapInput wrap={wrap}> 

            <s.Container className="input_field" ninja={ninja}>
                <s.Label shrink={Boolean(handler?.value)}>
                    {ninja ? "" :
                        handler?.label ? 
                            handler.label : name}
                </s.Label>
                <s.Field 
                    placeholder={placeholder ? placeholder : undefined}
                    size={small ? 'small' : null}
                    {...handler} 
                    InputLabelProps={{ 
                        shrink: Boolean(handler?.value), 
                        ...inputProps 
                    }}
                />
             </s.Container>
    </WrapInput>
)
}
const NumField = ({
    handler, 
    placeholder = null,
    ninja = false, 
    wrap = false, 
    name = '',
    label = '',
    small = null,
    size = null,
    value = null,
    onChange = null,
    fullWidth = false,
    ...other
}) => (
    <TextField {...{
        fullWidth,
        placeholder,
        handler, 
        name,
        label,
        small,
        size,
        value,
        onChange,
        type:  "number",
        ninja, 
        wrap, 
        ...other
    }}/>
)


/**
 * Use automatic handled text input field
 * 
 * @param {Value|Label|FullWidth} ConfigObject
 * @returns {field|handler} field|handler
 */
const useAutoTextField = ({value = '', label = '', fullWidth = false}) => {
    const handler = useTextField(value, label);

    return {
        field: <TextField {...handler} fullWidth={fullWidth} />,
        handler
    }
}

export {
    NumField,
    TextField,
    TextArea,
    useAutoTextField,
}
export default TextField