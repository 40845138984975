import { CenterCenter, colors } from "@mosanic/styles";
import styled, { css } from "styled-components";

const CheckBoxLabel = styled.span` 
    margin-left: 8px;
    padding-bottom: 4px;
    font-size: 14px;
    align-items: top;
    &:hover {
        cursor: pointer;
    }
`
const CheckBoxCount = styled.div` 
    ${CenterCenter}
    overflow: hidden;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: ${colors.util.primary.light};
    color: #fff;
    font-size: 11px;
    position: absolute;
    top: 2.5px;
    right: 2.5px;
`
const CheckBoxImage = styled.div` `
const CheckBoxWrapper = styled.label` 
    position: relative;
    ${props => props.hasBorder && css` 
        transition: all ease-in-out 0.3s;
        border: 1px solid ${colors.util.gray.light};
        border-radius: 7.5px;
        padding: 2.5px;
        margin: 5px;
        ${CheckBoxImage} {
            margin: 5px 5px 0;
            transform: scale(1.1);
            transform-origin: left top;
        }
        &:hover{
            cursor: pointer;
            background: ${colors.util.gray.lighter};
            transform: scale(1.05);
        }

        ${props.checked && css` 
            border-color: ${colors.util.primary.base};
        `}
    `}
    ${props => props.disabled || props.unfocus && css` 
        opacity: 0.7;
        transform: scale(0.9);
        &:hover{
            transform: scale(0.9)
        }
    `}
    ${props => props.size === 'full' && css` 
        ${CheckBoxLabel} {
            display: block;
            width: 100%;
            font-size: 12px;
            margin: 5px;
            max-height: 16px;
            overflow: hidden;
        }
    `}
`

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`

const Icon = styled.svg`
    margin-top: -14px;
    fill: none;
    stroke: white;
    stroke-width: 3px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    transition: ease-in-out 0.2s all;
    padding: 2px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    width: 16px;
    height: 16px;
    border: ${props => (props.checked ? 'none' : `1px solid ${colors.util.gray.base}`)};
    background: ${props => (props.checked ? colors.util.primary.base : colors.util.gray.lighter)};
    border-radius: 3px;
    transition: all 150ms;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px ${colors.util.primary.underlay};
    }
    &:hover {
        background: ${props => props.checked ? colors.util.primary.light : colors.util.gray.light};
        box-shadow: 0 0 0 3px ${colors.util.primary.underlay};
    }

    ${Icon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    }
`

const CheckBoxStyle = {
    CheckBoxWrapper,
    CheckBoxLabel,
    CheckBoxImage,
    CheckBoxCount,

    CheckboxContainer,
    Icon,
    HiddenCheckbox,
    StyledCheckbox,
}
export default CheckBoxStyle;