import { Editor, Element as SlateElement } from "slate"
import { HEADING_TYPES } from "./Constants"


const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => 
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n[blockType] === format || 'headings' === format && HEADING_TYPES.includes(n[blockType])),
    })
  )

  return !!match
}

const isFormatActive = (editor, format) => {
  if(!Editor.nodes(editor)?.[0]) return
  const [match] = Editor.nodes(editor, {
    match: n => n?.[format] === true,
    mode: 'all',
  })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}


const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const isButtonActive = editor => {
  const [button] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
  })
  return !!button
}



const Validate = {
  isBlockActive,
  isFormatActive,
  isMarkActive,


  //Inline elements
  isLinkActive,
  isButtonActive
}

export default Validate;