import { Button, Choice, Select } from "@mosanic/fields";
import { isClone } from "@mosanic/utils/value";
import { siteIdSelector } from "@redux/auth/reducer";
import { useCollections } from "../useCollections";
import { useSelector } from "react-redux";
import { List, ListItem } from "@mosanic/items/List";
import { ChevronRightIcon, ExternalLinkIcon } from "@radix-ui/react-icons";
import { AdvancedLabel, ControlDialog } from "@mosanic/items";
import { useRouter } from "next/router";

import { useState } from "react";
import { isString } from "@util/Text";

import { useGetCollections } from "@mosanic/cms/Query";

const collectionLabel = collection => (
    <span>
        <b>{collection?.title}</b> <br/>
        {collection?.entries?.length ? collection.entries.length : collection?.count ? collection.count : 0} entries <br/>
    </span>
)
const getCollectionOptions = (collections, full) => collections?.length >= 1 ? 
collections.map(collection => ({
    label: collectionLabel(collection),
    value: full ? collection : collection?._id
})) : [
    'No collections found.'
]

const getSourceType = (state, type) => type != 'collection' ? type : state?.modus === 'page' ? 'Page collection' : 'Collection';


const SelectCollection = ({ state = null, setState = null, collections, full = false, sourceSelector = true, type  = 'collection', as = "select", ...other }) => (

    <CollectionSelector 
        label={`${state?.collection ? 'Active' : 'Select'} ${getSourceType(state, type)}`}
        collections={collections}
        value={state.collection}
        onChange={collection => setState({...state, collection})}
        as={as}
    />
)

export const SwitchCollection  = ({active}) => {
    const router = useRouter();
    const [collections, loading] = useGetCollections({current: true})
    const [dialog, setDialog] = useState(false)

    const switchPage = (pageId) => {
        setDialog('Switching collection...')
        setTimeout(() => {
            router.push(`/collection/${pageId}`)
            setDialog(false)
        }, 750);
    }

    return loading ? null : [
        <Button icon={<ExternalLinkIcon />} label=" Switch" key="trigger" onClick={() => setDialog(true)} />,
        <ControlDialog key="dialog" state={dialog} close={() => setDialog(false)}>
            {isString(dialog) ? (
                <AdvancedLabel label={dialog} /> 
            ) : ( 
                <SelectCollection 
                    as="list"
                    setState={state => switchPage(state?.collection)} 
                    state={{collection: active}} 
                    collections={collections} 
                    full={false} 
                    clearable={false} 
                    sourceSelector={false}
                />
            )}
        </ControlDialog>
    ]
}


export const CollectionSelectorImbed = ({onChange, value, ...other}) => {
    const siteId = useSelector(siteIdSelector)
    const [collections, loading, error] = useGetCollections({siteId})
    return loading ? loading : (
        <CollectionSelector 
            collections={collections}
            onChange={o => {
                console.log(o)
                onChange(o)
            }}
            value={value}
            {...other}
        />
    );

}
export const CollectionSelector = ({collections, onChange, value, label, _idOnly = true, as="select", ...other}) => as === "select" ? (
    <Select 
        // getOptionValue={o => o?.value}
        clearable={false}
        getValue={e => e.value}
        options={getCollectionOptions(collections, !_idOnly)}
        label={label ? label : 'Select collection'}
        value={value}
        onChange={onChange}
        // onChange={onChange}
        {...other}
    />
): (
    <List>
        <ListItem disabled>
            All collections
        </ListItem>
        {getCollectionOptions(collections, !_idOnly)?.map(o => (
            <ListItem 
                chevron={<ChevronRightIcon />}
                active={value === o?.value}
                key={o.value} 
                onClick={() => onChange(o.value)}
            >
                {o.label} 
            </ListItem>
        ))}
    </List>
)




export default SelectCollection