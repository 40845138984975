

const ID = '[screens]'
export const SCREENS_ID = ID;


//Individual
const SCREEN_HANDLE_RESIZE       = `${ID} Handle resize screen`;
const SCREEN_UPDATE_POSITION     = `${ID} Update position screen`;
const SCREEN_INSPECT             = `${ID} Inspect screen`;
const PAGE_UPDATE_SEO             = `${ID} Update seo`;


//Create + remove + update
const PAGE_ADD_NEW               = `${ID} Add page`;
const PAGE_SET                      = `${ID} Set page`;
const SCREEN_ADD_NEW             = `${ID} Add screen`;
const SCREEN_REMOVE              = `${ID} Remove screen`;
const SCREENS_UPDATE_META        = `${ID} Update screen meta`;



//Global
const SCREENS_SET_SCALE          = `${ID} Set scale`;
const SCREENS_REORDER            = `${ID} Reorder`;
const SCREENS_RESET_OFFSETS      = `${ID} Reset Offsets`;

const SCREENS_INIT               = `${ID} Initialize`
const SCREEN_INIT               = `${ID} Init screen`
const SCREEN_INIT_NEW            = `${ID} Init new screen`

const SCREEN_SET_BP                 = `${ID} Set bp`
const SCREEN_SET_DEVICE             = `${ID} Set device`

const SCREENS_ACTION_IDS = {
    SCREEN_HANDLE_RESIZE,  
    SCREEN_UPDATE_POSITION,
    SCREEN_ADD_NEW,        
    PAGE_ADD_NEW,  
    PAGE_UPDATE_SEO,

    PAGE_SET,
    SCREEN_REMOVE,         
    SCREENS_UPDATE_META,  
    SCREEN_INIT_NEW, 
    SCREENS_SET_SCALE,     
    SCREENS_REORDER,       
    SCREENS_INIT,          
    SCREEN_INIT,         
    SCREEN_INSPECT,

    SCREEN_SET_BP,
    SCREEN_SET_DEVICE
};
export default SCREENS_ACTION_IDS;


//Live editing
export const handleInspectScreen = ({screen, bounds, size}) => ({
    type: SCREEN_INSPECT,
    payload: {
        component: {...screen, type: 'screen', group: 'screens'}, 
        bounds, 
        screen: size
    },
    meta: {feature: SCREENS_ID}
})
export const handleScreenResize = ({width, height, _id}) => ({
    type: SCREEN_HANDLE_RESIZE,
    payload: {width, height, _id},
    meta: {feature: SCREENS_ID}
})
export const handleScreenPosition = ({x, y, _id}) => ({
    type: SCREEN_UPDATE_POSITION,
    payload: {x, y, _id},
    meta: {feature: SCREENS_ID}
})


export const setScreenBpAction = ({bp}) => ({
    type: SCREEN_SET_BP,
    payload: bp,
    meta: {feature: SCREENS_ID}
})
export const setScreenDeviceAction = ({device}) => ({
    type: SCREEN_SET_DEVICE,
    payload: device,
    meta: {feature: SCREENS_ID}
})

//Requests
export const handlePageAdd = ({name, _id, meta}) => ({
    type: PAGE_ADD_NEW,
    payload: {name, _id, meta},
    meta: {feature: SCREENS_ID}
})
export const setCurrentPage = ({_id}) => ({
    type: PAGE_SET,
    payload: _id,
    meta: {feature: SCREENS_ID}
})
export const handleScreenAdd = ({breakpoint}) => ({
    type: SCREEN_ADD_NEW,
    payload: breakpoint,
    meta: {feature: SCREENS_ID}
})
export const handleScreenRemove = ({_id}) => ({
    type: SCREEN_REMOVE,
    payload: _id,
    meta: {feature: SCREENS_ID}
})



//handle screen changes
export const updateScreenMeta = ({screen, _id = null}) => ({
    type: SCREENS_UPDATE_META,
    payload: _id ? {...screen, _id} : screen,
    meta: {feature: SCREENS_ID}
})
export const initNewScreen = ({screen, _id = null}) => ({
    type: SCREEN_INIT_NEW,
    payload: {...screen, _id},
    meta: {feature: SCREENS_ID}
});


export const updateSeoAction = ({seo, _id = null}) => ({
    type: PAGE_UPDATE_SEO,
    payload: {seo, _id},
    meta: {feature: SCREENS_ID}
});


//Controls
export const setScreensScale = ({scale}) => ({
    type: SCREENS_SET_SCALE,
    payload: scale,
    meta: {feature: SCREENS_ID}
});

export const updateScreensScale = ({scale, type = '+'}) => {
    const newScale = (type === '+') ? (scale * 10 + 0.1 * 10) / 10 : (scale * 10 - 0.1 * 10) / 10;
    return {
        type: SCREENS_SET_SCALE,
        payload: newScale,
        meta: {feature: SCREENS_ID, oldScale: scale, type}
    }
};
export const reorderScreens = () => ({
    type: SCREENS_REORDER,
    meta: {feature: SCREENS_ID}
});
export const resetOffsetsScreens = () => ({
    type: SCREENS_RESET_OFFSETS,
    meta: {feature: SCREENS_ID}
});

//Automatic middleware
export const initializeScreens = () => ({
    type: SCREENS_INIT,
    meta: {feature: SCREENS_ID}
});
export const initScreen = ({screen, bp}) => ({
    type: `${SCREEN_INIT} ${bp}`,
    payload: screen,
    meta: {feature: SCREENS_ID}
});

