import { Expander, InputGroup, InputGroupItem } from "@mosanic/items";
import Input from "../Input";
import GoogleMap from "@mosanic/builder/Map";
import { SwitchRenderField } from "../Input/Input";
import {Button, ButtonGroup, Select, TextField} from '@mosanic/fields';
import MediaSingleUploader from "@mosanic/components/Media/MediaSingleUploader";
import ClickMap from "./ClickMap";
import { useEffect, useState } from "react";

export const locationFields = [
    ...'address|city|street_name|street_number|state|state_short|post_code|country|country_short'.split('|').map(name => ({
        label: name,
        value: {
            type: 'text',
            name 
        }
    })), {
        label: 'Latitude',
        value: {
            name: 'lat',
            type: 'number',
        }
    }, {
        label: 'Longitude',
        value: {
            name: 'lng',
            type: 'number',
        }
    }
]

export const Location = ({
    //Essential
    name = '',
    value = null,
    onChange = null,

    //Overwrite
    label = null,


    //Validation
    disabled = false,
    required = false,

    ...other
}) => {
    const [map, setTab] = useState('preview')
    const [search, setSearch] = useState('')
    const [input, setInput] = useState('')

    const setMap = tab => {
        setTab(tab); setInput(''); setSearch('');
    }

    const setSearchInput = value => setInput(value);
    
    useEffect(() => {
        let timer;
        timer = setTimeout(() => setSearch(input), 1000);
        return () => clearTimeout(timer)
    },[input])

    const update = (prop, val) => onChange({...value, [prop]: val})



    return (
        <>
        <InputGroup border label={"Location"} cols={4} style={{padding: '8px 4px'}}>
            {/* <AdvancedLabel label="Google map" span helpText={<Link href="https://www.latlong.net/"><ArrowRightIcon /> Find latitude longitude</Link>} style={{display: 'block', width: '100%'}} my={4} /> */}
            {/* https://www.latlong.net/ */}
            {locationFields?.map((field, index) => (
                <SwitchRenderField 
                    wrap
                    fullWidth={index === 0}
                    {...other}
                    label={field.label}
                    type={field.value.type}
                    name={field.value.name}
                    onChange={e => update(field.value.name, e.target.value)} 
                    value={value?.[field.value.name]}
                />
            ))}
            <Select wrap label="Theme" onChange={e => update('theme',e)} value={value?.theme} options={'default,minimal,light,dark'.split(',')} />
        </InputGroup>
        <InputGroup border label="Map">
            <ButtonGroup>
                <Button label="Preview" onClick={() => setMap('preview')} active={map === 'preview'}  />
                <Button label="Set marker"  onClick={() => setMap('marker')} active={map === 'marker'} />
                <Button label="Set zoom"  onClick={() => setMap('zoom')} active={map === 'zoom'} />
            </ButtonGroup>
            {map === 'marker' && (
                <InputGroupItem style={{margin: "0 0 10px"}}>
                    <TextField label="Go to area to click the desired place." onChange={e => setSearchInput(e.target.value)} value={input} />
                </InputGroupItem>
            )}
        

            <div style={{ width: '100%', height: map === 'preview' ? '150px' : '300px' }}>
                {map === 'preview' ? (
                    <GoogleMap editor={{ source: { ...value } }} />
                ): (
                    <ClickMap value={value} onChange={onChange} control={map} search={search} />
                )}
            </div>
        </InputGroup>
        <Expander trigger={<Button fullWidth ghost label="More fields" style={{width: '100%'}} />}>
            <InputGroup fullWidth>

                <TextField wrap label="apiKey" onChange={e => update('apiKey', e.target.value)} value={value?.apiKey} />
                <TextField wrap label="zoom" onChange={e => update('zoom', e.target.value)} value={value?.zoom} />
                <MediaSingleUploader
                    wrap
                    imageUrl={value?.marker}
                    type='image'
                    label="marker"
                    callback={e => update('marker', e)}
                    value={value?.marker}
                />
            </InputGroup>
        </Expander> 
        </>
    )
}

export default Location;

// MosanicSwopYourStay2023