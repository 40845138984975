
import { MarkedImage } from "@mosanic/components/Media/Marked/MarkedImage";
import { FullRelationRender } from "@mosanic/core/Page/Render";
import { DataProvider, useDataProvider, useFormProvider, usePageContext, useRelationProvider, useRepeatProvider } from "@mosanic/core/Provider";
import { RenderText } from "@mosanic/fields";
import { useLocale } from "@mosanic/i18n";
import { Expander, Link } from "@mosanic/items";

import { parseImageSrc } from "@mosanic/utils/Image";
import { hasAttr, omitDeep } from "@util/Obj";
import { containsSubstr, excerptString, isString, toLowCase } from "@util/Text";
import Image from "next/image";
import { useRouter } from "next/router";
import slugify from "slugify";
import GoogleMap from "../Map";
import { useDataReceiver } from "./DataReceiver";
import { hasRelationRender, RelationRender } from "./DataRender";
import { evalReplaceValue } from "@mosanic/utils/value";
import { getLatestFieldFromFieldPath } from "@mosanic/fields/Source/helpers";
import { FormField } from "./FormField";
import { isNumber } from "@util/Num";



const getEntries = (getData, repeater, collection) => getData ?
    collection?.entries :
    repeater?.entries ? 
        repeater?.entries :
        repeater?.entry;

const getEntry = (entries, entry) => (entry?.entry) ?
    entries?.find(e => e?._id === entry?.entry) :
    entries 



const shouldReplaceCodeField = entry => entry?.field?.code && entry?.field?.field

const RenderContent = (html, content) => html ? 
    <div dangerouslySetInnerHTML={{__html : content}} /> :
    content


    // TODO --> add 'author' / userId selector --> for getting correct entries.
    // TODO also in repeater.


const DataLink = ({fields, ref, editor, active = false, clone, isDev}, children) => {
    const router = useRouter()
    const {source} = editor;
    const {entry} = source || {entry: null};

    const {limit, end, excerpt, html} = source || {limit: null, end: null, excerpt: false, html: false}    
    
    const dataProvider = useDataProvider();
    const form = useFormProvider()
    const page = usePageContext()
 
    const repeater = useRepeatProvider()

    const getData = entry?.source != 'provider' && (( 
        !source?.dynamic && 
        !source?.hasParentLink && 
        !repeater?.entries && 
        !repeater?.entry
    ) || !page?.entry);




    let entries = null, current = null;

    const lastField = getLatestFieldFromFieldPath(entry?.fieldPath)
    let fieldType = lastField?.type;

     let [content, schema, baseRoute] = useDataReceiver({
        source: source?.dynamic ? 'page' : entry?.source, //Temp: transform old inputs.
        entry,
        clone,
    })
 

    if(shouldReplaceCodeField(entry)) {
        content = evalReplaceValue(entry.field.code, content)
    }
    

    const locale = useLocale(JSON.stringify(content ? content : {}));

    const lowCaseField = toLowCase(entry?.field);
 

    if((containsSubstr(lowCaseField, 'slug') || lowCaseField === 'slug') || lastField?.name === 'slug' || (entry?.field?.code && containsSubstr(content, 'https'))) {
        // const schemaIndex = schema?.[0]?.name;
        
        if(!containsSubstr(content, 'https')) content = baseRoute ? `/${baseRoute}/${content}` : `/${content}`;
        // if(schemaIndex && hasAttr(content, schemaIndex)) url += toLowCase(slugify(content[schemaIndex]))

        // if(entry?.collection === '63359058737d68d97014da2d') {
        //     url = '/listing/'
        //     if(schemaIndex && content?.accommodation_name) url += toLowCase(slugify(content?.accommodation_name))

        // } 
        if(source?.iframe) return <iframe src={content} height="100%" width="100%"/>

        if(router?.pathname === '/app/builder') return (
            <div href={content}>

                {children ? children : 'view'}
            </div>
        )
        return ( 
            <Link href={content}>

                {children ? children : 'view'}
            </Link>
        );
    }

    // if(fieldType === 'gallery') return JSON.stringify(content['image_gallery'])

    if(fieldType === 'markedimage') return (
        <>
        {/* {JSON.stringify({content})} */}
        <MarkedImage 
            value={content}
            displayOnly
        />
        </>
    )

    const imageSource = parseImageSrc(content, true);
    
    if(fieldType === 'image' || imageSource && !['textarea', 'text'].includes(fieldType)) {

        return ( 
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <Image 
                    blurDataURL={imageSource?.min}
                    src={imageSource?.full} 
                    alt={content?.alt ? content.alt : ""}
                    objectFit='contain' 
                    layout="fill"
                />
            </div>
        )
    }

    if(fieldType === 'location') {
        return <GoogleMap editor={{source: {...content, apiKey: 'AIzaSyAma8GPPHZNC5Gg1bXp7V5GMbfYH1gIGbk',}}}/>

    }
 
     if(fieldType === 'relation') {

            // relationEnd --> rooms/ beds
    // relationRender --> page 
    // relation is type

        const linkData = entry?.fieldPath[entry?.fieldPath?.length - 1];
        // return JSON.stringify(linkData)
        if(hasRelationRender(linkData?.relation)) return <RelationRender entry={entry} link={linkData?.relation} content={content} />
        // if(hasRelationRender(linkData?.relationEnd)) return <RelationRender entry={entry} link={linkData?.relationEnd} content={content} />
        return (
            <>
            {/* {JSON.stringify({data: linkData ? linkData : null})} */}
            <FullRelationRender 
                linkData={linkData}
                render={linkData?.relationRender ? linkData.relationRender : linkData?.render}
                relation={linkData?.relationEnd ? linkData.relationEnd : linkData?.relation}
                entries={linkData?.entries ? linkData.entries : content}
            />
            </>
        )
    }

    let contentRender = (content) ? (
        (
            (Array.isArray(content) && content?.[0]?.url?.url) || 
            fieldType === 'gallery'
        ) ? null :
        (!isString(content) && Array.isArray(content)) ? (
                hasAttr(content?.[0], 'children') ?
                    <RenderText content={omitDeep(content)}/> :
                    null
            ) : isNumber(content) ?
                    content.toString() :
                isString(content) ?
                    content :
                isString(locale) ?
                    locale :
                    Array.isArray(locale) ?
                        <RenderText content={omitDeep(locale)}/> :
                        null
    ): null;
    
    // if(toLowCase(entry?.field) === 'gallery' ) console.log({content, contentRender})
    // if((Array.isArray(content) && content?.[0]?.url?.url) ) console.log("NOT RENDINGER?")
    const wrapperContent = 
        (!contentRender && content) ? content : 
            current ? current : 
            entries ? entries :
            null
 
    if(isString(contentRender) && excerpt) {
        const sourceContent = contentRender
        contentRender = (
            <Expander trigger={RenderContent(html, excerptString(sourceContent, parseFloat(limit), end))}>
                {RenderContent(html, sourceContent.substring(parseFloat(limit), 99999))}
            </Expander>
        )
    }
            // console.log({content, wrapperContent, content, entry: entry?.field} )
            // return JSON.stringify({wrapperContent, contentRender, content})

    
    return (
        <DataProvider data={wrapperContent}>
            {children && children}

            {form?.state?.editing ? (
                <FormField 
                    entry={entry}
                    schema={schema}
                />
            ): (
                contentRender && (
                    isString(contentRender) ? (
                        RenderContent(html, contentRender)
                    ) : contentRender
                )
            )}
        {/* {source === 'relation' && (
             <pre>
    {JSON.stringify({wrapperContent, contentRender, content}, null, 2)}

</pre>
        )}   */}
        </DataProvider>
    ); 
    //  return content ? (
    //     <>
    //     {contentRender}
    //     </>
    // ) : (
    //     <DataProvider value={wrapperContent}>
    //         {children}
    //     </DataProvider>
    // );
}


export default DataLink;