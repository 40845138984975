

import { isAuthenticated } from "@api/token";
import { useGetUser } from "@mosanic/cms/Query/byId";
import { redirectToPortal } from "@mosanic/components/Membership";
import { EditProfile, EditUser, useUpdateUser, useUser } from "@mosanic/components/User";
import { useDeleteUser } from "@mosanic/components/User/Handlers";
import { DeleteConfirm } from "@mosanic/core/Notifications";
import { ButtonGroup } from "@mosanic/fields";
import { Button, Buttons } from '@mosanic/fields/Button/Buttons';
import { AdvancedLabel, LoadingDots } from "@mosanic/items";
import { DashCard } from "@mosanic/items/Page";
import { userIdSelector } from "@redux/auth/reducer";
import { getFullTimeStamp } from "@util/Date";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export const UserAccount = ({teamsAvailable = true, overwrite = {
  settings: "Your personal details and information.",
  author: "This information is displayed under the articles created by you."
}}) => {


  const methods = useForm({mode: 'all'});

  const isAuth = isAuthenticated();
  const userId = useSelector(userIdSelector)
    const [storedUser] = useGetUser({_id: userId})

  const {_id} = storedUser || {_id: null}

  const isVisit = false;
  const [user, loading, error] = useUser({_id})
  const [deleteAccount, dataDelete, loadingDelete, errorDelete] = useDeleteUser()

  const {firstName, lastName, subscription} = user;
  const plan = subscription?.active ? subscription.active : 'starter';
  const [viewPlan, setViewPlan] = useState(false)

  const [update, dataUpdate, loadingUpdate, errorUpdate] = useUpdateUser()
  
  const updateAccount =(updatedUser) => update({_id, user: {
    ...user, 
    ...updatedUser,
    registrationDate: undefined, 
    lastLogin: undefined,
    _id: undefined, 
    email: undefined
  }});
  

  
  const [accountDeleteModal, setAccountDeleteModal] = useState(false)
  const dispatchDeleteAction = () => {
    deleteAccount({_id, user});
  } 
 

  const [data, setData] = useState(); 
  const [sites, setSites] = useState();

  useEffect(() => {
    if(user?._id != methods?.watch()?._id) {
      methods.reset({
        ...user,
        registrationDate: getFullTimeStamp(user?.registrationDate),
        lastLogin: getFullTimeStamp(user?.lastLogin) 
      })
    }
  },[methods, user])

  useEffect(() => {
    if(data) {
      setSites(data?.sites)
    }
  },[data]) 


    return (
                    <FormProvider {...methods} >
                      {/* {JSON.stringify(methods?.watch())} */}
        <h1>
          {!user?.errors ? 
            isVisit ? 
              `${firstName} ${lastName}` :
              "Account" : 
            "No account found."
          }
        </h1>
          {!isVisit ? (
          <ButtonGroup mt={2}>
            {plan != 'starter' && (
              <Button label="View current plan" onClick={() => setViewPlan(true)} />
            )}
            <Button
                onClick={() => redirectToPortal(user)}
              >
              Open customer portal
            </Button> 
          </ButtonGroup>
        ):(
          <Buttons>
            <Button href={`mailto:${user?.email}`} label={user?.email} ghost/>
          </Buttons>
        )}
        <br/>
          {loading}
        {error}
        {(isAuth && !loading) && (
          <>
          <DashCard key='Settings'
              header="Settings"
              label={overwrite.settings}
              help="You can change this information any time, just make sure to save it once you&apos;re done."
              render={<EditUser />}
            /> 
          {(user?.teams?.length <= 0 && teamsAvailable)  && (
              <DashCard 
                header="Team"
                label="Join a team or set one up."
                helpText="Note that we combine this information with the fields you filled in under /users."
                render={(
                  <Buttons mb={10}>
                    <Button label="Create team" href="/team/setup"/>
                    <Button label="Join team" href="/teams" />
                  </Buttons>
                )}
              />
            )} 

            <DashCard
              header="Author profile"
              label={overwrite.author}
              helpText="Note that we combine this information with the fields you filled in under /users."
              render={<EditProfile />}
            />
          
          </>
        )}
        <AdvancedLabel 
          label={`This account was created on ${getFullTimeStamp(user?.registrationDate)} `}
          helpText={`The last login on Mosanic was at: ${getFullTimeStamp(user?.lastLogin)} `} span
        />
        <Buttons style={{float: 'right', marginRight: '5%'}}>
          <Button 
            label="Cancel"
            type="submit"
          />
          <Button 
            mr={0} theme="primary"
            label={loadingUpdate ? <LoadingDots /> : "Save changes"}
            onClick={() => updateAccount(methods.watch())}
          />
        </Buttons>
        {errorUpdate}
            <DeleteConfirm
        action={dispatchDeleteAction}
        actionState={loadingDelete || dataDelete}
        validateLabel="email"
        validateValue={user?.email}
        header="About to delete your account."
        message="We're sad to see your go. Continue below if you really want to delete your Mosanic account. Note that it will take up-to 31 days to fully remove all of your data. "
        state={accountDeleteModal}
        close={() => setAccountDeleteModal(false)}
      />
          </FormProvider>
    )
}