import { removeLastChar } from "@util/Text";


//Item selectors
const itemPath = (_id, group) => ({_id, group});
const itemPaths = (_ids, group) => {
    const paths = [];
    _ids.forEach(_id => {
        paths.push({_id, group});
    });
    return paths;
}

//Paths
const genPath = (attr, field, variant, sub = null) => sub ? 
    `data.${attr}.${field}.${variant}.${sub}` :
    `data.${attr}.${field}.${variant}`;

const autoPath = (fields) => {
    let path = ``;
    fields.forEach(field => path += `${field}.`);
    return removeLastChar(path);
};

//Values
const pathValue = ({path, value}) => ({value, path});
const pathMetric = ({path, value, metric, token}) => ({value: metricValue({value, metric, token}), path});


const metricValue = ({value, metric, token}) => ({value, metric, token});

export {
    itemPath,
    itemPaths,

    genPath,
    autoPath,

    pathValue,
    pathMetric,

    metricValue,
}