import { FormItems } from '@mosanic/fields/Form';
import Input from '@mosanic/fields/Input';
import { InputGroup } from '@mosanic/items';
import { useFormContext } from 'react-hook-form';


export const EditProfile = ({handler, updating = false, author}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } }= useFormContext();
	const inputProps = {control, fullWidth: true, errors}

 

	return (
        <InputGroup>
            <FormItems controls={inputProps} fields={[{    
                name: 'author.displayName',
                label: 'Displayed name',
                fullWidth: true
            },{    
                name: 'author.bio',
                label: 'Short bio',
            },{    
                name: 'author.biography',
                label: 'Biography',
                type: 'textarea'
            }]}
            />
        </InputGroup>
	);
};