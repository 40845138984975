import { useState } from 'react';

import { Select } from '@mosanic/fields';

import { InlineGroup } from '@mosanic/items/Inputs';
import { hasAttr } from '@util/Obj';
import { capitalizeFirst, replacePart } from '@util/Text';
import { inputFields } from './Field/FieldType';




export const useFieldFilters = ({watch}) => {

    
	const [filters, setFieldFilters] = useState({
		meta: null,
		type: null,
		displayName: false,
	})


	
	const shouldFilter = group => Array.isArray(filters?.[group]) && filters?.[group]?.length >= 1
	const getFilter = group => filters?.[group]?.map(f => f.value)
	const setFilter = (val, group) => setFieldFilters({...filters, [group]: val})

	const inFilters = field => {
		if(!filters.meta && !filters.inMap && !filters.type) return field;
		let pass = true;
		if(	shouldFilter('type') && !getFilter('type')?.includes(field.type) ) return 
		if( shouldFilter('meta') ) {
			getFilter('meta').map(filter => {
				if(!hasAttr(field, filter) || !field?.[filter]) pass = false
			})
		}
		return pass ? field : null;
	}

	
	const getOptions = (options, validate) => (
		options.map(o => ({
			value: o, 
			count: watch()?.schema?.filter(f => validate(f, o))?.length
		}))
		.filter(o => o.count >= 1)
		.map(o => ({
			...o, label: capitalizeFirst(`${replacePart(o.value, 'Active', '')} (${o.count})`) 
		}))
	)


	const filterGroups = [{
		label: 'Field type',
		options: getOptions(inputFields, (f, o) => f.type === o),
		type: 'type'
	},{
		label: 'Validation',
		options: getOptions(
			'none|regexActive|populateActive|limit'.split('|'),
			(f, o) => f?.[o]
		),
		type: 'validate'
	},{
		label: 'Field meta',
		options: getOptions(
			'all|inMap|localized|props|variable|required|searchable|filterable'.split('|'),
			(f, o) => f?.[o]
		),
		type: 'meta'
	}]


    return [filterGroups, filters, inFilters, setFilter]
}

export const FieldFilters = ({filterGroups, setFilter, filters}) => (
    <InlineGroup justify="space-between">
        {filterGroups.filter(g => g.options?.length >= 1).map(g => (
            <Select wrap
                key={g.type}
                multiple searchable label={g.label}
                value={filters?.[g.type]}
                onChange={val => setFilter(val, g.type)}
                options={g.options.sort((a, b) => b.count - a.count)}
            />
        ))}
        {/* <Button 
            label={filters?.displayName ? 'Display name' : 'Field Id'} minWidth="100px" 
            onClick={() => setFilter(!filters?.displayName, 'displayName')} ghost m={0} small
        /> */}
    </InlineGroup>
)