import styled, { css } from "styled-components";
import { color, layout, space, flexbox } from 'styled-system';
import { CenterCenter, RowWrap } from "./flex.styles";

export const Col = styled.div` 
    transition: background-color 0.3s ease-out;
    position: relative;
    width: 50%;
    ${space}
    ${layout}
    ${color}
    ${flexbox}

    ${props => props.background ? `background: ${props?.theme?.colors?.white};` : ``}
    ${props => props.center && css` 
        ${CenterCenter}
    `}

    &,
    & > *,
    & > :is(h1, h2, h3, h5, h6, p, b, strong, span) {
        color: ${props => props?.theme?.colors?.text};
    }
`
export const Row = styled.div` 
    ${space}
    ${layout}
    ${color}
    ${flexbox}
`
export const Wrap = styled.div` 
    display: flex;
    ${props => props.bg && css` 
        background: ${props => props?.theme?.colors?.white === '#FFFFFF' ? (
            props?.white ? '#F9FBFC' : '#f5f5f5'
        ) : (
            '#222'
        )};
    `}
    ${space}
    ${layout}
    ${color}
    ${flexbox}

    ${RowWrap}
`
const GridStyles = {
    Col,
    Row,
    Wrap,
}
export default GridStyles;