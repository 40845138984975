import { DesktopIcon, LaptopIcon, MobileIcon } from "@radix-ui/react-icons";
import { SvgTablet } from "./Icons";

export const getBreakpointIcon = (type, text = true) => {
    return (
        <>
            {type === 'xs' && <MobileIcon />}
            {type === 'sm' && text && 'sm'}
            {type === 'md' && text && 'md'}
            {type === 'lg' &&  <LaptopIcon/>}
            {type === 'xl' && <DesktopIcon />}
        </>
    );
}