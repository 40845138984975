
import { includesAction } from "@redux/util";
import { isAction } from "@redux/util/validateAction";

import { default as actions, modalActions } from "./action";
import { getModalSelector } from "./reducer";



export const storeModalFlow = ({getState, dispatch}) => next => action => { 
  next(action);
  
  if(includesAction(action, actions.REQUEST)){
    dispatch(modalActions.storeModalAction({action}))
  }
};

export const modalSubmitActionFlow = ({getState, dispatch}) => next => action => { 
  next(action);

  if(isAction(action, actions.SUBMIT)){
    dispatch(modalActions.clearModalAction())
    if(action.payload === 'decline') dispatch(modalActions.closeModal())
  
  }
};

export const modalConfirmActionFlow = ({getState, dispatch}) => next => action => { 
  next(action);

  if(isAction(action, actions.CONFIRM)){
    const confirmAction = getModalSelector(getState())?.confirmAction;
    dispatch(modalActions.clearModalAction())
    if(confirmAction) dispatch(confirmAction);
  }

};

export const modalDeclineActionFlow = ({getState, dispatch}) => next => action => { 
  next(action);

  if(action && isAction(action, actions.DECLINE)){
    const declineAction = getModalSelector(getState())?.declineAction;
    dispatch(modalActions.clearModalAction())
    if(declineAction) dispatch(declineAction);
  }

};

export const modalMiddleware = [
  // previousModalMdl,
  storeModalFlow,
  modalSubmitActionFlow,
  modalConfirmActionFlow,
  modalDeclineActionFlow
]
// export default modalMiddleware;