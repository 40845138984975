import { ControlPopper } from '@mosanic/items';
import { List, ListItem } from '@mosanic/items/List';
import { ListItemIcon } from '@mosanic/items/List/List';
import { getBreakpointsSelector } from '@redux/breakpoints/reducer';
import { setScreenBpAction } from '@redux/screens/action';
import { currentBpSelector } from '@redux/screens/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getBreakpointIcon } from 'src/components/Builder/Util/getBreakpointIcon';




export const useBreakpoints = () => {
    const breakpoint = useSelector(currentBpSelector)
    const activeBreakpoints = useSelector(getBreakpointsSelector)
    const breakpoints = [...activeBreakpoints]?.reverse()?.filter(bp => bp?._id != 'base');
    
    return [
        breakpoints,
        breakpoint
    ]
};

export const BreakpointsList = () => {
    const dispatch = useDispatch();
    const setBp = bp => dispatch(setScreenBpAction({bp}))
    const [breakpoints, breakpoint] = useBreakpoints()

    return (
        <List>
            <ItemList 
                items={breakpoints}
                active={breakpoint}
                handle={bp => setBp(bp?._id)}
                label={bp => (
                    <>
                     <ListItemIcon style={{width: '20px', display: 'inline-block'}}>
                        {getBreakpointIcon(bp?._id, false)}
                    </ListItemIcon>
                    {bp?.name}
                    </>
                )}

            />
        </List>
    )
}
export const ControlBreakpoints = ({trigger}) => (
    <ControlPopper trigger={trigger}>
        <BreakpointsList />
    </ControlPopper>
)

export const ItemList = ({items, active, label, handle}) => items ? (
    items.map(item => (
        <ListItem key={item?._id} active={item?._id === active} onClick={() => handle(item)}>
            {label(item)}
        </ListItem>
    ))
) : null