

import { useUpdateUser, useUser } from "@mosanic/components/User";
import { BuilderPreferences, DashboardPreferences, EmailPreferences, GeneralPreferences, PrivacyPreferences } from "@mosanic/components/User/Settings";
import { Button, Buttons } from '@mosanic/fields/Button/Buttons';
import { AdvancedLabel, Block, LoadingDots } from "@mosanic/items";
import { ControlTabs } from "@mosanic/items/Control/ControlButtons";
import { Page } from "@mosanic/items/Page";
import { colors } from "@mosanic/styles";
import { BarChartIcon, Component1Icon, DashboardIcon, EnvelopeOpenIcon, EyeOpenIcon, LockClosedIcon, ResetIcon } from "@radix-ui/react-icons";
import { userIdSelector } from "@redux/auth/reducer";
import { hasAttr } from "@util/Obj";
import { capitalizeFirst } from "@util/Text";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const defaultPref = {
  "theme":null,
  "sidebar":{"transparent":false,"compact":false},
  "helpers":{"tooltips":true,"intro":true,"headers":true,"autoCloseAccordion":true,"autoOpenLayers":true,"autoOpenInspect":true},
  "email":{"news-update":true,"tips-tutorial":true,"beta":true,"reminders":true,"direct":true,"comments":true},
  "privacy":{"public":true,"length":true,"messages":true,"email":true,"lastLogin":true,"status":true}
}

export const UserPreferences = ({overwrite = {
  interface: true,
  help: true,
  privacy: true,
  builder: true,
  dashboard: true,
  mail: true,
  platformName: 'Mosanic'
}}) => {
  const o = overwrite;
  const name = o.platformName || 'Mosanic';

  const router = useRouter();
  const hasGeneral = ( o.interface || o.help ) 
  const [main, setMain] = useState(hasGeneral ? 'general' : 'privacy')
  const [init, setInit] = useState(null)

  const _id = useSelector(userIdSelector)

  const [user, loading, error] = useUser({_id})
  const methods = useForm({mode: 'all', defaultValues: user});
  const {firstName, lastName, subscription} = user;

  const defaultState = {
    ...methods.watch(), 
    pref: defaultPref,
  }
  const resetToDefault = () => methods.reset(defaultState)
  const [update, dataUpdate, loadingUpdate, errorUpdate] = useUpdateUser()
  const updateAccount =(updatedUser) => update({_id, user: updatedUser});
  useEffect(() => {
    if(user?.pref && init != _id) {
      methods.reset(user)
      setInit(_id)

      if(!hasAttr(user, 'pref', 'lastLogin') && user?._id) {
        resetToDefault()
        updateAccount(defaultState)
      }
    }
  },[user, _id, init])
    
  
  
  const tabs = [{
    value: 'general',
    icon: <Component1Icon />,
    render: hasGeneral && <GeneralPreferences ui={o.interface} help={o.help} name={name}/>
  }, {
    value: 'privacy',
    icon: <LockClosedIcon />,
    render: o.privacy && <PrivacyPreferences name={name}/>
  }, {
    value: 'builder',
    icon: <DashboardIcon />,
    render: o.builder && <BuilderPreferences name={name}/>
  }, {
    value: 'dashboard',
    icon: <BarChartIcon />,
    render: o.dashboard && <DashboardPreferences name={name}/>
  }, {
    value: 'mail',
    icon: <EnvelopeOpenIcon />,
    render: o.mail && <EmailPreferences name={name}/>
  }, {
    value: 'all',
    label: 'View all',
    icon: <EyeOpenIcon />
  }]?.filter(t => t?.render)

return (
    <FormProvider {...methods} >

{/* {JSON.stringify(methods.watch()?.privacy)} */}
       
        <ControlTabs active={main} setActive={setMain} size="large" my={4} 
          options={tabs.map(t => ({label: <span>{capitalizeFirst(t.value)}</span>, ...t}))} 
        >
          <Button mt="1" ghost small force tooltip={{title: "Reset preferences", wrap: true}} icon={<ResetIcon />} onClick={() => resetToDefault()}/>
        </ControlTabs>
        

        <Block py={4}>

        {loading}
        {error}

        {tabs.map(t => (main === 'all' || main === t.value) && t?.render)}        


        <Buttons style={{float: 'right', marginRight: '5%'}}>
          <Button 
            label="Cancel"
            type="submit"
          />
          <Button 
            mr={0} theme="primary"
            label={loadingUpdate ? <LoadingDots /> : "Save changes"}
            onClick={() => updateAccount(methods.watch())}
          />
        </Buttons>
        </Block>
  {errorUpdate}
        
    </FormProvider>
  );
}