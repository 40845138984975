import { DELETE_MEDIA, DELETE_UPLOAD } from "@api/mutations/media";
import { useMutate } from "@hooks/useApi";
import { siteIdSelector } from "@redux/auth/reducer";
import { setNotification } from "@redux/notification/action";
import { removeFirstChar } from "@util/Text";
import { useDispatch, useSelector } from "react-redux";

const ERROR_DURATION = 10000;
const STATUS_DURATION = 4000;

const errorField = (field) => ({
    message: `Asset could not be deleted. ${field} not received.`, 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
})
const errorAction = (action) => ({
    message: `${action} error.`, 
    feature: "ImageContainer", 
    meta: {duration: ERROR_DURATION}
})
const successAction = (action) => ({
    message: `${action} success.`, 
    feature: "ImageContainer", 
    meta: {duration: STATUS_DURATION}
})

export const useDeleteAsset = () => {
    const dispatch = useDispatch();
    const siteId = useSelector(siteIdSelector)

    const notify = (notification) => dispatch(setNotification(notification));

	const [ deleteFile, [data, loading, error] ] = useMutate('deleteFile', DELETE_UPLOAD);
	const [ deleteMedia, [dataMedia, loadingMedia, errorMedia] ] = useMutate('mediaRemoveById', DELETE_MEDIA);


	const handleDelete = (asset) => {
        let {_id, filename, url} = asset;
        let next = true;

		if(url?.charAt(0) === '/') url = removeFirstChar(url)

		if(!_id) { notify(errorField('_id')); next = false; return; }
        if(!filename) { notify(errorField('filename'));next = false;  return; }
        if(!url) { notify(errorField('url')); next = false; return; }

        if(asset.siteId != siteId) {
            notify(errorAction('Current editing site is not as asset.'))
            next = false;
            return;
        }
        
        deleteMedia({_id}).then(({data}) => {
            if(data?.mediaRemoveById) data = data.mediaRemoveById
            const {error, recordId} = data;
            if(!error) {
                notify(successAction(`Deleted asset reference: ${recordId}`))
            } else {
                notify(errorAction(`Asset reference not deleted.`));
                next = false;
            }
        
        }).catch(e => {
            notify(errorAction(`Asset reference not deleted. Catch error.`));
            console.error(e);
            next = false;
        });

        if(next) {
			const file = {filename, url};
			deleteFile(file).then((response) => {
                notify(successAction(`Deleted asset`));
				console.log(response)
			
			}).catch(e => {
                notify(errorAction(`Asset not deleted. Catch error.`));
				console.log(e)
			});
        }
	}
    return [
        handleDelete,
        (dataMedia || data),
        (loading || loadingMedia),
        (error || errorMedia)
    ]

}