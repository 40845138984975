
import { css } from "styled-components";
import colors from "./colors";

const smoothAttention = css` 
  @keyframes smoothAttention {
    0% {
      transform: scale(0.9);
      border-width: 1px;
    }
    50% {
      transform: scale(1.2);
      border-width: 1.5px;
    }
    100% {
      transform: scale(0.9);
      border-width: 1px;
    }
  } 
`;

const gradientAttentionBg = css`
  @keyframes gradientAttentionBg {
    0% {
      background-position: 100% 0%
    }
    100% {
      background-position: 15% 100%
    }
  } 
`

const redNoticeAttention = css` 
  @keyframes redNoticeAttention {
    0% {
        background-color: ${colors.util.red.base};
    }
    50% {
      background-color: ${colors.util.red.dark};
    }
    100% {
      background-color: ${colors.util.red.base};
    }
  } 
`;

const statusAttention = css`
  @keyframes status {
    0% {
        opacity: 1;
        transform:scale(.5)
    }

    to {
        opacity: 0;
        transform: scale(3)
    }
  }
`
const redNoticeBorders = css` 
  @keyframes redNoticeBorders {
    0% {border-color: ${colors.util.red.light};}
    50% {border-color: ${colors.util.red.dark};}
    100% {border-color: ${colors.util.red.light};}
  } 
`;

const smoothSpin = css` 
  @keyframes smoothSpin {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(10deg);;
    }
    50% {
      transform: rotate(-10deg);;
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

export {
    gradientAttentionBg,
    redNoticeBorders,
    redNoticeAttention,
    smoothAttention,
    statusAttention,
    smoothSpin
}