import { BUILDER_ITEMS } from "@api/queries"
import { useQuery } from "@hooks/useApi"
import { ADD_ITEMS } from "@mosanic/constants/components"
import { colors } from "@mosanic/styles"
import { isString, lowCaseContainsSubstr } from "@util/Text"
import { useEffect, useMemo, useState } from "react"


export const useItems = () => {
    const [serverItems, loading, error] = useQuery('builderItemMany', BUILDER_ITEMS)

    return Boolean(serverItems) ? 
		[...serverItems, ...ADD_ITEMS] : 
        ADD_ITEMS;
}

const isSubstr = (val, input) => !val ? false : lowCaseContainsSubstr(val, input)
export const substrSearch = isSubstr;

const componentValidate = (c, input) => (
	isSubstr(c.component?.type, input) ||
	isSubstr(c?.component?.desc, input) || 
	isSubstr(c?.component?.title, input) || 
	isSubstr(c?.component?.icon, input) 
)

export const useSearchItems = ({components, validate = componentValidate}) => {
    const [input, set] = useState('')
	const [delayed, setDelay] = useState('')

	const setInput = e => setDelay(e)

	useEffect(() => {
		let timer = setTimeout(() => {
			set(delayed)
		}, 400);

		return () => clearTimeout(timer)
	},[ delayed ])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const isMatch = (c) => validate(c, input);

    const filteredComponents = useMemo(() => (
		input?.length >= 1 ? 
			components.filter(c => isMatch(c)) : 
			components
	),[input?.length, components, isMatch])

    return [delayed, setInput, filteredComponents]
}

export const getSearchMarkTitle = (title, input) => isString(title) ? title.replace(
	new RegExp(input, "gi"), (match) => (
`<mark style="background: ${colors.util.orange.mark}">${match}</mark>`
)) : title;