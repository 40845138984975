
const StatusOptions = [
	'PLANNED',
	'PUBLISHED',
	'PENDING',
	'DRAFT',
    'DISABLED',
	'EDITING',
	'HIDDEN',
	'LOCKED',
	'TRASHED',
];
import { baseSchemaFields, convertColumnsToSchema } from "./base"
import { get } from "@util/Obj";
import { convertEntry } from "@mosanic/cms/Query/useDataQuery";
import { isString } from "@util/Text";
import { updateSchemaProp, updateSlug } from "./helpers";
import { isNumber } from "@util/Num";

export const collectionColumns = [{
    name: 'title',
    type: 'text'
},{
    name: 'description',
    type: 'text'
},{
    name: 'Fields',
    type: 'custom'
},{
    name: 'Entries',
    type: 'text'
},{
    name: 'Linked',
    type: 'text'
},{
    name: 'Base route',
    slug: 'baseRoute',
    type: 'text'
}, 
...baseSchemaFields
]



const overwrite = f => {
    if(f?.name === 'Biography') return {...f, type: 'textarea'}
    return f;
}
const validate = (props, option, type = 'collection') =>  get(props, option) && props.type === type
const formatCollectionForDataGridColumns = c => ({
    ...c, 
    Fields: c?.schema?.length?.toString(),
    Entries: c?.entries?.length ? c?.entries?.length?.toString() : isNumber(c?.entries) ? c?.entries : '0',
    Linked: isString(c?.entries?.[0]) || c?.linked ? 'true' : 'false'
})

export const collectionsSchema = {
    groups: [
        'base',
        'preview',
        'schema'
    ],
    formatting: {
        columns: formatCollectionForDataGridColumns,
        entries: entry => ({
            ...entry, 
            ...entry?.data, 
            ...entry?.map, 
            ...entry?.filters, 
            ...entry?.props, 
            map: undefined, 
            data: undefined,
            filters: undefined,
            props: undefined 
        })
    },
    site: {
        // schema: getSiteUserSchema
    },
    columns: [
        ...collectionColumns,
        // ...subscriptionColumns
    ],
    props: {
        multiple: 'collections',
        singular: 'collection',
        type: ['collection', 'form'],
        info: {
            title: 'Collections',
            label: "Manage your data collections",
            help: "Mosanic collections are the place where you can store your data",
            slug: [{href: '/site', label: 'Site'}]
        },
        empty: {
            title: 'No collections found.',
            label: "Let's start with creating your site's first data collection."
        },
        controls: {
            create: 'create',
            update: 'update',
            delete: 'delete',
            view: 'view',
        },
        routing: {
            indexPath: null,
            basePath: 'collection',
        },
        editor: {
            schema: true,
            entry: 'data_table'
        },
        entries: true
    },
    base: [
        {
            name: 'status',
            type: 'select',
            options: StatusOptions
        }, {
            name: 'singular',
            type: 'text',
        }, {
            name: 'inside',
            type: 'switch',
        }, {
            name: 'limit',
            type: 'number',
            when: props => props.type != 'collection'
        },
        ...convertColumnsToSchema(collectionColumns).map(f => overwrite(f)),
        {
            label: 'Form',
            name: 'form',
            when: props => props.type === 'form',
            schema: [{
                name: 'type',
                label: 'Stepper flow',
                helpText: "Each field is displayed in a separate step. To combine fields in a step, use a group field.",
                type: 'switch',
                when: props => props.type === 'form'
            },{
                name: 'dataDelete',
                label: 'Auto delete',
                type: 'select',
                options: '7 days|14 days|1 month|3 months|6 months|fill-up'.split('|'),
                when: props => props.type === 'form'
            }]
        },{
        //     name: 'config',
        //     label: 'Entry config',
        //     schema: [{
        //         name: 'notLinked',
        //         label: 'Entries inside schema',
        //         helpText: 'Entries',
        //         type: 'switch',
        //         fullWidth: true
        //     }]
        // },{
            label: 'Routing',
            name: 'routing',
            when: props => props.type === 'collection',
            schema: [{
                name: 'catchRoutes',
                label: 'Catch routes',
                type: 'switch'
            },{
                name: 'frontEnd',
                label: 'Front end',
                type: 'switch'
            },{
                name: 'baseRoute',
                label: 'Base route',
                type: 'text',
                when: props => validate(props, 'routing.catchRoutes')
            },{
                name: 'dynamicPage',
                label: 'Dynamic page',
                type: 'page',
                when: props => validate(props, 'routing.catchRoutes')
            },{
                name: 'editRoute',
                label: 'Edit route',
                type: 'text',
                when: props => validate(props, 'routing.frontEnd')
            },{
                name: 'editPage',
                label: 'Edit page',
                type: 'page',
                when: props => validate(props, 'routing.frontEnd')
            },{
                name: 'indexPage',
                label: 'Index page',
                type: 'page',
                when: props => validate(props, 'routing.catchRoutes')
            },{
                name: 'notFoundPage',
                label: '404 page',
                type: 'page',
                when: props => validate(props, 'routing.catchRoutes')
            }]
        }
    ]
}



const getCollectionSchema = () => {
    let schema = updateSlug(collectionsSchema, [
        {href: '/site', label: 'Site'}, 
        {href: '/site/collections', label: 'Collections'}
    ])

    schema = updateSchemaProp({
        schema,
        path: 'props.routing.indexPath',
        data: '/site/collections',
    })

    schema = updateSchemaProp({
        schema,
        path: 'props.editor.entry',
        data: 'entry',
    })
    return schema;
}
export const collectionSchema = getCollectionSchema()
