import { CenterCenter, colors } from "@mosanic/styles";
import styled, { css } from "styled-components";
import { space, layout } from "styled-system";

const Button = styled.span` 
    padding: 10px;
    font-size: 12px;
    transition: all ease-in-out 0.3s;
    &:hover {
        background: ${colors.util.gray.light};
        cursor: pointer;
    }
    ${props => props.disabled && css` 
        opacity: 0.3;
    `}
`
const HeaderField = styled.div` 
    min-width: 100px;
`
const HeaderType = styled.div` 
    text-align: right;
    font-size: 10px;
    min-width: 70px;
    text-transform: upperCase;
    padding: 0 5px;
    opacity: 0.4;
    cursor: s-resize;

    svg {
        transform: scale(0.9);
        transform-origin: top left;
    }
`
const FieldControls = styled.div` 
    right: 0;
    top: 15px;
    min-width: 120px;
    /* z-index: 2; */

    button {
        z-index: 2;
        display: inline-block;
    }

    ${layout}
    ${space}
`
const HeaderToggle = styled(Button)` 
    /* padding: 15px 15px 20px 15px; */
    display: inline-block;
    cursor: ${props => !props.expanded ? 's-resize' : 'n-resize'}!important;
    /* position: absolute;
    text-align: right;
    top: 0;
    right:0;
    bottom: 0; */
    z-index: 0;
    width: 40px;

    &, svg, svg path {
        transition: all ease-in-out 0.3s;
    }
    &:hover {
        svg path {
            transform: scale(1.5);
            transform-origin: center center;
        }
    }
    ${props => props.disabled && css` 
        opacity: 0.2;
    `}
`

const FieldHeader = styled.div` 
    position: relative;
    transition: all ease-in-out 0.3s;
    min-width: 100%;
    padding: 0 0 0 10px;
    height: 60px;
    border-bottom: 1px solid ${colors.util.gray.light};
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    ${props => props.expanded ? css`
        background: ${colors.util.gray.lighter};

        ${HeaderToggle} svg {
            transform: rotate(-180deg);
        }
    ` : css`
        background: ${colors.util.gray.trans};
    `}
    &:hover {
        background: ${colors.util.gray.lighter};
    }

    input {
        /* z-index: 2; */
    }

    ${props => (props.level && props.level >= 1) && css` 
        min-width: 90%;
        padding: 0 0 0 10px;
        margin-left: -10px;
        background: ${props => props.expanded ?
            colors.util.gray.lighter : 'transparent'
        };
        /* ${HeaderField} {
            position: absolute;
            top: 0;
        } */
    `}
`

const FieldEntry = styled.div` 
    position: relative;
    
`
const FieldEntryFields = styled.div` 
    padding: 0 0 20px;
`

//Field specific
const RelationLabel = styled.span` 
    color: ${colors.util.primary.base};
    padding: 0 5px;
`

const FieldTypes = styled.div` 
    display: flex;
    flex-wrap: wrap;
`
const HeaderLabel = styled.div` 
    opacity: 0.5;
    overflow: hidden;
    min-width: 120px;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
`
const FieldType = styled.div` 
    position: relative;
    width: 23%;
    padding: 10px 10px;
    text-align: center;
    font-size: 12px;
    ${CenterCenter}
    margin: 10px 1%;
    background: ${props => props.coming ? colors.util.gray.lightest : "transparent"};
    border: ${props => props.coming ? `1px dashed ${colors.util.gray.light}` : "none"};
    transition: all ease-in-out 0.3s;
    border-radius: 5px;
    flex-wrap: wrap;

    ${props => props.active && css` 
        border: 2px solid ${colors.util.gray.base};
    `}
    :hover {
        background: ${props => props.coming ? colors.util.gray.trans : colors.util.gray.lighter};
        transform: ${props => props.coming ? 'scale(0.9)' : 'scale(1.1)'};
        cursor: pointer;
    }
    :active {
        transform: scale(1);
    }
    div {
        width: 100%;
    }
`
const CollectionStyles = {
    Button,

    FieldHeader,
    HeaderField,
    HeaderType,
    HeaderLabel,
    HeaderToggle,

    FieldControls,

    FieldEntry,
    FieldEntryFields,

    RelationLabel,
    FieldType,
    FieldTypes
}
export default CollectionStyles;