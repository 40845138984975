import "./fields/Select/Select.register";
// import { getMobileFirstScreens } from "@redux/canvas/reducer";
export const DEFAULT_BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 850,
    lg: 1200,
    xl: 1400,
};

export const parseIcon = (type) => type === 'parse/icons';

export const getMobileFirstScreens = () => Object.keys(DEFAULT_BREAKPOINTS);
import { arrToObj } from "@util/Obj";
import dynamic from "next/dynamic";
const SelectLayout = dynamic(() => import("src/components/Builder/Util/SelectLayout"))
import { mosanic } from "./root";
import { CrossCircledIcon, DotsHorizontalIcon, EyeClosedIcon, FontBoldIcon, FontItalicIcon, FontRomanIcon, LetterCaseCapitalizeIcon, LetterCaseLowercaseIcon, LetterCaseUppercaseIcon, ScissorsIcon } from "@radix-ui/react-icons";
import { loop, loopFunc } from "@util/Arr";


export const defaultEndAdornmentValueFallback = 'px';

export const quickArrowButtonPresets = [{
  id: 'smallSet',
  values: [0, 10, 20, 40]
},{
  id: 'largeSet',
  values: [60, 100, 140, 220]
}];

export const addUnset = arr => [...arr, {value: null, label: 'Unset'}];

export const defaultSizingArray = [
  // {value:'none', tooltip: 'Unset field'}, 
  // {value: 'auto', tooltip: 'Set automatic'}, 
  {value: 'stretch', tooltip: 'Stretch content'}, 
  {value: 'contain', tooltip: 'Contain content'}, 
  'divider',
  {value: '%', convert: true, tooltip: 'Percentage (%)'},
  {value: 'px', convert: true, tooltip: 'Pixels (px)'},
  {value: 'vh', convert: true, tooltip: 'Viewport height (vh)'},
  {value: 'vw', convert: true, tooltip: 'Viewport width (vw)'},
  'divider',
]

export const defaultMetricArray = [
  'divider',
  {value: '%', convert: true, tooltip: 'Percentage (%)'},
  {value: 'px', convert: true, tooltip: 'Pixels (px)'},
  {value: 'vh', convert: true, tooltip: 'Viewport height (vh)'},
  {value: 'vw', convert: true, tooltip: 'Viewport width (vw)'},
  'divider',
];
export const defaultMinimumArray = [
  {value: '%', convert: true, tooltip: 'Percentage (%)'},
  {value: 'px', convert: true, tooltip: 'Pixels (px)'},
  'divider',
];
export const defaultMarginArray = [
  {value: 'auto', tooltip: 'Set automatic'}, 
  'divider',
  {value: '%', convert: true, tooltip: 'Percentage (%)'},
  {value: 'px', convert: true, tooltip: 'Pixels (px)'},
  {value: 'vh', convert: true, tooltip: 'Viewport height (vh)'},
  {value: 'vw', convert: true, tooltip: 'Viewport width (vw)'},
  'divider',
];
export const advancedMetricArray = [
  {value: 'cm', convert: true, tooltip: 'Centimeters (cm)'},
  {value: 'mm', convert: true, tooltip: 'Millimeters (mm)'},
  {value: 'in', convert: true, tooltip: 'Inches (in)'},
  {value: 'pt', convert: true, tooltip: 'Points (pt)'},
  'divider',
];
export const defaultTypographyMetricArray = [
  {value: 'px', convert: true, tooltip: 'Pixels (px)'},
  {value: 'em', convert: true, tooltip: 'Font size of the parent'},
  {value: 'rem', convert: true, tooltip: 'Font size of the root element'},
  {value: 'ch', convert: true, tooltip: `Advance measure of '0' from current font`},
  {value: 'lh', convert: true, tooltip: `Line height of the element`},
  {value: 'ex', convert: true, tooltip: `X-Height of element's font`},
  'divider',
]; 
mosanic.registerFieldset("sizing", {
  name: "sizing",
  meta: {
    description: "Element sizing",
    helpSource: "layout/sizing/inspector",
  },
  props: {
    fields: ['width', 'height', 'minWidth', 'minHeight', 'maxWidth', 'maxHeight'],
    endAdornment: {
      type: 'list',
      options: defaultSizingArray,
      defaultValue: 'px'
    },
  },
  labelFormat: 'parse/camelCase',
  returnFormat: 'parse/string/css',
  path: 'data.sizing.[field]',
  expand: 'slice',
  type: 'metricInput',
  defaultValue: ''
});
mosanic.registerFieldset("padding", {
  name: "padding",
  meta: {
    description: "Element padding",
    helpSource: "layout/padding/inspector",
  },
  props: {
    fields: ['top', 'bottom', 'left', 'right'],
    endAdornment: {
      type: 'list',
      options: defaultMetricArray,
      defaultValue: 'px'
    },
    fieldArrows: true,
    inline: 'four',

  },
  labelFormat: 'parse/camelCase',
  returnFormat: 'parse/string/css',
  path: 'data.padding.[field]',
  type: 'metricInput',
  minimize: {
    type: 'inlineValues' //todo --> show in header tab
  },
  defaultValue: '0'
});

mosanic.registerFieldset("margin", {
  name: "margin",
  meta: {
    description: "Element margin",
    helpSource: "layout/margin/inspector",
  },
  props: {
    fields: ['top', 'bottom', 'left', 'right'],
    endAdornment: {
      type: 'list',
      options: defaultMarginArray,
      defaultValue: 'px'
    },
    fieldArrows: true,
    inline: 'four',
  },
  labelFormat: 'parse/camelCase',
  returnFormat: 'parse/string/css',
  path: 'data.margin.[field]',
  type: 'metricInput',
  defaultValue: '0'
});

mosanic.registerFieldset("docking", {
  name: "docking",
  meta: {
    description: "Element docking",
    helpSource: "layout/docking/inspector",
  },
  props: {
    fields: ['top', 'bottom', 'left', 'right'],
    endAdornment: {
      type: 'list',
      options: defaultMarginArray,
      defaultValue: 'px'
    },
    fieldArrows: true,
    inline: 'four',
  },
  labelFormat: 'parse/camelCase',
  returnFormat: 'parse/string/css',
  path: 'data.docking.[field]',
  type: 'metricInput',
  defaultValue: '0'
});

mosanic.registerFieldset("corner", {
  name: "corner",
  meta: {
    description: "Element Corners",
    helpSource: "layout/corners/inspector",
  },
  props: {
    fields: ['top-left', 'bottom-right', 'bottom-left', 'top-right'],
    endAdornment: {
      type: 'list',
      options: defaultMinimumArray,
      defaultValue: 'px'
    },
    fieldArrows: true,
    extra: 'corners',
    inline: 'four',
  },
  labelFormat: 'parse/camelCase',
  returnFormat: 'parse/string/css',
  path: 'data.corner.[field]',
  type: 'metricInput',
  defaultValue: '0'
});

// Registering Parallax Tilt as an attachment
// registerComponent(ReactParallaxTilt, {
//   name: 'Parallax Tilt',
//   isAttachment: true,
//   props: {
//     tiltEnabled: 'boolean',
//     tiltReverse: 'boolean',
//     tiltAngleX: 'number',
//     tiltAngleY: 'number',
//     children: 'slot'
//   }
// });
 
mosanic.registerFieldset("flex", {
  name: "flex",
  meta: {
    description: "Display flex",
    helpSource: "layout/flex/inspector",
  },
  props: { 
    direction: {
      type: 'choice',
      options: ['horizontal', 'vertical'],
      tooltip: 'Column or row direction',
    },
    align: {
      type: 'choice',
      options: ['start', 'center', 'end', 'stretch', 'baseline'],
      wrapperClass: props => props?.direction === 'vertical' ? '-rotate' : '',
      labelFormat: 'parse/icons',
      tooltip: 'Align items'
    }, 
    justify: {
      type: 'choice',
      options: ['start', 'center', 'end', 'between', 'around'],
      wrapperClass: props => props?.direction === 'vertical' ? '-rotate' : '',
      labelFormat: 'parse/icons',
      tooltip: 'Justify spacing items'
    },
    group: {
      wrap: {
        type: 'toggle',
        display: 'choice'
      },
      reverse: 'toggle',
      test: {
        type: 'toggle',
        display: props => props?.wrap
      }
    },
    // label: {
    //   type: 'text',
    //   placeholder: 'Test text'
    // },
    submit: 'select',
    // submit: 'btn',

    // count: {
    //   type: 'number',
    //   defaultValue: 2,
    //   max: 10
    // },

    // range: 'slider',

  },
  // expand: 'slice',
  path: 'data.display.[field]',
});

mosanic.registerFieldset("container", {
  name: "container",
  meta: {
    description: "Section layout",
    helpSource: "layout/section/inspector",
  },
  props: { 
    container: {
      tooltip: 'Container toggles',
      helpText: "Select the order of this column relative to its parents children.",
      options: [...getMobileFirstScreens(), 'all'],
      type: 'toggle'
    },
    layout: {
      tooltip: 'Change this section layout',
      helpText: "Layout change can also by applied by clicking the preferred section.",
      render: <SelectLayout rowData={null} hideOthers={true}/>,
      type: 'render',
    },
  },
  labelFormat: 'parse/icons',
});

const bpOptions = {
  type: 'incrementInput',
  options: getMobileFirstScreens(),
  baseOnly: true
}

mosanic.registerFieldset("columnGrid", {
  name: "columnGrid",
  meta: {
    description: "Section layout",
    helpSource: "layout/section/inspector",
  },
  props: { 
    grid: {
      tooltip: 'Grid sizing',
      ...bpOptions
    },
    offset: {
      tooltip: 'Grid offset',
      ...bpOptions
    },
    order: {
      tooltip: 'Grid order',
      ...bpOptions
    },
    elements: {
      tooltip: 'Elements',
      ...bpOptions
    },
    hide: {
      ...bpOptions
    }
  },
  expand: {
    type: 'tab',
    defaultValue: 'grid',
  },
  labelFormat: 'parse/icons',
  iconGroup: 'grid',
});

const sizes = [{
  value: 'xs',
  label: 'Extra small'
}, {
  value: 'sm',
  label: 'Small'
},{
  value: 'md',
  label: 'Medium'
},{
  value: 'lg',
  label: 'Large'
},{
  value: 'xl',
  label: 'Extra large'
},{
  value: 'custom',
  label: 'Custom'
}]


const customField = (type = 'number') => ({
  type,
  display: props => props?.shadow === 'custom',
})
mosanic.registerFieldset("shadow", {
  name: "shadow",
  meta: {
    description: "Box shadow",
    helpSource: "layout/shadow/inspector",
  },
  props: { 
    shadow: {
      type: 'select',
      options: addUnset(sizes),
      tooltip: 'Box shadow',
    },
    type: {
      ...customField('select'),
      options: ['outset', 'inset'],
    },
    left: customField(),
    top: customField(),
    blur: customField(),
    spread: customField(),
    color: customField('color'),
  },
  path: 'data.shadow.[field]'
});

const cursors = ('pointer|help|alias|none|not-allowed|context-menu|progress|text|crosshair|copy|move|grab|grabbing|zoom-in|zoom-out'
  .split('|')
  .map(c => ({value: c, label: <div style={{cursor: c}}>{c}</div>}))
);

// * Custom behavior 
// - Reveal on scroll
// - 3D parallax tilt 
// - Parallax scroll
// - Component store?
mosanic.registerFieldset("templates", {
  name: "templates",
  props: { 
    template: {
      type: 'select',
      options: 'default|none|clear'.split('|'),
    },
    create: {
      type: 'btn',
    }
  }
});
mosanic.registerFieldset("presets", {
  name: "presets",
  props: { 
    animation: {
      type: 'select',
      options: '3D Parallax tilt| Parallax scroll|Animate in'.split('|'),
    },
  }
});
mosanic.registerFieldset("animate", {
  name: "animate",
  props: { 
    'Coming Soon': {
      type :'btn'
    }
  }
});
mosanic.registerFieldset("cursor", {
  name: "cursor",
  meta: {
    description: "Scroll",
    helpSource: "layout/scroll/inspector",
  },
  props: { 
    cursor: {
      type: 'select',
      options: [
        {value: null, label: 'auto'}, 
        ...cursors
      ],
    },
    reveal: {
      type: 'toggle',
      label: 'Reveal on scroll'
    }
  }
});
mosanic.registerFieldset("position", {
  name: "position",
  meta: {
    description: "Position",
    helpSource: "layout/position/inspector",
  },
  props: { 
    position: {
      type: 'choice',
      options: ['relative', 'absolute', 'fixed', 'sticky'],
      //        'auto'       'free'      'fixed'   'sticky'
    },
    place: {
      type: 'number',
      // type: 'top, right, bottom, left',
      // help icons: 
      display: props => props?.position === 'absolute'
    },
    zIndex: {
      type: 'number'
    },
  }
});

mosanic.registerFieldset("display", {
  name: "display",
  meta: {
    description: "Display",
    helpSource: "layout/scroll/inspector",
  },
  props: { 
    hidden: {
      tooltip: 'Hidden on screen:',
      label: 'Hidden on screen:',
      helpText: "Select the order of this column relative to its parents children.",
      options: [{value: 'all', label: <EyeClosedIcon />}, ...getMobileFirstScreens()],
      type: 'toggle'
    },
    opacity: {
      type: 'slider',
      min: 0,
      max: 1,
      step: 0.1
      // minDistance: 0.05,
    },
    objectFit: {
      type: 'select',
      options: addUnset('fill|contain|cover|none|scaleDown'.split('|'))
    },
    overflow: {
      type: 'select',
      options: addUnset('visible|hidden|clip|scroll|overlay|auto'.split('|'))
    },
  }

});

const filterOptions = (type) => ({
  [`${type}Type`]: {
    type: 'select',
    options: addUnset('blur|brightness|contrast|grayscale|drop-shadow|hue-rotate|invert|opacity|sepia|saturate'.split('|')),
    baseOnly: true
  },
  amount: {
    type: 'string'
  },
  color: {
    type: 'color',
    display: props => props?.[`${type}Type`] === 'drop-shadow'
  },
});

mosanic.registerFieldset("effect", {
  name: "effect",
  meta: {
    description: "Filter effect",
    helpSource: "layout/filter-effect/inspector",
  },
  props: { 
    filterGroup: {
      ...filterOptions('filter')
    },
    backdropGroup: {
      ...filterOptions('backdrop')
    },
  },
  expand: {
    type: 'tab',
    place: 'inline',
    defaultValue: 'filterGroup',
  },
  path: 'data.effect.[field]',
});

// * Transform
// - Move, rotate, scale, skew (x y z)

mosanic.registerFieldset("transform", {
  name: "transform",
  meta: {
    description: "Transform",
    helpSource: "layout/transform/inspector",
  },
  props: { 
    transform:{
      type: 'transform',
      receive: ['image', 'bg.repeat', 'bg.size', 'bg.sizeValue'],
    },
  },
  path: 'data.transform.[field]',
});


mosanic.registerFieldset("transition", {
  name: "transition",
  meta: {
    description: "Transition",
    helpSource: "layout/transition/inspector",
  },
  props: { 
    baseGroup: {
      target: {
        type: 'select',
        options: addUnset('all|margin|padding|background|opacity|custom'.split('|')),
        baseOnly: true
      },
      selector: {
        type: 'string',
        baseOnly: true,
        display: props => props?.target === 'custom'
      },
      duration: {
        type: 'string',
        defaultValue: '0.3s',
      },
    },
    advancedGroup: {
      curve: {
        type: 'select',
        options: addUnset('ease|ease-in-out|ease-in|ease-out|linear|custom'.split('|')),
        baseOnly: true
      },
      bezier: {
        type: 'bezier',
        baseOnly: true,
        display: props => props?.curve === 'custom'
      },
      delay: {
        type: 'string',
        defaultValue: '0s'
      },
    },
  },
  expand: {
    type: 'tab',
    place: 'inline',
    defaultValue: 'baseGroup',
  },
  path: 'data.transition.[field]',
});



mosanic.registerFieldset("border", {
  name: "border",
  meta: {
    description: "Border",
    helpSource: "layout/shadow/inspector",
  },
  props: { 
    width: {
      type: 'slider',
      minDistance: 0.25,
      min: 0,
      max: 10
    },
    style: {
      type: 'select',
      options: addUnset('solid|dashed|dotted'.split('|'))
    },
    color: {
      type: 'color',
    }
  },
  path: 'data.border.[field]',
});

mosanic.registerFieldset("background", {
  name: "background",
  meta: {
    description: "Background options",
    helpSource: "layout/background/inspector",
  },
  props: { 
    background: {
      type: 'color',
      tooltip: 'Background color',
    },
    imageGroup: {
      image: {
        type: 'imageUrl'
      },
      'bg.position': {
        label: 'position',
        type: 'focal',
        receive: ['image', 'bg.repeat', 'bg.size', 'bg.sizeValue'],
        receiveBaseOnly: true,
      },
      'bg.size': {
        type: 'select',
        label: 'Size',
        options: addUnset('contain|cover|percentage'.split('|')),
      },
      'bg.sizeValue': {
        type: 'string',
        label: 'Size value',
        display: props => props?.bg?.size === 'percentage',
      },
      'bg.repeat': {
        type: 'select',
        label: 'Repeat',
        options: addUnset('repeat|repeat-x|repeat-y|no-repeat'.split('|')),
      },
    },
    gradient: {
      type: 'gradient',
    }
  },
  path: 'data.[field]',
  expand: {
    type: 'tab',
    place: 'inline',
    defaultValue: 'background',
  },
});

const align = 'left|center|right|justify'.split('|').map(a => ({value: a, label: a}))

mosanic.registerFieldset("text", {
  name: "text",
  meta: {
    description: "Text options",
    helpSource: "layout/text/inspector",
  },
  props: { 
    textAlign: {
      type: 'choice',
      options: addUnset(align),
      labelFormat: 'parse/icons',
    },
    textColor: {
      type: 'colorPlus'
    },
  },
  expand: {
    place: 'inline'
  }
});

mosanic.registerFieldset("textColor", {
  name: "textColor",
  props: { 
    textColor: {
      type: 'colorPlus'
    },
  },
});
mosanic.registerFieldset("font", {
  name: "font",
  props: { 
    font: {
      type: 'font',
    },
    size: 'number',
    weight: {
      type: 'select',
      options: loopFunc(4, i => ({value: i, label: (i*100).toString()}))
    }
  },
  path: 'data.font.[field]'
});
mosanic.registerFieldset("letters", {
  name: "letters",
  props: { 
    textAlign: {
      type: 'choice',
      options: addUnset(align),
      labelFormat: 'parse/icons',
    },
    lineHeight: {
      label: 'Line Height',
      type: 'number',
    },
    letterSpacing: {
      label: 'Letter spacing',
      type: 'number',
    },
    style: {
      type: 'choice',
      options: [{
        value: null,
        label: <FontRomanIcon />
      },{
        value: 'italic',
        label: <FontItalicIcon />
      },{
        value: 'bold',
        label: <FontBoldIcon />
      }]
    },
    transform: {
      type: 'choice',
      options: [{
        value: 'uppercase',
        label: <LetterCaseUppercaseIcon />
      },{
        value: 'capitalize',
        label: <LetterCaseCapitalizeIcon />
      },{
        value: 'lowercase',
        label: <LetterCaseLowercaseIcon />
      },{
        value: null,
        label: 'Unset'
      }]
    },
    selectable: {
      type: 'toggle',
      defaultValue: 1
    },
    clip: {
      type: 'toggle',
      baseOnly: true
    },
    overflow: {
      type: 'choice',
      display: props => props?.clip === 1,
      options: [{
        value: 'excerpt',
        label: <DotsHorizontalIcon />
      },{
        value: 'clip',
        label: <ScissorsIcon />
      },{
        value: null,
        label: 'Overflow'
      }]
    },
  },
  path: 'data.font.[field]'
});
const htmlTags = [
    {value: null,  label: 'Component'},
    {value: 'div', label: 'Box'},
    {value: 'p', label: 'Paragraph'},
    {value: 'span', label: 'Span'},
    {value: 'article',label: 'Article'},
    {value: 'main', label: 'Main'},
    {value: 'section', label: 'Section'},
    {value: 'nav', label: 'Nav'},
    {value: 'header', label: 'Header'},
    {value: 'footer', label: 'Footer'},
    {value: 'aside', label: 'Aside'},
    {value: 'address', label: 'Address'},
    {value: 'blockquote', label: 'Blockquote'},
    {value: 'cite', label: 'Citation'},
    {value: 'dt', label: 'Term'},
    {value: 'ul', label: 'Unordered list'},
    {value: 'ol', label: 'Ordered list'},
    {value: 'dl', label: 'Description list'},
    {value: 'li', label: 'List item'},
    {value: 'a', label: 'Link tag'},
    {value: 'strong', label: 'Strong'},
    {value: 'italic', label: 'Italic'},
    {value: 'figure', label: 'Figure'},
    {value: 'figcaption', label: 'Figure Caption'},
    {value: 'pre', label: 'Pre'},
    {value: 'code', label: 'Code'},
    {value: 'dd', label: 'Description'},
    {value: 'form', label: 'Form'},
    {value: 'label', label: 'Label'},
    {value: 'input', label: 'Input'},
    {value: 'button', label: 'Button'},
    ...loopFunc(6, i => ({value: `h${i+1}`, label: `H${i+1}`}))
].map(o => o.value ? ({...o, label: `${o.label} <${o.value}>`, tag: o.value}) : o);

mosanic.registerFieldset("tags", {
  name: "tags",
  meta: {
    description: "Attributes",
    helpSource: "layout/coding/inspector",
  },
  props: {
    mainGroup: {
      tag: {
        type: 'select',
        options: htmlTags,
        baseOnly: true,
      },
      type: {
        type: 'select',
        searchable: true,
        baseOnly: true,
        options: 'text|password|hidden|email|number|date|time|email|tel'.split('|'),
        display: props => props?.tag === 'input',
      },
      anchorId: 'string',
      className: 'string',
      identifier: 'string',
      tabIndex: 'string',
    },
    ariaGroup: {
      role: 'string',
      label: 'string',
      describedBy: 'string',
      label: 'string',
      labelledBy: 'string',
    }
  },
  expand: {
    type: 'tab',
    defaultValue: 'mainGroup',
  },
  path: 'data.tags.[field]'
});
mosanic.registerFieldset("coding", {
  name: "coding",
  meta: {
    description: "Coding",
    helpSource: "layout/coding/inspector",
  },
  props: { 
    css: {
      type: 'code',
      lang: 'css',
    },
  },
});
mosanic.registerFieldset("html", {
  name: "html",
  meta: {
    description: "Html",
    helpSource: "layout/html/inspector",
  },
  props: { 
    html: {
      type: 'code',
      lang: 'html',
    },
  },
});
export const FIELD_SETS = mosanic.fieldsets;
export const getFieldRules = (rules, field) => rules && rules['sizing'][field];
export const getFieldset = (fieldset) => FIELD_SETS[fieldset];
export const getFieldsetFields = (fieldset) => (
  !Array.isArray(getFieldset(fieldset)?.props?.fields) ?
    Object.entries(getFieldset(fieldset)?.props).map(
      ([fieldName, fieldData]) => ({fieldName, fieldData})
    ) : 
    getFieldset(fieldset)?.props?.fields
);

export const getFieldsetFieldsAsObject = (fieldset) => arrToObj(getFieldsetFields(fieldset));
