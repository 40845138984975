import { ControlMenu } from '@mosanic/items';
import { getContainerOffset } from '@redux/canvas/reducer';
import { useCallback, useState } from "react";

import SaveLayout from 'src/components/Builder/Items/SaveLayout';
import RowAddSection from 'src/components/Builder/Layout/Row/AddNew';
import SelectLayout from 'src/components/Builder/Util/SelectLayout';

// import SaveLayout from '../Items/SaveLayout';
// const SelectLayout = dynamic(() => import("../Util/SelectLayout"))
// import RowAddSection from "./Row/AddNew";
import {default as w} from "./Styles/Item.wrapper.styles";
import { default as s } from './Styles/Item.styles';

// const mediaQuery = (minWidth, maxWidth) => {
//   return `@media(min-width: ${minWidth}px) {
//     max-width: ${maxWidth}px;
//     margin-right: auto;
//     margin-left: auto;
//   }`;
// };

/** 
 * Row 
 * @param {*} param0 
 * @returns 
 */
const Row = ({props, menu, production= false}) => {
    const {item, child, path, level, breakpoint, hover, inspect, ref} = props;



  const [rowAdd, toggleRowAdd] = useState(false);

  const hasContainer = (item?.data?.container && item?.data?.container[breakpoint] || item?.data?.container?.all);


  
  return (
    <>
    {!production && (
      <>


    
      <ControlMenu position={{x: menu?.position?.x, y: menu?.position?.y}} close={menu?.closeMenu}>
        Adjust section layout

        <SelectLayout rowData={child} passHandleClose={menu?.closeMenu} hideOthers={true}></SelectLayout>
        <SaveLayout row={item} rowData={child}></SaveLayout>
      </ControlMenu>
     


      <w.RowAdd className="row__add" style={rowAdd ? {opacity:1} : {}}>
        <RowAddSection passRowState={toggleRowAdd} rowId={path}/>
      </w.RowAdd>
      </>
    )}


      {hasContainer || true ? (
        <s.ColWrapper className="row_col_wrapper" offset={getContainerOffset(breakpoint)}>
          <s.Columns className="row_col_container">
            {props.children}
          </s.Columns>
        </s.ColWrapper>
      ) : (
        <s.Columns className="row_col_container">
         {props.children}
        </s.Columns>
      )}

    </>
  );
};
export default Row;
// export default memo(Row, (prevProps, nextProps) => {
//   return (
//     prevProps.isActive === nextProps.isActive && 
//     prevProps.element === nextProps.element)
// });