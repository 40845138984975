import { trash } from '@mosanic/Icons/EditIcons';
import { ControlPopper } from '@mosanic/items';
import { colorByHexSelector, colorsSelector, paletteColorsSelector, simplePaletteSelector } from '@redux/colors/reducer';
import { getRGB, invertColor } from '@util/color/Color';
import { toLowCase } from '@util/Text';
import { useEffect, useState } from "react";
import { CustomPicker, SketchPicker } from "react-color";
import { EditableInput } from "react-color/lib/components/common";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ButtonGroup } from '../Button/ButtonGroup';
import Button from '../Button/Buttons';
import BaseField from '../Input/Base';
import { useBaseField } from '../Input/Base';
import { default as s } from './ColorPicker.styles';

const defaultValue = '#FFFFFF';
const wheel = <svg version="1.1" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px"><path d="M0.877075,7.49985c0,-3.65769 2.96515,-6.62284 6.62284,-6.62284c3.65769,0 6.62279,2.96515 6.62279,6.62284c0,3.65765 -2.9651,6.62285 -6.62279,6.62285c-3.65769,0 -6.62284,-2.9652 -6.62284,-6.62285Zm2.90428,-4.2842c0.90163,-0.78326 2.05294,-1.28661 3.31863,-1.37476v4.6934l-3.31863,-3.31864Zm-0.56568,0.56569c-0.78325,0.90164 -1.28658,2.05294 -1.37472,3.31863h4.69335l-3.31863,-3.31863Zm3.31863,4.11863h-4.69333c0.08819,1.26565 0.59156,2.41693 1.37482,3.31853l3.31851,-3.31853Zm-2.75281,3.88423c0.90161,0.7831 2.05286,1.2865 3.31849,1.3746v-4.69314l-3.31849,3.31854Zm4.11849,-3.31854v4.69314c1.26561,-0.0882 2.41682,-0.5915 3.31842,-1.3747l-3.31842,-3.31844Zm3.88412,2.75274c0.7832,-0.9016 1.2866,-2.05282 1.3747,-3.31843h-4.69313l3.31843,3.31843Zm-3.31843,-4.11843h4.69323c-0.0882,-1.26565 -0.5915,-2.41692 -1.3747,-3.31854l-3.31853,3.31854Zm2.75283,-3.88424c-0.9016,-0.78327 -2.05285,-1.28664 -3.31852,-1.37483v4.69339l3.31852,-3.31856Z" fill="#000" fillRule="evenodd"></path></svg>

const Container = styled(BaseField)` 
    flex-wrap: wrap;
`
const ColorPicker = ({ 
    value = {hex: defaultValue}, 
    onChange = null,
    advanced = true,
    label = 'Color',
    boxes = false,
    ...other
}) => {
    const paletteColors = useSelector(paletteColorsSelector)
    const simplePalette = useSelector(simplePaletteSelector)
    const colors = useSelector(colorsSelector);


    const [local, setLocal] = useState(value);
    const [manual, setManual] = useState(false);
    
    useEffect(() => {
        let timer = setTimeout(() => {
            if(local?.hex != defaultValue && manual && onChange) {

                console.log(local)
                setManual(false)
            }
        }, 500);
        return () => clearTimeout(timer)
    },[onChange, local, manual])

    useEffect(() => {
       
        if(local?.hex?.hex) setLocal(local.hex.hex)
    },[local])

    const handleChange = (color, direct = false) => {
        const hex = color?.hex ? color.hex : color;
        const colorValue = {
            hex,
            rgb: color?.rgb?.a != 1 ? color.rgb : undefined,
            ...colorByHexSelector(colors, hex)
        }
        
        setManual(true)
        if(direct) onChange(colorValue)
        setLocal(colorValue)
    };

    const styles = {
        input: {
            border: value?.hex ? `1px solid ${value?.hex}` : 'none',
            width: '90px',
            borderRadius: '6px',
            outline: 'none',
            transform: 'scale(0.9)',
            transformOrigin: 'top',
            appearance: 'none',
            marginTop: boxes ? 0 : '4px',
            boxShadow: boxes ? '1px 1px 7.5px '+getRGB(value?.hex, '0.4') : null, 
            background: value?.hex,
            fontSize: '12px',
            color: invertColor(value?.hex, true) 
        },
    };
    const getActiveState = (colorCheck) => {
        return colorCheck && colorCheck.toString() === value?.hex ? 
            {boxShadow: '1px 1px 7.5px '+getRGB(colorCheck, '0.4')} : null;
    }

    const [focussed, onFocus, onBlur] = useBaseField({...other})
    const props = {
        ...other,
        focussed,
        label, 
        value: true,
        onFocus,
        onBlur
    }
    return (
        <Container {...props} className="customPicker" style={{justifyContent: 'space-between'}}>
            
        {boxes && (
            <s.ColorBoxes>
                {paletteColors && paletteColors.map(colorOption => {
                    return (
                        <s.ColorBox 
                            active={toLowCase(colorOption?.value) === toLowCase(local?.hex)}
                            key={colorOption.value}
                            data-title={colorOption.value} 
                            style={{background:colorOption.value, ...getActiveState(colorOption?.value)}} 
                            onClick={() => handleChange(colorOption.value, true)}
                        >
                            {/* {colorOption?.value} */}
                        </s.ColorBox>
                    )
                })}
            </s.ColorBoxes>
        )}

        <div className="-inline">
            {advanced ? (
                <EditableInput
                    style={{ input: styles.input}}
                    className="colorInput"
                    value={value?.hex ? value.hex : defaultValue}
                    onChange={handleChange}
                    disabled={!advanced}
                />
            ):(
                <s.ColorInput 
                    color={local?.hex}
                    hex={hex}
                    className="colorInput"
                    value={value?.hex ? value.hex : defaultValue} 
                    type="text" 
                    disabled
                />
            )}   
        </div>

        <ButtonGroup width={60} my={0} style={{border: 'none'}}>
            {advanced && (
                <ControlPopper 
                    customClass="-large -scaleDown"
                    trigger={(
                        <Button 
                            ghost
                            style={{width: '30px', minWidth: '30px'}}
                            tooltip="Select a custom color"
                            icon={wheel}
                        />
                    )}
                > 
                    <SketchPicker
                        className="backgroundPicker"
                        triangle="hide"
                        color={ local?.rgb ? local.rgb : local?.hex ? local.hex : local }
                        presetColors={!boxes ? simplePalette : []}
        
                        // onChangeComplete={ handleChangeComplete } 
                        onChange={ handleChange } 
                        // onSwatchHover={ hoverSwatch }
                    />
                </ControlPopper>
            )}
            {local && (
                <Button 
                    ghost
                    style={{width: '30px', minWidth: '30px'}}
                    disabled={local?.hex === defaultValue}
                    onClick={() => {
                        setLocal('')
                        onChange(null)
                    }}
                    tooltip="Clear color"
                    icon={trash}
                />
            )}
        </ButtonGroup>

    </Container>
  );
};

export default ColorPicker;
