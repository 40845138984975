import { Button, Select } from "@mosanic/fields";

import { isFunc } from "@util/Funct";
import { get } from "@util/Obj";
import { capitalizeFirst, isString } from "@util/Text";

import { useState } from "react";
import { useDataGrid } from "./useDataGrid";
import { isNumber } from "@util/Num";
import { getRole } from "@mosanic/components/Team/roles";


const useTypeFilter = ({data, entryTypePath, defaultType, filtering}) => {
    const [filter, setFilter] = useState(defaultType)
    
    if(!Array.isArray(data) || !filtering) return []

    //Flat map of possible types
    let options = ['all', ...new Set(data?.map(entry => get(entry, entryTypePath, undefined)))];
    const shouldFilter = Boolean(Array.isArray(options) && options?.length > 2 && filtering);

    const set = (o, cb) => {
        setFilter(o); 
        if(isFunc(cb)) cb();
    }

    if(options?.length >= 1) options = options.map(value => ({
        value,
        label: getRole(value)?.label ? getRole(value).label : capitalizeFirst(value)
    }))

    const Selector = ({cb}) => !shouldFilter ? ({}) => null : ( 
        options?.length > 3 ? (
            <Select 
                container={{border: 'none'}}
                clearable={false}
                minWidth="100px"
                label="Type"
                options={options}
                value={filter}
                onChange={(o) => set(o, cb)}
            />
        ) : (
            options.map(o => (
                <Button 
                    key={o.value} 
                    active={filter === o.value} 
                    onClick={() => set(o.value, cb)} 
                    label={o.label} 
                />
            ))
        )
    )

    
    return [
        filter,
        Selector,
        options
  ]
        
}



/**
 * #### Data receiver 
 * @param {array} hookResponse [data, loading, error, cb] > full response from query hook
 * 
 * @param {string|boolean} entriesPointer path to entries, defaults to false, since a array of entries can be received
 * 
 * #### Formatting
 * @param {array} headerSchema [{name, slug, type}] > for data grid header rendering
 * @param {function} entryFormatter receives entry to parse into header schema slug setup.
 * 
 * #### Filtering
 * @param {boolean} filtering filtering enabled > default = true
 * @param {string} entryTypePath path.to.type > pointer to string_value within a entry for filtering
 * @param {string} defaultType defaultType > default filter + 'all' listing
 */
export const useMosanicDataGrid = ({
    hookResponse, 
    dataHookMethods = null,
    countResponse = undefined,


    props,

    entriesPointer = false,
    entryHookMethods = null,
    useEntriesReceiver = ({}) => [],
    // useGetEntriesPage =  ({}) => [],
    entriesReceiverParams = null,
    entryCount = [null, false, null, () => null],
    

    headerSchema = null,
    entryFormatter = e => e,

    entryTypePath = 'type', 
    defaultType = 'all',
    filtering = true,
    config = {},
    level = 2,
    ...other
}) => {

    //Split hook response
    const [data, loading, error, refreshData] = hookResponse || countResponse;
    const [dataCount, loadingC, errorC, refreshDataC] = entryCount || countResponse;
    const count = (
        isNumber(dataCount) ? dataCount : 
        isNumber(data) ? data : null
    )
    const hasCountPointer =  (
        (isNumber(data) || isNumber(dataCount)) && count >= 1
    );



    //Check if response data has schema in it (for direct data grids)
    const schema = !headerSchema && !Array.isArray(data) && data?.schema ? data.schema : headerSchema

    //Get type and filter selector options
    const [type, Selector, options] = useTypeFilter({data, entryTypePath, defaultType, filtering})

    //Pre define filter function
    const canFilterItems = (type != 'all' && filtering && Array.isArray(data));
    const filterData = dataArray => dataArray?.filter(entry => (
        get(entry, entryTypePath, null) === type || !get(entry, entryTypePath)
    ));

    //Retrieve pointed entries if defined else filter if data is received as an array 
    const filteredItems = (
        hasCountPointer ? undefined :
        entriesPointer ? get(data, entriesPointer) :
        canFilterItems ? filterData(data) : 
        data 
    );

    //Create the data grid
    const { items, limiter, pagination, actions, state, Dialog } = useDataGrid({
        refresh: () => refreshData(),
        items: filteredItems,
        count,
        level
    })

    const hasItems = items?.length >= 1;
    const shouldRetrieveEntries = Boolean(
        entriesPointer &&
        isString(items?.[0]) &&
        isFunc(useEntriesReceiver)
    ) || (!data?.config?.notLinked && !hasItems);

    // console.log({
    //     boolean: (entriesPointer &&
    //     isString(items?.[0]) &&
    //     isFunc(useEntriesReceiver)
    //     ) || (!data?.config?.notLinked && !hasItems),
    //         entriesPointer,
    //         config: data?.config ,
    //         hasItems ,
    //         items
    // })
    //Convert entries receiver to pagination props
    if(hasCountPointer) {
        entriesReceiverParams = {
            perPage: limiter?.limit ? parseFloat(limiter?.limit) : 10,
            page: parseFloat(pagination?.validate?.currentPage),
            ...entriesReceiverParams
        }
    }
 

    // todo ---> change all of these to 'pagination' 



    const [_entries, _loading, _error] = useEntriesReceiver({
        _ids: hasCountPointer ? undefined : (shouldRetrieveEntries && hasItems) ? items?.map(_id => ({_id})) : undefined,
        ...(!items && data?._id && !hasItems && !hasCountPointer ? {collectionId: data._id} : {}),
        ...entriesReceiverParams
    })


    const entries = (shouldRetrieveEntries || hasCountPointer) ? _entries : items

    return { 
        limiter, pagination, actions, state,
        dataHookMethods,
        entryHookMethods,
        data: {
            ...(entriesPointer ? data : {}),
            ...other,
            schema,
            linked: (shouldRetrieveEntries || hasCountPointer),
            entries: Array.isArray(entries) ? entries?.map(entry => entryFormatter(entry)) : data?.entries ? data.entries : [],
        },
        collection: entriesPointer ? data : null,
        status: {
            collection: {loading, error},
            entries: {loading: _loading, error: _error},
            loading: loading || _loading || null,
            error: error || _error || null,
            hasCountPointer
        },

        loading,
        error,
        Selector, type, options,
        Dialog
    };

}

export const useLinkedDataGrid = ({
    hookResponse,
    dataHookMethods = null,

    filtering = false,

    entriesPointer = 'entries',

    entryHookMethods = null,
    entriesReceiverParams,
    useEntriesReceiver,

    entryFormatter  = e => e,
    ...other
}) => useMosanicDataGrid({
    hookResponse,
    dataHookMethods,
    filtering,
    entriesPointer,
    entryHookMethods,
    entriesReceiverParams,
    useEntriesReceiver,
    entryFormatter,
    ...other
})

export const useDirectDataGrid = ({
    hookResponse,

    filtering = true,
    entryTypePath,
    defaultType,

    headerSchema,
    entryFormatter  = e => e,
    ...props
}) => useMosanicDataGrid({
    hookResponse,
    filtering,
    entryTypePath,
    defaultType,
    headerSchema,
    entryFormatter,
    ...props
})

export const usePaginateDataGrid = ({
    countResponse,

    entriesReceiverParams,
    useEntriesReceiver,

    filtering = true,
    entryTypePath,
    defaultType,

    headerSchema,
    entryFormatter  = e => e,
}) => useMosanicDataGrid({
    countResponse,
    entriesReceiverParams,
    useEntriesReceiver,
    filtering,
    entryTypePath,
    defaultType,
    headerSchema,
    entryFormatter
})
