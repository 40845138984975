
import { setInspectBoundsAction } from '@redux/bounds/action';
import { clearPreStoreAction } from '@redux/map/drop/action';
import { getAutoOpenInspect } from '@redux/options/reducer';
import { openInspectorPanel } from '@redux/ui/uiAction';
import { isInspectorOpen } from '@redux/ui/uiReducer';
import { includesAction } from '@redux/util';
import { default as actions, inspectActions } from './action';
import { isInspectedSelector } from './reducer';


export const shouldOpenInspectSidebar = state => getAutoOpenInspect(state) && !isInspectorOpen(state)

export const inspectActionFlow = ({getState, dispatch}) => next => action => {
    next(action);

    if(includesAction(action, actions.INSPECT_SET)){ 
        const state = getState();
        const payload = action.payload;
        
        
        //Clear
        if(includesAction(action, '(empty)')) dispatch(clearPreStoreAction())
 
        //Init validated
        dispatch(inspectActions.initInspectAction({...payload}, true))
        dispatch(setInspectBoundsAction({...payload}))

        //Open sidebar
        if(shouldOpenInspectSidebar(state)) dispatch(openInspectorPanel())

        //Already inspected
        if(isInspectedSelector(state, payload.component._id)) return;
    }
  
 
    
   
};

export const inspectMiddleware = [
    inspectActionFlow
];