export const PATH_SEPARATOR = '-';

const hasPaths = (p1, p2) => p1 && p2
//Main
export const splitPath = (path) => path?.split(PATH_SEPARATOR);
export const extendPath = (path, index) => `${path}${PATH_SEPARATOR}${index}`

//Convert
export const pathToItem = (splitPath) => joinDropZonePath(splitPath.slice(0, -1))
export const joinDropZonePath = (splitPath) => splitPath.join(PATH_SEPARATOR);
 
//Get
export const getItemIndex = (splitPath) => Number(splitPath.slice(-1)[0]);


//Validate
export const isDiffRow = (activePath, itemPath) => (
    !hasPaths(activePath, itemPath) ? true : 
        activePath[0] != itemPath[0]
);
export const isDiffCol = (activePath, itemPath) => (
    !hasPaths(activePath, itemPath) ? true : 
        activePath[1] != itemPath[1]
);

export const isSameLevel = (activePath, itemPath) => (
    !hasPaths(activePath, itemPath) ? true : 
        activePath.length === itemPath?.length
);

export const isDiffParent = (activePath, itemPath) => (
    !hasPaths(activePath, itemPath) ? true : 
        activePath[activePath?.length - 1] != itemPath[itemPath?.length - 1]
);



