import styled, { css } from "styled-components";
import { default as btns } from "./button.styles";
import colors from "./colors";
import {layout, space} from 'styled-system';

const getSize = (size) => {
    switch (size) {
        case 'large': return css`
            margin: 0 0 10px;
            
            ${btns.Button} {
                padding: 10px;
                max-height: 40px;
                min-height: 40px;
                min-width: 48px;
                max-width: 48px;
            }
        `;

        case 'xs': return css`
            margin: 0 0 5px;
            
            ${btns.Button} {
                padding: 2.5px;
                max-height: 28px;
                min-height: 28px;
                min-width: 38px;
                max-width: 48px;
            }
        `;
    
        //Small
        default: return css`
            margin: 0 0 8px;

            ${btns.Button} {
                padding: 5px;
                max-height: 32px;
                min-height: 32px;
                 min-width: 48px;
                max-width: 48px;
                /* min-width: 38px; */
                /* max-width: 38px; */
            }
        `;
    }
}

const Radius = '4px';

const ButtonGroup = styled.div` 
    ${props => getSize(props?.size)}
    border: 1px solid ${colors.util.gray.light};
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
    border-radius: ${Radius};
    width: fit-content;

    ${props => props.isText && css` 
        width: 100%;
        align-items: stretch;
        ${btns.Button} {
            max-width: none;
            width: 100%;
        }
    `}
    ${btns.Button},
    span,
    a {
        margin: 0;
        border: none;
    }
    span, a {
        width: 100%;
    }

    ${props => props.active && css`
        border-color: ${colors.util.primary.base};
    `}

    svg {
        transition: all ease-in-out 0.3s;
    }
    
    &.-rotate {
        svg {
            transform: rotate(-90deg);
        }
    }

    ${props => props.right && css` 
        margin-top: -5px;
        float: right;
        margin-left: auto;
        width: fit-content;
    `}
    ${props => props.small && css` 
        transform: scale(0.8);
    `}


    ${layout}
    ${space}
`

const ButtonGroupStyles = {
    ButtonGroup,
}
export default ButtonGroupStyles;