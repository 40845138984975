import RenderPage, { Navigation, usePage, usePageInit } from "@mosanic/components/Pages";
import { getIndexPageId } from "@mosanic/utils/slug";

import { FooterProvider, HeaderProvider, ModeProvider, PageProvider, RelationProvider } from "@mosanic/core/Provider";

import { useGetSite } from "@mosanic/cms/Query/byId";
import { siteIdSelector } from "@redux/auth/reducer";
import { pageMetaAttrSelector } from "@redux/pages/reducer";
import { useSelector } from 'react-redux';
import { useCollection } from "../Collections";
import RenderTheme from "../Theme";
import { Condition } from "@JSX";
import styled from "styled-components";
import { CenterCenter, colors } from "@mosanic/styles";

export const FullRelationRender = ({render, relation, entries, bp = "auto"}) => {
    const [getCol, [collection]] = useCollection({_id: relation}, true);
    const [getPages, [page]] = usePage({_id: render});

    const getEntry = id => collection?.entries?.find(e => e?._id === id);

    // relationEnd --> rooms/ beds
    // relationRender --> page 
    // relation is type
    return (
        <>
        {/* {JSON.stringify({entries})} */}
        {/* {JSON.stringify(getEntry(entries[0]))} */}
        {!Array.isArray(entries) ? (
            <RelationRender page={page} entry={getEntry(entries?.value)} bp={bp} /> 
        ) : entries?.map(r => (
            <RelationRender page={page} entry={getEntry(r?.value ? r?.value : r)} bp={bp} />
        ))}
        </>
    )
}
 
const RelationRender = ({page, entry, bp}) => (
    <>
    {/* {JSON.stringify(entry?.Map?.styles[1])} */}
    
    <RelationProvider data={{entry, entries: [entry]}}>
        {/* <pre>
        {JSON.stringify(entry, null, 2)}
        </pre> */}
        <RenderPage page={page ? page : null} production noWrap blockSeo bp={bp} />
    </RelationProvider>
    </>
) 
 

const MainRender = ({page, entry, bp = "auto"}) => (
  <PageProvider entry={entry}>
    <RenderPage page={page ? page : null} production blockSeo bp={bp} />
  </PageProvider>
) 

const InitiateBlock = styled.div` 
    border:  1px solid transparent;
    transition: all ease-in-out 0.3s;
    > ::after {
        background: rgba(255,255,255, 0.5);
        backdrop-filter: blur(2px);
        ${CenterCenter}
        position: absolute;
        top: 0;
        right:0;
        left: 0;
        bottom: 0;
        content: ${props => `'${props.tooltip}'`};
        height: 100%;
        width: 100%;
        z-index: 9999;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        font-size: 12px;
    }
    &:hover {
        border-color: ${colors.util.primary.base};
        cursor: pointer;
        > ::after {
            opacity: 1;
        }
    }
`
const HeaderRender = ({header, site, locale = null, seo = null, bp = "auto", initiate}) => (
  <HeaderProvider nav={site?.nav} locales={site?.locales}>
    <Condition when={initiate} wrapper={children => (
        <InitiateBlock onClick={() => initiate()} tooltip="Edit header">
            {children}
        </InitiateBlock>
    )}>
        {header ? (
        <RenderPage page={header} production locale={locale} seo={seo} bp={bp} />
        ): (
        <Navigation nav={site?.nav} url={`/logo/${site?._id}.svg`} translated={site?.locales}/>
        )}
    </Condition>
  </HeaderProvider>
) 
const FooterRender = ({footer, site, bp = "auto", initiate}) => (
  <FooterProvider nav={site?.nav} locales={site?.locales}>

    <Condition when={initiate} wrapper={children => (
        <InitiateBlock onClick={() => initiate()} tooltip="Edit footer">
            {children}
        </InitiateBlock>
    )}>
        <RenderPage page={footer} production blockSeo bp={bp} />
    </Condition>

    <RenderTheme themeId={site?.themeId}/>
  </FooterProvider>
) 

export const SwitchPageRender = ({page, site, entry = null}) => (
    page?.type === 'header' ? (
        <HeaderRender 
            header={page}
            site={site}
            seo={null}
        />
    ) : page?.type === 'footer' ? (
        <FooterRender 
            footer={page}
            site={site}
        />
    ) : (
        <MainRender 
            page={page}
            entry={entry}
        />
    )
)
export const useDynamicPage = ({site}) => {
    const {header, footer} = site || {header: null, footer: null};
    const home = getIndexPageId(site, 'home');

    const [getHeader, [headerPage]] = usePage({_id: header})
    const [getFooter, [footerPage]] = usePage({_id: footer})
    const [getHome, [homePage]] = usePage({_id: home})

    const pages = {headerPage, footerPage, homePage}
    return pages
}
//Just pass the page or get the home page
export const ClientPageRender = ({site, page = null, bp = "auto"}) => {
    const pages = useDynamicPage({site});
    return (
        <PageRender 
            bp={bp}
            page={page ? page : pages?.homePage}
            site={site}
            headerPage={pages?.headerPage}
            footerPage={pages?.footerPage}
        />
    )
}

export const RenderHeaderFooter = ({children, bp, isDev = false}) => {
    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})
    const type = useSelector(state => pageMetaAttrSelector(state, 'type'));
    const initiate = usePageInit({})

    const pages = useDynamicPage({site})
    if(type != 'page') return children;
    return (
        <ModeProvider data={{isDev}}>
            <PageRender 
                bp={bp}
                page={null}
                site={site}
                headerPage={pages?.headerPage}
                footerPage={pages?.footerPage}
                initiate={(page) => initiate(page)}
            >   
                {children}
            </PageRender>
        </ModeProvider>
    )
}
//Full custom page render
export const PageRender = ({page, site, headerPage, footerPage, entry = null, locale = null, seo = null, bp = 'auto', initiate, children}) => (
    <>
        <HeaderRender 
            bp={bp}
            header={headerPage}
            site={site}
            seo={seo}
            locale={locale}
            initiate={initiate ? () => initiate(headerPage) : null}
        />
        {children ? children : (
            <MainRender 
                bp={bp}
                page={page}
                entry={entry}
            />
        )}
        <FooterRender 
            bp={bp}
            footer={footerPage}
            site={site}
            initiate={initiate ? () => initiate(footerPage) : null}
        />
    </>
)
