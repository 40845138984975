import { getImageUrl } from "@api/host";
import { matchSubstrList } from "@util/Text";

import VideoField from "./VideoFile";
export default VideoField;

export const isVideo = url => !url ? false : matchSubstrList(url, ['.mp4', '.mov']);

export const Video = ({url, ...other}) => isVideo(url) ? (
    <video 
        src={getImageUrl(url)}
        {...other}
        alt='Video'
        autoPlay 
        muted
        controls
    />
) : JSON.stringify(url)