import { useEffect, useState } from 'react';

import { addEntry, createNewEntry, createUpdateEntry } from '@mosanic/utils/collections';
import { userIdSelector } from '@redux/auth/reducer';
import { useSelector } from 'react-redux';

import { useCreate } from '@mosanic/cms';
import { useUpdate } from '@mosanic/cms/Mutate/useDataMutate';
import { useSiteId } from '@mosanic/cms/Query';
import { convertEntryBack } from '@mosanic/cms/Query/useDataQuery';
import { AdvancedLabel, Spinner } from '@mosanic/items';
import { getFields } from '@mosanic/utils/collection';
import { replaceEntryPageProps, setDefaultEntryValues } from '@mosanic/utils/entry';
import { EntryFields } from './EntryFields';
import { useEntryMails } from './EntryMails';
import { useEntryTriggers } from './EntryTriggers';

export const Entry = ({
	collection, 
	initial = null, 
	type = 'create', 
	hideDisabled = false, 
	callback = null, 
	
	hideMeta = false, 
	preview = false, 
	
	dataType = 'collection', 
	submitTrigger = null, 
	
	cols = 2,
	label = null,
	border = false,
	
	handler, 
	replaceRouter = false, 
	disabled = false,
	page_props = null,

}) => {
	const [state, setState] = useState({
		step: type,
		data: null
	})

	const userId = useSelector(userIdSelector)
	const isCreate = Boolean(type === 'create');

	//Schema fields
	const schema = collection?.schema;
	const schemaButtons = getFields(schema, 'button');
	const schemaUsers = getFields(schema, 'user');

	const loggedInCreation = schemaUsers?.filter(u => (u?.userId || u?.isAuthor))


	//Update handling
	const [createEntry, [c_data, c_loading, c_error]] = useCreate({callback})
	const [updateEntry, [c_update_data, c_update_loading, c_update_error]] = useUpdate({callback})

	const [action, [data, loading, error]] = handler ? handler({callback, collection}) : [() => null, [null, false, null]]
	const siteId = useSiteId();

	useEffect(() => {
		if(c_data?.recordId) {
			setState(state => ({...state, step: 'continue', mail: {
				...state.mail,
				entry : {
					...state?.mail?.entry,
					slug: state?.mail?.entry?.slug ? state?.mail?.entry?.slug :  c_data?.recordId,
					_id: c_data?.recordId
				}
			}}))
		} else {

		}
	}, [c_data])

	const onSubmit = (data) => {
		if(handler) {
			action({data:{
				...data,
				userId: data?.userId ? data.userId : userId,
				siteId,
				short_description: undefined
			}})
		} else if(type === 'create') {
			schemaUsers?.map(u => {
				if(u?.userId || u?.isAuthor) {
					data = {...data, [u.name]: userId}
				}
			})
			//Convert data
			data = replaceEntryPageProps({entry: data, schema, pageProps: page_props});
			data = setDefaultEntryValues({entry: data, schema});


			//Validate triggers
			const creation = schemaButtons?.find(b => b.action === 'create')
			const hasUserTriggers = (creation?.enableUser && creation?.trigger?.users?.length >= 1)
			const hasMailTriggers = (creation?.enableMail && creation?.mailId && creation?.mailTemplate)
			createEntry({data: {
				...convertEntryBack(data, schema),
				date: new Date(),
				collectionId: collection?._id,
				siteId: collection?.siteId ? collection?.siteId : siteId,
				userId: userId ? userId : '999',

				//Set slug by id ...
			}})

			setState({
				...state, 
				data,
				hasUserTriggers,
				hasMailTriggers,
				step: 'loading',
				trigger: !hasUserTriggers ? null : (
					creation.trigger.users
					.filter(t => t?.code && t?.userId)
					.map(trigger => ({
						userId: data?.[trigger.userId],
						code: trigger.code
					}))
				),
				mail: !hasMailTriggers ? null : {
					mailId: creation.mailId,
					template: creation.mailTemplate,
					entry: convertEntryBack(data, schema)
				}

			})
		}



		if(collection?.linked && false) {


			if(isCreate) {
				createEntry({data: convertEntryBack(entry, collection?.schema)})
			} else {
				console.log({record: convertEntryBack(entry, collection?.schema), _id: initial?._id})
				updateEntry({data: {
					record: {
						...convertEntryBack(entry, collection?.schema),	
						_id: undefined,
					}, 
					_id: initial?._id
				}})
			}

			return
		}
 
		return ;
		if(isCreate){
			entry = createNewEntry(entry, userId);
			data = addEntry(collection, entry);
		} else {
			entry = createUpdateEntry(entry);
			data = updateEntry(collection, entry);
		}

	};


	const canRenderEntry = Boolean( 
		loggedInCreation?.length === 0 || (loggedInCreation?.length >= 1 && userId) || disabled 
	)

	const triggers = useEntryTriggers({
		state, 
		setState, 
		trigger: state?.trigger,  
		collection, 
		entry: state?.data
	})
	const mails = useEntryMails({
		state, 
		setState, 
		mail: state?.mail,  
		collection, 
	})


	useEffect(() => {
		if(triggers && mails) {
			setState(state => ({...state, checks: [triggers, mails]}))
		}
	},[mails, triggers])

// 		{JSON.stringify(parsedMail)}
// <br/><br/><br/><br/>
// <pre>
// 		{JSON.stringify(parsed, undefined, 4)}
	return canRenderEntry ? (
		<>
			{c_loading}
			{c_error}
			{loading}
			{error}
			
			
			{/* {JSON.stringify(page_props)}
			{JSON.stringify(schemaUsers?.filter(u => u?.isAuthor))} */}
			
			{['create', 'update'].includes(state.step) ? (
				<EntryFields 
					collection={collection}
					initial={initial}
					onSubmit={onSubmit}
					schema={schema}
					loading={loading}

					disabled={disabled}
					formDisabled={loading || type === 'preview'}
					hideDisabled={hideDisabled}
					inputGroup={{label, border, cols}}
					preview={preview}

					schemaButtons={schemaButtons}
					replaceRouter={replaceRouter}
					smallSubmit={(dataType === 'global' || type === 'preview')}
					submitTrigger={submitTrigger}
					hideMeta={hideMeta}
					type={type}
					dataType={dataType}
				/>
			): (
				((state?.trigger && !state?.updated) ||
				(state?.mail && !state?.send)) && (state?.hasMailTriggers || state?.hasUserTriggers)
			) ? (
				<Spinner />
			) : (
				<div style={{textAlign: 'center', width: '100%'}}>
					{submitTrigger ? <AdvancedLabel mt={4} as="h6" label='Submission is successfully send' /> : (
						<AdvancedLabel as="h4" label={`${type === 'create' ? 'Created' : 'Updated'} ${collection?.singular}`} />
					)}
					{(state?.hasMailTriggers || state?.hasUserTriggers) && state?.checks?.map(point => (
						<AdvancedLabel as="p" style={{position: 'relative'}}>
							{point}
						</AdvancedLabel>
					))}
					
				</div>
			)} 
			

		</>

	) : (
		<>

		</>
	);
};
