import { colors } from "@mosanic/styles";
import styled, {css} from "styled-components";

const Container = styled.div` 
	position: relative;
	margin: 25px auto;
	padding: 50px 0;
`

const Loader = styled.div` 
	top: 20px;
	left: calc(50% - 20px);
	position: absolute;
	width: 40px;
	height: 40px;
`
const BounceOne = styled.div` 
	@keyframes bounce {
		0%, 100% { 
			transform: scale(0.0);
			-webkit-transform: scale(0.0);
		} 50% { 
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		}
	}
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: ${colors.util.primary.base};
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	
	-webkit-animation: bounce 2.0s infinite ease-in-out;
	animation: bounce 2.0s infinite ease-in-out;
`

const BounceTwo = styled(BounceOne)` 
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
`






export const Spinner = () => {
	return(
		<Container className="spinner-container">
			<Loader className="spinner">
				<BounceOne className="bounce1"/>
				<BounceTwo className="bounce2"/>
			</Loader>
		</Container>
	);
}; 