import { useGetSite } from "@mosanic/cms/Query/byId";
import { siteIdSelector } from "@redux/auth/reducer";
import { colorSelector } from "@redux/colors/reducer";
import { baseFontSizeSelector, fontSourcesSelector, fontsSelector } from "@redux/typography/reducer";
import { containsSubstr, oneLineString, replacePart } from "@util/Text";
import { useSelector } from 'react-redux';
// import { useFontSources } from "./useFontSources";

const selectors = `:is(.mosanic_preview, .mosanic_site)`
const children = `:is(span, b, u, strong, i, a, ul, li)`;

const getType = (type) => {
    const base = `${selectors} ${type}`;

    if('h1|h2|h3|h4|h5|h6'.split('|').includes(type)) {
        return `${base} > ${children}, ${base}`
    }
    if(type === 'p'){
        return `${selectors} div > ${children}, ${base} > ${children}, ${base}`
    }
    return `${selectors} ${type}, ${selectors} ${type} > span`
    // if(type != button) 
}
const FontTypes = (fonts, primary) => fonts?.length >= 1 ? fonts.map(item => oneLineString(` 
    /** ${item.type} **/    
    ${selectors} ${item.type} {
        font-weight: ${
            item?.bold && !item?.weight ? 'bold' : 
            item?.style === 'regular' ? 400 :
            item?.weight 
        };
    }
    ${getType(item.type)} {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: "${item.font}";
        font-size: ${item.size+""+item.metric};
        font-weight: ${
            item?.bold && !item?.weight ? 'bold' : 
            item?.style === 'regular' ? 400 :
            item?.weight 
        };
        font-style: ${item.italic ? 'italic' : 'normal'};
        ${item.underline ? 'text-decoration: underline' : ''};
        ${item.type === 'a' && primary?.value && `color: ${primary?.value};`}
    }
`)) : null;


export const FontFace = ({family, weight, style, src}) => `
    @font-face {
        font-family: "${family}";
        src: url('${replacePart(src, 'http', 'https')}') format('truetype');
        font-weight: ${style === 'regular' ? 400 : weight};
        font-style: ${style};
    }
`

export const FontSources = ({dynamic = false}) => {
    // const site.themeId 
    const siteId = useSelector(siteIdSelector)
    const [site] = useGetSite({_id: siteId})


    const sources = useSelector(fontSourcesSelector);
    const fonts = useSelector(fontsSelector);
    const primary = useSelector(state => colorSelector(state, 'main', '40'))
    const baseSize = useSelector(baseFontSizeSelector);
    const data ={fonts, sources, baseSize, primary};
    


    // useEffect(() => {
    //     let timer = null;
    //     if(dynamic && theme) timer = setTimeout(() => handles.setTheme(theme), 500)
    //     return () => clearTimeout(timer)
    // },[dynamic, theme, handles])

    return <RenderFonts {...data}/>
};

export const RenderFonts = ({fonts, baseSize = '16px', primary, sources}) => (
<style type="text/css">
{`
html ${selectors} {
    font-size: ${baseSize}${containsSubstr(baseSize, 'px') ? '' : 'px'};
    line-height: ${(parseFloat(baseSize) / 10) - 0.1};
}
`}
{sources?.length >= 1 ? sources.map((source, index) => (

<FontFace {...source} key={index} />

)) : null}

{FontTypes(fonts, primary)}        
</style>)
