// import {setDesignState, copyDesignState, initDesignState} from './initializeDesign'



//TODO  RENAME PARENT TO: DATA





import { setDesignState, copyDesignState, initDesignState } from "./initializeDesign";

import { 
  getEmptyChildDisplayState,
  getEmptyVisibilityState, 
  getGridDefaults, 

  setLayoutState
} from "./initializeLayout";
import { capitalizeFirst, toLowCase } from '@util/Text';
import { getMosanicFields, getMosanicTemplate } from "@mosanic/_Schemas";
import { toClassName } from "@mosanic/utils";
import { templates } from "@mosanic/_templates";

export { 
  setDesignState, copyDesignState, initDesignState,
};
 
const baseData = {
  reference: null,
  compRef: null,
  isMain: false,
  isComp: false,
  isCloned: false,
  isLinked: false,
  repeating: false,
}
export const initialize = ({item, setup = null}) => {
  const className = toClassName(item.itemType, item?.type, item._id);
  // const parsedItem = parseSideBarItem(item);/
  // const data = parseItemData(item, setup)
  // const design = checkMosanicDesignTemplates(item)
  return {
    ...checkMosanicTemplates(item),
    className,
    options: setup ? setup : undefined
  }

}
const parseItemData = (sideBarItem, setup) => setup ? 
    {...baseData, ...sideBarItem.data, ...setup} : 
    {...baseData, ...sideBarItem.data};

// const parseItemContent = (content) => content.replace(/<([a-z][a-z0-9]*)[^>]*?(\/?)>/si, "");

const parseSideBarItem = (sideBarItem) => Object.keys(sideBarItem).length >= 3 ?({
  _id: sideBarItem._id,
  type: sideBarItem.type, 
  title: sideBarItem.title,
  icon: sideBarItem.icon,
  desc: sideBarItem.desc,
}) : ({
  _id: sideBarItem._id,
  type: sideBarItem.type, 
  title: capitalizeFirst(sideBarItem.type),
});

const checkMosanicDesignTemplates = (sideBarItem) => {
  return {default: {}};
}
const checkMosanicTemplates = (item) => {
  const type = toLowCase(item.type);
  const template = item?.template ? 
    templates[item.template] :
    getMosanicTemplate(type);

  const fields = getMosanicFields(type);
  return template || fields ? {
    ...item,
    fields: {
      ...item.fields,
      ...fields,
    },
    data: { 
      ...item.data,
      ...template
    }
  } : item;
}


// export const initializeNewComponent = (element, isMain=false) => {
//   initializeElement(element);
//   return element;
// }


// export const initializeNewRow = (newRowId, isMain=false, isCloned=false) => {
//   let newRow = { 
//     _id: newRowId, 
//     type: "ROW", 
//     title: "Section",
//     // container: true,
//     layout: {},
//     layouts: {},
//     design: {},
//     repeating: {
// 			collection: 'abcd'
// 		},
//     data: {
// 			reference: 'abcd',
//       isMain: isMain,
//       isCloned: isCloned,
//       isLinked: false,
// 			repeating: true,
// 		},
//   };
//   newRow = initializeElement(newRow, 'default', false);

//   if(newRow?.layout?.display) newRow.layout.display = undefined;

//   return newRow;
// };

// export const initializeNewStack = ({id, state}) => {
//   const stackId = id;
//   const {isMain, isCloned, isLinked} = state;

//   let newStack = {
//     _id: stackId, 
//     type: "STACK", 
//     title: "Stack",
//     layout: {
//       // grid: {
//       //   display:    {xs: true, sm: true, md: true, lg: true, xl: true},
//       // },
//     },
//     layouts: {},
//     design: {},
//     isLocked: false,

//     data: {
// 			reference: 'abcd',
//       isMain: isMain,
//       isCloned: isCloned,
//       isLinked: false,
// 			repeating: true,
// 		},

//   };
//   // newColumn = setColumnDisplay(newColumn);
//   newStack = initializeElement(newStack, 'default', false);
//   return newStack;
// };
// export const initializeNewRepeater = ({id, reference}) => {
//   const repeaterId = id;
//   const mainChild = reference;

//   let newRepeater = {
//     _id: repeaterId, 
//     type: "REPEATER", 
//     title: "Repeater",
//     layout: {},
//     layouts: {},
//     design: {},
//     isLocked: false,
//     layout: {
//     },
//     collection: {
//       source: null, //news
//       filter: '',   //obj? graphql check??
//       sort: '',     //  ""
//       pagination: '',
//     },
//     data: {
// 			mainRef: reference?._id,
//       childType: mainChild.type,
//       clones: [],
// 		},

//   };
//   // newRepeater = setColumnDisplay(newRepeater);
//   newRepeater = initializeElement(newRepeater, 'default', false);
//   return newRepeater;
// };
// export const initializeNewCol = ({id, childType, state}) => {
//   const newColumnId = id;
//   const newComponent = childType
//   const {isMain, isCloned, isLinked} = state;

//   let newColumn = {
//     _id: newColumnId, 
//     type: "COLUMN", 
//     title: "Column",
//     layout: {
//       grid: {
//         responsive: getGridDefaults(newComponent),
//         order:     {xs: null, sm: null, md: null, lg: null, xl: null},
//         offset:     {xs: null, sm: null, md: null, lg: null, xl: null},
//         display:    {xs: true, sm: true, md: true, lg: true, xl: true},
//       },
//     },
//     layouts: {},
//     design: {},
//     isLocked: false,

//     data: {
// 			mainRef: null,
//       isMain: isMain,
//       isCloned: isCloned,
//       isLinked: false,
// 			repeating: false,
// 		},

//   };
//   // newColumn = setColumnDisplay(newColumn);
//   newColumn = initializeElement(newColumn, 'default', false);
//   return newColumn;
// };

export const setDefaultLayout = (element) => {
    element = setVisibility(element);
    // element = setDisplay(element);
    if(element.type === 'row' || element.type === "ROW"){
      element.layout.container = {
        state: {xs: true, sm: true, md: true, lg: true, xl: true}
      }
    }

    return element;
}
// element = setDisplay(element);
export const setDisplay = (element) => {
  if(!element?.layout?.display?.position){
    element.layout.display = getEmptyChildDisplayState();
  }
  return element;
}
export const setVisibility = (element) => {
  if(element?.layout && element?.layout?.visibility === undefined){
    element.layout.visibility = getEmptyVisibilityState();
  }
  return element;
}

export const setColumnDisplay = (element) => {
  if(!element?.layout?.display){
    element.layout.display = {
      displayType: 'auto',
      position: 'relative',
      flex: {
        direction: 'row',
        align: 'start',
        justify: 'start',
        reverse: false,
        wrap: false,
      }
    };

  }
  return element;
}




