import { clearSession } from "@api/token";


const ID = '[auth]';

const STORE =  `${ID} Store`;

const ACTIVATE =  `${ID} Activate`;
const REMOVE =  `${ID} Remove`;

const SET_USER_ID =  `${ID} Set userId`;
const SET_SITE_ID =  `${ID} Set siteId`;


const REVALIDATE = `${ID} Revalidate`;
const ROUTER_PUSH = `${ID} Router push`;

const IDS = {
    STORE,
    ACTIVATE,
    REMOVE,

    SET_USER_ID,
    SET_SITE_ID,

    REVALIDATE,
    ROUTER_PUSH
}
export default IDS;



export const authRevalidate = (source = ID) => ({
    type: REVALIDATE,
    meta: {feature: ID, source}
})
export const routerPushAction = ({pathname, query}) => ({
    type: ROUTER_PUSH,
    payload: {pathname, query},
    meta: {feature: ID}
});

export const activateAuthAction = ({token, redirect, remember}) => ({
    type: ACTIVATE,
    payload: {token, redirect, remember},
    meta: {feature: ID}
})

export const storeAuthAction = ({token, redirect, remember, userData}, handled = false) => ({
    type: handled ? STORE : `${STORE} handle`,
    payload: {token, redirect, remember, userData},
    meta: {feature: ID}
});



export const removeAuthAction = ({redirect = '/login'}, src = 'No source', handled = false) => {
    clearSession();   
    return {
        type: handled ? REMOVE : `${REMOVE} handle`,
        payload: {redirect},
        meta: {feature: ID, src}
    }
}

export const setSiteId = ({siteId}, handled = false) => ({
    type:  handled ? SET_SITE_ID : `${SET_SITE_ID} handle`,
    payload: siteId,
    meta: {feature: ID}
})

export const setUserId = ({userId}) => ({
    type: SET_USER_ID,
    payload: userId,
    meta: {feature: ID}
})