import { gql } from '@apollo/client';
// const gql = dynamic(() => import("graphql-tag"))
import dynamic from 'next/dynamic';
import { BUILDER_ITEM_CORE, COMPONENT_FIELDS } from '../fragments/layouts';

export const BUILDER_ITEMS = gql`
query builderItemMany{
  builderItemMany {
    ${BUILDER_ITEM_CORE}
    component 
    map
    data
    siteId
    date
    updatedAt
    updates
  }
}
`;
