import { containsSubstr } from '@util/Text';
import {default as ScriptTag} from 'next/script'

const Script = ({fields, ref, editor, active = false}) => {
    const {source, handler} = editor;

    return containsSubstr(source?.url, "fonts.googleapis") ? (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link href={source?.url} rel="stylesheet" /> 
        </>
    ) : (
        <ScriptTag 
            src={source?.url ? source.url : undefined}
            dangerouslySetInnerHTML={{
                __html: source?.dangerCode,
            }}
            strategy={source?.strategy ? 
                source.strategy : 
                'afterInteractive'
            }
            onLoad={source?.onLoadCode ? 
                source.onLoadCode : 
                undefined
            }
            onReady={source?.onReadyCode ? 
                source.onReadyCode : 
                undefined
            }
            onError={(e) => source?.onErrorFunc ? 
                source.onErrorFunc(e) : 
                undefined
            }
            {...source?.dataAttrObject}
        >
            {source?.inlineScriptCode}
        </ScriptTag>
    ); 
}
export default Script;