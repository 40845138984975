import { getImageUrl } from '@api/host';
import { isImage } from '@mosanic/utils/Image';
import { containsSubstr, matchSubstrList } from '@util/Text';
import { default as s } from './Media.styles';

import { AdvancedLabel } from '@mosanic/items';
import { Skeleton } from '@mosanic/items/Skeletons';
import { loop } from '@util/Arr';

export const MediaItem = ({size, setPreview, image, isActive}) => (
    <s.MediaItem 
        size={size}
        active={isActive}
        className={`MediaGallery__item`}
        style={{
            backgroundImage: `url('${getImageUrl((!containsSubstr(image?.urlThumb, '.svg') && image?.urlThumb ) ? image.urlThumb : image.url)}')` 
        }}
        onClick={() => setPreview(image)}
    >

        {!isImage(image?.urlThumb ? image.urlThumb : image.url) && (
            <video 
                src={getImageUrl(image?.url)}
                alt={'Video'} 
                loop
                controls
                muted 
                autoPlay
            />
        )}
    </s.MediaItem>
)

export const NoMediaItems = ({size}) => (
    <>
    {loop(3, (
        <s.MediaItem size={size} isLoading>
            <Skeleton />
        </s.MediaItem>
    ))}

    <AdvancedLabel ml={2}>No assets found.</AdvancedLabel>
    </>
)