import { containsSubstr } from "@util/Text";
import { hex2hsl, rgb2Hex } from "./Convert";

export const invertColor = (initialHex, bw) => {
    let hex = initialHex || '#FFF';
    hex = hex.toString()

    if(containsSubstr(hex, 'rgb')){
        const split = hex.split(',')
        hex = rgb2Hex(split[0], split[1], split[2])
    }

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        // throw new Error('Invalid HEX color.');
        return initialHex;
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return rgb2Hex(r, g, b)
    // return "#" + padZero(r) + padZero(g) + padZero(b);
}


export const getRGB = (hex, alpha) => {
    if(!hex) return;
    hex = hex.toString()
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}



export const getSaturation = (color) => {
    return hex2hsl(color).l
}