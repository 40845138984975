import isBoolean from '@util/Boolean';
import { getNumberPartOfString } from '@util/Text';

import { AdvancedLabel } from '@mosanic/items';

import { colors } from '@mosanic/styles';
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import styled, { css } from 'styled-components';

const CheckList = styled.div` 
    background: #ffffff;
    position: absolute;
    right: -250px;
    margin-top: -50px;
    width: 250px;
    padding: 10px;
    ${props => props.center && css` 
        text-align: center;
        position: relative;
        margin: 0;
        right: 0;
        width: 100%;

        ul li {
            text-align: center;
            justify-content: center;
            margin: 0;

            div {
                margin: 0;
            }
        }
    `}
    ul {
        list-style: none;
        padding: 0;
    }
`
const ListItem = styled.li` 
    padding: 5px;
    font-size: 14px;
    display: flex;
    ${props => props.valid && css` 
        color: #999;
    `}
`
const Label = styled.span` 
    background: ${props => colors.util.bright?.[props.color]};
    border-radius: 4px;
    color: #fff;
    padding: 2px 4px;
    margin-left: 4px;

`
const Status = styled.div` 
    max-height: 20px;
    max-width: 20px;    
    min-height: 20px;
    min-width: 20px; 
    display: inline-block;
    margin-right: 10px;
    padding: 0 2.5px;
    overflow: hidden;
    border-radius: 15px;
    background: ${props => props.valid ? colors.util.bright.green : colors.util.bright.red};
    svg {
        margin-top: -4px;
        path {
            fill: #fff;
        }
    }
`

const list = (txt) => ({
    match: 'Passwords match',
    length: `At least 8 characters (${txt?.length})`,
    lower: 'Lower case letters (a-z)',
    higher: 'Upper case letters (A-Z)',
    number: 'Contains a number (0-9)',
    special: 'A special character (!@#$%&*)'
})

export const usePasswordValidation = ({password = '', repeat_password = '', firstOnly = false}) => {

    const numbers = getNumberPartOfString(password)?.length;

    const specials = password?.length - password?.replace(/[^a-zA-Z\']/g, "")?.length
    const validations = {
        match: password != repeat_password ? 'Passwords do not match.' : true,
        length: password?.length < 8  ? 'Password must be longer than 8 characters.' : true,
        lower: ! new RegExp(`[a-z]`).test(password) ? 'Password should contain one lower case letter' : true,
        higher: ! new RegExp(`[A-Z]`).test(password) ? 'Password should contain one upper case letter' : true,
        number: (!numbers || numbers?.length < 1)  ? 'Password should contain a number.' : true,
        special: ! new RegExp(`[!@#$%--&*]`).test(password) ? 'Password should contain a special character.' : true
    }
    const errorMessage = (!repeat_password && !password) ? null : Object.values(validations)?.find(val => !isBoolean(val) && val)

    const color = {
        'Not good enough': 'red',
        'Good +': 'green',
        'Strong': 'green',
        'Good': 'green',
        'Medium': 'orange',
    }
    const strength = () => {
        if(errorMessage) return 'Not good enough'
        if(password?.length >= 14){
            if(numbers >= 3 || specials >= 3) return 'Good +'
            return (numbers >= 2 || specials >= 2) ? 'Strong' : 'Good'
        }
        if(numbers >= 4 && specials >= 4) return 'Insane' 
        if(numbers >= 3 || specials >= 3) return 'Strong' 
        if(numbers >= 2 || specials >= 2) return 'Good' 
        return 'Medium'
    } 

    const filteredValidations = Object.entries(validations)
        .filter(([_, val]) => !firstOnly || !isBoolean(val))
        .filter((e, index) => !firstOnly || index === 0)
        
    const Validations = ({center}) => (
        <CheckList center={center}>
            {!firstOnly && (
                <h5>Password Validation:</h5>
            )}
            <ul>
                {filteredValidations.map(([key, value]) => (
                    <ListItem key={key} valid={isBoolean(value)}>
                        <Status valid={isBoolean(value)}>
                            {isBoolean(value) ? <CheckIcon /> : <Cross2Icon />}
                        </Status> 
                        {list(password)[key]}
                    </ListItem>
                ))}
                {(filteredValidations?.length === 0 || !firstOnly) && (
                    <AdvancedLabel>
                        Strength: 
                        <Label color={color[strength()]}>{strength()}</Label>
                    </AdvancedLabel>
                )}
            </ul>
        </CheckList>
    )

    return [
        errorMessage,
        Validations,
        strength(),
    ]
}