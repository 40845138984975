import { Button, ButtonGroup } from "@mosanic/fields";
import { localFlag } from "@mosanic/fields/Input/Input";
import { ComponentInstanceIcon } from "@radix-ui/react-icons";
import Image from "next/image";

const mapped = {
    'en': 'English',
    'nl': 'Dutch'
}
export const SelectLocales = ({locales, active, setActive}) => locales?.length >= 2 ? (
    <ButtonGroup fullWidth>
        {locales.map(lang => (
            <Button 
                key={lang} 
                label={mapped?.[lang] ? ` ${mapped[lang]}` : lang} 
                active={active === lang} 
                icon={lang === 'base' ? (
                    <ComponentInstanceIcon />
                ) : (
                    <Image
                        alt={`${lang} flag`}
                        src={localFlag(lang)}
                        width="20px" height="12px"
                    />
                )}
                onClick={() => setActive(lang)} 
                fullWidth
            />
        ))}
    </ButtonGroup>
) : null;