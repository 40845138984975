export const PAGE_CORE = `
  title
  unique
  slug
  description
  date
  status
`;

export const PAGE_SEO = `
  seo {
    title
    description
    image
    isIndexed
    unique
    slug
  }
`;

export const SEO_FRAGMENT = `
  seo {
    title
    description
    keywords

    language
    author
    color
    
    image
    icon
    favicon

    isIndexed
    unique
    slug
    localized
  }
`;