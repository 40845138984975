import { BarChartIcon, BlendingModeIcon, ButtonIcon, CalendarIcon, CardStackIcon, CheckboxIcon, CodeIcon, ColumnsIcon, Component1Icon, ComponentInstanceIcon, Cross2Icon, DropdownMenuIcon, EnvelopeClosedIcon, EnvelopeOpenIcon, ExternalLinkIcon, FileIcon, GridIcon, HeadingIcon, ImageIcon, InputIcon, LetterCaseCapitalizeIcon, Link2Icon, ListBulletIcon, LoopIcon, PieChartIcon, PinTopIcon, QuestionMarkCircledIcon, QuestionMarkIcon, RadiobuttonIcon, RowSpacingIcon, SectionIcon, SizeIcon, SliderIcon, SquareIcon, StarIcon, SwitchIcon, TableIcon, TextAlignLeftIcon, TextIcon, TimerIcon, VideoIcon, ViewGridIcon } from "@radix-ui/react-icons";
import { hasAttr } from "@util/Obj";
import { hide } from "./EditIcons";

const AccordionGroupIcon = () => (
    <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg"><g fillRule="evenodd"><path d="M12 5.8c0 .1-.2.2-.5.2h-10c-.3 0-.5-.1-.5-.2V3.2c0-.1.2-.2.5-.2h10c.3 0 .5.1.5.2v2.6ZM13 1v5c0 .6-.7 1-1.5 1h-10C.7 7 0 6.6 0 6V1c0-.5.2-1 1-1h11c.8 0 1 .5 1 1ZM1.5 2v-.5c0-.3.2-.5.5-.5h9c.3 0 .5.2.5.5V2h-10Z"/><path d="M13 6v3H0V6h13Zm-1 1H1v1h11V7Z"/><path d="M13 8v3H0V8h13Zm-1 1H1v1h11V9Z"/></g></svg>
)
const AccordionIcon = () => (
    <svg width="13" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M12 6.7c0 .2-.2.3-.5.3h-10c-.3 0-.5-.1-.5-.3V3.3c0-.2.2-.3.5-.3h10c.3 0 .5.1.5.3v3.4Zm1-5.6v5.8c0 .6-.7 1.1-1.5 1.1h-10C.7 8 0 7.5 0 7V1c0-.4.2-1 1-1h11c.8 0 1 .6 1 1ZM1.5 2v-.5c0-.3.2-.5.5-.5h9c.3 0 .5.2.5.5V2h-10Z" fillRule="evenodd"/></svg>
)
const TabHeaderIcon = () => (
    <svg width="13" height="7" xmlns="http://www.w3.org/2000/svg"><path d="M12 7H1V3h10.5c.3 0 .5.1.5.3V7Zm1-6v6H0V1c0-.5.2-1 1-1h11c.8 0 1 .5 1 1ZM1 3V2l.1-1H4v1.4c0 .3.3.6.6.6H1Zm4-1v-.5l.1-.5H8l.1.5V2H5Zm4 0v-.5l.1-.5H12l.1.5V2H9Z" fillRule="evenodd"/></svg>
)
const TabsIcon = () => (
    <svg width="13" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.5c0 .3-.2.5-.5.5h-10a.5.5 0 0 1-.5-.5V3h10.5c.3 0 .5.2.5.5v6Zm1-8v8c0 .8-.7 1.5-1.5 1.5h-10C.7 11 0 10.3 0 9.5v-8C0 .8.2 0 1 0h11c.8 0 1 .8 1 1.5ZM1 3V2l.1-1H4v1.4c0 .3.3.6.6.6H1Zm4-1v-.5l.1-.5H8l.1.5V2H5Zm4 0v-.5l.1-.5H12l.1.5V2H9Z" fillRule="evenodd"/></svg>
)
const ColumnIcon = () => (
    <svg width="6" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M1 12h4V2.1C5 1.5 4.8 1 4.6 1H1v11ZM6 1.5C6 .7 5.7 0 5.3 0H.7C.3 0 0 .7 0 1.5v10c0 .8.3 1.5.7 1.5h4.6c.4 0 .7-.7.7-1.5v-10Z" fillRule="evenodd"/></svg>
)

export const ImageSliderIcon = () => (
<svg width="22" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 1h4.6c.4 0 .7.3.7.7v4.6c0 .4-.3.7-.7.7h-4.6a.7.7 0 0 1-.7-.7V1.7c0-.4.3-.7.7-.7Zm0 .5c-.1 0-.2 0-.2.2v2.7l.7-.8a.2.2 0 0 1 .3 0l1.7 1.8 1 1.1h1c.2 0 .3-.1.3-.3V1.7c0-.1 0-.2-.2-.2h-4.6Zm-.2 4.8V5l.9-1L19 5.9l.7.7h-3a.2.2 0 0 1-.2-.2Zm3.1-3.2a.4.4 0 1 1 .8 0 .4.4 0 0 1-.8 0Zm.4-.8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6ZM5.3 1H.7c-.4 0-.7.3-.7.7v4.6c0 .4.3.7.7.7h4.6c.4 0 .7-.3.7-.7V1.7c0-.4-.3-.7-.7-.7Zm0 .5c.1 0 .2 0 .2.2v2.7l-.7-.8a.2.2 0 0 0-.3 0L2.8 5.4l-1 1.1h-1a.3.3 0 0 1-.3-.3V1.7c0-.1 0-.2.2-.2h4.6Zm.2 4.8V5l-.9-1L3 5.9l-.7.7h3c.1 0 .2 0 .2-.2ZM2.4 3.1a.4.4 0 1 0-.8 0 .4.4 0 0 0 .8 0ZM2 2.3a.8.8 0 1 1 0 1.6.8.8 0 0 1 0-1.6ZM14 0H8a1 1 0 0 0-1 1v6c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1Zm0 .6c.2 0 .4.2.4.3v6.2c0 .2-.1.3-.3.3H7.9a.3.3 0 0 1-.3-.3V.9L8 .6h6.2Zm-2.5 3.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Zm-.5-1a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM8.6 11c.3 0 .6-.2.6-.5s-.3-.5-.6-.5-.6.2-.6.5.3.5.6.5Zm2.4 0c.3 0 .6-.2.6-.5s-.3-.5-.6-.5-.6.2-.6.5.3.5.6.5Zm2.4 0c.3 0 .6-.2.6-.5s-.3-.5-.6-.5-.6.2-.6.5.3.5.6.5Z" fillRrule="evenodd"/></svg>
)

export const NumList = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 1352 829"><path fill="currentcolor" fillRule="evenodd" d="M352 103c0-30 30-60 50-60h899.999998c27.61424 0 50 22.385762 50 50 0 27.614237-22.38576 50-50 50H402c-27.614237 0-50-22.385763-50-50v10Zm50 250c-27.614237 0-50 22.385763-50 50s22.385763 50 50 50h899.999998c27.61424 0 50-22.385763 50-50s-22.38576-50-50-50H402Zm0 300c-27.614237 0-50 22.385763-50 50s22.385763 50 50 50h899.999998c27.61424 0 50-22.385763 50-50s-22.38576-50-50-50H402ZM63.683787 22.16065C13.328305 47.658368-1.948076 65.790078 5.97301 88.454716c2.82896 8.49924 12.447423 18.698327 21.500094 23.231255 14.144798 6.799391 18.671134 6.232775 35.0791-1.699848l19.236925-9.065856v113.889807c0 42.922777.236957 78.499135.710871 106.729073 0 4.9213 12.529455 7.38195 37.588364 7.38195 27.274424 0 40.911636-2.46065 40.911636-7.38195V176.846805c0-147.886764-.565792-155.819387-11.315838-166.58509C133.276195-6.170148 112.907686-3.337068 63.683788 22.16065ZM7.63909 554.413486c0 13.587786 26.057563 36.800254 41.918688 36.800254 8.497031 0 19.259937-6.793893 28.889906-18.683206 19.259937-22.080152 30.022844-26.609414 47.016906-19.249364 28.889906 13.021629 23.225219 27.175573-45.3175 118.89313C-8.222036 789.368957-8.222036 789.368957 10.57382 817.593234c10.315904 11.406792 10.315904 11.406792 101.861989 11.406792 91.546085 0 83.270906-.566184 96.299687-14.15397 8.497031-19.261844 10.717615-31.374402 2.265875-50.388041-10.762906-13.587786-15.294656-14.720102-50.982187-14.720102H120.36637l33.421656-44.160305c66.276844-87.188295 75.340344-109.268448 63.4445-153.428753-7.364093-28.307888-35.687531-61.711196-60.612156-71.902036-20.959343-9.058524-66.276843-9.624682-86.669718-1.698473-27.756969 11.323155-62.311563 53.21883-62.311563 75.86514Z"/></svg>
)
export const LocalizedTextIcon = () => (
<svg width="15" height="15" viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="letter-case-capitalize">
            <path d="M3.68979003,2.5894786e-07 C3.89667003,-0.000209481463 4.08232003,0.127010519 4.15679003,0.320030519 L7.36662003,8.64000052 C7.46602003,8.89770052 7.33774003,9.18710052 7.08010003,9.28650052 C6.82247003,9.38590052 6.53304003,9.25760052 6.43365003,9.00000026 L5.38250003,6.27537052 L2.01133003,6.27537052 L0.966992029,8.99900052 C0.868128029,9.25680052 0.578964029,9.38570052 0.321126029,9.28690052 C0.063287829,9.18800052 -0.065586371,8.89880052 0.033277429,8.64100052 L3.22344003,0.320990519 C3.29751003,0.127820519 3.48290003,0.000210518537 3.68979003,2.5894786e-07 Z M3.69174003,1.89284052 L5.05458003,5.42537052 L2.33724003,5.42537052 L3.69174003,1.89284052 Z" id="Shape" fill="currentColor"></path>
            <g id="文" transform="translate(8.000000, 1.000000)" fill="currentColor" fillRule="nonzero">
                <path d="M0.155507559,1.47868505 L0.155507559,2.39406151 L1.46868251,2.39406151 C1.83153348,3.67030753 2.47948164,4.78812301 3.412527,5.75630965 C2.51403888,6.53085896 1.37365011,7.09416755 0,7.45503712 L0.483801296,8.26479321 C1.89200864,7.85991516 3.08423326,7.2173913 4.05183585,6.34602333 C4.96760259,7.12057264 6.11663067,7.7718982 7.49892009,8.3 L8,7.51664899 C6.68682505,7.02375398 5.58099352,6.4252386 4.69114471,5.70349947 C5.48596112,4.8145281 6.09071274,3.71431601 6.50539957,2.39406151 L7.80993521,2.39406151 L7.80993521,1.47868505 L4.54427646,1.47868505 C4.28509719,0.906574761 4,0.413679745 3.68898488,0 L2.78185745,0.334464475 C3.09287257,0.69533404 3.36069114,1.073807 3.57667387,1.47868505 L0.155507559,1.47868505 Z M2.33261339,2.39406151 L5.57235421,2.39406151 C5.23542117,3.45906681 4.72570194,4.37444327 4.05183585,5.13138918 C3.25701944,4.33923648 2.68682505,3.43266172 2.33261339,2.39406151 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)
export const GalleryIcon = () => (
<svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M7.7 7h4.6c.39 0 .7.31.7.7v4.6a.7.7 0 0 1-.7.7H7.7a.7.7 0 0 1-.7-.7V7.7c0-.39.31-.7.7-.7Zm0 .46c-.14 0-.24.1-.24.23v2.7l.78-.77a.2.2 0 0 1 .3 0l1.63 1.76.94 1.16h1.13a.3.3 0 0 0 .3-.3V7.69c0-.13-.1-.23-.23-.23H7.69Zm-.24 4.85v-1.32l.92-.92L10 11.82l.66.72H7.69a.23.23 0 0 1-.23-.23Zm3.15-3.23a.4.4 0 1 1 .78 0 .4.4 0 0 1-.78 0Zm.39-.81a.8.8 0 1 0 0 1.61.8.8 0 0 0 0-1.61ZM5.3 0H.7a.7.7 0 0 0-.7.7v4.6c0 .39.31.7.7.7h4.6a.7.7 0 0 0 .7-.7V.7a.7.7 0 0 0-.7-.7Zm0 .46c.14 0 .24.1.24.23v2.7l-.78-.77a.2.2 0 0 0-.3 0L2.83 4.39l-.94 1.16H.76a.3.3 0 0 1-.3-.3V.69C.46.56.56.46.7.46h4.62Zm.24 4.85V3.99l-.92-.92L3 4.82l-.66.72h2.98c.13 0 .23-.1.23-.23ZM2.39 2.08a.4.4 0 1 0-.78 0 .4.4 0 0 0 .78 0ZM2 1.27a.8.8 0 1 1 0 1.61.8.8 0 0 1 0-1.61ZM5.3 7H.7a.7.7 0 0 0-.7.7v4.6c0 .39.31.7.7.7h4.6a.7.7 0 0 0 .7-.7V7.7a.7.7 0 0 0-.7-.7Zm0 .46c.14 0 .24.1.24.23v4.55a.3.3 0 0 1-.3.3H.76a.3.3 0 0 1-.3-.3V7.69c0-.13.1-.23.23-.23h4.62Zm-1.9 2.62a.4.4 0 1 0-.8 0 .4.4 0 0 0 .8 0ZM3 9.27a.8.8 0 1 1 0 1.61.8.8 0 0 1 0-1.61ZM12.3 0H7.7a.7.7 0 0 0-.7.7v4.6c0 .39.31.7.7.7h4.6a.7.7 0 0 0 .7-.7V.7a.7.7 0 0 0-.7-.7Zm0 .46c.14 0 .24.1.24.23v4.55a.3.3 0 0 1-.3.3H7.76a.3.3 0 0 1-.3-.3V.69c0-.13.1-.23.23-.23h4.62Zm-1.9 2.62a.4.4 0 1 0-.8 0 .4.4 0 0 0 .8 0Zm-.4-.81a.8.8 0 1 1 0 1.61.8.8 0 0 1 0-1.61Z"/></svg>
)
export const HorizontalStack = () => (
    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="vertical_stack" transform="translate(6.500000, 6.500000) rotate(90.000000) translate(-6.500000, -6.500000) " fill="#000000">
                <path d="M12,12 L12,8 L2.1,8 C1.492492,8 1,8.179088 1,8.4 L1,12 L12,12 Z M1.5,7 C0.67157,7 0,7.30995538 0,7.69230769 L0,12.3076923 C0,12.6900308 0.67157,13 1.5,13 L11.5,13 C12.3284,13 13,12.6900308 13,12.3076923 L13,7.69230769 C13,7.30995538 12.3284,7 11.5,7 L1.5,7 Z" id="Shape"></path>
                <path d="M12,5 L12,1 L2.1,1 C1.492492,1 1,1.179088 1,1.4 L1,5 L12,5 Z M1.5,0 C0.67157,0 0,0.309955385 0,0.692307692 L0,5.30769231 C0,5.69003077 0.67157,6 1.5,6 L11.5,6 C12.3284,6 13,5.69003077 13,5.30769231 L13,0.692307692 C13,0.309955385 12.3284,0 11.5,0 L1.5,0 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
)
export const VerticalStack = () => (
    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="vertical_stack" fill="#000000">
                <path d="M12,12 L12,8 L2.1,8 C1.492492,8 1,8.179088 1,8.4 L1,12 L12,12 Z M1.5,7 C0.67157,7 0,7.30995538 0,7.69230769 L0,12.3076923 C0,12.6900308 0.67157,13 1.5,13 L11.5,13 C12.3284,13 13,12.6900308 13,12.3076923 L13,7.69230769 C13,7.30995538 12.3284,7 11.5,7 L1.5,7 Z" id="Shape"></path>
                <path d="M12,5 L12,1 L2.1,1 C1.492492,1 1,1.179088 1,1.4 L1,5 L12,5 Z M1.5,0 C0.67157,0 0,0.309955385 0,0.692307692 L0,5.30769231 C0,5.69003077 0.67157,6 1.5,6 L11.5,6 C12.3284,6 13,5.69003077 13,5.30769231 L13,0.692307692 C13,0.309955385 12.3284,0 11.5,0 L1.5,0 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
)
export const elementIcon = (type) => hasAttr(elementIcons, type) ? elementIcons[type] : elementIcons.unknown;

export const icons = {
    visual: <LetterCaseCapitalizeIcon />,
    translated: <LocalizedTextIcon />,
    localizedtext: <LocalizedTextIcon />,

    heading: <HeadingIcon/>,
    label: <HeadingIcon/>,
    text: <TextIcon/>,
    textarea: <TextAlignLeftIcon/>,
    email: <EnvelopeClosedIcon />,
    number: <Cross2Icon />,

    imageslider: <ImageSliderIcon />,
    "archive": <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" d="M3.309 1a1 1 0 0 0-.894.553L1.053 4.276A.5.5 0 0 0 1 4.5V13a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.053-.224l-1.362-2.723A1 1 0 0 0 11.691 1H3.309Zm0 1H7v2H2.309l1-2ZM8 4V2h3.691l1 2H8Zm-.5 1H13v8H2V5h5.5Zm-2 2a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4Z" fill="#000"/></svg>,
    "row": <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M9 2H6v11h3V2Zm1 0v11h2.5a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H10ZM2.5 2H5v11H2.5a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5Zm0-1A1.5 1.5 0 0 0 1 2.5v10A1.5 1.5 0 0 0 2.5 14h10a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 12.5 1h-10Z" fill="#000"/></svg>,
    container: <SectionIcon />,
    repeater: <LoopIcon />,
    stack:  <CardStackIcon />,
    
    "add": <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.5.877a6.623 6.623 0 1 0 0 13.246A6.623 6.623 0 0 0 7.5.877ZM1.827 7.5a5.673 5.673 0 1 1 11.346 0 5.673 5.673 0 0 1-11.346 0ZM7.5 4a.5.5 0 0 1 .5.5V7h2.5a.5.5 0 1 1 0 1H8v2.5a.5.5 0 0 1-1 0V8H4.5a.5.5 0 0 1 0-1H7V4.5a.5.5 0 0 1 .5-.5Z" fill="#000"/></svg>,
    input: <InputIcon />,
    button:  <ButtonIcon />,

    slider: <SliderIcon />,
    radio: <RadiobuttonIcon/>,
    checkbox: <CheckboxIcon />,
    switch: <SwitchIcon />,
    select: <DropdownMenuIcon />,


    gallery: <GalleryIcon/>,
    image: <ImageIcon />,
    icon: <StarIcon />,
    link: <Link2Icon />,
    relation: <Link2Icon />,
    external: <ExternalLinkIcon />,
    file: <FileIcon />,
    color: <BlendingModeIcon />,
    id: <Component1Icon />,
    component: <ComponentInstanceIcon />,

    video: <VideoIcon />,

    horizontalstack: <HorizontalStack />,
    verticalstack: <VerticalStack />,
    
    columns: <ColumnsIcon />,
    column: <ColumnIcon />,

    tabs: <TabsIcon/>,
    tabheader: <TabHeaderIcon/>,
    tab: <SquareIcon/>,

    expanders: <AccordionGroupIcon/>,
    expander: <span style={{transform: 'rotate(180deg)'}}><CardStackIcon /></span>,
    expanderheader: <AccordionIcon/>,

    dialog: <SizeIcon/>,

    form: <InputIcon/>,
    accordion: <AccordionIcon/>,
    panel: <QuestionMarkCircledIcon/>,
    menu: <PinTopIcon />,
    list: <ListBulletIcon />,
    
    table: <TableIcon />,
    chart: <BarChartIcon />,
    pie: <PieChartIcon />,

    timer: <TimerIcon />,
    time: <TimerIcon />,
    calendar: <CalendarIcon/>,
    'date-time':  <CalendarIcon/>,
    date:  <CalendarIcon/>,
    
    layout: <ViewGridIcon/>,
    grid: <GridIcon/>,
    code: <CodeIcon />,
    script: <CodeIcon />,

    help: <QuestionMarkIcon />,
    
    "hidden": hide,
    "trans": <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path opacity=".25" d="M0 0h3v3H0V0Zm6 3H3v3H0v3h3v3H0v3h3v-3h3v3h3v-3h3v3h3v-3h-3V9h3V6h-3V3h3V0h-3v3H9V0H6v3Zm0 3V3h3v3H6Zm0 3H3V6h3v3Zm3 0V6h3v3H9Zm0 0H6v3h3V9Z" fill="#000"/></svg>,
  
    "unknown": <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M12.803 7.146a.5.5 0 1 1 .707.708.5.5 0 0 1-.707-.708Zm-.707 1.415a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707Zm-1.414 1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707Zm-1.414 1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707ZM7.146 13.51a.5.5 0 1 1 .708-.707.5.5 0 0 1-.708.707ZM2.904 8.56a.5.5 0 1 0 .707.708.5.5 0 0 0-.707-.707ZM4.318 9.975a.5.5 0 1 0 .707.707.5.5 0 0 0-.707-.707ZM5.732 11.39a.5.5 0 1 0 .707.706.5.5 0 0 0-.707-.707ZM2.197 7.854a.5.5 0 0 0 .064-.63.506.506 0 0 0-.771-.078.5.5 0 0 0 .707.708ZM3.611 5.732a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM5.025 4.318a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM6.44 2.904a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM7.854 1.49a.5.5 0 1 0-.708.707.5.5 0 0 0 .708-.707ZM9.268 2.904a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM10.682 4.318a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM12.096 5.732a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Z" fill="#000"/></svg>,
};

export const elementIcons = {
    ...icons,
    
}