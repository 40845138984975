import { HoveringToolbar } from './ToolbarHover'
import { TopToolbar } from './ToolbarFull'

const Toolbars = ({full = true, location = "location", endSource = null}) => full ? (
    <TopToolbar location={location} endSource={endSource} />
) : (
    <HoveringToolbar extra={<TopToolbar reversed minified/>}/>
)


export {
    TopToolbar,
    HoveringToolbar,
}

export default Toolbars;