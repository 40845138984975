








export const SIDEBAR_ITEM = "sidebarItem";
export const SIDEBAR_STACK = "sidebarContainer";
export const SIDEBAR_REPEATER = "sidebarRepeater";
export const SIDEBAR_PANEL = "sidebarPanel";
export const ADD_ITEM = "addItem";
export const SIDEBAR_LAYOUT = "layoutItem";
export const ROW = "ROW";
export const COLUMN = "COLUMN";
export const STACK = "STACK";
export const COMPONENT = "COMPONENT";
export const REPEATER = "REPEATER";


export const SMALLEST_BP = 'xs';
export const LARGEST_BP = 'xl';


export const LAYOUT_STATES = "layouts";
export const DESIGN_STATES = "design";

export const DEFAULT_STATE = "default";
export const HOVER_STATE = "hover";

export const EDITOR_MODES = [
    {name: 'Classic', value: 'classic' },
    {name: 'Inline', value: 'inline' },
    {name: 'Balloon', value: 'balloon' },
    {name: 'Follow', value: 'balloon-always'},
]
export const COLOR_OPTIONS = [
    {name: 'Color', value: '#F8F8F8'}, 
    {name: 'Color', value: '#F4F7FA'}, 
    {name: 'Color', value: '#E7E9ED'}, 
    {name: 'Color', value: '#D5D7DD'}, 
    {name: 'Color', value: '#7D818D'}, 
    {name: 'Color', value: '#666666'}, 
    {name: 'Color', value: '#1E1E20'},   
    {name: 'Color', value: '#FF6900'}, 
    {name: 'Color', value: '#FCB900'}, 
    {name: 'Color', value: '#7BDCB5'}, 
    {name: 'Color', value: '#00D084'}, 
    {name: 'Color', value: '#8ED1FC'}, 
    {name: 'Color', value: '#0693E3'}, 
    {name: 'Color', value: '#ABB8C3'}, 
    {name: 'Color', value: '#EB144C'}, 
    {name: 'Color', value: '#F78DA7'}, 
    {name: 'Color', value: '#9900EF'}
];

export const FONT_SCALES = [
        {value: 1.067, label: '1.067 - Minor Second'},
        {value: 1.125, label: '1.125 - Major Second'},
        {value: 1.200, label: '1.200 - Minor Third'},
        {value: 1.250, label: '1.250 - Major Third'},
        {value: 1.333, label: '1.333 - Perfect Fourth'},
        {value: 1.414, label: '1.414 - Super Fourth'},
        {value: 1.500, label: '1.500 - Perfect Fifth'},
        {value: 1.618, label: '1.618 - Golden Ratio'},
    ]


export const MAIN_SHADE = 3;

export const COLOR_SHADES = [
    {
        name: 'Main shades', 
        type: 'shades',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'snow', value: '#F4F7FA'},
            {type: '20', inPalette: false, inShading: true, name: 'mist', value: '#E7E9ED'},
            {type: '30', inPalette: false, inShading: true, name: 'storm', value: '#D5D7DD'}, 
            {type: '40', inPalette: false, inShading: true, name: 'stone', value: '#7D818D'},
            {type: '50', inPalette: false, inShading: true, name: 'night', value: '#5A5E68'},
            {type: '60', inPalette: false, inShading: true, name: 'gone', value: '#1E1E20'},
        ]
    },{
        name: 'Primary', 
        type: 'main',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'soft', value: '#D7EFFE'},
            {type: '20', inPalette: false, inShading: true, name: 'sky', value: '#A5DBFD'},
            {type: '30', inPalette: false, inShading: true, name: 'soft', value: '#8ED1FC'}, 
            {type: '40', inPalette: false, inShading: true, name: 'bright', value: '#0693E3'},
            {type: '50', inPalette: false, inShading: true, name: 'ocean', value: '#046095'}, 
            {type: '60', inPalette: false, inShading: true, name: 'sea', value: '#023350'}, 
        ]
    },{
        name: 'Secondary', 
        type: 'secondary',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'Color', value: '#D7EFFE'},
            {type: '20', inPalette: false, inShading: true, name: 'Color', value: '#A5DBFD'},
            {type: '30', inPalette: false, inShading: true, name: 'Color', value: '#8ED1FC'}, 
            {type: '40', inPalette: false, inShading: true, name: 'Color', value: '#0693E3'},
            {type: '50', inPalette: false, inShading: true, name: 'Color', value: '#046095'}, 
            {type: '60', inPalette: false, inShading: true, name: 'Color', value: '#023350'}, 
        ]
    },{
        name: 'Error', 
        type: 'error',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'},
            {type: '20', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'}, 
            {type: '30', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'},
            {type: '40', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'}, 
            {type: '50', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'},
            {type: '60', inPalette: false, inShading: true, name: 'Color', value: '#EB144C'}, 
        ]
    },{
        name: 'Warning', 
        type: 'warning',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'Color', value: '#FCB900'},
            {type: '20', inPalette: false, inShading: true, name: 'Color', value: '#FF6900'}, 
            {type: '30', inPalette: false, inShading: true, name: 'Color', value: '#FCB900'},
            {type: '40', inPalette: false, inShading: true, name: 'Color', value: '#FCB900'}, 
            {type: '50', inPalette: false, inShading: true, name: 'Color', value: '#FCB900'},
            {type: '60', inPalette: false, inShading: true, name: 'Color', value: '#FCB900'}, 
        ]
    },{
        name: 'Success', 
        type: 'success',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'Color', value: '#7BDCB5'},
            {type: '20', inPalette: false, inShading: true, name: 'Color', value: '#7BDCB5'}, 
            {type: '30', inPalette: false, inShading: true, name: 'Color', value: '#7BDCB5'},
            {type: '40', inPalette: false, inShading: true, name: 'Color', value: '#00D084'}, 
            {type: '50', inPalette: false, inShading: true, name: 'Color', value: '#00D084'},
            {type: '60', inPalette: false, inShading: true, name: 'Color', value: '#00D084'}, 
        ]
    },{
        name: 'Utility colors', 
        type: 'util',
        colors: [
            {type: '10', inPalette: false, inShading: true, name: 'Color', value: '#ABB8C3'},
            {type: '20', inPalette: false, inShading: true, name: 'Color', value: '#9900EF'}, 
            {type: '30', inPalette: false, inShading: true, name: 'Color', value: '#F78DA7'},
            {type: '40', inPalette: false, inShading: true, name: 'Color', value: '#EA0EA0'}, 
            {type: '50', inPalette: false, inShading: true, name: 'Color', value: '#9900EF'},
            {type: '60', inPalette: false, inShading: true, name: 'Color', value: '#212121'}, 
        ]
    },
];

export const INITIAL_METRICS = {
    width: {
        label: 'Width',
        id: 'width',
        metric: 'px',
        value: null,
        isDisabled: false,
        isDisabledReason: '',
    },
    height: {
        label: 'Height',
        id: 'height',
        metric: 'px',
        value: null,
        isDisabled: false,
        isDisabledReason: '',
    },
    minWidth: {
        label: 'Min width',
        id: 'minWidth',
        metric: 'px',
        value:null,
        isDisabled: false,
        isDisabledReason: '',
    },
    minHeight: {
        label: 'Min height',
        id: 'minHeight',
        metric: 'px',
        value:null,
        isDisabled: false,
        isDisabledReason: '',
    },
    maxWidth: {
        label: 'Max width',
        id: 'maxWidth',
        metric: 'px',
        value: null,
        isDisabled: false,
        isDisabledReason: '',
    },
    maxHeight: {
        label: 'Max height',
        id: 'maxHeight',
        metric: 'px',
        value: null,
        isDisabled: true,
        isDisabledReason: "Text can't have a maximum height",
    }
};




export const ADD_LAYOUTS = [
    {
        type: SIDEBAR_LAYOUT,
        title: 'test row',
        path: '0-0', 
        children: [{
            type: COLUMN,
            _id:  'foxed1',
            children: [
               {
                    type: COMPONENT,
                    _id:  'component6',
                }
            ]
        },{
            type: COLUMN,
            _id:  'foxed2',
            width: "60",
            children: [
                {
                    type: COMPONENT,
                    _id:  'component2',
                },{
                    type: COMPONENT,
                    _id:  'component2',
                }
            ]
        },{
            type: COLUMN,
            _id:  'foxed2',
            width: "60",
            children: [
                {
                    type: COMPONENT,
                    _id:  'component2',
                },{
                    type: COMPONENT,
                    _id:  'component2',
                }
            ]
        }]
        
    }
]

