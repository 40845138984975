import { ADD_ITEM, SIDEBAR_ITEM } from "./items";


export const INITIAL_COMPONENTS = [
    {

        type: SIDEBAR_ITEM,
        addQuick: false,
        component: {
            name: "Layout tools",
            type: "layout-tools",
            icon: null,
            content: "Build up the foundation of this page.",
            isFocus: false,
            editor: {
               desc: 'Testing desc'
            }, 
        },
        elements: [
            "boxes",
            "layouts",
            "grids",
            "repeaters",
            "modals"
        ]
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "text", 
            title: "Visual text", 
            icon: 'visual'
        },
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            icon: "translated",
            type: "localizedtext", 
            title: "Localized text", 
        },
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            icon: 'text',
            type: "content", 
            title: "Text area", 
        },
    },
    {

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "heading", 
            
        },
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "button",
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "icon",
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "dataprovider",
            template: 'height',
            title: "Data Provider",
            icon: "collection",
            desc: 'Provide data to children'
        }
    }, {
        

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "repeater",
            desc: 'Loop dynamic with data links'
        }
    }, {

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "datalink",
            template: 'height',
            title: "Data link",
            icon: "link",
            desc: 'Connect to a entry'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "link", 
            icon: 'external',
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "stripeprovider",
            template: 'height',
            title: "stripe Provider",
            icon: "product",
            desc: 'Retreive stripe products'
        }
    }, {

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "image",
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "video",
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            icon: "imageslider",
            type: 'imageslider',
            title: "Content slider"
        }
    },
    {

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "gallery",
            desc: 'Image masonary gallery'
            // coming: true
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "dialog",
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "container",
            template: 'container',
            desc: 'Base container'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "stack",
            template: 'vertical',
            title: 'Vertical stack',
            icon: "verticalstack",
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "stack",
            template: 'horizontal',
            title: 'Horizon stack',
            icon: "horizontalstack",
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "columns",
            desc: 'Easy responsive sizing'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "column",
            desc: 'Only as child of columns'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "table",
            future: true,
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "tabs",
            desc: 'Must wrap tabs'
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Tab header',
            type: "tabheader",
            desc: 'Control current tab'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "tab",
            desc: 'Tab content'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Accordions',
            type: "expanders",
            desc: 'To control multi expanders'
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Accordion H',
            icon: 'accordion',
            type: "expanderheader",
            desc: 'Control current expander'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Accordion',
            type: "expander",
            desc: 'Expander Accordion'
        }
    },{
         type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "layout", 
            coming: true
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "grid", 
            coming: true
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "code",
            desc: 'Custom code'
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "script",
            desc: 'Insert code links'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "panel",
            desc: 'Information panel',
            coming: true
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        limit: 'header',
        component: {
            type: "menu",
            desc: 'Insert custom navs'
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: "Count down",
            type: "timer",
            coming: true,
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "form",
            desc: 'Link custom forms direct'
        }
    },{
        type: SIDEBAR_ITEM,
        group: 'form',
        addQuick: true,
        component: {
            type: "inputfield",
            title: 'Input',
            desc: 'Field input'
        }
    },{
        type: SIDEBAR_ITEM,
        group: 'form',
        addQuick: true,
        component: {
            type: "checkbox",
            future: true,
        }
    },{
        type: SIDEBAR_ITEM,
        group: 'form',
        addQuick: true,
        component: {
            type: "switch",
            future: true,
        }
    },{
        type: SIDEBAR_ITEM,
        group: 'form',
        addQuick: true,
        component: {
            type: "radio",
            future: true,
        }
    },{
        type: SIDEBAR_ITEM,
        group: 'form',
        addQuick: true,
        component: {
            type: "slider",
            future: true,
        }
    }, {
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "chart",
            planned: true,
        }
    },{
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "pie",
            future: true,
        }
    }, {
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            type: "calendar",
            future: true,
        }
    }, {
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Social grid',
            type: "socialgrid",
        }
    }, {
        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Google map',
            type: "googlemap",
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Account',
            type: "account",
            desc: '[addon] User accounts'
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'Auth forms',
            type: "auth",
            desc: '[addon] User accounts'
        }
    },{

        type: SIDEBAR_ITEM,
        addQuick: true,
        component: {
            title: 'User',
            type: "user",
            desc: '[addon] User accounts'
        }
    }
];

const getQuickAddItems = () => {
    const items = [];

    Object.values(INITIAL_COMPONENTS).map((item, index) => {
        if(item.addQuick){
            if(item.type === SIDEBAR_ITEM)
                item.type = ADD_ITEM;

            items.push(item);
        }
    });

    return items;
}

export const ADD_ITEMS = getQuickAddItems();

export const getAddItem = (type, index = 0) => ADD_ITEMS.filter(item => item.component.type === type)[index]

export const getInitialComponent = (type) => (
    INITIAL_COMPONENTS.filter(item => 
        item.component.type === type
    )[0]
);