
import { Condition } from "@JSX";
import { Buttons } from "@mosanic/fields";
import { ControlPopper } from "@mosanic/items";
import { DotsHorizontalIcon, DotsVerticalIcon, DragHandleDots1Icon, TrashIcon } from "@radix-ui/react-icons";
import { default as s } from './Collection.styles';

const Button = ({icon, label, disabled, onClick, ...other}) => (
    <s.Button {...other} disabled={disabled} onClick={() => ( !disabled && onClick )&& onClick()}>
        {icon}{label}
    </s.Button>
)
export const FieldControls = ({remove, move, index, count, dragHandle = null, wrap = true, extra = null, trigger = null, ...other}) => (
    <Condition when={wrap} wrapper={children => <s.FieldControls {...other}>{children}</s.FieldControls>}>
        {extra}
        {dragHandle && (
            <Button ghost icon={<DragHandleDots1Icon/> } {...dragHandle} style={{cursor: 'grab'}} />
        )}
        {(move || remove) && (
            <ControlPopper trigger={trigger ? trigger : <Button icon={<DotsVerticalIcon />} />} p={0} width="auto">
                <Buttons>
                    {move && <Button ghost type="button" disabled={0 === index} onClick={() => move(index, index - 1)} label="↑" />}
                    {move && <Button ghost type="button" disabled={count === index + 1} onClick={() => move(index, index + 1)} label="↓" />}
                    {remove && <Button ghost type="button" onClick={() => remove(index)} icon={<TrashIcon />} />}
                </Buttons>
            </ControlPopper>
        )}
    </Condition>
)