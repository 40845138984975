import { default as c } from "@mosanic/styles/card.styles";


export const Card = ({children, ...other}) => (
    <c.Card {...other}>
        {children}
    </c.Card>
)
export const Block = ({children, ...other}) => (
    <c.Block {...other}>
        {children}
    </c.Block>
)
export const MembershipCard = ({children, ...other}) => (
    <c.MembershipCard {...other}>
        {children}
    </c.MembershipCard>
)
export const Tag = ({children, ...other}) => (
    <c.Tag {...other}>
        {children}
    </c.Tag>
)