import { CodeIcon, InputIcon } from "@radix-ui/react-icons"

import { canValueReplaceWithCode, getLatestFieldFromFieldPath, isProvider } from "../helpers"


const getColumnTabOptions = ({canReplaceValue, isProvider}) => {
    const base = [
        {value: 'field', icon: <InputIcon />},
        {value: 'editor', icon: <CodeIcon />},
    ]
    if(isProvider || !canReplaceValue) return base

    return [{value: 'code', icon: <CodeIcon />}, ...base]
}

/**
 * Source selector column options
 * @returns data + handling for selector
 */
export const useColumnTabs = ({value, handler, itemType}) => {
    const {column} = value || {column: 'field'}

    const setColumn = col => handler({
        ...value, 
        column: col
    })

    const options = getColumnTabOptions({
        canReplaceValue: canValueReplaceWithCode(
            getLatestFieldFromFieldPath(value?.fieldPath)?.type
        ),
        isProvider: isProvider(itemType),
    })
    return {
        value: column,
        handler: setColumn,
        options,
        key: 'column'
    }
}