/**
 * P1: 
 * * Like Shadow Equal, use Object.is for first-level filtering.
 * 
 * P2: 
 * * Special handling is required for Date and RegExp, 
 * Use Date.prototype.getTime() for Date to get the timestamp and compare
 * Use RegExp.prototype.toString() for RegExp to get string and compare.
 * 
 * P3: Like Shadow Equal, make sure both are objects and return false if either is not.
 * 
 * P4: 
 * * Use WeakMap as a hash table to solve the circular reference problem. 
 * If the two have been compared before, it will return true, 
 * which means that it will not affect the final result.
 * 
 * P5: 
 * * Compared to Shadow Equal, we upgrade to Reflect.ownKeys to get all keys. 
 * Then we also judge the length of the attribute array, 
 * Then loop through all the attribute keys of objA, 
 * Use Reflect.has to judge whether there are the same attributes on objB. 
 * Finally, we upgrade Object.is to recursive processing
 * Constantly judge whether the deep values are equal.
 * 
 * @param {*} objA 
 * @param {*} objB 
 * @param {*} map 
 * @returns 
 */

const isEqual = (objA, objB, map = new WeakMap()) => {
  // P1
  if (Object.is(objA, objB)) return true;

  // P2
  if (objA instanceof Date && objB instanceof Date) {
    return objA.getTime() === objB.getTime();
  }
  if (objA instanceof RegExp && objB instanceof RegExp) {
    return objA.toString() === objB.toString();
  }

  // P3
  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  // P4
  if (map.get(objA) === objB) return true;
  map.set(objA, objB);

  // P5
  const keysA = Reflect.ownKeys(objA);
  const keysB = Reflect.ownKeys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Reflect.has(objB, keysA[i]) ||
      !isEqual(objA[keysA[i]], objB[keysA[i]], map)
    ) {
      return false;
    }
  }

  return true;
};
export default isEqual;