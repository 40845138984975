
import { If } from '@JSX';
import { helpCircle } from '@mosanic/Icons';
import ControlPopper from './Popper';


export type HelpPopperProps = {
  helpText: string
  trigger?: any
  renderHelp?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top'
  hover?: boolean
  span?: boolean
}


export const DefaultTrigger = () => (
  <span className='help__icon -sm'>
    {helpCircle}
  </span>
);


export const HelpPopper = ({helpText, trigger = <DefaultTrigger/>, renderHelp = false, hover = false, span = false}:HelpPopperProps) => (
    <If condition={helpText}>
        <ControlPopper trigger={trigger} customClass="helpPopper" hover={hover}>
            {renderHelp ? 
              <div dangerouslySetInnerHTML={{ __html: helpText}}></div> :
              span ? (<span style={{fontSize: '12px'}}>{helpText}</span>) : helpText
            }
        </ControlPopper> 
    </If>
);