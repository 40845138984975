import { AdvancedLabel, ControlPopper } from "@mosanic/items";
import { HeadingIcon, MixIcon, TextAlignLeftIcon } from "@radix-ui/react-icons";
import { BlockButton, MarkButton, ColorButton } from "../Components"
import { Toolbar } from "../Wrappers"
import { ControlGroup } from "./ControlGroup";
import { GroupAlign, SourceButton, GroupHeadings, GroupInlineElements, GroupInsertBlocks, GroupStyle } from "./Groups";
import { LocalizedTextIcon } from "@mosanic/Icons/ElementIcons";



const TopToolbar = ({reversed= false, minified = false, location = "auto", endSource = null}) => (
    <Toolbar minified={minified} location={location}>
        {!minified ? <GroupStyle code/> : null}
        
        <ControlGroup 
            triggerProps={{formatGroup: 'headings', icon: <HeadingIcon />}} 
            group={<GroupHeadings />} 
            reversed={reversed}
        />
    
        <GroupInsertBlocks />
        {minified ? (
             <ControlGroup 
                triggerProps={{formatGroup: 'link', icon: <MixIcon />}} 
                group={<GroupInlineElements />} 
                reversed={reversed}
            />
        ) : <GroupInlineElements /> }

        {(minified || true) ? (
             <ControlGroup 
                triggerProps={{formatGroup: 'align', icon: <TextAlignLeftIcon />}} 
                group={<GroupAlign />} 
                reversed={reversed}
            />
        ) : <GroupAlign /> }

        <ControlGroup 
            triggerProps={{formatGroup: 'translate', icon: <LocalizedTextIcon />}} 
            group={(
            <div>
                <AdvancedLabel label="Translate content" />
            </div>)} 
            reversed={reversed}
        />

        <ColorButton />

        {endSource && (
            <SourceButton endSource={endSource}/>
        )}
    </Toolbar>
);

// const MinifiedAlign = ({reversed = false}) => (

// )


export {
    TopToolbar
}