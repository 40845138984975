import styled, { css } from "styled-components";



const Th = styled.th` 
    text-transform: capitalize;
    text-align: center;
    font-size: 12px;

    ${props => props.bg && css` 
        background: ${props.bg};
    `}
    overflow: hidden;
    padding: 5px 2.5px;
`
const Tr = styled.tr` 
`
const Td = styled.td` 
    padding: 5px 2.5px;
    text-align: center;
    ${props => props.bg && css` 
        background: ${props.bg};
    `}
`
const Table = styled.table` 
    width: 100%;
    ${props => props.cells && css` 
        ${Th} {
            width: ${100 / props.cells}%;
        }
    `}
    ${props => props.align && css` 
        ${Th}, ${Td} {
            text-align: ${props.align};
        }
    `}
    ${props => props.fontSize && css` 
        ${Th}, ${Td} {
            font-size: ${props.fontSize};
        }
        ${Th} {
            font-weight: 600;
        }
    `}
    tr:last-child {
        padding-bottom: 15px;
    }
`

const table = {
    Th,
    Tr,
    Table,
    Td
}
export default table;

export {
    Th,
    Tr,
    Table,
    Td
}