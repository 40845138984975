
import { composeProviders } from '@hooks';
import dynamic from "next/dynamic";


import { CoreProvider } from '@context/CoreContext';

import { GlobalProvider } from '@context/GlobalContext';


export const BuildersProviders = composeProviders(
  CoreProvider,
  GlobalProvider,
)

const BuilderRouting = ({children}) => (
    <BuildersProviders>
        {children}
    </BuildersProviders>
); 
export default BuilderRouting;