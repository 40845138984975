import { colors } from "@mosanic/styles";
import media from "css-in-js-media";
import { lighten } from "polished";
import styled, { css } from "styled-components";
import { color, layout, space } from 'styled-system';

const navOffset = '40px';
const subNavOffset = '40px';
const combinedOffset = `${parseFloat(navOffset) + parseFloat(subNavOffset) + 2}px`

const ListItem = styled.div` 
    margin: 4px 10px 0px;
    color: ${props => props.theme.colors?.text}!important;
`
const Divider = styled.div` 
    border-bottom: ${props => lighten(0.05, props.theme.colors?.lightGray)} 2px solid;
`

export const ListItemIcon = styled.div` 
    margin: 2px 0 -5px 4px;
`

const ListMenuItem = styled.div`
    padding: 5px;
    transition: ease-in-out 0.2s all;
    display: flex;
    margin: 0;
    max-height: 40px;
    height: 40px;
    overflow: hidden;

    &:hover {
        background: ${props => props.theme.colors.light};
        cursor: pointer;

        ${ListItemIcon} svg path {
            fill: ${colors.util.primary.dark};
        }
    }

    svg path {
        fill: ${props => props.theme.colors?.text};
    }
    ${props => props.active && css` 

        background: ${props => props.theme.colors.light};
        
        &:hover {
            background: ${props => props.theme.colors?.white};
        }

        ${ListItem} {
          color: ${colors.util.primary.base} !important;
        }
        svg path {
          fill: ${colors.util.primary.base};
        }
    `}
`;


const List = styled.div` 

    ${props => props.large && css` 
        ${ListItem} {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 900;
            font-size: 12px;
            line-height: 24px;
        }
        ${ListMenuItem} {

        }
        ${ListItemIcon} svg {
            transition: all ease-in-out 0.3s;
            transform: scale(1.2);
            transform-origin: center left;

        }
    `}
`
const ListMenu = styled.div` 
    margin: 0 !important;
    padding: 0 !important;
`

const ListHeader = styled.div` 
    min-height: 45px;
    height: 45px;
    background: ${props => props.theme.colors?.white};
    border-bottom: ${props => props.theme.colors?.lightGray} 2px solid;
    overflow: hidden;
    ${props => props.close && css` 
        @media (min-width: 600px){
            span {
                opacity: 0;
            }
        }
    `}
`

const SideBarToggle = styled.span` 
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    position: absolute;
    margin: 5px 5px 5px 0;
    padding: 5px;

    right: 5px;
    top: 0;
    /* background-color: ${props => props.theme.colors?.lightGray}; */

    svg path {
        fill: ${props => props.theme.colors.dark};
      }
    &:hover svg {
      transform: scale(1.05);
      path {
        fill: ${props => props.theme.colors.dark};
      }
    }

    ${media(">desktop")} {
        font-size: 15px;
    }
    ${media("<=desktop", ">tablet")} {
        font-size: 20px;
    }
    ${media("<=tablet", ">phone")} {
        font-size: 25px;
    }
    ${media("<=phone")} {
        font-size: 30px;
    }
`

const subNavOffsetStyling = css` 
    margin-top: 80px;
    height: ${`calc(100vh - ${combinedOffset})`};
    overflow-y: auto;

    ${SideBarToggle} {
        /* top: 42px!important; */
    }
`

const SubSideBar = styled.div` 
    box-shadow: none;
    ${props => props.fixed && css` 
        margin-left: ${props.marginLeft};
        position: fixed;
        z-index: 2;
        top: 0;
    `}
    overflow-x: hidden;
    
    background: rgba(255,255,255,0.95);
    background:  ${props => props.theme.colors?.white};

    backdrop-filter: blur(4px) grayscale(100%);
    border-right: 1px solid #e5e5e5;
    transition: all ease-in-out 0.35s;

    ${props => props.close && css` 
        margin-left: -355px!important;
        @media (max-width: 600px){
            margin-left: -100%!important;
        }
    `}

    /* Get height based if page has subnav */
    ${props => props.subNavBar ? subNavOffsetStyling : css` 
        /* height: ${`calc(100vh - ${navOffset})`}; */
    `}
/* 
    ${props => props.small && css` 
        width: 324px;
        margin-left: 170px;
    `} */

    /* margin-left: ${props => props.ml}; */
    width: ${props => props.width};
    max-width: ${props => props.width};


    @media (max-width: 600px){
        margin-left: 0;
        z-index: 4;
        position: fixed;
        width: 100%;
    }
`

const SideBar = styled.div` 
    ${space}
    ${layout}
    ${color}
    position: relative;
    top: 0;
    flex: 0 0 1;
    width: ${props => props.width};
    transition: all ease-in-out 0.35s;
    ${props => props.type === 'icon' && css` 
        text-align: center;
    `}

    /* max-height: 100vh; */
    height: 100%;
    /* width: 100%; */
    overflow-x: hidden;
    overflow-y: scroll;
    background: ${props => props.theme.colors?.white};

    border-right: 1px solid ${props => props.theme.colors?.lightGray};
    /* border-left: 1px solid #e5e5e5; */ 
    z-index: 3;
    vertical-align: top;
    display: inline-block;

    /* margin-top: 40px; */


    ${props => props.close && css` 
        margin-left: -300px;

        @media (max-width: 600px) {
            margin-left: -140%;
        }
    `}

    ${props => props.small && css` 
        width: 170px;
    `}

    ${props => props.right ? css` 
        right: 0;
        position: fixed;
        border-right: none;
        border-left: 1px solid #e5e5e5;
        width: 275px;
        top: 80px;
        max-height: calc(100vh - 80px);
        
        ${props => props.close && css`
            margin-left: 0;
            margin-right: -275px;
        `}
    ` : css`
        left: 0;
    `}

    & *, ${ListItem} * {
        color: #212121!important;
        font-weight: 400;
    }

`

export const InlineContainer = styled.div` 
    transition: all ease-in-out 0.3s;
    overflow: hidden;
    width: 100%;
`
const SideBarContainer = styled.div` 
    display: flex;
    position: relative;

    width: ${props => props.width};
    min-width: ${props => props.width};
    ${SideBar} {
        -ms-overflow-style: none; 
        scrollbar-width: none; 

        &::-webkit-scrollbar {
            display: none; 
        }
    }
    /* ${props => props.iconOnly && css` 
        &, ${SideBar} {
            width: 45px;
            min-width: 45px!important;
        }
        ${ListItem},
        .nameLabel {
            opacity: 0;
        }
        .nameLabel {
            display: none;
        }
        ${SubSideBar} {
            margin-left: 45px;
            width: 324px;
        }
        
    `} */

    ${props => props.fixed ? css` 
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        margin-top: 80px !important;
        
    ` : css` 
        
        /* /* content: ''; */
        @media (max-width: 600px) {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 3;
        }
        @media (min-width: 601px) { */
            /* display: inline-block; */
            min-width: 275px;

    
        }
    `}
    
    
    /* @media (max-width: 600px) {
        ${SideBar} {
            text-align: center;
        }
        ${ListItem} {
            margin-left: 25px;
        }
    }
    
    ${props => props.mobile && css` 
        ${SideBar} {
            @media (max-width: 600px){
                width: 100%;
            }
        }
    `} */

/* 
    .sideBarTrigger {
        cursor: pointer;
        font-size: 14px;
        margin: 5px 6px;
    } */
    ${layout}
    ${space}
`


const SideBarStyles = {
    SideBarContainer,
    SideBarToggle,
    SubSideBar,
    SideBar,

    List,
    ListMenu,

    ListHeader,

    ListItem,
    ListMenuItem,

    Divider,

    ListItemIcon
}
export default SideBarStyles;