

import { capitalizeFirst, toLowCase } from "@util/Text";


const controlVariant = (controlProps) => ({
    value: 'control',
    label: 'Controlled item',
    type: 'single', //auto
    options: Object.keys(controlProps.control)?.map(variant => ({
        value: `control-${toLowCase(variant)}`,
        label: capitalizeFirst(variant)
    }))
})

const getActiveControl = (controlProps) =>  {
    const active = Object.entries(controlProps.control)?.find(([key, state]) => key === 'active' && state);
    return active ? `control-true` : 'control-false'
}
 
const appendControlProps = (item, controlProps) => ({
    ...item,
    variants: item?.variants ? [
        ...item.variants, controlVariant(controlProps)
    ] : [ controlVariant(controlProps) ],
    state: {
        ...item?.state,
        control: getActiveControl(controlProps),
    }
});

const appendLocaleProp = (item, locale) => ({
    ...item,
    state: {
        ...item?.state,
        locale: `local-${locale}`,
    } 
})

export const appendControl = (item, controlProps, locale) => {
    if(controlProps?.control) item = appendControlProps(item, controlProps)
    if(locale) item = appendLocaleProp(item, locale)
    return item;
}
