
export const isNumber = value => typeof value === 'number' && isFinite(value);

const biteSizeToMb = (bytes) => bytes ? parseFloat(bytes) / 1000000 : bytes;

const sumNumberArray = (arr) => {
    let sum = 0;
    arr.forEach(stringNumber => {
        if(isNumber(stringNumber)){ 
            sum += stringNumber;
        } else {
            sum += parseFloat(stringNumber);
        }
    });
    return sum;
}

const removeNumbers = (string) => string ? string.toString().replace(/[0-9]/g, '') : false;



export {
    sumNumberArray,
    removeNumbers,
    biteSizeToMb
};

