import { WrapInput } from "../../items/Inputs";
// import { Select as Selector } from "./External";

import { useState } from "react";
import { default as Picker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { BaseField, InputStyles as s } from "../Input";
import { colors } from "@mosanic/styles";
import { containsSubstr } from "@util/Text";
import { removeUndefined } from "@util/Obj";

const Field = Picker

    /* ${BaseField} */
    

const DatePicker = ({
    //Essential
    name = '',
    value = null,
    onChange = null,

    //Overwrite
    label = null,

    //Styling
    fullWidth = false,
    small = null,
    size = null,
    wrap = false, 
    center = false,
    
    //Specific
    defaultValue = null,

    //Validation
    valid = null,
    empty = false,
    disabled = false,
    required = false,

    innerRef = null,
    
    noCase= false,
    timeOnly = false,

    range = false,
    ...other
}) => {
    const [focussed, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);



    return (
        <WrapInput wrap={wrap ? wrap : false} fullWidth={fullWidth}> 
            <s.Container 
                className="input_field"
                focussed={focussed}
                disabled={disabled}
                valid={valid} 
                empty={empty}
                size={size}
                center={center}
            >
                <s.Label shrink={Boolean(innerRef?.current || value || focussed)} noCase={noCase}>
                    {
                        label ? label :
                        name ? name : ""
                    }
                    
                    {required && <s.Required />}
                </s.Label>

               
                <Field 
                    className={`noborder`}
                    style={{border: 'none'}}
                    ref={innerRef}
                    fullWidth={fullWidth}
                    size={small ? 'small' : size ? size : null}
                    {...other}
                    
                    disabled={disabled}
                    minDate={new Date()}
                    selected={range ? (
                        Array.isArray(range) ? 
                            (value?.[0] || null) : 
                            null
                    ) : (
                        containsSubstr(value, 'T') ? new Date(value.split('T')[0]) : value || null
                    )}
                    
                    onChange={val => range ? onChange(val) : onChange(removeUndefined(val))}
                    onFocus={(e) => {
                        if(other?.onFocus) other.onFocus(e)
                        onFocus();
                    }} 
                    onBlur={(e) => {
                        if(other?.onBlur) other.onBlur(e)
                        onBlur();
                    }}
                    selectsRange={range}

                    startDate={range ? (
                        Array.isArray(value) ? 
                            (value?.[0] || new Date()) : 
                            new Date() 
                    ) : null}
                    endDate={range ? (
                        Array.isArray(value) ? 
                            (value?.[1] || new Date()) : 
                            new Date() 
                    ) : null}
                    // onBlur={(e) => {
                    //     if(other?.onBlur) other.onBlur(e)
                    //     onBlur();
                    // }}
                    calendarClassName={`small-calendar ${timeOnly ? 'timeOnly' : ''}`}
                />
            <style>
                {`
                    .small-calendar.timeOnly .react-datepicker__month-container {display: none;}
                    .noborder {width: 100%; border: none !important;font-size: 12px; background: transparent;}.noborder:focus {outline: none !important;}
                    .small-calendar {transform: scale(0.9); transform-origin: bottom center; padding: 10px; border: 1px solid  ${colors.util.gray.base};}
                    .small-calendar .react-datepicker__day--outside-month {opacity: 0.4;}
                    .small-calendar .react-datepicker__day--outside-month:hover {opacity: 0.9;}
                    .small-calendar .react-datepicker__day--selected,
                    .small-calendar .react-datepicker__time-list-item--selected {background-color: ${colors.util.primary.dark}!important; font-weight: bold; }
                    .small-calendar .react-datepicker__time-list-item--selected {border-radius: 5px; transform: scale(0.9);font-size: 14px; line-height: 18px;width: 90%;margin-left: 5%;}
                    .small-calendar .react-datepicker__header {background: none; border: none; }
                    .small-calendar .react-datepicker__navigation {margin: 15px 0;transform-origin: center center; transform: scale(0.8);}
                    .small-calendar .react-datepicker__time-container {border: none; margin-left: 5px;}
                    .small-calendar .react-datepicker__triangle {display: none;}
                `}
            </style>
            </s.Container>
        </WrapInput>
    )
}
export default DatePicker;