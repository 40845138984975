import { SITE } from "@api/queries/sites";
import { useGet } from "./useDataQuery";

import { COLLECTION } from "@api/queries/collections";
import { TEAM } from "@api/queries/teams";
import { MAIL } from "@api/queries/mails";
import { USER } from "@api/queries/users";



const useGetItem = ({params, query, type, ...other}) => {
    const [get, [data, loading, error]] = useGet({
        params,
        method: 'ById',
        query,
        type,
        ...other
    });

    return [data, loading, error, get]
}


export const useGetCollection = ({_id, ...other}) => useGetItem({
    params: {_id},
    query: COLLECTION,
    type: 'collection',
    ...other
});

export const useGetSite = ({_id, ...other}) => useGetItem({
    params: {_id},
    query: SITE,
    type: 'site',
    silent: true,
    ...other
});

export const useGetTeam = ({_id}) => useGetItem({
        query: TEAM,
        type: 'team',
        params: {_id},
        silent: true
    })

export const useGetMail = ({_id, ...other}) => useGetItem({
    params: {_id},
    query: MAIL,
    type: 'mail',
    ...other
})

export const useGetUser = ({_id, ...other}) => useGetItem({
    params:{_id},
    query: USER,
    type: 'user',
    silent: true,
    ...other
})

// export const useGetMediaItem = ({_id}) => useGetItem({
//     query: PAGINATION_MEDIA,
//     params: siteId ? {filter: {siteId}, page, perPage} : null,
//     method: 'Pagination',
//     type: 'media',
// })


