import { AdvancedLabel, ControlPopper, Label } from "@mosanic/items";
import styled, {css} from "styled-components";
import Button, { Buttons } from "../Button/Buttons";
import { ArrowRightIcon, DropdownMenuIcon } from "@radix-ui/react-icons";
import { colors } from "@mosanic/styles";
import { lighten } from "polished";
import Select from "../Select/Select";
import TextField from "../TextField/Fields";
import { useCallback, useEffect, useState } from "react";
import { get } from "@util/Obj";
import print_r from "src/components/Builder/Util/print_r";
import dynamic from "next/dynamic";
import { updateItem } from "@util/Arr";
import { addUnset } from "@mosanic/_Fieldsets";
import { ControlTabs } from "@mosanic/items/Control/ControlButtons";

const ReactJson = dynamic(() => import('react-json-view'), {
  ssr: false
})

const Table = styled.table` 
    margin: 5px 0 15px;
    width: 100%;

    th, td { 
        font-size: 12px;
        border: 1px solid #eee;
        padding: 5px;
    }
`
const Tr = styled.tr` 
    ${props => props.warning && css` 
        background: ${lighten(0.1, colors.util.orange.lighter)};
    `}
`

const TableHeader = ({hasPointer = false}) => (
    <tr>
        <th>
            Current
        </th>
        <th>
            Option
        </th>
        {hasPointer && (
            <th>
                Found
            </th>
        )}
        <th style={{width: '100px'}}>
            Convert 
        </th>
    </tr>
)

const TableFooter = ({converted, value, conversion, update, disabled, options}) => {
    const [tab, setTab] = useState('conversion')
    return (
        <tr>
            <th colSpan={2}>
                <Buttons>
                    <ControlPopper trigger={<Button label="View conversion" ghost/>} width="100%" maxWidth="70vw">
                        <ControlTabs mb={2} active={tab} setActive={setTab} options="conversion|start data|parsed data"/>
                        {tab === 'conversion' ? (
                            Array.isArray(converted) ? converted?.map(o => (
                                <span>
                                    {o?.index} {JSON.stringify(o.origin)} {`>`} {JSON.stringify(o?.overwrite?.value ? o.overwrite : o.convert)}
                                    <br/>
                                </span>
                            )) : JSON.stringify(converted)
                        ) : tab === 'start data' ? (
                            JSON.stringify(value)
                        ) : (
                            Array.isArray(conversion) ? 
                                ['[', ...conversion.map(c => ` ${c} , `), ']'].map(id => (<span key={id}>{id} <br/></span>)) : 
                                JSON.stringify(conversion)
                        )}
                    </ControlPopper>
                    {options && (
                        <ControlPopper trigger={<Button label="View pointer options" ghost/>} width="100%">
                            <ReactJson name='source' src={options} collapsed/>
                        </ControlPopper>
                    )}
                </Buttons>
            </th>
            <th>
                <Button mt={1} theme="primary" label="Convert" onClick={update} ghost disabled={disabled}/>
            </th>
                    </tr>
    )
}
 
export const PreviewDataTable = ({value, options, label = null, data = null, getLabel = null, onChange}) => {
    const findOption = useCallback(o => options?.find(option => option?.label === o?.value || option?.label === o?.label),[options]);
    const [pointer, setPointer] = useState('')

    const props = {value, options, findOption, label, data, getLabel, onChange, setPointer, pointer};

    if(Array.isArray(value)) return <ArrayDataTable {...props} />
    return <SingleDataTable {...props} />
}

const SingleDataTable = ({
    value, 
    options, 
    findOption, 
    label, 
    data, 
    getLabel, 
    onChange,
    setPointer,
    pointer
}) => {
    const o = findOption({
        value: value?.value ? value.value : value,
        label: value?.value ? value.value : value
    })
    const option = data?.find(option => get(option, pointer) === value);

    const converted = [{
        origin: value,
        option,
        convert: {
            label: getLabel ? getLabel(option) : option?.label ? option.label : option,
            value: option?._id ? option._id : option?.value ? option.value : option 
        }
    }], conversion = option?._id ? option._id : option?.value ? option.value : option;
    const update = () => onChange(conversion)
    return (
        <Table>
            <TableHeader hasPointer={pointer} />

            <DataRow origin={{label, value}} option={
                option
            } getLabel={getLabel}  setPointer={setPointer} pointer={pointer} options={addUnset(options)} update={val => update(val, index)}/>


            <TableFooter 
                {...{converted, conversion, value, options: data}}
                disabled={converted?.filter(o => !o?.option)?.length >= 1}
                update={update}
            />
        </Table>
    )
}

const ArrayDataTable = ({
    value, 
    options, 
    findOption, 
    label, 
    data, 
    getLabel, 
    onChange,
    setPointer,
    pointer
}) => {


    const formatOption = useCallback(option => !getLabel ? option : {
        ...option,
        label: getLabel(option),
    },[getLabel])

    //Covert 
    const convertValue = useCallback((o, index) => {
        const option = findOption(o) || data?.find(option => get(option, pointer) === value);
        return {
            index,
            origin: o,
            option,
            convert: formatOption(option),
            overwrite: null,
        }
    },[
        data, 
        findOption, 
        formatOption, 
        pointer, 
        value
    ])

    const convertData = useCallback(() => ( 
        value?.map((val, index) => convertValue(val, index)) 
    ),[convertValue, value])

    const [converted, setConverted] = useState(convertData())

    const update = (val, index) => setConverted(
        updateItem(converted, index, {
            ...converted[index],
            overwrite: formatOption(options?.find(o => o?.value === val))
    
        })
    )
    

    useEffect(() => {
        let timer = null;
        timer = setTimeout(() => {
            setConverted(convertData())
        }, 500);
        return () => clearTimeout(timer)
    }, [convertData, pointer])

    const conversion = converted?.map(o => 
        o?.overwrite?.value ? o?.overwrite.value : 
        o?.option?.value ? o?.option.value : 
        o?.origin?.value ? o.origin.value : 
        o.origin
    )


    const updateAll = () => {
        onChange(conversion)
    }
    return (
        <Table>
            <TableHeader hasPointer={pointer} />

            {converted?.map((o, index) => (
                <DataRow {...o} getLabel={getLabel} setPointer={setPointer} pointer={pointer} options={addUnset(options)} update={val => update(val, index)}/>
            ))}
        
            <TableFooter 
                {...{converted, conversion, value, options}}
                disabled={converted?.filter(o => !o?.option && !o?.overwrite?.value)?.length >= 1}
                update={updateAll}
            />
        </Table>
    )
}

const RenderOption = ({option, label = "Matched to:", ...other}) => (
    <AdvancedLabel span label={option?.label+' '} helpText={`${label} ${option?.value}`} {...other} />
)

const DataRow = ({option, origin, options, overwrite, pointer, setPointer, getLabel, update}) => (
    <Tr warning={!option}>
        <td>
            <Label label={`${origin?.label}:`} value={origin?.value}/>
        </td>
        <td>
            {((overwrite || option) && !pointer) ? (
                <>
                <RenderOption option={option} style={{opacity: overwrite?.value ? 0.2 : 1}}/>
                {overwrite?.value && (
                    <RenderOption option={overwrite} label="Overwrite set to:" />
                )}
                </>
            ) : (
                <TextField label="Set manual pointer" value={pointer} onChange={e => setPointer(e.target.value)} />
            )}
        </td>
        {pointer && (
            <td>
                <AdvancedLabel span label={getLabel(option)} />
            </td>
        )}
        <td>
            <Buttons>
                <ControlPopper overflow p={0} trigger={<Button theme={!option && 'warning'} icon={<DropdownMenuIcon />} ghost/>}>
                    <Select searchable label="Link" options={options} value={overwrite?.value} onChange={update} />
                </ControlPopper>
                {/* <Button icon={<ArrowRightIcon />} ghost disabled={!option} /> */}
            </Buttons>
        </td>
    </Tr>
)
