import { ThemeProvider } from "styled-components";

import { usePrefColorSchema } from '@hooks/usePrefColorSchema';
import { MosanicTheme } from '@mosanic/styles';
import { colorTemplates } from '@util/theme';
import { isServer } from "@util/Device";
import { useSelector } from "react-redux";
import { themeModeSelector } from "@redux/ui/uiReducer";


const getTheme = (mode) => ({
  ...MosanicTheme,
  mode,
  alt: mode === 'dark' ? 'light' : 'dark',
  colors: colorTemplates?.[mode]
})

const ThemeWrapper = ({ children }) => {
    const savedAutomatic = !isServer() ? localStorage.getItem('automaticColorScheme') : null;
  const savedPreferred = !isServer() ? localStorage.getItem('preferredColorScheme') : null;
  const pref = savedPreferred || savedAutomatic
  const mode = useSelector(themeModeSelector)
  return (
    <ThemeProvider theme={pref === 'dark' ? getTheme('light') : getTheme('light')}>  
    {/* // todo add dark switch */}
      {children}
    </ThemeProvider>
  );
}

export default ThemeWrapper;
