import { Condition, If } from '@JSX';
import { useControlPopper } from '@mosanic/hooks/useControlPopper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import dynamic from 'next/dynamic';
import Popper from '@mui/material/Popper';
// const ClickAwayListener = dynamic(() => import('@mui/material/ClickAwayListener') )
// const Popper = dynamic(() => import('@mui/material/Popper') )
import { isNumber } from '@util/Num';

import { Fragment, useCallback, useState } from 'react';

import styled, {css} from 'styled-components';
import { ControlPanel } from './Parts';
import { usePopper } from 'react-popper';
import { colors } from '@mosanic/styles';
import { prevent } from '@util/Event';

const Trigger = styled.span``;
const PopperContent = styled.div``;

const Spacing = css` 
  background: #ffffff;
  padding: 5px;
  right: 0;
  left: 0px;
  /* position: fixed; */
  width: 100%;
  margin-bottom: 0px;
  height: 45px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  :is(h1, h2, h3, h4, h5) {
    margin-bottom: 0px;
  }
`
const Header = styled.div` 
  border-bottom: 1px solid ${colors.util.gray.light};
  ${Spacing}
`
const Footer = styled.div` 
  border-top: 1px solid ${colors.util.gray.light};
  ${Spacing}
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  right: 25px;
  padding-top: 10px;
  position: relative;
  /* bottom: -5px; */
`

const ScrollContainer = styled.div` 
  height: ${props => props.height ?  ( props.hasHeaders ? parseFloat(props.height) - 100 : parseFloat(props.height))+'px'  : '200px'};
  overflow: auto;
  /* bottom: -5px; */
  position: relative;
`

const isSameTarget = (anchor, event) => (anchor?.current && anchor?.current.contains(event.target));
const getMaxIndex = (maxIndex) => isNumber(maxIndex) ? {zIndex: maxIndex} : (!maxIndex ? {zIndex: 55}:{zIndex: 9999});

//Control automatic
const ControlPopper = ({
  anchor = null, 
  close = () => null, 
  customClass = '', 
  bigShadow = false, 
  maxIndex = false, 
  disablePortal = false, 
  placement = "bottom", 
  trigger = null, 
  reversed = false, 
  hover = false,  
  clickStick = false,
  header = null,
  footer = null,
  scroll = false,
  overflow = null,
  contextOnly = false,
  children,  
  ...other
}) => {

  const handler = useControlPopper();
  const open = (event) => handler.open(event);

  if(trigger){
    anchor = handler.anchor;
    close = (event) => handler.close(event);
  }
  const canBeOpenZoom = Boolean(anchor);

  const handleCloseZoom = useCallback((event) => {
    if(isSameTarget(anchor, event)) return;
    if(close) close();
  },[anchor, close]);

  return (
  <Fragment>
    <If condition={Boolean(trigger)}>
      <span
      onContextMenu={e => {
          if(!contextOnly) return
          prevent(e);
          open(e);
       }}
        onMouseEnter={(e) => hover ? open(e) : null}
        onMouseLeave={(e) => hover ? close(e) : null}
        onClick={e => {
          prevent(e);
          if(contextOnly) return;
          anchor ? close() : open(e)
        }} 
        style={{cursor:'pointer'}} 
        key="trigger" 
        className="triggerPopper"
      >
        {trigger}
      </span>
    </If>
    <Popper 
      open={canBeOpenZoom} 
      key="ControlPopper"
      id="controlPopper"
      anchorEl={anchor}  
      disablePortal={disablePortal} 
      placement={placement} 
      // transition 
      style={{...getMaxIndex(maxIndex)}}
      // ref={setPopperElement} style={styles.popper} {...attributes.popper}
    >
      {/* {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          style={{transformOrigin: 'right'}} 
          timeout={350}
        >  */}
          <div>
            <ClickAwayListener onClickAway={handleCloseZoom}>
              <div>
                <ControlPanel 
                  extraClass={customClass} 
                  bigShadow={bigShadow} 
                  reversed={reversed} h
                  asHeader={header} 
                  hasFooter={footer} 
                  overflow={overflow} 
                  {...other}
                >
                  {header && (
                    <Header>
                      {header}
                    </Header>
                  )}
                  <Condition when={scroll} wrapper={children => (
                    <ScrollContainer hasHeaders={header && footer} height={other?.height}>
                      {children}
                    </ScrollContainer>
                  )}>
                    {children}
                  </Condition>
                  {header && (
                    <Footer>
                      {footer}
                    </Footer>
                  )}
                </ControlPanel>
              </div>
            </ClickAwayListener>  
          </div>
        {/* </Fade>
      )} */}
    </Popper>
  </Fragment>
  );
};
export default ControlPopper;
