export const THEME_ID                 = `[theme]`;

const SET       = `${THEME_ID} Set`;

const THEME_ACTION_IDS = {
    SET
}
export const setThemeAction = (data) => ({
    type: SET,
    payload: data,
    meta: {feature: THEME_ID,}
})

export default THEME_ACTION_IDS;