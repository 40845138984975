
const editPencil = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M11.854 1.146a.5.5 0 0 0-.707 0L3.714 8.578a1 1 0 0 0-.212.314L2.04 12.303a.5.5 0 0 0 .657.657l3.411-1.463a1 1 0 0 0 .314-.211l7.432-7.432a.5.5 0 0 0 0-.708l-2-2Zm-7.432 8.14L11.5 2.206 12.793 3.5l-7.078 7.078-1.496.641-.438-.438.64-1.496Z" fill="#000"/></svg>
const editPencilBorder = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M12.146 1.146a.5.5 0 0 1 .707 0l2 2a.5.5 0 0 1 0 .708l-3.942 3.942a1 1 0 0 1-.26.188L6.724 9.947a.5.5 0 0 1-.671-.67l1.963-3.928a1 1 0 0 1 .188-.26l3.942-3.943Zm.354 1.061-3.59 3.59-1.037 2.076.254.254 2.077-1.038L13.793 3.5 12.5 2.207ZM10 2 9 3H4.9c-.428 0-.72 0-.944.019-.22.018-.332.05-.41.09a1 1 0 0 0-.437.437c-.04.078-.072.19-.09.41C3 4.18 3 4.472 3 4.9v6.2c0 .428 0 .72.019.944.018.22.05.332.09.41a1 1 0 0 0 .437.437c.078.04.19.072.41.09.225.019.516.019.944.019h6.2c.428 0 .72 0 .944-.019.22-.018.332-.05.41-.09a1 1 0 0 0 .437-.437c.04-.078.072-.19.09-.41.019-.225.019-.516.019-.944V7l1-1V11.12c0 .403 0 .735-.022 1.006-.023.281-.072.54-.196.782a2 2 0 0 1-.874.874c-.243.124-.501.173-.782.196-.27.022-.603.022-1.005.022H4.88c-.403 0-.735 0-1.006-.022-.281-.023-.54-.072-.782-.196a2 2 0 0 1-.874-.874c-.124-.243-.173-.501-.196-.782C2 11.856 2 11.523 2 11.12V4.88c0-.403 0-.735.022-1.006.023-.281.072-.54.196-.782a2 2 0 0 1 .874-.874c.243-.124.501-.173.782-.196C4.144 2 4.477 2 4.88 2H10Z" fill="#000"/></svg>

const circlePlus = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.5.877a6.623 6.623 0 1 0 0 13.246A6.623 6.623 0 0 0 7.5.877ZM1.827 7.5a5.673 5.673 0 1 1 11.346 0 5.673 5.673 0 0 1-11.346 0ZM7.5 4a.5.5 0 0 1 .5.5V7h2.5a.5.5 0 1 1 0 1H8v2.5a.5.5 0 0 1-1 0V8H4.5a.5.5 0 0 1 0-1H7V4.5a.5.5 0 0 1 .5-.5Z" fill="#000"/></svg>
const plus = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8 2.75a.5.5 0 0 0-1 0V7H2.75a.5.5 0 0 0 0 1H7v4.25a.5.5 0 0 0 1 0V8h4.25a.5.5 0 0 0 0-1H8V2.75Z" fill="#000"/></svg>
const circleMin = <svg version="1.1" viewBox="0 0 15 15" width="15" height="15"  xmlns="http://www.w3.org/2000/svg" ><path d="M7.49991,0.876892c-3.65769,0 -6.62284,2.96515 -6.62284,6.62283c4.44089e-16,3.65768 2.96515,6.62288 6.62284,6.62288c3.65769,0 6.62279,-2.9652 6.62279,-6.62288c0,-3.65768 -2.9651,-6.62283 -6.62279,-6.62283Zm-5.67284,6.62283c0,-3.13301 2.53982,-5.67283 5.67284,-5.67283c3.13299,2.22045e-16 5.67279,2.53982 5.67279,5.67283c0,3.13298 -2.5398,5.67288 -5.67279,5.67288c-3.13302,0 -5.67284,-2.5399 -5.67284,-5.67288Zm2.67296,-0.49972c-0.27614,0 -0.5,0.22386 -0.5,0.5c0,0.27614 0.22386,0.5 0.5,0.5h5.99997c0.2762,0 0.5,-0.22386 0.5,-0.5c0,-0.27614 -0.2238,-0.5 -0.5,-0.5h-5.99997Z" fill="#000" fillRule="evenodd"></path></svg>
const minus = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" fill="black"></path></svg>;;

const hide = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" d="M13.354 2.354a.5.5 0 0 0-.708-.708L10.683 3.61A8.475 8.475 0 0 0 7.5 3C4.308 3 1.656 4.706.076 7.235a.5.5 0 0 0 0 .53c.827 1.323 1.947 2.421 3.285 3.167l-1.715 1.714a.5.5 0 0 0 .708.708l1.963-1.964c.976.393 2.045.61 3.183.61 3.192 0 5.844-1.706 7.424-4.235a.5.5 0 0 0 0-.53c-.827-1.323-1.947-2.421-3.285-3.167l1.715-1.714Zm-3.45 2.035A7.517 7.517 0 0 0 7.5 4C4.803 4 2.53 5.378 1.096 7.5c.777 1.15 1.8 2.081 3.004 2.693L9.904 4.39ZM5.096 10.61 10.9 4.807c1.204.612 2.227 1.543 3.004 2.693C12.47 9.622 10.197 11 7.5 11a7.518 7.518 0 0 1-2.404-.389Z" fill="#000"/></svg>
const view = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" d="M7.5 11c-2.697 0-4.97-1.378-6.404-3.5C2.53 5.378 4.803 4 7.5 4s4.97 1.378 6.404 3.5C12.47 9.622 10.197 11 7.5 11Zm0-8C4.308 3 1.656 4.706.076 7.235a.5.5 0 0 0 0 .53C1.656 10.294 4.308 12 7.5 12s5.844-1.706 7.424-4.235a.5.5 0 0 0 0-.53C13.344 4.706 10.692 3 7.5 3Zm0 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#000"/></svg>
const hidden = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M14.765 6.076a.5.5 0 0 1 .159.689 9.523 9.523 0 0 1-1.554 1.898l1.201 1.201a.5.5 0 1 1-.707.707l-1.263-1.263a8.472 8.472 0 0 1-2.667 1.343l.449 1.677a.5.5 0 1 1-.966.259l-.458-1.71a8.666 8.666 0 0 1-2.918 0l-.458 1.71a.5.5 0 1 1-.966-.26l.45-1.676a8.475 8.475 0 0 1-2.668-1.343l-1.263 1.263a.5.5 0 1 1-.707-.707l1.2-1.2A9.52 9.52 0 0 1 .077 6.764a.5.5 0 1 1 .848-.53 8.426 8.426 0 0 0 1.77 2.034A7.462 7.462 0 0 0 7.5 10c2.808 0 5.156-1.494 6.576-3.765a.5.5 0 0 1 .689-.159Z" fill="#000"/></svg>

const trash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" d="M5.5 1a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4ZM3 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H11v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4h-.5a.5.5 0 0 1-.5-.5ZM5 4h5v8H5V4Z" fill="#000"/></svg>
const greenTrash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M5.5 1a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4ZM3 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H11v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4h-.5a.5.5 0 0 1-.5-.5ZM5 4h5v8H5V4Z" fill="#2bd67b"/></svg>;

const cut = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M3 2.5a.5.5 0 0 1 .5-.5h5.793L12 4.707V12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-10ZM3.5 1A1.5 1.5 0 0 0 2 2.5v10A1.5 1.5 0 0 0 3.5 14h8a1.5 1.5 0 0 0 1.5-1.5V4.604a.75.75 0 0 0-.22-.53L9.854 1.145A.5.5 0 0 0 9.5 1h-6Zm1.75 6a.5.5 0 0 0 0 1h4.5a.5.5 0 0 0 0-1h-4.5Z" fill="#000"/></svg>;
const removeFile = cut;
const copy = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M5 2V1h5v1H5Zm-.25-2A.75.75 0 0 0 4 .75V1h-.5A1.5 1.5 0 0 0 2 2.5v10A1.5 1.5 0 0 0 3.5 14H7v-1H3.5a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5H4v.25c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75V2h.5a.5.5 0 0 1 .5.5V7h1V2.5A1.5 1.5 0 0 0 11.5 1H11V.75a.75.75 0 0 0-.75-.75h-5.5ZM9 8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm.5 1.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-.5 2.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm0 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-6-4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm.5 1.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM8.5 15a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" fill="#000"/></svg>
const copyFile = copy;
const paste = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M3.5 2a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V4.707L9.293 2H3.5ZM2 2.5A1.5 1.5 0 0 1 3.5 1h6a.5.5 0 0 1 .354.146l2.926 2.927c.141.14.22.332.22.53V12.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 2 12.5v-10Zm2.75 5a.5.5 0 0 1 .5-.5H7V5.25a.5.5 0 0 1 1 0V7h1.75a.5.5 0 0 1 0 1H8v1.75a.5.5 0 0 1-1 0V8H5.25a.5.5 0 0 1-.5-.5Z" fill="#000"/></svg>;
const pasteFile = paste;

const save = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M7.5 1.05a.45.45 0 0 1 .45.45v6.914l2.232-2.232a.45.45 0 1 1 .636.636l-3 3a.45.45 0 0 1-.636 0l-3-3a.45.45 0 1 1 .636-.636L7.05 8.414V1.5a.45.45 0 0 1 .45-.45ZM2.5 10a.5.5 0 0 1 .5.5V12c0 .554.446 1 .996 1h7.005A.999.999 0 0 0 12 12v-1.5a.5.5 0 0 1 1 0V12c0 1.104-.894 2-1.999 2H3.996A1.997 1.997 0 0 1 2 12v-1.5a.5.5 0 0 1 .5-.5Z" fill="#000"/></svg>;
const download = save;
const duplicate = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M1 9.5A1.5 1.5 0 0 0 2.5 11H4v-1H2.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V4H5.5A1.5 1.5 0 0 0 4 5.5v7A1.5 1.5 0 0 0 5.5 14h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 12.5 4H11V2.5A1.5 1.5 0 0 0 9.5 1h-7A1.5 1.5 0 0 0 1 2.5v7Zm4-4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7Z" fill="#000"/></svg>;

const expand = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path d="M11.5 3.05a.45.45 0 0 1 .45.45v4a.45.45 0 0 1-.9 0V4.586L4.586 11.05H7.5a.45.45 0 0 1 0 .9h-4a.45.45 0 0 1-.45-.45v-4a.45.45 0 1 1 .9 0v2.914l6.464-6.464H7.5a.45.45 0 1 1 0-.9h4Z" fill="#000"/></svg>

const dotOpen = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.5 9.125a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25Zm0 1a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25Z" fill="#000"/></svg>
const warningIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="alert-triangle"><path d="M23 16 15 4a3 3 0 0 0-6 0L1 16a3 3 0 0 0 0 3 3 3 0 0 0 3 2h16a3 3 0 0 0 3-2 3 3 0 0 0 0-3zm-2 2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1 1 1 0 0 1 0-1l8-12a1 1 0 0 1 2 0l8 12a1 1 0 0 1 0 1z"/><circle cx="12" cy="16" r="1"/><path d="M12 8a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V9a1 1 0 0 0-1-1z"/></g></g></svg>

const bookmarkIcon = <svg version="1.1" viewBox="0 0 15 15" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M3,2.5c0,-0.27614 0.22386,-0.5 0.5,-0.5h8c0.2761,0 0.5,0.22386 0.5,0.5v11c0,0.1818 -0.0986,0.3492 -0.2576,0.4373c-0.159,0.0881 -0.3533,0.083 -0.5074,-0.0133l-3.735,-2.3344l-3.735,2.3344c-0.15413,0.0963 -0.34841,0.1014 -0.50738,0.0133c-0.15898,-0.0881 -0.25762,-0.2555 -0.25762,-0.4373v-11Zm1,0.5v9.5979l2.97,-1.8563c0.32427,-0.2026 0.73573,-0.2026 1.06,0l2.97,1.8563v-9.5979h-7Z" fill="#000" fillRule="evenodd"></path></svg>

const EditIcons = {
    editPencil,
    editPencilBorder,
    
    circlePlus,
    plus,
    circleMin,
    minus,

    //Eyes
    hide,
    view,
    hidden,

    trash,
    greenTrash,

    cut,
    removeFile,
    copy,
    copyFile,
    paste,
    pasteFile,

    save,
    download,
    duplicate,
    expand,

    dotOpen,
    warningIcon,
    bookmarkIcon
}

export {
    bookmarkIcon,
    editPencil,
    editPencilBorder,

    circlePlus,
    plus,
    circleMin,
    minus,

    //Eyes
    hide,
    view,
    hidden,

    trash,
    greenTrash,

    cut,
    removeFile,
    copy,
    copyFile,
    paste,
    pasteFile,

    save,
    download,
    duplicate,
    expand,

    dotOpen,
    warningIcon
}
export default EditIcons;