

import { ENTRIES_MAP, ENTRY, FILTER_ENTRIES } from "@api/queries/entries";
import { useLazyQuery } from "@hooks/useApi";
import { useEffect } from "react";

export const convertEntry =  entry => ({...entry, ...entry?.data, ...entry?.map, map: undefined, data: undefined })
export const useEntry = ({_id}) => {
    let [handler, [entry, loading, error]] = useLazyQuery('entryById', ENTRY, {_id}) 
    
    useEffect(() => {
        if(_id) handler({_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_id])

    return [handler, [entry, loading, error]]; 
}


export const useEntries = ({collectionId = null, filter = undefined, limit = undefined,  skip = null, minimize = false}) => {
    const [callback, [entries, loading, error]] = useLazyQuery('entryMany', minimize ? ENTRIES_MAP : FILTER_ENTRIES)
    useEffect(() => {
     
        if(collectionId) callback({filter: {collectionId}, limit, skip})
        else if(filter)  {
            callback({filter, limit, skip})
        }
         //todo limit: 5
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[collectionId, filter])

    return [callback, [entries, loading, error]];
}
