
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
   
};

const updateReducer = (update = initialState, action) => produce(update, draft => {

    switch (action.type) {
        case HYDRATE:
            return {...update, ...action.payload};

       
        default:
            return update;
    }; //switch
});
export default updateReducer;
