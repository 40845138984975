
import { css } from "styled-components"; 

export const Transitions = (timing = 0.3, prop = 'all') => ({
    easeInOut: css`transition: ${prop} ${timing}s cubic-bezier(0.42, 0, 0.58, 1);`,
    ease: css`transition: ${prop} ${timing}s cubic-bezier(0.25, 0.1, 0.25, 1);`,
    easeIn: css`transition: ${prop} ${timing}s cubic-bezier(0.42, 0, 1, 1);`,
    easeOut: css`transition: ${prop} ${timing}s cubic-bezier(0, 0, 0.58, 1);`,
    linear: css`transition: ${prop} ${timing}s cubic-bezier(0, 0, 1, 1);`,
})

