import { gql } from '@apollo/client';;


export const COLLECTIONS_COUNT = gql` 
query collectionCount($filter:FilterCountCollectionInput) {
  collectionCount(filter:$filter) 
}
`

export const COLLECTIONS = gql`
query collectionMany{
	collectionMany {
		title

		# singular
      	description
		catchRoutes
		baseRoute
		dynamicPage
		config
		schema
		entries
		excerpt 
		date 
		updatedAt 
		status 
		userId 
		siteId 
		_id
	}
}
`;

export const FILTER_COLLECTIONS = gql`
query collectionMany($filter: FilterFindManyCollectionInput, $limit: Int, $skip: Int, $sort: SortFindManyCollectionInput) {
	collectionMany(filter: $filter, limit: $limit, skip: $skip, sort: $sort){
		title
		singular
		type
		description
		routing {
			catchRoutes
			frontEnd
			baseRoute
			editRoute
			indexPage
			editPage
			notFoundPage
			dynamicPage
		}
		catchRoutes
		baseRoute
		editRoute 
		editPage
		dynamicPage
		schema
		entries
		excerpt 
		date 
		updatedAt 
		status 
		userId 
		siteId 
		_id
	}
}
`;
export const FILTER_COLLECTIONS_INDEX = gql`
query collectionMany($filter: FilterFindManyCollectionInput) {
	collectionMany(filter: $filter){
		title
		singular
		type
		description
		catchRoutes
		baseRoute
		dynamicPage
		schema
		entries
		excerpt 
		date 
		updatedAt 
		status 
		userId 
		siteId 
		_id
	}
}
`;
export const COLLECTION = gql`
query collectionById($_id: MongoID!) {
	collectionById(_id: $_id){
		title
		routing {
			catchRoutes
			frontEnd
			baseRoute
			editRoute
			indexPage
			editPage
			notFoundPage
			dynamicPage
		}
		singular
      	description
		catchRoutes
		baseRoute
		dynamicPage
		config
		schema
		entries
		excerpt 
		date 
		updatedAt 
		status 
		userId 
		siteId 
		_id
	}
}
`;
