import { CenterCenter } from "@mosanic/styles";
import { Card } from "@mosanic/styles/card.styles";
import { RowWrap } from "@mosanic/styles/flex.styles";
import styled, { css } from "styled-components";
import { color, layout, space } from 'styled-system';

export const CoreWrapper = styled.div` 
    /* overflow-x: hidden; */
    padding-top: ${props => props.navbar}; 
    min-height: 100vh;

    display: flex;
    height: 100%;
    direction: ltr;
    width: 100%;
    background: red;

    ${props => props.isApp && css` 
        @media (min-width: 600px){
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }
    `}
`
const Container = css` 
    max-width: 100%;
`;

const Content = styled.div` 
    ${Container}
    ${props => props.center && css` 
        ${Card} {
            margin: 0 auto;
        }
    `}
    ${layout}
    ${space}
`
const Nav = styled.div` 
    width: 100%;
    height: 44px;
    padding: 6px 5px;
    background:  ${props => props.theme.colors?.white};
    border-bottom: 0.5px solid #e5e5e5;
    
    font-weight: 600;

    &, & > * {
        color: ${props => props.theme.colors?.text};
    }
`
const NavSlug = styled.div` 
    font-size: 14px;
    padding: 4px 0;
`


const NavBack = styled.button` 
    background: none;
    border: none;
    padding: 0;
    margin: 0 4px;
    :hover {
        svg {
            transform: scale(1.2);
        }
    }
`
const NavContent = styled.div` 
    margin: 0 auto;
    ${RowWrap}
`
const Header = styled.section`
    ${Container} 
    ${CenterCenter}
    flex-direction: column;
    flex-wrap: nowrap;

    /* font-size: 24px; */
    
    font-weight: 600;

    > :is(h1, h2, h3, h4, h5, h6, p) {
        display: block;
    }
`;

const PreTitle = styled.h4` 
    margin-bottom: 7px;
    font-size: 1em;
`
const Page = styled.div` 
    ${layout}
    ${space}
    
    /* padding-top: 40px; */
    background: ${props => props.theme.colors?.white === '#FFFFFF' ? (
        props.white ? '#F9FBFC' : '#f5f5f5'
    ) : (
        '#222'
    )};
    /* props.theme.colors?.bg */
    /* props.theme.colors.light10 */


    ${props => props.fullWidth && css` 
        ${Header} , ${Content} {
            /* max-width: 90%; */
        }
    `}
    ${color}
`
export const PageStyle = Page;

const PageStyles = {
    Page,
    Content,
    Header,
    PreTitle,
    Nav,
    NavSlug,
    NavBack,
    NavContent
};

export default PageStyles; 