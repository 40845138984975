import { Condition } from "@JSX";
import MarkedImageUploader from "@mosanic/components/Media/Marked/MarkedImage";
import MediaMultiUploader from "@mosanic/components/Media/MediaMultiUploader";
import MediaSingleUploader from "@mosanic/components/Media/MediaSingleUploader";
import { FieldControls } from "@mosanic/core/Collections/FieldControls";
import { Button, ButtonGroup, Checkbox, ColorPicker, DatePicker, Editor, Select, Switch, TextArea, TextField } from '@mosanic/fields';
import { getEntryLabel, getFieldOptions, getFieldRelation, shouldPassData, shouldPassSchema } from "@mosanic/fields/Source/helpers";
import { AdvancedLabel, InputGroup, InputGroupItem } from "@mosanic/items";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { loop } from "@util/Arr";
import { excerptString, isString, revertDevCases, slugify, upperCase } from "@util/Text";
import { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { getInitialContent } from "../Editor/Handler/Content";
import ErrorMessage from "../Errors";
import Radio from "../Radio";
import SourceSelector from "../Source";
import RelationSelector from "../Source/Relation";
import UserSelector from "../Source/User";

import { CollectionSelectorImbed } from "@mosanic/core/Collections/Select/SelectCollection";

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
  })



import { useGetPages } from "@mosanic/cms/Query";
import { useGetUser } from "@mosanic/cms/Query/byId";
import { useGetMails } from "@mosanic/cms/Query/many";
import { useFormProvider } from "@mosanic/core/Provider";
import { canValidateField, populateFieldValue, validateFieldRules } from "@mosanic/utils/field";
import { userIdSelector } from "@redux/auth/reducer";
import { getDevModePrefSelector } from "@redux/options/reducer";
import { hasAttr } from "@util/Obj";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useSelector } from "react-redux";
import { FullCodeEditor } from "../Code/FullEditor";
import Location from "../Location/Location";

const PageSelectorImbed = ({value, onChange, ...other}) => {
    const [pages, loading, error] = useGetPages({current: true})
    return loading ? loading : (
        <Select 
            getOptionValue={o => o?.value}
            options={pages?.map(p => ({value: p._id, label: p.title}))}
            onChange={onChange}
            value={value}
            {...other}
        />
    );

}
 
const MailSelectorImbed = ({value, onChange, templateSelect = true, ...other}) => {
    let [mails, loading, error] = useGetMails({current: true})
    mails = mails?.map(p => ({value: p._id, label: p.title, templates: p.templates?.map(p => p?.template_id)}))

    const options = templateSelect ? (
        mails?.flatMap(item => [
            // {...item, templates: undefined},
            ...item.templates.map(t => ({
                value: `${item.value}|${t}`, 
                label: `${item.label}: ${t}`
            }))
        ])
    ) : mails
            
    return loading ? loading : (
        <Select 
            getOptionValue={o => o?.value}
            options={options}
            onChange={onChange}
            value={value}
            {...other}
        />
    );

}

const Visual = ({ onChange, value, name = null, label = '', hideToolbar = false, main, ...other }) => (
    <>
    
    <Editor
        fullToolbar={!main?.hideToolbar}
        ninja={main?.removeBorders}


        label={label ? label : name ? name : null}
        content={!Array.isArray(value) ? getInitialContent(value, 'p') : value}
        setContent={(data) => onChange(data)}
        autoFocus={false}
        {...other} 
    />
        </>
)


export const localFlag = locale => {
    if (locale === 'en') locale = 'gb'
    return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${upperCase(locale)}.svg`
}
export const LocalizedInput = ({ 
    field,
    locales,
    locale,
    activeLang,
    renderAll = true,
    ...other
}) => (field?.localized && locales?.length >= 2) ? (
    <>
        {locale === activeLang && (
            <Input
                {...other}
                field={field}
                name={`${field?.name}.${locale}`}
                label={
                    <span>
                        {revertDevCases(isString(field?.label) ? field.label : field?.name)} &nbsp;
                        <Image
                            alt={`${locale} flag`}
                            src={localFlag(locale)}
                            width="10px" height="6px"
                        />

                    </span>}
                type={field?.type}
            />
        )}
        {renderAll && locales.filter(l => l != locale).map(lang => lang === activeLang && (
            <Input
                {...other}
                field={field}
                name={`${field?.name}.${lang}`}
                label={
                    <span>
                        {revertDevCases(isString(field?.label) ? field.label : field?.name)} &nbsp;
                        <Image
                            alt={`${lang} flag`}
                            src={localFlag(lang)}
                            width="10px" height="6px"
                        />
                    </span>}
                type={field?.type}
                noCase
            />
        ))}


    </>
) : (
        <Input
            {...other}
            field={field}
            name={field?.name}
            label={revertDevCases(isString(field?.label) ? field.label : field?.name)}
            type={field?.type}
        />
    )

export const Input = ({
    register,
    name,
    control,
    hideDisabled = false,
    rules = null,
    type = 'text',
    wrap = true,
    fullWidth = false,
    errors = null,
    field = null,
    ...other
}) => {

return type === 'repeater' ? (
    <>
    <Repeater
        main={field}
        errors={errors}
        fullWidth={fullWidth}
        control={control}
        name={name}
        {...other}
    />
    </>
) : type === 'group' ? (
    field?.schema?.length >= 1 ? (
    <InputGroup p={2} cols={1} border fullWidth label={revertDevCases(field?.label ? field.label : field?.name)}>

        {field?.schema?.map((sub, index) => (
            <Input
                {...other}
                key={sub.name + '' + index}
                label={sub?.label ? sub?.label : sub?.name}
                name={`${name}.${sub.name}`}
                type={sub?.type}
                field={sub}
                hideDisabled={hideDisabled}
                options={getFieldOptions(sub)}

                help={sub?.help}
                multiple={sub?.multiple}

                // relation={getFieldRelation(sub)}
                // schema={shouldPassSchema(sub) ? collection.schema : null}
                // data={shouldPassData(sub) ? watch() : null}
                // preview={other?.preview}

                // options={sub?.type === 'select' ? sub?.values?.split('\n') : null}
                // relation={sub?.type === 'relation' ? sub : null}
                // schema={sub?.type === 'repeater' ? other?.schema : null}
                noCase
                {...{ control, errors, fullWidth }}
            // value={field?.default} 
            />
        ))}
    </InputGroup>
    ) : null
) : (
    <Condition
        when={wrap && (
             !field?.fixed && !field?.hidden 
        )}
        wrapper={children => <InputGroupItem fullWidth={fullWidth}>{children}</InputGroupItem>}
    >

        <RenderInput {...{
            hideDisabled,
            register,
            name,
            control,
            rules,
            type,
            wrap,
            main: field,
            ...other
        }}/>
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <p>{message}</p>}
        />
    </Condition>
)
}


const RenderInput = ({ register = null, name, control, rules, type, wrap, ...other }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState }) => (
            <RenderField {...{
                register,
                name,
                fieldState,
                field,
                type,
                ...other
            }} />
        )}
    />
)
// return register ? <input {...register(name)} {...other} /> : null;

export const SwitchRenderField = ({...props}) => <RenderField {...props} />;

const RenderField = ({
    register,
    name,
    fieldState,
    field,
    type,
    relation,
    main,
    ...other
}) => {
      const userId = useSelector(userIdSelector)
    const [user] = useGetUser({_id: userId})
    const isDev = useSelector(getDevModePrefSelector)

    let fieldType = ['text', 'password', 'number'].includes(type) ? 'text' : type;
    let props = { type, name, ...main, ...field, ...fieldState, ref: undefined, innerRef: field?.ref, relation, ...other }


    const shouldValidate = canValidateField(props);

    let disabled = ( props?.fixed || props?.hidden );
    
    const form = useFormProvider()

    const canPopulate = populateFieldValue({
        onChange: props?.onChange, 
        entry: props?.data,
        value: props?.value,
        field: props,  
    });

    if (props?.hideDisabled && ( props?.fixed || props?.hidden )) return null;


    const validation = validateFieldRules({
        entry: props?.data,
        field: props,
        user,
    })

    if(
        shouldValidate && (
        validation?.disable === 'true' ||
        validation?.enable === 'false'
    )) disabled = true;
        
    if(hasAttr(validation, 'hide') && validation?.hide === 'true') return null;
    if(hasAttr(validation, 'show') && validation?.show === 'false') return null;
        
    // if(validation) return (
    //     <pre>
    //         {JSON.stringify({canPopulate, validation, props}, null, 4)}
    //     </pre>
    // )
    props = {...props, disabled: disabled || props.disabled || main?.disabled || canPopulate}

    // if(props.disabled) {
    //     <pre>
    //         DISABLED
    //         {JSON.stringify({props}, null, 4)}
    //     </pre>
    // }
    
    // if(!props?.onChange && props?.value) props = {...props, onChange: undefined, defaultValue: props.value, value: undefined}
    switch (fieldType) {
        case 'text': return <TextField {...props} />
        case 'email': return <TextField {...props} type="email" />
        case 'textarea': return <TextArea {...{ ...props, innerRef: undefined }} maxRows={20} />
        // case 'code': return <Code {...props} />;

        case 'code': return (
            <FullCodeEditor
                {...props}
            />
        );
        case 'button':  

            const passFieldName = () => form?.setSubmitButton ?
                form.setSubmitButton(props.name) : null

            return (<>
            
                <Button {...props} style={{width: '100%'}} m={0} type='submit' customClass={slugify(props?.name)}
                    onClick={() => passFieldName()}
                />

                <AdvancedLabel label={
                    isString(validation?.error) ? validation.error :
                    (!disabled && isString(validation?.helper)) ?
                        validation.helper : null
                }/>
                </>
            )
        case 'visual': return <Visual {...props}  main={main} />
        case 'collections': return <CollectionSelectorImbed {...props}  />
        case 'mail': return <MailSelectorImbed {...props}  />
        case 'pages': return  <PageSelectorImbed {...props}  />
        case 'select': return (
            <Select searchable {...props} options={props?.options ? props.options : props?.values ? props.values.split('\n') : []} />
        );
        case 'color': return <ColorPicker {...props} />
        case 'collection': return <SourceSelector {...props} activate={{ collection: true }} />
        case 'buttonGroup': return (
            <ButtonGroup {...props}>
                <ButtonGroup.Choices
                    {...props}
                    handler={props.onChange}
                />
            </ButtonGroup>
        )
        case 'gallery': return <MediaMultiUploader
            imageUrls={props.value}
            type='image'
            callback={props.onChange}
            label={'Gallery uploader'}
        />

        case 'relation': return (
            <RelationSelector {...props} relation={relation} inline type="collection" />
        )
        case 'markedimage': return (
            <MarkedImageUploader
                {...props}
                type='image'
                callback={props.onChange}
                relation={main?.markerRelation}
                display={main?.markerDisplay}
                value={{ ...props?.value, styles: main?.default?.styles }}
            />
        )
        case 'date': return (
            <>

                <DatePicker {...props} {...main} />
            </>)
        case 'time': return (<DatePicker {...props} timeOnly />)
        case 'date-time': return (
            <DatePicker
                {...props}
                timeFormat="HH:mm"
                showTimeSelect
            // value={new Date(user?.subscription?.ending)} 
            // onChange={(val) => setUser({
            //     ...user, 
            //     subscription: {
            //         ...user.subscription, 
            //         ending: val
            //     }
            // })}
            />
        )
        case 'image': return (
            <MediaSingleUploader
                {...props}
                imageUrl={props.value}
                type='image'
                callback={props.onChange}
            />
        );
        case 'video': return (
            <MediaSingleUploader
                imageUrl={props.value}
                type='video'
                callback={props.onChange}
            />
        );
        case 'switch': return <Switch {...props} checked={field?.value} />
        case 'checkbox': return <Checkbox {...props} />
        case 'radio': return <Radio {...props} />
        case 'submit': return <Button label="Submit" />
        case 'label': return (
            <>
                <AdvancedLabel  {...other} {...main} label={field?.label ? field.label : name} span as={main?.headingSize} {...other?.fieldProps} />
            </>
        )
        case 'location': return <Location {...props}/>
        case 'user': return (
            <UserSelector {...props} />
        )
        default: return register ? <input {...register(name)} {...other} /> : null;
    }
}

const getLevelType = lvl => {
    if ((lvl + 3) < 6) return `h${lvl + 3}`
    return 'bold'
}

const Repeater = ({ main, name, errors, fullWidth, fieldState, control, ...other }) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name,
    });
    const entries = fields;

    const [activeTab, setTab] = useState(0)
    const hasTabs = main?.displayChildren === 'tabs' || !main?.displayChildren;

    const singular = main?.singular ? main.singular : 'entry';
    const inputProps = { control, errors, fullWidth }

    const activeLevel = main?.level ? main?.level : 0;
    const schema = main?.schema;
    const [show, setShow] = useState(true)
    const devMode = useSelector(getDevModePrefSelector)

    // useEffect(() => {
    //     setShow(false)
    //     setTimeout(() => {
    //         setShow(true)
    //     }, 50);
    //     setTab(0);
    // }, [entries])

    // if(other?.control) return;

    // main?.displayChildren

    const getNameValue = i => getEntryLabel(entries?.[i], schema, `${singular} ${i}`)

    return (
        <InputGroup border py={4} style={{marginTop: '20px !important;'}} label={(
            <AdvancedLabel
                as={getLevelType(activeLevel)}
                label={`${loop(activeLevel, '>')} ${revertDevCases(main?.name ? main.name : name)}`}
                helpText={main?.help}
            />
        )}>
            {(hasTabs && entries?.length >= 1) && (
                <ButtonGroup size="small">
                    <ButtonGroup.Choices
                        max={10}
                        value={activeTab}
                        handler={setTab}
                        options={fields?.map((f, i) => ({ 
                            label: getNameValue(i)?.length >= 8 ? 
                                `${main?.singular} ${i + 1}` : 
                                getNameValue(i), 
                            value: i 
                        }))}
                    />
                    {/* <Button size="small" onClick={() => append({})} label={`Append ${singular}`} /> */}
                </ButtonGroup>
            )}

            {show && entries?.map((field, index) => ((hasTabs && index === activeTab) || !hasTabs) ? (
                <InputGroup border label={getNameValue(activeTab)} cols={1} key={field?.id}>

                    {!hasTabs && (
                        <AdvancedLabel label={`${singular} ${index + 1}`} />
                    )}
                    <FieldControls
                        style={{top: 0}}
                        m={0}
                        remove={remove}
                        move={move}
                        index={index}
                        count={fields?.length}
                        trigger={
                            <Button icon={<DotsHorizontalIcon />} ghost mb={2} />
                        }
                    />


                    {schema?.map(sub => (
                        <Input
                            key={sub.name + '' + index}
                            label={sub?.name}
                            name={`${name}.${index}.${sub.name}`}
                            type={sub?.type}
                            field={{ ...sub, level: activeLevel + 1 }}
                            options={sub?.type === 'select' ? sub?.values?.split('\n') : null}
                            relation={sub?.type === 'relation' ? sub : null}
                            schema={sub?.type === 'repeater' ? other?.schema : null}
                            noCase
                            {...inputProps}
                        // value={field?.default} 
                        />
                    ))}


                </InputGroup>
            ) : null)}

            {/* {devMode && (
                <ReactJson collapsed src={{other, main}}/>
            )} */}
            {/* relation={field?.type === 'relation' ? field.relation : null} */}
            <Button
                type="button"
                style={{width: '100%'}}
                onClick={() => append({})}
            >
                &nbsp;
                {entries?.length >= 1 ?
                    `Append ${singular}` :
                    `Add first ${singular}`
                }
            </Button>
        </InputGroup>
    )
};