import { NumList } from "@mosanic/Icons/ElementIcons";
import { AlignLeftIcon, AlignRightIcon, CodeIcon, FontBoldIcon, FontItalicIcon, ListBulletIcon, QuoteIcon, TextAlignCenterIcon, TextAlignJustifyIcon, TextAlignLeftIcon, TextAlignRightIcon, UnderlineIcon } from "@radix-ui/react-icons";
import { BlockButton, InlineButtons, MarkButton, SourceButton } from "../Components";

const GroupStyle = ({code = false}) => (
    <>
        <MarkButton format="bold" icon={<FontBoldIcon />} />
        <MarkButton format="italic" icon={<FontItalicIcon />} />
        <MarkButton format="underline" icon={<UnderlineIcon />} />
        {code ? <MarkButton format="code" icon={<CodeIcon />} /> : null}
    </>
)

const GroupAlign = () => (
    <>
        <BlockButton format="left" icon={<TextAlignLeftIcon />} />
        <BlockButton format="center" icon={<TextAlignCenterIcon />} />
        <BlockButton format="right" icon={<TextAlignRightIcon />} />
        <BlockButton format="justify" icon={<TextAlignJustifyIcon />} />
    </>
);

const GroupHeadings = () => (
    <>
        <BlockButton format="heading-1" text="h1" />
        <BlockButton format="heading-2" text="h2" />
        <BlockButton format="heading-3" text="h3" />
        <BlockButton format="heading-4" text="h4" />
        <BlockButton format="heading-5" text="h5" />
        <BlockButton format="heading-6" text="h6" />
    </>
);

const GroupInsertBlocks = () => (
    <>
        <BlockButton format="block-quote" icon={<QuoteIcon />} />
        <BlockButton format="numbered-list" icon={<NumList />} />
        <BlockButton format="bulleted-list" icon={<ListBulletIcon/>} />
    </>
)

const GroupInlineElements = () => (
    <>
        <InlineButtons.Link />
        <InlineButtons.Image />
        <InlineButtons.Button />
    </>
)



export {
    GroupStyle,
    GroupAlign,
    GroupHeadings,
    GroupInsertBlocks,
    GroupInlineElements,
    SourceButton,
};
