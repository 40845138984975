

import { ADD_ITEM, COLUMN, COMPONENT, CONTAINER, REPEATER, ROW, SIDEBAR_ITEM, SIDEBAR_LAYOUT, SIDEBAR_REPEATER, SIDEBAR_STACK, STACK } from "@mosanic/constants/items";
import { getItemIndex, pathToItem as getPathToItem, splitPath } from "@mosanic/map/paths";
import { preDropStoreSelector } from "@redux/map/drop/reducer";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { default as s } from './DropZone.style';

const ACCEPTS = [ADD_ITEM, SIDEBAR_LAYOUT, SIDEBAR_ITEM, SIDEBAR_STACK, SIDEBAR_REPEATER, COMPONENT, ROW, COLUMN, STACK, REPEATER, CONTAINER];

const canDropItem = (item, data, allowed) => {
  const itemType = item?.itemType;
  const itemPath = item?.path;
  const dropZonePath = data?.path;
  
  const splitItemPath = splitPath(itemPath);
  const splitDropZonePath = splitPath(dropZonePath);


  if(!item) return false
  if(!dropZonePath) return false; //No dropZone path
  if(itemPath === dropZonePath) return false; //Same location
  // if(itemType === Items.ROW && splitDropZonePath?.length != 1) return false; //rows only at base      
  if(item?.component?.type === 'columns' && splitDropZonePath?.length != 1) return false; //New columns wrapper only at base
  if(Array.isArray(allowed)){
    const checkType = item?.component?.type ? item.component.type : item.type;
    return allowed.includes(checkType)
  }

  if(!itemPath) return true; //New item

  // * custom limits
  // switch (item.type) {
  //   case SIDEBAR_LAYOUT: return splitDropZonePath.length === 1
  //   case REPEATER: if(splitDropZonePath.length <= 3) return false;
  //   case SIDEBAR_REPEATER:return (splitDropZonePath.length <= 3)
  //   default: break;
  // }

  const dropZonePathRowIndex = splitDropZonePath[0];
  const itemPathRowIndex = splitItemPath[0];
  const diffRow = dropZonePathRowIndex !== itemPathRowIndex;

  if ( !diffRow && splitDropZonePath.length === 2 && data.childrenCount >= 12) return false;  //Limit columns



  //Prevent row drop inside child
  const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
  // if(parentDropInChild && isContainer(itemType)) return false;


  // Current area
  if (splitItemPath.length === splitDropZonePath.length) {
    const pathToItem = getPathToItem(splitItemPath);
    const currentItemIndex = getItemIndex(splitItemPath);

    const pathToDropZone = getPathToItem(splitDropZonePath);
    const currentDropZoneIndex = getItemIndex(splitDropZonePath);

    if (pathToItem === pathToDropZone) {
      const nextDropZoneIndex = currentItemIndex + 1;
      if (nextDropZoneIndex === currentDropZoneIndex) return false;
    }
  }
  return true;
}


const DropZone = ({ 
  data, 
  onDrop, 
  isLast, 
  isHorizontal = false, 
  allowed = true,
  canExpand = false,
  empty = false, 
  slot = false, 
  level = 0, 
  breakpoint = null,
  ...other
}) => {
  const preStoreDrop = useSelector(preDropStoreSelector);
  const canDropPreStore = canDropItem(preStoreDrop, data, allowed);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop({...data, slot}, item);
    },
    canDrop: (item, monitor) =>  canDropItem(item, data, allowed),

    collect: (monitor, item) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const state = {
    level,
    empty,

    canExpand,
    canDrop: canDrop || canDropPreStore,
    isOver,
    isLast,
    isHorizontal
  }
  return (
    <s.DropZone 
      {...state} 
      ref={drop} 
      slot={slot || breakpoint==='layers' || true} 
      type="dropZone" 
      layers={breakpoint==='layers'} 
      onClick={() => canDropPreStore ? onDrop({...data, slot}, preStoreDrop) : null}
      {...other}
    > 
      <s.Inside />
      
      
    </s.DropZone>
  );
};
export default DropZone;
