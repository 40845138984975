import { containsSubstr } from "@util/Text";
import styled, { css } from "styled-components";
import Radio from "./Radio";

const Label = styled.div` 
    display: inline-block;
    vertical-align: top;
    margin-top: 0px;
    margin-right: 5px;
`
const Direction = styled.div` 
    display: flex;
    ${props => props.direction === 'vertical' ? css` 
        flex-wrap: wrap;
        label {
            width: 100%;
        }
    ` : css` 
        flex-wrap: nowrap;
    `}
`
const Radios = ({
    className,
    options, 
    value, 
    onChange = null,
    disabled = false,
    size ="small",
    direction = 'horizontal',
    renderOption = null,
    ...other
}) => (
    <Direction direction={direction}>
        {options?.map((option, index) => {
            const lbl = option?.label ? option.label : option;
            return (
                <Radio 
                    key={index}
                    index={index}
                    {...other}
                    renderOption={renderOption}
                    label={containsSubstr(lbl, '|') ? (
                        <Label>
                            <b>{lbl?.split('|')[0]}</b><br/>
                            {lbl?.split('|')[1]}
                        </Label>
                    ) : option} 
                    value={value?.[lbl]} 
                    onChange={state => onChange({[lbl]: value?.[lbl] ? false : true})}
                />
            )
        })}
    </Direction>
);

export default Radios;