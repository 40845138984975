import { getImageUrl } from "@api/host"
import { containsSubstr, matchSubstrList, toLowCase } from "@util/Text"
import { isVideo } from "@mosanic/fields/Video";

const isSvgImage = url => containsSubstr(url, '.svg');

const getMosanicSrc = imageUrl => (imageUrl?.url && imageUrl?.urlMin) ? ({
    full: imageUrl.url,
    min: imageUrl.urlMin,
    thumb: isSvgImage(imageUrl?.url) ? imageUrl?.url :
        imageUrl?.urlThumb ? 
            imageUrl?.urlThumb : 
            imageUrl.urlMin
}) : ({
    full: imageUrl,
    min:imageUrl,
    thumb: imageUrl,
});

const isWordpressSrc = imageUrl => imageUrl?.sizes?.thumbnail;
export const isExternalImageSrc = img => isWordpressSrc(img) || containsSubstr(img, 'https://')

const getExternalSrc = imageUrl => isWordpressSrc(imageUrl) ? ({
    full: imageUrl.url,
    min: imageUrl.sizes?.medium,
    thumb: imageUrl?.sizes?.thumbnail
}) : ({
    full: imageUrl,
    min: imageUrl,
    thumb: imageUrl
})

const getFullSrc = srcset => {
    let full = {};
    Object.entries(srcset).map(([size, url]) => {
        full = {...full, [size]: getImageUrl(url)}
    })
    return full;
}


export const isImage = url => (
    url && 
    !matchSubstrList(toLowCase(url), ['.mp4', '.mov']) && 
    matchSubstrList(toLowCase(url), '.jpg|.png|.svg|.jpeg|.webp|.svg'.split('|'))
)

export const parseImageSrc = (imageUrl, fullSrc = false) => {
    const isExternal = isExternalImageSrc(imageUrl);

    const source = isExternal ? 
        getExternalSrc(imageUrl) : 
        getMosanicSrc(imageUrl);

    return (isImage(source?.full) || isVideo(source?.full)) ? (
        ( fullSrc && !isExternal ) ? getFullSrc(source) : source
    ) : false;
}

