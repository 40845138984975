export const blendingIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="15px" height="15px" viewBox="0 0 15 15"><path fillRule="evenodd" clipRule="evenodd" d="M3 9c0-2.5 1.5-4.75 4.5-7.5 3 2.75 4.5 5 4.5 7.5 0 2.4853-2.0147 4.5-4.5 4.5S3 11.4853 3 9Zm7.9524-.697c-1.279-.4818-2.6645.159-3.9628.7596-1.0569.4888-2.056.9509-2.8927.759A3.5091 3.5091 0 0 1 4 9c0-1.888 1.0269-3.728 3.5-6.1264C9.6684 4.9764 10.725 6.65 10.9524 8.303Z" fill="#000"/></svg>;
export const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="15px" height="15px" viewBox="0 0 15 15"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793.2223a.6.6 0 0 1 .8415 0l6.75 6.64a.6.6 0 0 1-.8415.8554L13 6.902V12.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V6.902l-.8292.8157a.6.6 0 1 1-.8415-.8554l6.75-6.64ZM7.5 1.4916 12 5.9183V12h-2V8.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V12H3V5.9183l4.5-4.4267ZM7 12h2V9H7v3Z" fill="#000"/></svg>;
export const tokenIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="15px" height="15px" viewBox="0 0 15 15"><path fillRule="evenodd" clipRule="evenodd" d="M4.5 2C3.1193 2 2 3.1193 2 4.5S3.1193 7 4.5 7 7 5.8807 7 4.5 5.8807 2 4.5 2ZM3 4.5C3 3.6716 3.6716 3 4.5 3S6 3.6716 6 4.5 5.3284 6 4.5 6 3 5.3284 3 4.5ZM10.5 2C9.1193 2 8 3.1193 8 4.5S9.1193 7 10.5 7 13 5.8807 13 4.5 11.8807 2 10.5 2ZM9 4.5c0-.8284.6716-1.5 1.5-1.5s1.5.6716 1.5 1.5S11.3284 6 10.5 6 9 5.3284 9 4.5Zm-7 6C2 9.1193 3.1193 8 4.5 8S7 9.1193 7 10.5 5.8807 13 4.5 13 2 11.8807 2 10.5ZM4.5 9C3.6716 9 3 9.6716 3 10.5S3.6716 12 4.5 12 6 11.3284 6 10.5 5.3284 9 4.5 9Zm6-1C9.1193 8 8 9.1193 8 10.5S9.1193 13 10.5 13s2.5-1.1193 2.5-2.5S11.8807 8 10.5 8ZM9 10.5c0-.8284.6716-1.5 1.5-1.5s1.5.6716 1.5 1.5-.6716 1.5-1.5 1.5S9 11.3284 9 10.5Z" fill="#000"/></svg>
export const layersIcon = <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 15 15"><path d="M7.7543.8195a.5.5 0 0 0-.5087 0l-5.5 3.25a.5.5 0 0 0 0 .861l5.5 3.25a.5.5 0 0 0 .5087 0l5.5-3.25a.5.5 0 0 0 0-.861l-5.5-3.25ZM7.5 7.1692 2.9828 4.5 7.5 1.8308 12.017 4.5 7.5 7.1692Zm-5.9305.3264a.5.5 0 0 1 .6848-.176L7.5 10.4191l5.2456-3.0997a.5.5 0 1 1 .5087.861l-5.5 3.25a.5.5 0 0 1-.5087 0l-5.5-3.25a.5.5 0 0 1-.1761-.6849Zm0 3a.5001.5001 0 0 1 .6848-.1761L7.5 13.4192l5.2456-3.0997a.5.5 0 0 1 .5087.861l-5.5 3.25a.5.5 0 0 1-.5087 0l-5.5-3.25a.5.5 0 0 1-.1761-.6849Z" fill="currentColor" fillRule="evenodd" clipRule="#000"/></svg>
export const boxIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"  width="15px" height="15px" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.2886.797a.5.5 0 0 1 .4228 0l6 2.8A.5.5 0 0 1 14 4.05v6.9a.5.5 0 0 1-.2886.4531l-6 2.8a.5003.5003 0 0 1-.4228 0l-6-2.8A.5.5 0 0 1 1 10.95v-6.9a.5.5 0 0 1 .2886-.453l6-2.8ZM2 4.8057l5 2.125v6.0341l-5-2.3333V4.8058Zm6 8.1591 5-2.3333V4.8058l-5 2.125v6.0341Zm-.5-6.9082 4.7719-2.028L7.5 1.8017l-4.772 2.227L7.5 6.0567Z" fill="#000"/></svg>;
export const collectionIcon = boxIcon;
export const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"  width="15px" height="15px" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.5 1h10c.8284 0 1.5.6716 1.5 1.5v10c0 .8284-.6716 1.5-1.5 1.5h-10c-.8284 0-1.5-.6716-1.5-1.5v-10C1 1.6716 1.6716 1 2.5 1Zm0 1a.5.5 0 0 0-.5.5v5.8636l1.6818-1.6818a.45.45 0 0 1 .6471.0111l3.5446 3.7972 2.8083-2.8083a.45.45 0 0 1 .6364 0L13 9.3636V2.5a.5.5 0 0 0-.5-.5h-10ZM2 12.5V9.6364l1.9889-1.9889 3.5422 3.7946L8.9411 13H2.5a.5.5 0 0 1-.5-.5Zm10.5.5h-2.345l-1.6716-1.847L11 8.6364l2 2V12.5a.5.5 0 0 1-.5.5ZM6.6492 5.5a.8508.8508 0 1 1 1.7016 0 .8508.8508 0 0 1-1.7016 0ZM7.5 3.7492c-.967 0-1.7508.7839-1.7508 1.7508 0 .967.7839 1.7508 1.7508 1.7508.967 0 1.7508-.7839 1.7508-1.7508 0-.967-.7839-1.7508-1.7508-1.7508Z" fill="#000"/></svg>;
export const userInput = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width="15px" height="15px" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.5 1a.5.5 0 0 0 0 1c.6267 0 .9572.2003 1.1556.4781C7.8781 2.7896 8 3.2884 8 4v7c0 .7116-.1219 1.2104-.3444 1.5219C7.4572 12.7997 7.1267 13 6.5 13a.5.5 0 0 0 0 1c.8733 0 1.5428-.2997 1.9694-.8969a2.0522 2.0522 0 0 0 .0306-.044c.01.0148.0202.0295.0306.044C8.9572 13.7003 9.6267 14 10.5 14a.5.5 0 0 0 0-1c-.6267 0-.9572-.2003-1.1556-.4781C9.1219 12.2104 9 11.7116 9 11V4c0-.7116.1219-1.2104.3444-1.5219C9.5428 2.2003 9.8733 2 10.5 2a.5.5 0 0 0 0-1c-.8733 0-1.5428.2997-1.9694.8969a2.2162 2.2162 0 0 0-.0306.044 2.2162 2.2162 0 0 0-.0306-.044C8.0428 1.2997 7.3733 1 6.5 1ZM14 5h-3V4h3c.5523 0 1 .4477 1 1v5c0 .5523-.4477 1-1 1h-3v-1h3V5ZM6 4v1H1v5h5v1H1c-.5523 0-1-.4477-1-1V5c0-.5523.4477-1 1-1h5Z" fill="#000"/></svg>;
export const builderIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15px" height="15px"><path d="M7 0H4a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2Zm-2 6H4a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2Zm11-7h-3a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2ZM14 7h3v3a1 1 0 0 0 2 0V7h3a1 1 0 0 0 0-2h-3V2a1 1 0 0 0-2 0v3h-3a1 1 0 0 0 0 2Z"/></svg>;
export const userIcon = <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px"><path d="M23 11h-2V9a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2ZM9 12a6 6 0 1 0-6-6 6.006 6.006 0 0 0 6 6ZM9 2a4 4 0 1 1-4 4 4 4 0 0 1 4-4Zm0 12a9.01 9.01 0 0 0-9 9 1 1 0 1 0 2 0 7 7 0 0 1 14 0 1 1 0 1 0 2 0 9.01 9.01 0 0 0-9-9Z"/></svg>;
export const lockIcon = <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px"><path d="M5 4.636c0-.8757.2422-1.5306.6432-1.9624.3961-.4266 1.0026-.6953 1.8582-.6953.856 0 1.4617.2682 1.8568.6938C9.7584 3.103 10 3.757 10 4.6332V6H5V4.636ZM4 6V4.636c0-1.0545.2934-1.9785.9105-2.643.622-.6697 1.5163-1.0147 2.5909-1.0147 1.0744 0 1.9681.344 2.5896 1.0133.6166.664.909 1.5874.909 2.6417V6h1c.5523 0 1 .4477 1 1v6c0 .5523-.4477 1-1 1H3c-.5523 0-1-.4477-1-1V7c0-.5523.4477-1 1-1h1ZM3 7h9v6H3V7Z" fillRule="evenodd"/></svg>;
