
import { ReactNode } from 'react';
import {default as s} from "./Link.style";
import { default as NextLink } from "next/link";
export type LinkProps = {
  href: string
  children: ReactNode | any
  passHref?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top',
  action?: any | Function
}


export const Link = ({ href, passHref = true, target, children, action = null, ...other}: LinkProps) => (
  <NextLink href={href} passHref={passHref}>
    <s.a href={href} target={target} onClick={action} {...other}>
      {children}
    </s.a>
  </NextLink>
);
export default Link