import { gql } from '@apollo/client';

export const LIST_ALL_TEAMS = gql`
query teamMany($filter: FilterFindManyTeamInput) {
	teamMany(filter: $filter){
		name
		sector 
		description 
		superAdmin 
		isActive 
		subscription {
			premium 
			active 
			ending 
			started 
			userCount 
			data 
			addons 
			trial 
		}
		registrationDate 
		sites {
			siteId 
		}
		users {
			userId 
			role 
		}
		notifications 
		teamAuthor {
			displayName 
			bio 
			biography
		}
		_id
	}
}
`;

export const FIND_TEAM = gql`
query teamMany($filter: FilterFindManyTeamInput) {
	teamMany(filter: $filter){
		name
		sector 
		description 
		isActive 
		_id
	}
}
`;

export const TEAM = gql`
query teamById ($_id: MongoID!) {
	teamById(_id: $_id){
		name
		sector 
		description 
		superAdmin 
		isActive 
		subscription {
			premium 
			active 
			ending 
			started 
			userCount 
			data 
			addons 
			trial 
		}
		registrationDate 
		sites {
			siteId 
		}
		users {
			userId 
			role 
			roles
		}
		notifications 
		teamAuthor {
			displayName 
			bio 
			biography
		}
		_id
	}
}
`;
