import { Condition } from "@JSX";
import { getSearchMarkTitle } from "@mosanic/core/Items/useItems";
import { Button, Buttons, Checkbox, RenderText, TextField } from "@mosanic/fields";
import { ToggleButton } from "@mosanic/fields/Button/Buttons";
import { AdvancedLabel, ControlDialog, Label, Link } from "@mosanic/items";
import { ControlPopper, HelpPopper } from "@mosanic/items/ControlPanel";
import { InlineGroup } from "@mosanic/items/Inputs";
import { colors } from "@mosanic/styles";
import { parseImageSrc } from "@mosanic/utils/Image";
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, CalendarIcon, DotsVerticalIcon, ExternalLinkIcon, LockOpen1Icon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { getDate } from "@util/Date";
import isEmpty from "@util/Empty";
import { get, hasAttr, isObject } from "@util/Obj";
import { capitalizeFirst, combineString, containsSubstr, isString, revertDevCases } from "@util/Text";
import Image from "next/image";
import { textEditor } from "react-data-grid";
import styled from "styled-components";
import MediaMultiUploader from "../../components/Media/MediaMultiUploader";
import { FilterRenderer } from "./FilterHeader";

const Unfocus = styled.span` 
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
    font-size: 12px!important;

`
export const StatusOptions = [
	'PLANNED',
	'PUBLISHED',
	'PENDING',
	'DRAFT',
    'DISABLED',
	'EDITING',
	'HIDDEN',
	'LOCKED',
	'TRASHED',
];
const hasLocale = (props, key, locale) => isObject(props.row?.[key]) && props.row?.[key]?.[locale]

function inputStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}

function selectStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

const getLink = ({basePath = 'collection', parent, rowId}) => parent ? 
    `/${basePath}/${parent}/${rowId}?action=view` :
    `/${basePath}/${rowId}?action=view`


const getUrl = (host, baseRoute, slug) => combineString([
    baseRoute && host,
    baseRoute,
    slug
],['/']);


const bookingFields = ({collection, host, handles, routing}) => [{
    width: 40,
    minWidth: 100,
    resizable: false,
    frozen: true,
    name: 'Status',
    formatter: (props) => {
        const status = props?.row?.booking?.status;
        const color = 
            status === 'pending' ? 'orange' :
            status === 'accepted' ? 'green' :
            status === 'cancelled' ? 'red' :
            status === 'rejected' ? 'red' :
            'gray';

        return (
            <AdvancedLabel 
                bg={color} 
                fontWeight={500} 
                as="span"
                style={{
                    textTransform: 'uppercase', fontSize: 12, padding: '2px 6px', borderRadius: 4, color: '#fff',
                    backgroundColor: colors?.util?.[color]?.base
                }}
            >
                {status}
            </AdvancedLabel>
        )
    }
}]


const baseFields = ({collection, host, handles, routing}) => [{
    width: handles?.viewOnly ? 20 : 40,
    minWidth: handles?.viewOnly ? 20 : 65,
    resizable: false,
    frozen: true,
    formatter: (props) => {
        const link = getLink({
            parent: collection?._id ? collection._id : routing?.singlePath, 
            rowId: props?.row?._id, 
            basePath: routing?.basePath
        });
        return (
            <Buttons mx="-16px" style={{transform: 'scale(0.9)', transformOrigin: 'top', background: '#fff'}} >
                <Button tooltip="Open collection" href={
                    handles?.viewOnly ? getUrl(host, collection?.baseRoute, props.row.slug) : link 
                } icon={<ArrowRightIcon/>} ghost width={30} p={0} />
                
                {handles?.viewOnly ? (
                    // <Button tooltip="Modal editor" icon={<SizeIcon/>} onClick={() => handles.edit(props?.row)} ghost/>
                    null
                ) :(
                <ControlPopper p={0} m={0} width={40} placement="right" trigger={
                    <Button icon={<DotsVerticalIcon/>} width={30} p={0} ghost/>
                }>
                    <Buttons>
                        {link && (
                            <Button  
                                tooltip="Open collection in new tab"
                                href={link} target="_blank" 
                                icon={<ExternalLinkIcon/>} ghost 
                            />
                        )}
                        <Button tooltip="Modal editor" icon={<Pencil1Icon/>} onClick={() => handles.edit(props?.row)} ghost/>
                        
                        {handles?.delete && (
                            <Button tooltip="Delete" icon={<TrashIcon />} ghost
                                onClick={() => handles?.delete({_id: props.row._id})} 
                            />
                        )}
                    </Buttons>
                </ControlPopper>
                )}
            </Buttons>

        ) 
    }
}
]

const infoFields = (collection, host, handles, userNames, filters) => {
    let base = []
    const hasField = atr => collection?.entries?.filter(e => e?.[atr])?.length >= 1

    if(hasField('slug') && !handles?.viewOnly) base = [...base,{
        width: 40,
        minWidth: 90,
        resizable: false,
        name: 'Slug',
        formatter: (props) => props.row.slug ? (
            <Link target="_blank" href={getUrl(
                host, 
                collection?.routing?.baseRoute ? collection?.routing?.baseRoute : collection?.baseRoute, 
                props.row.slug
            )}>{props.row.slug}</Link>
        ) : ''
    }]

    // if(hasField('status')) base = [...base, {
        // width: 40,
        // minWidth: 70,
    //     resizable: false,
    //     name: 'Status',
    //     // frozen: true,
    //     formatter: (props) => (
    //         <ControlPopper trigger={<span style={{color: lighten(0.3, getPageStatusColor(props.row.status))}}>{props.row?.status}</span>} overflow p={0}>
    //             <Select options={StatusOptions} value={props.row?.status} label="Status" clearable={false}/>
    //         </ControlPopper>
    //     )
    // }]



    // if(hasField('userId')) base = [...base, {
    //     width: 40,
    //     minWidth: 70,
    //     resizable: true,
    //     name: 'Author',
    //     // frozen: true,
    //     formatter: (props) => (
    //         // <ControlPopper trigger={<span style={{color: lighten(0.3, getPageStatusColor(props.row.status))}}>{props.row?.status}</span>} overflow p={0}>
    //         <Link target="_blank" href={`/user/${props.row?.userId}`}>
    //             {userNames ? userNames?.find(user => user?.value === props.row?.userId)?.label : ''}

    //         </Link>
    //         // </ControlPopper>
    //     )
    // }]
    

    return [...base, {
        width: 50,
        minWidth: 110,
        resizable: false,
        name: 'Date',
        headerRenderer: (p) => (
            <FilterRenderer {...p}>
                {({ filters, ...rest }) => (
                    <ToggleButton
                        ghost
                        {...rest} small
                        label="Date"
                        m={0}
                        p={0}
                        altLabel="Date"
                        icon={!isEmpty(filters?.date) ? <ArrowDownIcon /> : null}
                        altIcon={<ArrowUpIcon />}
                        checked={filters?.date}
                        onClick={() => handles?.setFilters({
                                ...filters,
                                'date': !filters?.date
                            })}

                        // value={filters?.[key]}
                        // onChange={(e) =>
                            // handles?.setFilters({
                            //     ...filters,
                            //     [key]: e.target.value
                            // })
                        // }
                        // onKeyDown={inputStopPropagation}
                        // endSource={
                        //     <ControlPopper trigger={filters[`${key}-order`] === 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}>
                        //         <GridOrderButton />
                        //     </ControlPopper>
                        // }
                    />
                )}
                
            </FilterRenderer>
        ),
        formatter: (props) => (
            <HelpPopper span 
                trigger={(props.row?.lastLogin != props.row.date && props?.row?.lastLogin) ?  (
                        <span><LockOpen1Icon />&nbsp;{getDate(props.row.lastLogin)}</span>
                    ):(
                        <span><CalendarIcon />&nbsp;{getDate(props.row.date)}</span>
                    )}
                helpText={(
                    <span>
                        {props.row?.registrationDate != props.row?.date && (
                            <span><CalendarIcon />&nbsp;Published {getDate(props.row.date)}</span>
                        )}

                        {props.row?.updatedAt != props.row?.date && props.row?.updatedAt && 
                            <span><CalendarIcon />&nbsp;Updated at: {getDate(props.row.updatedAt)}</span>
                        }
                        {props.row?.registrationDate &&  (
                            <span><CalendarIcon />&nbsp;Registered at: {getDate(props.row.registrationDate)}</span>
                        )}
                        {props.row?.lastLogin != props.row?.date && props.row?.lastLogin &&  (
                            <span><LockOpen1Icon />&nbsp;Last login: {getDate(props.row.lastLogin)}</span>
                        )}
                    </span>
                )} 
            />
        )
    }]
}
const renderText = (hasTranslation, value) => {
    if(!value) return '';
    value = hasTranslation ? value.en : value;

    if(Array.isArray(value)) return <RenderText content={value}/>;
    if(isString(value)) return value;
    
}

const isInThePast = (date) => {
    const now = new Date();
    const dateObj = new Date(date);
    return dateObj < now;
}

const baseFormatter = (props, key, locale) => (
    <Condition 
        when={hasLocale(props, key, locale)}
        wrapper={children => (
            <ControlPopper trigger={props.row?.[key]?.[locale]} title="Languages">
                <AdvancedLabel label={capitalizeFirst(`${locale} entry`)} as="h4"/>
                {children}
                {Object.keys(props.row?.[key]).filter(lang => lang != locale).map(lang => (
                    <p key={lang}>
                        <AdvancedLabel label={capitalizeFirst(`${lang} entry`)} as="h4"/>
                        {props.row?.[key]?.[lang]}
                    </p>
                ))}
            </ControlPopper>
        )}
    >
            
        {
            hasLocale(props, key, locale) ? 
                props.row?.[key][locale] :
                Array.isArray(props.row?.[key]) ? 
                    'Array' : 
                    isString(props.row?.[key]) ? 
                        props.row?.[key] :
            'No data'
        }
    </Condition>
)
const formatField = ({field, handles, locale, userNames, siteNames, filters, schema}) => {
    const key = field?.slug ? field.slug : field?.name;
    const value = props => containsSubstr(key, '.') ? get(props.row, key) : props.row?.[key];
    const hasTranslation = props => hasAttr(value(props), 'en');

    const base = {
        key,
        field: key,
        _id: field?.name,
        name: field?.label ? field.label : revertDevCases(field?.name),
        resizable: true,
        formatter: (props) => baseFormatter(props, key, locale)
    }
    if(base?.name === 'Global') {
        return {
            ...base,
            editor: textEditor,
            formatter: (props) => (
                <span>
                    {siteNames?.find(site => site?._id === props.row?.[key])?.name} <u style={{fontSize: '12px', opacity: 0.2}}>{props.row?.[key]}</u>
                </span>
            )
        }
    }

    switch (field?.type) {
        case 'custom': return {
            ...base,
            formatter: (props) => (
                <ControlPopper trigger={renderText(hasTranslation(props), value(props))}>
                    {value(props)?.render}
                </ControlPopper>
            )
        }
        case 'group': 
            const groupField = schema?.find(s => s?.name === base?._id);
            return {
            ...base,
            width: groupField?.name?.length, 
            formatter: (props) => (
                <ControlPopper width="600px" trigger={<Button label="expand" ghost m={0} p={0} style={{width: '100%', color: '#888', background: 'none'}} />}>
                    <InlineGroup justify="space-between">
                    {groupField?.schema?.filter(f => f?.inColumns)?.map(field => (
                        <Label
                            as="h4" 
                            label={field?.label ? field.label : revertDevCases(field?.name)} 
                            value={field?.type === 'date' ? getDate(value(props)?.[field.name]) : value(props)?.[field.name]} 
                            key={field?.name}/>
                    ))}
                    </InlineGroup>
                </ControlPopper>
            )
        }
        case 'text': return {
            ...base,
            editor: textEditor,
            formatter: (props) => (
                <span dangerouslySetInnerHTML={{ __html: 
                    getSearchMarkTitle(
                        hasTranslation(props) ? value(props).en : value(props), 
                        filters?.[key]?.en ? filters?.[key]?.en : filters?.[key]
                    ) 
                }}/>
            ),
            headerRenderer: (p) => (
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <TextField
                            {...rest} small
                            label={capitalizeFirst(key)}
                            value={filters?.[key]}
                            onChange={(e) =>
                                handles?.setFilters({
                                    ...filters,
                                    [key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                            // endSource={
                            //     <ControlPopper trigger={filters[`${key}-order`] === 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}>
                            //         <GridOrderButton />
                            //     </ControlPopper>
                            // }
                        />
                    )}
                    
                </FilterRenderer>
            )
        }
        case 'textarea': return {
            ...base,
            editor: textEditor,
            formatter: (props) => (
                <span dangerouslySetInnerHTML={{ __html: 
                    getSearchMarkTitle(
                        hasTranslation(props) ? value(props).en : value(props), 
                        filters?.[key]?.en ? filters?.[key]?.en : filters?.[key]
                    ) 
                }}/>
            ),
            headerRenderer: (p) => (
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <TextField
                            {...rest} small
                            label={capitalizeFirst(key)}
                            value={filters?.[key]}
                            onChange={(e) =>
                                handles?.setFilters({
                                    ...filters,
                                    [key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                            // endSource={
                            //     <ControlPopper trigger={filters[`${key}-order`] === 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}>
                            //         <GridOrderButton />
                            //     </ControlPopper>
                            // }
                        />
                    )}
                    
                </FilterRenderer>
            )
        }
        case 'visual': return {
            ...base,
            formatter: (props) => (
                <ControlDialog trigger={renderText(hasTranslation(props), value(props))}>
                    <AdvancedLabel label="Content preview" mb={5} as="h4"/>
                    {renderText(hasTranslation(props), value(props))}
                </ControlDialog>
            )

        }
        case 'number': return {
            ...base,
            headerRenderer: (p) => (
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <>
                    <Checkbox
                        {...rest}
                        label="Value"
                        type="checkbox"
                        // className={filterClassname}
                        value={filters?.[field?.name]}
                        onChange={(e) =>
                        handles?.setFilters({
                            ...filters,
                            [field?.name]: !filters?.[field?.name]
                        })
                        }
                    />
                    
                    </>
                    )}
                </FilterRenderer>
            ),
            formatter: (props) => value(props)
        }
        case 'date': return {
            ...base,
            formatter: (props) => getDate(value(props)) ? (
                isInThePast(value(props)) ? (
                    <Unfocus>{getDate(value(props))}</Unfocus>
                ) : getDate(value(props))
            ) : 'unknown'
        }
        case 'repeater': return {
            ...base,
            formatter: (props) => `${value(props)?.length ? value(props).length : 0} entries`
        }
        case 'image': return {
            ...base,
            width: 40,
            headerRenderer: (p) => (
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <>
                    <Checkbox
                        {...rest}
                        type="checkbox"
                        // className={filterClassname}
                        value={filters?.[field?.name]}
                        onChange={(e) =>
                        handles?.setFilters({
                            ...filters,
                            [field?.name]: !filters?.[field?.name]
                        })
                        }
                    />
                    
                    </>
                    )}
                </FilterRenderer>
            ),
            formatter: (props) => value(props) ? (
                <div 
                    style={{textAlign: 'center', cursor: 'pointer'}}
                    onClick={() => handles.setImage({src: parseImageSrc(value(props), true)?.full })}    
                >
                    <Image 
                        height="35px"
                        width="35px"
                        alt="thumb"
                        src={parseImageSrc(value(props), true).thumb}
                    />
                    
                </div>
            ) : 'No image set'
        }
        case 'gallery': return {
            ...base,
            width: 40,
            formatter: (props) => value(props) ? (
                <MediaMultiUploader
                    imageUrls={value(props)}
                    type='image'
                    callback={null}
                    label='Gallery preview'
                />
            ) : '0 uploaded'
        }
        case 'user': 
        return {
            ...base,
            // width: 80,
            formatter: (props) => {
                const user = userNames?.find(user => user?.value === value(props));    
                return user?.current ? <Link href="/account" style={{opacity: 0.2}}> you </Link> : (
                    <Link target="_blank" href={`/account/${value(props)}`}>
                        {userNames ? user?.label : ''}
                    </Link>
                )
            }
        }
        case 'email': return {
            ...base,
            formatter: (props) => <Link href={`mailto:${value(props)}`}>{value(props)}</Link>
        }
        default: return {
            ...base,
        }
            break;
    }
}

export const initColumns = ({collection, handles, locale = 'en', siteNames, userNames, filters, host = null, props}) => {
    if(!collection?.schema?.length || !Array.isArray(collection?.entries)) return [];

    // todo --> make these able to render later
    const display = f => !'repeat|relation|location|label'.split('|').includes(f?.type);
    const isBooking = collection?._id === '6401045f800fef330f4f4c04'
    // const hasField = atr =>  true
    
    const hasField = atr => collection?.entries?.filter(e => (
        containsSubstr(atr, '.') ?
            get(e, atr, null) :
            e?.[atr]
        )
    )?.length >= 1

    let fields = collection.schema
        .filter(f => display(f))
        .filter(f => f?.name != 'booking')
        .filter(f => collection?.entries?.length >= 1 ? hasField(f?.slug ? f.slug : f?.name) : f)
    //  .filter //todo --> add 'schema' column 'fields' selector/ hider

    
    if(isBooking) {
        fields = [
            ...fields,
            ...collection?.schema
                ?.find(f => f?.name === 'booking')
                ?.schema?.filter(f => 
                    ['days', 'persons', 'rooms', 'amount'].includes(f?.name)
                )?.map(f => (
                    {...f, slug: `booking.${f?.name}`}
                )),
            ...collection?.schema
                ?.find(f => f?.name === 'booking')
                ?.schema?.filter(f =>
                    ['arrival_date', 'departure_date'].includes(f?.name)
                )?.map(f => (
                    {...f, slug: `booking.${f?.name}`}
                ))
        ]
        console.log()
    }
    
    const columns = [
        // SelectColumn,
        ...baseFields({collection, host, handles, routing: props?.routing}),
        ...fields.map(field => formatField({field, handles, locale, siteNames, userNames, filters, schema: collection?.schema})),
        ...infoFields(collection, host, handles, userNames, filters)
    ];


    // ? temp add bookings status to columns
    return (isBooking) ? [
        columns[0],
        ...bookingFields({collection, host, handles, routing: props?.routing}),
        ...columns.slice(2, columns.length - 1),
    ] : columns
}