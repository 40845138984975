import EditIcons, {editPencil, editPencilBorder} from "./EditIcons";
import HelpIcons, {helpCircle, largeCircle} from "./HelpIcons";
import ColIcons from "./GridIcons";

import { elementIcon, elementIcons } from "./ElementIcons";
import EditorIcons from "./EditorIcons";
import { hasAttr } from "@util/Obj";

export const IconSets = {
    elements: elementIcons,
    editor: EditorIcons,
    edit: EditIcons,
    help: HelpIcons
}
export const icon = (set, icon) => hasAttr(IconSets, set, icon) ? IconSets?.[set]?.[icon] : helpCircle;

export {
    EditIcons,
    editPencil,
    editPencilBorder,

    HelpIcons,
    helpCircle,
    largeCircle,

    ColIcons,
    elementIcon
}
export default icon;