import { colors } from '@mosanic/styles';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { InputStyles } from '../Input';
import { BaseFieldStyles } from '../Input/Input.styles';



export const TextArea = styled(TextareaAutosize)` 
    ${BaseFieldStyles}
`

const TextAreaStyles = {
    TextArea
}
export default TextAreaStyles;