

 import { SEND_MAIL } from "@api/queries/mails";
import { useLazyQuery } from "@hooks/useApi";
import { useNotify } from "@mosanic/hooks";
import { useCallback } from "react";

const defaultTemplate = 'd-f00fa9adf0ad46f389bb8289412447a5';


export const useSendOne = () => {   

    const [sendMail, [data, loading, error]] = useLazyQuery('mailSendOne', SEND_MAIL)
    const notify = useNotify()


    const sendSimpleMail = useCallback(async ({address, subject, message}) => {
        const res = await fetch("/api/mail", {
            body: JSON.stringify({
                ...address,
                subject,
                name: 'Mosanic.io',
                message,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });

        const { error } = await res.json();
        if (error) {
            notify(error)
            return {error}
        } else {
            notify('Successfully send simple mail.', 'app', 'success');
            return {message: 'Mail send successful.'}
        }
    },[notify])

    const sendTemplateMail = useCallback(async ({address, mailData, templateId}) => {
        sendMail({
            ...address,
            mailData,
            templateId
        }).then(res => {
            const {data, error} = res;
            if(!error) {
                notify('Successfully send template mail.', 'app', 'success');
            } else {
                notify('Could not send template mail.');
            }
            return {data} || {error}
        
        })
        
    },[notify, sendMail])

    const handler = useCallback(({subject, to, preHeader, subHeader, mainHeader, mainText, simple = false, templateId = defaultTemplate, from = 'info@mosanic.io'}) => {

        if(!subject) { notify('No mail subject provided.'); return; }
        if(!to) { notify('No receiving mail address provided.'); return; }

        if(!preHeader || !subHeader || !mainHeader || !mainText) { 
            notify('Mail data is not valid.'); 
            return; 
        }

        const address = {
            to,
            from,
        }



        const result = simple ?
            sendSimpleMail({address, subject, message: mainText}) :
            sendTemplateMail({
                address, 
                templateId,
                mailData: {
                    subject,
                    preHeader,
                    subHeader,
                    mainHeader,
                    mainText
                }
            });
        return result;

    },[sendSimpleMail, sendTemplateMail, notify]);

    
    return [
        handler, 
        data, 
        loading, 
        error, 
    ]
}