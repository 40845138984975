import { convertEntry } from "@mosanic/cms/Query/useDataQuery"

import { containsSubstr, replaceParts } from "@util/Text"

import { formatText } from "./replace"
import { replaceWithSource } from "./value"

import { 
    useEntryReplaceSlugField, 
    useEntryReplaceUserFields 
} from "./entry"


export const useEntryMailConversion = ({entry, template, schema, baseRoute}) => {
    entry = convertEntry(entry ? entry : {})
    entry = useEntryReplaceUserFields({entry, schema})
    entry = useEntryReplaceSlugField({entry, baseRoute})
    return convertTemplate({template, entry})
}

export const convertTemplate = ({template, entry}) => {
    const replaceValue = (key, value) => containsSubstr(value, '\n') ?
        replaceParts(formatText(replaceWithSource(value, entry)), '\n', '<br/>') :
        formatText(replaceWithSource(value, entry))

    return template ? Object.fromEntries(
        Object.entries(template).map(([key, value]) => [key, replaceValue(key, value)] )
    ) : null
}