import { combineString, containsSubstr, excerptString, getDotCountInString, removePart, subStringFrom, subStringTill } from "@util/Text";
const defaultHost = 'mosanic.io';

export const SiteUrl = ({site}) => site ? combineString([
    !site?.customDomain ? site?.subdomain : '', 
    site?.customDomain ? site.customDomain : defaultHost
], '.') : '';

export const getSiteUrl = (site) => site ? combineString([
    !site?.customDomain ? site?.subdomain : '', 
    site?.customDomain ? site.customDomain : defaultHost
], '.') : '';

export const getSubDomainUrl = (site) => site ? combineString([
    site?.subdomain, 
    defaultHost
], '.') : '';

export const parseSiteUrl = (siteUrl) => {
    if(containsSubstr(siteUrl, `.${defaultHost}`)) return removePart(siteUrl, `.${defaultHost}`);
    if(getDotCountInString(siteUrl) >= 2) return {
        subdomain: subStringTill(siteUrl, '.'),
        customDomain: subStringFrom(siteUrl, '.'),
    }
    return siteUrl;
}

export const getSiteSlug = (current = '', name = null) => {
    const baseSlug = [
        {href: '/', label: 'Dashboard'},
        {href: `/site/`, label: name ? excerptString(name, 12, '...') : 'Site'},
    ]
    return current ? [...baseSlug, {label: current}] : baseSlug
}
export const siteSlugUrl = (sub) => (sub && sub != 'site') ? 
        `/site/${sub}` : 
        `/site`