




import {
    BarChartIcon,
    BorderBottomIcon, BorderTopIcon,
    CalendarIcon, ChatBubbleIcon, ClockIcon,
    DashboardIcon,
    ImageIcon,
    InputIcon,
    ListBulletIcon,
    MixIcon,
    PersonIcon, PlusCircledIcon, QuestionMarkCircledIcon,
    SizeIcon, SketchLogoIcon, StarIcon,
    TextAlignJustifyIcon, TextAlignLeftIcon, TextIcon, VideoIcon, ViewGridIcon
} from "@radix-ui/react-icons";


export const ELEMENTS_SIDEBAR = [{
    label: 'Quick add',
    value: 'quick-add',
    help: "Quick add element's are the building blocks of your page. You can drag & drop them into the layout.",
    icon: <PlusCircledIcon />
},{
    key: 'subAssets',
    value: 'divider',
},{
    label: 'Layouts',
    value: 'layouts',
    help: "Layouts are preset sections without much template content and styling.",
    icon: <ViewGridIcon />
    // <GridViewIcon fontSize="small" />
},{
    label: 'Structures',
    value: 'layouts-structures',
    icon: <DashboardIcon />
    // <ViewComfyRoundedIcon fontSize="small" />
},{
    key: 'subMain',
    value: 'divider',
},{
    label: 'Navigation',
    value: 'layouts-navigation',
    icon: <BorderTopIcon/>
    // <VerticalAlignTopIcon fontSize="small" />
},{
    label: 'Header',
    value: 'layouts-headers',
    icon: <BorderTopIcon/>
    // <VerticalAlignTopIcon fontSize="small" />
},{
    label: 'Footer',
    value: 'layouts-footers',
    icon: <BorderBottomIcon/>
    // <VerticalAlignTopIcon fontSize="small" />
},{
    key: 'subCore',
    value: 'divider',
},{
    label: 'About',
    value: 'layouts-about',
    icon: <TextIcon />
},{
    label: 'Benefits',
    value: 'layouts-benefits',
    icon: <ListBulletIcon />
},{
    label: 'Call to action',
    value: 'layouts-cta',
    icon: <StarIcon />
},{
    label: 'Gallery',
    value: 'layouts-images',
    icon: <ImageIcon />
},{
    label: 'Video',
    value: 'layouts-video',
    icon: <VideoIcon />
},{
    value: 'divider',
    key: 'subContents',
// },{
//     label: 'Contact',
//     value: 'layouts-contact',
//     icon: <EnvelopeOpenIcon/>
// },{
//     label: 'Appointments',
//     value: 'layouts-appointments',
//     icon: <CalendarIcon />
// },{
//     label: 'Reservations',
//     value: 'reservations',
//     icon: <CalendarIcon />
// },{
//     label: 'Newsletter',
//     value: 'layouts-newsletter',
//     icon: <EnvelopeClosedIcon />

},{    
    label: 'News',
    value: 'layouts-news',
    icon: <TextAlignLeftIcon />
    // <ArticleOutlinedIcon fontSize="small" />
},{
    label: 'Article',
    value: 'layouts-article',
    icon: <TextAlignJustifyIcon />
    // <ArticleOutlinedIcon fontSize="small" />
},{
    value: 'divider',
    key: 'subNews',
},{
    label: 'Products',
    value: 'layouts-products',
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15px" height="15px"><g data-name="Layer 2"><g data-name="shopping-cart"><rect width="24" height="24" opacity="0"></rect><path d="M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7zm-4.7 7H8.76L7.13 8h12.25z"></path><circle cx="7.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle></g></g></svg>
},{
    label: 'Product',
    value: 'layouts-pricing',
    icon: <SketchLogoIcon />
},{
    label: 'Projects',
    value: 'layouts-projects',
    icon: <MixIcon />
},{
    label: 'Events',
    value: 'layouts-events',
    icon: <CalendarIcon />
},{
    label: 'Forms',
    value: 'layouts-from',
    icon: <InputIcon />
},{
//     value: 'divider',
//     key: 'subCta',
// },{
//     label: 'Menu',
//     value: 'layouts-menu',
//     icon: <TextAlignCenterIcon />
// },{
    value: 'divider',
    key: 'subSpecific',
},{
//     label: 'Location',
//     value: 'layouts-location',
//     icon: <SewingPinIcon />
// },{
    label: 'Business',
    value: 'layouts-opening-hours',
    icon: <ClockIcon />
},{
    label: 'Reviews',
    value: 'layouts-reviews',
    icon: <ChatBubbleIcon />
},{
    label: 'Team',
    value: 'layouts-team',
    icon: <PersonIcon />
},{
//     label: 'Calendar',
//     value: 'layouts-calendar',
//     icon: <CalendarIcon />
// },{
//     label: 'Info Graphics',
//     value: 'layouts-info-graphics',
//     icon: <PieChartIcon />
// },{
    label: 'Charts',
    value: 'layouts-charts',
    icon: <BarChartIcon />
},{
    label: 'FAQs',
    value: 'faqs',
    icon: <QuestionMarkCircledIcon  />
},{
    label: 'Dialogs',
    value: 'layouts-dialogs',
    icon: <SizeIcon />
},{
//     label: 'Partners',
//     value: 'layouts-partners',
//     icon: <StarIcon />
// },{
    label: 'Misc',
    value: 'layouts-misc',
    icon: <PersonIcon />
// },{
//     value: 'divider',
//     key: 'subBusiness',
// },{
//     label: 'Music',
//     value: 'layouts-music',
//     icon: <SpeakerLoudIcon />
// },{
//     label: 'Tour Dates',
//     value: 'layouts-tour-dates',
//     icon: <CalendarIcon />
}]

