import { smallestDefaultWidth } from "./reducer";


export const transformBreakPointToScreen = (screen, breakPoint, previousX = 0, removeId = false) => ({
    ...(removeId ? {...screen, _id: undefined} : screen),
    size: {
        width: getWidthByBreakPoint(breakPoint),
        minWidth: breakPoint.minWidth,
        maxWidth: breakPoint.maxWidth,
    },
    position: {
        x: previousX,
        y: 0,
    }
});

const getWidthByBreakPoint = (breakPoint) => 
    breakPoint.minWidth === 0 ? smallestDefaultWidth :
        breakPoint.maxWidth === null ? breakPoint.minWidth :
            breakPoint.maxWidth;