
import { passControlState } from "@mosanic/utils/schema";
import { createContext, useContext } from "react";
import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";


export const ExpanderContext = createContext(undefined);


const Accordion = ({children, expanded = false, trigger = null,  timeout = 400, direction = null, fullWidth = false}) => {

    const [isExpanded, setExpanded] = useState(expanded)
    const { getCollapseProps, getToggleProps } = useCollapse({ 
        isExpanded
    })

    useEffect(() => {
        setExpanded(expanded)
    }, [expanded])

    return (
        <>
        {trigger ? (
            <span 
                className={isExpanded ? 'expanded' : 'closed'}
                style={{width: fullWidth ? '100%' : 'auto'}}
                {...getToggleProps({
                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}
            >
                {trigger}
            </span>
        ):null}
        <Expandable 
            expanded={getCollapseProps} 
            timeout={timeout}
            direction={direction}
        >
            {children}
        </Expandable>
        </>
    )
}
const Expandable = ({children, expanded, timeout, direction}) => (
    <div {...expanded()}>
        {children}
    </div>
);





const Expanders = ({fields, editor, ref, active}, children) => (
    <ExpanderContext.Provider value={editor}>
        {children}
    </ExpanderContext.Provider>
)




const ExpanderHeader = ({fields, editor, ref, active}, children) => {
    const {source, handler} = editor;
    const ctx = useContext(ExpanderContext)
    const isActive = source?.identifier === ctx?.source?.active;
    const controlProps = {
        control: {
            active,
            notActive: !isActive,
        },
        handler: () => ctx?.handler({value: !isActive ? source?.identifier : null, extend: 'active'})
    }

    return (
        <div
            onClick={() => {
                ctx?.handler({value: !isActive ? source?.identifier : null, extend: 'active'})
            }}
        >
            {source?.content}
            {passControlState({children, controlProps})}
        </div>
    )
}

const Expander = ({fields, editor, ref}, children) => {
    const {source, handler} = editor || {source: null, handler: null}
    const ctx = useContext(ExpanderContext)
    const trigger = !ctx ? source?.content : null
    const active = (trigger || !ctx) ? null : source?.identifier === ctx?.source?.active
     
    return (
        <>
        {/* {JSON.stringify({active, editor, ctx})} */}
        <Accordion expanded={active} trigger={trigger}>
            {/* {children} */}
            {passControlState({children, controlProps: {
                control: {active, notActive: !active}}
            })}
        </Accordion>
        </>
    ) 
}

export default Expanders;
export {
    ExpanderHeader,
    Expander
}