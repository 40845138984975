import { ButtonGroup } from "@mosanic/fields/Button/ButtonGroup";
import Button from "@mosanic/fields/Button/Buttons";
import { AdvancedLabel } from "@mosanic/items";
import { SizeIcon } from "@radix-ui/react-icons";
import styled from "styled-components";
import { useSourceTabs } from "./useSourceTabs";
import { useEntryTabs } from "./useEntryTabs";
import { useColumnTabs } from "./useColumnTabs";
import { useDevTabs } from "./useDevTabs";
import { capitalizeFirst } from "@util/Text";



const Wrapper = styled.div` 
    display: flex;
    justify-content: space-between;
    width: 100%;
    
`

const isValue = (option, value) => value && (option.value === value);


const Group = ({options, handler, value, children, ...other}) => (
    <ButtonGroup small right {...other}> 
        {options?.map(option => (
            <Button
                key={option.value}
                label={isValue(option, value) && option?.icons ? '' : option.value}

                onClick={() => handler(option.value)}

                icon={option?.icons ? (
                    isValue(option, value) ? 
                        option.icons[0] : 
                        option.icons[1] 
                ): option.icon}
                active={isValue(option, value)}
                disabled={other?.disabled}
                {...option}
            />
        ))}
    </ButtonGroup>
) 

const HeaderWrapper = ({title, helpText, dialog, children}) => (
    <Wrapper>
        <AdvancedLabel as="h4" style={{marginTop: dialog.expand ? '15px' : 0}} span helpText={helpText} > 
            {title}
            <Button ghost icon={<SizeIcon />} mt="-2px"  style={{display: 'inline-block'}} onClick={dialog.handleExpand} />
        </AdvancedLabel>
        {children}
    </Wrapper>
)

export const Header = ({
    dialog,
    title,
    helpText,

    value,
    handler,
    itemType,
    context
}) => {
    title = !value?.dev ? title : capitalizeFirst(value.dev);
    
    const groups = [
        useSourceTabs({value, handler, context}),
        useEntryTabs({value, handler, itemType}),
        useColumnTabs({value, handler, itemType}),
        useDevTabs({value, handler}),
    ]
    .filter(g => g.options.length > 1)
    .filter(g => !value?.dev || g.key === 'dev')
    .map(g => !value?.dev ? g : ({
        ...g, 
        options: g.options?.filter(o => o.value === value.dev)
    }));

    return (
        <HeaderWrapper {...{title, helpText, dialog}}>
            {groups.map(group => (
                <Group key={group?.key} {...group} />
            ))}
        </HeaderWrapper>
    )
}
