import { colors } from "@mosanic/styles";
import styled, { css } from "styled-components";

const Required = styled.span` 
    
    &:after {
        content: "*";
        display: inline-block;
        color: ${colors.util.gray.base};
        padding-left: 1.5px;
        position: absolute;
    }
`
export const InputRequired = Required;
const End = styled.span`
    font-size: 12px;
    position: absolute;

    right: 4px;
    top: 0;
    bottom: 0;
    min-width: 8px;
    display: flex;
    align-items: center; 

    svg {
        transform: scale(0.7);
    }
`



const Label = styled.label`
    transition: all ease-in-out 0.1s;
    font-size: 12px;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 5px;
    ${props => !props.noCase && css` 
        text-transform: capitalize;
    `}

    ${props => props.shrink && css ` 
        left: 6px;
        top: -8px;
        font-size: 9px;
        padding: 0 2px;
        /* background: #fff; */
        color: inherit;
        display: inline-block;
        z-index: 0;
        &::before {
            z-index: -1;
            content: '';
            display: block;
            background:inherit;
            height: 4px;
            width: 110%;
            bottom: calc(50% - 2px);
            margin: 0 -5px;
            position: absolute;
        }
    `}
`
export const InputLabel = Label;

const BaseField = css` 
    flex: 1 0;
    min-width: 50px;
    min-height: 25px;
    font-size: inherit;
    background-color: transparent;
    padding-left: 5px;
    border: 0;
    font-size: 12px;
    &:focus {
        outline: none;
    }
`
export const BaseFieldStyles = BaseField;

const Field = styled.input`

    ${BaseField}
    
`

const SmallSize = css` 
    ${Field} {
        font-size: 11px;
        line-height: 16px;
    }
`
const LargeSize = css` 
     ${Field} {
        font-size: 16px;
        line-height: 18px;
    }
    ${Label}  {
        margin: 2px 0;
    }
`
const ExtraLargeSize = css` 
    ${Field} {
        font-size: 22px;
        line-height: 24px;
    }
    ${Label}  {
        margin: 3px 0;
    }
`
const getSize = (size) => {
    switch(size){
        case 'small': return SmallSize;
        case 'sm': return SmallSize;
        case 'md': return css` 
            padding: 6px 4px;
            ${Field} {
                font-size: 14px;
                line-height: 16px;
            }
            ${Label} {
                margin: 2px 0;
            }
        `;
        case 'large': return LargeSize;
        case 'lg': return LargeSize;
        case 'extra-large': return ExtraLargeSize;
        case 'xl': return ExtraLargeSize;
        default: return SmallSize;
    }
}



const Container = styled.div`
    position: relative;
    transition: border ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border: 1px solid ${colors.util.gray.light};
    border-radius: 5px;
    /* max-width: 350px; */
    
    ${props => props.fullWidth && css` 
        width: 100%;
    `}
    ${props => props.center && css` 
        margin-left: auto;
        margin-right: auto;
    `}

    ${props => props?.valid === 'true' ? css`
        fieldset {
            border-color: ${colors.util.bright.green};
        }
        border-color: ${colors.util.bright.green};
    ` : props?.valid === 'false' ? css` 
        fieldset {
            border-color: ${colors.util.red.lighter};
        }
        border-color: ${colors.util.red.lighter}!important;
    `: ``}

    ${props => props.focussed ? css` 
        border: 1px solid ${colors.util.gray.base};
        opacity: 1;
    ` : css` 
    `}

    ${props => (props.empty || props.disabled) && css` 
        opacity: 0.8;
        color: ${colors.util.gray.base};
    `}
    &:hover {
        border: 1px solid ${colors.util.gray.base};
        &:hover {
            opacity: 1;
        }
    }
    ${props => props.disabled && css` 
        ${Label} {
            opacity: 0.5;
        }
        textarea {
            opacity: 0.5;
        }

        &:hover {
            opacity: 0.8;
            border-color: ${colors.util.gray.light};
            ${End} {
                transform: scale(1.05);
            }
            *:hover{
                cursor: not-allowed!important;
            }
        }
    `}

    ${props => props.noBorder ? 'border: none!important;' : ``}

    ${props => props.ninja && css`
        border-color: transparent;
        font-size: inherit;
        font-weight: inherit;
        ${Field} {
            font-size: ${props.ninja.size ? props.ninja.size+'px' : 'inherit'};
            font-weight: ${props.ninja.weight ? props.ninja.weight : 'inherit'};
            color: var(--color-texts-opacity)!important;
            font-family: inherit!important;
            background: inherit !important;
            color: inherit !important;
            padding: 0 !important;
            margin: 0 !important;   
        }  
        &:hover {
            border-color: transparent;
        }
        
    `}
    ${props => getSize(props.size)}
    
`

const InputStyles = {
    Container,
    BaseField,
    Field,
    Label, 
    End,
    Required,
}
export default InputStyles;