import { areEqual, canMap } from '@util/Arr';
import { produce } from 'immer';
import { HYDRATE } from 'next-redux-wrapper';

import BREAKPOINTS_ACTION_IDS from './action';
const Action = BREAKPOINTS_ACTION_IDS;

export const DEFAULT_BREAKPOINTS = [{
    name: 'Base',
    _id: 'base',
    minWidth: 0,
    maxWidth: null,
    canDelete: false
},{
    name: 'Mobile',
    _id: 'xs',
    minWidth: 0,
    maxWidth: null,
    canDelete: false
}, {
    name: 'Landscape',
    _id: 'sm',
    minWidth: 575,
    maxWidth: null,
    canDelete: true
}, {
    name: 'Tablet',
    _id: 'md',
    minWidth: 750,
    maxWidth: null,
    canDelete: false
}, {
    name: 'Laptop',
    _id: 'lg',
    minWidth: 975,
    maxWidth: null,
    canDelete: true
}, {
    name: 'Desktop',
    _id: 'xl',
    base: true,
    minWidth: 1200,
    maxWidth: null,
    canDelete: false
}];

const initialState = DEFAULT_BREAKPOINTS;

const breakpointsReducer = (breakpoints = initialState, action) => produce(breakpoints, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...breakpoints, ...action.payload};

        case Action.BREAKPOINTS_RESET:
            draft = DEFAULT_BREAKPOINTS;
            return draft;

        case Action.BREAKPOINTS_REORDER:
            return draft.sort( compareWidths );

        case Action.BREAKPOINT_ADD:
            if(breakpointExists(draft, action.payload._id)) return;

            draft.push(action.payload);
            return draft;
       
        case Action.BREAKPOINT_REMOVE:
            if(!breakpointExists(draft, action.payload)) return;
            draft = draft.filter(bp => bp._id != action.payload);
            return draft;

        case Action.BREAKPOINT_UPDATE:
            if(!breakpointExists(draft, action.payload._id)) return;
            draft = draft.map(bp => {
                if(bp._id != action.payload._id) return bp;
                return {...bp, ...action.payload};
            });
            return draft;

        default:
            return breakpoints;
    }; 
});
export default breakpointsReducer;

/**
 * Check if breakpoint id exists
 * @param {redux} state breakpoints
 * @param {string} find id
 * @returns {boolean}
 */
const breakpointExists = (state, find) => state.filter(bp => bp._id === find)?.length >= 1

/**
 * Sort breakpoints by min width
 * @param {bp} a 
 * @param {bp} b 
 * @returns {sorted array}
 */
function compareWidths( a, b ) {
    if ( a.minWidth < b.minWidth ) return -1;
    if ( a.minWidth > b.minWidth ) return 1;
    return 0;
}
export const canReorderBreakpoints = (breakpoints) => {
    const sortedCopy = produce(breakpoints, copy => {
        copy.sort( compareWidths )
    });
    return !areEqual( sortedCopy, breakpoints, 'minWidth' );
}

//Select current redux feature
const breakpointsFeature = state => state.present?.breakpoints;

//Get all breakpoints
export const getBreakpointsSelector = state => breakpointsFeature(state);

const validateBp = (bp, width) => (
    (
        bp?.minWidth && bp?.maxWidth && 
        bp?.maxWidth >= width && bp?.minWidth <= width
    ) ||
    (!bp?.minWidth && bp?.maxWidth >= width) || 
    (!bp?.maxWidth && bp?.minWidth <= width)
)

export const getBreakpointsBySize = (state, width) => {
    const breakpoints = canMap(state) ? state : getBreakpointsSelector(state);
    const valid = [];
    breakpoints.map(bp => {
        if(validateBp(bp, width)) valid.push(bp);
    })
    return valid
}
export const getBreakPointByWidth = (state, width) => {
    let breakpoints = canMap(state) ? state : getBreakpointsSelector(state);
    breakpoints = [...breakpoints]?.reverse()?.filter(bp => bp?._id != 'base');
    let previous, start, current, next, index = 0;
    const last = breakpoints.length;
    breakpoints.map(bp =>{
      index++;
      if(validateBp(bp, width) && !current) //width = larger than bp
        previous = bp;
      
      if(validateBp(bp, width) && !current){ //value = smaller 
        current = previous;
        next = bp;
      }

      if(!current && index === last)
        current = previous;
    });

    return current;
}


export const getBreakpointById = (state, bp) => getBreakpointIn(getBreakpointsSelector(state), bp);
export const getBreakpointIn = (arr, _id) => arr.find(bp => bp._id === _id);


