import { AdvancedLabel, WrapInput } from '@mosanic/items';
import { useState } from 'react';
import {default as t} from './TextArea.style';
import { InputStyles as s } from '../Input';

const TextArea = ({
    //Essential
    name = '',
    type = 'text',
    value = null,
    onChange = null,
    placeholder = null,

    //Overwrite
    label = null,

    //Styling
    fullWidth = false,
    small = null,
    size = null,
    ninja = false, 
    wrap = false, 
    
    //Specific
    minRows = 3,
    maxRows = 6,

    //Validation
    valid = null,
    empty = false,
    disabled = false,
    required = false,

    endAdornment = null,
    endSource = null,

    ref = null,

    //Dep
    error = false,
    handler = null, 
    inputProps = null,
    noCase = false,
    ...other
}) => {
    const [focussed, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false);

    const [local, setLocal] = useState()
    const handleInput = (e) => disabled ? null : onChange ? 
        onChange(e.target?.value ? e.target.value : '') : 
        setLocal(e.target?.value ? e.target.value : '')
    
        // console.warn('Please use other text area!')
    return (
        <WrapInput wrap={wrap ? wrap : false} fullWidth={fullWidth}>
            <s.Container 
                className="input_field"
                focussed={focussed}
                disabled={disabled}
                valid={valid} 
                empty={empty}
                ninja={ninja} 
                size={size}
            >
                <s.Label shrink={Boolean(ref?.current || value || focussed || other?.placeHolder)} noCase={noCase}>
                    {
                        ninja ? "" :
                        label ? label :
                        name ? name : ""
                    }
                    {required && <s.Required />}
                </s.Label>
                <t.TextArea 
                    ref={ref}
                    placeholder={focussed ? placeholder : undefined}
                    size={small ? 'small' : size ? size : null}
                    minRows={minRows}
                    maxRows={maxRows}
                    value={value ? value : local}
                    onChange={handleInput}
                    {...handler} 
                    {...other}
                    // innerRef={undefined}
                    onFocus={(e) => {
                        if(other?.onFocus) other.onFocus(e)
                        onFocus();
                    }} 
                    onBlur={(e) => {
                        if(other?.onBlur) other.onBlur(e)
                        onBlur();
                    }}
                />
                <s.End>
                    {endAdornment}
                    {endSource}
                </s.End>
            {error && error}
            </s.Container>
        </WrapInput>
    )
}
export default TextArea;