import { colors } from "@mosanic/styles";
import { RowWrap } from "@mosanic/styles/flex.styles"
import styled, {css} from "styled-components"


export const RowAdd = styled.button`
    z-index: 9;
    transform: scale(0.6);
    transform-origin: center bottom;
    position: absolute;
    margin-top: -10px;
    left: calc(50% - 30px);
    transition: all ease-in-out 0.3s;
    opacity: 0;
    text-align:center;
    width: 60px;
    background: none;
    height: 0px;
    border: none!important;
    .trigger {
        border: none;
        background: none!important;
    }
    span.add {
        transition: all ease-in-out 0.3s;
        background: ${colors.light.primary};
        color: #fff;
        height: 35px;
        width: 35px;
        line-height: 10px;
        border-radius: 50%;
        padding: 6px 8px;
        font-weight: bold;
        font-size: 14px;

        &:hover {
            background: ${colors.util.primary.dark};
        }
    }
`;


const ItemWrapperStyles = {
    RowAdd,

}
export default ItemWrapperStyles;