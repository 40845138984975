import { dataNormalized, dataNormalizing } from "./action";
// import { normalize, schema } from "normalizr";
import produce from "immer";



export const normalizeMiddleware = ({dispatch}) => (next) => (action) => {

    //Handle normalization before set
    if(action.type.includes('SET') && (action?.meta?.normalizeKey || Array.isArray(action?.meta?.normalize)) && action?.payload) {
        const identifier = action.meta?.type ? action.meta.type : action.meta?.feature;
        let data = {}, map = {}, used = '';
        
        dispatch(dataNormalizing({data: action.payload, feature: identifier }));

        if(action?.meta?.bulk === true){
            //Bulk normalize payload
            if(hasMultipleEntries(action.payload)) {
                data = getBulkNormalizedData(action);
                used = 'bulk normalized';
            } else {
                dispatch(setNotification({
                    message: "Bulk normalizer couldn't find multiple object entries.", feature: identifier
                }));
            }
        } else if(Array.isArray(action?.meta?.normalize)){
            data = getCustomNormalizeSchema({templates: action.meta.normalize, payload: action.payload, normalizeKey: '_id'});
            used = 'custom normalized';
        } else if(identifier != 'layout') {
            //Default array to obj 
            data = getNormalizedData({
                payload: action.payload, 
                normalizeKey: action.meta.normalizeKey,
                identifier
            });
            used = 'default normalized';
        } else {
            data = action.payload;
            used = 'not normalized';
        }
        
        dispatch(dataNormalized({data: data, feature: identifier}));

        next({...action, payload: data, normalizeKey: null, meta: {...action.meta, used}});
    } else {
        next(action);
    }

}

export const hasMultipleEntries = (obj) => {
    return Object.entries(obj).length >= 2;
}

export const getCustomNormalizeSchema = (data) => {
    const {templates, payload, normalizeKey} = data;
    let schemas = {};
    templates.forEach(template => {
        let attribute = template.attribute;
        // let newSchema = new schema.Entity(
        //     attribute, {}, { idAttribute: template.normalizeKey }
        // ); 
        // schemas = {
        //     ...schemas,
        //     [template.attribute]: [newSchema]
        // };
    });

    // const dataSchema = new schema.Entity(
    //     'element', {...schemas}, { idAttribute: normalizeKey }
    // );

    const normalizedData = payload ; //normalize(payload, dataSchema);
    return normalizedData ? normalizedData : false;
}

export const getNormalizedData = (data) => {
    const {payload, normalizeKey, identifier} = data;

    // const dataSchema = new schema.Entity(identifier, {}, { idAttribute: normalizeKey });

    const normalizedData = payload; //normalize(payload, [dataSchema]);
    return normalizedData ? normalizedData : false;
}

export const getBulkNormalizedData = (action) => {
    let data, map;

    Object.entries(action.payload).forEach(([key, element]) => {
        if(element?.length < 0 ){
            data = { ...data, [key]: {} };
            map = { ...map, [key]: [] };
        }else if(key != 'layout') {
            let normalizedData = getNormalizedData({
                payload: element, 
                normalizeKey: action.meta.normalizeKey,
                identifier: key
            });

            data = { ...data, [key]: normalizedData?.entities[key] };
            map = { ...map, [key]: normalizedData?.result };
        } else {
            data = { ...data, [key]: element };
            // map = { ...map, [key]: [] };
        }
    });
    data = {
        entities: data,
        map: map
    };
    return data;
}