/* eslint-disable react-hooks/exhaustive-deps */

import { THEME, THEMES } from "@api/queries/themes";
import { useLazyQuery } from "@hooks/useApi";
import { useEffect } from "react";

export const useTheme = ({_id}) => {
    const [handler, [theme, loading, error]] = useLazyQuery('themeById', THEME, {_id},) 
    
    useEffect(() => {
        if(_id) handler({_id});
    },[_id])

    return [handler, [theme, loading, error]];
}
export const useThemes = ({_ids, onlyUser = false}) => {
    const [handler, [themes, loading, error]] = useLazyQuery('themeMany', THEMES) 
    
    useEffect(() => {
        if(Array.isArray(_ids) && _ids?.length >= 1) {
            handler({
                filter: {
                    OR: [ 
                        ..._ids?.map(_id => ({_id})),
                        {isUserGenerated: onlyUser}
                    ]
                }
            });
        } else if( !onlyUser) {
            handler({
                filter: {
                    isUserGenerated: false
                }
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_ids])

    return [themes, loading, error];
}
