import { isServer } from "@util/Device";
import { useEffect, useState } from "react";


export const scrollTop = () => isServer() ? null :
   window.scrollTo({top: 0, behavior: 'smooth'});

export const useShowScrollTop = () => {
    const [showScroll, setShowScroll] = useState(false)
    
    useEffect(() => {
        const checkScrollTop = () => {    
            if (!showScroll && window.pageYOffset > 400){
                setShowScroll(true)    
            } else if (showScroll && window.pageYOffset <= 400){
                setShowScroll(false)    
            }  
        };
        window.addEventListener('scroll', checkScrollTop)

        return () => window.removeEventListener('scroll', checkScrollTop)
    },[showScroll])
    
    return [showScroll, scrollTop]
}