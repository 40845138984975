export const Leaf = ({ attributes, children, leaf, prod = false}) => {
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  // // For inline at the end of a block, put the cursor inside the final {text: ''} node
  // if (leaf.link || leaf.button) {
  //   children = 
  //     <span className={css` padding-left: 0.1px; `}>
  //       {children}
  //     </span>
  // }
  if(prod) return children
  return (
    <span
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      style={{paddingLeft: 
        (leaf.text) === ''
          ? `0.1px;`
          : null
      }}
      {...attributes}
    >
      {children}
    </span>
  )

  // return <span {...attributes}>{children}</span>
}