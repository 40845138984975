import { RenderText, Serialize } from '@mosanic/fields';
const Editor = dynamic(() => import(
    /* webpackChunkName: 'Inline editor/ editor' */
    '@mosanic/fields/Editor'
))
import { useInspectState } from '@mosanic/hooks';
import { useEditorMenu } from '@mosanic/hooks/useEditorMenu';
import { omitDeep } from '@util/Obj';
import isEqual from '@util/isEqual';
import { useCallback, useEffect, useState } from "react";
import { getInitialContent } from './Handler/Content';
import dynamic from 'next/dynamic';
const interval = 2500; 

export const InlineEditor = ({
    content = '', 
    handler = null,
    ninja = false,
    oneLine = true,
    fullToolbar = false,

    active=false
}) => {
    if(!content) content = getInitialContent(content, 'span');

    // const [enabled, toggleEditor] = useEditorMenu(screen);
    const [enabled, setEnabled] = useState(active)

    //Store
    // const [editorState, setEditorState] = useState(showEditor)
    const [data, setData] = useState(content)
    const [localData, setLocalData] = useState(content)

    useEffect(() => {
        if(
            localData?.length === 1 &&
            localData[0]?.children?.length === 1 &&
            localData[0]?.children[0]?.text === ''
        ){
            // setLocalData(getInitialContent('...', 'span'))
        }
    }, [content, localData])

    useEffect(() => {
        if(!isEqual(content, data) && !active) {
            setData(content);
            setLocalData(content)
        }
    }, [content, data, active])
 
    
    //Handle
    const setContent = (value) => {

        setData(value);
        setLocalData(value)
    }

    //Handle input timing
    useEffect(() => {
        let timer;
        if(active){
            if(enabled != active) setEnabled(active);
            timer = setInterval(() => {
                if(!isEqual(localData, content) && active) {
                    dispatchData(localData);
                }
            }, interval)
        } else {
            //exit
            if(enabled){
                setEnabled(false);
                dispatchData(localData)
            }
            // dispatchData(localData)
        }

        return () => clearInterval(timer);

    }, [dispatchData, localData, enabled, active, content]);


    //Update to redux
    const dispatchData = useCallback((newData) => {

        handler(newData);
    }, [handler]);

    const [state, setActive] = useState(false)
 
    // useEffect(() => {
    //   setTimeout(() => {
    //     if(state != (enabled && current))
    //     setActive(enabled && current)
    //   }, 200);
    // }, [enabled, current, state]) 


    return Boolean(active) ? (
        <Editor
            content={data}
            setContent={setContent} key="slate"
            fullToolbar={fullToolbar}
            ninja={ninja}
            singleLine={oneLine}
        />
    ) : (
        <span>
            {(ninja && oneLine) ? ( 
                <Serialize content={data}/>
            ) : (
               <RenderText content={omitDeep(content)}/> 
            )}
        </span>
    )
    
};

export default InlineEditor;