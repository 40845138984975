const ID = '[cart]'
export const ADD = `${ID} Add`
export const REMOVE = `${ID} Remove`
export const REMOVE_MULTIPLE = `${ID} Remove x`
export const CLEAR = `${ID} Clear`
export const DATA = `${ID} Data`

export const CHECKOUT_REQUEST = `${ID} CHECKOUT_REQUEST`
export const CHECKOUT_SUCCESS = `${ID} CHECKOUT_SUCCESS`
export const CHECKOUT_FAILURE = `${ID} CHECKOUT_FAILURE`
export const RECEIVE_PRODUCTS = `${ID} RECEIVE_PRODUCTS`

const CART_IDS = {
    ADD,
    REMOVE,
    DATA,
    REMOVE_MULTIPLE,
    CLEAR
}
export default CART_IDS;

export const cartDataAction = (attr, data) => ({
    type: DATA,
    payload: {attr, data}
})

export const addCartAction = (productId, priceId) => ({
    type: ADD,
    payload: {productId, priceId}
})

export const removeCartAction = (productId, priceId) => ({
    type: REMOVE,
    payload: {productId, priceId}
})

export const removeMultipleCartAction = (productIds) => ({
    type: REMOVE,
    payload: productIds
})

export const clearCartAction = () => ({
    type: CLEAR,
}) 