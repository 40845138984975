import { Button } from '@mosanic/fields/Button/Buttons';
import { AdvancedLabel, ControlDialog, InputGroup } from '@mosanic/items';
import { useEffect, useState } from 'react';
import Input from '@mosanic/fields/Input';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { SuccessAlert } from 'src/components/Core/Utils';
import { FormGroup } from '@mosanic/fields/Form';
import { Condition } from '@JSX';


export const MetaFields = ({meta, handler, trigger, dialog = true}) => {
	const { handleSubmit, control, watch, reset, formState: { errors, isValid } } = useForm({mode: 'all'});

 	useEffect(() => {
		reset(meta)
	},[meta, reset])
	const inputProps = {control, fullWidth: true, errors}

	const [modalStateManager, setModalStateManager] = useState(false);
	const [updated, setUpdated] = useState(false);

	const open = () => {
		setModalStateManager(true);
		setUpdated(false);
	}
	const onSubmit = (data) => {
		handler(data);
		setUpdated(true);

		setTimeout(() => {
			setModalStateManager(false);
		}, 1000);
	}

	return (
		<>

<Condition when={dialog} wrapper={children=> (
	<>
	<span onClick={open}>
			{trigger}  
		</span>  	
		<ControlDialog 
			state={modalStateManager} 
			close={() => setModalStateManager(false)} 
			size="xs"
			fullWidth={true} 
			portal={false}
		>{children}</ControlDialog>
	</>
)}>
			<AdvancedLabel span helpText="From here you can edit the meta data of your assets and pages.">
				Edit SEO fields.
			</AdvancedLabel>
			<FormGroup disabled={!updated} onSubmit={handleSubmit(onSubmit)}>

				<Input
					name="title" label="Title" type="text" {...inputProps}
					rules={{required: "You should enter the title for seo purposes."}}
				/>

			{dialog ? (
				<>
				<Input 
					name="alt" label="Alt text" type="textarea" {...inputProps}
					rules={{required: "Give a description about your asset."}}
				/>
				<Input 
					name="folder" label="Folder" type="text" {...inputProps}
				/>
				</>
			) : (
				<>
				<Input 
					name="description" label="Description" type="textarea" {...inputProps}
				/>
				<Input 
					name="keywords" label="Keywords" type="text" {...inputProps}
					rules={{required: "Comma seperated and required."}}
				/>
				<Input 
					name="language" label="Language" type="text" {...inputProps}
				/>
				<Input 
					name="robots" label="Robots indexing" type="switch" {...inputProps}
				/>
				</>
			)}
				

				<Button 
					wrap fullWidth
					label={dialog ? "Store meta" : "Update"}
					theme="primary"
					size="large"
					type="submit"
					disabled={Boolean(!isValid)}
				/>

			</FormGroup>
			{updated && (
				<SuccessAlert successMessage={"Updated meta"}/>
			)}
		
				
		</Condition>	
		
		</>
	);
};