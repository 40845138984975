
import { USER } from "@api/queries/users";
import { useVarQuery } from "@hooks/useApi";
import { useGet } from "@mosanic/cms";


export const useUser = ({_id}) => {
    // const [user, loading, error] = useVarQuery('userById', USER, {_id}, Boolean(_id))
    const [getUser, [user, loading, error]] = useGet({
        params: {_id},
        type: 'user',
        query: USER,
        silent: true,
        siteCheck: false
    }) 
    const {firstName, lastName} = user?.author || {firstName: undefined, lastName: undefined};

    return [
        {...user, firstName, lastName},
        loading,
        error,
        getUser
    ]
}