/* eslint-disable react/display-name */
import { useSlate, useSlateStatic } from 'slate-react';
// import { AdvancedPicker } from '../Color';
import dynamic from "next/dynamic";
const AdvancedPicker = dynamic(() => import(
  /* webpackChunkName: 'Advanced picker from Editor' */
  "../Color/AdvancedPicker"
))
import { TEXT_ALIGN_TYPES } from './Constants';
import { Insert, Toggle, Wrap } from './Handler';
import Validate from './Validate';
import { Button, Icon, Text } from './Wrappers';
import { ControlDialog } from '@mosanic/items';
import { useState } from 'react';
import TextField from '../TextField/Fields';
import { isImageUrl } from '@util/Url';
import { ButtonIcon, ImageIcon, Link1Icon, LinkBreak1Icon } from '@radix-ui/react-icons';


const ButtonContent = ({icon, text}) => !text ? icon : (
  <Text>{text}</Text>
);



const FormatButton = ({ format, icon, text = null }) => {
  const editor = useSlate()
  return (
    <Button
      reversed
      active={Validate.isFormatActive(editor, format)}
      onClick={() => Toggle.toggleFormat(editor, format)}
    >
      <ButtonContent icon={icon} text={text}/>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={Validate.isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        Toggle.toggleMark(editor, format)
      }}
    >
      {icon}
    </Button>
  )
}

const BlockButton = ({ format, icon, formatGroup = null, text = null }) => {
  const editor = useSlate()
  return (
    <Button
      active={Validate.isBlockActive(
        editor,
        formatGroup ? formatGroup : format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type' 
      )}
      onMouseDown={event => {
        event.preventDefault()
        if(!format) return;
        Toggle.toggleBlock(editor, format)
      }}
    >
      <ButtonContent icon={icon} text={text}/>
    </Button>
  )
}
const ColorButton = ({}) => (
  <Button className='mosanic-icon -small'>
    <AdvancedPicker />
  </Button>
)
const SourceButton = ({endSource}) => (
  <Button className='mosanic-icon -small'>
      {endSource}
  </Button>
)

/** InlineElements */
const ToggleLinkButton = () => {
  const editor = useSlate()
  const [modal, setModal] = useState(false)
  const handleLink = (event) => {
    if (Validate.isLinkActive(editor)) {
      Wrap.unwrapLink(editor)
    } else {
      event.preventDefault()
      const url = window.prompt('Enter the URL of the link:')
      // setModal(true);
      if (!url) return
      Insert.insertLink(editor, url)
    }
        
  }
  return (
    <>
    <ControlDialog title="Link" size="xxs" state={modal} close={() => setModal(false)}>
      <TextField label="url" />
    </ControlDialog>
    <Button
      active={Validate.isLinkActive(editor)}
      onMouseDown={(event) => {
        if (Validate.isLinkActive(editor)) {
          Wrap.unwrapLink(editor)
        } else {
          event.preventDefault()
          const url = window.prompt('Enter the URL of the link:')
          if (!url) return
          Insert.insertLink(editor, url)
        }
            
      }}
    >
      {Validate.isLinkActive(editor) ? 
        <LinkBreak1Icon /> : 
        <Link1Icon />
      }
    </Button>
    </>
  )
}
const ImageButton = () => {
  const editor = useSlateStatic()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the image:')
        if (url && !isImageUrl(url)) {
          alert('URL is not an image')
          return
        }
        url && Insert.insertImage(editor, url)
      }}
    >
      <ImageIcon />
    </Button>
  )
}

const ToggleEditableButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={Validate.isButtonActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        Validate.isButtonActive(editor) ?  
          Wrap.unwrapButton(editor) :
          Insert.insertButton(editor);
      }}
    >
      <ButtonIcon />
    </Button>
  )
}

const InlineButtons = {
  Link: ToggleLinkButton,
  Button: ToggleEditableButton,
  Image: ImageButton
}


export {
  FormatButton,
  MarkButton,
  BlockButton,
  SourceButton,
  ColorButton,

  InlineButtons
};

