import { gql } from '@apollo/client';;

const THEME_FIELDS = ` 
	type
	title 
	description 
	isUserGenerated
	isBookmarked
	colors 
	typography 
	_id
`

export const THEMES = gql`
query themeMany($filter: FilterFindManyThemeInput) {
	themeMany(filter: $filter){
		${THEME_FIELDS}
	}
}
`;

export const THEME = gql`
query themeById($_id: MongoID!) {
	themeById(_id: $_id){
		${THEME_FIELDS}
	}
}
`;
