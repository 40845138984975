import { getPrice, parsePrice } from "@mosanic/utils/products";
import { featureSelector } from "@redux/baseSelector";
import { get, set } from "@util/Obj";
import produce from "immer";
import { HYDRATE } from "next-redux-wrapper";
import { default as actions } from "./action";

const initialState = {
    products: {},
    prices: {},
    annual: true,
    data: []
}
  
const addProduct = (draft, {productId, priceId}) => {
    const product = get(draft.products, productId, 0);
    const price = get(draft.prices, priceId, 0);
    draft = set(draft.products, productId, (product || 0) + 1)
    draft = set(draft.prices, priceId, (price || 0) + 1)
    return draft;
}

const removeProduct = (draft, productId) => {
    const product = get(draft.products, productId, null);
    if(!product) return draft;
    const count = (product - 1)
    if(count === 0) set(draft.products, productId, undefined);
    else set(draft.products, productId, count)

    return draft;
}

const cartReducer = (cart = initialState, action) => produce(cart, draft => {
    switch (action.type) {
        case HYDRATE:
            return {...cart, ...action.payload};

        case actions.DATA:
            return {
                ...draft,
                [action.payload.attr]: action.payload.data
            }
                
        case actions.ADD:
            return addProduct(draft, action.payload);

        case actions.REMOVE:
            return removeProduct(draft, action.payload);

        case actions.REMOVE_MULTIPLE:
            action.payload.map(productId => {
                draft = removeProduct(draft, productId)
            });

        case actions.CLEAR:
            return {
                ...draft,
                products: {}
            };
            
        default:
            return cart;
    }; 
});
export default cartReducer;

export const cartSelector = state => featureSelector(state, 'cart')
export const productsSelector = state => cartSelector(state).products
export const productIdsSelector = state => Object.keys(productsSelector(state));
export const productQuantitySelector = (state, productId) => productsSelector(state)?.[productId]

const addPrice = (total, item) => {
    total.price += (item?.price * item?.count);
    total.count += item?.count;

    return total;
};
export const cartSumSelector = state => {
    const priceData = cartDataSelector(state, 'data');
    const annual = annualSelector(state)
    const data = productsSelector(state);

    const products = Object.entries(data).map(([id, count]) => count ? ({
            price: getPrice(priceData[id]?.prices, annual)?.unit_amount, 
            count
     }) : ({price: 0, count: 0}));

    return products.reduce( addPrice, { price: 0, count: 0 } );
}


export const containsOneProductSelector = (state, productIds) => productIdsSelector(state)?.some(id => productIds?.includes(id))
export const activeContainProductSelector = (state, productIds) => productIdsSelector(state)?.find(id => productIds?.includes(id))


export const cartDataSelector = (state, attr) => cartSelector(state)?.[attr]
export const annualSelector = state => cartDataSelector(state, 'annual')
export const planIdsSelector = state => cartDataSelector(state, 'plans')
export const addonIdsSelector = state => cartDataSelector(state, 'addons')