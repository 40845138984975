

import { colors } from "@mosanic/styles";
import { CenterCenter } from "@mosanic/styles/flex.styles";
import styled, { css } from "styled-components";

const isEmptyCol = (props) => props.empty && props.isLast && props.canExpand
const isActive = (props) => props.canDrop && props.isOver

const getBackground = (level) => {
  switch (level) {
    case 0: return `rgba(16, 132, 233, 0.049)`
    case 1: return `rgba(16, 132, 233, 0.109)`
    case 2: return `rgba(16, 132, 233, 0.149)`
    case 3: return `rgba(16, 132, 233, 0.209)`
    case 4: return `rgba(16, 132, 233, 0.249)`
    case 5: return `rgba(16, 132, 233, 0.309)`
    case 6: return `rgba(16, 132, 233, 0.449)`
    case 'full': return `rgba(16, 132, 233, 0.549)`
    default: return `rgba(16, 132, 233, 0.049)`
  }
}
const getOpacity = (props) => {
  if(props.canDrop){
    if(props.isOver) return 0.7;
    if(isEmptyCol(props)) return 0.05;
    return 0.3;
  }
  return 0;
}
const Inside = styled.div` 

`;
export const DropZone = styled.div` 
  position: absolute;
  transform: translateZ(0);
  transition: 
    background-color 0.2s ease-in-out,
    transform 0.3s ease-out,
    opacity 0.3s ease-in-out  
  ;
  pointer-events: ${props => props.canDrop ? 'all' : 'none'};
  z-index: 99;
  border: 1px solid ${colors.util.primary.lighter};
  background-color:  ${props => isActive(props) ? 
    getBackground(props.level + 2) : 
    getBackground(props.level)
  };
  transform:  ${props => isActive(props) ? 
    `scale(0.9)` : 
    `scale(0.85)`
  };
  opacity: ${props => getOpacity(props)};
  /* position: relative; */
  border-radius: 2px;
  flex: 0 0 auto;
  margin: -12.5px 0;
  width: 100%;
  height: 25px;
  max-height: 25px;

  ${props => isEmptyCol(props) && css` 
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-height: 25px;
    position: absolute;
  `}

  ${props => props.isHorizontal && css` 
    /* position: absolute; */
    margin: 0 -1.05%;
    width: 2%;
    height: auto;
    max-height: none;
    min-height: auto;
  `}

  ${props =>props.isOver && css` 
    border: 1px solid ${colors.util.primary.light};
  `}

  ${props => (props.slot || props.layers) && css` 
    background: none !important;
    border-color: transparent !important;
    flex-shrink: 0;
    ${CenterCenter}

    ${Inside} {
      position: absolute;
      width: 100%;
      height: 5px;
      border: 2px solid ${colors.util.primary.base};
      background: ${getBackground(4)}!important;
    }
    ${props.isOver && css`
      ${Inside} {
      
        height: 10px;
        background: ${getBackground('full')}!important;
      }
    `}
  `}
  ${props => (props.layers) && css` 
      /* margin: -10px ; */
  `} 
  ${props => props.canDrop && css` 
    &:hover {
      opacity: 0.7!important;
      border: 1px solid ${colors.util.primary.light};
      background-color:  ${props => getBackground(props.level + 2)};

      cursor: pointer; 

      ${(props.slot || props.layers) && css` 
        ${Inside} {
          height: 10px;
          background: ${getBackground('full')}!important;
        }
      `}

    }
  `}

  ${props => props.layers && css` 
    /* positio */
    transform: scale(0.95);
    position: relative;
    /* margin: -12.5px 0; */
    /* margin-top: ${props => ((props.level - 1) * 10)+'px!important'}; */
    /* margin-left: ${props => ((props.level - 1) * 10)+'px!important'}; */
      /* margin: 5px 0; */
      /* margin-bottom: -2.5px; */
      /* border: 1px solid red!important; */
      /* opacity: 1; */
  `}
  /* background-color: red; */
  /* opacity: 0.2; */
`;

const DropZoneStyles = {
  DropZone,
  Inside
}
export default DropZoneStyles;









// import { colors } from "@mosanic/styles";
// import { CenterCenter } from "@mosanic/styles/flex.styles";
// import styled, { css } from "styled-components";

// const isEmptyCol = (props) => props.empty && props.isLast && props.canExpand
// const isActive = (props) => props.canDrop && props.isOver

// const getBackground = (level) => {
//   switch (level) {
//     case 0: return `rgba(16, 132, 233, 0.049)`
//     case 1: return `rgba(16, 132, 233, 0.109)`
//     case 2: return `rgba(16, 132, 233, 0.149)`
//     case 3: return `rgba(16, 132, 233, 0.209)`
//     case 4: return `rgba(16, 132, 233, 0.249)`
//     case 5: return `rgba(16, 132, 233, 0.309)`
//     case 6: return `rgba(16, 132, 233, 0.449)`
//     case 'full': return `rgba(16, 132, 233, 0.549)`
//     default: return `rgba(16, 132, 233, 0.049)`
//   }
// }
// const getOpacity = (props) => {
//   if(props.canDrop){
//     if(props.isOver) return 0.7;
//     if(isEmptyCol(props)) return 0.05;
//     return 0.3;
//   }
//   return 0;
// }
// const Inside = styled.div` 

// `;
// export const DropZone = styled.div` 
//   position: absolute;
//   transform: translateZ(0);
//   transition: 
//     background-color 0.2s ease-in-out,
//     transform 0.3s ease-out,
//     opacity 0.3s ease-in-out  
//   ;
//   pointer-events: ${props => props.canDrop ? 'all' : 'none'};
//   z-index: 99;
//   border: 1px solid ${colors.util.primary.lighter};
//   background-color:  ${props => isActive(props) ? 
//     getBackground(props.level + 2) : 
//     getBackground(props.level)
//   };
//   transform:  ${props => isActive(props) ? 
//     `scale(0.9)` : 
//     `scale(0.85)`
//   };
//   opacity: ${props => getOpacity(props)};
//   /* position: relative; */
//   border-radius: 2px;
//   flex: 0 0 auto;
//   margin: -2.5px 0;
//   width: 100%;
//   height: 5px;
//   max-height: 5px;

//   ${props => isEmptyCol(props) && css` 
//     margin: 0;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 100%;
//     min-height: 25px;
//     position: absolute;
//   `}

//   ${props => props.isHorizontal && css` 
//     /* position: absolute; */
//     margin: 0 -1.05%;
//     width: 2%;
//     height: auto;
//     max-height: none;
//     min-height: auto;
//   `}

//   ${props =>props.isOver && css` 
//     opacity: 0.7!important;
//     border: 1px solid ${colors.util.primary.light};
//   `}

//   ${props => (props.slot || props.layers) && css` 
//     background: none !important;
//     border-color: transparent !important;
//     flex-shrink: 0;
//     ${CenterCenter}

//     ${Inside} {
//       position: absolute;
//       width: 100%;
//       height: 5px;
//       border: 2px solid ${colors.util.primary.base};
//       background: ${getBackground(4)}!important;
//     }
//     ${props.isOver && css`
//       ${Inside} {
      
//         height: 5px;
//         background: ${getBackground('full')}!important;
//       }
//     `}
//   `} 
//   ${props => props.canDrop && css` 
//     opacity: 0;
//     &:hover {
//       opacity: 0.7;
//       border: 1px solid ${colors.util.primary.light};
//       background-color:  ${props => getBackground(props.level + 2)};

//       cursor: pointer; 

//       ${(props.slot || props.layers) && css` 
//         ${Inside} {
//           height: 5px;
//           background: ${getBackground('full')}!important;
//         }
//       `}

//     }
//   `}
//   /* background-color: red; */
//   /* opacity: 0.2; */
// `;

// const DropZoneStyles = {
//   DropZone,
//   Inside
// }
// export default DropZoneStyles;