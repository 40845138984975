import {layout, space} from 'styled-system';
import styled, { css } from "styled-components";

const Item = styled.div` 
  ${layout}
  ${space}
  margin: 5px 0;
`
const PulseSkeleton = styled.span`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: ${props =>
    props.trans //translucent
      ? css`linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%)`
      : css`linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)`
    };
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const Skeleton = styled(PulseSkeleton)`
  border-radius: 5px;

  &::before {
    content: "\\00a0";
  }
`;

const SkeletonLine = styled(Skeleton)`
  width: 5.5em;
`;

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 100%;

  min-width: 100%;
  min-height: 100%;

  ${props => props.full && css` 
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
  `}
`;
const SkeletonCircle = styled(Skeleton)` 
  height: ${props => props.height ? props.height : 30}px;
  width: ${props => props.width ? props.width : 30}px;
  border-radius: 50%;
`
const SkeletonStyles = {
  SkeletonLine,
  SkeletonCard,
  SkeletonCircle,
  Item
}
export default SkeletonStyles;