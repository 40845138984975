
import { Condition } from '@JSX';
import { List } from '@mosanic/items/List';
import { ListDivider, ListItem, ListItemChevron, ListItemContent, ListItemIcon, ListItemRight, ListItemSub, ListItemText } from '@mosanic/items/List/List';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { useState } from 'react';

import Link from '../Link';
import Tooltip from '../Tooltip';
import SubMenuItem from './SubMenuItem';

import { sideBarHelpSelector } from '@redux/options/reducer';
import { useSelector } from 'react-redux';
import { default as s } from '../SideBar/SideBar.style';
import Expander from '../Expander';

export default function MenuList({menuMap, iconOnly = false, activate = null, active = '', compact= false, ...other}) {
    const [sub, setSub] = useState(null)

  return (
    <List {...other} p={0} compact={compact}>
        {menuMap.map((item, index) => (
            <MenuListItem 
                name={item.key}
                item={item} 
                type={item?.type} 
                iconOnly={iconOnly}
                active={active}
                activate={activate}
                subIsOpen={sub?.[item?.label]}
                toggleSub={(panel) => setSub({...sub, [panel]: sub?.[panel] ? !sub?.[panel] : true})}
                key={item?.key ? item.key : index}
            />
        ))}
    </List>    
  );
}


const ListItemStyle = {height: '40px', overflow: 'hidden', whiteSpace: 'nowrap'};
const ListItemStyleHelp = {height: 'auto', whiteSpace: 'wrap'};

const MenuListItem = ({item, type, name, iconOnly, active, activate, subIsOpen, toggleSub}) => {
    const isActive = item => (
        ( `/${active?.page}` === item?.href && item?.href) ||
        ( active?.page === '/app' && item?.href === '/') ||
        (active?.page === item?.value && item?.value) ||
        // (active?.main === item?.value) ||
        (active?.sub === item?.value && item?.value)

    ) ? true : false;
    const helpSideBar = useSelector(sideBarHelpSelector) || false
    const {
        action,
        toggle,
        icon,
        label,
        map,

        value,
        href,
        sub_menu,
    } = item;

    const hasLinkValue = Boolean(href || value);
    const LinkWrapper = ({children, disabled = false}) => (hasLinkValue && !disabled) ? (
        item?.href ? (
            <Link href={item.href} action={() => activate(item)}>{children}</Link>
        ) : (
            <span onClick={() => activate(item)}>{children}</span>
        ) 
    ) : children;

    const hasTooltip = Boolean(iconOnly && !subIsOpen)
    const TooltipWrapper = ({children}) => hasTooltip ? (
        <Tooltip title={label} placement="right" force wrap>{children}</Tooltip>
    ) : children;

    if(type === 'divider') return name;
    if(value === 'divider') return <s.Divider /> 
    if(type === 'subMenu') return (
        <SubMenuItem
            label={label}
            toggleComponent={toggle}
        >
            <MenuList menuMap={map} compact sub/>
        </SubMenuItem>
    ) 

    return (
        <TooltipWrapper>
            <LinkWrapper disabled={sub_menu}>
            <ListItem px={iconOnly ? 0 : 4} w={100}  onClick={action} size="large" active={isActive(item)} style={helpSideBar ? ListItemStyleHelp : ListItemStyle}>
                <LinkWrapper disabled={!sub_menu}>
                    <ListItemIcon disabled={!sub_menu}>
                        {icon}
                    </ListItemIcon>
                </LinkWrapper>
                
                    <ListItemContent>
                        {!iconOnly && (
                            <LinkWrapper disabled={!sub_menu}>
                                <ListItemText text={item?.label}/>
                            </LinkWrapper>
                        )}

                        {item?.sub_menu && (
                            <ListItemChevron onClick={() => toggleSub(item?.label)}>
                                {!subIsOpen ? 
                                    <ChevronDownIcon /> :
                                    <ChevronUpIcon />
                                }
                            </ListItemChevron>
                        )}
                    </ListItemContent>
               
               
                {!iconOnly && helpSideBar && (
                    // <LinkWrapper disabled={!sub_menu}>
                        <ListItemSub>
                            {item?.help}
                        </ListItemSub>
                    // </LinkWrapper>
                )}
                {item?.shortcut &&
                    <ListItemRight>
                        {item.shortcut}
                    </ListItemRight>
                }
                
                

            </ListItem>
            {sub_menu && (
                    <Expander expanded={subIsOpen}>
                        <MenuList menuMap={sub_menu} p={0} m={0} ml={iconOnly ? 0 : 5} iconOnly={iconOnly} activate={activate} active={active}/>
                    </Expander>
                )}
            </LinkWrapper>
        </TooltipWrapper>
    )
}