

import { PAGE, PAGE_VERSIONS } from "@api/queries/pages";
import { useLazyQuery } from "@hooks/useApi";
import { useEffect } from "react";

export const usePage = ({_id}) => {
    const [handler, [page, loading, error]] = useLazyQuery('pageById', PAGE, {_id},) 
    
    useEffect(() => {
        if(_id) handler({_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_id])

    return [handler, [page, loading, error]];
}

export const usePageVersions = ({_id}) => {
    const [handler, [page, loading, error]] = useLazyQuery('pageById', PAGE_VERSIONS, {_id},) 
    
    useEffect(() => {
        if(_id) handler({_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_id])

    return [handler, [page, loading, error]];
}