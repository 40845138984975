
import { Condition } from "@JSX";
import { useGetPages } from "@mosanic/cms/Query";
import RenderPage, { usePage } from "@mosanic/components/Pages";
import InView from "@mosanic/core/Items/InView";
import { RelationProvider, useDataProvider, useRepeatProvider } from "@mosanic/core/Provider";
// import ControlDialog  from "@mosanic/items/ControlModal";
const ControlDialog = dynamic(() => import( "@mosanic/items/ControlModal"),{
    ssr: false
})
import { isClone } from "@mosanic/utils/value";
import { isFunc } from "@util/Funct";
import { containsSubstr } from "@util/Text";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { isValidElement } from "react";


export const RenderDialog = ({page, size, title, data, children, ...other}) => (
    <ControlDialog size={size} title={null} trigger={children} wrapTrigger {...other} padding={0}>
        <Condition when={data} wrapper={children=>(
            <RelationProvider data={{...data, entry: data}}>
                {children}
            </RelationProvider>
        )}>
            {/* <pre>
                {JSON.stringify(data, null, 2)}
            </pre> */}
            {page && (
                <RenderPage 
                    blockSeo
                    mode="prod"
                    page={page}
                />
            )}
        </Condition>
    </ControlDialog>
)

export const Modal = ({fields, editor, ref, active, clone}, children) => {
    const {source, handler} = editor || {source: {dialog: undefined}, handler: undefined};
    const [pages, loading, error] = useGetPages({filter: {_id: source?.dialog}})
    const page = pages?.[0]
    const router = useRouter()
    const isDev = containsSubstr(router?.asPath, '/builder')

    //TODO --> use a universal 'data getter'
    // --> refactor, datalink type getter 
    // --> combine all methods
    // --> validate and check for 'clone'
    // --> check if entry is set
    // --> check if fallback 

    // --> page 
    // const data = useDataProvider();
    const repeat = useRepeatProvider();

    // return (
    //     <div>
    //         {isValidElement(children) ? children :'test'}
    //     </div>
    // );
    
    const data = isClone(clone) ? repeat?.entries?.[clone] : null
    if(!children) children = null;

    return (isDev || !ControlDialog) ? children : !loading ? (
        <ControlDialog 
            size={source?.size} title={null} 
            trigger={isValidElement(children) ? children : null} wrapTrigger  padding={0}
        >
            <Condition when={data} wrapper={children=>(
                <RelationProvider data={data}>
                    {children}
                </RelationProvider>
            )}>
                {page && (
                    <RenderPage 
                        blockSeo
                        mode="prod"
                        page={page}
                    />
                )}
            </Condition>
        </ControlDialog>
        // <RenderDialog 
        //     size={source?.size}
        //     title={source?.title}
        //     page={page}
        //     data={isClone(clone) ? repeat?.entries?.[clone] : null}
        // >
        //     {isValidElement(children) ? children : null}
        // </RenderDialog>
    ) : null;
}



// a[href^="http://maps.google.com/maps"]{display:none !important}
// a[href^="https://maps.google.com/maps"]{display:none !important}

// .gmnoprint[role=menubar], .gm-style-cc {
//     display:none;
// }
// .gmnoprint div {
//     transform: scale(0.9);
//     trasnform-origin: left center;
//     background:rgba(0,0,0,0.6) !important;
// }


