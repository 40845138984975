import { gql } from '@apollo/client';

export const ADD_ENTRY = gql`
mutation entryCreateOne($record: CreateOneEntryInput!) {
	entryCreateOne (record: $record) {
		recordId
		record {
			title
			_id
		}
		error {
			message
		}
	}
}
`;

export const UPDATE_ENTRY = gql`
mutation entryUpdateById($_id: MongoID!, $record: UpdateByIdEntryInput!) {
	entryUpdateById (_id: $_id, record: $record) {
		recordId
		record {
			title
			_id
		}
		error {
			message
		}
	}
}
`;

export const REMOVE_ENTRIES = gql`
mutation entryRemoveMany($filter: FilterRemoveManyEntryInput!) {
	entryRemoveMany (filter: $filter) {
		numAffected
		error {
			message
		}
	}
}
`;

export const REMOVE_ENTRY = gql`
mutation entryRemoveById($filter: FilterRemoveEntryByIdInput!) {
	entryRemoveById (filter: $filter) {
		error {
			message
		}
	}
}
`;