import { useUpdateUser } from "@mosanic/cms/Mutate/update";
import { useGetUsers } from "@mosanic/cms/Query";
import { parseCode } from "@mosanic/fields/Code/PreviewCode";
import { useCallback, useEffect } from "react";




export const useEntryTriggers = ({state, setState, entry, trigger}) => {
    const [update, [d, loading]] = useUpdateUser()
    const [users, load] = useGetUsers({filter: {OR: Array.isArray(trigger) ? trigger?.map(t => ({_id: t?.userId}) ) : null}})

    const getParsed = useCallback(() => Array.isArray(trigger) ? trigger?.map(t => {

        const user = Array.isArray(users) ? users?.find(u => u._id === t?.userId) : null;

        return parseCode(t?.code, {user, entry}, false)
    }) : [], [entry, trigger, users])

    useEffect(() => {
        if(users?.length >= 1 && !load && !state?.updated && trigger && Array.isArray(trigger) ) {
            
            const parsed = getParsed();

            if(parsed && parsed?.length >= 1 && parsed?.filter(i => i)?.length >= 1) {
                parsed?.map(user => {
                    update({data: user})
                })

                setState(state => ({...state, updated: 'users'}))
            } 
        } 
        
    },[entry, getParsed, load, setState, state, trigger, update, users])

    return loading ? loading ? load : load : 'Updated users'
}