
// import { RenderDialog } from '@mosanic/builder/Modal';
import { RenderDialog } from '@mosanic/builder/Modal/Modal';
import { useUploadFactory } from '@mosanic/components/Media/Handlers';
import { usePage } from '@mosanic/components/Pages';
import { useCollection } from '@mosanic/core/Collections';
import { Button, Buttons, ColorPicker, Select, TextArea, TextField } from '@mosanic/fields';
import FileField from '@mosanic/fields/File';
import { useTabs } from '@mosanic/hooks';
import { ControlDialog, Expander, InputGroup, InputGroupItem } from '@mosanic/items';
import { CenterCenter, colors } from '@mosanic/styles';
import { parseImageSrc } from '@mosanic/utils/Image';
import { isClone } from '@mosanic/utils/value';
import { ChevronDownIcon, TrashIcon } from '@radix-ui/react-icons';
import { remove, updateItem as update } from '@util/Arr';
import { capitalizeFirst, replacePart } from '@util/Text';
import { darken } from 'polished';
import { useEffect, useState } from 'react';
import ImageMarker, {Marker as Mark} from 'react-image-marker';
import styled, { css } from 'styled-components';

const getType = type => {
    if(type === 'dot') return css` 
        border-radius: 50%;
    `
    return ``
}
const MarkerDot = styled.div` 
    transition: all ease-in-out 0.3s;
    width: 25px;
    height: 25px;
    
    background: ${props=> props.styleProps?.bg};
    border: 2px solid ${props=> darken(0.1, props.styleProps?.bg)};
    color: ${props=> props.styleProps?.color};
    box-shadow: rgba(0,0,0,0.35) 5px 5px 10px 5px;
    font-size: 12px;
    ${CenterCenter}

    ${props => getType(props.type)}
    ${props => props?.size && css` 
        transform: ${`scale(${
            props.size === 'large' ? 1.4 : 
            props.size === 'small' ? 1 : 
            props.size === 'xs' ? 0.5 : 
        1})`};
    `}
    :hover {
        cursor: pointer;
        ${props => props?.size && css` 
            transform: ${`scale(${
                props.size === 'large' ? 1.6 : 
                props.size === 'small' ? 1.2 : 
                props.size === 'xs' ? 0.75 : 
            1.2})`};
        `}  
        &[title]::after {
            background: inherit;
            padding: 5px 10px;
            content: attr(title);
            position: absolute;
            top: calc(-100% - 15px);
            left: calc(-50% - 10px);
            box-shadow: rgba(0,0,0,0.4) 5px 5px 25px 5px;
        }
    }
`
const Inline = styled.div` 
    display: flex;
    align-items: center; 
` 
const Border = styled.div` 
    margin-top: 8px;
    border: 1px dashed ${colors.util.gray.light};
    padding: 2px;
` 

const typeLabel = (type, styleProps, size, title = true) => (
    <Inline>
        <MarkerDot styleProps={styleProps} type={type} size={size}>
            1
        </MarkerDot> 
        &nbsp;{(styleProps?.title && title) ? styleProps.title : `${capitalizeFirst(type)} ${size ? size : ''}`}
    </Inline>
)

const defaultDot = i => ({color: '#000', bg: '#fff', type: 'dot', title: `Dot ${i}`});

const MarkedImageUploader = ({
    value, 
    type = 'image',
    onChange = null,
    label = null,
    setup = true,
    relation = null,
    display = null,
    displayOnly = false
}) => {
    const source = parseImageSrc(value?.img?.url ? value?.img?.url : value?.img, true);
    const [handler, [collection]] = useCollection({_id: relation})
    const hasRelation = Boolean(collection && relation)
    //Data
    const [images, meta, progress, handlers, loading, error] = useUploadFactory({
        callback: img => onChange({...value, img}),
        url: value?.img, 
    });

    const [trigger, setTrigger] = useState('')

    const [ styles, setStyles] = useState( value?.styles ? value?.styles : []);
    const updateStyle = (index, newData) => setStyles(update(styles, index, newData));
    const removeStyle = (index) => setStyles([...remove(styles, index)])
    const addStyle = () => setStyles([...styles, defaultDot(styles?.length)])

    //Data handling
    const [arr, setArr] = useState(value?.markers ? value?.markers : []);
    const updateItem = (index, newData) => setArr(update(arr, index, newData));
    const removeItem = (index) => setArr([...remove(arr, index)])
    const addItem = (item) => setArr([...arr, item])

    const [relationType, setRelationType] = useState(null)

    const removeMarker = index => removeItem(index)

    // useEffect(() => {
    //     if(display && !value?.display) onChange({...value, display})
    //     if(relation && !value?.relation) onChange({...value, relation})
    //      if(value?.markers) setArr(value?.markers)
    // },[display, value?.markers, relation, value])

    useEffect(() => {
        let timer;
        timer = setTimeout(() => {
            if(arr?.length >= 1) onChange({...value, markers: arr})
        }, 400);
        return ()=> clearTimeout(timer)
    },[arr, value?.markers?.length, value?.markers])

    // useEffect(() => {
    //     let timer;
    //     timer = setTimeout(() => {
    //         if(styles?.length >= 1) onChange({...value, styles})
    //     }, 400);
    //     return ()=> clearTimeout(timer)
    // },[styles?.length, styles, setup])

    const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);
    
    const Marker = props => {
        const markerDot = arr[props.itemNumber];
        let markerStyle= styles?.find(s => s?.title === markerDot?.type);
        markerStyle = markerStyle ? markerStyle : styles[0]
        return (
            <MarkerDot 
                onClick={() => setActiveMarkerIndex(props.itemNumber)}
                style={{opacity: (hasRelation && markerDot?.relation != relationType) ? 0.4 : 1}}
                data={markerStyle?.title}
                attr={markerDot?.attr} 
                size={markerStyle?.type?.split('-')?.[1]}
                type={markerStyle?.type?.split('-')?.[0]}
                styleProps={markerStyle} 
            >
                {replacePart(markerDot?.title, 'Marker', '')}
            </MarkerDot>
        )
    }

    const {setOptions, current, Tabs} = useTabs({tabs: (setup ? 'markers|image|style' : 'markers|image').split('|'), tab: 'image'})
    useEffect(() => {
        setOptions((setup ? 'styles|trigger|markers' : 'markers|image').split('|'))
    },[setup])

    const RelationSelect = ({onChange, value, unsetLabel = 'Unset', ...other}) => (
        <Select 
            wrap fullWidth
            label="Relation"
            options={[...collection?.entries?.map(c => ({
                value: c?._id,
                label: c?.[collection?.schema?.[0]?.name]
            })),{
                label: unsetLabel,
                value: null
            }]}
            onChange={onChange}
            value={value}
            {...other}
        />
    )

    return (
        <>
    
        <ControlDialog state={isClone(activeMarkerIndex)} close={() => setActiveMarkerIndex(null)} overflow title={`Marker: ${arr[activeMarkerIndex]?.title ? arr[activeMarkerIndex]?.title : activeMarkerIndex}`}>
            {(!relationType || relationType === arr[activeMarkerIndex]?.relation) && (
                <InputGroupItem fullWidth>
                    <Button icon={<TrashIcon/>} ghost onClick={() => removeMarker(activeMarkerIndex)}/>
                    <TextField 
                        label="Title"
                        value={arr[activeMarkerIndex]?.title}
                        onChange={e => updateItem(activeMarkerIndex, {...arr[activeMarkerIndex], title: e?.target?.value})}
                    />
                    {styles?.length >= 2 && (
                        <Select 
                            wrap fullWidth
                            label="Marker style"
                            options={styles?.map((style, index) => ({
                                value: style?.title,
                                label: typeLabel(style?.type?.split('-')[0], style, style?.type?.split('-')?.[1])
                            }))}
                            value={arr[activeMarkerIndex]?.type}
                            onChange={type => updateItem(activeMarkerIndex, {...arr[activeMarkerIndex], type})}
                        />
                    )}
                    {hasRelation && (
                        <RelationSelect 
                            value={arr[activeMarkerIndex]?.relation}
                            onChange={relation => updateItem(activeMarkerIndex, {...arr[activeMarkerIndex], relation})}
                        />
                    )}
                    {trigger?.split('\n')?.length >= 2 && (
                        <Select 
                            wrap fullWidth
                            label="Trigger"
                            options={trigger?.split('\n')}
                            value={arr[activeMarkerIndex]?.trigger}
                            onChange={trigger => updateItem(activeMarkerIndex, {...arr[activeMarkerIndex], trigger})}
                        />
                    )}
                    
                </InputGroupItem>
            )}
        </ControlDialog>
        <Tabs />
        
        {current === 'image' && (
            <FileField 
                label={label}
                onChange={(e) => handlers.uploadFile(e.target.files[0], '')} 
                initiate={(url) => {
                    handlers?.setImages([{url}]);
                    onChange({...value, img: url});
                }}
                value={images?.[0]}
                loading={loading}
                type={type}
            />
        )}
        {current === 'styles' && (
            <>
            {styles?.map((itemStyle, index) => (
                <MarkerStyling 
                    item={itemStyle}
                    update={item => updateStyle(index, item)}
                    // update={item => updateStyle(index, item)}
                />
            ))}
            <Buttons>
                <Button label="Remove type" onClick={() => removeStyle(styles?.length - 1)}/>
                <Button label="Add type" onClick={addStyle}/>
            </Buttons>
            </>
        )}
        {current === 'trigger' && (
            <InputGroup>
                <TextArea 
                    label="Triggers"
                    onChange={e => setTrigger(e)}
                    value={trigger}
                />
            </InputGroup>
        )}
        {current === 'markers' && (
            <InputGroup>
                    <Border>
                        {/* {JSON.stringify({...value, markers: undefined, styles: undefined, relation: undefined})} */}

                        {source?.full && (
                            <ImageMarker
                                src={source?.full}
                                markers={arr ? arr : value?.markers ? value.markers : []}
                                onAddMarker={(marker) => !displayOnly ? addItem(marker) : null}
                                markerComponent={Marker}
                            />
                        )}
                    </Border>
                    {hasRelation && (
                        <RelationSelect 
                            unsetLabel="View all"
                            onChange={setRelationType}
                            value={relationType}
                            label="Filter relation"
                        />
                    )}

                    {arr?.map((marker, index) => (!relationType || relationType === marker?.relation) && (
                        <InputGroupItem key={index} fullWidth>
                            <Expander trigger={<Button label={marker?.title ? marker?.title : index} icon={<ChevronDownIcon />} ghost/>}>
                            <Button icon={<TrashIcon/>} ghost onClick={() => removeMarker(index)}/>
                            <TextField 
                                label="Title"
                                value={marker?.title}
                                onChange={e => updateItem(index, {...marker, title: e?.target?.value})}
                            />
                            {styles?.length >= 2 && (
                                <Select 
                                    wrap fullWidth
                                    label="Marker style"
                                    options={styles?.map((style, index) => ({
                                        value: style?.title,
                                        label: typeLabel(style?.type?.split('-')[0], style, style?.type?.split('-')?.[1])
                                    }))}
                                    value={marker?.type}
                                    onChange={type => updateItem(index, {...marker, type})}
                                />
                            )}
                            {hasRelation && (
                                <RelationSelect 
                                    value={marker?.relation}
                                    onChange={relation => updateItem(index, {...marker, relation})}
                                />
                            )}
                            {trigger?.split('\n')?.length >= 2 && (
                                <Select 
                                    wrap fullWidth
                                    label="Trigger"
                                    options={trigger?.split('\n')}
                                    value={marker?.trigger}
                                    onChange={trigger => updateItem(index, {...marker, trigger})}
                                />
                            )}
                            </Expander>
                        </InputGroupItem>
                    ))}
            </InputGroup>
        )}
        </>
    )
};

const MarkerStyling = ({item, update}) => {
    let markerStyles = 'dot|square'.split('|').map(type => ({
        value: type,
        label: typeLabel(type, item, null, false)
    }))
    const addSize = (markers, size) => markers.map(m => ({value: `${m?.value}-${size}`, label: typeLabel(m.value, item, size, false)}))
    markerStyles = [
        ...addSize(markerStyles, 'xs'),
        ...addSize(markerStyles, 'small'),
        ...markerStyles, 
        ...addSize(markerStyles, 'large')
    ];

    return (
        <InputGroup>
            <TextField 
                label="Title"
                value={item?.title}
                onChange={e => update({...item, title: e?.target?.value})}
            />
            <Select 
                wrap fullWidth
                label="Marker style"
                options={markerStyles}
                value={item?.type}
                onChange={type => update({...item, type})}
            />
            <ColorPicker 
                wrap fullWidth
                label="Background"
                value={item?.bg}
                onChange={bg => update({...item, bg: bg?.hex})}
            />
            <ColorPicker 
                wrap fullWidth
                label="Color"
                value={item?.color}
                onChange={color => update({...item, color: color?.hex})}
            />  
        </InputGroup>
    )
}


export const MarkedImage = ({value}) => {
    const styles = value?.styles;
    const arr = value?.markers;
    const source = parseImageSrc(value?.img?.url, true);

    const [mark, setMark] = useState(null)
    const [getColl, [collection]] = useCollection({_id: value?.relation})
    const [getPage, [page]] = usePage({_id: value?.display});

    const Marker = props => {
        const mark = arr?.[props.itemNumber];
        let markerStyle = styles?.find(s => s?.title === mark?.type);
        markerStyle = markerStyle ? markerStyle : styles[0]
        return (
            <MarkerDot 
                onClick={() => setMark(mark)}
                data={markerStyle?.title}
                attr={mark?.attr} 
                size={markerStyle?.type?.split('-')?.[1]}
                type={markerStyle?.type?.split('-')?.[0]}
                styleProps={markerStyle} 
                title={mark?.type}
            >
                {replacePart(mark?.title, 'Marker', '')}
            </MarkerDot>
        )
    }


    return (
        <>

        {(page && collection && mark && mark?.relation) && (
            <RenderDialog
                page={page}
                data={collection?.entries?.find(e => e._id === mark?.relation)}
                size="xl"
                title={collection?.singular}
                state={mark?.relation}
                close={()=> setMark(null)}
            />
        )}  
        {value?.img?.url ? (
            <ImageMarker
                src={source?.full}
                markers={Array.isArray(arr) ? arr : []}
                markerComponent={Marker}
            />
        ) : null}
        </>
    )


}
export default MarkedImageUploader;