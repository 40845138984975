import { getEntryLabel, isDataLink, isProvider } from "@mosanic/fields/Source/helpers";
import { CollectionEditing } from "@mosanic/core/Collections/Parts";
import { AdvancedLabel, ControlDialog } from "@mosanic/items";
import { ChevronRightIcon, SizeIcon } from "@radix-ui/react-icons";
import styled from "styled-components";
import Button, { Buttons } from "../Button/Buttons";
import { SourceModus } from "./SourceTabs";
import { convertEntry } from "@mosanic/cms/Query/useDataQuery";
import { revertDevCases } from "@util/Text";

const Slug = styled.div` 
    /* display: flex; */
    /* flex-wrap: nowrap; */
    margin-top: 4px;
    white-space: nowrap;
    max-width: 275px;
    height: 35px;
    /* overflow: scroll; */
`
const Wrapper = styled.div` 
    display: flex;
    justify-content: space-between;
    width: 100%;
    
`

export const SourceHeader = ({title, helpText, dialog, children}) => (
    <Wrapper>
        <AdvancedLabel as="h4" style={{marginTop: dialog.expand ? '15px' : 0}} span helpText={helpText} > 
            {title}
            <Button ghost icon={<SizeIcon />} mt="-2px"  style={{display: 'inline-block'}} onClick={dialog.handleExpand} />
        </AdvancedLabel>
        {children}
    </Wrapper>
)
export const SourceFooter = ({collection, state, activeEntry, save, canSave, type}) => {
    const fieldPath = state?.fieldPath
    return (
        <>
        <AdvancedLabel>
            <Slug>
                {collection?.title} <ChevronRightIcon />
                {revertDevCases(getEntryLabel(convertEntry(activeEntry?.entry), collection?.schema))} 
                {fieldPath && <ChevronRightIcon />}
                {fieldPath && fieldPath?.map((f, index) => (
                    <span key={f?.name}>
                        {revertDevCases(f?.name)} 
                        {(fieldPath?.length - 1) != index && ( 
                            <ChevronRightIcon />
                        )}
                    </span>
                ))}
            </Slug>
        </AdvancedLabel>
        <Buttons size="small" right >
            {state?.collection && ( 
                <ControlDialog title={collection?.title} size="xl" trigger={(
                    <Button label={`View ${type}`} ghost target="_blank" />
                )}>
                    <CollectionEditing collection={collection}/>
                </ControlDialog>
            )}
            <Button disabled={!canSave} label="Save" theme="primary" onClick={save} />
        </Buttons>
        </>
    )
}