import { Transforms } from 'slate'
import Wrap from "./Wrap"

const insertLink = (editor, url) => {
    if (editor.selection) {
        Wrap.wrapLink(editor, url)
    }
}
const insertImage = (editor, url) => {
    const text = { text: '' }
    const image = { type: 'image', url, children: [text] }

    Transforms.insertNodes(editor, image)
}

const insertButton = editor => {
    if (editor.selection) {
        Wrap.wrapButton(editor)
    }
}


const Insert = {
    insertLink,
    insertImage,
    insertButton,
}

export default Insert;