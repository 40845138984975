import { Variants } from "@mosanic/constants";
import { getAttrGroup } from "@mosanic/core/Map/Styles/parseAttr";

import { useValidate } from "@mosanic/hooks";
import { hasAttr } from "@util/Obj";

export const getOptions = (options) => {
  const parsed = {
    main: options?.main ? options.main : undefined, 
    clone: options?.clone ? options.clone : undefined, 
    link: options?.link ? options.link : undefined,
    component: options?.component ? options.component : undefined,
  };
  return parsed ? parsed : undefined;
}

export const getVariant = ({source, screen, field}) => !source ? null :
    hasAttr(source, screen) ?
        source[screen][field] : 
        hasAttr(source, Variants.BASE) ?
            source[screen][Variants.BASE] :
            null;

//Get component data by screen variant or other specific variants
export const useData = ({component, screen = Variants.BASE, variant = []}, applyBase = true, overwrite) => {
  const validate = useValidate(component?.state, screen)

  const data = component?.data;
  const padding = getAttrGroup(data?.padding, validate)
  const margin = getAttrGroup(data?.margin, validate)
  const corner = getAttrGroup(data?.corner, validate)


  return {
    padding, 
    margin,
    corner
  };
}