import { isRenderField } from "@mosanic/constants";
import { canMap } from "@util/Arr";

export const isSlot = (child) => (child === 'slot' || child?.type === 'slot')

// export const getSlotCount = render => render.filter(child => isSlot(child))?.length;

const getItemsAfterSlot = (render, slotIndex) => [...render].splice(slotIndex + 1, render?.length);

export const getSlotChildCount = (render, slotIndex) => {
    const validate = getItemsAfterSlot(render, slotIndex);
    if(!validate?.length) return 0;

    let childCount = 0, next = true;
    
    validate.map(c => {
        //If a defined break is setup stop.
        if(isRenderField(c) || isSlot(c)) next = false;
        if(next) childCount ++;
    })
    return childCount;
}

export const isValidSlot = (slot, length) => {
    if(!slot?.max) return true;
    return slot.max >= length;
}

export const hasSlots = (children) => {
    let hasSlots = false;
    if(canMap(children)) children?.map(child => {
        if(child?.slot || isSlot(child)) hasSlots = true;
    })
    return hasSlots;
}

export const manageSlots = (children) => {
    if(!hasSlots(children)) return false;
    let manageSlots = false;
    if(canMap(children)) children?.map(child => {
        if((child?.slot || isSlot(child)) && child?.manage) manageSlots = true;
    })
    return manageSlots;
}