import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import undoable, { includeAction } from 'redux-undo';

import { 
    HANDLE_HOVER, 
    SET_HOVER_ELEMENT, 
    TOGGLE_LAST_HOVER
} from './bounds/action';

import { 
    END_DRAGGING,
    START_DRAGGING 
} from './ui/uiAction';

import { boundsMdl } from './bounds/middleware';
import { canvasMdl } from './canvas/middleware';
import { modalMiddleware } from "./modal/middleware";
import {notificationMdl}  from "./notification/middleware";
import { orderMdl } from "./order/orderMdl";
import { pageMdl } from "./pages/middleware";
import { screensMdl } from './screens/middleware';
import { tokensMdl } from "./tokens/middleware";
import { dataMiddleware } from './map/data';
import { dropMiddleware } from './map/drop';
import { inspectMiddleware } from './inspect';
import { mapMiddleware } from './map';
import { navMiddleware } from './navigation';
import { updateMiddleware } from './update';
import { artboardMiddleware } from './artboard';
import { colorsMdl } from './colors';
import { typesMdl } from './typography';

import { apiMiddleware } from "./api/middleware";
import { preventMiddleware } from './util/preventMdl';
import { spinnerMiddleware } from './ui/middleware';
import { actionSplitterMiddleware } from "./util/actionSplitter";
import { normalizeMiddleware } from "./util/normalize/middleware";

import storage from './sync_storage';
import client from '@api/client';

import { coreReducer, createReducer } from "./coreReducer";
import dynamic from 'next/dynamic';
import { authMdl } from './auth/middlware';


import {default as map} from './map/action';
import {default as data} from './map/data/action';
import {default as screen} from './screens/action';


const enhanceOptions = {
    actionsDenylist: [
        SET_HOVER_ELEMENT,
        TOGGLE_LAST_HOVER,
        
        HANDLE_HOVER,
        END_DRAGGING,
        START_DRAGGING
    ],
    trace: true, traceLimit: 25, limit: 5,
};

const composeEnhancers = composeWithDevTools(enhanceOptions);
/**
 * Add redux dev tools
 */
const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        return composeEnhancers(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};



const featureMiddleware = [
    // checkDropMiddleware, //feature
    ...inspectMiddleware,
    
    ...authMdl,
    ...dropMiddleware,
    ...dataMiddleware,
    ...mapMiddleware,
    ...updateMiddleware,

    ...artboardMiddleware,
    ...canvasMdl,
    ...screensMdl,
    ...tokensMdl,
    ...colorsMdl,
    ...typesMdl,

    ...boundsMdl,
    ...pageMdl, 
    ...navMiddleware,
    ...orderMdl, 
];



const coreMiddleware = [
    actionSplitterMiddleware,
    apiMiddleware,
    normalizeMiddleware,
    ...notificationMdl,
    spinnerMiddleware,
    ...modalMiddleware
]
const coreUndo = undoable(coreReducer,{
    filter: includeAction([
        map.MAP_UPDATE, 
        data.DATA_UPDATE,
        screen.SCREENS_UPDATE_META
    ]), //todo same
    limit: 10,
    // filter: excludeAction([SET_HOVER_ELEMENT,TOGGLE_LAST_HOVER,HANDLE_HOVER]),
    // groupBy: groupByActionTypes([SOME_ACTION]),
});
 
/**
 * Make global store
 * @param {boolean} isServer 
 * @returns redux store
 */
const makeStore = ({ isServer }) => {
    if (isServer) {
        /**
         * Create new server store
         */
        return createStore(
            // stateFreezer(coreReducer), 
            coreUndo,
            bindMiddleware([
                thunk.withExtraArgument({client}),
                preventMiddleware,
                ...featureMiddleware,
                ...coreMiddleware
            ])
        );
    } else {

        /**
         * Create store on client side
         * This will be a persisted object.
         */
         const { persistStore, persistReducer } = require('redux-persist');
        //  const persistStore = dynamic(() => import('redux-persist/es/persistStore'));
        //  const persistReducer = dynamic(() => import('redux-persist/es/persistReducer'));
         
        const persistConfig = {
             key: 'soocore',
             whitelist: ['counter', 'options', 'tokens', 'auth'], // will be persisted
             storage, 
         };

         const persistedCoreReducer = undoable(persistReducer(persistConfig, coreReducer) ,{
             filter: includeAction([
                map.MAP_UPDATE, 
                data.DATA_UPDATE,
                screen.SCREENS_UPDATE_META
            ]),  //todo update layout + data
             limit: 10,
         });
       

        const store = createStore(
            persistedCoreReducer, 
            bindMiddleware([
                thunk.withExtraArgument({client }),
                preventMiddleware,
                ...featureMiddleware,
                ...coreMiddleware
                
            ]) 
        ); // Creating the store again

        /**
         * Push new persistor store to __persistor
         * Used to call the syn storage
         */
         store.__persistor = persistStore(store); 


        return store;
    }
};



/**
 * Export wrapper for _app
 */
export const reduxWrapper = createWrapper(makeStore);


