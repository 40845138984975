import { splitPath } from "./paths";


export const getCurrentPath = (path, index) => (path ? 
    `${path}-${index}` : 
    `${index}`
  );

export const getSiblingPath = (path, after = true) => {
    const splittedPath = Array.isArray(path) ? path : splitPath(path);
    const itemIndex = parseFloat(splittedPath.pop()) + (after ? 1 : -1);
    return [...splittedPath, itemIndex];
}
