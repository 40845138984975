import { FormGroup, FormItems } from "@mosanic/fields/Form";
import { slugify } from "@util/Text";
import { useFormContext } from "react-hook-form";

export const AppearanceForm = ({fields = []}) => {
    const { handleSubmit, control, watch, reset, formState: { errors, isValid } }= useFormContext();
	const inputProps = {control, fullWidth: true, errors}

    return (
        <FormGroup onSubmit={null} disabled={!isValid}>
            <FormItems controls={inputProps} fields={fields}
            />
        </FormGroup>
    )
} 