import { Button } from "@mosanic/fields"
import { getEntryLabel, getFieldOptions, getFieldRelation, shouldPassData, shouldPassSchema } from "@mosanic/fields/Source/helpers"
import { Expander, InputGroup } from "@mosanic/items"
import { FileIcon, PlusCircledIcon, TokensIcon } from "@radix-ui/react-icons"
import { isString, revertDevCases } from "@util/Text"
import { useState } from "react"
import { useGetCollection, useGetEntries } from "../Query"
import { convertEntry } from "../Query/useDataQuery"
// import { Entry } from "@mosanic/core/Collections"
import { LocalizedInput } from "@mosanic/fields/Input/Input"
import { SelectLocales } from "@mosanic/i18n"
import ControlButtons from "@mosanic/items/Control/ControlButtons"
import { TwoColumns } from "@mosanic/items/Page/Columns"
import { colors } from "@mosanic/styles"
import { useRouter } from "next/router"
import { useFieldArray, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { EditorVariables } from "./EditorVariables"


import { useEntryMailConversion } from "@mosanic/utils/mail"

export const EditorTemplates = ({collectionId, trigger = false, schema = [], templates = []}) => {
    const id = 'templates';

    const { control, errors, setValue, watch, formState: { isValid } } = useFormContext();
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		name: id, 
		control
	}); 

    const router = useRouter()
	const {locales, locale, asPath: path} = router;
	const [activeLang, setActiveLan] = useState(locale);

    const setLang = (lang, replaceRouter) => {
		setActiveLan(lang);
		if(replaceRouter)
			router.push(path, path, { locale: lang, shallow: true });
	}

	const hasLocalizedFields = watch()?.schema?.find(s => s?.localized)
	const primaryField = watch()?.schema?.[0]?.name
    const inputProps = {control, errors, fullWidth: true}

    const [tab, setTab] = useState(null)

    const getLabel = i => getEntryLabel(watch()?.[id]?.[i], watch()?.schema)

    const [entries] = useGetEntries({filter: {collectionId}, limit: 1, skip: 0});
    const [coll] = useGetCollection({_id: collectionId, silent: true})
    

    let entry = convertEntry(entries?.[0])
    

    const mail = useEntryMailConversion({
        entry, 
        template: watch()?.[id]?.[tab], 
        baseRoute: coll?.baseRoute,
        schema: coll?.schema
    })

    return (
        <>
        <ControlButtons 
            options={fields?.map((_, index) => ({label: getLabel(index), icon: <FileIcon/>, value: `${index}`}))}
            setActive={setTab}
            active={tab}
        >
            <EditorVariables 
                trigger={(
                    <Button 
                        style={{
                            border: `${colors.util.orange.lighter} 1px solid`, 
                            color: colors.util.orange.dark
                        }} 
                        icon={<TokensIcon/>} 
                        m={0} ml={2} 
                        label={<b>Variables</b>} 
                        minWidth="100px" 
                    />
                )} 
                collectionId={collectionId}
            />
            <Button onClick={() => append({})} icon={<PlusCircledIcon />} label="Add template" />
        </ControlButtons>

        <SelectLocales locales={hasLocalizedFields ? locales : null} active={activeLang} setActive={setLang} />

        {fields?.map((template, index) =>  `${index}` === tab ? (

                <Entry 
                    mail={mail}
                    id={id}
                    index={index}
                    inputProps={inputProps}
                    schema={watch()?.schema}
                    locales={{
                        locales, 
                        locale,
                        activeLang
                    }}
                    label={`Template: ${index + 1}`}
                    remove={() => {
                        setTab(fields?.[0]?.id)
                        remove(index);
                    }}
                />
        ): null)}

    {/* {JSON.stringify({...watch(), schema: undefined, data: undefined, collectionId: '', userId: undefined, updatedAt: undefined})} */}
        </>

    )
}




const Entry = ({schema, locales, label = null, cols = 1, id, index, remove, mail}) => {
    return (
        <TwoColumns first={
				<InputGroup fullWidth cols={cols} style={{width: '100%'}} border label={label}>

					{schema?.map((field) => (
						<LocalizedInput
							{...locales}

							type={field?.type} 
							// value={field?.default} 
							field={{
                                ...field,
                                name: `${id}.${index}.${field?.name}`,
                                label: revertDevCases(isString(field?.label) ? field.label : field?.name)
                            }}
							help={field?.help}
							options={getFieldOptions(field)}
							relation={getFieldRelation(field)}
							schema={shouldPassSchema(field) ? collection.schema : null}
                            data={shouldPassData(field) ? watch() : null}
							// {...inputProps}
							fullWidth={field?.fullWidth ? field.fullWidth : false}
						/>
					))}
                    <Button mt={4} ml={0} onClick={() => remove()} label="Remove template" />
				</InputGroup>
            }
            second={
                <Expander trigger={
                    <Preview>
                        <b>{mail?.subject}</b> <br/>
                        <PreHeader>
                            {mail?.preHeader}
                        </PreHeader>
                        <a href={`mailto:${mail?.to}`}>{mail?.to}</a>
                    </Preview>
                }>
                    <Mail border>
                        {mail?.subHeader && <h5>{mail.subHeader}</h5>}
                        {mail?.mainHeader && <h2>{mail.mainHeader}</h2>}
                        <span dangerouslySetInnerHTML={{ __html: mail?.content }} />
                        {mail?.mainHeader && <CenterLink href="/">email dynamic code</CenterLink>}
                        {mail?.sectionFooterHeader && <h2>{mail.sectionFooterHeader}</h2>}
                        {mail?.sectionFooterText && <span dangerouslySetInnerHTML={{ __html: mail?.sectionFooterText }} />}
                          {/* replaceParts('\n','<br/>') */}

                    </Mail>
                </Expander>
            }
        />
            
    )
}

const CenterLink = styled.a` 
width: 100%;
display: block;
text-align: center;
padding: 10px 0 25px;
font-size: 110%;
`
const Preview = styled.div` 
    font-size: 80%;
    padding: 20px 10px 5px;
    cursor: pointer;
    transition: all ease-in-out .3s;
    &:hover {
        transform: scale(1.02)
    }
`
const PreHeader = styled.p` 
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 0;
`
const Mail = styled.p` 
    margin-top: 10px;
    border: 1px solid #eee;
    padding: 15px 10px;
    font-size: 80%;
    border-radius: 10px;
    
`