import { colors } from "@mosanic/styles";
import styled, { css } from "styled-components";


const ScaledFrame = styled.div`
    width: 143%;
    /* width: 130% */
    zoom: ${props => props.scale ? props.scale : 0.7};
    transform: scale(${props => props.scale ? props.scale : 0.7});
    transform-origin: 0 0;
    margin-bottom: -100%; 
    /* -20% */

    ${props => props.border && css` 
        border: 1px solid ${colors.util.gray.light};
        border-radius: 10px;
    `}
    ${props => props.float && css` 
        box-shadow: 4px 4px 25px 0 ${colors.util.gray.light};
    `}
    
`;


export {
    ScaledFrame
};