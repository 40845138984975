/* Configuration imported from '.env' file */
const isServer = Boolean(process.env.BACKEND_APP_PROTOCOL);

const backendProtocol 	= isServer ? process.env.BACKEND_APP_PROTOCOL : process.env.NEXT_PUBLIC_APP_PROTOCOL;
const backendHost 		= isServer ? process.env.BACKEND_APP_HOST : process.env.NEXT_PUBLIC_APP_HOST;
const backendDomain 	= isServer ? process.env.BACKEND_APP_DOMAIN : process.env.NEXT_PUBLIC_APP_DOMAIN;
const backendPort 		= isServer ? process.env.BACKEND_APP_PORT : process.env.NEXT_PUBLIC_APP_PORT;
const backendGraphql 	= isServer ? process.env.BACKEND_APP_GRAPHQL : process.env.NEXT_PUBLIC_APP_GRAPHQL;

const backendBase = `${backendProtocol}://${backendHost}`;

const backendLocation = Boolean(backendPort) ? 
    `:${backendPort}` : 
    `.${backendDomain}`;

export const backendAddress = `${backendBase}${backendLocation}${backendGraphql}`;
export const backendSocketAddress = `ws://${backendHost}${backendLocation}/subscriptions`;
export default backendAddress;
